import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NSCard } from 'bricks';
import React, { useCallback } from 'react';
import { IReportBuilderReport } from 'views/ReportBuilder/constants';

export interface IProps {
    report: IReportBuilderReport;
    setNewReports: React.Dispatch<React.SetStateAction<IReportBuilderReport[]>>;
}
const SavedReportDetailCard = ({ report, setNewReports }: IProps) => {
    const handleDelete = useCallback(() => {
        setNewReports(prevReports => prevReports.filter(r => r.savedReportId !== report.savedReportId));
    }, [report.savedReportId, setNewReports]);

    return (
        <NSCard className="NSCard--level-2 p-3 mt-2">
            <div className="d-flex justify-content-between align-items-center">
                <h4 className="text-white m-0">{report.savedReportName}</h4>
                <div className="d-flex align-items-center">
                    <p className="m-0">{report.savedReportType}</p>
                    <FontAwesomeIcon
                        icon={faTrash}
                        color="white"
                        data-testid="trash-icon"
                        size="lg"
                        className="pl-2 cursor--pointer"
                        onClick={handleDelete}
                    />
                </div>
            </div>
        </NSCard>
    );
};

export default SavedReportDetailCard;
