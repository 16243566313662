import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faCalendarCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';

import { useAuthContext } from 'contexts/AuthContext';
import { NSButton, NSTable, NSInput } from 'bricks';
import { formatDate } from 'ns_libs/formatter';
import capitalize from 'lodash/capitalize';
import { useQueryClient } from '@tanstack/react-query';
import RenderIf from 'components/RenderIf/RenderIf';
import useToast from 'hooks/useToast';
import { useGetTimelines } from '../hooks/useGetTimeline';
import { useUpdateTimeline } from '../hooks/useUpdateTimeline';
import { useDeleteTimeline } from '../hooks/useDeleteTimeline';
import ActionMenu from './components/ActionMenu/ActionMenu';
import './Timeline.scss';
import 'react-datepicker/dist/react-datepicker.css';

type TimelineItem = {
    id: string;
    dealId: number;
    name: string;
    target: string | null;
    completed: string | null;
    status: 'open' | 'complete' | 'overdue';
};

const Timeline = ({ dealId }: { dealId: number }) => {
    const { selectedOrganizationId } = useAuthContext();

    const listRef = useRef<HTMLDivElement>(null);
    const [timelineData, setTimelineData] = useState<TimelineItem[]>([]);
    const [editingId, setEditingId] = useState<string | null>(null);
    const [editName, setEditName] = useState<string>('');
    const [editingDateField, setEditingDateField] = useState<{ id: string; field: 'target' | 'completed' } | null>(null);

    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { data: timelines, isLoading: isLoadingTimelines } = useGetTimelines({ orgId: selectedOrganizationId!, dealId });
    const { mutate: deleteTimeline } = useDeleteTimeline();
    const { showError } = useToast();

    const targetInputRef = useRef<HTMLInputElement>(null);
    const completedInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (timelineData?.length === 0 && timelines) {
            setTimelineData(timelines);
        }
    }, [timelines]);

    const handleRowEdit = (id: string) => {
        setEditingDateField(null);
        setEditingId(id);
        const timeline = timelineData.find(item => item.id === id);
        if (timeline) {
            setEditName(timeline.name);
        }
    };

    const handleDateEdit = (id: string, field: 'target' | 'completed') => {
        setEditingDateField({ id, field });
    };

    const cancelDateEdit = () => {
        setEditingDateField(null);
    };

    const formatDateForAPI = (date: Date): string => {
        const offset = date.getTimezoneOffset();
        // Adjust for timezone offset by subtracting the offset minutes converted to milliseconds
        const adjustedDate = new Date(date.getTime() - (offset * 60 * 1000));
        return adjustedDate.toISOString().split('T')[0];
    };

    const handleDateChange = (id: string, field: 'target' | 'completed', date: Date): void => {
        if (!isValidDate(date)) {
            showError('Invalid date provided');
            return;
        }

        const newDate = formatDateForAPI(date);
        const originalData = [...timelineData];

        setTimelineData(prevData => prevData.map(item => (item.id === id ? { ...item, [field]: newDate } : item)));
        setEditingDateField(null);

        if (field === 'target') {
            targetInputRef.current?.focus();
        } else {
            completedInputRef.current?.focus();
        }

        if (!editingId) {
            updateTimeline(
                {
                    orgId: selectedOrganizationId!,
                    dealId,
                    timelineId: id,
                    patchData: { [field]: newDate },
                },
                {
                    onSuccess: () => {
                        queryClient.invalidateQueries({ queryKey: ['timelines', selectedOrganizationId!, dealId] });
                    },
                    onError: () => {
                        showError('Failed to update date');
                        setTimelineData(originalData);
                    },
                },
            );
        }
    };

    const onUpdateSuccess = (updatedTimeline: any) => {
        setTimelineData(prevTimeline => prevTimeline.map(item => (item.id === updatedTimeline.id ? { ...item, ...updatedTimeline } : item)));
    };

    const { mutate: updateTimeline } = useUpdateTimeline(onUpdateSuccess);

    const handleDelete = (id: string) => {
        deleteTimeline(
            {
                orgId: selectedOrganizationId!,
                dealId,
                timelineId: id,
            },
            {
                onSuccess: () => {
                    setTimelineData(prevTimeline => prevTimeline.filter(item => item.id !== id));
                    queryClient.invalidateQueries({ queryKey: ['timelines', selectedOrganizationId!, dealId] });
                },
                onError: (error: any) => {
                    console.error(`Failed to delete timeline with ID: ${id}`, error);
                },
            },
        );
    };

    const handleEdit = (id: string) => {
        const timeline = timelineData.find(item => item.id === id);
        if (timeline) {
            setEditingId(id);
            setEditName(timeline.name);

            setTimeout(() => {
                const input = window.document.querySelector<HTMLInputElement>(`#timeline-input-${id}`);
                input?.focus();
            }, 0);
        }
    };

    const cancelEdit = () => {
        setEditingId(null);
        setEditName('');
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (listRef.current && !listRef.current.contains(event.target as Node)) {
                cancelEdit();
                setEditingDateField(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [cancelEdit, setEditingDateField]);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditName(event.target.value);
    };

    const handleRowUpdate = (id: string) => {
        const timeline = timelineData.find(item => item.id === id);
        if (!timeline) return;

        setTimelineData(prevTimeline => prevTimeline.map(item => (item.id === id ? { ...item, name: editName } : item)));
        setEditingId(null);

        const target = timeline.target ? formatDateForAPI(new Date(timeline.target)) : null;
        const completed = timeline.completed ? formatDateForAPI(new Date(timeline.completed)) : null;

        updateTimeline(
            {
                orgId: selectedOrganizationId!,
                dealId,
                timelineId: id,
                patchData: {
                    name: editName,
                    target,
                    completed,
                },
            },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: ['timelines', selectedOrganizationId!, dealId] });
                },
                onError: (error: any) => {
                    console.error(`Failed to update timeline with ID: ${id}`, error);
                },
            },
        );
    };

    const handleRowKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, id: string) => {
        if (event.key === 'Enter') {
            handleRowUpdate(id);
            setEditingDateField(null);
            setEditingId(null);
        } else if (event.key === 'Escape') {
            cancelEdit();
            setEditingDateField(null);
        }
    };

    const isValidDate = (date: Date): boolean => date instanceof Date && !Number.isNaN(date.getTime());

    return (
        <div className="px-2 pb-2">
            <div className="d-flex justify-content-between align-items-center">
                <h5 className="text-white font-16 font-weight-semibold">Due diligence timeline</h5>
                <div>
                    <NSButton
                        icon={faArrowUpRightFromSquare}
                        color="link"
                        className="text-primary px-0"
                        callback={() => navigate('/settings')}
                    >
                        <span className="ml-1">Manage template</span>
                    </NSButton>
                </div>
            </div>
            <div ref={listRef} className="TimelineTable__container">
                <NSTable className="TimelineTable" hover>
                    <thead>
                        <tr>
                            <th>Activity</th>
                            <th>Target</th>
                            <th>Date completed</th>
                            <th>Status</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        <RenderIf isTrue={timelineData?.length > 0} isLoading={isLoadingTimelines}>
                            {timelineData?.map(item => (
                                <tr key={item.id}>
                                    <td>
                                        <RenderIf isTrue={editingId === item.id}>
                                            <NSInput
                                                type="text"
                                                name={`timeline-input-${item.id}`}
                                                id={`timeline-input-${item.id}`}
                                                value={editName}
                                                onChange={handleNameChange}
                                                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleRowKeyPress(e, item.id)}
                                            />
                                        </RenderIf>
                                        <RenderIf isTrue={editingId !== item.id}>
                                            <span
                                                onClick={() => handleEdit(item.id)}
                                                onKeyDown={e => e.key === 'Enter' && handleEdit(item.id)}
                                                role="button"
                                                tabIndex={0}
                                                className="cursor--pointer"
                                            >
                                                {item.name}
                                            </span>
                                        </RenderIf>
                                    </td>
                                    <td>
                                        <RenderIf isTrue={editingId === item.id}>
                                            <input
                                                ref={targetInputRef}
                                                type="text"
                                                value={formatDate(item.target) || ''}
                                                onClick={() => handleDateEdit(item.id, 'target')}
                                                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleRowKeyPress(e, item.id)}
                                                className="form-control"
                                                readOnly
                                            />
                                        </RenderIf>
                                        <RenderIf isTrue={editingId !== item.id && !!item.target}>
                                            <span
                                                onClick={() => handleDateEdit(item.id, 'target')}
                                                onKeyDown={e => e.key === 'Enter' && handleDateEdit(item.id, 'target')}
                                                role="button"
                                                tabIndex={0}
                                                className="cursor--pointer text-primary"
                                            >
                                                {formatDate(item.target)}
                                            </span>
                                        </RenderIf>
                                        <RenderIf isTrue={editingId !== item.id && !item.target}>
                                            <FontAwesomeIcon
                                                icon={faCalendarCirclePlus}
                                                size="lg"
                                                className="text-primary cursor--pointer"
                                                data-testid="calendar-icon"
                                                aria-label="Add target date"
                                                onClick={() => handleDateEdit(item.id, 'target')}
                                            />
                                        </RenderIf>
                                        <RenderIf isTrue={editingDateField?.id === item.id && editingDateField.field === 'target'}>
                                            <div className="TimelineTable__datepicker">
                                                <DatePicker
                                                    selected={item.target ? new Date(item.target) : null}
                                                    onChange={date => date && handleDateChange(item.id, 'target', date)}
                                                    inline
                                                    onClickOutside={cancelDateEdit}
                                                />
                                            </div>
                                        </RenderIf>
                                    </td>
                                    <td>
                                        <RenderIf isTrue={editingId === item.id}>
                                            <input
                                                ref={completedInputRef}
                                                type="text"
                                                value={formatDate(item.completed) || ''}
                                                onClick={() => handleDateEdit(item.id, 'completed')}
                                                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleRowKeyPress(e, item.id)}
                                                className="form-control"
                                                readOnly
                                            />
                                        </RenderIf>
                                        <RenderIf isTrue={editingId !== item.id && !!item.completed}>
                                            <span
                                                onClick={() => handleDateEdit(item.id, 'completed')}
                                                onKeyDown={e => e.key === 'Enter' && handleDateEdit(item.id, 'completed')}
                                                role="button"
                                                tabIndex={0}
                                                className="cursor--pointer text-primary"
                                            >
                                                {formatDate(item.completed)}
                                            </span>
                                        </RenderIf>
                                        <RenderIf isTrue={editingId !== item.id && !item.completed}>
                                            <FontAwesomeIcon
                                                icon={faCalendarCirclePlus}
                                                size="lg"
                                                className="text-primary cursor--pointer"
                                                data-testid="calendar-icon"
                                                aria-label="Add completed date"
                                                onClick={() => handleDateEdit(item.id, 'completed')}
                                            />
                                        </RenderIf>
                                        <RenderIf isTrue={editingDateField?.id === item.id && editingDateField.field === 'completed'}>
                                            <div className="TimelineTable__datepicker">
                                                <DatePicker
                                                    selected={item.completed ? new Date(item.completed) : null}
                                                    onChange={date => date && handleDateChange(item.id, 'completed', date)}
                                                    inline
                                                    onClickOutside={cancelDateEdit}
                                                />
                                            </div>
                                        </RenderIf>
                                    </td>
                                    <td>
                                        <p className={`badge-status--${item.status.toLowerCase()}`}>{capitalize(item.status)}</p>
                                    </td>
                                    <td>
                                        <ActionMenu onView={() => handleRowEdit(item.id)} onDelete={() => handleDelete(item.id)} />
                                    </td>
                                </tr>
                            ))}
                        </RenderIf>
                    </tbody>
                </NSTable>
            </div>
        </div>
    );
};

export default Timeline;
