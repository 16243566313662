import React from 'react';
import RenderIf from 'components/RenderIf/RenderIf';
import { formatUSDCurrencyTwoDigits } from 'ns_libs/formatter';
import { calculateBOEValues, calculateIterativeValues } from '../SlideoutForm/helpers';
import { BOESlideoutFormValues } from '../SlideoutForm/types';

export function calculateLoanValue(loanBOEValues: BOESlideoutFormValues): number {
    const {
        netOperatingIncome, exitValuation, calculateLoanAmount, hardCostsTotal, softCostsTotal,
    } = calculateBOEValues(loanBOEValues);

    const { loanAmount } = calculateIterativeValues(loanBOEValues, hardCostsTotal, softCostsTotal, netOperatingIncome, exitValuation, calculateLoanAmount);
    return loanAmount;
}

export function loanRowByType(amount?: number, maxAmount?: number) {
    const isMax = amount !== undefined && amount !== 0 && amount === maxAmount;
    return (
        <div className={`LoanCard-row-box ${isMax ? 'LoanCard-row-box__highlight' : ''}`}>
            <RenderIf isTrue={amount === undefined || amount === 0}>
                <div className="LoanCard-row__italic">Not yet calculated</div>
            </RenderIf>
            <RenderIf isTrue={amount !== undefined && amount !== 0}>
                <div className="LoanCard-row">{formatUSDCurrencyTwoDigits(amount as number)}</div>
            </RenderIf>
        </div>
    );
}
