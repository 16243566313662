import React from 'react';
import { IReportBuilderReport, NEW_REPORT_LABELS, ReportTypes } from 'views/ReportBuilder/constants';
import ReportDetailCard from '../components/ReportDetailCard/ReportDetailCard';

export interface IProps {
    report: IReportBuilderReport;
    setNewReports: React.Dispatch<React.SetStateAction<IReportBuilderReport[]>>;
}
const DealBOEDetailCard = ({ report, setNewReports }: IProps) => {
    const handleEdit = () => {
        setNewReports(prevReports => {
            const reportIndex = prevReports.findIndex(r => r.reportType === ReportTypes.DEAL_BACK_OF_EVENLOPE);

            if (reportIndex === -1) {
                return prevReports;
            }

            const updatedReports = [...prevReports];
            updatedReports[reportIndex] = {
                ...prevReports[reportIndex],
                isEditing: true,
            };
            return updatedReports;
        });
    };

    const handleDelete = () => {
        setNewReports(prevReports => {
            const reportIndex = prevReports.findIndex(r => r.reportType === ReportTypes.DEAL_BACK_OF_EVENLOPE);

            if (reportIndex === -1) {
                return prevReports;
            }

            const updatedReports = [...prevReports];
            updatedReports.splice(reportIndex, 1);
            return updatedReports;
        });
    };

    return (
        <ReportDetailCard header={NEW_REPORT_LABELS.DEAL_BACK_OF_EVENLOPE} handleEdit={handleEdit} handleDelete={handleDelete}>
            <p className="mb-0">Scenarios</p>
            {report.selectedBOEScenarioNames?.map(scenario => (
                <p key={scenario} className="mb-0 mt-1 text-white">
                    {scenario}
                </p>
            ))}
            <p className="mb-0 mt-3">Include printed on date</p>
            <p className="mb-0 mt-1 text-white">{report.includePrintedOnDate ? 'Yes' : 'No'}</p>
        </ReportDetailCard>
    );
};

export default DealBOEDetailCard;
