import React, { useEffect } from 'react';
import './Expenses.scss';
import { NSDropdown, NSInput, NSLabel } from 'bricks';
import { ProFormaModelIds } from 'constants/proForma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollar } from '@fortawesome/pro-regular-svg-icons';
import { faLeftRight } from '@fortawesome/free-solid-svg-icons';
import { COST_BASIS_OPTIONS } from 'views/DealDashboard/BOE/components/SlideoutForm/constants';
import classNames from 'classnames';
import { IExpense } from 'views/ProFormaWizard/BasicInformation/hooks/useExpenses';
import ExpensesDetail from '../ExpensesDetail/ExpensesDetail';
import { IExpenses } from '../../types';

export interface IExpensesProps {
    values: IExpenses;
    proFormaModelId: number;
    dealUnits?: number;
    dealSF?: number;
    onChange: (key: string, value: number | string) => void;
    onDetailChange: (updatedExpenses: IExpense[]) => void;
    initialExpenses: IExpense[];
    showExpenseDetail: boolean;
    setShowExpenseDetail: (showExpenseDetail: boolean) => void;
}

const Expenses = ({
    values, proFormaModelId, dealUnits, dealSF, onChange, onDetailChange, initialExpenses, showExpenseDetail, setShowExpenseDetail,
}: IExpensesProps) => {
    const filteredUnits = proFormaModelId === ProFormaModelIds.APARTMENT ? COST_BASIS_OPTIONS : COST_BASIS_OPTIONS.filter(unit => unit.value === 'sf');

    const updateCosts = (
        type: 'expenses' | 'totalExpenses',
        newValue: number,
        relatedType: 'expenses' | 'totalExpenses',
        multiplierType: 'expensesType',
    ) => {
        const multiplier = values[multiplierType] === 'units' ? dealUnits : dealSF;
        if (type.includes('total')) {
            onChange(type, newValue);
            onChange(relatedType, newValue / (multiplier ?? 1));
        } else {
            onChange(type, newValue);
            onChange(relatedType, newValue * (multiplier ?? 1));
        }
    };

    useEffect(() => {
        updateCosts('expenses', values.expenses, 'totalExpenses', 'expensesType');
    }, [values.expensesType]);

    return (
        <div className="w-100">
            <div className="Expenses__title">Expenses</div>
            <div className="Expenses__subtitle">Overview of this investment’s total expenses</div>
            <div
                className={classNames([
                    'd-flex flex-row align-items-center w-50 pb-3',
                    {
                        'Expenses__inputs-container--disabled': showExpenseDetail,
                    },
                ])}
            >
                <NSLabel className="text-dark font-weight-normal pt-1 mr-4" htmlFor="hardCosts">
                    Expenses
                </NSLabel>
                <NSInput
                    id="expenses"
                    dataTestId="expenses"
                    type="number"
                    name="expenses"
                    placeholder="Enter expenses"
                    disabled={showExpenseDetail}
                    prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                    value={values.expenses}
                    onChange={(e: { target: { value: number } }) => updateCosts('expenses', Number(e.target.value), 'totalExpenses', 'expensesType')}
                    appendInputAddon={(
                        <NSDropdown
                            options={filteredUnits}
                            selectedOption={filteredUnits.find(option => option.value === values.expensesType)}
                            customSelectedOptionText={filteredUnits.find(option => option.value === values.expensesType)?.label}
                            handleSelectOption={option => onChange('expensesType', option.value)}
                            badgeColor=""
                            isBadgeToggle
                            toggleClassName="p-0 m-0"
                        />
                    )}
                />
                <FontAwesomeIcon icon={faLeftRight} className="mx-2" />
                <NSInput
                    id="totalHardCosts"
                    dataTestId="total-expenses"
                    type="number"
                    name="totalHardCosts"
                    disabled={showExpenseDetail}
                    value={values.totalExpenses}
                    onChange={(e: { target: { value: number } }) => updateCosts('totalExpenses', Number(e.target.value), 'expenses', 'expensesType')}
                    prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                    appendInputAddon={<div>Total</div>}
                />
            </div>
            <ExpensesDetail
                initialValues={initialExpenses}
                proFormaModelId={proFormaModelId}
                showExpenseDetail={showExpenseDetail}
                setShowExpenseDetail={setShowExpenseDetail}
                onChange={onDetailChange}
            />
        </div>
    );
};

export default Expenses;
