import React, { useMemo } from 'react';
import {
    NSButton,
    NSDropdown, NSLabel,
} from 'bricks';
import NSModal, { INSModalProps } from 'bricks/NSModal/NSModal';
import { IDealDirectory, IndexedFile } from 'views/DocumentLibrary/types';
import { extractDirectories, getFileIcon, normalizeFileFormat } from 'views/DocumentLibrary/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import DropzoneCard from '../DropzoneCard/DropzoneCard';
import './UploadDocumentModal.scss';

export interface IUploadDocumentLibrary extends INSModalProps {
    onConfirm: (folder: number) => void;
    directoryTree?: IDealDirectory
    getFilesFromEvent: (event: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>, dropLocation: string) => Promise<File[]>;
    filesToUpload: IndexedFile[];
    setFilesToUpload: (files: IndexedFile[]) => void;
    selectedDirectoryId: number;
    setSelectedDirectoryId: (id: number) => void;
}

const UploadDocumentLibrary = ({
    onConfirm, directoryTree, getFilesFromEvent, filesToUpload, setFilesToUpload, selectedDirectoryId, setSelectedDirectoryId, ...props
}: IUploadDocumentLibrary) => {
    const handleSave = () => {
        onConfirm(selectedDirectoryId);
        setSelectedDirectoryId(0);
    };

    const directoryOptions = useMemo(() => {
        if (directoryTree) {
            return extractDirectories(directoryTree, true);
        }
        return [];
    }, [directoryTree]);

    const handleCancel = () => {
        props.toggle();
    };

    const removeFile = (id: string) => {
        setFilesToUpload(filesToUpload.filter(file => file.id !== id));
    };

    const FilesToUpload = (id: string, { type, name }: File) => (
        <div className="UploadDocumentModal__file d-flex my-2 px-2 rounded" key={id}>
            <FontAwesomeIcon
                icon={getFileIcon({ fileFormat: normalizeFileFormat(type), isExpanded: false })}
                className="text-white mr-2"
            />
            <span className="my-auto">{name}</span>
            <NSButton
                className="cursor-pointer"
                icon={faClose}
                callback={() => removeFile(id)}
            />
        </div>
    );

    return (
        <NSModal
            {...props}
            modalHeaderText="Upload file"
            modalHeaderClassName="bg-primary text-white"
            modalFooterButtonText="Confirm"
            modalFooterCancelButtonText="Cancel"
            modalFooterButtonColor="primary"
            modalFooterButtonFunction={handleSave}
            className="UploadDocumentModal"
            customFooter={(
                <div>
                    <NSButton text="Cancel" callback={handleCancel} outline />
                    <NSButton className="mx-2" color="primary" text="Confirm" callback={handleSave} />
                </div>
            )}
        >
            <div className="pt-2">
                <DropzoneCard getFilesFromEvent={getFilesFromEvent} accept="*" />
                {filesToUpload.map(({ id, file }) => (
                    FilesToUpload(id, file)
                ))}
            </div>
            <div className="separator mb-2" />
            <NSLabel>Destination</NSLabel>
            <NSDropdown
                options={directoryOptions || []}
                selectedOption={directoryOptions?.find(f => f.value === selectedDirectoryId)}
                handleSelectOption={option => setSelectedDirectoryId(option.value)}
                placeholder="Select folder"
                isFullWidth
            />
        </NSModal>
    );
};

export default UploadDocumentLibrary;
