import { useMemo, useRef, useState } from 'react';
import {
    parseISO, addMonths, subMonths, format,
} from 'date-fns';
import { ChartData, ChartOptions } from 'chart.js';
import { formatDate } from 'ns_libs/formatter';
import {
    COLORS, LIGHT_MODE_COLORS, DEAL_ROW_COLORS, NUM_TICKS_X_AXIS_PADDING,
} from './constants';

export interface UseTimelineChartDataParams<T extends Record<string, any>> {
    data: T[];
    scatterXFields: {
        name: keyof T;
        label: string;
    }[];
    barXFields: {
        start: keyof T;
        end: keyof T;
        label: string;
    };
    yField: keyof T;
    hideYAxisLabels?: boolean;
    lightMode?: boolean;
}

const useTimelineChartData = <T extends Record<string, any>>(
    {
        data, scatterXFields, barXFields, yField, hideYAxisLabels = false, lightMode = false,
    }: UseTimelineChartDataParams<T>,
) => {
    const chartRef = useRef(null);
    const [isChartReady, setIsChartReady] = useState(false);
    const dateRange = useMemo(() => {
        if (!data.length) {
            return { minDate: undefined, maxDate: undefined, totalMonths: 0 };
        }

        let minDate: Date | null = null;
        let maxDate: Date | null = null;

        data.forEach(item => {
            const dateFields = [
                ...scatterXFields.map(field => item[field.name]), item[barXFields.start], item[barXFields.end],
            ].filter(Boolean); // Remove null or undefined values

            dateFields.forEach(dateStr => {
                if (!dateStr) return;
                const date = parseISO(dateStr);
                if (!Number.isNaN(date.getTime())) {
                    if (!minDate || date < minDate) minDate = date;
                    if (!maxDate || date > maxDate) maxDate = date;
                }
            });
        });

        // Ensure minDate and maxDate are valid Dates
        const paddedMin: Date | null = minDate ? subMonths(minDate, NUM_TICKS_X_AXIS_PADDING) : null;
        const paddedMax: Date | null = maxDate ? addMonths(maxDate, NUM_TICKS_X_AXIS_PADDING) : null;

        // Ensure they are valid before using `.getFullYear()` and `.getMonth()`
        if (paddedMin instanceof Date && paddedMax instanceof Date) {
            const yearDifference = paddedMax.getFullYear() - paddedMin.getFullYear();
            const yearDifferenceScaled = yearDifference * 12;
            const monthDifference = paddedMax.getMonth() - paddedMin.getMonth();
            const totalMonths = yearDifferenceScaled + monthDifference;

            return { minDate: paddedMin.getTime(), maxDate: paddedMax.getTime(), totalMonths };
        }

        return { minDate: undefined, maxDate: undefined, totalMonths: 0 }; // Fallback return
    }, [data]);

    const scatterData = data.flatMap((row, rowIdx) => scatterXFields.map(field => ({ date: row[field.name], label: field.label }))
        .filter(r => r.date && r.label)
        .map(r => ({
            x: typeof r.date === 'string' ? parseISO(r.date).getTime() : null,
            y: row[yField],
            label: r.label,
            backgroundColor: DEAL_ROW_COLORS[rowIdx % DEAL_ROW_COLORS.length].solid,
            borderColor: DEAL_ROW_COLORS[rowIdx % DEAL_ROW_COLORS.length].solid,
        })));

    const barData = data
        .filter(row => row[barXFields.start] && row[barXFields.end])
        .map((row, rowIdx) => ({
            x: [parseISO(row[barXFields.start]), parseISO(row[barXFields.end])],
            y: row[yField],
            label: barXFields.label,
            backgroundColor: DEAL_ROW_COLORS[rowIdx % DEAL_ROW_COLORS.length].transparent,
            borderColor: DEAL_ROW_COLORS[rowIdx % DEAL_ROW_COLORS.length].transparent,
        }));

    const chartData: ChartData<'bar' | 'scatter'> = {
        datasets: [
            {
                type: 'scatter' as const,
                label: 'Milestone',
                data: scatterData as any,
                pointRadius: 4,
                backgroundColor: scatterData.map(d => d.backgroundColor),
                borderColor: scatterData.map(d => d.borderColor),
                datalabels: {
                    display: false,
                    formatter: (value: any, context: any) => context.dataset.data[context.dataIndex].label || '',
                    color: lightMode ? LIGHT_MODE_COLORS.barBorder : 'white',
                    font: { size: 12 },
                    anchor: 'end',
                    align: (context: any) => (context.dataIndex % 2 === 0 ? 'top' : 'bottom'),
                    offset: (context: any) => (context.dataIndex % 2 !== 0 ? 10 : 2),
                },
            },
            {
                type: 'bar' as const,
                label: barXFields.label,
                data: barData as any,
                borderWidth: 1,
                borderSkipped: false,
                borderRadius: 5,
                backgroundColor: barData.map(d => d.backgroundColor),
                borderColor: barData.map(d => d.borderColor),
                barPercentage: 0.3,
                categoryPercentage: 1,
                datalabels: {
                    display: true,
                    formatter: (value: any, context: any) => context.dataset.data[context.dataIndex].label || '',
                    color: (context: any) => (lightMode ? 'white' : DEAL_ROW_COLORS[context.dataIndex % DEAL_ROW_COLORS.length].solid),
                    font: { size: 12 },
                    anchor: 'center',
                    align: 'center',
                },
            },
        ],
    };

    const options: ChartOptions<any> = {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        animation: {
            onComplete: () => setIsChartReady(true),
        },
        plugins: {
            datalabels: { clip: true },
            legend: { display: false }, // hide default y-axis legend
            chartAreaBackground: { color: lightMode ? LIGHT_MODE_COLORS.chartAreaBackground : COLORS.chartAreaBackground },
            yAxisHeader: { lightMode },
            xAxisYearHeader: { lightMode },
            yAxisBackground: { color: lightMode ? LIGHT_MODE_COLORS.yAxisBackground : COLORS.yAxisBackground },
            xAxisHeaderBackground: {
                color: lightMode ? LIGHT_MODE_COLORS.xAxisHeaderBackground : COLORS.xAxisHeaderBackground,
                yAxisOverlapColor: lightMode ? LIGHT_MODE_COLORS.yAxisOverlap : COLORS.yAxisOverlap,
            },
            tooltip: { // configuration for on hover tooltip
                enabled: true,
                position: 'customPositioner',
                callbacks: {
                    title: (tooltipItem: any) => '', // remove title from tooltip
                    label: (tooltipItem: any) => {
                        const { dataset, dataIndex } = tooltipItem;
                        const dataType = dataset.type;
                        const dataPoint = dataset.data[dataIndex];
                        if (dataType === 'bar') {
                            if (Array.isArray(dataPoint.x) && dataPoint.x.length === 2) {
                                const startDate = formatDate(dataPoint.x[0]);
                                const endDate = formatDate(dataPoint.x[1]);
                                return ` ${dataPoint.label}: ${startDate} - ${endDate}`;
                            }
                        } else if (dataType === 'scatter') {
                            const date = formatDate(dataPoint.x);
                            return ` ${dataPoint.label}: ${date}`;
                        }
                        return dataPoint.label || dataset.label || '';
                    },
                },
            },
        },
        layout: { // chart alignment / padding configuration
            autoPadding: false,
            padding: {
                top: 40,
                right: 0,
                left: 0,
                bottom: 0,
            },
        },
        scales: {
            x: {
                type: 'time' as const,
                min: dateRange.minDate,
                max: dateRange.maxDate,
                position: 'top',
                grid: { color: lightMode ? LIGHT_MODE_COLORS.xAxisTickColor : COLORS.yAxisBackground, drawBorder: false, drawTicks: false },
                ticks: {
                    callback: (value: any) => format(new Date(value), '  MM'),
                    color: lightMode ? LIGHT_MODE_COLORS.xAxisTickColor : COLORS.xAxisTickColor,
                    align: 'start',
                    font: { size: 10 },
                    padding: 0,
                    autoSkip: true,
                    maxTicksLimit: dateRange.totalMonths || 12,
                },
            },
            y: {
                type: 'category' as const,
                labels: hideYAxisLabels ? [] : data.map(item => item[yField]),
                display: !hideYAxisLabels,
                grid: { display: false },
                ticks: { color: lightMode ? LIGHT_MODE_COLORS.yAxisTickColor : COLORS.yAxisTickColor, padding: 10 },
                categoryPercentage: 1,
            },
        },
    };

    return {
        chartData, options, isChartReady, chartRef,
    };
};

export default useTimelineChartData;
