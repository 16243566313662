import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useAuthContext } from 'contexts/AuthContext';
import { NSLabel, NSModal } from 'bricks';
import useToast from 'hooks/useToast';
import { useDeleteDealGroup } from 'views/Deals/hooks/useDeleteDealGroup';

export interface IDeleteDealGroupModalProps {
    toggle: () => void;
    isOpen: boolean;
    dealGroupId?: number;
    dealName: string | null;
    onDeleteSuccess?: () => void;
}

const DeleteDealGroupModal = ({
    toggle, isOpen, dealGroupId, dealName, onDeleteSuccess,
}: IDeleteDealGroupModalProps) => {
    const { selectedOrganizationId } = useAuthContext();

    const { mutate: deleteDealGroup, error: deleteError } = useDeleteDealGroup();
    const queryClient = useQueryClient();
    const { showSuccess, showError } = useToast();

    const handleDelete = () => {
        if (!dealGroupId) {
            showError('Please select a group.');
            return;
        }
        deleteDealGroup(
            { organizationId: selectedOrganizationId!, dealGroupId },
            {
                onSuccess: () => {
                    showSuccess(`Deal group: ${dealName} deleted successfully.`);
                    onDeleteSuccess?.();
                    queryClient.invalidateQueries({ queryKey: ['dealGroups', selectedOrganizationId!] });
                    toggle();
                },
                onError: error => {
                    showError(deleteError?.message || 'Failed to delete deal group. Please try again.');
                },
            },
        );
    };

    return (
        <NSModal
            toggle={toggle}
            isOpen={isOpen}
            modalHeaderClassName="bg-danger modal-colored-header"
            modalHeaderText="Delete group"
            modalFooterButtonText="Confirm"
            modalFooterIsShown
            modalFooterButtonFunction={handleDelete}
            modalFooterButtonColor="danger"
        >
            <div className="d-flex flex-column">
                <NSLabel className="text-dark font-weight-normal pt-1 mb-0">
                    Are you sure you want to delete the group
                    <span className="font-weight-bold">
                        {' '}
                        {dealName}
                        {' '}
                    </span>
                    ?
                </NSLabel>
                <h6 className="text-dark font-weight-normal mt-1">
                    Note: this will
                    {' '}
                    <span className="font-weight-bold"> not </span>
                    {' '}
                    delete the deals inside of this group.
                </h6>
            </div>
        </NSModal>
    );
};

export default DeleteDealGroupModal;
