import React from 'react';
import { NSButton, NSCard } from 'bricks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ProFormaExitValuationCard.scss';
import { faChartMixedUpCircleDollar, faMagnifyingGlassDollar } from '@fortawesome/pro-solid-svg-icons';

interface ProFormaExitValuationCardProps {
    exitValuation: number;
    leveredIRR: number;
    leveredEMx: number;
    id?: string;
    status?: 'best' | 'good' | 'danger' | 'warning';
}

const ProFormaExitValuationCard: React.FC<ProFormaExitValuationCardProps> = ({
    exitValuation, leveredIRR, leveredEMx, id, status = 'success',
}) => {
    const formatCurrency = (value: number) => new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(value);

    const formatPercentage = (value: number) => `${value.toFixed(2)}%`;

    const formatMultiplier = (value: number) => `${value.toFixed(2)}x`;

    const handleCreateProForma = () => {
        if (id) {
            console.log('Creating pro forma for id:', id);
        }
    };

    return (
        <div className={`ProFormaExitValuation ProFormaExitValuation--${status}`}>
            <NSCard className="NSCard--level-4 p-2">
                <div className="ProFormaExitValuation__valuation-container ProFormaExitValuation__valuation-container--primary">
                    <div className="ProFormaExitValuation__card-title">EXIT VALUATION</div>
                    <div className="ProFormaExitValuation__card-valuation text-white">{formatCurrency(exitValuation)}</div>
                </div>

                <div className="d-flex">
                    <div className="ProFormaExitValuation__valuation-container mr-1">
                        <FontAwesomeIcon icon={faMagnifyingGlassDollar} className="ProFormaExitValuation__floating-dollar" />
                        <div className="ProFormaExitValuation__card-title">Levered IRR</div>
                        <div className="ProFormaExitValuation__card-subtitle text-white">{formatPercentage(leveredIRR)}</div>
                    </div>
                    <div className="ProFormaExitValuation__valuation-container">
                        <FontAwesomeIcon size="xl" icon={faChartMixedUpCircleDollar} className="ProFormaExitValuation__floating-chart" />
                        <div className="ProFormaExitValuation__card-title">Levered EMx</div>
                        <div className="ProFormaExitValuation__card-subtitle text-white">{formatMultiplier(leveredEMx)}</div>
                    </div>
                </div>
                <div className="ProFormaExitValuation__separator" />
                <NSButton
                    className="d-flex w-100 justify-content-center"
                    callback={handleCreateProForma}
                    color="primary"
                    text="Create pro forma"
                    outline
                />
            </NSCard>
        </div>
    );
};

export default ProFormaExitValuationCard;
