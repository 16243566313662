import React, { useEffect, useState } from 'react';
import {
    NSInput, NSSelect, NSMultiSelect, NSCard,
} from 'bricks';
import { IOptionAnyValue } from 'bricks/types';
import RenderIf from 'components/RenderIf/RenderIf';
import { FOLDER_OPTIONS, DOCUMENT_TYPES, ACTIVITIES } from './constants';
import './DocumentLibrarySlideout.scss';

interface DocumentDetailsProps {
    editMode: boolean;
    documentName: string;
    size?: string;
    format?: string;
    folder?: string;
    dateModified?: string;
    documentType?: string;
    activities?: string[];
    onChange: (field: string, value: any) => void;
}

const DocumentDetails: React.FC<DocumentDetailsProps> = ({
    editMode,
    documentName,
    size,
    format,
    folder,
    dateModified,
    documentType,
    activities,
    onChange,
}) => {
    const [selectedFolder, setSelectedFolder] = useState<IOptionAnyValue>();
    const [selectedType, setSelectedType] = useState<IOptionAnyValue>();
    const [selectedActivities, setSelectedActivities] = useState<IOptionAnyValue[]>([]);

    useEffect(() => {
        setSelectedFolder(FOLDER_OPTIONS.find(option => option.value === folder));
        setSelectedType(DOCUMENT_TYPES.find(option => option.value === documentType));
        setSelectedActivities(ACTIVITIES.filter(option => activities && activities.includes(option.value)) || []);
    }, [folder, documentType, activities]);

    return (
        <div className="Content__overview p-2">
            <NSCard className="NSCard--level-3 p-2">
                <RenderIf isTrue={editMode}>
                    <NSInput
                        name="name"
                        type="text"
                        label="Name"
                        value={documentName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange('documentName', e.target.value)}
                    />

                    <div className="mt-2 text-white">Folder</div>
                    <NSSelect
                        options={FOLDER_OPTIONS}
                        name="folder"
                        value={selectedFolder}
                        onChange={option => {
                            setSelectedFolder(option);
                            onChange('folder', option.value);
                        }}
                        isClearable={false}
                    />

                    <div className="mt-2 text-white">Type</div>
                    <NSSelect
                        options={DOCUMENT_TYPES}
                        name="documentType"
                        value={selectedType}
                        onChange={option => {
                            setSelectedType(option);
                            onChange('documentType', option.value);
                        }}
                        isClearable={false}
                    />

                    <div className="mt-2 text-white">Activities</div>
                    <NSMultiSelect
                        fixedPlaceholder="Search to add activities"
                        options={ACTIVITIES}
                        selectName="activities"
                        value={selectedActivities}
                        onChange={value => {
                            setSelectedActivities(value);
                            onChange('activities', value.map(item => item.value));
                        }}
                    />
                </RenderIf>
                <RenderIf isTrue={!editMode}>
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className="text-white">Overview</h4>
                    </div>
                    <div className="d-flex flex-column">
                        <div className="d-flex mt-1">
                            <span className="text-muted mr-1">Name:</span>
                            <span className="text-dark">{documentName}</span>
                        </div>
                        <div className="d-flex mt-1">
                            <span className="text-muted mr-1">Format:</span>
                            <span className="text-dark">{format}</span>
                        </div>
                        <div className="d-flex mt-1">
                            <span className="text-muted mr-1">Size:</span>
                            <span className="text-dark">{size}</span>
                        </div>
                        <div className="d-flex mt-1">
                            <span className="text-muted mr-1">Folder:</span>
                            <span className="text-dark">{folder}</span>
                        </div>
                        <div className="d-flex mt-1">
                            <span className="text-muted mr-1">Type:</span>
                            <span className="text-dark">{documentType || '-'}</span>
                        </div>
                        <div className="d-flex mt-1">
                            <span className="text-muted mr-1">Activities/tasks:</span>
                            <div className="d-flex flex-column">
                                {activities && activities.length > 0 ? activities.map(task => (
                                    <>
                                        <span key={task} className="text-dark">{task}</span>
                                        {'\n'}
                                    </>
                                )) : '-'}

                            </div>
                        </div>
                        <div className="d-flex mt-2">
                            <span className="text-muted mr-1">Last modified:</span>
                            <span className="text-dark">{dateModified}</span>
                        </div>
                    </div>
                </RenderIf>
            </NSCard>
        </div>
    );
};

export default DocumentDetails;
