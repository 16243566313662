import axiosInstance from 'ns_libs/nsAxios';
import { useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import { IProjectSourcesActuals } from 'views/ProFormaTable/types';

interface IProjectSourcesActualsProps {
    projectUuid: string;
}

const getProjectSourcesActuals = async ({ projectUuid }: IProjectSourcesActualsProps): Promise<IProjectSourcesActuals> => {
    const response = await axiosInstance.get(API_ENDPOINTS.PROJECT_SOURCES_ACTUALS(projectUuid));
    return response.data;
};

const useGetProjectSourcesActuals = (projectUuid: string, options: { enabled: boolean }) => useQuery({
    queryKey: ['projectSourcesActuals'],
    queryFn: () => getProjectSourcesActuals({ projectUuid }),
    ...options,
});

export default useGetProjectSourcesActuals;
