import React from 'react';
import RenderIf from 'components/RenderIf/RenderIf';
import { DefaultAttributes } from '../../types';
import DefaultAttributeInput from '../DefaultAttributeInput';
import { useGetAttributes } from '../../hooks/useGetAttributes';

interface UnitsSectionProps {
    showRequiredOnly: boolean;
}

const UnitsSection: React.FC<UnitsSectionProps> = ({
    showRequiredOnly,
}) => {
    const { data: attributesConfig } = useGetAttributes();

    const getAttributeIsRequired = (field: DefaultAttributes): boolean => {
        const attribute = attributesConfig?.find(({ name }) => name === field);
        return attribute?.required || false;
    };

    const shouldRender = !showRequiredOnly || (showRequiredOnly && getAttributeIsRequired(DefaultAttributes.UNITS));

    return (
        <RenderIf isTrue={shouldRender}>
            <div className="NSCard--level-3 mt-2 py-2 px-3 rounded">
                <div className="text-dark" data-testid="units-section-title">Units</div>
                <div className="CreateDeal__form__divider_muted mt-1 mb-2" />
                <div className="d-flex flex-wrap">
                    <DefaultAttributeInput
                        label="[Built] GSF"
                        name="grossBuildSf"
                        type="number"
                        placeholder="Enter value"
                        attribute={DefaultAttributes.UNITS}
                        showRequiredOnly={showRequiredOnly}
                        className="w-50 pr-2 mb-2"
                    />
                    <DefaultAttributeInput
                        label="RSF"
                        name="rentableSf"
                        type="number"
                        placeholder="Enter value"
                        attribute={DefaultAttributes.UNITS}
                        showRequiredOnly={showRequiredOnly}
                        className="w-50 pl-2 mb-2"
                    />
                    <DefaultAttributeInput
                        label="Acres"
                        name="acres"
                        type="number"
                        placeholder="Enter value"
                        attribute={DefaultAttributes.UNITS}
                        showRequiredOnly={showRequiredOnly}
                        className="w-50 pr-2 mb-2"
                    />
                    <DefaultAttributeInput
                        label="Residential units"
                        name="residentialUnits"
                        type="number"
                        placeholder="Enter value"
                        attribute={DefaultAttributes.UNITS}
                        showRequiredOnly={showRequiredOnly}
                        className="w-50 pl-2 mb-2"
                    />
                    <DefaultAttributeInput
                        label="Lots"
                        name="lots"
                        type="number"
                        placeholder="Enter value"
                        attribute={DefaultAttributes.UNITS}
                        showRequiredOnly={showRequiredOnly}
                        className="w-50 pr-2 mb-2"
                    />
                    <DefaultAttributeInput
                        label="Beds"
                        name="beds"
                        type="number"
                        placeholder="Enter value"
                        attribute={DefaultAttributes.UNITS}
                        showRequiredOnly={showRequiredOnly}
                        className="w-50 pl-2 mb-2"
                    />
                    <DefaultAttributeInput
                        label="Megawatts"
                        name="megawatts"
                        type="number"
                        placeholder="Enter value"
                        attribute={DefaultAttributes.UNITS}
                        showRequiredOnly={showRequiredOnly}
                        className="w-50 pr-2"
                    />
                </div>
            </div>
        </RenderIf>
    );
};

export default UnitsSection;
