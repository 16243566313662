import React from 'react';
import { useField, useFormikContext } from 'formik';
import { NSInput } from 'bricks';
import RenderIf from 'components/RenderIf/RenderIf';
import { DefaultAttributes } from '../../../types';
import { useGetAttributes } from '../../../hooks/useGetAttributes';

interface CoordinatesInputProps {
    attribute: DefaultAttributes;
    showRequiredOnly: boolean;
    className?: string;
    onCoordinatesChange?: (lat: number, lng: number) => void;
}

const CoordinatesInput: React.FC<CoordinatesInputProps> = ({
    attribute,
    showRequiredOnly,
    className = '',
    onCoordinatesChange,
}) => {
    const { data: attributesConfig } = useGetAttributes();
    const [latField, latMeta] = useField('latitude');
    const [lngField, lngMeta] = useField('longitude');
    const { setFieldValue } = useFormikContext();

    const getAttributeIsRequired = (field: DefaultAttributes): boolean => {
        const attributeConfig = attributesConfig?.find(({ name }) => name === field);
        return attributeConfig?.required || false;
    };

    const isRequired = getAttributeIsRequired(attribute);
    const shouldRender = !showRequiredOnly || (showRequiredOnly && isRequired);

    const handleCoordinateChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setFieldValue(field, value);

        const lat = field === 'latitude' ? Number(value) : Number(latField.value);
        const lng = field === 'longitude' ? Number(value) : Number(lngField.value);

        if (onCoordinatesChange) {
            onCoordinatesChange(lat, lng);
        }
    };

    return (
        <RenderIf isTrue={shouldRender}>
            <div className={`d-flex ${className}`}>
                <div className="w-50 mr-2">
                    <NSInput
                        type="number"
                        label="Latitude"
                        placeholder="Enter latitude"
                        errorMsg={latMeta.touched && latMeta.error ? latMeta.error : null}
                        showErrorMsg={!!(latMeta.touched && latMeta.error)}
                        required={isRequired}
                        {...latField}
                        onChange={handleCoordinateChange('latitude')}
                    />
                </div>
                <div className="w-50">
                    <NSInput
                        type="number"
                        label="Longitude"
                        placeholder="Enter longitude"
                        errorMsg={lngMeta.touched && lngMeta.error ? lngMeta.error : null}
                        showErrorMsg={!!(lngMeta.touched && lngMeta.error)}
                        required={isRequired}
                        {...lngField}
                        onChange={handleCoordinateChange('longitude')}
                    />
                </div>
            </div>
        </RenderIf>
    );
};

export default CoordinatesInput;
