import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { useMutation } from '@tanstack/react-query';
import { IBackOfEnvelopePayload } from '../types';

interface IUpdateVariables {
    patchData: Partial<IBackOfEnvelopePayload>;
    orgId: string;
    scenarioId: string;
}

const updateBackOfEnvelope = async ({ patchData, orgId, scenarioId }: IUpdateVariables): Promise<IBackOfEnvelopePayload> => {
    const response = await axiosInstance.patch(`${API_ENDPOINTS.BACK_OF_ENVELOPE_BY_ID(orgId, scenarioId)}`, patchData);
    return response.data;
};

export const useUpdateBackOfEnvelope = () => useMutation({
    mutationFn: updateBackOfEnvelope,
});
