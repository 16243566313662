import React from 'react';
import classNames from 'classnames';
import NSCellInput from '../../../bricks/NSCellInput/NSCellInput';
import './ProFormaMappingTable.scss';
import { NSTable, NSInput } from '../../../bricks';

export interface Column<T> {
    key: string;
    header: string;
    isInput?: boolean;
    isCellInput?: boolean;
    isHighlighted?: boolean;
    onChange?: (item: T, value: string) => void;
}

interface ProFormaMappingTableProps<T> {
    data: (T & { isHighlighted?: boolean })[];
    columns: Column<T>[];
    className?: string;
    getRowKey?: (item: T, index: number) => string | number;
}

const ProFormaMappingTable = <T extends object>({
    data,
    columns,
    className = '',
    getRowKey = (_, index) => index,
}: ProFormaMappingTableProps<T>) => {
    const renderCell = (column: Column<T>, item: (T & { isHighlighted?: boolean }), index: number) => {
        const hasSheet = Boolean((item as any).sheet?.trim());
        const hasCell = Boolean((item as any).cell?.trim());
        const showError = (hasSheet && !hasCell && column.key === 'cell') || (!hasSheet && hasCell && column.key === 'sheet');

        if (column.isInput) {
            return (
                <NSInput
                    name={`${column.key}-${getRowKey(item, index)}`}
                    type="text"
                    value={(item as any)[column.key]}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => column.onChange?.(item, e.target.value)}
                    placeholder={`${column.header} reference`}
                    customClassName={classNames({ 'border border-danger': showError })}
                />
            );
        } if (column.isCellInput && !item.isHighlighted) {
            return (
                <NSCellInput
                    type="text"
                    value={(item as any)[column.key]}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => column.onChange?.(item, e.target.value)}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => column.onChange?.(item, e.target.value)}
                    placeholder={`${column.header} reference`}
                />
            );
        }
        return (item as any)[column.key];
    };

    return (
        <NSTable className={`ProFormaMappingTable ${className}`}>
            <thead>
                <tr>
                    {columns.map(column => (
                        <th key={column.key}>{column.header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((item, index) => (
                    <tr key={getRowKey(item, index)} className={item.isHighlighted ? 'ProFormaMappingTable--highlighted' : undefined}>
                        {columns.map(column => (
                            <td key={column.key}>
                                {renderCell(column, item, index)}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </NSTable>
    );
};

export default ProFormaMappingTable;
