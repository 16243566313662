import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { useMutation } from '@tanstack/react-query';

interface ICreateReportBuilderReportPostData {
    reportType: string;
    metaData: any;
}

interface ICreateTemplatePostData {
    orgId: string;
    templateName: string;
    reportBuilderReports: ICreateReportBuilderReportPostData[];
}

interface ICreateTemplateParams {
    postData: ICreateTemplatePostData;
    dealId: number;
}

interface IResponse {
    id: number;
    orgId: string;
    dealId: number;
    reportName: string;
}

const createReportBuilderTemplate = async ({ postData, dealId }: ICreateTemplateParams): Promise<IResponse> => {
    try {
        const response = await axiosInstance.post(`${API_ENDPOINTS.REPORT_BUILDER_TEMPLATE(dealId)}`, postData);
        return response.data;
    } catch (error: any) {
        if (error.response?.status !== 500) {
            throw new Error(error.response.data);
        }
        throw new Error('An unexpected error occurred. Please try again.');
    }
};

export const useCreateReportBuilderTemplate = () => useMutation({
    mutationFn: createReportBuilderTemplate,
});

export default useCreateReportBuilderTemplate;
