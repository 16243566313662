export const INITIAL_PROPERTY_RETURNS = [
    {
        id: 'unleveredIRR',
        label: 'Unlevered IRR',
        sheet: '1',
        cell: 'A1',
        isHighlighted: false,
    },
    {
        id: 'leveredIRR',
        label: 'Levered IRR',
        sheet: '1',
        cell: 'A1',
        isHighlighted: false,
    },
];

export const INITIAL_PARTNERSHIP_RETURNS = [
    {
        id: 'limitedPartnerIRR',
        label: 'Limited partner IRR',
        sheet: '1',
        cell: 'A1',
        isHighlighted: false,
    },
    {
        id: 'generalPartnerIRR',
        label: 'General partner IRR',
        sheet: '1',
        cell: 'A1',
        isHighlighted: false,
    },
];

export const INITIAL_DEVELOPMENT_RETURNS = [
    {
        id: 'untrendedYieldOnCost',
        label: 'Untrended yield-on-cost',
        sheet: '1',
        cell: 'A1',
        isHighlighted: false,
    },
    {
        id: 'trendedYieldOnCost',
        label: 'Trended yield-on-cost',
        sheet: '1',
        cell: 'A1',
        isHighlighted: false,
    },
];

export const INITIAL_OPERATIONS = [
    {
        id: 'effectiveGrossRevenue',
        label: 'Effective gross revenue',
        sheet: '1',
        cell: 'A1',
        isHighlighted: false,
    },
    {
        id: 'totalOperatingExpenses',
        label: 'Total operating expenses',
        sheet: '1',
        cell: 'A1',
        isHighlighted: false,
    },
    {
        id: 'netOperatingIncome',
        label: 'Net operating income',
        sheet: '1',
        cell: 'A1',
        isHighlighted: true,
    },
    {
        id: 'capitalExpenditures',
        label: 'Capital expenditures',
        sheet: '1',
        cell: 'A1',
        isHighlighted: false,
    },
    {
        id: 'cashFlowFromOperations',
        label: 'Cash flow from operations',
        sheet: '1',
        cell: 'A1',
        isHighlighted: true,
    },
];

export const INITIAL_VALUATION = {
    sheet: '1',
    cell: 'A1',
};

export const INITIAL_DEVELOPMENT_USES = [
    {
        id: 'acquisitionCosts',
        label: 'Acquisition costs',
        sheet: '1',
        cell: 'A1',
        isHighlighted: false,
    },
    {
        id: 'hardCosts',
        label: 'Hard costs',
        sheet: '1',
        cell: 'A1',
        isHighlighted: false,
    },
    {
        id: 'softCosts',
        label: 'Soft costs',
        sheet: '1',
        cell: 'A1',
        isHighlighted: false,
    },
    {
        id: 'financingCosts',
        label: 'Financing costs',
        sheet: '1',
        cell: 'A1',
        isHighlighted: false,
    },
    {
        id: 'totalUses',
        label: 'Total uses',
        sheet: '1',
        cell: 'A1',
        isHighlighted: true,
    },
];

export const INITIAL_DEVELOPMENT_SOURCES = [
    {
        id: 'constructionLoan',
        label: 'Construction loan',
        sheet: '1',
        cell: 'A1',
        isHighlighted: false,
        isCellInput: true,
    },
    {
        id: 'mezzanineLoan',
        label: 'Mezzanine loan',
        sheet: '1',
        cell: 'A1',
        isHighlighted: false,
        isCellInput: true,
    },
    {
        id: 'limitedPartner',
        label: 'Limited partner',
        sheet: '1',
        cell: 'A1',
        isHighlighted: false,
        isCellInput: true,
    },
    {
        id: 'generalPartner',
        label: 'General partner',
        sheet: '1',
        cell: 'A1',
        isHighlighted: false,
        isCellInput: true,
    },
    {
        id: 'totalSources',
        label: 'Total sources',
        sheet: '1',
        cell: 'A1',
        isHighlighted: true,
    },
];

export const INITIAL_ACQUISITIONS = [
    {
        id: 'aquisitionCosts',
        label: 'Aquisition costs',
        sheet: '1',
        cell: 'A1',
        isHighlighted: false,
    },
];
