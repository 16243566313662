import TimelineChart from 'components/TimelineChart/TimelineChart';
import React from 'react';
import { LABELS, ROW_HEIGHT } from './constants';
import { TimelineData } from '../types';

export interface IProps {
    data: TimelineData[];
    enableScrollView?: boolean;
}

const ExecutiveSummaryTimelineChart = ({ data, enableScrollView = true } : IProps) => (
    <div className="mb-3 px-0">
        <TimelineChart
            data={data}
            scatterXFields={[
                { name: 'analysisStart', label: LABELS.analysisStart },
                { name: 'firstUnitDelivered', label: LABELS.firstUnitDelivered },
                { name: 'constructionStartDate', label: LABELS.constructionPeriod },
            ]}
            barXFields={{
                start: 'constructionStartDate',
                end: 'constructionEndDate',
                label: LABELS.constructionPeriod,
            }}
            enableScrollView={enableScrollView}
            yField="dealName"
            hideYAxisLabels
            rowHeight={ROW_HEIGHT}
        />
    </div>
);

export default ExecutiveSummaryTimelineChart;
