import { useMemo } from 'react';
import { useGetDealDirectoryTree } from './useGetDealDirectoryTree';
import { extractDirectories } from '../helpers';

export const useDealDirectoryOptions = (dealId: number) => {
    const { data: directoryTree } = useGetDealDirectoryTree({ dealId, onlyDir: true });

    const folderLocationOptions = useMemo(() => {
        if (directoryTree) {
            return extractDirectories(directoryTree, true);
        }
        return [];
    }, [directoryTree]);

    return folderLocationOptions;
};
