import React, { useMemo } from 'react';
import { NSCard, NSButton } from 'bricks';
import { CardBody, CardHeader } from 'reactstrap';
import { downloadFileFromUrl } from 'helpers/file/download';
import ReadOnlyWrapper from 'components/ReadOnlyWrapper/ReadOnlyWrapper';
import { useGetProFormaExecutiveSummary } from '../../hooks';
import { getProFormaExecutiveSummaryPdf } from '../../hooks/useGetProFormaExecutiveSummaryPdf';
import { formatReturns, formatOperations, formatDevelopment } from './helpers';
import ExecutiveSummaryDetail from './ExecutiveSummaryDetail/ExecutiveSummaryDetail';
import ExecutiveSummaryQuickStats from './ExecutiveSummaryQuickStats/ExecutiveSummaryQuickStats';
import ExecutiveSummaryGenericTable from './ExecutiveSummaryGenericTable/ExecutiveSummaryGenericTable';
import ExecutiveSummaryTimelineChart from './ExecutiveSummaryTimelineChart/ExecutiveSummaryTimelineChart';

interface IProps {
    hasWriteAccessToProForma: boolean;
    dealId: number;
    proFormaId: number;
}

const ExecutiveSummary: React.FC<IProps> = ({ hasWriteAccessToProForma, dealId, proFormaId }) => {
    const { data } = useGetProFormaExecutiveSummary(dealId, proFormaId, { enabled: !!proFormaId && !!dealId });

    // Memoize the formatted data for performance optimization
    const formattedReturns = useMemo(() => (data ? formatReturns(data.returns) : null), [data]);
    const formattedOperations = useMemo(() => (data ? formatOperations(data.cashFlows) : null), [data]);
    const formattedDevelopment = useMemo(() => (data ? formatDevelopment(data.development) : null), [data]);

    const exportDealOverviewPdf = async () => {
        if (data) {
            const response = await getProFormaExecutiveSummaryPdf(dealId, proFormaId);
            const pdfUrl = response.url;
            await downloadFileFromUrl(pdfUrl, `${data.general.dealName}-executive-summary`, 'pdf', true);
        }
    };

    return (
        <ReadOnlyWrapper isReadOnly={!hasWriteAccessToProForma}>
            <NSCard className="NSCard--level-2 shadow-none">
                {data && (
                    <>
                        <CardHeader>
                            <div className="d-flex align-items-center justify-content-between text-white">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <h4 className="mb-0">Executive Summary</h4>
                                        <h5 className="mb-1 mt-1 text-muted">{data?.details.address}</h5>
                                    </div>
                                </div>
                                <div>
                                    <NSButton color="primary" className="mr-2" outline text="Export" callback={exportDealOverviewPdf} />
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <ExecutiveSummaryDetail details={data.details} />
                            <ExecutiveSummaryTimelineChart data={[data.timelineData]} />
                            <ExecutiveSummaryQuickStats stats={data.keyStats} />
                            {formattedReturns && <ExecutiveSummaryGenericTable title="RETURNS" sections={formattedReturns} />}
                            <div className="mb-2" />
                            {formattedOperations && <ExecutiveSummaryGenericTable title="OPERATIONS" sections={formattedOperations} />}
                            <div className="mb-2" />
                            {formattedDevelopment && <ExecutiveSummaryGenericTable title="DEVELOPMENT" sections={formattedDevelopment} />}
                        </CardBody>
                    </>
                )}
            </NSCard>
        </ReadOnlyWrapper>
    );
};

export default ExecutiveSummary;
