import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { useMutation } from '@tanstack/react-query';
import { IUpdateDocumentRequest, IDocumentResponse } from '../types';

interface IProps {
    data: IUpdateDocumentRequest;
    documentId: number;
}

const updateDocument = async ({ data, documentId }: IProps): Promise<IDocumentResponse> => {
    try {
        const response = await axiosInstance.put(`${API_ENDPOINTS.DOCUMENT_BY_ID(documentId)}`, data);
        return response.data;
    } catch (error: any) {
        if (error.response?.status !== 500) {
            throw new Error(error.response.data);
        }
        throw new Error('An unexpected error occurred. Please try again.');
    }
};

export const useUpdateDocument = () => useMutation({
    mutationFn: updateDocument,
});

export default useUpdateDocument;
