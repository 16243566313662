import React from 'react';
import { Nullable } from 'types/shared';

interface IDetailTextProps {
    label: string;
    value?: Nullable<string | number>;
}

const DetailText = ({ label, value }: IDetailTextProps) => (
    <div className="d-flex justify-content-between mt-1">
        <div className="text-muted">{label}</div>
        <div className="text-dark">{value || ''}</div>
    </div>
);

export default DetailText;
