import React, { useEffect, useMemo, useState } from 'react';
import { NSDropdown, NSModal } from 'bricks';
import { faDown } from '@fortawesome/pro-solid-svg-icons';
import { IOptionAnyValue } from 'bricks/types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetAssetTypes } from 'hooks/useGetAssetTypes';
import { useGetConstructionTypes } from 'hooks/useGetConstructionTypes';
import { useGetMarkets } from 'hooks/useGetMarkets';
import {
    longValueAttributes, FilterType, filterOptionGroups, unitTypeSelectorOptions, AdditionalFieldType, getAdditionalFields,
} from '../constants';
import AdditionalField from './AdditionalField';
import { FieldInput, IFilter } from '../types';
import { AttributeKeysMap, Attributes } from '../../constants';

interface IFilterModalProps {
    toggle: () => void;
    isOpen: boolean;
    saveFilter: (filter: IFilter) => void;
    filter?: IFilter;
    attributeOptions: IOptionAnyValue[];
}

const FilterModal = ({
    filter, toggle, isOpen, saveFilter, attributeOptions,
}: IFilterModalProps) => {
    const [selectedAttribute, setSelectedAttribute] = useState<IOptionAnyValue | undefined>();
    const [selectedFilterOption, setSelectedFilterOption] = useState<IOptionAnyValue | undefined>();
    const [selectedUnitType, setSelectedUnitType] = useState(unitTypeSelectorOptions[0]);
    const [fieldInput, setFieldInput] = useState<FieldInput>(null);

    const { data: assetTypeValuesRaw } = useGetAssetTypes();
    const { data: constructionTypeValuesRaw } = useGetConstructionTypes();
    const { data: marketValuesRaw } = useGetMarkets();

    const assetTypes = assetTypeValuesRaw?.map(({ correlationId, name }) => ({ label: name, value: correlationId })) || [];
    const constructionTypes = constructionTypeValuesRaw?.map(({ correlationId, name }) => ({ label: name, value: correlationId })) || [];
    const markets = marketValuesRaw?.map(({ correlationId, name }) => ({ label: name, value: correlationId })) || [];

    const additionalFields = getAdditionalFields({ assetTypes, constructionTypes, markets });

    useEffect(() => {
        if (isOpen) {
            if (filter) {
                setSelectedAttribute({ label: AttributeKeysMap[filter.attribute], value: filter.attribute });
                setSelectedFilterOption({ label: filter.filterOption, value: filter.filterOption });
                setSelectedUnitType(filter.unitType ? { label: filter.unitType, value: filter.unitType } : unitTypeSelectorOptions[0]);
                setFieldInput(filter.fieldInput);
            } else {
                clearState();
            }
        }
    }, [isOpen]);

    const filterOptions = useMemo(() => {
        const filterGroup = filterOptionGroups.find(group => group.appliedToAttributes.includes(selectedAttribute?.label as Attributes));
        if (filterGroup) {
            return filterGroup.filterOptions.map(filter => ({
                label: filter,
                value: filter,
            }));
        }
        return [];
    }, [selectedAttribute?.label]);

    const additionalField = useMemo(
        () => additionalFields.find(input => {
            const includesAttribute = input.appliedToAttributes.includes(selectedAttribute?.label as Attributes);
            const includesFilter = input.appliedToFilters.includes(selectedFilterOption?.label as FilterType);
            return includesAttribute && includesFilter;
        }),
        [selectedAttribute?.label, selectedFilterOption?.label],
    );

    const isRangeValid = useMemo(() => {
        if (fieldInput && 'from' in fieldInput && 'to' in fieldInput) {
            if (!fieldInput.from || !fieldInput.to) return true;

            if (additionalField?.type === AdditionalFieldType.DateRange) {
                const fromDate = new Date(fieldInput.from);
                const toDate = new Date(fieldInput.to);
                return fromDate.getTime() <= toDate.getTime();
            }

            if (additionalField?.type === AdditionalFieldType.Range) {
                return parseInt(fieldInput.from, 10) <= parseInt(fieldInput.to, 10);
            }
        }
        return true;
    }, [fieldInput, additionalField?.type]);

    const isAddingNewFilter = !filter;

    const isSelectedAttributeUnits = selectedAttribute?.label === Attributes.Units;
    const isLongValueAttribute = longValueAttributes.includes(selectedAttribute?.label as Attributes);
    const isRangeFilter = selectedFilterOption?.label === FilterType.IsBetween;
    const includesWideFields = isLongValueAttribute || isRangeFilter || isSelectedAttributeUnits;

    const isValueValid = fieldInput && 'value' in fieldInput ? !!fieldInput.value : true;
    const isMultiSelectValid = fieldInput && 'options' in fieldInput ? !!fieldInput.options.length : true;
    const isAdditionalFieldValid = additionalField ? fieldInput && isRangeValid && isValueValid && isMultiSelectValid : true;
    const isModalButtonDisabled = !selectedAttribute || !selectedFilterOption || !isAdditionalFieldValid;

    const clearState = () => {
        setSelectedAttribute(undefined);
        setSelectedFilterOption(undefined);
        setFieldInput(null);
    };

    const handleChangeSelectedAttribute = (option: IOptionAnyValue) => {
        setSelectedAttribute(option);
        setSelectedFilterOption(undefined);
        setSelectedUnitType(unitTypeSelectorOptions[0]);
        setFieldInput(null);
    };

    const handleChangeSelectedFilter = (option: IOptionAnyValue) => {
        setSelectedFilterOption(option);
        setSelectedUnitType(unitTypeSelectorOptions[0]);
        setFieldInput(null);
    };

    const handleSaveFilter = () => {
        const newFilter: IFilter = {
            attribute: selectedAttribute?.value,
            filterOption: selectedFilterOption?.label as FilterType,
            fieldInput,
        };
        if (isSelectedAttributeUnits) newFilter.unitType = selectedUnitType.label;

        saveFilter(newFilter);
        closeModal();
    };

    const closeModal = () => {
        toggle();
        clearState();
    };

    return (
        <NSModal
            toggle={closeModal}
            isOpen={isOpen}
            modalHeaderClassName="bg-primary modal-colored-header"
            modalHeaderText={isAddingNewFilter ? 'Add Filter' : 'Edit Filter'}
            modalFooterIsShown
            modalFooterButtonText={isAddingNewFilter ? 'Add Filter' : 'Save Filter'}
            modalFooterButtonFunction={handleSaveFilter}
            modalFooterIsButtonFunctionDisabled={isModalButtonDisabled}
        >
            <div>
                <span className="font-weight-normal text-white">
                    Attribute
                    {' '}
                    <span className="text-danger">*</span>
                </span>
                <NSDropdown
                    options={attributeOptions}
                    selectedOption={selectedAttribute}
                    handleSelectOption={(option: IOptionAnyValue) => handleChangeSelectedAttribute(option)}
                    toggleClassName="w-100"
                    placeholder="Select filter attribute"
                />
            </div>
            {selectedAttribute && <hr />}
            <div className="d-flex flex-wrap">
                <div className={classNames(['p-0 flex-fill d-flex', { 'mr-1': !includesWideFields && selectedFilterOption }])}>
                    {isSelectedAttributeUnits && (
                        <NSDropdown
                            toggleClassName="w-100"
                            containerClassName="flex-fill w-100 mr-1"
                            options={unitTypeSelectorOptions}
                            selectedOption={selectedUnitType}
                            handleSelectOption={setSelectedUnitType}
                        />
                    )}
                    {selectedAttribute && (
                        <NSDropdown
                            toggleClassName="w-100"
                            containerClassName="flex-fill w-100"
                            options={filterOptions}
                            selectedOption={selectedFilterOption}
                            handleSelectOption={(option: IOptionAnyValue) => handleChangeSelectedFilter(option)}
                            placeholder="Select filter"
                        />
                    )}
                </div>
                {selectedAttribute && additionalField && (
                    <>
                        {includesWideFields && (
                            <div className="col-12 d-flex p-1 justify-content-around">
                                <FontAwesomeIcon icon={faDown} />
                            </div>
                        )}
                        <div className={classNames([' m-0 p-0 col-6', { 'col-12': includesWideFields }])}>
                            <AdditionalField
                                additionalField={additionalField}
                                attribute={selectedAttribute.label}
                                isRangeValid={isRangeValid}
                                fieldInput={fieldInput}
                                setFieldInput={setFieldInput}
                            />
                        </div>
                    </>
                )}
            </div>
        </NSModal>
    );
};

export default FilterModal;
