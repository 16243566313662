import React, { useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { useParams, useNavigate, generatePath } from 'react-router-dom';
import { useAuthContext } from 'contexts/AuthContext';
import CreateProFormaModal from 'views/CreateProFormaModal/CreateProFormaModal';
import { useGetDealById } from 'views/Deals/hooks/useGetDealById';
import EmptyState from 'components/EmptyState/EmptyState';
import { useGetProFormasByDeal } from 'queries/ProForma';
import { DealType } from 'constants/deals';
import { useGetBackOfEnvelopeScenarios } from 'views/DealDashboard/BOE/hooks/useGetBOEScenarios';
import PageTitle from '../../components/PageTitle/PageTitle';
import RenderIf from '../../components/RenderIf/RenderIf';
import { NSButton, NSNavTabs } from '../../bricks';
import { NORTHSPYRE_PRO_FORMAS, UPLOADED_PRO_FORMAS } from './constants';
import NorthspyreProFormasTable from './components/NorthspyreProFormasTable/NorthspyreProFormasTable';
import UploadededProFormasTable from './components/UploadedProFormasTable/UploadedProFormasTable';
import { URLS } from '../../services/urls';

export interface IProFormaHomeProps {
    dealId?: number;
}

const ProFormaHome = () => {
    const { selectedOrganizationId } = useAuthContext();
    const { dealId } = useParams<{ dealId: string }>();
    const navigate = useNavigate();
    const uploadPath = generatePath(URLS.PRO_FORMA.UPLOAD, { dealId });

    const [activeTabName, setActiveTabName] = useState(NORTHSPYRE_PRO_FORMAS);
    const [openCreateProFormaModal, setOpenCreateProFormaModal] = useState(false);

    const { data: fetchedDeal, isLoading } = useGetDealById({
        orgId: selectedOrganizationId!,
        dealId: Number(dealId),
        shouldFetch: !!Number(dealId),
    });

    const { data: northspyreProFormas, isLoading: isLoadingNorthspyreProFormas } = useGetProFormasByDeal({ dealId: Number(dealId) });
    const toggleOpenProformaModal = () => setOpenCreateProFormaModal(!openCreateProFormaModal);

    const proFormaNavTabs: string[] = [NORTHSPYRE_PRO_FORMAS, UPLOADED_PRO_FORMAS];

    if (!fetchedDeal && !isLoading && !isLoadingNorthspyreProFormas) {
        return <div>No deal selected</div>;
    }

    const isDealTypeAquisition = fetchedDeal && fetchedDeal?.dealType?.name === DealType.Acquisition;

    const { data: { items: boeScenarios } = {} } = useGetBackOfEnvelopeScenarios({ orgId: selectedOrganizationId!, dealId: Number(dealId) });
    const initialScenario = boeScenarios?.length ? { label: boeScenarios?.[0]?.name, value: boeScenarios?.[0]?.id } : undefined;

    return (
        <>
            <PageTitle title="Pro Forma" displayBreadcrumb dealName={fetchedDeal?.dealName} pageMetaDataTitle="Pro Forma Home" containerClassName="mb-3">
                <NSButton color="secondary" outline={false} callback={() => navigate(uploadPath)} text="Upload" className="mr-2" />
                <NSButton
                    color="primary"
                    id="create-pro-forma"
                    outline={false}
                    callback={toggleOpenProformaModal}
                    text="Create pro forma"
                    disabled={isDealTypeAquisition}
                    disabledTooltip={isDealTypeAquisition ? 'Pro forma creation is not yet available for acquisitions.' : undefined}
                />
            </PageTitle>

            <RenderIf isTrue={!isLoading && !isLoadingNorthspyreProFormas} isLoading={isLoading || isLoadingNorthspyreProFormas}>
                <RenderIf isTrue={!northspyreProFormas?.length}>
                    <EmptyState
                        title="No pro formas created yet!"
                        subtitle="Create your first pro forma from scratch or upload your own."
                        className="mt-3 vh-100"
                    />
                </RenderIf>

                <RenderIf isTrue={northspyreProFormas?.length}>
                    <NSNavTabs
                        containerClassName="mb-0 text-dark d-flex justify-content-start"
                        navItems={proFormaNavTabs.map((navName, index) => ({
                            callback: () => setActiveTabName(navName),
                            name: navName,
                            isActive: activeTabName === navName,
                            id: `pro-forma-home-nav-tab-${index}`,
                            className: 'text-dark text-nowrap px-4',
                        }))}
                    />

                    <TabContent activeTab={activeTabName}>
                        <TabPane tabId={NORTHSPYRE_PRO_FORMAS}>
                            <RenderIf isTrue={activeTabName === NORTHSPYRE_PRO_FORMAS}>
                                <NorthspyreProFormasTable proFormas={northspyreProFormas!} />
                            </RenderIf>
                        </TabPane>
                        <TabPane tabId={UPLOADED_PRO_FORMAS}>
                            <RenderIf isTrue={activeTabName === UPLOADED_PRO_FORMAS}>
                                <UploadededProFormasTable />
                            </RenderIf>
                        </TabPane>
                    </TabContent>
                </RenderIf>
            </RenderIf>

            <RenderIf isTrue={openCreateProFormaModal}>
                <CreateProFormaModal
                    toggle={toggleOpenProformaModal}
                    isOpen={openCreateProFormaModal}
                    initialScenario={initialScenario}
                    showScenarioStep
                />
            </RenderIf>
        </>
    );
};

export default ProFormaHome;
