import React, { useMemo, useState } from 'react';
import ProFormaGenericTable from 'views/ProFormaTable/components/ProFormaGenericTable/ProFormaGenericTable';
import { IOptionAnyValue } from 'bricks/types';
import { useScrollableSync } from 'views/ProFormaTable/hooks/useScrollableSync';
import { IContributionsAndDistributionsProps } from './types';
import {
    CONTRIBUTIONS_COLUMNS, DISTRIBUTIONS_COLUMNS, CASH_FLOW_COLUMNS, getWaterfallColumns,
} from './constants';

const ContributionsAndDistributions = ({
    contributionsRows,
    setContributionsRows,
    distributionWaterfallRows,
    setDistributionWaterfallRows,
    distributionsRows,
    setDistributionsRows,
    netCashFlowRows,
    setNetCashFlowRows,
}: IContributionsAndDistributionsProps) => {
    const [selectedIRR, setSelectedIRR] = useState<IOptionAnyValue>({ value: 'irr', label: 'IRR' });

    const WATERFALL_COLUMNS = useMemo(() => getWaterfallColumns(selectedIRR, setSelectedIRR), [selectedIRR]);

    const { handleScroll, handleScrollableRef } = useScrollableSync();

    return (
        <div>
            <ProFormaGenericTable
                columns={CONTRIBUTIONS_COLUMNS}
                rows={contributionsRows}
                setRows={setContributionsRows}
                onScrollableRef={handleScrollableRef(0)}
                onScroll={handleScroll}
                hideAddButton
            />
            <div className="pt-1" />
            <ProFormaGenericTable
                columns={WATERFALL_COLUMNS}
                rows={distributionWaterfallRows}
                setRows={setDistributionWaterfallRows}
                onScrollableRef={handleScrollableRef(1)}
                onScroll={handleScroll}
                hideAddButton
                hideTotalFooter
            />
            <div className="pt-1" />
            <ProFormaGenericTable
                columns={DISTRIBUTIONS_COLUMNS}
                rows={distributionsRows}
                setRows={setDistributionsRows}
                onScrollableRef={handleScrollableRef(2)}
                onScroll={handleScroll}
                hideAddButton
            />
            <div className="pt-1" />
            <ProFormaGenericTable
                columns={CASH_FLOW_COLUMNS}
                rows={netCashFlowRows}
                setRows={setNetCashFlowRows}
                onScrollableRef={handleScrollableRef(3)}
                onScroll={handleScroll}
                hideAddButton
            />
        </div>
    );
};

export default ContributionsAndDistributions;
