import React, { useState, useEffect } from 'react';
import { ProFormaGenericRow } from 'views/ProFormaTable/components/ProFormaGenericTable/types';
import ProFormaGenericTable from 'views/ProFormaTable/components/ProFormaGenericTable/ProFormaGenericTable';
import { BASE_VALUATION_COLUMNS, BASE_STABILIZATION_COLUMNS } from './constants';
import './ProFormaValuation.scss';
import { getStabilizationColumnHeaderText, getValuationColumnHeaderText } from './helpers';
import { useProFormaSocketContext } from '../../socketContext/ProFormaSocketProvider';

const isDash = (value: string): boolean => value === '-' || value === '' || value === undefined || value === null;

const ProFormaValuation = () => {
    const { disposition } = useProFormaSocketContext();
    const [valuationRows, setValuationRows] = useState<ProFormaGenericRow[]>([]);

    useEffect(() => {
        const rows: ProFormaGenericRow[] = [
            {
                id: 'noi',
                title: 'Net operating income',
                untrended: disposition?.netOperatingIncome?.untrended?.toString() || '',
                trended: disposition?.netOperatingIncome?.trended?.toString() || '',
                sale: disposition?.netOperatingIncome?.sale?.toString() || '',
            },
            {
                id: 'capRate',
                title: 'Cap rate',
                untrended: disposition?.capRate?.untrended?.toString() || '',
                trended: disposition?.capRate?.trended?.toString() || '',
                sale: disposition?.capRate?.sale?.toString() || '',
                format: 'none',
            },
        ];
        setValuationRows(rows);
    }, [disposition]);

    const valuationColumns = [
        ...BASE_VALUATION_COLUMNS.map(col => {
            if (['untrended', 'trended', 'sale'].includes(col.key)) {
                return {
                    ...col,
                    header: (
                        <div className="ProFormaForecastTable__header">
                            <div>{getValuationColumnHeaderText(col.key).title}</div>
                            <small className="text-muted">{getValuationColumnHeaderText(col.key).subtitle}</small>
                        </div>
                    ),
                };
            }
            return col;
        }),
    ];

    const stabilizationColumns = [
        ...BASE_STABILIZATION_COLUMNS.map(col => {
            if (['untrended', 'trended', 'sale'].includes(col.key)) {
                const headerText = getStabilizationColumnHeaderText(col.key, disposition);
                return {
                    ...col,
                    header: (
                        <div className="ProFormaValuation__header">
                            <div className={isDash(headerText.title) ? 'text-muted' : ''}>
                                {headerText.title}
                            </div>
                            <small className="text-muted">{headerText.subtitlePrefix}</small>
                            <small className={isDash(headerText.subtitle) ? 'text-muted' : ''}>
                                {headerText.subtitle}
                            </small>
                        </div>
                    ),
                };
            }
            return col;
        }),
    ];

    return (
        <div className="d-flex flex-column p-3">
            <ProFormaGenericTable columns={valuationColumns} rows={valuationRows} setRows={setValuationRows} hideAddButton hideTotalFooter />
            <ProFormaGenericTable columns={stabilizationColumns} rows={[]} setRows={() => {}} hideTotalFooter hideAddButton />
        </div>
    );
};

export default ProFormaValuation;
