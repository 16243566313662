import { useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { IDealsQueryParams, IDealsResponse } from '../types';

const getDeals = async ({
    pageSize, currentPage, orgId, searchValue, sortBy, sortOrder, groupId, subprojectStageIds,
}: IDealsQueryParams): Promise<IDealsResponse> => {
    const includeSearch = searchValue ? `&search=${searchValue}` : '';
    const includeSort = sortBy && sortOrder ? `&${sortBy}=${sortOrder}` : '';
    const includeGroupId = groupId ? `&deal_group_id=${groupId}` : '';
    const includeSubprojectStageIds = subprojectStageIds ? `&${subprojectStageIds}` : '';
    const response = await axiosInstance.get(
        // eslint-disable-next-line max-len
        `${API_ENDPOINTS.DEALS(orgId)}?page=${currentPage - 1}&page_size=${pageSize}${includeSearch}${includeSort}${includeGroupId}${includeSubprojectStageIds}`,
    );
    return response.data;
};

export const useGetDeals = ({
    pageSize, currentPage, orgId, searchValue, sortBy, sortOrder, groupId, subprojectStageIds,
}: IDealsQueryParams) => useQuery({
    queryKey: ['deals', orgId, currentPage, pageSize, searchValue, sortBy, sortOrder, groupId, subprojectStageIds],
    queryFn: () => getDeals({
        pageSize, currentPage, orgId, searchValue, sortBy, sortOrder, groupId, subprojectStageIds,
    }),
    staleTime: 5000,
    refetchOnWindowFocus: true,
});
