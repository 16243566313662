/* eslint-disable max-len */
import React, { useState, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NSTable, NSSelect } from 'bricks';
import { faCircleInfo, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import RenderIf from 'components/RenderIf/RenderIf';
import { formatValue } from 'views/ProFormaTable/helpers';
import { Tooltip } from 'reactstrap';
import useAuthContext from 'contexts/AuthContext';
import { FEATURE_IDS } from 'services/constants';
import { AccessLevels } from 'types/auth';
import { ProFormaGenericRow, ProFormaGenericColumn, ProFormaGenericTableProps } from './types';
import DeleteRowConfirmationModal from '../DeleteRowConfirmationModal/DeleteRowConfirmationModal';
import './ProFormaGenericTable.scss';
import CellInputWithDeferredUpdate from './CellInputWithDeferredUpdate/CellInputWithDeferredUpdate';

export const formatValueOrDash = (value: string | number | undefined): string => {
    if (value === undefined || value === null) {
        return '-';
    }

    if (typeof value === 'number' || (typeof value === 'string' && !Number.isNaN(Number(value)))) {
        if (Number(value) === 0) {
            return '-';
        }
        return String(value);
    }

    if (typeof value === 'string') {
        return value;
    }

    return '-';
};

const isNegativeValue = (row: ProFormaGenericRow, column: ProFormaGenericColumn): boolean => {
    // Check for row-level negative flag (keep this for backward compatibility)
    if (row.isNegative) return true;

    const value = row[column.key];
    if (typeof value === 'string') {
        const numValue = parseFloat(value);
        if (!Number.isNaN(numValue)) {
            return numValue < 0;
        }
    }

    return false;
};

const isDash = (value: any): boolean => {
    // If value is false, we treat it as not a dash (it means we want to hide it)
    if (value === false) {
        return false;
    }
    return value === '-' || value === '' || value === undefined || value === null;
};

const getCellDisplayValue = (value: any): string => {
    // If value is false, return empty string (hide the value)
    if (value === false) {
        return '';
    }
    return formatValueOrDash(value);
};

const ProFormaGenericTable: React.FC<ProFormaGenericTableProps> = ({
    columns,
    rows = [],
    setRows,
    defaultRow: customDefaultRow,
    addButtonLabel = 'Add new row',
    hideTotalFooter = false,
    hideAddButton = false,
    totalText = 'Total',
    hideHeader = false,
    firstColumnWidth,
    onScrollableRef,
    onScroll,
    totalValues,
    handleAddRow = () => { },
    handleDeleteRow = () => { },
    handleUpdateRow = () => { },
    numFirstColumns = 1,
}) => {
    const { isFeatureAccessibleToUser } = useAuthContext();
    const hasWriteAccessToProForma = isFeatureAccessibleToUser([FEATURE_IDS.PRO_FORMA], AccessLevels.READ_WRITE_ACCESS);
    const [activeInput, setActiveInput] = useState<string | undefined>(undefined);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [rowToDelete, setRowToDelete] = useState<string | null>(null);
    const [doNotShowDeleteConfirmation, setDoNotShowDeleteConfirmation] = useState(
        () => localStorage.getItem('proFormaTableDeleteConfirmation') === 'true',
    );
    const [openTooltips, setOpenTooltips] = useState<Record<string, boolean>>({});
    const toggleTooltip = (tooltipId: string) => {
        setOpenTooltips(prev => ({ ...prev, [tooltipId]: !prev[tooltipId] }));
    };

    const totals = useMemo(() => {
        if (totalValues) {
            return totalValues;
        }
        return {};
    }, [rows, columns, totalValues]);

    const handleSetId = (id: string | undefined) => {
        setActiveInput(id);
    };

    const handleDeleteClick = (rowId: string) => {
        if (localStorage.getItem('proFormaTableDeleteConfirmation') === 'true') {
            handleDeleteRow(Number(rowId));
        } else {
            setRowToDelete(rowId);
            setShowDeleteModal(true);
        }
    };

    const handleConfirmDelete = () => {
        if (rowToDelete) {
            handleDeleteRow(Number(rowToDelete));
            setShowDeleteModal(false);
            setRowToDelete(null);
        }
    };

    const handleSetDoNotShowDeleteConfirmation = (value: boolean) => {
        setDoNotShowDeleteConfirmation(value);
        localStorage.setItem('proFormaTableDeleteConfirmation', value.toString());
    };

    const fixedColumns = columns.filter(col => col.isFixed !== false);
    const scrollableColumns = columns.filter(col => col.isFixed === false);

    const isFirstColumn = (column: ProFormaGenericColumn) => column.key === columns[0].key;

    const isBeforeSeparator = (column: ProFormaGenericColumn) => {
        const columnIndex = columns.findIndex(col => col.key === column.key);
        return columnIndex < numFirstColumns;
    };

    const isLastFirstColumn = (column: ProFormaGenericColumn) => {
        const columnIndex = columns.findIndex(col => col.key === column.key);
        return columnIndex === numFirstColumns - 1;
    };

    const isCellReadOnly = (row: ProFormaGenericRow, column: ProFormaGenericColumn) => {
        if (row.isReadOnly) return true;

        if (typeof column.isReadOnly === 'function') {
            return column.isReadOnly(row);
        }
        return column.isReadOnly;
    };

    // Helper to determine if negative styling should apply to this cell
    const shouldApplyNegativeStyling = (row: ProFormaGenericRow, column: ProFormaGenericColumn) => {
        // Never apply negative styling to the first column (title)
        if (isFirstColumn(column)) return false;

        // Check if the cell value is a dash ('-')
        const value = row[column.key];
        if (typeof value === 'string' && value === '-') {
            return true;
        }

        // For other columns, check if the row is marked as negative
        return isNegativeValue(row, column);
    };

    const getCellClassName = (row: ProFormaGenericRow, column: ProFormaGenericColumn) => {
        const classes: string[] = [];

        // All columns before the separator should be left-aligned
        if (!isBeforeSeparator(column)) {
            classes.push('text-right w-100 justify-content-end');
        }

        const value = row[column.key];

        if (isBeforeSeparator(column)) {
            classes.push('text-white');
        } else if (isDash(value) || isNegativeValue(row, column)) {
            classes.push('text-muted');
        } else {
            classes.push('text-white');
        }

        return classes.join(' ');
    };

    const renderHeaderCell = (column: ProFormaGenericColumn) => {
        if (column.headerDropdown) {
            return (
                <div className="d-flex align-items-center">
                    <NSSelect
                        name={`header-${column.key}-select`}
                        options={column.headerDropdown.options}
                        value={column.headerDropdown.value}
                        onChange={column.headerDropdown.onChange}
                        placeholder={column.headerDropdown.placeholder}
                        isClearable={false}
                        menuPosition="fixed"
                        small
                    />
                    {column.headerDropdown.label && <div className="ml-1">{column.headerDropdown.label}</div>}
                </div>
            );
        }

        const content = column.header;
        const shouldShowAddButton = isLastFirstColumn(column) && !hideAddButton;

        if (shouldShowAddButton) {
            return (
                <div className="d-flex align-items-center justify-content-between w-100">
                    <span>{content}</span>
                    <FontAwesomeIcon
                        icon={faPlus}
                        className="ml-2 text-muted cursor--pointer"
                        onClick={handleAddRow}
                        title={addButtonLabel}
                    />
                </div>
            );
        }

        return content;
    };

    const getHeaderBorderClass = (column: ProFormaGenericColumn, hideAddButton: boolean) => {
        // Add right border only to the last column before the separator
        if (isLastFirstColumn(column)) {
            return hideAddButton ? '' : 'NSTable__thead__tr__th--right-border';
        }
        return 'text-right';
    };

    const getBodyTableBorderClass = (column: ProFormaGenericColumn, hideAddButton: boolean) => {
        // Add right border only to the last column before the separator
        if (isLastFirstColumn(column)) {
            return hideAddButton ? 'NSTable__tbody__tr' : 'NSTable__tbody__tr__td--right-border';
        }
        return 'text-right';
    };

    const handleCellChange = (rowId: number, dataKey: string) => handleUpdateRow?.(rowId, dataKey) || ((_e: React.ChangeEvent<HTMLInputElement>) => { });

    return (
        <div className="ProFormaGenericTable">
            <div className="d-flex" style={{ '--first-column-width': firstColumnWidth } as React.CSSProperties}>
                {fixedColumns.length > 0 && (
                    <div className="ProFormaGenericTable__fixed-section">
                        <NSTable className="ProFormaGenericTable__table">
                            {!hideHeader && (
                                <thead className="NSTable__thead">
                                    <tr className="NSTable__thead__tr">
                                        {fixedColumns.map(column => (
                                            <th
                                                key={column.key}
                                                className={`NSTable__thead__tr__th 
                                                ${getHeaderBorderClass(column, hideAddButton)}
                                                ${isFirstColumn(column) && column.dropdown ? 'has-dropdown' : ''}`}
                                            >
                                                <div
                                                    className={`d-flex align-items-center 
                                                        ${isFirstColumn(column) ? 'justify-content-between' : 'justify-content-end'}`}
                                                >
                                                    {renderHeaderCell(column)}
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                            )}
                            {rows.length === 0 && !hideAddButton ? (
                                <tbody className="NSTable__tbody">
                                    <tr className="NSTable__tbody__tr">
                                        <td colSpan={fixedColumns.length} className="text-center text-muted py-4">
                                            <div className="text-white">Your added sources will appear here.</div>
                                            <div className="small">
                                                Click the
                                                {' '}
                                                <span className="font-weight-bold text-white">[+]</span>
                                                {' '}
                                                symbol in the
                                                {' '}
                                                <span className="font-weight-bold text-white">{columns[0].header}</span>
                                                {' '}
                                                header to add your first source.
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody className="NSTable__tbody">
                                    {rows.map(row => (
                                        <tr key={row.id} className="NSTable__tbody__tr">
                                            {fixedColumns.map(column => (
                                                <td
                                                    key={column.key}
                                                    className={`NSTable__tbody__tr__td
                                                        ${getBodyTableBorderClass(column, hideAddButton)}
                                                        ${activeInput === `${row.id}-${column.key}` ? 'pr-0' : ''}`}
                                                >
                                                    <RenderIf isTrue={!column.isHidden}>
                                                        {isCellReadOnly(row, column) ? (
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div
                                                                    className={getCellClassName(row, column)}
                                                                >
                                                                    {row.label && column.showLabel && <span className="text-muted mr-2">{row.label}</span>}
                                                                    {formatValue(
                                                                        getCellDisplayValue(row[column.key]),
                                                                        shouldApplyNegativeStyling(row, column),
                                                                        true,
                                                                        column.format,
                                                                        column.isHidden,
                                                                    )}
                                                                    {column.showTooltip && row.tooltipMessage && (
                                                                        <>
                                                                            <FontAwesomeIcon
                                                                                className="px-1 text-white"
                                                                                id={`${row.id}-${column.key}-tooltip`}
                                                                                icon={faCircleInfo}
                                                                            />
                                                                            <Tooltip
                                                                                target={`${row.id}-${column.key}-tooltip`}
                                                                                isOpen={openTooltips[`${row.id}-${column.key}-tooltip`] || false}
                                                                                toggle={() => toggleTooltip(`${row.id}-${column.key}-tooltip`)}
                                                                                placement="top"
                                                                            >
                                                                                <span className="font-weight-bold">{row[column.key] as string}</span>
                                                                                <div>{row.tooltipMessage}</div>
                                                                            </Tooltip>
                                                                        </>
                                                                    )}
                                                                </div>
                                                                {column.dropdown && column.dropdown.shouldShowDropdown?.(row) && (
                                                                    <div className="d-flex align-items-center ml-2">
                                                                        <NSSelect
                                                                            name={`${row.id}-${column.key}-select`}
                                                                            options={column.dropdown.options}
                                                                            value={column.dropdown.getSelectedOption(row)}
                                                                            onChange={column.dropdown.handleSelectOption(row.id)}
                                                                            placeholder={column.dropdown.label}
                                                                            isClearable={false}
                                                                            menuPosition="fixed"
                                                                        />
                                                                    </div>
                                                                )}
                                                                {column.customCell && column.customCell.shouldShowCustomCell?.(row) && (
                                                                    <div>
                                                                        {column.customCell.renderCell({
                                                                            row,
                                                                            column,
                                                                            value: row[column.key],
                                                                            onChange: handleCellChange(Number(row.id), column.key),
                                                                            activeInput,
                                                                            handleSetId,
                                                                        })}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className={`d-flex align-items-center
                                                                ${getCellClassName(row, column)}`}
                                                            >
                                                                {row.label && column.showLabel && <span className="text-muted mr-2">{row.label}</span>}
                                                                <CellInputWithDeferredUpdate
                                                                    rowId={Number(row.id)}
                                                                    columnKey={column.key}
                                                                    value={row[column.key]}
                                                                    isRightAligned={!isFirstColumn(column)}
                                                                    customClassName={!isFirstColumn(column) ? 'text-right' : ''}
                                                                    disabled={!hasWriteAccessToProForma}
                                                                    handleUpdateRow={(rowId, dataKey, value) => {
                                                                        console.log('handleUpdateRow', Number(rowId), dataKey, value);
                                                                        const updateFn = handleUpdateRow(rowId, dataKey);
                                                                        if (typeof updateFn === 'function') {
                                                                            updateFn({
                                                                                target: { value },
                                                                            } as React.ChangeEvent<HTMLInputElement>);
                                                                        }
                                                                    }}
                                                                />
                                                                <RenderIf isTrue={isLastFirstColumn(column) && !row.isReadOnly && hasWriteAccessToProForma}>
                                                                    <FontAwesomeIcon
                                                                        icon={faTrash}
                                                                        className="delete-icon"
                                                                        onClick={() => handleDeleteClick(row.id)}
                                                                    />
                                                                </RenderIf>
                                                            </div>
                                                        )}
                                                    </RenderIf>
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            )}
                            {!hideTotalFooter && (
                                <tfoot className="NSTable__tfoot">
                                    <tr className="NSTable__tfoot__tr">
                                        {fixedColumns.map(column => (
                                            <td
                                                key={column.key}
                                                className={`NSTable__tfoot__tr__td 
                                                    ${isBeforeSeparator(column) ? 'NSTable__tfoot__tr__td--right-border' : ''} 
                                                    ${isFirstColumn(column) ? 'text-left' : 'text-right'}`}
                                            >
                                                <div className="ProFormaGenericTable__total-cell">
                                                    {isFirstColumn(column)
                                                        ? totalText
                                                        : (
                                                            <div>
                                                                <span className={isDash(totals[column.key]) ? 'text-muted' : 'text-white'}>
                                                                    {formatValue(
                                                                        getCellDisplayValue(totals[column.key]),
                                                                        false,
                                                                        true,
                                                                        column.format,
                                                                        column.isHidden,
                                                                    )}
                                                                </span>
                                                            </div>
                                                        )}
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                </tfoot>
                            )}
                        </NSTable>
                    </div>
                )}

                {/* Scrollable Columns */}
                {scrollableColumns.length > 0 && (
                    <div className="ProFormaGenericTable__scrollable-section" ref={onScrollableRef} onScroll={onScroll}>
                        <NSTable className="ProFormaGenericTable__table">
                            {!hideHeader && (
                                <thead className="NSTable__thead">
                                    <tr className="NSTable__thead__tr">
                                        {scrollableColumns.map(column => (
                                            <th key={column.key} className="NSTable__thead__tr__th text-right">
                                                <div className="d-flex align-items-center justify-content-end">
                                                    <span>{column.header}</span>
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                            )}
                            {rows.length === 0 && !hideAddButton ? (
                                <tbody className="NSTable__tbody">
                                    <tr className="NSTable__tbody__tr">
                                        <td colSpan={scrollableColumns.length} className="text-center text-muted py-4">
                                            <div>&nbsp;</div>
                                            <div className="small">&nbsp;</div>
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody className="NSTable__tbody">
                                    {rows.map(row => (
                                        <tr key={row.id} className="NSTable__tbody__tr">
                                            {scrollableColumns.map(column => (
                                                <td
                                                    key={column.key}
                                                    className={`NSTable__tbody__tr__td text-right 
                                                        ${activeInput === `${row.id}-${column.key}` ? 'pr-0' : ''}`}
                                                >
                                                    <div className={getCellClassName(row, column)}>
                                                        {formatValue(
                                                            getCellDisplayValue(row[column.key]),
                                                            shouldApplyNegativeStyling(row, column),
                                                            true,
                                                            column.format,
                                                            column.isHidden,
                                                        )}
                                                    </div>
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            )}
                            {!hideTotalFooter && (
                                <tfoot className="NSTable__tfoot">
                                    <tr className="NSTable__tfoot__tr">
                                        {scrollableColumns.map(column => (
                                            <td key={column.key} className="NSTable__tfoot__tr__td text-right">
                                                <div>
                                                    <span className={isDash(totals[column.key]) ? 'text-muted' : 'text-white'}>
                                                        {formatValue(
                                                            getCellDisplayValue(totals[column.key]),
                                                            false,
                                                            true,
                                                            column.format,
                                                            column.isHidden,
                                                        )}
                                                    </span>
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                </tfoot>
                            )}
                        </NSTable>
                    </div>
                )}
            </div>
            <DeleteRowConfirmationModal
                isOpen={showDeleteModal}
                toggle={() => setShowDeleteModal(false)}
                handleDelete={handleConfirmDelete}
                doNotShowAgain={doNotShowDeleteConfirmation}
                setDoNotShowAgain={handleSetDoNotShowDeleteConfirmation}
            />
        </div>
    );
};

export default ProFormaGenericTable;
