import { useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { IAggregateReportQueryParams, IAggregateReportResponse } from '../types';

export const getAggregateReportDataQueryKey = 'getAggregateReportData';

const getAggregateReportData = async ({ orgId, proFormaIds, cadence }: IAggregateReportQueryParams): Promise<IAggregateReportResponse> => {
    const response = await axiosInstance.get(`${API_ENDPOINTS.ORG_REPORTS(orgId)}/aggregate_report`, {
        params: {
            pro_forma_ids: proFormaIds,
            cadence,
        },
        paramsSerializer: params => Object.entries(params)
            .map(([key, value]) => (Array.isArray(value)
                ? value.map(v => `${key}=${encodeURIComponent(v)}`).join('&')
                : `${key}=${encodeURIComponent(value)}`))
            .join('&'),
    });
    return response.data;
};

export const useGetAggregateReportData = ({ orgId, proFormaIds, cadence }: IAggregateReportQueryParams) => useQuery({
    queryKey: ['getAggregateReportData', orgId, proFormaIds, cadence],
    queryFn: () => getAggregateReportData({ orgId, proFormaIds, cadence }),
    staleTime: 5000,
    refetchOnMount: true,
});

export default useGetAggregateReportData;
