import { NSModal } from 'bricks';
import React from 'react';

export interface IDeleteSensitivityConfirmationModalProps {
    isOpen: boolean;
    toggle: () => void;
    handleDelete: () => void;
}

const DeleteSensitivityConfirmationModal = ({ isOpen, toggle, handleDelete }: IDeleteSensitivityConfirmationModalProps) => (
    <NSModal
        isOpen={isOpen}
        toggle={toggle}
        modalHeaderText="Remove sensitivity analysis"
        modalHeaderClassName="text-white bg-danger"
        modalFooterButtonText="Yes, remove"
        modalFooterButtonColor="danger"
        modalFooterButtonFunction={() => {
            handleDelete();
        }}
    >
        <div className="text-dark h5 mb-0">Are you sure you want to remove this sensitivity analysis?</div>
        <div className="text-muted mb-0">Your work will not be saved.</div>
    </NSModal>
);

export default DeleteSensitivityConfirmationModal;
