import React, {
    Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsFromLine } from '@fortawesome/pro-solid-svg-icons';
import './DevelopmentUsesTable.scss';
import {
    NSTable, NSCheckbox, NSDropdown, NSButton,
} from 'bricks';
import { PRO_FORMA_UNIT_TO_FIELD_VALUES, UNIT_TYPE_NAMES } from 'constants/unitTypes';
import {
    IUsesExpandedCategories,
    IUsesSelectedCategories,
    IUnitTypeOption,
    DevelopmentUnitOfMeasureTypes,
    ProjectionType,
    IProFormaGeneralInfo,
    IBudgetClassificationPatchData,
    IUsesSummary,
} from 'views/ProFormaTable/types';
import {
    BULK_ASSIGN_OPTIONS,
    BULK_OPTION,
    DISABLED_PLACEHOLDER_PERIOD,
    PROJECTION_TYPES,
    PRO_FORMA_DEVELOPMENT_UNIT_TYPE_KEY,
    PRO_FORMA_TABLE_NAV,
    PRO_FORMA_TABS,
} from 'views/ProFormaTable/constants';
import RenderIf from 'components/RenderIf/RenderIf';
import { IOptionAnyValue } from 'bricks/types';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { generateDateRange, getMissingDateText } from 'views/ProFormaTable/helpers';
import NSInfoBanner from 'components/NSInfoBanner/NSInfoBanner';
import { numeralFormatterCurrency } from 'ns_libs/formatter';
import BudgetLineAmountModal from '../BudgetLineAmountModal/BudgetLineAmountModal';
import CurveFitModal from '../CurveFitModal/CurveFitModal';
import DateModal from '../DateModal/DateModal';
import { useUsesTableContext } from './context/UsesTableProvider';
import UsesTableHighLevel from './UsesTableHighLevel/UsesTableHighLevel';
import MissingDateModal from '../MissingDateModal/MissingDateModal';
import { useProFormaSocketContext } from '../../socketContext/ProFormaSocketProvider';
import PeriodCellValue from './PeriodCellValue/PeriodCellValue';

export interface IDevelopmentUsesTableProps {
    uses: IUsesSummary;
    unitType: IUnitTypeOption;
    setUnitType: Dispatch<SetStateAction<IUnitTypeOption | null>>;
    unitTypeOptions: IUnitTypeOption[];
    proForma: IProFormaGeneralInfo;
}

const DevelopmentUsesTable = ({
    uses, unitType, setUnitType, unitTypeOptions, proForma,
}: IDevelopmentUsesTableProps) => {
    const {
        navigateToSection, deselectAllCategories, selectedCategories,
        selectAllCategories, hideAllCategories, showAllCategories,
        isProFormaReadOnly,
    } = useUsesTableContext();

    const {
        handleUpdateBudgetClassifications,
        isBudgetClassificationUpdatingLoading,
        handleDeleteBudgetClassification,
        isBudgetClassificationDeletionLoading,
    } = useProFormaSocketContext();

    const [shouldSelectAllCategories, setShouldSelectAllCategories] = useState(false);
    const [expandAllCategories, setExpandAllCategories] = useState(false);
    const [bulkOption, setBulkOption] = useState<string | null>(null);
    const [openDateModal, setOpenDateModal] = useState(false);
    const [openCurveModal, setOpenCurveModal] = useState(false);
    const [openAmountModal, setOpenAmountModal] = useState(false);
    const [deleteAllSelectedCategories, setDeleteAllSelectedCategories] = useState(false);

    const toggleDateModal = () => setOpenDateModal(!openDateModal);
    const toggleCurveModal = () => setOpenCurveModal(!openCurveModal);
    const toggleAmountModal = () => setOpenAmountModal(!openAmountModal);
    const toggleDeleteSelectedCategories = () => setDeleteAllSelectedCategories(!deleteAllSelectedCategories);

    const handleExpandAllCategories = () => {
        if (expandAllCategories) {
            hideAllCategories();
            setExpandAllCategories(false);
        } else {
            const updatedExpandedCategories: IUsesExpandedCategories = { highLevels: [], subcategories: [] };
            uses.uses.forEach(highLevel => {
                updatedExpandedCategories.highLevels.push(highLevel.id);
                (highLevel.children || []).forEach(subcategory => {
                    updatedExpandedCategories.subcategories.push(subcategory.id);
                });
            });
            showAllCategories(updatedExpandedCategories);
            setExpandAllCategories(true);
        }
    };

    const handleUpdateUnitType = (option: IUnitTypeOption) => {
        setUnitType(option);
        localStorage.setItem(PRO_FORMA_DEVELOPMENT_UNIT_TYPE_KEY, JSON.stringify(option.value));
    };

    const deselectAllBudgetCategories = () => {
        deselectAllCategories();
        setShouldSelectAllCategories(false);
    };

    const handleSelectAllCategories = () => {
        if (shouldSelectAllCategories) {
            deselectAllBudgetCategories();
        } else {
            const updatedSelectedCategories: IUsesSelectedCategories = { highLevels: [], subcategories: [], budgetClassifications: [] };
            uses.uses.forEach(highLevel => {
                updatedSelectedCategories.highLevels.push(highLevel.id);
                (highLevel.children || []).forEach(subcategory => {
                    updatedSelectedCategories.subcategories.push(subcategory.id);
                    (subcategory.children || []).forEach(budgetClassification => {
                        updatedSelectedCategories.budgetClassifications.push(budgetClassification.id);
                    });
                });
            });
            selectAllCategories(updatedSelectedCategories);
            setShouldSelectAllCategories(true);
        }
    };

    // Bulk patch actions
    const handleSelectBulkOption = (option: IOptionAnyValue) => {
        setBulkOption(option.value as string);
        if (option.value === BULK_OPTION.START_DATE || option.value === BULK_OPTION.END_DATE) {
            toggleDateModal();
        }
        if (option.value === BULK_OPTION.CURVE) {
            toggleCurveModal();
        }
        if (option.value === BULK_OPTION.AMOUNT) {
            toggleAmountModal();
        }
    };

    const updateSuccessCallback = () => {
        if (openDateModal) toggleDateModal();
        if (openCurveModal) toggleCurveModal();
        if (openAmountModal) toggleAmountModal();
    };

    // bulk update is only for budget lines
    const handleBulkUpdate = (updates: Partial<IBudgetClassificationPatchData>) => {
        if (selectedCategories.budgetClassifications.length === 0) return;

        const budgetClassificationsToUpdate: IBudgetClassificationPatchData[] = selectedCategories.budgetClassifications.map(budgetClassificationId => ({
            id: budgetClassificationId,
            ...updates,
        }));

        handleUpdateBudgetClassifications(budgetClassificationsToUpdate, updateSuccessCallback);
    };

    const handleBulkUpdateDate = (date: string, isStartDate: boolean) => {
        handleBulkUpdate(isStartDate ? { startDate: date } : { endDate: date });
    };

    const handleBulkUpdateCurveFit = (projectionType: ProjectionType, projectionVariable: number) => {
        const targetProjection = PROJECTION_TYPES.find(type => type.projectionType === projectionType);
        if (targetProjection) {
            handleBulkUpdate({ projectionType, projectionVariable: projectionVariable! });
        }
    };

    const handleBulkUpdateAmount = (amount: number | null) => {
        handleBulkUpdate({ amount: String(amount)! });
    };

    const handleBulkDelete = () => {
        handleDeleteBudgetClassification(Object.values(selectedCategories).flat(), false, toggleDeleteSelectedCategories);
    };

    useEffect(() => {
        if (!openDateModal && !openCurveModal && !openAmountModal) {
            setBulkOption(null);
        }
    }, [openDateModal, openCurveModal, openAmountModal]);

    const selectedUnitTotal = uses.totals[PRO_FORMA_UNIT_TO_FIELD_VALUES[unitType.value as DevelopmentUnitOfMeasureTypes]];

    const numberOfSelectedCategories = Object.values(selectedCategories).flat().length;

    const { title, prependedSubtitle, boldSubtitleText } = getMissingDateText(proForma.startDate, proForma.saleDate);
    const periodsDateRange = proForma.startDate && proForma.saleDate ? [proForma.startDate, proForma.saleDate] : null;

    const redirectToGeneralInformation = () => {
        navigateToSection(PRO_FORMA_TABLE_NAV.PRO_FORMA.name, PRO_FORMA_TABS.GENERAL_INFORMATION.name);
    };

    const unavailableUnitTypes: string[] = [];

    unitTypeOptions.map(option => {
        const costPerUnitTotal = uses.totals[PRO_FORMA_UNIT_TO_FIELD_VALUES[option.value as DevelopmentUnitOfMeasureTypes]];
        return costPerUnitTotal === null ? unavailableUnitTypes.push(option.value) : null;
    });

    const updatedUnitTypeOptions = unitTypeOptions.map(option => ({
        ...option,
        disabled: unavailableUnitTypes.includes(option.value),
    }));

    const periodOptions = periodsDateRange !== null
        ? generateDateRange(periodsDateRange, 'MMM yyyy', true)
        : [{ label: 'MON YEAR', value: DISABLED_PLACEHOLDER_PERIOD }];

    return (
        <>
            <RenderIf isTrue={!proForma?.startDate || !proForma?.saleDate}>
                <NSInfoBanner
                    title={title as string}
                    subtitle={(
                        <small className="d-flex text-dark">
                            <div>
                                {prependedSubtitle}
                                <span role="presentation" onClick={redirectToGeneralInformation} className="text-primary px-1 cursor--pointer">
                                    General Information
                                </span>
                                <span>
                                    to populate
                                    {' '}
                                    <b>{boldSubtitleText}</b>
                                    {' '}
                                    on each budget line.
                                </span>
                            </div>
                        </small>
                    )}
                    className="mt-3 NSCard--level-4 NSCard__left-border--warning bg-warning-lighten mb-3 py-1"
                    warning
                    customInfo={<NSButton text="Fix" color="primary" callback={redirectToGeneralInformation} className="mr-2" />}
                />
            </RenderIf>

            <RenderIf isTrue={Boolean(numberOfSelectedCategories)}>
                <div className="d-flex justify-content-between rounded DevelopmentUsesTable__bulk-actions-card py-2 px-3">
                    <div className="d-flex">
                        <div className="my-auto mr-2">
                            {numberOfSelectedCategories}
                            {' '}
                            selected
                        </div>
                        <RenderIf isTrue={Boolean(selectedCategories.budgetClassifications.length)}>
                            <NSDropdown
                                options={BULK_ASSIGN_OPTIONS}
                                selectedOption={{ label: 'Assign to budget lines', value: 'assign' }}
                                handleSelectOption={handleSelectBulkOption}
                                toggleClassName="bg-primary rounded border border-primary"
                            />
                        </RenderIf>
                        <NSButton text="Delete" className="ml-1" color="danger" callback={toggleDeleteSelectedCategories} outline />
                    </div>
                    <div role="presentation" onClick={deselectAllBudgetCategories} className="text-primary cursor--pointer my-auto">
                        Clear
                    </div>
                </div>
            </RenderIf>

            <div className="d-flex NSTable--sticky-headers__table-container-with-scrollbar">
                <NSTable className="NSTable--sticky-headers NSTable--sticky-headers--full-width NSTable--sticky-headers--six-sticky-columns">
                    <thead className="NSTable__thead">
                        <tr className="NSTable__thead__tr">
                            <th className="NSTable__thead__tr__th NSTable__thead__tr__th--right-border align-middle py-1">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <RenderIf isTrue={!isProFormaReadOnly}>
                                            <NSCheckbox
                                                id="usesTableSelectAll"
                                                checked={shouldSelectAllCategories}
                                                labelClassName="cursor--pointer"
                                                callback={handleSelectAllCategories}
                                            />
                                        </RenderIf>
                                        <FontAwesomeIcon
                                            className="mr-2 text-muted cursor--pointer ReadOnlyWrapper--enable-pointer-events"
                                            icon={faArrowsFromLine}
                                            onClick={handleExpandAllCategories}
                                        />
                                        <span className="ml-0">Uses</span>
                                    </div>
                                    {/** TODO: show this button when implementing logic for user to create their own high levels */}
                                    {/* <NSButton className="px-2 text-muted" size="sm" iconOnly icon={faPlus} callback={() => { }} /> */}
                                </div>
                            </th>
                            <th className="NSTable__thead__tr__th align-middle py-1">Start</th>
                            <th className="NSTable__thead__tr__th align-middle py-1">End</th>
                            <th className="NSTable__thead__tr__th align-middle py-1">Curve</th>
                            <th className="NSTable__thead__tr__th align-middle py-1 text-nowrap">
                                <div className="text-right">
                                    <span className="mr-1">Cost per</span>
                                    <NSDropdown
                                        options={updatedUnitTypeOptions}
                                        selectedOption={{ label: UNIT_TYPE_NAMES[unitType.value].abbreviated, value: unitType.value }}
                                        handleSelectOption={option => handleUpdateUnitType(option)}
                                        containerClassName="ReadOnlyWrapper--enable-pointer-events"
                                        badgeColor="dark-lighten"
                                        isBadgeToggle
                                        useBodyContainer
                                        menuRight
                                    />
                                </div>
                            </th>
                            <th className="NSTable__thead__tr__th text-right NSTable__thead__tr__th--right-border
                            align-middle text-nowrap py-1"
                            >
                                Amount
                            </th>
                            {periodOptions?.map((period, index) => (
                                <th
                                    key={period.value}
                                    className="NSTable__thead__tr__th align-middle
                                NSTable__thead__tr__th--grey-bg NSTable__thead__tr__th--width-150 py-1"
                                >
                                    <RenderIf isTrue={period.value === DISABLED_PLACEHOLDER_PERIOD}>
                                        <div className="h6 NSTable__thead__tr__th--grey-text
                                        NSTable__thead__tr__th--opacity-3 text-uppercase text-nowrap text-secondary text-right mb-0"
                                        >
                                            Month #
                                        </div>
                                        <div className="h6 text-uppercase text-right text-nowrap NSTable__thead__tr__th--opacity-3 mt-1">
                                            {period.label}
                                        </div>
                                    </RenderIf>
                                    <RenderIf isTrue={period.value !== DISABLED_PLACEHOLDER_PERIOD}>
                                        <div className="h6 NSTable__thead__tr__th--grey-text text-uppercase text-nowrap text-secondary text-right mb-0">
                                            Month
                                            {' '}
                                            {index}
                                        </div>
                                        <div className="h6 text-uppercase text-right text-nowrap mt-1">{period.label}</div>
                                    </RenderIf>
                                </th>
                            ))}
                        </tr>
                    </thead>

                    <tbody className="NSTable__tbody">
                        {uses.uses.map(highLevel => (
                            <UsesTableHighLevel
                                key={highLevel.id}
                                highLevel={highLevel}
                                unitType={unitType.value as DevelopmentUnitOfMeasureTypes}
                                periodOptions={periodOptions || []}
                            />
                        ))}
                    </tbody>

                    <tfoot className="NSTable__tfoot">
                        <tr className="NSTable__tfoot__tr">
                            <td className="NSTable__tfoot__tr__td NSTable__tfoot__tr__td--right-border text-left NSTable__tfoot__tr__td--width-400">
                                Total
                            </td>
                            <td className="NSTable__tfoot__tr__td" />
                            <td className="NSTable__tfoot__tr__td" />
                            <td className="NSTable__tfoot__tr__td" />
                            <td className="NSTable__tfoot__tr__td text-right text-nowrap">
                                {selectedUnitTotal !== null ? numeralFormatterCurrency(selectedUnitTotal) : '—'}
                            </td>
                            <td className="NSTable__tfoot__tr__td text-right text-nowrap">
                                {uses.totals.amount !== null ? numeralFormatterCurrency(uses.totals.amount) : '—'}
                            </td>
                            {periodOptions?.map(period => {
                                const targetPeriod = uses.periodsTotals?.find(p => p.periodName === period.label);

                                return (
                                    <PeriodCellValue
                                        key={period.value}
                                        period={period}
                                        value={targetPeriod?.projected !== null
                                            ? numeralFormatterCurrency(targetPeriod?.projected)
                                            : '—'}
                                        cellClassName="NSTable__tfoot__tr__td text-right"
                                    />
                                );
                            })}
                        </tr>
                    </tfoot>
                </NSTable>
            </div>

            <RenderIf isTrue={bulkOption && periodsDateRange}>
                <DateModal
                    isOpen={openDateModal}
                    toggle={toggleDateModal}
                    headerText={proForma.startDate ? 'Start date' : 'End date'}
                    handleUpdateDate={handleBulkUpdateDate}
                    isStartDate={bulkOption === BULK_OPTION.START_DATE}
                    dateRange={periodsDateRange!}
                    isLoadingCallback={isBudgetClassificationUpdatingLoading}
                />
            </RenderIf>

            <RenderIf isTrue={bulkOption && !periodsDateRange}>
                <MissingDateModal
                    isOpen={openDateModal}
                    toggle={toggleDateModal}
                    startDate={proForma.startDate}
                    saleDate={proForma.saleDate}
                    redirectToGeneralInformation={redirectToGeneralInformation}
                />
            </RenderIf>

            <RenderIf isTrue={bulkOption && bulkOption === BULK_OPTION.CURVE}>
                <CurveFitModal
                    isOpen={openCurveModal}
                    toggle={toggleCurveModal}
                    headerText="Curve"
                    handleUpdateProjection={handleBulkUpdateCurveFit}
                    isLoadingCallback={isBudgetClassificationUpdatingLoading}
                />
            </RenderIf>

            <RenderIf isTrue={bulkOption && bulkOption === BULK_OPTION.AMOUNT}>
                <BudgetLineAmountModal
                    isOpen={openAmountModal}
                    toggle={toggleAmountModal}
                    headerText="Amount"
                    handleUpdateAmount={handleBulkUpdateAmount}
                    isLoadingCallback={isBudgetClassificationUpdatingLoading}
                />
            </RenderIf>

            <RenderIf isTrue={deleteAllSelectedCategories}>
                <ConfirmationModal
                    toggle={toggleDeleteSelectedCategories}
                    isOpen={deleteAllSelectedCategories}
                    onConfirm={handleBulkDelete}
                    onCancel={toggleDeleteSelectedCategories}
                    modalHeaderText="Delete multiple selected"
                    modalFooterButtonText="Yes, delete"
                    modalFooterButtonIsLoading={isBudgetClassificationDeletionLoading}
                    danger
                >
                    <div className="text-dark font-weight-bold mb-1">Are you sure you want to delete these multiple items?</div>
                    <div>Your work will not be able to be recovered. Any child content associated with this bulk selection will also be deleted.</div>
                    <div className="text-warning mt-1">Note: any selected high level categories (e.g. Hard Costs) will not be deleted.</div>
                </ConfirmationModal>
            </RenderIf>
        </>
    );
};

export default DevelopmentUsesTable;
