import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEllipsisVertical, faTrash } from '@fortawesome/pro-regular-svg-icons';
import {
    Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
} from 'reactstrap';
import classNames from 'classnames';
import { NSButton } from '../../../../bricks';
import { getFileIcon, iconColorByFilenameExtension } from '../../helpers';
import './RecentDocumentCard.scss';

interface IRecentDocumentCard {
    filename: string;
    uri?: string;
    dateModified?: string;
    format?: string;
}

const RecentDocumentCard = ({
    filename, uri, dateModified, format,
}: IRecentDocumentCard) => {
    const [openOptions, setOpenOptions] = useState(false);

    const handleOpenDocument = useCallback(() => {
        window.open(uri, '_blank');
    }, [uri]);

    return (
        <div className="p-2 d-flex mt-1 mr-3 card RecentDocumentCard__container">
            <div className="d-flex d-flex align-items-center justify-content-between">
                <FontAwesomeIcon
                    className={classNames('p-2 rounded', { [`RecentDocumentCard__${iconColorByFilenameExtension[format!]}`]: true })}
                    icon={getFileIcon({ fileFormat: format, isExpanded: false })}
                    color="white"
                />
                <div className="ml-1 px-1 w-50">
                    <p className="m-0 RecentDocumentCard__truncate">{filename}</p>
                    <span className="text-muted">{dateModified}</span>
                </div>
                <Dropdown isOpen={openOptions} toggle={() => setOpenOptions(!openOptions)} direction="down">
                    <DropdownToggle tag="button" className="RecentDocumentCard__dropdown-toggle">
                        <NSButton color="secondary" icon={faEllipsisVertical} />
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={handleOpenDocument} data-testid="download-action" className="text-white">
                            <div className="d-flex align-items-center justify-content-between w-100">
                                <span>Download</span>
                                <FontAwesomeIcon icon={faDownload} />
                            </div>
                        </DropdownItem>
                        <DropdownItem onClick={() => { }} data-testid="edit-action" className="text-white">
                            <div className="d-flex align-items-center justify-content-between w-100">
                                <span>Edit</span>
                                <FontAwesomeIcon icon={faTrash} />
                            </div>
                        </DropdownItem>
                        <DropdownItem onClick={() => { }} data-testid="delete-action" className="text-white">
                            <div className="d-flex align-items-center justify-content-between w-100">
                                <span>Delete</span>
                                <FontAwesomeIcon icon={faTrash} />
                            </div>
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
        </div>
    );
};

export default RecentDocumentCard;
