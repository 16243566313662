import React, { useMemo } from 'react';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import PDFRenderer from './PDFRenderer';

interface IPreviewFileProps {
    uri?: string;
    type?: string;
    minHeight?: number;
}

const PreviewFile = ({ uri, type, minHeight }: IPreviewFileProps) => {
    if (!uri || !type) {
        return null;
    }
    const renderers = useMemo(() => (type === 'pdf' || type === 'application/pdf' ? [PDFRenderer] : DocViewerRenderers), [type]);

    return (
        <div className="d-flex w-100 h-50 flex-wrap">
            <DocViewer
                className="w-100 h-100"
                style={minHeight ? { minHeight: `${minHeight}px` } : undefined}
                config={{
                    header: {
                        disableHeader: true,
                        disableFileName: true,
                        retainURLParams: false,
                    },
                }}
                pluginRenderers={renderers}
                documents={[
                    {
                        uri,
                        fileType: type,
                    },
                ]}
            />
        </div>
    );
};

export default PreviewFile;
