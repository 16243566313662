/* eslint-disable no-nested-ternary */
/* eslint-disable no-mixed-operators */
import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { NSButton } from 'bricks';
import React, { useState } from 'react';
import './SensitivityTable.scss';
import { formatPercentage } from 'ns_libs/formatter';
import classNames from 'classnames';
import { DEFAULT_GRID_SIZE, SensitivityLabel, SensitivityValue } from './types';

interface SensitivityTableProps {
    data: SensitivityValue[];
    verticalLabel: string;
    horizontalLabel: string;
    verticalDataLabels: SensitivityLabel[];
    horizontalDataLabels: SensitivityLabel[];
    disableVerticalDecrement?: boolean;
    disableHorizontalDecrement?: boolean;
    showDecimal?: boolean;
    onVerticalIncrement: () => void;
    onVerticalDecrement: () => void;
    onHorizontalIncrement: () => void;
    onHorizontalDecrement: () => void;
    onCellClick: (cellData: SensitivityValue | null) => void;
    displayValue: keyof SensitivityValue;
    valueFormatter: (value: string | number) => string;
}

const SensitivityTable = ({
    data,
    verticalLabel,
    horizontalLabel,
    verticalDataLabels,
    horizontalDataLabels,
    disableVerticalDecrement = false,
    disableHorizontalDecrement = false,
    showDecimal = false,
    onVerticalIncrement,
    onVerticalDecrement,
    onHorizontalIncrement,
    onHorizontalDecrement,
    onCellClick,
    displayValue,
    valueFormatter,
}: SensitivityTableProps) => {
    const [focusedCell, setFocusedCell] = useState<{ row: number; col: number } | null>(null);

    const getCellClassName = (rowIndex: number, colIndex: number, cellData?: SensitivityValue) => {
        const isBaseCase = rowIndex === 2 && colIndex === 2;
        const isFocused = focusedCell?.row === rowIndex && focusedCell?.col === colIndex;

        if (isBaseCase) {
            return 'SensitivityTable__cell--base';
        }

        const statusClass = cellData?.status ? `SensitivityTable__cell--${cellData.status}` : 'SensitivityTable__cell--success';

        return `p-2 ${statusClass} ${isFocused && cellData?.status ? `SensitivityTable__cell--${cellData.status}--focused` : ''}`;
    };

    const renderBaseCase = (value: number | undefined) => (
        <div>
            <div className="SensitivityTable__small-font">Base case</div>
            <div className="text-center mt-0">
                {value !== undefined ? valueFormatter(value) : 'N/A'}
            </div>
        </div>
    );

    const handleCellClick = (cellData: SensitivityValue, rowIndex: number, colIndex: number) => {
        if (focusedCell?.row === rowIndex && focusedCell?.col === colIndex) {
            setFocusedCell(null);
            onCellClick(null);
        } else if (!(rowIndex === 2 && colIndex === 2)) {
            setFocusedCell({ row: rowIndex, col: colIndex });
            onCellClick(cellData);
        }
    };

    return (
        <div className="d-flex flex-row w-100">
            <div className="SensitivityTable__vertical-label text-white justify-content-center text-center pt-4">{verticalLabel}</div>
            <div
                className="
                SensitivityTable__vertical-buttons-container
                d-flex
                flex-column
                justify-content-between
                align-items-center pr-1 position-relative"
            >
                <div className="SensitivityTable__buttons--container position-absolute translate-middle-y d-flex flex-column align-items-center">
                    <NSButton
                        className="SensitivityTable__buttons p-1 text-muted cursor--pointer NSButton--secondary-lighten"
                        size="xs"
                        iconOnly
                        icon={faPlus}
                        aria-label="Increment vertical"
                        callback={onVerticalIncrement}
                    />
                    <NSButton
                        className="SensitivityTable__buttons p-1 text-muted cursor--pointer NSButton--secondary-lighten"
                        size="xs"
                        iconOnly
                        icon={faMinus}
                        disabled={disableVerticalDecrement}
                        aria-label="Decrement vertical"
                        callback={!disableVerticalDecrement ? onVerticalDecrement : undefined}
                    />
                </div>

                {verticalDataLabels.map(({ id, label }, index) => (
                    <div key={id} className={classNames('SensitivityTable__vertical-data-label text-muted', { 'text-center': index !== 0 })}>
                        {formatPercentage(parseFloat(label ?? '0') * 100, 100, showDecimal ? 1 : 0)}
                    </div>
                ))}
            </div>

            <div className="w-100">
                <div className="table-responsive">
                    <table className="SensitivityTable w-100 text-center">
                        <tbody>
                            {verticalDataLabels.map((row, rowIndex) => (
                                <tr key={row.id}>
                                    {horizontalDataLabels.map((col, colIndex) => {
                                        const index = rowIndex * DEFAULT_GRID_SIZE + colIndex;
                                        const cellData = data[index];

                                        return (
                                            <td
                                                key={cellData?.id ?? `cell-${rowIndex}-${colIndex}`}
                                                className={`SensitivityTable__cell cursor--pointer ${getCellClassName(rowIndex, colIndex, cellData)}`}
                                                onClick={() => {
                                                    handleCellClick(cellData, rowIndex, colIndex);
                                                }}
                                            >
                                                {rowIndex === 2 && colIndex === 2
                                                    ? renderBaseCase(cellData?.[displayValue] as number | undefined)
                                                    : cellData?.[displayValue] !== undefined
                                                        ? valueFormatter(cellData[displayValue]!)
                                                        : 'N/A'}
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="d-flex flex-row justify-content-between position-relative align-items-center pt-1">
                        {horizontalDataLabels.map(({ id, label }) => (
                            <div key={id} className="SensitivityTable__small-font text-center border-none px-0">
                                {formatPercentage(parseFloat(label ?? '0') * 100, 100, showDecimal ? 1 : 0)}
                            </div>
                        ))}

                        <div className="SensitivityTable__horizontal-buttons-container position-absolute d-flex flex-row align-items-center">
                            <NSButton
                                className="SensitivityTable__buttons p-1 mr-1 text-muted cursor--pointer NSButton--secondary-lighten"
                                size="sm"
                                iconOnly
                                icon={faMinus}
                                disabled={disableHorizontalDecrement}
                                callback={!disableHorizontalDecrement ? onHorizontalDecrement : undefined}
                            />
                            <NSButton
                                className="SensitivityTable__buttons p-1 text-muted cursor--pointer NSButton--secondary-lighten"
                                size="sm"
                                iconOnly
                                icon={faPlus}
                                callback={onHorizontalIncrement}
                            />
                        </div>
                    </div>
                    <div className="text-center text-white">{horizontalLabel}</div>
                </div>
            </div>
        </div>
    );
};

export default SensitivityTable;
