import React, { useEffect, useState } from 'react';
import NSLabel from 'bricks/NSLabel/NSLabel';
import {
    Input, InputGroup, InputGroupAddon, InputGroupText, UncontrolledTooltip,
} from 'reactstrap';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RenderIf from 'components/RenderIf/RenderIf';
import 'css/partials/_bricks.NSInputNumberStepper.scss';

export interface INSInputNumberStepperProps {
    name: string;
    id?: string;
    labelClassName?: string;
    labelText?: string;
    value: number | null;
    inputGroupClassName?: string;
    min?: number;
    max?: number;
    onChange?: any;
    icon?: any;
    iconTooltipText?: string;
    required?: boolean;
    step: number;
    percentage?: boolean;
    customSuffix?: string;
    showErrorMsg?: boolean;
    errorMsg?: string;
    onBlur?: () => void;
}

const NSInputNumberStepper = ({
    name,
    id,
    labelClassName = '',
    labelText = '',
    value,
    inputGroupClassName,
    min,
    max,
    onChange,
    step,
    icon,
    iconTooltipText,
    customSuffix,
    required = false,
    percentage = false,
    showErrorMsg,
    errorMsg,
    onBlur,
}: INSInputNumberStepperProps) => {
    const [intermediateValue, setIntermediateValue] = useState<string>('');

    const getDisplayValue = (val: string) => {
        if (Number.isNaN(Number(val))) return '';
        if (percentage && val.length) return `${val}%`;
        if (customSuffix && val.length) return `${val} ${customSuffix}`;
        return val;
    };

    const countDown = (prevValue: string) => {
        let newValue = Number(prevValue) - step;
        if (min !== undefined) newValue = Math.max(min, newValue);
        newValue = Math.max(0, newValue);

        const formattedValue = Number.isInteger(step) ? Math.round(newValue) : parseFloat(newValue.toFixed(1));
        handleChange(String(formattedValue));
    };

    const countUp = (prevValue: string) => {
        let newValue = Number(prevValue) + step;
        if (max !== undefined) newValue = Math.min(max, newValue);

        const formattedValue = Number.isInteger(step) ? Math.round(newValue) : parseFloat(newValue.toFixed(1));
        handleChange(String(formattedValue));
    };

    const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;

        const cleanValue = newValue.replace(/[^0-9.]/g, '') // Keep only numbers and '.'
            .replace(/^(\d*\.\d*)\.+/, '$1'); // Prevent multiple decimals

        setIntermediateValue(cleanValue);
    };

    const handleChange = (value: string) => {
        let numericValue = parseFloat(value);

        if (Number.isNaN(numericValue)) return;

        if (value.endsWith('.')) {
            numericValue = Number(value.slice(0, -1));
        }

        if (min !== undefined) numericValue = Math.max(min, numericValue);
        if (max !== undefined) numericValue = Math.min(max, numericValue);

        const formattedValue = Number.isInteger(step)
            ? Math.round(numericValue)
            : Number(numericValue.toFixed(1));

        onChange(formattedValue);
    };

    const handleBlur = () => {
        if (onBlur) {
            onBlur();
        } else {
            handleChange(intermediateValue);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'ArrowUp') {
            countUp(intermediateValue);
        } else if (event.key === 'ArrowDown') {
            countDown(intermediateValue);
        } else if (event.key === 'Enter') {
            handleChange(intermediateValue);
        } else if (event.key === 'Escape') {
            setIntermediateValue(`${value}`);
        }
    };

    useEffect(() => {
        setIntermediateValue(value === null ? '' : `${value}`);
    }, [value]);

    return (
        <>
            <RenderIf isTrue={labelText}>
                <NSLabel for={name} className={labelClassName}>
                    {labelText}
                    <RenderIf isTrue={required}>
                        <span className="text-danger ml-1">*</span>
                    </RenderIf>
                    <RenderIf isTrue={icon}>
                        <FontAwesomeIcon icon={icon} className="pl-1 cursor-pointer" id="numberStepperIcon" />
                        <RenderIf isTrue={iconTooltipText}>
                            <UncontrolledTooltip target="numberStepperIcon">{iconTooltipText}</UncontrolledTooltip>
                        </RenderIf>
                    </RenderIf>
                </NSLabel>
            </RenderIf>
            <InputGroup className={`d-flex NSInputNumberStepper ${inputGroupClassName}`}>
                <InputGroupAddon addonType="prepend" className="NSInputNumberStepper__addon-prepend cursor--pointer">
                    <InputGroupText className="py-0 NSInputNumberStepper__addon-prepend__text" onClick={() => countDown(intermediateValue)}>
                        <FontAwesomeIcon icon={faMinus} data-testid="prependButton" />
                    </InputGroupText>
                </InputGroupAddon>
                <Input
                    type="text"
                    id={id}
                    name={name}
                    value={getDisplayValue(intermediateValue)}
                    placeholder="Enter"
                    className="NSInputNumberStepper__input border-right-0 border-left-0 text-center"
                    onChange={handleNumberChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    min={min}
                    max={max}
                />
                <InputGroupAddon addonType="append" className="NSInputNumberStepper__addon-append cursor--pointer">
                    <InputGroupText className="py-0 NSInputNumberStepper__addon-append__text" onClick={() => countUp(intermediateValue)}>
                        <FontAwesomeIcon icon={faPlus} data-testid="appendButton" />
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>

            <RenderIf isTrue={showErrorMsg}>
                <p className="text-danger small mt-1">{errorMsg}</p>
            </RenderIf>
        </>
    );
};

export default NSInputNumberStepper;
