import { ProFormaExecutiveSummary } from 'views/ProFormaTable/types';
import { Section } from './ExecutiveSummaryGenericTable/types';

export function formatReturns(returns: ProFormaExecutiveSummary['returns']): Section[] {
    return [
        formatPropertyReturns(returns.property),
        formatPartnershipReturns(returns.partnership),
        formatDevelopmentReturns(returns.development),
    ];
}

function formatPartnershipReturns(partnership: ProFormaExecutiveSummary['returns']['partnership']): Section {
    return {
        id: 'partnership-returns-section',
        title: 'Partnership returns',
        columns: ['ROI', 'EMx', 'IRR'],
        rows: [
            {
                id: 'limited-partner-row',
                label: 'Limited Partner',
                values: [
                    { value: partnership.limitedPartner.roi, format: 'percentage' },
                    { value: partnership.limitedPartner.emx, format: 'none', label: 'X' },
                    { value: partnership.limitedPartner.irr, format: 'percentage' },
                ],
            },
            {
                id: 'general-partner-row',
                label: 'General Partner',
                values: [
                    { value: partnership.generalPartner.roi, format: 'percentage' },
                    { value: partnership.generalPartner.emx, format: 'none', label: 'X' },
                    { value: partnership.generalPartner.irr, format: 'percentage' },
                ],
            },
        ],
    };
}

function formatPropertyReturns(property: ProFormaExecutiveSummary['returns']['property']): Section {
    return {
        id: 'property-returns-section',
        title: 'Property returns',
        columns: ['ROI', 'EMx', 'IRR'],
        rows: [
            {
                id: 'unlevered-row',
                label: 'Unlevered',
                values: [
                    { value: property.unlevered.roi, format: 'percentage' },
                    { value: property.unlevered.emx, format: 'none', label: 'X' },
                    { value: property.unlevered.irr, format: 'percentage' },
                ],
            },
            {
                id: 'levered-row',
                label: 'Levered',
                values: [
                    { value: property.levered.roi, format: 'percentage' },
                    { value: property.levered.emx, format: 'none', label: 'X' },
                    { value: property.levered.irr, format: 'percentage' },
                ],
            },
        ],
    };
}

function formatDevelopmentReturns(development: ProFormaExecutiveSummary['returns']['development']): Section {
    return {
        id: 'development-returns-section',
        title: 'Development returns',
        columns: ['Untrended', 'Trended', 'Sale'],
        rows: [
            {
                id: 'yield-on-cost-row',
                label: 'Yield-on-cost',
                values: [
                    { value: development.yieldOnCost.untrended, format: 'percentage' },
                    { value: development.yieldOnCost.trended, format: 'percentage' },
                    { value: development.yieldOnCost.sale, format: 'percentage' },
                ],
            },
            {
                id: 'market-cap-rate-row',
                label: 'Market cap rate',
                values: [
                    { value: development.marketCapRate.untrended, format: 'percentage' },
                    { value: development.marketCapRate.trended, format: 'percentage' },
                    { value: development.marketCapRate.sale, format: 'percentage' },
                ],
            },
            {
                id: 'development-spread-row',
                label: 'Development spread',
                values: [
                    { value: development.developmentSpread.untrended, format: 'none', label: ' bps' },
                    { value: development.developmentSpread.trended, format: 'none', label: ' bps' },
                    { value: development.developmentSpread.sale, format: 'none', label: ' bps' },
                ],
            },
        ],
    };
}

export function formatOperations(cashFlows: ProFormaExecutiveSummary['cashFlows']): Section[] {
    return [
        formatCashFlows(cashFlows),
        formatNetOperatingIncome(cashFlows),
        formatCashFlowFromOperations(cashFlows),
    ];
}

function formatCashFlows(cashFlows: ProFormaExecutiveSummary['cashFlows']): Section {
    return {
        id: 'cash-flows-section',
        title: 'Cash flows',
        columns: ['Untrended', 'Trended', 'Sale'],
        rows: [
            {
                id: 'effective-gross-revenue',
                label: 'Effective gross revenue',
                values: [
                    { value: cashFlows.effectiveGrossRevenue.untrended, format: 'currency' },
                    { value: cashFlows.effectiveGrossRevenue.trended, format: 'currency' },
                    { value: cashFlows.effectiveGrossRevenue.sale, format: 'currency' },
                ],
            },
            {
                id: 'operating-expenses',
                label: 'Operating expenses',
                values: [
                    { value: cashFlows.operatingExpenses.untrended, format: 'currency', isNegative: true },
                    { value: cashFlows.operatingExpenses.trended, format: 'currency', isNegative: true },
                    { value: cashFlows.operatingExpenses.sale, format: 'currency', isNegative: true },
                ],
            },
        ],
    };
}

function formatNetOperatingIncome(cashFlows: ProFormaExecutiveSummary['cashFlows']): Section {
    return {
        id: 'net-operating-income-section',
        rows: [
            {
                id: 'net-operating-income',
                label: 'Net operating income',
                values: [
                    { value: cashFlows.netOperatingIncome.untrended, format: 'currency' },
                    { value: cashFlows.netOperatingIncome.trended, format: 'currency' },
                    { value: cashFlows.netOperatingIncome.sale, format: 'currency' },
                ],
            },
            {
                id: 'capital-expenditures',
                label: 'Capital expenditures',
                values: [
                    { value: cashFlows.capitalExpenditures.untrended, format: 'currency', isNegative: true },
                    { value: cashFlows.capitalExpenditures.trended, format: 'currency', isNegative: true },
                    { value: cashFlows.capitalExpenditures.sale, format: 'currency', isNegative: true },
                ],
            },
        ],
    };
}

function formatCashFlowFromOperations(cashFlows: ProFormaExecutiveSummary['cashFlows']): Section {
    return {
        id: 'cash-flow-from-operations-section',
        rows: [
            {
                id: 'cash-flow-operations',
                label: 'Cash flow from operations',
                values: [
                    { value: cashFlows.cashFlowOperations.untrended, format: 'currency' },
                    { value: cashFlows.cashFlowOperations.trended, format: 'currency' },
                    { value: cashFlows.cashFlowOperations.sale, format: 'currency' },
                ],
            },
            {
                id: 'cap-rate',
                label: 'Cap rate',
                values: [
                    { value: cashFlows.capRate.untrended, format: 'percentage' },
                    { value: cashFlows.capRate.trended, format: 'percentage' },
                    { value: cashFlows.capRate.sale, format: 'percentage' },
                ],
            },
            {
                id: 'valuation',
                label: 'Valuation',
                values: [
                    { value: cashFlows.valuation.untrended, format: 'currency' },
                    { value: cashFlows.valuation.trended, format: 'currency' },
                    { value: cashFlows.valuation.sale, format: 'currency' },
                ],
            },
            {
                id: 'total-project-cost',
                label: '% of total project cost',
                values: [
                    { value: cashFlows.totalProjectCost.untrended, format: 'percentage' },
                    { value: cashFlows.totalProjectCost.trended, format: 'percentage' },
                    { value: cashFlows.totalProjectCost.sale, format: 'percentage' },
                ],
            },
            {
                id: 'valuation-unit',
                label: 'Valuation / unit',
                values: [
                    { value: cashFlows.valuationPerUnit.untrended, format: 'currency' },
                    { value: cashFlows.valuationPerUnit.trended, format: 'currency' },
                    { value: cashFlows.valuationPerUnit.sale, format: 'currency' },
                ],
            },
        ],
    };
}

export function formatDevelopment(development: ProFormaExecutiveSummary['development']): Section[] {
    return [
        formatDevelopmentUses(development),
        formatDevelopmentUsesTotal(development.totalUses),
        formatDevelopmentSources(development),
        formatDevelopmentSourcesTotal(development.totalSources),
    ];
}

function formatDevelopmentUses(development: ProFormaExecutiveSummary['development']): Section {
    return {
        id: 'uses',
        title: 'Uses',
        columns: ['%', '$ / Built SF', '$ / Unit', 'Amount'],
        rows: [
            {
                id: 'land-costs',
                label: 'Land costs',
                values: [
                    { value: development.landCosts.percentage, format: 'percentage' },
                    { value: development.landCosts.perBuiltSf, format: 'currency' },
                    { value: development.landCosts.perUnit, format: 'currency' },
                    { value: development.landCosts.amount, format: 'currency' },
                ],
            },
            {
                id: 'hard-costs',
                label: 'Hard costs',
                values: [
                    { value: development.hardCosts.percentage, format: 'percentage' },
                    { value: development.hardCosts.perBuiltSf, format: 'currency' },
                    { value: development.hardCosts.perUnit, format: 'currency' },
                    { value: development.hardCosts.amount, format: 'currency' },
                ],
            },
            {
                id: 'soft-costs',
                label: 'Soft costs',
                values: [
                    { value: development.softCosts.percentage, format: 'percentage' },
                    { value: development.softCosts.perBuiltSf, format: 'currency' },
                    { value: development.softCosts.perUnit, format: 'currency' },
                    { value: development.softCosts.amount, format: 'currency' },
                ],
            },
            {
                id: 'carry-costs',
                label: 'Carry costs',
                values: [
                    { value: development.carryCosts.percentage, format: 'percentage' },
                    { value: development.carryCosts.perBuiltSf, format: 'currency' },
                    { value: development.carryCosts.perUnit, format: 'currency' },
                    { value: development.carryCosts.amount, format: 'currency' },
                ],
            },
        ],
    };
}

function formatDevelopmentUsesTotal(totalUses: ProFormaExecutiveSummary['development']['totalUses']): Section {
    return {
        id: 'uses-total',
        isTotal: true,
        rows: [
            {
                id: 'total-uses',
                label: 'Total uses',
                values: [
                    { value: totalUses.percentage, format: 'percentage' },
                    { value: totalUses.perBuiltSf, format: 'currency' },
                    { value: totalUses.perUnit, format: 'currency' },
                    { value: totalUses.amount, format: 'currency' },
                ],
            },
        ],
    };
}

function formatDevelopmentSources(development: ProFormaExecutiveSummary['development']): Section {
    return {
        id: 'sources',
        title: 'Sources',
        rows: [
            {
                id: 'construction-loan',
                label: 'Construction loan',
                values: [
                    { value: development.constructionLoan.percentage, format: 'percentage' },
                    { value: development.constructionLoan.perBuiltSf, format: 'currency' },
                    { value: development.constructionLoan.perUnit, format: 'currency' },
                    { value: development.constructionLoan.amount, format: 'currency' },
                ],
            },
            {
                id: 'mezzanine-loan',
                label: 'Mezzanine loan',
                values: [
                    { value: development.mezzanineLoan.percentage, format: 'percentage' },
                    { value: development.mezzanineLoan.perBuiltSf, format: 'currency' },
                    { value: development.mezzanineLoan.perUnit, format: 'currency' },
                    { value: development.mezzanineLoan.amount, format: 'currency' },
                ],
            },
            {
                id: 'limited-partner',
                label: 'Limited Partner',
                values: [
                    { value: development.limitedPartner.percentage, format: 'percentage' },
                    { value: development.limitedPartner.perBuiltSf, format: 'currency' },
                    { value: development.limitedPartner.perUnit, format: 'currency' },
                    { value: development.limitedPartner.amount, format: 'currency' },
                ],
            },
            {
                id: 'general-partner',
                label: 'General Partner',
                values: [
                    { value: development.generalPartner.percentage, format: 'percentage' },
                    { value: development.generalPartner.perBuiltSf, format: 'currency' },
                    { value: development.generalPartner.perUnit, format: 'currency' },
                    { value: development.generalPartner.amount, format: 'currency' },
                ],
            },
        ],
    };
}

function formatDevelopmentSourcesTotal(totalSources: ProFormaExecutiveSummary['development']['totalSources']): Section {
    return {
        id: 'sources-total',
        isTotal: true,
        rows: [
            {
                id: 'total-sources',
                label: 'Total sources',
                values: [
                    { value: totalSources.percentage, format: 'percentage' },
                    { value: totalSources.perBuiltSf, format: 'currency' },
                    { value: totalSources.perUnit, format: 'currency' },
                    { value: totalSources.amount, format: 'currency' },
                ],
            },
        ],
    };
}
