import React from 'react';
import { NSCard } from 'bricks';
import './ExecutiveSummaryCard.scss';
import RenderIf from 'components/RenderIf/RenderIf';
import { IExecutiveSummaryCardProps, ISummaryItem } from './types';
import DescriptionItem from './DescriptionItem';
import SummaryItem from './SummaryItem';

const ExecutiveSummaryCard = ({ sections, containerClassName }: IExecutiveSummaryCardProps) => {
    const renderItem = (
        id: string, props: Omit<ISummaryItem, 'id'>,
    ) => (id === 'description'
        ? <DescriptionItem key={id} {...props} />
        : <SummaryItem key={id} {...props} />);

    return (
        <NSCard className={`NSCard--level-4 flex-1 p-3 ${containerClassName}`}>
            {sections.map(({ id, title, items }) => (
                <div key={id}>
                    <RenderIf isTrue={title}>
                        <div className={`d-flex justify-content-between align-items-center mb-1 ${id === 'description' && 'mt-2'}`}>
                            <h5 className="ExecutiveSummaryCard__title text-uppercase text-primary">{title}</h5>
                        </div>
                        <RenderIf isTrue={id === 'details'}>
                            <div className="separator my-1" />
                        </RenderIf>
                    </RenderIf>

                    <div className="d-flex flex-column">{items.map(({ id, ...props }) => renderItem(id, props))}</div>

                    <RenderIf isTrue={id !== sections[sections.length - 1].id}>
                        <div className="separator my-1" />
                    </RenderIf>
                </div>
            ))}
        </NSCard>
    );
};

export default ExecutiveSummaryCard;
