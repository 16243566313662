import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

export interface INSBreadcrumbBackLinkProps {
    message?: string;
    callback: () => void;
    containerClassName?: string;
    containerStyle?: any;
    icon?: any;
    isSmall?: boolean;
}

const NSBreadcrumbBackLink = ({
    message, callback, containerClassName, containerStyle, icon, isSmall = true,
}: INSBreadcrumbBackLinkProps) => (
    <div className={containerClassName} style={containerStyle}>
        <span className={classNames(['cursor--pointer text-primary', { small: !!isSmall }])} onClick={callback} role="presentation">
            <FontAwesomeIcon icon={icon || faChevronLeft} className="pr-1" />
            {message || 'back'}
        </span>
    </div>
);

export default NSBreadcrumbBackLink;
