import React from 'react';
import { useField } from 'formik';
import { NSInput } from 'bricks';
import RenderIf from 'components/RenderIf/RenderIf';
import { InputType } from 'reactstrap/es/Input';
import { DefaultAttributes } from '../../types';
import { useGetAttributes } from '../../hooks/useGetAttributes';

interface DefaultAttributeInputProps {
  name: string;
  label: string;
  placeholder?: string;
  type?: InputType;
  attribute: DefaultAttributes;
  showRequiredOnly: boolean;
  className?: string;
  prependInputAddon?: string | JSX.Element | null;
  rows?: number;
  additionalCondition?: boolean;
  onBlur?: (value: string) => void;
}

const DefaultAttributeInput: React.FC<DefaultAttributeInputProps> = ({
    name,
    label,
    placeholder,
    type = 'text',
    attribute,
    showRequiredOnly,
    className = '',
    prependInputAddon,
    rows,
    additionalCondition = true,
    onBlur,
}) => {
    const { data: attributesConfig } = useGetAttributes();
    const [field, meta] = useField(name);

    const getAttributeIsRequired = (field: DefaultAttributes): boolean => {
        const attributeConfig = attributesConfig?.find(({ name }) => name === field);
        return attributeConfig?.required || false;
    };

    const isRequired = getAttributeIsRequired(attribute);
    const shouldRender = !showRequiredOnly || (showRequiredOnly && isRequired && additionalCondition);

    const handleBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
        field.onBlur(e);
        if (onBlur) {
            onBlur(e.target.value);
        }
    };

    return (
        <RenderIf isTrue={shouldRender}>
            <div className={className}>
                <NSInput
                    label={label}
                    type={type}
                    placeholder={placeholder}
                    prependInputAddon={prependInputAddon}
                    errorMsg={meta.touched && meta.error ? meta.error : null}
                    showErrorMsg={!!(meta.touched && meta.error)}
                    required={isRequired}
                    rows={rows}
                    {...field}
                    onBlur={handleBlur}
                />
            </div>
        </RenderIf>
    );
};

export default DefaultAttributeInput;
