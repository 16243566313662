import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { IExcelReportData } from 'types/excelExport';

export const excelExport = (
    reportName: string,
    showGrid: boolean,
    reportData: IExcelReportData,
    addBlankRowsBetweenTables: boolean = true,
    hiddenColumns: number[] = [],
) => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Northspyre';
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(reportName, {
        pageSetup: { orientation: 'landscape' },
        views: [{ showGridLines: showGrid }],
    });

    populateExcelWorksheet(worksheet, reportData, addBlankRowsBetweenTables, hiddenColumns);

    // Download to client
    workbook.xlsx.writeBuffer().then(data => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `${reportName}.xlsx`);
    });
};

export const populateExcelWorksheet = (
    worksheet: ExcelJS.Worksheet,
    reportData: IExcelReportData,
    addBlankRowsBetweenTables: boolean = true,
    hiddenColumns: number[] = [],
) => {
    // Add report headers
    reportData.topHeaderRows.forEach(headerRow => {
        populateRow(headerRow, worksheet);
    });
    // Add blank row between headers and tables
    addBlankRow(worksheet);

    // Add tables
    reportData.reportTables.forEach(table => {
        // Add table headers
        table.tableHeaderRows.forEach(header => {
            populateRow(header, worksheet);
        });
        // Add table data
        table.tableData.forEach(row => {
            populateRow(row, worksheet);
        });
        // Add table footer
        populateRow(table.tableFooter, worksheet);

        // Add blank row after each table
        if (addBlankRowsBetweenTables) {
            addBlankRow(worksheet);
        }
    });

    // Hide columns as needed
    if (hiddenColumns.length) {
        hiddenColumns.forEach(columnNumber => {
            const col = worksheet.getColumn(columnNumber);
            col.hidden = true;
        });
    }
};

const addBlankRow = (worksheet: any) => {
    const { rowCount } = worksheet;
    const blankRow = worksheet.getRow(rowCount + 1);
    blankRow.commit();
};

export const populateRow = (rowData: any, worksheet: any) => {
    const { rowCount } = worksheet;
    const currentRow = worksheet.getRow(rowCount + 1);
    if (rowData.rowFont) {
        currentRow.font = rowData.rowFont;
    }
    if (rowData.rowHeight) {
        currentRow.height = rowData.rowHeight;
    }
    if (rowData.outlineLevel > 0) {
        currentRow.outlineLevel = rowData.outlineLevel;
    }
    rowData.rowCells.forEach((cell: any, index: number) => {
        const cellIndex = index + 1;
        currentRow.getCell(cellIndex).value = cell.value;
        if (cell.width) {
            // eslint-disable-next-line no-param-reassign
            worksheet.getColumn(cellIndex).width = cell.width;
        }
        if (cell.alignment) {
            currentRow.getCell(cellIndex).alignment = cell.alignment;
        }
        if (cell.cellFont) {
            currentRow.getCell(cellIndex).font = cell.cellFont;
        }
        if (cell.border) {
            currentRow.getCell(cellIndex).border = cell.border;
        }
        if (rowData.rowFill) {
            currentRow.getCell(cellIndex).fill = rowData.rowFill;
        }
        if (cell.cellFill) {
            currentRow.getCell(cellIndex).fill = cell.cellFill;
        }
        if (cell.numFmt) {
            currentRow.getCell(cellIndex).numFmt = cell.numFmt;
        }
    });
    currentRow.commit();
};
