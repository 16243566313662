import React, { useRef } from 'react';

export const useScrollableSync = () => {
    const scrollableSections = useRef<(HTMLDivElement | null)[]>([]);

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const { scrollLeft } = event.target as HTMLDivElement;
        scrollableSections.current.forEach(section => section?.scrollTo?.({ left: scrollLeft }));
    };

    const handleWheel = (event: WheelEvent) => {
        // Only handle if horizontal scroll is significant and larger than vertical
        if (Math.abs(event.deltaX) > 0.5 && Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
            event.preventDefault();
            scrollableSections.current.forEach(section => section?.scrollTo?.({ left: section.scrollLeft + event.deltaX }));
        }
    };

    const handleScrollableRef = (index: number) => (el: HTMLDivElement | null) => {
        scrollableSections.current[index] = el;
        if (el) {
            el.addEventListener('wheel', handleWheel, { passive: false });
        }
    };

    return {
        handleScroll,
        handleScrollableRef,
    };
};
