import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { useMutation } from '@tanstack/react-query';
import { ICreateReportPostData, IReportDetails } from '../types';

interface ICreateReportParams {
    postData: ICreateReportPostData;
    orgId: string;
}

const createReport = async ({ postData, orgId }: ICreateReportParams): Promise<IReportDetails> => {
    try {
        const response = await axiosInstance.post(`${API_ENDPOINTS.ORG_REPORTS(orgId)}`, postData);
        return response.data;
    } catch (error: any) {
        if (error.response?.status !== 500) {
            throw new Error(error.response.data);
        }
        throw new Error('An unexpected error occurred. Please try again.');
    }
};

export const useCreateReport = () => useMutation({
    mutationFn: createReport,
});

export default useCreateReport;
