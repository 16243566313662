import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { useMutation } from '@tanstack/react-query';

const deleteDocument = async (documentId: number): Promise<boolean> => {
    try {
        const response = await axiosInstance.delete(`${API_ENDPOINTS.DOCUMENT_BY_ID(documentId)}`);
        return response.data;
    } catch (error: any) {
        if (error.response?.status !== 500) {
            throw new Error(error.response.data);
        }
        throw new Error('An unexpected error occurred. Please try again.');
    }
};

export const useDeleteDocument = () => useMutation({
    mutationFn: deleteDocument,
});

export default useDeleteDocument;
