/* eslint-disable operator-linebreak */
import { NORTHSPYRE_TIER_TYPES } from 'bricks/constants';
import useAuthContext from 'contexts/AuthContext';
import { useGetOrganizationLicenses } from 'hooks/useGetOrganizationLicenses';

export const useOrganizationAccess = () => {
    const { selectedOrganizationId } = useAuthContext();

    const {
        data: licenses,
        isLoading,
        error,
    } = useGetOrganizationLicenses(selectedOrganizationId!, {
        enabled: Boolean(selectedOrganizationId),
    });

    const hasCoreAccess =
        licenses &&
        (!!licenses[NORTHSPYRE_TIER_TYPES.NORTHSPYRE_PLAN].length ||
            !!licenses[NORTHSPYRE_TIER_TYPES.NORTHSPYRE_PLAN_PLUS].length ||
            !!licenses[NORTHSPYRE_TIER_TYPES.NORTHSPYRE_PRO].length ||
            !!licenses[NORTHSPYRE_TIER_TYPES.NORTHSPYRE_ENTERPRISE].length ||
            !!licenses[NORTHSPYRE_TIER_TYPES.NORTHSPYRE_HISTORICAL_DATA].length);

    const hasEnterpriseLicense = !!licenses?.[NORTHSPYRE_TIER_TYPES.NORTHSPYRE_ENTERPRISE].length;

    return {
        hasCoreAccess, hasEnterpriseLicense, isLoading, error,
    };
};
