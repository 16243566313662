import React from 'react';
import ProFormaReturnsTable from 'components/ProFormaReturnsTable/ProFormaReturnsTable';
import { IOptionAnyValue } from 'bricks/types';
import { IAggregateReportResponse } from 'views/Reports/types';

export interface IProps {
    selectedPeriod: IOptionAnyValue;
    data: IAggregateReportResponse;
}

export const AggregateProFormaReturnsTable = ({ data, selectedPeriod }: IProps) => (
    <div className="pt-2">
        <ProFormaReturnsTable
            proFormaReturns={data.proFormaReturns}
            cashFlowData={data.cashFlowData}
            costMetrics={data.costMetrics}
            selectedPeriod={selectedPeriod}
        />
    </div>
);

export default AggregateProFormaReturnsTable;
