/* eslint-disable no-unused-vars */

export enum PageSpecificKeys {
    ReportPage = 'reportPage',
    DealsPage = 'dealsPage',
    DealsPageColumnOrder = 'dealsPageColumnOrder',
    DealDashboardPage = 'dealDashboardPage',
    ProFormaPage = 'proFormaPage',
}

export enum GlobalKeys {
    Placeholder = 'placeholder', // Placeholder key to enforce type checking for key names until a global key exists.
}
