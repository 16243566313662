import { useState, useEffect } from 'react';

const useScript = (src: string, id: string): { loaded: boolean; error: Error | null } => {
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        if (document.getElementById(id)) {
            setLoaded(true);
            return () => {};
        }

        const script = document.createElement('script');
        script.id = id;
        script.type = 'text/javascript';
        script.async = true;
        script.src = src;

        const onScriptLoad = () => setLoaded(true);
        const onScriptError = () => {
            setError(new Error(`Error loading script ${src}`));
            script.remove();
        };

        script.addEventListener('load', onScriptLoad);
        script.addEventListener('error', onScriptError);

        document.head.appendChild(script);

        return () => {
            script.removeEventListener('load', onScriptLoad);
            script.removeEventListener('error', onScriptError);
        };
    }, [src, id]);

    return { loaded, error };
};

export default useScript;
