import * as Yup from 'yup';
import { DefaultAttributes, IDealAttribute } from './types';

export const CUSTOM_ATTRIBUTE_IS_REQUIRED_ERROR_SUFFIX = ' is required';
export const CUSTOM_ATTRIBUTE_IS_REQUIRED_ERROR_SEPARATOR = ', ';

const emptyToZero = (value: any) => {
    if (value === '' || value === undefined || value === null) {
        return 0;
    }
    return value;
};

export const buildDealSchemaFields = (attributesConfig?: IDealAttribute[]) => {
    const isFieldRequired = (fieldName: DefaultAttributes): boolean => {
        if (!attributesConfig) return true;
        const attribute = attributesConfig.find(attr => attr.name === fieldName);
        return attribute ? attribute.required : false;
    };

    return Yup.object().shape({
        dealName: isFieldRequired(DefaultAttributes.NAME)
            ? Yup.string().required('Deal name is required')
            : Yup.string(),

        address: Yup.string().when('coordinatesSwitch', {
            is: true,
            then: () => Yup.string(),
            otherwise: () => (isFieldRequired(DefaultAttributes.ADDRESS)
                ? Yup.string().required('Address is required')
                : Yup.string()),
        }),

        city: Yup.string().when('coordinatesSwitch', {
            is: true,
            then: () => Yup.string(),
            otherwise: () => (isFieldRequired(DefaultAttributes.CITY)
                ? Yup.string().required('City is required')
                : Yup.string()),
        }),

        county: Yup.string().when('coordinatesSwitch', {
            is: true,
            then: () => Yup.string(),
            otherwise: () => (isFieldRequired(DefaultAttributes.COUNTY)
                ? Yup.string().required('County is required')
                : Yup.string()),
        }),

        state: Yup.string().when('coordinatesSwitch', {
            is: true,
            then: () => Yup.string(),
            otherwise: () => (isFieldRequired(DefaultAttributes.STATE)
                ? Yup.string().required('State is required')
                : Yup.string()),
        }),

        zipCode: Yup.string().when('coordinatesSwitch', {
            is: true,
            then: () => Yup.string(),
            otherwise: () => (isFieldRequired(DefaultAttributes.ZIP_CODE)
                ? Yup.string()
                    .required('Zip is required')
                    .matches(/^[a-zA-Z0-9]+$/, 'Zip must contain only letters and numbers')
                    .min(5, 'Zip must be at least 5 characters')
                    .max(10, 'Zip must be at most 10 characters')
                : Yup.string()
                    .matches(/^[a-zA-Z0-9]+$/, 'Zip must contain only letters and numbers')
                    .min(5, 'Zip must be at least 5 characters')
                    .max(10, 'Zip must be at most 10 characters')),
        }),

        latitude: Yup.number().when('coordinatesSwitch', {
            is: true,
            then: schema => (isFieldRequired(DefaultAttributes.ADDRESS)
                ? schema.required('Latitude is required')
                : schema),
            otherwise: schema => schema,
        }),

        longitude: Yup.number().when('coordinatesSwitch', {
            is: true,
            then: schema => (isFieldRequired(DefaultAttributes.ADDRESS)
                ? schema.required('Longitude is required')
                : schema),
            otherwise: schema => schema,
        }),

        dealTypeId: isFieldRequired(DefaultAttributes.DEAL_TYPE)
            ? Yup.mixed().required('Please select one')
            : Yup.mixed(),

        assetTypeId: isFieldRequired(DefaultAttributes.ASSET_CLASS)
            ? Yup.mixed().required('Please select one')
            : Yup.mixed(),

        constructionTypeId: isFieldRequired(DefaultAttributes.CONSTRUCTION_TYPE)
            ? Yup.mixed().required('Please select one')
            : Yup.mixed(),

        hasSubtype: Yup.boolean(),
        assetSubtypeId: Yup.mixed().when('hasSubtype', {
            is: true,
            then: schema => (isFieldRequired(DefaultAttributes.ASSET_CLASS)
                ? schema.required('Please select one')
                : schema),
            otherwise: schema => schema.optional(),
        }),

        marketId: isFieldRequired(DefaultAttributes.MARKET)
            ? Yup.mixed().required('Please select one')
            : Yup.mixed(),

        submarket: isFieldRequired(DefaultAttributes.SUBMARKET)
            ? Yup.string().required('Submarket is required')
            : Yup.string(),

        acquisitionPrice: Yup.number()
            .transform(value => emptyToZero(value))
            .concat(
                isFieldRequired(DefaultAttributes.ACQUISITION_PRICE)
                    ? Yup.number().required('Acquisition price is required')
                    : Yup.number(),
            )
            .typeError('Must be a number')
            .positive('Must be a positive value'),

        description: Yup.string().concat(
            isFieldRequired(DefaultAttributes.DESCRIPTION)
                ? Yup.string().required('Description is required')
                : Yup.string().optional(),
        ),

        coordinatesSwitch: Yup.boolean(),

        phaseId: isFieldRequired(DefaultAttributes.PHASE)
            ? Yup.mixed().required('Please select one')
            : Yup.mixed(),

        leadSourceId: isFieldRequired(DefaultAttributes.LEAD_SOURCE)
            ? Yup.mixed().required('Please select one')
            : Yup.mixed(),

        leasingStrategyId: isFieldRequired(DefaultAttributes.LEASING_STRATEGY)
            ? Yup.mixed().required('Please select one')
            : Yup.mixed(),

        rankId: isFieldRequired(DefaultAttributes.RANK)
            ? Yup.mixed().required('Please select one')
            : Yup.mixed(),

        grossBuildSf: Yup.number()
            .transform(emptyToZero)
            .concat(
                isFieldRequired(DefaultAttributes.UNITS)
                    ? Yup.number().required('Gross build SF is required')
                    : Yup.number(),
            ),

        rentableSf: Yup.number()
            .transform(emptyToZero)
            .concat(
                isFieldRequired(DefaultAttributes.UNITS)
                    ? Yup.number().required('Rentable SF is required')
                    : Yup.number(),
            ),

        acres: Yup.number()
            .transform(emptyToZero)
            .concat(
                isFieldRequired(DefaultAttributes.UNITS)
                    ? Yup.number().required('Acres is required')
                    : Yup.number(),
            ),

        residentialUnits: Yup.number()
            .transform(emptyToZero)
            .concat(
                isFieldRequired(DefaultAttributes.UNITS)
                    ? Yup.number().required('Residential units is required')
                    : Yup.number(),
            ),

        beds: Yup.number()
            .transform(emptyToZero)
            .concat(
                isFieldRequired(DefaultAttributes.UNITS)
                    ? Yup.number().required('Beds is required')
                    : Yup.number(),
            ),

        lots: Yup.number()
            .transform(emptyToZero)
            .concat(
                isFieldRequired(DefaultAttributes.UNITS)
                    ? Yup.number().required('Lots is required')
                    : Yup.number(),
            ),

        dealOwnerId: isFieldRequired(DefaultAttributes.DEAL_OWNER)
            ? Yup.mixed().required('Deal owner is required')
            : Yup.mixed(),

        megawatts: Yup.number()
            .transform(emptyToZero)
            .concat(
                isFieldRequired(DefaultAttributes.UNITS)
                    ? Yup.number().required('Megawatts is required')
                    : Yup.number(),
            ),

        targetReturnOnCost: Yup.number().optional()
            .transform(emptyToZero).nullable(),

        targetReturnOnEquity: Yup.number().optional()
            .transform(emptyToZero).nullable(),

        targetProfitReturn: Yup.number().optional()
            .transform(emptyToZero).nullable(),

        irr: Yup.number().optional()
            .transform(emptyToZero),

        equityMultiple: Yup.number().optional()
            .transform(emptyToZero),

        scenarioId: Yup.number().optional().nullable(),

        customAttributes: Yup.array().of(
            Yup.object().shape({
                attributeId: Yup.string().required(),
                valueText: Yup.string().optional(),
                valueNumber: Yup.number().optional(),
                valueSingleSelect: Yup.string().optional(),
                valueMultiSelect: Yup.array().of(Yup.string()).optional(),
            }),
        ).test(
            'required-custom-attributes',
            'Required custom attributes must be set',
            function requiredCustomAttributes(value) {
                if (!attributesConfig) return true;
                if (!value) return true;

                const requiredCustomAttributes = attributesConfig
                    .filter(attr => attr.isCustom && attr.required);

                if (requiredCustomAttributes.length === 0) return true;

                const missingRequired = requiredCustomAttributes.filter(requiredAttr => {
                    const attributeValue = value.find(v => v.attributeId === requiredAttr.correlationId);
                    if (!attributeValue) return true;

                    switch (requiredAttr.inputType) {
                        case 'text':
                            return !attributeValue.valueText || attributeValue.valueText.trim() === '';
                        case 'number':
                        case 'amount':
                            return attributeValue.valueNumber === undefined || attributeValue.valueNumber === null;
                        case 'select':
                            return !attributeValue.valueSingleSelect || attributeValue.valueSingleSelect.trim() === '';
                        case 'multi_select':
                            return !attributeValue.valueMultiSelect || !attributeValue.valueMultiSelect.length;
                        default:
                            return true;
                    }
                });

                if (missingRequired.length > 0) {
                    return this.createError({
                        message: missingRequired
                            .map(m => m.name + CUSTOM_ATTRIBUTE_IS_REQUIRED_ERROR_SUFFIX)
                            .join(CUSTOM_ATTRIBUTE_IS_REQUIRED_ERROR_SEPARATOR),
                    });
                }

                return true;
            },
        ),
    });
};
