/* eslint-disable operator-linebreak */
import React, { useState } from 'react';
import { IProFormaUses, DevelopmentUnitOfMeasureTypes } from 'views/ProFormaTable/types';
import { NSCheckbox } from 'bricks';
import { numeralFormatterCurrency } from 'ns_libs/formatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import RenderIf from 'components/RenderIf/RenderIf';
import classNames from 'classnames';
import { PRO_FORMA_UNIT_TO_FIELD_VALUES } from 'constants/unitTypes';
import { DEFAULT_SUBCATEGORY_NAME } from 'views/ProFormaTable/constants';
import { IOptionAnyValue } from 'bricks/types';
import { useUsesTableContext } from '../context/UsesTableProvider';
import UsesTableSubcategory from './UsesTableSubcategory/UsesTableSubcategory';
import { useProFormaSocketContext } from '../../../socketContext/ProFormaSocketProvider';
import PeriodCellValue from '../PeriodCellValue/PeriodCellValue';

export interface IUsesTableHighLevel {
    highLevel: IProFormaUses;
    unitType: DevelopmentUnitOfMeasureTypes;
    periodOptions: IOptionAnyValue[];
}

const UsesTableHighLevel = ({ highLevel, unitType, periodOptions }: IUsesTableHighLevel) => {
    const { handleCreateBudgetClassification, isBudgetClassificationLoading } = useProFormaSocketContext();

    const {
        selectedCategories, expandedCategories, updateSelectedCategories,
        updateExpandedCategories, isProFormaReadOnly,
    } = useUsesTableContext();
    const [isHovered, setIsHovered] = useState(false);

    const expandCategory = () => {
        if (!expandedCategories.highLevels.includes(highLevel.id)) {
            updateExpandedCategories(highLevel.id, null);
        }
    };

    const createSubcategory = () => {
        const postBody = {
            description: DEFAULT_SUBCATEGORY_NAME,
            parentId: highLevel.id,
        };
        handleCreateBudgetClassification(postBody, expandCategory);
    };

    const subcategoryIds: number[] = [];
    const budgetClassificationIds: number[] = [];

    highLevel.children?.map(subcategory => {
        subcategoryIds.push(subcategory.id);

        subcategory.children?.map(budgetClassification => {
            if (budgetClassification.isEditable) {
                budgetClassificationIds.push(budgetClassification.id);
            }

            return null;
        });

        return null;
    });

    const selectedUnitTotal = highLevel[PRO_FORMA_UNIT_TO_FIELD_VALUES[unitType]];

    return (
        <>
            <tr
                className="NSTable__tbody__tr NSTable__tbody__tr--level-1"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <td
                    className="NSTable__tbody__tr__td--right-border text-dark d-flex align-items-center justify-content-between"
                >
                    <div className="d-flex">
                        <RenderIf isTrue={!isProFormaReadOnly}>
                            <NSCheckbox
                                id={`selectHighLevel-${highLevel.id}`}
                                checked={selectedCategories.highLevels.includes(highLevel.id)}
                                callback={() => updateSelectedCategories(highLevel.id, subcategoryIds, budgetClassificationIds)}
                                containerClassName="custom-control-inline align-middle"
                            />
                        </RenderIf>
                        <div
                            role="presentation"
                            onClick={() => {
                                updateExpandedCategories(highLevel.id, null);
                            }}
                        >
                            <RenderIf isTrue={Boolean(highLevel.children?.length)}>
                                <FontAwesomeIcon
                                    className="icon mr-1 ReadOnlyWrapper--enable-pointer-events"
                                    icon={expandedCategories.highLevels.includes(highLevel.id) ? faCaretDown : faCaretRight}
                                    fixedWidth
                                />
                            </RenderIf>
                            <span className={classNames({ 'ml-3': !highLevel.children?.length })}>{highLevel.description}</span>
                        </div>
                    </div>
                    <RenderIf isTrue={isHovered}>
                        <RenderIf isTrue={isBudgetClassificationLoading}>
                            <span className="spinner-border spinner-border-sm" style={{ height: '15px', width: '15px' }} />
                        </RenderIf>
                        <RenderIf isTrue={!isBudgetClassificationLoading}>
                            <FontAwesomeIcon icon={faPlus} onClick={createSubcategory} className="cursor--pointer" />
                        </RenderIf>
                    </RenderIf>
                </td>
                <td className="NSTable__tbody__tr__td" />
                <td className="NSTable__tbody__tr__td" />
                <td className="NSTable__tbody__tr__td" />
                <td className="NSTable__tbody__tr__td">
                    <div className="d-flex justify-content-end text-dark">
                        {selectedUnitTotal !== null ? numeralFormatterCurrency(selectedUnitTotal) : '—'}
                    </div>
                </td>
                <td className="NSTable__tbody__tr__td NSTable__tbody__tr__td--right-border">
                    <div className="d-flex justify-content-end text-dark">
                        {highLevel.amount !== null ? numeralFormatterCurrency(highLevel.amount) : '—'}
                    </div>
                </td>
                {periodOptions?.map(period => {
                    const targetPeriod = highLevel.periods?.find(p => p.periodName === period.label);

                    return (
                        <PeriodCellValue
                            key={period.value}
                            period={period}
                            value={targetPeriod?.projected === null || highLevel.amount === null ?
                                '—' : numeralFormatterCurrency(targetPeriod?.projected)}
                            cellClassName="NSTable__tbody__tr__td text-dark text-right"
                        />
                    );
                })}
            </tr>

            <RenderIf isTrue={expandedCategories.highLevels.includes(highLevel.id)}>
                {(highLevel.children || []).map(subcategory => (
                    <UsesTableSubcategory
                        key={subcategory.id}
                        subcategory={subcategory}
                        unitType={unitType}
                        periodOptions={periodOptions}
                    />
                ))}
            </RenderIf>
        </>
    );
};

export default UsesTableHighLevel;
