import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IOptionAnyValue } from 'bricks/types';
import React from 'react';
import ProFormaSelector from 'views/Reports/components/ProFormaSelector/ProFormaSelector';
import { IComparisonReportData } from 'views/Reports/types';

export interface IProps {
    data: IComparisonReportData;
    removeSelectedDeal: (dealId: number) => void;
    updateSelectedProFormaId: (dealId: number, proFormaId: number) => void;
    isEditMode: boolean;
}

export const ColumnHeader = ({
    data, removeSelectedDeal, updateSelectedProFormaId, isEditMode,
}: IProps) => {
    const handleProFormaSelection = (option: IOptionAnyValue) => {
        updateSelectedProFormaId(data.dealId, option.value);
    };

    return (
        data && (
            <div className="d-flex align-items-center justify-content-between">
                <div>
                    <div className="text-white h4">{data.dealName}</div>
                    <div>Rental apartment • Development</div>
                    <ProFormaSelector dealId={Number(data.dealId) || 1} proFormaId={data.proFormaId} onChangeProFormaSelection={handleProFormaSelection} />
                </div>
                {isEditMode && (
                    <div>
                        <FontAwesomeIcon className="icon my-auto cursor--pointer" icon={faXmark} onClick={() => removeSelectedDeal(data.dealId)} />
                    </div>
                )}
            </div>
        )
    );
};

export default ColumnHeader;
