import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NSTable from 'bricks/NSTable/NSTable';
import { NSCheckbox } from 'bricks';
import useToast from 'hooks/useToast';
import { getColSortConfig, getSortIcon } from 'helpers/sort';
import { ColSortConfig } from 'types/sort';
import ReportRow from './ReportRow/ReportRow';
import { IReport } from '../types';
import ActionRow from './ActionRow/ActionRow';
import './ReportTable.scss';

interface IReportTableProps {
    reportData: IReport[];
    colSortConfig: ColSortConfig<any> | undefined;
    setColSortConfig: (config: ColSortConfig<any> | undefined) => void;
}

const ReportTable = ({ reportData, colSortConfig, setColSortConfig }: IReportTableProps) => {
    const [isAllRowSelected, setIsAllRowSelected] = useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);

    const { ToastContainerElement } = useToast();

    const toggleSelectAll = () => {
        if (isAllRowSelected) {
            setSelectedRows([]);
        } else {
            setSelectedRows(reportData.map(report => report.id));
        }
        setIsAllRowSelected(!isAllRowSelected);
    };

    const toggleRowSelection = (id: number) => {
        setSelectedRows(prev => (prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]));
    };

    useEffect(() => {
        setIsAllRowSelected(selectedRows.length === reportData.length);
    }, [selectedRows, reportData]);

    const configureSort = (key: string) => {
        const newColSortConfig = getColSortConfig(key, colSortConfig);
        setColSortConfig(newColSortConfig);
    };

    return (
        <div>
            {selectedRows.length > 0 && <ActionRow selectedRows={selectedRows} />}
            <NSTable>
                <thead className="NSTable__thead">
                    <tr className="NSTable__thead__tr">
                        <th className="NSTable__thead__tr__th">
                            <div className="d-flex align-items-center">
                                <NSCheckbox id="uses-table-select-all" checked={isAllRowSelected} callback={toggleSelectAll} />
                                <span className="mr-2">Name</span>
                                <FontAwesomeIcon
                                    icon={getSortIcon('name', colSortConfig)}
                                    className="cursor--pointer"
                                    onClick={() => configureSort('name')}
                                />
                            </div>
                        </th>
                        <th className="NSTable__thead__tr__th">
                            <div>
                                <span>Type</span>
                            </div>
                        </th>
                        <th className="NSTable__thead__tr__th">
                            <div>
                                <span>Description</span>
                            </div>
                        </th>
                        <th className="NSTable__thead__tr__th">
                            <div>
                                <span>Deals included</span>
                            </div>
                        </th>
                        {/* empty th for column with actions */}
                        <th className="NSTable__thead__tr__th" />
                    </tr>
                </thead>
                <tbody className="NSTable__tbody">
                    {reportData.map(report => (
                        <ReportRow key={report.id} reportRowData={report} isSelected={selectedRows.includes(report.id)} onSelect={toggleRowSelection} />
                    ))}
                </tbody>
            </NSTable>
            {ToastContainerElement}
        </div>
    );
};

export default ReportTable;
