import React, { useCallback, useState, useRef } from 'react';
import classNames from 'classnames';
import { numeralFormatterCurrency } from 'ns_libs/formatter';
import { NSButton } from '../../../../bricks';
import RenderIf from '../../../../components/RenderIf/RenderIf';
import { SensitivityAnalysisParameter, SensitivityAnalysisParams, SensitivityAnalysisResponse } from '../../types';
import { SensitivityValue, SensitivityTableValues } from '../SensitivityTable/types';
import SensitivityTable from '../SensitivityTable/SensitivityTable';
import ProFormaExitValuationCard from '../ProFormaExitValuationCard/ProFormaExitValuationCard';
import useGetProFormaSensitivityAnalysis from '../../hooks/useGetProFormaSensitivityAnalysis';
import { SENSITIVITY_CONFIG, SENSITIVITY_OPTIONS } from './constants';

interface TableConfig {
    id: string;
    type: string;
    tableLabel: string;
}

interface SensitivityTableContainerProps {
    dealId: number;
    proFormaId: number;
    tableConfig: TableConfig;
    onRequestDelete: (tableId: string) => void;
}

const mockData: SensitivityTableValues = {
    values: [],
    verticalLabel: 'Hard + soft costs',
    horizontalLabel: 'Exit cap rate',
};

const DEFAULT_VERTICAL_INCREMENT = 1;
const DEFAULT_HORIZONTAL_INCREMENT = 1;

const SensitivityTableContainer = ({
    dealId,
    proFormaId,
    tableConfig,
    onRequestDelete,
}: SensitivityTableContainerProps) => {
    const [sensitivityData, setSensitivityData] = useState<SensitivityValue | null>(null);
    const [offsets, setOffsets] = useState({ vertical: 0, horizontal: 0 });

    const selectedOption = SENSITIVITY_OPTIONS.find(opt => opt.value === tableConfig.type);
    const increments = {
        vertical: selectedOption?.increments?.vertical || DEFAULT_VERTICAL_INCREMENT,
        horizontal: selectedOption?.increments?.horizontal || DEFAULT_HORIZONTAL_INCREMENT,
    };

    const currentParamsRef = useRef<SensitivityAnalysisParams>({
        parameters: selectedOption?.value as SensitivityAnalysisParameter,
        firstParameterStepMultiplier: increments.vertical,
        secondParameterStepMultiplier: increments.horizontal,
    });

    const {
        data,
        isLoading,
        isFetching,
        refetch,
    } = useGetProFormaSensitivityAnalysis(
        dealId,
        proFormaId,
        currentParamsRef.current,
        { enabled: true },
    );

    const transformSensitivityData = useCallback((apiData: SensitivityAnalysisResponse | undefined) => {
        if (!apiData) return null;

        const verticalLabels = [...apiData.sensitivityAnalysis].reverse().map(item => ({
            id: `vertical-${item.firstParameterValue}`,
            label: item.firstParameterValue,
        }));

        const horizontalLabels = apiData.sensitivityAnalysis[0].bySecond.map(item => ({
            id: `horizontal-${item.secondParameterValue}`,
            label: item.secondParameterValue || undefined,
        }));

        const reverseValues = [...apiData.sensitivityAnalysis].reverse();
        const values = reverseValues.flatMap(firstParam => firstParam.bySecond.map(secondParam => ({
            exitValuation: secondParam.exitValuation ? parseFloat(secondParam.exitValuation) : undefined,
            netOperatingIncome: secondParam.netOperatingIncome ? parseFloat(secondParam.netOperatingIncome) : undefined,
            constructionDebtAmount: secondParam.constructionDebtAmount ? parseFloat(secondParam.constructionDebtAmount) : undefined,
            leveredIRR: secondParam.leveredIrr ? parseFloat(secondParam.leveredIrr) : undefined,
            leveredEMx: secondParam.leveredEmx ? parseFloat(secondParam.leveredEmx) : undefined,
            status: 'good',
        })));

        return {
            values,
            verticalLabel: apiData.firstParameter,
            horizontalLabel: apiData.secondParameter,
            verticalDataLabels: verticalLabels,
            horizontalDataLabels: horizontalLabels,
        };
    }, []);

    const handleLabelChange = async (direction: 'vertical' | 'horizontal', change: number) => {
        try {
            currentParamsRef.current = {
                ...currentParamsRef.current,
                firstParameterStepMultiplier: direction === 'vertical'
                    ? currentParamsRef?.current?.firstParameterStepMultiplier + change
                    : currentParamsRef?.current?.firstParameterStepMultiplier,
                secondParameterStepMultiplier: direction === 'horizontal'
                    ? currentParamsRef?.current?.secondParameterStepMultiplier + change
                    : currentParamsRef.current.secondParameterStepMultiplier,
            };

            const result = await refetch();
            if (result.data) {
                setOffsets(prev => ({
                    ...prev,
                    [direction]: prev[direction] + change,
                }));
            }
        } catch (error) {
            console.error('Failed to fetch sensitivity data:', error);
        }
    };

    const handleCellClick = (cellData: SensitivityValue | null) => {
        setSensitivityData(cellData);
    };

    const tableData = transformSensitivityData(data) || {
        values: [],
        verticalLabel: mockData.verticalLabel,
        horizontalLabel: mockData.horizontalLabel,
        verticalDataLabels: [],
        horizontalDataLabels: [],
    };

    const cardVisible = Boolean(sensitivityData);
    const config = SENSITIVITY_CONFIG[tableConfig.type as keyof typeof SENSITIVITY_CONFIG] || {
        displayValue: 'exitValuation',
        formatter: numeralFormatterCurrency,
    };

    // Calculate base spacing and disable conditions
    const verticalBaseSpacing = tableData.verticalDataLabels.length > 1
        ? parseFloat(tableData.verticalDataLabels[1]?.label ?? '0') - parseFloat(tableData.verticalDataLabels[0]?.label ?? '0')
        : 1;
    const horizontalBaseSpacing = tableData.horizontalDataLabels.length > 1
        ? parseFloat(tableData.horizontalDataLabels[1]?.label ?? '0') - parseFloat(tableData.horizontalDataLabels[0]?.label ?? '0')
        : 1;

    const currentVerticalSpacing = verticalBaseSpacing + (offsets.vertical * increments.vertical);
    const currentHorizontalSpacing = horizontalBaseSpacing + (offsets.horizontal * increments.horizontal);

    const disableVerticalDecrement = Math.abs(currentVerticalSpacing) <= increments.vertical;
    const disableHorizontalDecrement = Math.abs(currentHorizontalSpacing) <= increments.horizontal;

    return (
        <div>
            <RenderIf isLoading={isLoading || isFetching} isTrue={data !== undefined}>
                <div className={classNames('Sensitivity__table pr-2', { 'Sensitivity__table--shrinked': cardVisible })}>
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <div>
                            <div className="Sensitivity__table--title text-white mb-0">{tableConfig.tableLabel}</div>
                            <div className="text-muted">Click on a cell to see the impact on returns.</div>
                        </div>
                        {!cardVisible && (
                            <NSButton
                                className="m-2"
                                outline
                                color="danger"
                                callback={() => onRequestDelete(tableConfig.id)}
                            >
                                Remove sensitivity
                            </NSButton>
                        )}
                    </div>
                    <div className="Sensitivity--separator w-100 pt-2 mb-2" />
                    <div>
                        <SensitivityTable
                            verticalLabel={tableData.verticalLabel}
                            horizontalLabel={tableData.horizontalLabel}
                            verticalDataLabels={tableData.verticalDataLabels}
                            horizontalDataLabels={tableData.horizontalDataLabels}
                            data={tableData.values as SensitivityValue[]}
                            onVerticalIncrement={() => handleLabelChange('vertical', -(selectedOption?.increments?.vertical ?? 1))}
                            onVerticalDecrement={() => handleLabelChange('vertical', selectedOption?.increments?.vertical ?? 1)}
                            onHorizontalIncrement={() => handleLabelChange('horizontal', selectedOption?.increments?.horizontal ?? 1)}
                            onHorizontalDecrement={() => handleLabelChange('horizontal', -(selectedOption?.increments?.horizontal ?? 1))}
                            onCellClick={handleCellClick}
                            disableVerticalDecrement={disableVerticalDecrement}
                            disableHorizontalDecrement={disableHorizontalDecrement}
                            showDecimal={increments.vertical === 0.1 || increments.horizontal === 0.1}
                            displayValue={config.displayValue}
                            valueFormatter={config.formatter}
                        />
                    </div>
                </div>

                <div
                    className={classNames('Sensitivity__valuation-card-container m-2', {
                        'Sensitivity__valuation-card-container--hidden': !cardVisible,
                    })}
                >
                    <ProFormaExitValuationCard
                        exitValuation={sensitivityData?.exitValuation || 0}
                        leveredIRR={sensitivityData?.leveredIRR || 0}
                        leveredEMx={sensitivityData?.leveredEMx || 0}
                        status={sensitivityData?.status}
                        id={tableConfig.id}
                    />
                </div>
            </RenderIf>
        </div>
    );
};

export default SensitivityTableContainer;
