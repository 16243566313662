import React, { CSSProperties } from 'react';
import { Card, CardProps } from 'reactstrap';

export interface INSCardProps extends CardProps {
    id?: string;
    style?: CSSProperties;
    className?: string;
    children: React.ReactNode;
}

const NSCard = ({
    id, children, className = '', style = {}, onClick = () => {},
}: INSCardProps) => (
    <Card className={`NSCard ${className}`} style={style} onClick={onClick} id={id}>
        {children}
    </Card>
);

export default NSCard;
