import { ITableData } from 'types/excelExport';
import {
    IComparisonReportData, IComparisonReportUses, IInvestmentMetricsData, IProjectionMetricsData,
} from 'views/Reports/types';
import {
    COMPARISON_REPORT_GROUPS, COMPARISON_REPORT_GROUPS_MAP, ComparisonReportPropertyGroup, ComparisonReportRow,
} from './constants';

const EXCEL_NSBLUE = 'e6e8e8';
const EXCEL_WHITE = '0FB3FF';
const BORDER_COLOR = '000000';
const EXCEL_DARK_GRAY = 'BFBFBF';

const defaultFontSize = 12;
const borderStyle = { style: 'medium', color: { argb: BORDER_COLOR } };
const thinBorderStyle = { style: 'thin', color: { argb: BORDER_COLOR } };
const dashedBorderStyle = { style: 'dashed', color: { argb: BORDER_COLOR } };

export const formatExcelExportData = (title: string, subtitle: string, data: IComparisonReportData[]) => {
    const conjunctionGroups = [
        ComparisonReportPropertyGroup.PROPERTY_RETURNS,
        ComparisonReportPropertyGroup.PARTNERSHIP_RETURNS,
        ComparisonReportPropertyGroup.DEVELOPMENT_RETURNS,
        ComparisonReportPropertyGroup.USES,
        ComparisonReportPropertyGroup.INCOME,
    ];

    const topHeaderRows = [
        {
            rowFont: { bold: true, size: defaultFontSize },
            rowCells: [{ value: title }],
        },
        {
            rowFont: { bold: true, size: defaultFontSize },
            rowCells: [{ value: subtitle }],
        },
    ];

    const tableHeaderLabelsRow = {
        rowFont: {
            bold: true,
            size: 12,
            color: { argb: EXCEL_NSBLUE },
        },
        rowFill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: EXCEL_WHITE },
        },
        rowCells: [
            {
                value: '',
                width: 25,
                border: {
                    top: borderStyle,
                    left: borderStyle,
                    bottom: thinBorderStyle,
                    right: thinBorderStyle,
                },
            },
            ...data.map((col, idx) => ({
                value: `${col.dealName} - ${col.proFormaName}`,
                width: 42,
                border: {
                    top: borderStyle,
                    right: idx === data.length - 1 ? borderStyle : dashedBorderStyle,
                    bottom: thinBorderStyle,
                },
                alignment: { horizontal: 'right' },
            })),
        ],
    };

    const tableData = COMPARISON_REPORT_GROUPS.flatMap((group, groupIdx) => {
        const groupRow: ITableData = {
            rowFill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: EXCEL_DARK_GRAY },
            },
            rowCells: [
                {
                    value: group,
                    border: {
                        top: thinBorderStyle,
                        left: borderStyle,
                        right: thinBorderStyle,
                    },
                    width: 25,
                },
                ...data.map((col, idx) => ({
                    value: '',
                    border: {
                        top: thinBorderStyle,
                        right: idx === data.length - 1 ? borderStyle : dashedBorderStyle,
                    },
                })),
            ],
        };

        const additionalRows = [];
        if (conjunctionGroups.includes(group)) {
            additionalRows.push(
                ...COMPARISON_REPORT_GROUPS_MAP[group].flatMap((row, rowId) => {
                    let lastRowBottomBorder = dashedBorderStyle;
                    if (rowId === COMPARISON_REPORT_GROUPS_MAP[group].length - 1) {
                        lastRowBottomBorder = thinBorderStyle;
                        if (groupIdx === COMPARISON_REPORT_GROUPS.length - 1) {
                            lastRowBottomBorder = borderStyle;
                        }
                    }
                    const firstRow: ITableData = {
                        rowCells: [
                            {
                                value: '',
                                border: {
                                    left: borderStyle,
                                    right: thinBorderStyle,
                                },
                                width: 25,
                            },
                            ...data.map((col, idx) => ({
                                value: getComparisonReportValueByProperty(col, group, row)[0],
                                border: {
                                    right: idx === data.length - 1 ? borderStyle : dashedBorderStyle,
                                },
                                alignment: { horizontal: 'right' },
                            })),
                        ],
                    };
                    const secondRow: ITableData = {
                        rowCells: [
                            {
                                value: row,
                                border: {
                                    left: borderStyle,
                                    right: thinBorderStyle,
                                },
                                width: 25,
                            },
                            ...data.map((col, idx) => ({
                                value: getComparisonReportValueByProperty(col, group, row)[1],
                                border: {
                                    right: idx === data.length - 1 ? borderStyle : dashedBorderStyle,
                                },
                                alignment: { horizontal: 'right' },
                            })),
                        ],
                    };
                    const thirdRow: ITableData = {
                        rowCells: [
                            {
                                value: '',
                                border: {
                                    left: borderStyle,
                                    right: thinBorderStyle,
                                    bottom: lastRowBottomBorder,
                                },
                                width: 25,
                            },
                            ...data.map((col, idx) => ({
                                value: getComparisonReportValueByProperty(col, group, row)[2],
                                border: {
                                    right: idx === data.length - 1 ? borderStyle : dashedBorderStyle,
                                    bottom: lastRowBottomBorder,
                                },
                                alignment: { horizontal: 'right' },
                            })),
                        ],
                    };
                    return [firstRow, secondRow, thirdRow];
                }),
            );
        } else {
            additionalRows.push(
                ...COMPARISON_REPORT_GROUPS_MAP[group].map((row, rowIdx) => {
                    const newRow: ITableData = {
                        rowCells: [
                            {
                                value: row,
                                border: {
                                    left: borderStyle,
                                    right: thinBorderStyle,
                                    bottom:
                                        rowIdx === COMPARISON_REPORT_GROUPS_MAP[group].length - 1 && groupIdx === COMPARISON_REPORT_GROUPS.length - 1
                                            ? borderStyle
                                            : undefined,
                                },
                                width: 25,
                            },
                            ...data.map((col, idx) => ({
                                value: getComparisonReportSingleValueByProperty(col, group, row),
                                border: {
                                    right: idx === data.length - 1 ? borderStyle : dashedBorderStyle,
                                    bottom:
                                        rowIdx === COMPARISON_REPORT_GROUPS_MAP[group].length - 1 && groupIdx === COMPARISON_REPORT_GROUPS.length - 1
                                            ? borderStyle
                                            : undefined,
                                },
                                alignment: { horizontal: 'right' },
                            })),
                        ],
                    };
                    return newRow;
                }),
            );
        }

        return [groupRow, ...additionalRows];
    });

    return {
        topHeaderRows,
        reportTables: [
            {
                tableHeaderRows: [tableHeaderLabelsRow],
                tableData,
                tableFooter: {
                    rowCells: [],
                },
            },
        ],
    };
};

const getComparisonReportValueByProperty = (data: IComparisonReportData, propertyGroup: ComparisonReportPropertyGroup, property: ComparisonReportRow) => {
    const formatReturns = (returns: IInvestmentMetricsData) => [
        returns.roi ? `ROI: ${returns.roi}` : 'n/a',
        returns.emx ? `EMx: ${returns.emx}` : 'n/a',
        returns.irr ? `IRR: ${returns.irr}` : 'n/a',
    ];

    const formatDevelopmentMetricsAndIncome = (metrics: IProjectionMetricsData) => [
        metrics.untrended ? `Untrended: ${metrics.untrended}` : 'n/a',
        metrics.trended ? `Trended: ${metrics.trended}` : 'n/a',
        metrics.sale ? `Sale: ${metrics.sale}` : 'n/a',
    ];

    if (propertyGroup === ComparisonReportPropertyGroup.PROPERTY_RETURNS) {
        const { propertyReturns } = data;

        if (property === ComparisonReportRow.UNLEVERED) {
            return formatReturns(propertyReturns.unlevered);
        }
        if (property === ComparisonReportRow.LEVERED) {
            return formatReturns(propertyReturns.levered);
        }
    } else if (propertyGroup === ComparisonReportPropertyGroup.PARTNERSHIP_RETURNS) {
        const { partnershipReturns } = data;

        if (property === ComparisonReportRow.LIMITED_PARTNER) {
            return formatReturns(partnershipReturns.limitedPartner);
        }
        if (property === ComparisonReportRow.GENERAL_PARTNER) {
            return formatReturns(partnershipReturns.generalPartner);
        }
    } else if (propertyGroup === ComparisonReportPropertyGroup.DEVELOPMENT_RETURNS) {
        const { developmentReturns } = data;

        if (property === ComparisonReportRow.YIELD_ON_COST) {
            return formatDevelopmentMetricsAndIncome(developmentReturns.yieldOnCost);
        }
        if (property === ComparisonReportRow.MARKET_CAP_RATE) {
            return formatDevelopmentMetricsAndIncome(developmentReturns.marketCapRate);
        }
        if (property === ComparisonReportRow.DEVELOPMENT_SPREAD) {
            return formatDevelopmentMetricsAndIncome(developmentReturns.developmentSpread);
        }
    } else if (propertyGroup === ComparisonReportPropertyGroup.USES) {
        const {
            acquisitionCosts, hardCosts, softCosts, carryCosts, totalUses,
        } = data.uses;

        const formatCosts = (costs: IComparisonReportUses) => [`$${costs.amount} / %`, `$${costs.costPerBuiltSf} / SF`, `$${costs.costPerUnit} / Unit`];

        if (property === ComparisonReportRow.ACQUISITION_COSTS) {
            return formatCosts(acquisitionCosts);
        }
        if (property === ComparisonReportRow.HARD_COSTS) {
            return formatCosts(hardCosts);
        }
        if (property === ComparisonReportRow.SOFT_COSTS) {
            return formatCosts(softCosts);
        }
        if (property === ComparisonReportRow.CARRY_COSTS) {
            return formatCosts(carryCosts);
        }
        if (property === ComparisonReportRow.TOTAL_USES) {
            return formatCosts(totalUses);
        }
    } else if (propertyGroup === ComparisonReportPropertyGroup.INCOME) {
        const {
            effectiveGrossRevenue,
            operatingExpenses,
            netOperatingIncome,
            capitalExpenditures,
            cashFlowFromOperations,
            capRate,
            valuation,
            percentOfTotalProjectCost,
            valuationPerUnit,
        } = data.income;

        if (property === ComparisonReportRow.EFFECTIVE_GROSS_REVENUE) {
            return formatDevelopmentMetricsAndIncome(effectiveGrossRevenue);
        }
        if (property === ComparisonReportRow.OPERATING_EXPENSES) {
            return formatDevelopmentMetricsAndIncome(operatingExpenses);
        }
        if (property === ComparisonReportRow.NET_OPERATING_INCOME) {
            return formatDevelopmentMetricsAndIncome(netOperatingIncome);
        }
        if (property === ComparisonReportRow.CAPITAL_EXPENDITURES) {
            return formatDevelopmentMetricsAndIncome(capitalExpenditures);
        }
        if (property === ComparisonReportRow.CASH_FLOW_FROM_OPERATIONS) {
            return formatDevelopmentMetricsAndIncome(cashFlowFromOperations);
        }
        if (property === ComparisonReportRow.CAP_RATE) {
            return formatDevelopmentMetricsAndIncome(capRate);
        }
        if (property === ComparisonReportRow.VALUATION) {
            return formatDevelopmentMetricsAndIncome(valuation);
        }
        if (property === ComparisonReportRow.PERCENT_OF_TOTAL_PROJECT_COST) {
            return formatDevelopmentMetricsAndIncome(percentOfTotalProjectCost);
        }
        if (property === ComparisonReportRow.VALUATION_PER_UNIT) {
            return formatDevelopmentMetricsAndIncome(valuationPerUnit);
        }
    }
    return [0, 0, 0];
};

const getComparisonReportSingleValueByProperty = (
    data: IComparisonReportData,
    propertyGroup: ComparisonReportPropertyGroup,
    property: ComparisonReportRow,
) => {
    if (propertyGroup === ComparisonReportPropertyGroup.DETAILS) {
        const {
            units, buildings, height, landArea, netRentableSf, parkingRatio, parkingSpaces,
        } = data.details;
        if (property === ComparisonReportRow.UNITS) {
            return units || 'n/a';
        }
        if (property === ComparisonReportRow.BUILDINGS) {
            return buildings || 'n/a';
        }
        if (property === ComparisonReportRow.HEIGHT) {
            return height || 'n/a';
        }
        if (property === ComparisonReportRow.LAND_AREA) {
            return landArea || 'n/a';
        }
        if (property === ComparisonReportRow.NET_RENTABLE_SF) {
            return netRentableSf || 'n/a';
        }
        if (property === ComparisonReportRow.PARKING_RATIO) {
            return parkingRatio || 'n/a';
        }
        if (property === ComparisonReportRow.PARKING_SPACES) {
            return parkingSpaces || 'n/a';
        }
    }
    return 0;
};
