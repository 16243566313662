import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { throttle } from 'lodash';
import { config } from 'config';
import './Map.scss';

mapboxgl.accessToken = config.mapboxGlApiKey;

export interface IMapboxProps {
    lng: number;
    lat: number;
    zoom?: number;
    hideMarker?: boolean;
    height?: string;
    autoResize?: boolean;
}

const Map = ({
    lng, lat, zoom = 15, hideMarker = false, height = '400px', autoResize = false,
}: IMapboxProps) => {
    const mapContainerRef = useRef<HTMLDivElement>(null);
    const mapInstance = useRef<mapboxgl.Map | null>(null);
    const markerInstance = useRef<mapboxgl.Marker | null>(null);
    const resizeObserver = useRef<ResizeObserver | null>(null);

    // Throttled map update
    const throttledJumpTo = useRef(
        throttle((lng: number, lat: number, zoom: number) => {
            if (mapInstance.current) {
                mapInstance.current.jumpTo({
                    center: [lng, lat],
                    zoom,
                });
            }
        }, 500),
    ).current;

    // Calculate if an update is necessary based on distance
    const shouldUpdateMap = (oldCoords: [number, number], newCoords: [number, number]): boolean => {
        // eslint-disable-next-line no-mixed-operators
        const distance = Math.sqrt((newCoords[0] - oldCoords[0]) ** 2 + (newCoords[1] - oldCoords[1]) ** 2);
        return distance > 0.01;
    };

    // Initialize map
    useEffect(() => {
        if (!mapContainerRef.current) return;

        mapInstance.current = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/mapbox/dark-v10',
            center: [lng, lat],
            zoom,
            attributionControl: false,
            maxTileCacheSize: 500,
        });

        mapInstance.current.on('load', () => {
            if (!hideMarker && mapInstance.current) {
                markerInstance.current = new mapboxgl.Marker().setLngLat([lng, lat]).addTo(mapInstance.current);
            }
        });

        if (autoResize) {
            resizeObserver.current = new ResizeObserver(() => {
                if (mapInstance.current) {
                    mapInstance.current.resize();
                }
            });
            resizeObserver.current.observe(mapContainerRef.current);
        }

        // eslint-disable-next-line consistent-return
        return () => {
            if (mapInstance.current) {
                mapInstance.current.remove();
                mapInstance.current = null;
            }
            if (resizeObserver.current) {
                resizeObserver.current.disconnect();
                resizeObserver.current = null;
            }
        };
    }, []);

    // Handle coordinate changes
    useEffect(() => {
        if (mapInstance.current) {
            const currentCenter = mapInstance.current.getCenter().toArray() as [number, number];

            if (shouldUpdateMap(currentCenter, [lng, lat])) {
                throttledJumpTo(lng, lat, zoom);

                // Update or remove marker
                if (!hideMarker) {
                    if (markerInstance.current) {
                        markerInstance.current.setLngLat([lng, lat]);
                    } else {
                        markerInstance.current = new mapboxgl.Marker().setLngLat([lng, lat]).addTo(mapInstance.current);
                    }
                } else if (markerInstance.current) {
                    markerInstance.current.remove();
                    markerInstance.current = null;
                }
            }
        }
    }, [lng, lat, zoom, hideMarker]);

    const style = autoResize ? { height } : { minHeight: height };

    return <div ref={mapContainerRef} className="Map w-100 h-100" data-testid="map" style={style} />;
};

export default Map;
