import React from 'react';
import { IOptionAnyValue } from 'bricks/types';
import RenderIf from 'components/RenderIf/RenderIf';
import { DISABLED_PLACEHOLDER_PERIOD } from 'views/ProFormaTable/constants';

export interface IPeriodCellValueProps {
    period: IOptionAnyValue;
    value: number | string;
    cellClassName?: string;
    overlayingCellInput?: JSX.Element | null;
}
const PeriodCellValue = ({
    period, value, cellClassName, overlayingCellInput,
}: IPeriodCellValueProps) => (
    <>
        <RenderIf isTrue={period.value !== DISABLED_PLACEHOLDER_PERIOD}>
            <td className={`${cellClassName} align-middle`}>
                <RenderIf isTrue={overlayingCellInput}>
                    {overlayingCellInput}
                </RenderIf>
                {value}
            </td>
        </RenderIf>
        <RenderIf isTrue={period.value === DISABLED_PLACEHOLDER_PERIOD}>
            <td className={`${cellClassName} align-middle NSTable__tfoot__tr__td--grey-bg`} />
        </RenderIf>
    </>
);

export default PeriodCellValue;
