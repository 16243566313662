import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseyeArrow } from '@fortawesome/pro-regular-svg-icons';
import { Collapse } from 'reactstrap';
import './SlideOutReturns.scss';

import { useAuthContext } from 'contexts/AuthContext';
import { useGetDeals } from 'views/Deals/hooks/useFetchDeals';
import { NSSelect } from 'bricks';
import { Deal } from 'views/Deals/types';
import SlideOutReturnCard from './SlideOutReturnCard';

export interface IScenarioReturnsProps {
    containerClassName?: string;
    targetReturnOnCost?: number;
    targetReturnOnEquity?: number;
    targetProfitReturn?: number;
    forceExpanded?: boolean;
}

export interface IOptionAnyValue {
    label: string;
    value: any;
    id?: number | string;
    disabled?: boolean;
    returnOnCost?: number | null;
    returnOnEquity?: number | null;
    profitReturn?: number | null;
}

const SlideOutReturns = ({
    containerClassName = '', targetReturnOnCost, targetReturnOnEquity, targetProfitReturn, forceExpanded,
}: IScenarioReturnsProps) => {
    const { selectedOrganizationId } = useAuthContext();

    const [isExpandedState, setIsExpandedState] = useState(false);
    const [selectedOption, setSelectedOption] = useState<IOptionAnyValue | undefined>(undefined);
    const [selectedDeal, setSelectedDeal] = useState<Deal | null>(null);

    useEffect(() => {
        if (forceExpanded) {
            setIsExpandedState(true);
        }
    }, [forceExpanded]);

    // TODO: Change this request and logic when the new endpoint is ready.
    const { data } = useGetDeals({ pageSize: 100, currentPage: 1, orgId: selectedOrganizationId! });

    const dealOptions: IOptionAnyValue[] = data?.items.map(deal => ({
        label: deal.dealName,
        value: deal.id,
        id: deal.id,
        returnOnCost: deal.targetReturnOnCost,
        returnOnEquity: deal.targetReturnOnEquity,
        profitReturn: deal.targetProfitReturn,
        phase: deal.subprojectStage,
    })) || [];

    const formatOptionLabel = (option: IOptionAnyValue, { context }: { context: string }) => {
        if (context === 'value') {
            return (
                <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faBullseyeArrow} className="mr-1 text-muted" />
                    {option.label}
                </div>
            );
        }

        const isActive = selectedDeal && option.label === selectedDeal.dealName;

        return (
            <div className={`SlideOutReturns--header__dropdown-option ${isActive ? 'is-active' : ''}`}>
                <div className="d-flex flex-column w-100">
                    <div className="d-flex justify-content-between align-items-center">
                        <span
                            className={`
                                SlideOutReturns--header__dropdown-option__label
                                ${isActive ? 'text-white font-weight-bold' : 'text-muted'}
                            `}
                        >
                            {option.label}
                        </span>
                        {isActive && <span className="SlideOutReturns--header__dropdown-option__badge">Active</span>}
                    </div>
                    <div className="d-flex flex-column SlideOutReturns--header__dropdown-option__details">
                        <div className="d-flex justify-content-between">
                            <span className="text-muted">Return on cost</span>
                            <span className="text-white">
                                {option.returnOnCost ? `${(option.returnOnCost * 100).toFixed(0)}%` : '-'}
                            </span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <span className="text-muted">Return on equity</span>
                            <span className="text-white">
                                {option.returnOnEquity ? `${(option.returnOnEquity * 100).toFixed(0)}%` : '-'}
                            </span>
                        </div>
                        <div className="d-flex justify-content-between">
                            <span className="text-muted">Profit return</span>
                            <span className="text-white">
                                {option.profitReturn ? `$${option.profitReturn.toLocaleString()}` : '-'}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const handleSelectOption = (option: IOptionAnyValue) => {
        setSelectedOption(option);
        const deal = data?.items.find(item => item.id === option.value);
        setSelectedDeal(deal || null);
    };

    const textButton = isExpandedState ? 'Hide returns' : 'Show returns';

    const customStyles = {
        menuPortal: (base: any) => ({
            ...base,
            zIndex: 9999,
        }),
        menu: (base: any) => ({
            ...base,
            backgroundColor: '#404954',
        }),
        control: (base: any) => ({
            ...base,
            backgroundColor: '#404954',
            borderColor: '#47515d',
        }),
        singleValue: (base: any) => ({
            ...base,
            color: 'white',
        }),
        input: (base: any) => ({
            ...base,
            color: 'white',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        option: (base: any, { isSelected, isFocused }: any) => ({
            ...base,
            backgroundColor: '#404954',
            '&:active': {
                backgroundColor: '#404954',
            },
            '&:hover': {
                backgroundColor: '#47515d',
            },
            ':hover': {
                backgroundColor: '#47515d',
            },
            cursor: 'pointer',
            ...(isSelected && {
                backgroundColor: '#404954',
                ':hover': {
                    backgroundColor: '#47515d',
                },
            }),
            ...(isFocused && {
                backgroundColor: '#47515d',
            }),
        }),
    };

    return (
        <div className={`${containerClassName} SlideOutReturns__container`}>
            <div className="d-flex justify-content-end align-items-center mt-2">
                <button type="button" onClick={() => setIsExpandedState(!isExpandedState)} className="btn btn-secondary">
                    <FontAwesomeIcon icon={faBullseyeArrow} className="text-primary pr-1" />
                    <span className="text-primary">{textButton}</span>
                </button>
            </div>
            <Collapse isOpen={isExpandedState} enter exit data-testid="returns-collapse">
                <div className="NSCard--level-4 py-2 px-3 border border-secondary">
                    <div className="SlideOutReturns--header mb-2 d-flex flex-row align-items-center justify-content-between">
                        <div className="w-50">
                            <h4 className="text-white font-weight-bold mb-0">Returns</h4>
                            <h5 className="mt-1 text-muted">Compare these back-of-envelope calculations to target returns on your deals.</h5>
                        </div>
                        <NSSelect
                            options={dealOptions}
                            value={selectedOption}
                            onChange={handleSelectOption}
                            name="Deal"
                            menuPosition="fixed"
                            isClearable={false}
                            isSearchable
                            formClassName="SlideOutReturns--header__dropdown w-50"
                            menuPlacement="bottom"
                            formatOptionLabel={formatOptionLabel}
                            placeholder="Select target returns"
                            menuPortalTarget={document.body}
                            styles={customStyles}
                        />
                    </div>
                    <div className="d-flex flex-row SlideOutReturns__CardsContainer">
                        <SlideOutReturnCard
                            title="Return on cost:"
                            returnValue={targetReturnOnCost || 0}
                            targetValue={selectedDeal?.targetReturnOnCost || 0}
                            formatter="formatPercentage"
                        />
                        <SlideOutReturnCard
                            title="Return on equity:"
                            returnValue={targetReturnOnEquity || 0}
                            targetValue={selectedDeal?.targetReturnOnEquity || 0}
                            formatter="formatPercentage"
                        />
                        <SlideOutReturnCard
                            title="Profit return:"
                            returnValue={targetProfitReturn || 0}
                            targetValue={selectedDeal?.targetProfitReturn || 0}
                            formatter="formatUSDCurrency"
                        />
                    </div>
                </div>
            </Collapse>
        </div>
    );
};

export default SlideOutReturns;
