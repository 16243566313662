import React, { useCallback, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import './CannyChangelogWidget.scss';
import useScript from 'hooks/useScript';
import { ICanny } from 'types/global';
import { CANNY_APP_ID } from './constants';

const CannyChangelogWidget: React.FC = () => {
    const buttonRef = useRef<HTMLSpanElement>(null);
    const observerRef = useRef<MutationObserver | null>(null);
    const { loaded, error } = useScript('https://canny.io/sdk.js', 'canny-jssdk');

    const repositionIframe = useCallback(() => {
        const iframe = document.getElementById('canny-changelog-iframe');
        const button = buttonRef.current;
        if (!iframe || !button) return;
        const buttonRect = button.getBoundingClientRect();
        const { scrollY, scrollX, innerWidth } = window;
        Object.assign(iframe.style, {
            top: `${buttonRect.bottom + scrollY}px`,
            left: 'auto',
            right: `${innerWidth - (buttonRect.right + scrollX)}px`,
        });
    }, []);

    const initCannyQueue = useCallback(() => {
        if (window.Canny && typeof window.Canny === 'function') return;
        const cannyQueue: ICanny = Object.assign((...args: any[]) => cannyQueue.q.push(args), { q: [] });
        window.Canny = cannyQueue;
    }, []);

    const setupIframeObserver = useCallback(() => {
        observerRef.current?.disconnect();

        const observer = new MutationObserver((mutations, obs) => {
            const cannyIframe = mutations
                .flatMap(mutation => Array.from(mutation.addedNodes))
                .find(node => node instanceof HTMLElement && node.id === 'canny-changelog-iframe');

            if (cannyIframe) {
                repositionIframe();
                obs.disconnect();
            }
        });

        observer.observe(document.body, { childList: true });
        observerRef.current = observer;
    }, [repositionIframe]);

    const initCanny = useCallback(() => {
        if (!loaded || error) return;

        initCannyQueue();
        window.Canny?.('initChangelog', {
            appID: CANNY_APP_ID,
            position: 'bottom',
            align: 'right',
        });
        setupIframeObserver();
    }, [loaded, error, initCannyQueue, setupIframeObserver]);

    useEffect(() => {
        if (error) {
            console.error('Error loading Canny SDK:', error);
            return () => {};
        }

        initCanny();

        const handleReposition = () => requestAnimationFrame(repositionIframe);
        window.addEventListener('scroll', handleReposition, { passive: true });
        window.addEventListener('resize', handleReposition, { passive: true });

        return () => {
            observerRef.current?.disconnect();
            window.removeEventListener('scroll', handleReposition);
            window.removeEventListener('resize', handleReposition);
        };
    }, [error, initCanny, repositionIframe]);

    return (
        <span ref={buttonRef}>
            <FontAwesomeIcon data-canny-changelog icon={faBullhorn} className="NSNavbar__right__icon-list__icon cursor--pointer" aria-label="changelog" />
        </span>
    );
};

export default CannyChangelogWidget;
