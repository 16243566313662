import React from 'react';
import './TargetReturnCard.scss';
import { NSCard, NSInput } from 'bricks';
import RenderIf from 'components/RenderIf/RenderIf';
import { numeralFormatter } from 'ns_libs/formatter';
import { TargetReturnsValues } from '../TargetReturns';

export interface TargetReturnCardProps {
    isEditing: boolean;
    hasRange?: boolean;
    title: string;
    field: keyof TargetReturnsValues;
    value: number | null;
    min: number;
    max: number;
    unit: string;
    handleInputChange: (field: keyof TargetReturnsValues, value: number) => void;
}

const TargetReturnCard: React.FC<TargetReturnCardProps> = ({
    isEditing, title, field, value, min, max, unit, handleInputChange, hasRange = true,
}) => {
    const formattedValue = value !== null ? numeralFormatter(value) : '';

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = Math.min(Math.max(event.target.valueAsNumber, min), max);
        handleInputChange(field, value);
    };

    const handleBlur = () => {
        if (formattedValue === 0) {
            handleInputChange(field, 0);
        }
    };

    return (
        <NSCard className="NSCard--level-3 d-flex flex-grow-1 p-3 rounded">
            <h5 className="TargetReturnCard__title m-0 mb-1 font-14">{title}</h5>
            <RenderIf isTrue={isEditing}>
                <NSInput
                    type="number"
                    name={field}
                    value={value ?? 0}
                    min={min}
                    max={max}
                    step={1}
                    onChange={onChange}
                    inputClassName="mb-2"
                    includeRange={hasRange}
                    prependInputAddon={unit === '$' ? unit : null}
                    appendInputAddon={unit !== '$' ? unit : null}
                    selectOnFocus
                    onBlur={handleBlur}
                />
                <RenderIf isTrue={!hasRange}>
                    <span className="TargetReturnCard__no-range-filler" />
                </RenderIf>
            </RenderIf>
            <RenderIf isTrue={!isEditing}>
                <RenderIf isTrue={value !== null}>
                    <h3 className="text-white m-0">{unit === '%' ? `${formattedValue}${unit}` : `${unit}${formattedValue}`}</h3>
                </RenderIf>
                <RenderIf isTrue={value === null}>
                    <h5 className="text-white m-0 font-12">Not yet entered.</h5>
                </RenderIf>
            </RenderIf>
        </NSCard>
    );
};

export default TargetReturnCard;
