import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { useMutation } from '@tanstack/react-query';
import { ICreateDealGroupResponse, ICreateDealGroupVariables, IErrorResponse } from '../types';

const createDealGroup = async ({ postData, orgId }: ICreateDealGroupVariables): Promise<ICreateDealGroupResponse> => {
    try {
        const response = await axiosInstance.post<ICreateDealGroupResponse>(`${API_ENDPOINTS.DEALS_GROUPS(orgId)}`, postData);
        return response.data;
    } catch (error: any) {
        if (error.response?.status !== 500) {
            const errorData: IErrorResponse = error.response.data;
            throw new Error(errorData.detail);
        }
        throw new Error('An unexpected error occurred. Please try again.');
    }
};

export const useCreateDealGroup = () => useMutation<ICreateDealGroupResponse, Error, ICreateDealGroupVariables>({
    mutationFn: createDealGroup,
});
