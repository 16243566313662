import React from 'react';
import { NSLabel, NSModal } from 'bricks';
import { IReportBuilderReport } from 'views/ReportBuilder/constants.js';
import { useParams } from 'react-router-dom';
import { ListGroupItem } from 'reactstrap';
import { downloadFileFromUrl } from 'helpers/file/download';
import { createCombinedReportPdf } from 'views/ReportBuilder/hooks/useCreateCombinedReportPdf';

export interface IGenerateReportModalProps {
    orgId: string;
    newReports: Array<IReportBuilderReport>;
    isOpen: boolean;
    toggle: () => void;
}

const GenerateReportModal = ({
    orgId, newReports, toggle, isOpen,
}: IGenerateReportModalProps) => {
    const { dealId: dealIdString } = useParams<{ dealId: string; proFormaId: string }>();
    const dealId = Number(dealIdString);

    const generateReport = async () => {
        try {
            const response = await createCombinedReportPdf({
                dealId,
                postData: {
                    orgId,
                    reportBuilderReports: newReports.map(report => ({
                        reportType: report.reportType,
                        metaData: {
                            savedReportId: report.savedReportId,
                            boeScenarioIds: report.selectedBOEScenarioIds,
                            boeScenarioNames: report.selectedBOEScenarioNames,
                            proFormaId: report.selectedProFormaId,
                            proFormaName: report.selectedProFormaName,
                            dealAttributes: report.selectedDealAttributes,
                            includePrintedOnDate: report.includePrintedOnDate,
                            includeTargetReturns: report.includeTargetReturns,
                        },
                    })),
                },
            });
            const pdfUrl = response.url;
            if (pdfUrl) {
                await downloadFileFromUrl(pdfUrl, 'combined-report', 'pdf', true);
            } else {
                console.error('Failed to export report PDF: No URL returned');
            }
        } catch (error) {
            console.error('Failed to export report PDF:', error);
        }
        toggle();
    };

    return (
        <NSModal
            toggle={toggle}
            isOpen={isOpen}
            modalHeaderClassName="bg-primary text-white"
            modalHeaderText="Generate Report"
            footerButtonOutline={false}
            modalFooterButtonColor="primary"
            modalFooterButtonText="Generate"
            modalFooterButtonFunction={generateReport}
        >
            <NSLabel>Are you sure these are the reports you want to generate? </NSLabel>
            {newReports.map((report, index) => (
                <ListGroupItem key={report.reportType}>
                    <b className="pr-1">{`${index + 1}.`}</b>
                    {report.savedReportName || report.reportType}
                </ListGroupItem>
            ))}
        </NSModal>
    );
};

export default GenerateReportModal;
