import React, { useEffect, useState } from 'react';
import { IReportBuilderReport, NEW_REPORT_LABELS, ReportTypes } from 'views/ReportBuilder/constants';
import { NSCheckbox } from 'bricks';
import ExpandableField from 'components/ExpandableField/ExpandableField';
import ReportSaveCard from '../components/ReportSaveCard/ReportSaveCard';
import AttributesSection from './AttributesSection/AttributesSection';

export interface IProps {
    report: IReportBuilderReport;
    setNewReports: React.Dispatch<React.SetStateAction<IReportBuilderReport[]>>;
}
const DealOverviewSaveCard = ({ report, setNewReports }: IProps) => {
    const [isIncludePrintedOnDateChecked, setIsIncludePrintedOnDateChecked] = React.useState<boolean>(false);
    const [isIncludeTargetReturnsChecked, setIsIncludeTargetReturnsChecked] = React.useState<boolean>(false);
    const [checkedAttributeState, setCheckedAttributeState] = useState<Record<string, boolean>>({});

    useEffect(() => {
        if (report.selectedDealAttributes) {
            const selectedDealAttributes = report.selectedDealAttributes.reduce(
                (acc, attribute) => {
                    acc[attribute] = true;
                    return acc;
                },
                {} as Record<string, boolean>,
            );
            setCheckedAttributeState(selectedDealAttributes);
            setIsIncludePrintedOnDateChecked(!!report.includePrintedOnDate);
            setIsIncludeTargetReturnsChecked(!!report.includeTargetReturns);
        }
    }, [report.selectedDealAttributes]);

    const handleSave = () => {
        setNewReports(prevReports => {
            const reportIndex = prevReports.findIndex(r => r.reportType === ReportTypes.DEAL_OVERVIEW);

            if (reportIndex === -1) {
                return prevReports;
            }

            const updatedReports = [...prevReports];
            updatedReports[reportIndex] = {
                ...prevReports[reportIndex],
                isEditing: false,
                includePrintedOnDate: isIncludePrintedOnDateChecked,
                includeTargetReturns: isIncludeTargetReturnsChecked,
                selectedDealAttributes: Object.keys(checkedAttributeState).filter(key => checkedAttributeState[key]),
            };
            return updatedReports;
        });
    };

    const handleCancel = () => {
        setNewReports(prevReports => {
            const reportIndex = prevReports.findIndex(r => r.reportType === ReportTypes.DEAL_OVERVIEW);

            if (reportIndex === -1 || !prevReports[reportIndex]) {
                return prevReports;
            }

            const updatedReports = [...prevReports];
            const report = prevReports[reportIndex];

            if (report.selectedDealAttributes && report.selectedDealAttributes.length > 0) {
                updatedReports[reportIndex] = {
                    ...report,
                    isEditing: false,
                };
            } else {
                updatedReports.splice(reportIndex, 1);
            }

            return updatedReports;
        });
    };

    return (
        <ReportSaveCard
            header={NEW_REPORT_LABELS.DEAL_OVERVIEW}
            description="A detailed export of your Deal Dashboard's Overview page."
            handleSave={handleSave}
            handleCancel={handleCancel}
        >
            <NSCheckbox
                id="include-printed-on-date-deal-overview"
                containerClassName="mb-1"
                checked={isIncludePrintedOnDateChecked}
                labelText="Include printed on date"
                callback={() => setIsIncludePrintedOnDateChecked(!isIncludePrintedOnDateChecked)}
            />
            <NSCheckbox
                id="include-target-returns-deal-overview"
                checked={isIncludeTargetReturnsChecked}
                labelText="Include target returns"
                callback={() => setIsIncludeTargetReturnsChecked(!isIncludeTargetReturnsChecked)}
            />
            <ExpandableField className="mt-2" label={<p className="text-primary mb-0 cursor--pointer">Attributes:</p>} expanded={false}>
                <AttributesSection checkedAttributeState={checkedAttributeState} setCheckedAttributeState={setCheckedAttributeState} />
            </ExpandableField>
        </ReportSaveCard>
    );
};

export default DealOverviewSaveCard;
