/* eslint-disable no-unused-vars */
export enum ActiveSections {
    INITIAL = 'Initial',
    START_NEW_REPORT = 'Start New Report',
    SELECT_SAVED_TEMPLATE = 'Select Saved Template',
}

export enum ReportTypes {
    SAVED_REPORT = 'saved_report',
    BACK_OF_ENVELOPE_SCENARIO_COMPARISON = 'back_of_envelope_scenario_comparison',
    DEAL_BACK_OF_EVENLOPE = 'deal_back_of_envelope',
    DEAL_OVERVIEW = 'deal_overview',
    DOCUMENT_LIBRARY = 'document_library',
    PRO_FORMA_EXECUTIVE_SUMMARY = 'pro_forma_executive_summary',
}

export const NEW_REPORT_LABELS = {
    BACK_OF_ENVELOPE_SCENARIO_COMPARISON: 'Back-of-Envelope Scenario Comparison',
    DEAL_BACK_OF_EVENLOPE: 'Deal Back-Of-Envelope',
    DEAL_OVERVIEW: 'Deal Overview',
    DOCUMENT_LIBRARY: 'Document Library',
    PRO_FORMA_EXECUTIVE_SUMMARY: 'Pro Forma Executive Summary',
};

export interface IReportBuilderReport {
    savedReportId?: number;
    savedReportName?: string;
    savedReportType?: string;
    reportType: string;
    selectedBOEScenarioIds?: Array<number>;
    selectedBOEScenarioNames?: Array<string>;
    selectedDealAttributes?: Array<string>;
    selectedProFormaId?: number;
    selectedProFormaName?: string;
    includePrintedOnDate?: boolean;
    includeTargetReturns?: boolean;
    isEditing?: boolean;
    metaData?: any;
}

export const DEAL_OVERVIEW_ATTRIBUTES: Record<'general' | 'dealInformation' | 'dealMetrics' | 'personnel' | 'capitalPartners' | 'vendors', string[]> = {
    general: ['Deal name', 'Address', 'Market', 'Submarket', 'Date created', 'Description'],
    dealInformation: [
        'Deal type',
        'Asset class',
        'Construction type',
        'Units',
        'Units: Gross SF',
        'Units: Rentable SF',
        'Units: Finish SF',
        'Units: Acres',
        'Units: Residential units',
        'Units: Beds',
        'Units: Lots',
        'Phase',
        'Progress',
        'Lead source',
        'Leasing strategy',
        'Rank',
    ],
    dealMetrics: ['Acquisition price', 'Target return on cost', 'Target return on equity', 'Target profit return', 'IRR', 'Equity multiple'],
    personnel: ['Deal creator', 'Deal owner', 'Development manager'],
    capitalPartners: ['Equity partner', 'Lender'],
    vendors: ['Legal counsel', 'Architect', 'Engineer', 'Surveyor', 'Environmental consultant'],
};

export const savedReportTypeMap: Record<number, string> = {
    1: 'Pipeline report',
    2: 'Comparison report',
    3: 'Aggregate report',
};
