import React, { useState } from 'react';
import {
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

import { URLS } from 'services/urls';
import { useAuthContext } from 'contexts/AuthContext';
import { useGetOrganizationAddons } from 'hooks/useGetOrganizationAddons';
import RenderIf from 'components/RenderIf/RenderIf';
import { PORTFOLIO_ANALYTICS_PRO_ADDON_ID } from './constants';
import '../../NSNavbar.scss';

const AnalyticsMenu: React.FC = () => {
    const [isAnalyticsMenuOpen, setIsAnalyticsMenuOpen] = useState<boolean>(false);
    const toggleAnalyticsMenu = () => setIsAnalyticsMenuOpen(!isAnalyticsMenuOpen);
    const { selectedOrganizationId } = useAuthContext();
    const { data: addons, isLoading } = useGetOrganizationAddons(selectedOrganizationId!);

    return (
        <Dropdown
            isOpen={isAnalyticsMenuOpen}
            toggle={toggleAnalyticsMenu}
            onMouseEnter={() => setIsAnalyticsMenuOpen(true)}
            onMouseLeave={() => setIsAnalyticsMenuOpen(false)}
            data-testid="analytics-menu-dropdown"
            className="d-flex align-items-center px-2"
        >
            <DropdownToggle tag="span" data-testid="analytics-menu-toggle" className="NSNavbar__dropdown_toggle">
                Analytics
                <FontAwesomeIcon
                    className="icon ml-2"
                    icon={faCaretDown}
                />
            </DropdownToggle>
            {isAnalyticsMenuOpen && (
                <DropdownMenu className="NSNavbar__dropdown_Menu">
                    <DropdownItem className="text-wrap mt-1">
                        <a href={URLS.PROJECTS_APP.COST_ANALYTICS} className="NSNavbar__dropdown_link">
                            Cost Analytics
                        </a>
                    </DropdownItem>
                    <DropdownItem className="text-wrap">
                        <a href={URLS.PROJECTS_APP.MARKET_DATA} className="NSNavbar__dropdown_link">
                            Market Data
                        </a>
                    </DropdownItem>
                    <DropdownItem className="text-wrap">
                        <a href={URLS.PROJECTS_APP.QUICK_ANALYTICS} className="NSNavbar__dropdown_link">
                            Quick Analytics
                        </a>
                    </DropdownItem>
                    <DropdownItem className="text-wrap">
                        <a href={URLS.PROJECTS_APP.VENDOR_ANALYTICS} className="NSNavbar__dropdown_link">
                            Vendor Analytics
                        </a>
                    </DropdownItem>
                    <RenderIf isTrue={!isLoading}>
                        <RenderIf isTrue={addons?.some(addon => addon.id === PORTFOLIO_ANALYTICS_PRO_ADDON_ID)}>
                            <DropdownItem className="text-wrap">
                                <a href={URLS.PROJECTS_APP.DEVELOPER_FEE} className="NSNavbar__dropdown_link">
                                    Developer Fee
                                </a>
                            </DropdownItem>
                            <DropdownItem className="text-wrap">
                                <a href={URLS.PROJECTS_APP.GLOBAL_COST_FORECAST} className="NSNavbar__dropdown_link">
                                    Global Cost Forecast
                                </a>
                            </DropdownItem>
                            <DropdownItem className="text-wrap">
                                <a href={URLS.PROJECTS_APP.PORTFOLIO_CAPITAL_PLANNING} className="NSNavbar__dropdown_link">
                                    Portfolio Capital Planning
                                </a>
                            </DropdownItem>
                        </RenderIf>
                    </RenderIf>
                </DropdownMenu>
            )}
        </Dropdown>
    );
};

export default AnalyticsMenu;
