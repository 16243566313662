import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { useMutation } from '@tanstack/react-query';
import { ICreateDealDirectoryPostData, IDealDirectory } from '../types';

interface ICreateDealDirectoryParams {
    postData: ICreateDealDirectoryPostData;
    dealId: number;
}

const createDealDirectory = async ({ postData, dealId }: ICreateDealDirectoryParams): Promise<IDealDirectory> => {
    try {
        const response = await axiosInstance.post(`${API_ENDPOINTS.DEAL_DIRECTORY.DIRECTORY(dealId)}`, postData);
        return response.data;
    } catch (error: any) {
        if (error.response?.status !== 500) {
            throw new Error(error.response.data);
        }
        throw new Error('An unexpected error occurred. Please try again.');
    }
};

export const useCreateDealDirectory = () => useMutation({
    mutationFn: createDealDirectory,
});

export default useCreateDealDirectory;
