/* eslint-disable operator-linebreak */
import { Header } from '@tanstack/react-table';
import { useSortable } from '@dnd-kit/sortable';
import React, { useMemo } from 'react';
import { CSS } from '@dnd-kit/utilities';
import './DraggableHeader.scss';
import SortingIcon from './SortingIcon/SortingIcon';

type DraggableHeaderProps<TData> = {
    header: Header<TData, unknown>;
    sortedColumnId: string | null;
    sortedDirection: 'asc' | 'desc' | null;
    onAscendingSortPressed: (columnName: string) => void;
    onDescendingSortPressed: (columnName: string) => void;
};

export const DraggableHeader = <TData, >({
    header,
    sortedColumnId,
    sortedDirection,
    onAscendingSortPressed,
    onDescendingSortPressed,
}: DraggableHeaderProps<TData>) => {
    const isDraggable = header.column.id !== 'select' && header.column.id !== 'actions';

    const {
        attributes, listeners, setNodeRef, transform, isDragging,
    } = useSortable({
        id: header.column.id,
        disabled: !isDraggable,
    });

    const columnHeader = useMemo(
        () => (typeof header.column.columnDef.header === 'function'
            ? header.column.columnDef.header(header.getContext())
            : String(header.column.columnDef.header)),
        [header],
    );

    return (
        <th
            ref={setNodeRef}
            {...(isDraggable ? attributes : {})}
            className={`DraggableHeader ${isDragging ? 'DraggableHeader--no-transition' : ''} ${!isDraggable ? 'DraggableHeader--fixed' : ''}`}
            style={{
                transform: CSS.Translate.toString(transform),
            }}
        >
            <div className="d-flex align-items-center justify-content-between w-100 DraggableHeader__content">
                <div {...(isDraggable ? listeners : {})} className="DraggableHeader__title">
                    {header.isPlaceholder ? null : columnHeader}
                </div>

                {header.column.columnDef.enableSorting && (
                    <div>
                        <SortingIcon
                            isSorted={header.column.id === sortedColumnId}
                            sortedDirection={sortedDirection}
                            onAscendingSort={() => onAscendingSortPressed(header.column.id)}
                            onDescendingSort={() => onDescendingSortPressed(header.column.id)}
                            columnId={header.column.id}
                        />
                    </div>
                )}
            </div>
        </th>
    );
};
