import React, { useEffect, useMemo, useState } from 'react';
import { FormikHelpers } from 'formik';
import { useQueryClient } from '@tanstack/react-query';

import { useAuthContext } from 'contexts/AuthContext';
import {
    NSButton, NSInput, NSLabel, NSSelect,
} from 'bricks';
import NSModal, { INSModalProps } from 'bricks/NSModal/NSModal';
import { IOptionAnyValue } from 'bricks/types';
import RenderIf from 'components/RenderIf/RenderIf';
import { BOESlideoutFormValues } from '../SlideoutForm/types';
import { useGetDeals } from '../../../../Deals/hooks/useFetchDeals';

export interface IImportUsesModalProps extends INSModalProps {
    dealId?: number | null;
    onConfirm: (createNewDeal?: boolean) => void;
    onCancel: () => void;
    setFieldValue?: FormikHelpers<BOESlideoutFormValues>['setFieldValue'];
    selectedScenarioName?: string;
}

const SaveModal = ({
    onConfirm, onCancel, setFieldValue, children, dealId = null, selectedScenarioName = '', ...props
}: IImportUsesModalProps) => {
    const { selectedOrganizationId } = useAuthContext();

    const [scenarioName, setScenarioName] = useState<string>(selectedScenarioName);
    const [selectedDeal, setSelectedDeal] = useState<IOptionAnyValue | undefined>(undefined);

    // TODO If dropdownDeals is more than 100, current dropdown select will no longer be useful.
    // TODO Better to swap with a Input field with dropdown suggestion filter
    const { data: dropdownDeals } = useGetDeals({ pageSize: 100, currentPage: 1, orgId: selectedOrganizationId! });
    const queryClient = useQueryClient();

    const mappedDeals = useMemo(() => dropdownDeals?.items.map(item => ({
        value: item.id,
        label: item.dealName,
    })) || [], [dropdownDeals?.items]);

    useEffect(() => {
        if (!props.isOpen) {
            setScenarioName('');
        } else {
            setScenarioName(selectedScenarioName || '');
            const dealOption = dealId ? mappedDeals.find(deal => deal.value === dealId) : undefined;
            setSelectedDeal(dealOption);
        }
    }, [props.isOpen, selectedScenarioName, dealId, mappedDeals]);

    const handleCancel = () => {
        onCancel();
        setScenarioName('');
    };

    const handleSave = (createNewDeal: boolean = false) => {
        try {
            const selectedDealToSave = mappedDeals?.find(deal => deal.value === selectedDeal?.value);

            if (setFieldValue) {
                setFieldValue('name', scenarioName || selectedScenarioName);
                setFieldValue('dealId', selectedDealToSave?.value);
            }

            onConfirm(createNewDeal);

            Promise.resolve().then(() => {
                setScenarioName('');
                queryClient.invalidateQueries({ queryKey: ['deals', selectedOrganizationId!] });
                onCancel();
            });
        } catch (error) {
            console.error('Error during save:', error);
        }
    };

    return (
        <NSModal
            {...props}
            modalHeaderText="Save scenario"
            modalHeaderClassName="bg-primary text-white"
            modalFooterButtonText="Save to deal"
            modalFooterCancelButtonText="Cancel"
            modalFooterButtonColor="primary"
            modalFooterCancelButtonFunction={handleCancel}
            modalFooterButtonFunction={() => handleSave()}
            customFooter={
                selectedDeal ? undefined : (
                    <div>
                        <NSButton text="Cancel" callback={handleCancel} outline />
                        <NSButton className="mx-2" color="secondary" text="Save" callback={() => handleSave()} />
                        <NSButton color="primary" text="Create new deal from scenario" callback={() => handleSave(true)} />
                    </div>
                )
            }
        >
            <NSInput
                id="scenarioName"
                type="text"
                label="Scenario name"
                name="scenarioName"
                placeholder="Enter name"
                value={scenarioName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setScenarioName(e.target.value)}
                inputClassName="mb-2"
                required
            />
            <NSLabel>Save to deal</NSLabel>
            <NSSelect
                name="deals"
                options={mappedDeals || []}
                value={selectedDeal}
                onChange={option => setSelectedDeal(option)}
                placeholder="Select deal"
            />
            <RenderIf isTrue={!selectedDeal}>
                <small>If no deal is selected, this scenario will be saved as Uncategorized.</small>
            </RenderIf>
        </NSModal>
    );
};

export default SaveModal;
