import React, { useEffect, useState } from 'react';
import { NSDropdown } from 'bricks';
import NSCellInput from 'bricks/NSCellInput/NSCellInput';
import { IOptionAnyValue } from 'bricks/types';
import RenderIf from 'components/RenderIf/RenderIf';
import { PRO_FORMA_UNIT_TO_FIELD_VALUES } from 'constants/unitTypes';
import { numeralFormatterCurrency, percentFormatter } from 'ns_libs/formatter';
import useGetSourceTypes from 'queries/ProFormaSource/useGetSourceTypes';
import {
    DEBT_SOURCE_TYPES, LOAN_RATE_TYPE, PRO_FORMA_DEVELOPMENT_DEBT_RATE_OPTIONS,
} from 'views/ProFormaTable/constants';
import { DevelopmentUnitOfMeasureTypes, IProFormaSourceTableRow, LoanRateType } from 'views/ProFormaTable/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useProFormaSocketContext } from '../../../socketContext/ProFormaSocketProvider';
import './Source.scss';
import PeriodCellValue from '../../DevelopmentUsesTable/PeriodCellValue/PeriodCellValue';

export interface ISourceProps {
    source: IProFormaSourceTableRow;
    totalSourcesAmount: number;
    unitType: DevelopmentUnitOfMeasureTypes;
    selectedMetric: keyof IProFormaSourceTableRow;
    hasWriteAccessToProForma: boolean;
    periodOptions: IOptionAnyValue[];
}

const Source = ({
    source, totalSourcesAmount, unitType, selectedMetric, hasWriteAccessToProForma, periodOptions,
}: ISourceProps) => {
    const { handledDeleteSource, isSourceDeletionLoading, handleUpdateSource } = useProFormaSocketContext();
    const [isHovered, setIsHovered] = useState(false);
    const [name, setName] = useState(source.name);
    const [amount, setAmount] = useState(source.amount || '');
    const [metricAmount, setMetricAmount] = useState(source[selectedMetric] || '');
    const [fixedLoanRate, setFixedLoanRate] = useState(source.fixedLoanRate || '');

    const { data: sourceTypes } = useGetSourceTypes();
    const sourceType = sourceTypes?.find(sourceType => sourceType.correlationId === source.sourceTypeId);
    const isDebtSourceType = DEBT_SOURCE_TYPES.has(source.sourceTypeId);

    const traunchOptions: IOptionAnyValue[] = Array.from({ length: totalSourcesAmount }, (_, index) => ({
        label: `${index + 1}`,
        value: index + 1,
    }));

    const updateField
        = (
            field: keyof IProFormaSourceTableRow,
            value: string | number | null,
            setValue: React.Dispatch<React.SetStateAction<any>>,
            transform?: (val: any) => any,
        ) => (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (source[field] !== value) {
                if (event?.key === 'Enter') {
                    handleUpdateSource(source.id, {
                        [field]: transform ? transform(value) : value,
                    });
                } else if (event?.key === 'Escape') {
                    setValue(source[field] || '');
                }
            }
        };

    const updateName = updateField('name', name, setName);
    const updateAmount = updateField('amount', amount, setAmount, Number);

    const updateMetricAmount = (event: any) => {
        const updatedMetricAmount = Number(metricAmount) > 100 ? 100 : metricAmount;

        if (source[selectedMetric] !== updatedMetricAmount) {
            if (!('key' in event) || event?.key === 'Enter') {
                handleUpdateSource(source.id, { [selectedMetric]: Number(updatedMetricAmount) / 100 });
            } else if (event?.key === 'Escape') {
                setMetricAmount(source[selectedMetric] || '');
            }
        }
    };

    const updateFixedLoanRate = (event: any) => {
        const updatedFixedLoanRate = Number(fixedLoanRate) > 100 ? 100 : fixedLoanRate;

        if (source.loanRateType === LOAN_RATE_TYPE.FIXED && source.fixedLoanRate !== updatedFixedLoanRate) {
            if (!('key' in event) || event?.key === 'Enter') {
                handleUpdateSource(source.id, {
                    loanRateType: LOAN_RATE_TYPE.FIXED as LoanRateType,
                    fixedLoanRate: Number(updatedFixedLoanRate) / 100,
                });
            } else if (event?.key === 'Escape') {
                setFixedLoanRate(source.fixedLoanRate || '');
            }
        }
    };

    const sourceUnitAmount = source[PRO_FORMA_UNIT_TO_FIELD_VALUES[unitType]] !== null ? source[PRO_FORMA_UNIT_TO_FIELD_VALUES[unitType]] : null;

    useEffect(() => {
        if (source.name) {
            setName(source.name);
        }
        if (source.amount) {
            setAmount(source.amount);
        }
        if (selectedMetric && source[selectedMetric]) {
            setMetricAmount(source[selectedMetric] || '');
        }
    }, [source, selectedMetric]);

    return (
        <tr className="NSTable__tbody__tr Source Source__row">
            <td
                className="NSTable__tbody__tr__td NSTable__tbody__tr__td--right-border align-middle
                    NSTable__tbody__tr__td__input NSTable__tbody__tr__td--width-400"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div className="d-flex justify-content-between">
                    <div>
                        <div className="d-flex">
                            <div className="ml-1">{sourceType?.name}</div>
                            <RenderIf isTrue={isDebtSourceType}>
                                <NSDropdown
                                    options={PRO_FORMA_DEVELOPMENT_DEBT_RATE_OPTIONS}
                                    selectedOption={PRO_FORMA_DEVELOPMENT_DEBT_RATE_OPTIONS.find(option => option.value === source.loanRateType)}
                                    handleSelectOption={option => {
                                        if (source.loanRateType !== option.value) {
                                            handleUpdateSource(source.id, { loanRateType: option.value, fixedLoanRate: null });
                                        }
                                    }}
                                    isBadgeToggle
                                    badgeColor="none"
                                    containerClassName="text-dark"
                                />
                            </RenderIf>
                        </div>
                        <div className="d-flex Source__row__cell-input--height-45">
                            <NSCellInput
                                value={source.name}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                                onEnter={updateName}
                                onBlur={() => (source.name !== name ? handleUpdateSource(source.id, { name }) : null)}
                                placeholder="Enter source name"
                                labelClassName="my-2"
                            />

                            <RenderIf isTrue={source.loanRateType === LOAN_RATE_TYPE.FIXED}>
                                <NSCellInput
                                    value={source.fixedLoanRate !== null ? source.fixedLoanRate * 100 : null}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFixedLoanRate(e.target.value)}
                                    onEnter={(e: React.KeyboardEvent<HTMLInputElement>) => updateFixedLoanRate(e)}
                                    onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => updateFixedLoanRate(e)}
                                    placeholder="—"
                                    labelClassName="my-2"
                                    isPercentage
                                />
                            </RenderIf>
                        </div>
                    </div>

                    <RenderIf isTrue={isHovered && hasWriteAccessToProForma}>
                        <div className="d-flex align-items-center">
                            <RenderIf isTrue={isSourceDeletionLoading}>
                                <span className="spinner-border spinner-border-sm text-muted mr-2 ml-1" />
                            </RenderIf>
                            <RenderIf isTrue={!isSourceDeletionLoading}>
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    onClick={() => handledDeleteSource(source.id)}
                                    className="text-muted cursor--pointer mr-2 pr-1 hover:text-dark"
                                />
                            </RenderIf>
                        </div>
                    </RenderIf>
                </div>
            </td>
            <td className="NSTable__tbody__tr__td align-middle NSTable__tbody__tr__td--width-100">
                <RenderIf isTrue={isDebtSourceType && selectedMetric}>
                    <div className="d-flex justify-content-end text-primary">
                        <NSCellInput
                            value={selectedMetric && source[selectedMetric] !== null ? Number(source[selectedMetric]) * 100 : null}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMetricAmount(e.target.value)}
                            onEnter={(e: React.KeyboardEvent<HTMLInputElement>) => updateMetricAmount(e)}
                            onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => updateMetricAmount(e)}
                            type="number"
                            placeholder="—"
                            isRightAligned
                            isPercentage
                        />
                    </div>
                </RenderIf>
            </td>
            <td className="NSTable__tbody__tr__td align-middle">
                <div className="d-flex justify-content-end">
                    <NSDropdown
                        options={traunchOptions}
                        selectedOption={traunchOptions?.find(traunch => traunch.value === source.traunch)}
                        handleSelectOption={option => (source.traunch !== option.value ? handleUpdateSource(source.id, { traunch: option.value }) : null)}
                        isBadgeToggle
                        badgeColor="none"
                        containerClassName="text-primary"
                    />
                </div>
            </td>
            <td className="NSTable__tbody__tr__td align-middle">
                <div className="d-flex justify-content-end text-dark">{percentFormatter((source.sourcesPercentage || 0) * 100)}</div>
            </td>
            <td className="NSTable__tbody__tr__td align-middle">
                <div className="d-flex justify-content-end text-dark">
                    {sourceUnitAmount !== null ? numeralFormatterCurrency(Number(sourceUnitAmount)) : '—'}
                </div>
            </td>
            <td className="NSTable__tbody__tr__td align-middle NSTable__tbody__tr__td--width-100">
                <div className="d-flex align-items-center justify-content-end">
                    <NSCellInput
                        value={source.amount}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAmount(e.target.value)}
                        onEnter={(e: React.KeyboardEvent<HTMLInputElement>) => updateAmount(e)}
                        onBlur={() => (source.amount !== amount ? handleUpdateSource(source.id, { amount: Number(amount) }) : null)}
                        type="number"
                        placeholder="—"
                        isRightAligned
                    />
                </div>
            </td>
            {periodOptions?.map(period => {
                const targetPeriod = source.periods?.find(p => p.periodName === period.label);

                return (
                    <PeriodCellValue
                        key={period.value}
                        period={period}
                        value={targetPeriod?.amountToFund === null || source.amount === null
                            ? '—' : numeralFormatterCurrency(targetPeriod?.amountToFund)}
                        cellClassName="NSTable__tbody__tr__td text-dark text-right"
                        overlayingCellInput={source.loanRateType === LOAN_RATE_TYPE.VARIABLE ? (
                            <div className="d-flex align-items-center justify-content-end">
                                <NSCellInput
                                    value={null} // TODO: add variableLoanRate here
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => null}
                                    onEnter={(e: React.KeyboardEvent<HTMLInputElement>) => null}
                                    onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => null}
                                    placeholder="—"
                                    labelClassName="my-2"
                                    isPercentage
                                />
                            </div>
                        ) : null}
                    />
                );
            })}
        </tr>
    );
};

export default Source;
