import React from 'react';
import { URLS } from 'services/urls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUserCircle, faGrip, faLifeRing, faRightFromBracket,
} from '@fortawesome/free-solid-svg-icons';
import { DropdownMenu, DropdownItem } from 'reactstrap';
import './UserMenuItems.scss';
import { useAuth0 } from '@auth0/auth0-react';
import useAuthContext from 'contexts/AuthContext';
import { USER_CLASSES } from 'services/constants';
import useGetAuthenticatedUser from '../../../../../queries/UserMenu/useGetAuthenticatedUser';

const UserMenuItems = () => {
    const { logout } = useAuth0();
    const { role } = useAuthContext();

    const { data: authenticatedUser } = useGetAuthenticatedUser();

    let displayRole = role?.name || 'No Role';
    // a super admin user class supersedes role in auth checks
    if (authenticatedUser?.user.userClass === USER_CLASSES.SUPER_ADMIN) {
        displayRole = authenticatedUser?.user.userClassData.name;
    }

    return (
        <DropdownMenu className="UserMenuItems-dropdown-menu" data-testid="dropdown-menu">
            <DropdownItem header className="text-wrap">
                {authenticatedUser?.user.name}
                {' '}
                {' | '}
                {' '}
                {displayRole}
            </DropdownItem>
            <DropdownItem header className="UserMenuItems-dropdown-menu__dropdown-email">
                {authenticatedUser?.user.email}
            </DropdownItem>
            <DropdownItem divider />

            <a href={URLS.PROJECTS_APP.USER_SETTINGS}>
                <DropdownItem className="d-flex align-items-center py-1 px-2">
                    <FontAwesomeIcon className="mr-1" icon={faUserCircle} size="sm" />
                    Settings
                </DropdownItem>
            </a>

            <a href={URLS.PROJECTS_APP.ORGANIZATION_MANAGEMENT}>
                <DropdownItem className="d-flex align-items-center py-1 px-2">
                    <FontAwesomeIcon className="mr-1" icon={faGrip} size="sm" />
                    Organization Management
                </DropdownItem>
            </a>

            <DropdownItem divider />

            <a href={URLS.SUPPORT_URL} target="_blank" rel="noopener noreferrer">
                <DropdownItem className="d-flex align-items-center py-1 px-2">
                    <FontAwesomeIcon className="mr-1" icon={faLifeRing} size="sm" />
                    Support
                </DropdownItem>
            </a>

            <DropdownItem
                className="d-flex align-items-center py-1 px-2"
                onClick={() => logout({
                    logoutParams: {
                        returnTo: window.location.origin,
                    },
                })}
            >
                <FontAwesomeIcon className="mr-1" icon={faRightFromBracket} size="sm" />
                Logout
            </DropdownItem>
        </DropdownMenu>
    );
};

export default UserMenuItems;
