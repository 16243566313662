import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftToLine } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import NSSearchBar from 'bricks/NSSearchBar/NSSearchBar';
import { NSButton } from 'bricks';

interface ImportSearchBarProps {
    onExitImport: () => void;
    onSearch: (value: string) => void;
}

const ImportSearchBar: React.FC<ImportSearchBarProps> = ({ onExitImport, onSearch }) => {
    const [isDealsSelected, setIsDealsSelected] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const handleToggleSelection = (isDeals: boolean) => {
        setIsDealsSelected(isDeals);
        setSearchValue('');
        onSearch('');
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setSearchValue(newValue);
        onSearch(newValue);
    };

    const searchPlaceholder = isDealsSelected ? 'Search deals' : 'Search projects';

    return (
        <>
            <div className="text-primary cursor--pointer pb-1" role="presentation" onClick={onExitImport}>
                <FontAwesomeIcon icon={faArrowLeftToLine} className="ml-1 my-auto text-primary pr-1" />
                Exit import
            </div>
            <div className="d-flex flex-row w-100">
                <NSSearchBar
                    containerClassName="mb-0 flex-grow-1"
                    searchValue={searchValue}
                    placeholder={searchPlaceholder}
                    handleChangeSearchValue={handleSearch}
                    id="report-options"
                />
                <div className="pl-1 my-auto font-weight-normal text-dark text-nowrap">
                    <NSButton
                        color="secondary"
                        outline={false}
                        callback={() => handleToggleSelection(true)}
                        text="Deals"
                        className={classNames('px-3', { 'border border-primary bg-primary-lighten': isDealsSelected })}
                    />
                    <NSButton
                        color="secondary"
                        outline={false}
                        callback={() => handleToggleSelection(false)}
                        text="Projects"
                        className={classNames('px-3', {
                            'border border-primary bg-primary-lighten': !isDealsSelected,
                        })}
                    />
                </div>
            </div>
        </>
    );
};

export default ImportSearchBar;
