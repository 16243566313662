import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS, { QUERY_KEYS } from 'services/urls';
import { IMarket } from 'types/market';

const getMarkets = async (): Promise<IMarket[]> => {
    const response = await axiosInstance.get<IMarket[]>(API_ENDPOINTS.MSAS);
    return response.data;
};

export const useGetMarkets = (options?: Omit<UseQueryOptions<IMarket[]>, 'queryKey' | 'queryFn'>) => useQuery<IMarket[], Error>({
    queryKey: [QUERY_KEYS.MSAS],
    queryFn: () => getMarkets(),
    staleTime: Infinity,
    ...(options ?? {}),
});
