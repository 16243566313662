import React from 'react';
import { NSCard } from 'bricks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseyeArrow } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import { formatBOEValues, FormatterType } from '../../helper';
import './SlideOutReturnCard.scss';

export interface IScenarioReturnsProps {
    title: string;
    returnValue: number;
    targetValue: number | null;
    formatter: FormatterType;
}

const SlideOutReturnCard = ({
    title, returnValue, targetValue, formatter,
}: IScenarioReturnsProps) => {
    let displayMode = 'unstyled';
    if (targetValue !== null && returnValue !== targetValue) {
        displayMode = returnValue > targetValue ? 'success' : 'danger';
    }

    return (
        <NSCard
            key={title}
            className={classNames('pl-1 pr-2 py-1 flex-grow-1 SlideOutReturnCard__card', {
                'SlideOutReturnCard__card--success': displayMode === 'success',
                'SlideOutReturnCard__card--error': displayMode === 'danger',
            })}
        >
            <div className="">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="text-white mt-0 mb-0">{title}</div>
                    <div className="text-white font-weight-bold mt-0 mb-0">
                        {returnValue ? formatBOEValues(returnValue, formatter) : '-'}
                    </div>
                </div>
                <small className="d-flex flex-row justify-content-between align-items-center">
                    <div>
                        <FontAwesomeIcon icon={faBullseyeArrow} size="sm" />
                        <span> Target:</span>
                    </div>
                    <span>{targetValue ? formatBOEValues(targetValue, formatter) : '-'}</span>
                </small>
            </div>
        </NSCard>
    );
};

export default SlideOutReturnCard;
