import React, { useMemo, useState } from 'react';
import { NSCard, NSNavTabs } from 'bricks';
import classNames from 'classnames';
import { AttributeKey } from 'views/Reports/types';
import Attributes from './Attributes/Attributes';
import { TABS } from './constants';
import FiltersSection from './Filters/FiltersSection';
import { IFilter } from './Filters/types';

interface ConfigurationSidebarProps {
    selectedColumns: AttributeKey[];
    setSelectedColumns: (columns: AttributeKey[]) => void;
    filters: IFilter[];
    setFilters: (filters: IFilter[]) => void;
}

const ConfigurationSidebar = ({
    selectedColumns, setSelectedColumns, filters, setFilters,
}: ConfigurationSidebarProps) => {
    const [activeTab, setActiveTab] = useState(TABS.ATTRIBUTES);

    const tabs = useMemo(
        () => Object.values(TABS).map(tab => ({
            name: tab === TABS.FILTERS && filters.length ? `${tab} (${filters.length})` : tab,
            callback: () => setActiveTab(tab),
            isActive: activeTab === tab,
            id: tab,
        })),
        [activeTab, filters],
    );

    return (
        <NSCard className="ConfigurationSidebar w-25 p-3">
            <NSNavTabs navItems={tabs} />
            <Attributes
                className={classNames({ 'd-none': activeTab !== TABS.ATTRIBUTES })}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
            />
            <FiltersSection className={classNames({ 'd-none': activeTab !== TABS.FILTERS })} filters={filters} setFilters={setFilters} />
        </NSCard>
    );
};

export default ConfigurationSidebar;
