import React from 'react';
import { NSCard } from 'bricks';
import './ExecutiveSummaryCard.scss';
import { ISummaryItem } from './types';

const DescriptionItem = ({ value }: Pick<ISummaryItem, 'value'>) => (
    <NSCard className="NSCard--level-5">
        <div className="flex-row justify-content-between align-items-center p-2">
            <div className="text-muted">{value}</div>
        </div>
    </NSCard>
);

export default DescriptionItem;
