import React, { useEffect, useState } from 'react';
import { NSSwitch } from 'bricks';
import ReadOnlyWrapper from 'components/ReadOnlyWrapper/ReadOnlyWrapper';
import { ExpandableSection } from 'components/ExpandableSection/ExpandableSection';
import { ProFormaModelIds } from 'constants/proForma';
import RenderIf from 'components/RenderIf/RenderIf';
import OperatingInformation from './OperatingInformation/OperatingInformation';
import ApartmentIncomeTable from './ProFormaIncomeTable/ApartmentIncomeTable/ApartmentIncomeTable';
import ProFormaExpenses from './ProFormaExpenses/ProFormaExpenses';
import ProFormaPermanentDebt from './ProFormaPermanentDebt/ProFormaPermanentDebt';
import './ProFormaOperations.scss';
import { useProFormaSocketContext } from '../socketContext/ProFormaSocketProvider';
import DataCenterIncomeTable from './ProFormaIncomeTable/DataCenterIncomeTable/DataCenterIncomeTable';
import OfficeIndustrialIncomeTable from './ProFormaIncomeTable/OfficeIndustrialIncomeTable/OfficeIndustrialIncomeTable';

export interface IProFormaOperationsProps {
    hasWriteAccessToProForma: boolean;
}

const ProFormaOperations = ({ hasWriteAccessToProForma }: IProFormaOperationsProps) => {
    const { proForma, operatingInfo, handleUpdateProFormaOperations } = useProFormaSocketContext();
    const [includePermanentDebt, setIncludePermanentDebt] = useState(operatingInfo.includePermanentDebt || false);

    const handleTogglePermanentDebt = () => {
        setIncludePermanentDebt(prev => !prev);
        handleUpdateProFormaOperations(operatingInfo.id, { includePermanentDebt: !includePermanentDebt });
    };

    useEffect(() => {
        setIncludePermanentDebt(operatingInfo?.includePermanentDebt || false);
    }, [operatingInfo.includePermanentDebt]);

    return (
        <ReadOnlyWrapper isReadOnly={!hasWriteAccessToProForma}>
            <ExpandableSection
                number={1}
                title="Operating Information"
                subtitle="Information about this investment's incoming revenue"
                className="NSCard--level-2"
                isExpanded
                animate
            >
                <OperatingInformation />
            </ExpandableSection>
            <ExpandableSection
                number={2}
                title="Income"
                subtitle="Overview of this investment's total income"
                className="mt-3 NSCard--level-2"
                isExpanded
                animate
            >
                <RenderIf isTrue={proForma.proFormaModelId === ProFormaModelIds.APARTMENT}>
                    <ApartmentIncomeTable />
                </RenderIf>
                <RenderIf isTrue={proForma.proFormaModelId === ProFormaModelIds.DATA_CENTER}>
                    <DataCenterIncomeTable />
                </RenderIf>
                <RenderIf isTrue={proForma.proFormaModelId === ProFormaModelIds.OFFICE
                    || proForma.proFormaModelId === ProFormaModelIds.INDUSTRIAL}
                >
                    <OfficeIndustrialIncomeTable />
                </RenderIf>
            </ExpandableSection>
            <ExpandableSection
                number={3}
                title="Expenses"
                subtitle="Overview of this investment's total expenses"
                className="mt-3 NSCard--level-2"
                isExpanded
                animate
            >
                <ProFormaExpenses />
            </ExpandableSection>
            <ExpandableSection
                number={4}
                title="Permanent Debt"
                subtitle="Include permanent debt in this Pro Forma"
                className="mt-3 NSCard--level-2"
                isExpanded={includePermanentDebt}
                animate
                customToggle={(
                    <NSSwitch
                        id="include-permanent-debt"
                        name="include-permanent-debt"
                        containerClassName="ProFormaOperations__permanent-debt-toggle"
                        checked={includePermanentDebt}
                        onChange={e => e.preventDefault()}
                    />
                )}
                onCustomToggle={handleTogglePermanentDebt}
            >
                <ProFormaPermanentDebt />
            </ExpandableSection>
        </ReadOnlyWrapper>
    );
};

export default ProFormaOperations;
