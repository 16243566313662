import {
    BarElement, ChartType, PointElement, Tooltip,
    TooltipPositionerFunction,
} from 'chart.js';
import { format } from 'date-fns';

declare module 'chart.js' {
    // eslint-disable-next-line no-unused-vars
    interface TooltipPositionerMap {
        customPositioner: TooltipPositionerFunction<ChartType>;
    }
}

// Plugin to display a background color for the chart/grid area
const chartAreaBackgroundPlugin = {
    id: 'chartAreaBackground',
    beforeDraw: (chart: any) => {
        const { ctx, chartArea } = chart;
        ctx.save();
        ctx.fillStyle = chart.options.plugins.chartAreaBackground.color;
        ctx.fillRect(
            chartArea.left,
            chartArea.top,
            chartArea.right - chartArea.left,
            chartArea.bottom - chartArea.top,
        );
        ctx.restore();
    },
};

// Plugin to display a background color for the Y-axis labels area
const yAxisBackgroundPlugin = {
    id: 'yAxisBackground',
    beforeDraw: (chart: any) => {
        if (!chart.ctx) return;
        const {
            ctx, chartArea, scales, options,
        } = chart;
        if (!scales.y) return;

        ctx.save();
        ctx.fillStyle = options.plugins.yAxisBackground.color;

        // Calculate the Y-axis label area width
        const yAxisWidth = scales.y.left - chartArea.left;
        ctx.fillRect(
            scales.y.left - yAxisWidth,
            chartArea.top,
            yAxisWidth,
            chartArea.bottom - chartArea.top,
        );

        ctx.restore();
    },
};

// Plugin to display the header above the Y-axis labels
const yAxisHeaderPlugin = {
    id: 'yAxisHeader',
    beforeDraw: (chart: any) => {
        const {
            ctx, chartArea, scales, options,
        } = chart;
        if (!scales.y) return;

        const lightMode = options.plugins.yAxisHeader.lightMode ?? false;
        ctx.save();
        ctx.fillStyle = lightMode ? 'black' : 'white'; // Text color
        ctx.font = 'bold 14px Arial';
        ctx.textAlign = 'left';

        // add the header label in the correct position
        ctx.fillText('Deal', chartArea.left - 55, chartArea.top - 10);

        ctx.restore();
    },
};

// Plugin to display year headers above the X-axis labels
const xAxisYearHeaderPlugin = {
    id: 'xAxisYearHeader',
    beforeDraw: (chart: any) => {
        const {
            ctx, chartArea, scales, options,
        } = chart;
        if (!scales.x) return;

        const lightMode = options.plugins.xAxisYearHeader.lightMode ?? false;
        ctx.save();
        ctx.fillStyle = lightMode ? 'black' : 'white';
        ctx.font = '700 14px Arial';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';

        const tickPositions = scales.x.ticks.map((tick: any) => tick.value);
        const years = [
            ...new Set(
                tickPositions.map((value: any) => format(new Date(value), 'yyyy')),
            ),
        ];

        years.forEach((year: any) => {
            const firstTickOfYear = tickPositions.find(
                (value: any) => format(new Date(value), 'yyyy') === year,
            );
            if (firstTickOfYear) {
                const xPos = scales.x.getPixelForValue(firstTickOfYear);
                ctx.fillText(year, xPos + 5, chartArea.top - 35); // align year label
            }
        });

        ctx.restore();
    },
};

// Plugin to display background color for the header area above the X-axis labels
const xAxisHeaderBackgroundPlugin = {
    id: 'xAxisHeaderBackground',
    beforeDraw: (chart: any) => {
        const {
            ctx, chartArea, options, scales,
        } = chart;
        if (!scales.x) return;

        ctx.save();

        // height of the X-axis header area
        const headerHeight = 55;

        // background for the header aread above the X-axis labels
        ctx.fillStyle = options.plugins.xAxisHeaderBackground.color;
        ctx.fillRect(
            chartArea.left,
            chartArea.top - headerHeight,
            chartArea.right + (scales.y.left - chartArea.left),
            headerHeight,
        );

        // background for the header area above the Y-axis
        ctx.fillStyle = options.plugins.xAxisHeaderBackground.yAxisOverlapColor;
        const yAxisWidth = scales.y.left - chartArea.left;
        ctx.fillRect(
            yAxisWidth,
            chartArea.top - headerHeight,
            chartArea.left - yAxisWidth,
            headerHeight,
        );
        ctx.restore();
    },
};

// eslint-disable-next-line func-names
Tooltip.positioners.customPositioner = function (elements: any, eventPosition :any) {
    if (!elements.length) return false;

    const tooltip = this;

    if (!tooltip?.dataPoints?.length) return false;

    const { chart } = tooltip;
    const { datasetIndex } = tooltip.dataPoints[0];
    const { dataIndex } = tooltip.dataPoints[0];

    const element = chart.getDatasetMeta(datasetIndex).data[dataIndex];

    if (!element) return { x: eventPosition.x, y: eventPosition.y };

    if (element instanceof BarElement) {
        const { y, x, width } = element as any;

        return {
            x: x - (width / 2),
            y: y - 5,
            xAlign: 'center',
            yAlign: 'bottom',
        };
    }

    const { x, y } = element as PointElement;

    return {
        x,
        y: y - 5,
        xAlign: 'center',
        yAlign: 'bottom',
    };
};

export const allPlugins = [
    Tooltip,
    xAxisHeaderBackgroundPlugin,
    chartAreaBackgroundPlugin,
    yAxisBackgroundPlugin,
    yAxisHeaderPlugin,
    xAxisYearHeaderPlugin,
];
