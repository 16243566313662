import React from 'react';
import { IReportBuilderReport, NEW_REPORT_LABELS, ReportTypes } from 'views/ReportBuilder/constants';
import ReportDetailCard from '../components/ReportDetailCard/ReportDetailCard';

export interface IProps {
    report: IReportBuilderReport;
    setNewReports: React.Dispatch<React.SetStateAction<IReportBuilderReport[]>>;
}
const DealOverviewDetailCard = ({ report, setNewReports }: IProps) => {
    const handleEdit = () => {
        setNewReports(prevReports => {
            const reportIndex = prevReports.findIndex(r => r.reportType === ReportTypes.DEAL_OVERVIEW);

            if (reportIndex === -1) {
                return prevReports;
            }

            const updatedReports = [...prevReports];
            updatedReports[reportIndex] = {
                ...prevReports[reportIndex],
                isEditing: true,
            };
            return updatedReports;
        });
    };

    const handleDelete = () => {
        setNewReports(prevReports => {
            const reportIndex = prevReports.findIndex(r => r.reportType === ReportTypes.DEAL_OVERVIEW);

            if (reportIndex === -1) {
                return prevReports;
            }

            const updatedReports = [...prevReports];
            updatedReports.splice(reportIndex, 1);
            return updatedReports;
        });
    };

    return (
        <ReportDetailCard header={NEW_REPORT_LABELS.DEAL_OVERVIEW} handleEdit={handleEdit} handleDelete={handleDelete}>
            <p className="mb-0">Include printed on date</p>
            <p className="mb-0 mt-1 text-white">{report.includePrintedOnDate ? 'Yes' : 'No'}</p>
            <p className="mb-0 mt-3">Include target returns</p>
            <p className="mb-0 mt-1 text-white">{report.includeTargetReturns ? 'Yes' : 'No'}</p>
            <p className="mb-0 mt-3">Attributes</p>
            {report.selectedDealAttributes?.map((attribute: string) => (
                <p key={attribute} className="mb-0 mt-1 text-white">
                    {attribute}
                </p>
            ))}
        </ReportDetailCard>
    );
};

export default DealOverviewDetailCard;
