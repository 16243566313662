import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { useMutation } from '@tanstack/react-query';
import { IUpdateDirectoryRequest, IDirectoryResponse } from '../types';

interface IProps {
    directoryId: number;
    dealId: number;
    data: IUpdateDirectoryRequest;
}

const updateDirectory = async ({ directoryId, dealId, data }: IProps): Promise<IDirectoryResponse> => {
    try {
        const response = await axiosInstance.put(`${API_ENDPOINTS.DEAL_DIRECTORY.DIRECTORY(dealId, directoryId)}`, data);
        return response.data;
    } catch (error: any) {
        if (error.response?.status !== 500) {
            throw new Error(error.response.data);
        }
        throw new Error('An unexpected error occurred. Please try again.');
    }
};

export const useUpdateDirectory = () => useMutation({
    mutationFn: updateDirectory,
});

export default useUpdateDirectory;
