import React from 'react';
import { percentFormatter } from 'ns_libs/formatter';
import { Col, Row } from 'reactstrap';
import { IProjectionMetricsData } from '../../../types';

export const ProjectionMetrics: React.FC<{ data: IProjectionMetricsData }> = ({ data }) => (
    data && (
        <div className="text-white text-right">
            <Row>
                <Col xs="7">Untrended:</Col>
                <Col xs="5" className="text-truncate">
                    {percentFormatter(data.untrended)}
                </Col>
            </Row>
            <Row>
                <Col xs="7">Trended:</Col>
                <Col xs="5" className="text-truncate">{`${data.trended}X`}</Col>
            </Row>
            <Row>
                <Col xs="7">Sale:</Col>
                <Col xs="5" className="text-truncate">
                    {percentFormatter(data.sale)}
                </Col>
            </Row>
        </div>
    )
);

export default ProjectionMetrics;
