export const FOLDER_OPTIONS = [
    { label: 'Home 1', value: 'Home' },
    { label: 'Folder 1', value: 'Folder 1' },
    { label: 'Folder 2', value: 'Folder 2' },
];

export const DOCUMENT_TYPES = [
    { label: 'CAD/BIM', value: 'CAD/BIM' },
    { label: 'Drawings - Amendments/Bulletins', value: 'Drawings - Amendments/Bulletins' },
    { label: 'Drawings - Construction Documents', value: 'Drawings - Construction Documents' },
    { label: 'Drawings - Design Development', value: 'Drawings - Design Development' },
    { label: 'Drawings - Filing Set', value: 'Drawings - Filing Set' },
    { label: 'Drawings - Schematic Design', value: 'Drawings - Schematic Design' },
    { label: 'Environmental Phase I Assessment', value: 'Environmental Phase I Assessment' },
    { label: 'Environmental Phase II Assessment', value: 'Environmental Phase II Assessment' },
    { label: 'FF&E', value: 'FF&E' },
    { label: 'Field/Daily Report', value: 'Field/Daily Report' },
    { label: 'Financial Pro Forma', value: 'Financial Pro Forma' },
    { label: 'Geotechnical Report', value: 'Geotechnical Report' },
    { label: 'JV Agreement', value: 'JV Agreement' },
    { label: 'Lease Agreement', value: 'Lease Agreement' },
    { label: 'Legal', value: 'Legal' },
    { label: 'Loan/Financing Agreement', value: 'Loan/Financing Agreement' },
    { label: 'Municipal/Government Documents', value: 'Municipal/Government Documents' },
    { label: 'Operations & Maintenance Manual', value: 'Operations & Maintenance Manual' },
    { label: 'Other', value: 'Other' },
    { label: 'Permits/Entitlements', value: 'Permits/Entitlements' },
    { label: 'Progress Photos', value: 'Progress Photos' },
    { label: 'Punch List', value: 'Punch List' },
    { label: 'Purchase Agreement', value: 'Purchase Agreement' },
    { label: 'Renderings', value: 'Renderings' },
    { label: 'RFI', value: 'RFI' },
    { label: 'Signage/Wayfinding', value: 'Signage/Wayfinding' },
    { label: 'Submittal', value: 'Submittal' },
    { label: 'Utility Agreement', value: 'Utility Agreement' },
    { label: 'Warranty', value: 'Warranty' },
];

// TODO: Remove this constant once connected to the backend.
// The goal is to fetch activities corresponding to the selected deal dynamically.
export const ACTIVITIES = [
    { label: 'Drawings - Schematic Design', value: 'Drawings - Schematic Design' },
    { label: 'Environmental Phase I Assessment', value: 'Environmental Phase I Assessment' },
    { label: 'Environmental Phase II Assessment', value: 'Environmental Phase II Assessment' },
    { label: 'Financial Pro Forma', value: 'Financial Pro Forma' },
    { label: 'JV Agreement', value: 'JV Agreement' },
    { label: 'Lease Agreement', value: 'Lease Agreement' },
    { label: 'Loan/Financing Agreement', value: 'Loan/Financing Agreement' },
    { label: 'Municipal/Government Documents', value: 'Municipal/Government Documents' },
    { label: 'Permits/Entitlements', value: 'Permits/Entitlements' },
    { label: 'Purchase Agreement', value: 'Purchase Agreement' },
    { label: 'Comps', value: 'Comps' },
    { label: 'Sales Brochure', value: 'Sales Brochure' },
    { label: 'Investment Memo', value: 'Investment Memo' },
    { label: 'Offering Memo', value: 'Offering Memo' },
    { label: 'Zoning Report', value: 'Zoning Report' },
    { label: 'Market Feasibility Study', value: 'Market Feasibility Study' },
    { label: 'Real Estate Taxes', value: 'Real Estate Taxes' },
];
