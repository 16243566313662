import React from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { NSTable } from 'bricks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmptyState from 'components/EmptyState/EmptyState';
import { ColSortConfig } from 'types/sort';
import { getColSortConfig, getSortIcon } from 'helpers/sort';
import { AttributeKey } from 'views/Reports/types';
import { AttributeKeysMap } from '../ConfigurationSidebar/constants';

interface IDealsTableProps {
    selectedColumns: AttributeKey[];
    colSortConfig: ColSortConfig<AttributeKey> | undefined;
    setColSortConfig: (config: ColSortConfig<AttributeKey> | undefined) => void;
    isEditMode: boolean;
    tableData: {
        id: number;
        values: string[];
    }[];
}

const DealsTable = ({
    selectedColumns, tableData, colSortConfig, setColSortConfig, isEditMode,
}: IDealsTableProps) => {
    const configureSort = (key: AttributeKey) => {
        const newColSortConfig = getColSortConfig(key, colSortConfig);
        setColSortConfig(newColSortConfig);
    };

    return (
        <>
            {selectedColumns.length === 0 && (
                <EmptyState
                    title="No columns added yet!"
                    subtitle="Add attributes as columns from the left sidebar to populate this area."
                    className="Pipeline-report__table-empty-state bg-transparent"
                />
            )}
            {tableData.length > 0 ? (
                <OverlayScrollbarsComponent
                    defer
                    options={{
                        scrollbars: { autoHide: 'leave' },
                        overflow: { y: 'scroll' },
                    }}
                    className="OverlayScrollbarsComponent overflow-auto"
                >
                    <div className="NSTable--sticky-headers__table-container">
                        <NSTable className="NSTable NSTable--no-footer NSTable--sticky-headers NSTable--sticky-headers--full-width mb-1">
                            <thead className="NSTable__tbody__subheader">
                                <tr>
                                    {selectedColumns.map(col => (
                                        <th key={`header-cell-${col.split(' ').join('')}`}>
                                            <div className="d-flex justify-content-between align-items-center text-nowrap">
                                                <span>{AttributeKeysMap[col]}</span>
                                                {isEditMode && (
                                                    <FontAwesomeIcon
                                                        icon={getSortIcon(col, colSortConfig)}
                                                        className="cursor--pointer ml-3"
                                                        onClick={() => configureSort(col)}
                                                    />
                                                )}
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="NSTable__tbody text-white">
                                {tableData.map(deal => (
                                    <tr key={`row-${deal.id}`} className="NSTable__tbody__tr--level-3">
                                        {deal.values.map((cell, cellIdx) => (
                                            <td key={`cell-${selectedColumns[cellIdx]}-for-deal_id-${deal.id}`}>{cell}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </NSTable>
                    </div>
                </OverlayScrollbarsComponent>
            ) : (
                <EmptyState
                    title="No results available for selected filter(s)."
                    subtitle="Modify your selected filter(s) to populate this screen with relevant information."
                    className="Pipeline-report__table-empty-state bg-transparent"
                />
            )}
        </>
    );
};

export default DealsTable;
