import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { useMutation } from '@tanstack/react-query';

interface IDeleteVariables {
    orgId: string;
    scenarioId: number;
}

const deleteBackOfEnvelope = async ({ orgId, scenarioId }: IDeleteVariables): Promise<void> => {
    await axiosInstance.delete(`${API_ENDPOINTS.BACK_OF_ENVELOPE_BY_ID(orgId, String(scenarioId))}`);
};

export const useDeleteBackOfEnvelope = () => useMutation({
    mutationFn: deleteBackOfEnvelope,
});
