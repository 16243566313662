import React, { useState, useEffect } from 'react';
import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';

export interface IProps {
    label: string | React.ReactNode;
    children: any;
    expanded?: boolean; // if no "onChange" provided, sets initial expanded state
    onChange?: (arg0?: unknown) => void; // if provided, "expanded" must be managed externally by the caller
    className?: string;
    animate?: boolean;
}

export const ExpandableField = ({
    label, children, onChange, expanded = false, className = '', animate = false,
}: IProps) => {
    const [_expanded, setExpanded] = useState(expanded);
    const toggleExpanded = () => (onChange ? onChange() : setExpanded(!_expanded));

    useEffect(() => {
        setExpanded(expanded);
    }, [expanded]);

    return (
        <div className={className} data-testid="section-container">
            <div
                className="d-flex align-items-center cursor-pointer mb-1 text-white"
                onClick={toggleExpanded}
                onKeyDown={toggleExpanded}
                role="button"
                tabIndex={0}
            >
                <FontAwesomeIcon className="icon text-primary" icon={_expanded ? faCaretDown : faCaretRight} fixedWidth data-testid="caret-icon" />
                <div className="pl-1 mb-0">{label}</div>
            </div>
            <Collapse isOpen={_expanded} enter={animate} exit={animate} data-testid="collapse-section">
                {children}
            </Collapse>
        </div>
    );
};

export default ExpandableField;
