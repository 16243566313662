import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../../../ns_libs/nsAxios';
import API_ENDPOINTS from '../../../services/urls';
import { ISubprojectStageCount } from '../types';

export const getSubprojectsStagesCount = async ({ orgId }: { orgId: string }) => {
    const response = await axiosInstance.get(API_ENDPOINTS.SUBPROJECT_STAGES_COUNT(orgId));
    return response.data;
};

export const useGetSubprojectsStagesCount = ({ orgId, shouldFetch }: { orgId: string; shouldFetch: boolean }) => useQuery<ISubprojectStageCount[], Error>({
    queryKey: ['useGetSubprojectsStagesCount', orgId],
    queryFn: async () => getSubprojectsStagesCount({ orgId }),
    enabled: shouldFetch,
});
