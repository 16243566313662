import React from 'react';
import { percentFormatter } from 'ns_libs/formatter';
import { Col, Row } from 'reactstrap';
import { IInvestmentMetricsData } from '../../../types';

export const InvestmentMetrics: React.FC<{ data: IInvestmentMetricsData }> = ({ data }) => (
    data && (
        <div className="text-white text-right">
            <Row>
                <Col xs="6">ROI:</Col>
                <Col xs="6" className="text-truncate">
                    {percentFormatter(data.roi)}
                </Col>
            </Row>
            <Row>
                <Col xs="6">EMx:</Col>
                <Col xs="6" className="text-truncate">
                    {data.emx && `${data.emx}X`}
                </Col>
            </Row>
            <Row>
                <Col xs="6">IRR:</Col>
                <Col xs="6" className="text-truncate">
                    {percentFormatter(data.irr)}
                </Col>
            </Row>
        </div>
    )
);

export default InvestmentMetrics;
