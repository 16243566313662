import React, { useMemo, useState } from 'react';
import {
    Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form,
} from 'reactstrap';
import { faAngleRight, faFilter } from '@fortawesome/pro-solid-svg-icons';
import { NSButton, NSMultiSelect } from 'bricks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OptionType } from 'bricks/NSSelectWithCheckboxes/NSSelectWithCheckboxes';
import { FILTER_CATEGORIES, FILTERS_PARAM_KEYS } from './constants';

export interface IReportFilters {
    filterSettings: Partial<Record<FILTERS_PARAM_KEYS, any[]>> | undefined;
    setFilterSettings: (filterSettings: Partial<Record<FILTERS_PARAM_KEYS, any[]>> | undefined) => void;
    reportTypeOptions: OptionType[];
    dealOptions: OptionType[];
}

const ReportFilters = ({
    filterSettings, setFilterSettings, reportTypeOptions, dealOptions,
}: IReportFilters) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedCategoryOption, setSelectedCategoryOption] = useState<{
        label: string;
        value: FILTERS_PARAM_KEYS;
    } | null>();
    const [selectedFilterOptions, setSelectedFilterOptions] = useState<any>(filterSettings);

    const options = useMemo(() => {
        if (selectedCategoryOption?.value === FILTERS_PARAM_KEYS.reportType) {
            return reportTypeOptions;
        }
        if (selectedCategoryOption?.value === FILTERS_PARAM_KEYS.deals) {
            return dealOptions;
        }
        return [];
    }, [selectedCategoryOption, reportTypeOptions, dealOptions]);

    const toggle = () => {
        setIsOpen(!isOpen);
        setSelectedCategoryOption(null);
    };

    const handleClickCategory = (category: { label: string; value: FILTERS_PARAM_KEYS }) => {
        setSelectedCategoryOption(category);
        const selectedOptions = filterSettings ? filterSettings[category.value] : [];
        setSelectedFilterOptions({ ...selectedFilterOptions, [category.value]: selectedOptions });
    };

    const handleFilterSelectionChange = (values: any[], category: FILTERS_PARAM_KEYS) => {
        setSelectedFilterOptions({ ...selectedFilterOptions, [category]: values });
    };

    const handleApply = () => {
        setFilterSettings({ ...filterSettings, ...selectedFilterOptions });
        setSelectedCategoryOption(null);
    };

    return (
        <Dropdown toggle={toggle} isOpen={isOpen}>
            <DropdownToggle tag="div" data-testid="filters-dropdown-toggle">
                <NSButton
                    dataTestId="filter-button"
                    className="NSButton"
                    icon={faFilter}
                    text="Filters"
                    color="secondary"
                    outline={false}
                    callback={toggle}
                />
            </DropdownToggle>
            {!selectedCategoryOption && (
                <DropdownMenu className="text-white">
                    {FILTER_CATEGORIES.map(category => (
                        <DropdownItem
                            key={category.value}
                            className="px-2 py-1 text-white d-flex justify-content-between align-items-center"
                            toggle={false}
                            onClick={() => handleClickCategory(category)}
                        >
                            <p className="mb-0 mr-4">{category.label}</p>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            )}
            {selectedCategoryOption && (
                <DropdownMenu className="text-white" style={{ minWidth: '300px' }}>
                    <Form className="px-3 pb-2">
                        <NSMultiSelect
                            options={options}
                            value={selectedFilterOptions![selectedCategoryOption.value]!}
                            selectName="filterOptions"
                            onChange={values => handleFilterSelectionChange(values, selectedCategoryOption.value)}
                            label={selectedCategoryOption.label}
                            withSelectAllOption
                        />
                        <div className="mt-3 text-right">
                            <NSButton text="Cancel" outline={false} callback={toggle} />
                            <NSButton text="Apply" color="primary" outline={false} callback={handleApply} className="ml-1" />
                        </div>
                    </Form>
                </DropdownMenu>
            )}
        </Dropdown>
    );
};

export default ReportFilters;
