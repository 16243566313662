import { Slide, toast, ToastContainer } from 'react-toastify';
import './useToast.scss';
import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle, faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const useToast = () => {
    const handleClose = useCallback(
        ({ closeToast }: { closeToast: Function }) => () => closeToast({}),
        [],
    );

    const showToast = (message: string | JSX.Element, type: 'success' | 'warning' | 'error') => {
        const toastConfig = {
            position: 'bottom-right' as const,
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            transition: Slide,
            icon: ((props: any) => {
                const iconMap: Record<'success' | 'warning' | 'error', any> = {
                    success: <FontAwesomeIcon className="ToastContainer__icon--success my-auto" size="lg" icon={faCheckCircle} />,
                    warning: <FontAwesomeIcon className="ToastContainer__icon--warning my-auto" size="lg" icon={faExclamationTriangle} />,
                    error: <FontAwesomeIcon className="ToastContainer__icon--danger my-auto" size="lg" icon={faExclamationCircle} />,
                };
                return iconMap[type];
            }) as any, // Explicitly typing the icon as ToastIcon
            className: `ToastContainer ToastContainer--${type}`,
            closeButton: (props: any) => (
                <FontAwesomeIcon className="ToastContainer__close-button my-auto" icon={faXmark} size="lg" onClick={handleClose(props)} />
            ),
        };

        toast[type](<div className="my-auto">{message}</div>, toastConfig);
    };

    return {
        showSuccess: (message: string) => showToast(message, 'success'),
        showWarning: (message: string) => showToast(message, 'warning'),
        showError: (message: string) => showToast(message, 'error'),
        ToastContainerElement: React.useMemo(() => <ToastContainer />, []),
    };
};

export default useToast;
