import { NSTable } from 'bricks';
import RenderIf from 'components/RenderIf/RenderIf';
import { isEmpty } from 'lodash';
import { numeralFormatterCurrency, percentFormatter } from 'ns_libs/formatter';
import React, { Fragment } from 'react';
import { IProjectSourcesActuals } from 'views/ProFormaTable/types';

export interface IProjectSourcesActualsTableProps {
    projectSourcesActuals: IProjectSourcesActuals;
}

const ProjectSourcesActualsTable = ({ projectSourcesActuals }: IProjectSourcesActualsTableProps) => (
    <div className="d-flex m-3">
        <NSTable>
            <thead className="NSTable__thead">
                <tr className="NSTable__thead__tr text-dark">
                    <th className="NSTable__thead__tr__th align-middle">
                        Source name
                    </th>
                    <th className="NSTable__thead__tr__th align-middle">
                        Funding traunch amount
                    </th>
                    <th className="NSTable__thead__tr__th align-middle text-right">
                        Total souce amount
                    </th>
                    <th className="NSTable__thead__tr__th align-middle text-right">
                        Pro rata source share of traunch
                    </th>
                </tr>
            </thead>

            <tbody className="NSTable__tbody">
                <RenderIf isTrue={!isEmpty(projectSourcesActuals.sources)}>
                    {Object.entries(projectSourcesActuals.sources).map(([traunch, sources]) => (
                        <Fragment key={traunch}>
                            {sources.sources.map(source => (
                                <tr key={source.name} className="NSTable__tbody__tr text-dark">
                                    <td className="NSTable__tbody__tr__td">
                                        <div className="NSTable__tbody__tr__td__content">
                                            {source.name}
                                        </div>
                                    </td>
                                    <td className="NSTable__tbody__tr__td">
                                        <div className="NSTable__tbody__tr__td__content">
                                            {traunch}
                                        </div>
                                    </td>
                                    <td className="NSTable__tbody__tr__td">
                                        <div className="NSTable__tbody__tr__td__content text-right">
                                            {numeralFormatterCurrency(source.amount || 0)}
                                        </div>
                                    </td>
                                    <td className="NSTable__tbody__tr__td">
                                        <div className="NSTable__tbody__tr__td__content text-right">
                                            {percentFormatter(Number(source.proRataShareOfTraunch) * 100)}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            <tr className="NSTable__thead__tr text-dark font-weight-bold">
                                <td className="NSTable__tbody__tr__td">
                                    <div className="NSTable__tbody__tr__td__content">
                                        {`Subtotal [Funding traunch ${traunch}]`}
                                    </div>
                                </td>
                                <td className="NSTable__tbody__tr__td" />
                                <td className="NSTable__tbody__tr__td">
                                    <div className="NSTable__tbody__tr__td__content text-right">
                                        {numeralFormatterCurrency(sources.traunchSubtotal || 0)}
                                    </div>
                                </td>
                                <td className="NSTable__tbody__tr__td" />
                            </tr>
                        </Fragment>
                    ))}
                </RenderIf>

                <RenderIf isTrue={isEmpty(projectSourcesActuals.sources)}>
                    <tr className="NSTable__tbody__tr text-dark">
                        <td className="NSTable__tbody__tr__td align-middle" colSpan={6}>
                            <div className="text-center text-dark my-5">No existing project sources.</div>
                        </td>
                    </tr>
                </RenderIf>
            </tbody>

            <tfoot className="NSTable__tfoot">
                <tr className="NSTable__tfoot__tr font-weight-bold">
                    <td className="NSTable__tfoot__tr__td text-left">Grand total</td>
                    <td className="NSTable__tfoot__tr__td" />
                    <td className="NSTable__tfoot__tr__td text-right">
                        {!isEmpty(projectSourcesActuals.sources)
                            ? numeralFormatterCurrency(projectSourcesActuals.totalSourcesAmount || 0)
                            : null}
                    </td>
                    <td className="NSTable__tfoot__tr__td" />
                </tr>
            </tfoot>
        </NSTable>
    </div>
);

export default ProjectSourcesActualsTable;
