import React from 'react';
import classNames from 'classnames';
import { NSCard, NSNavTabs } from 'bricks';
import RenderIf from 'components/RenderIf/RenderIf';
import { PRO_FORMA_TABS, PRO_FORMA_TABLE_NAV } from 'views/ProFormaTable/constants';
import ProFormaDevelopment from './ProFormaDevelopment/ProFormaDevelopment';
import ProFormaGeneralInformation from './ProFormaGeneralInformation/ProFormaGeneralInformation';
import ProFormaOperations from './ProFormaOperations/ProFormaOperations';
import ProFormaDisposition from './ProFormaDisposition/ProFormaDisposition';

export interface IProFormaProps {
    navigateToSection: (sideNavName: string, tabName?: string) => void;
    currentSection?: string;
    currentTab?: string;
    hasWriteAccessToProForma: boolean;
}

const ProForma = ({
    navigateToSection,
    currentSection,
    currentTab,
    hasWriteAccessToProForma,
}: IProFormaProps) => (
    <>
        <NSNavTabs
            containerClassName="px-0"
            navItems={Object.entries(PRO_FORMA_TABS).map(([key, { prefix, name }], index) => ({
                callback: e => {
                    e.preventDefault();
                    navigateToSection(currentSection || PRO_FORMA_TABLE_NAV.PRO_FORMA.name, name);
                },
                navPrefix: prefix,
                className: classNames('text-nowrap', {
                    'mr-1': index !== Object.keys(PRO_FORMA_TABS).length - 1,
                }),
                name,
                isActive: currentTab === name,
                id: `proforma-tab-${index}`,
            }))}
        />
        <hr className="my-0" />
        <NSCard className="p-3">
            <RenderIf isTrue={currentTab === PRO_FORMA_TABS.GENERAL_INFORMATION.name}>
                <ProFormaGeneralInformation hasWriteAccessToProForma={hasWriteAccessToProForma} />
            </RenderIf>
            <RenderIf isTrue={currentTab === PRO_FORMA_TABS.DEVELOPMENT.name}>
                <ProFormaDevelopment navigateToSection={navigateToSection} hasWriteAccessToProForma={hasWriteAccessToProForma} />
            </RenderIf>
            <RenderIf isTrue={currentTab === PRO_FORMA_TABS.OPERATIONS.name}>
                <ProFormaOperations hasWriteAccessToProForma={hasWriteAccessToProForma} />
            </RenderIf>
            <RenderIf isTrue={currentTab === PRO_FORMA_TABS.DISPOSITION.name}>
                <ProFormaDisposition hasWriteAccessToProForma={hasWriteAccessToProForma} />
            </RenderIf>
        </NSCard>
    </>
);

export default ProForma;
