import { formatUS, formatUSTwoDigits } from 'ns_libs/formatter';
import { isNaN } from 'lodash';

type LoanTerm = {
    loanTermMonths?: number;
    loanTermTime?: 'monthly' | 'yearly';
};

export function formatPercentageComparison(value: string | number | undefined): string {
    if (value === undefined || value === '' || value === null) {
        return '-';
    }

    const numericValue = typeof value === 'string' ? parseFloat(value) : value;

    if (Number.isNaN(numericValue)) {
        return '-';
    }

    const decimalPlaces = numericValue % 1 === 0 ? 0 : 1;
    return `${numericValue.toFixed(decimalPlaces)}%`;
}

export const formatCurrencyComparison = (value: number): string => {
    if (value === undefined || value === null || isNaN(value)) {
        return '$0';
    }

    const formatted = (value % 1 === 0 ? formatUS : formatUSTwoDigits).format(value);

    return formatted === '-$0' ? '$0' : formatted;
};

export function formatLoanTerm({ loanTermMonths, loanTermTime }: LoanTerm): string {
    if (!loanTermMonths) return '-';

    if (loanTermTime === 'yearly') {
        return `${loanTermMonths} years`;
    }

    return `${loanTermMonths} months`;
}
