import React from 'react';
import { DocRenderer } from 'react-doc-viewer';
import { pdfjs, Document, Page } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.8.69/pdf.worker.min.mjs';

const PDFRenderer: DocRenderer = ({ mainState }) => {
    const rendererRect = mainState?.rendererRect || null;

    return (
        <Document file={mainState.currentDocument?.fileData} loading={<span>Loading...</span>} data-testid="pdf-renderer">
            <Page
                pageNumber={1}
                scale={2}
                height={(rendererRect?.height || 100) - 100}
                width={(rendererRect?.width || 100) - 100}
            />
        </Document>
    );
};

PDFRenderer.fileTypes = ['pdf', 'application/pdf'];
PDFRenderer.weight = 1;

export default PDFRenderer;
