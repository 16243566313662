import React, {
    createContext, useContext, useState, useRef,
} from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { URLS } from 'services/urls';

interface StepHandler {
    validate: () => boolean | Promise<boolean>;
    getData: () => Promise<any>;
}

interface ProFormaWizardContextType {
    currentStep: number;
    stepHandlers: Record<number, StepHandler>;
    stepData: Record<number, any>;
    registerStepHandler: (step: number, handler: StepHandler) => void;
    handleNextStep: () => void;
    handlePrevStep: () => void;
    getStepData: (step: number) => any;
    setStepData: (step: number, data: any) => void;
    isCurrentStepValid: boolean;
    setStepValidationStatus: (isValid: boolean) => void;
    isLoading: boolean;
}

interface ProFormaWizardProviderProps {
    children: React.ReactNode;
}

const ProFormaWizardContext = createContext<ProFormaWizardContextType | null>(null);

export const ProFormaWizardProvider: React.FC<ProFormaWizardProviderProps> = ({ children }) => {
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [stepData, setStepDataState] = useState<Record<number, any>>({});
    const [isCurrentStepValid, setIsCurrentStepValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const stepHandlers = useRef<Record<number, StepHandler>>({});
    const navigate = useNavigate();
    const { dealId, proFormaId } = useParams<{ dealId: string; proFormaId: string }>();

    const totalSteps = 3;

    const setStepData = (step: number, data: any) => {
        setStepDataState(prev => ({ ...prev, [step]: data }));
    };

    const getStepData = (step: number) => stepData[step] || null;

    const registerStepHandler = (step: number, handler: StepHandler) => {
        stepHandlers.current[step] = handler;
    };

    const handleNextStep = async () => {
        const handler = stepHandlers.current[currentStep];

        // Validate current step
        if (handler?.validate && !(await handler.validate())) {
            console.error(`Validation failed for step ${currentStep}`);
            return;
        }

        try {
            setIsLoading(true);
            if (handler?.getData) {
                await handler.getData();
            }

            if (currentStep === totalSteps) {
                const path = generatePath(URLS.PRO_FORMA.TABLE, {
                    dealId: dealId || '',
                    proFormaId: proFormaId || '',
                });
                navigate(path);
            } else {
                setCurrentStep(prev => prev + 1);
            }
        } catch (error) {
            console.error(`Error processing step ${currentStep}:`, error);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePrevStep = () => {
        if (currentStep === 1) {
            navigate('/'); // Replace `/` with the desired exit route
        } else {
            setCurrentStep(prev => prev - 1);
        }
    };

    const setStepValidationStatus = (isValid: boolean) => {
        setIsCurrentStepValid(isValid);
    };

    return (
        <ProFormaWizardContext.Provider
            value={{
                currentStep,
                stepHandlers: stepHandlers.current,
                stepData,
                registerStepHandler,
                handleNextStep,
                handlePrevStep,
                setStepData,
                getStepData,
                isCurrentStepValid,
                setStepValidationStatus,
                isLoading,
            }}
        >
            {children}
        </ProFormaWizardContext.Provider>
    );
};

export const useProFormaWizard = (): ProFormaWizardContextType => {
    const context = useContext(ProFormaWizardContext);
    if (!context) {
        throw new Error('useProFormaWizard must be used within a ProFormaWizardProvider');
    }
    return context;
};
