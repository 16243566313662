/* eslint-disable operator-linebreak */
import { useMemo, useCallback } from 'react';
import { BOESlideoutFormValues } from './types';
import { calculateBOEValues, calculateIterativeValues } from './helpers';

export const useBOECalculations = (values: BOESlideoutFormValues) => {
    const calculations = useMemo(() => {
        const {
            netRentable,
            sfPerUnit,
            grossPotentialRent,
            expenseTotal,
            netOperatingIncome,
            exitValuation,
            calculateLoanAmount,
            hardCostsTotal,
            softCostsTotal,
        } = calculateBOEValues(values);

        const {
            developerFeeTotal, interestReserveTotal, loanAmount, equityCost, returnOnCost, returnOnEquity, profitReturn, totalDevelopmentCosts,
        } =
            calculateIterativeValues(values, hardCostsTotal, softCostsTotal, netOperatingIncome, exitValuation, calculateLoanAmount);

        return {
            netRentable,
            sfPerUnit,
            grossPotentialRent,
            expenseTotal,
            netOperatingIncome,
            exitValuation,
            calculateLoanAmount,
            hardCostsTotal,
            softCostsTotal,
            developerFeeTotal,
            interestReserveTotal,
            loanAmount,
            equityCost,
            returnOnCost,
            returnOnEquity,
            profitReturn,
            totalDevelopmentCosts,
        };
    }, [values]);

    return calculations;
};

export const useNumberFormatter = () => {
    const formatNumber = useCallback((value: number | null | undefined, placeholder: string = 'TBD'): string => {
        if (value === 0 || value === null || value === undefined || Number.isNaN(value)) {
            return placeholder;
        }
        return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(value);
    }, []);

    return { formatNumber };
};
