import React from 'react';
import './ExecutiveSummaryCard.scss';
import { ISummaryItem } from './types';

const SummaryItem = ({ label, value, isNegative }: Omit<ISummaryItem, 'id'>) => (
    <div className="d-flex justify-content-between mt-1">
        <div className="text-muted">{label}</div>
        <div className={isNegative ? 'text-muted' : 'text-dark'}>{isNegative ? `(${value})` : value}</div>
    </div>
);

export default SummaryItem;
