import { faShapes, faSliders } from '@fortawesome/free-solid-svg-icons';

export const CREATE_PRO_FORMA_STEPS = {
    STEP_1: 1,
    STEP_2: 2,
};

export const CREATE_PRO_FORMA_STEP2_CARDS = [
    {
        name: 'Quick start',
        time: '3-5 MINUTES',
        description: 'I don\'t have all of the development and operating detail that I eventually will. I prefer to be guided.',
        image: 'pro_forma_quick_start_frame.png',
        icon: faShapes,
    },
    {
        name: 'Start with detail',
        time: '10-15 MINUTES',
        description: 'I have detailed information about uses, sources, and operations. I want to visualize cash flows right away.',
        image: 'pro_forma_start_with_detail_frame.png',
        icon: faSliders,
    },
];

export const MOCK_SCENARIO_OPTIONS = [
    {
        value: 1,
        label: 'Scenario 1',
    },
    {
        value: 2,
        label: 'Scenario 2',
    },
];
