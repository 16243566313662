import React, { useState, useEffect } from 'react';
import {
    NSLabel, NSSelect, NSInputNumberStepper,
} from 'bricks';
import { IOptionAnyValue } from 'bricks/types';
import {
    FORM_ROW_CLASS_NAME,
    FORM_LABEL_CLASS_NAME,
    proFormaModelParkingOptions,
    STRUCTURED_PARKING,
    SURFACE_PARKING,
    parkingOptions,
    CAR_PARKING,
    TRAILER_PARKING,
    industrialParkingOptions,
} from 'views/ProFormaTable/constants';
import RenderIf from 'components/RenderIf/RenderIf';
import { IProFormaValues } from 'views/ProFormaTable/types';
import { useProFormaSocketContext } from '../../../socketContext/ProFormaSocketProvider';

export interface IProFormaSizeParkingProps {
    values: IProFormaValues;
}

const ProFormaSizeParking = ({
    values,
}: IProFormaSizeParkingProps) => {
    const { handleUpdateProForma, proForma } = useProFormaSocketContext();

    const [selectedParkingOptions, setSelectedParkingOptions] = useState<IOptionAnyValue[]>([]);
    const updateProFormaParkingTypes = (parkingTypes: IOptionAnyValue[], event: any) => {
        const isStructuredParkingSelected = parkingTypes.find(type => type.value === STRUCTURED_PARKING);
        const isSurfaceParkingSelected = parkingTypes.find(type => type.value === SURFACE_PARKING);
        const isCarParkingSelected = parkingTypes.find(type => type.value === CAR_PARKING);
        const isTrailerParkingSelected = parkingTypes.find(type => type.value === TRAILER_PARKING);
        const dataToUpdate = [];

        // Removing parking types
        if (!isStructuredParkingSelected) dataToUpdate.push({ key: 'structuredParkingSpaces', value: null });
        if (!isSurfaceParkingSelected) dataToUpdate.push({ key: 'surfaceParkingSpaces', value: null });
        if (!isCarParkingSelected) dataToUpdate.push({ key: 'carParkingSpaces', value: null });
        if (!isTrailerParkingSelected) dataToUpdate.push({ key: 'trailerParkingSpaces', value: null });

        // Adding selected parking types
        if (isStructuredParkingSelected && !values.structuredParkingSpaces) dataToUpdate.push({ key: 'structuredParkingSpaces', value: 0 });
        if (isSurfaceParkingSelected && !values.surfaceParkingSpaces) dataToUpdate.push({ key: 'surfaceParkingSpaces', value: 0 });
        if (isCarParkingSelected && !values.carParkingSpaces) dataToUpdate.push({ key: 'carParkingSpaces', value: 0 });
        if (isTrailerParkingSelected && !values.trailerParkingSpaces) dataToUpdate.push({ key: 'trailerParkingSpaces', value: 0 });

        handleUpdateProForma(dataToUpdate);
    };

    useEffect(() => {
        const initialSelectedParkingOptions = [];
        if (values.structuredParkingSpaces != null) {
            initialSelectedParkingOptions.push(parkingOptions[0]);
        }
        if (values.surfaceParkingSpaces != null) {
            initialSelectedParkingOptions.push(parkingOptions[1]);
        }
        if (values.carParkingSpaces != null) {
            initialSelectedParkingOptions.push(industrialParkingOptions[0]);
        }
        if (values.trailerParkingSpaces != null) {
            initialSelectedParkingOptions.push(industrialParkingOptions[1]);
        }
        setSelectedParkingOptions(initialSelectedParkingOptions);
    }, [values.structuredParkingSpaces, values.surfaceParkingSpaces, values.carParkingSpaces, values.trailerParkingSpaces]);

    return (
        <>
            <div className={FORM_ROW_CLASS_NAME}>
                <NSLabel className={FORM_LABEL_CLASS_NAME}>Parking</NSLabel>
                <div className="w-100">
                    <NSSelect
                        isMulti
                        name="parking"
                        options={proFormaModelParkingOptions[proForma.proFormaModelId]}
                        value={selectedParkingOptions}
                        onChange={options => setSelectedParkingOptions(options || [])}
                        onBlur={event => updateProFormaParkingTypes(selectedParkingOptions, event)}
                    />
                </div>
            </div>

            <RenderIf isTrue={selectedParkingOptions.find(parkingOption => parkingOption.value === STRUCTURED_PARKING)}>
                <div className={FORM_ROW_CLASS_NAME}>
                    <NSLabel className={FORM_LABEL_CLASS_NAME}>Structured parking spaces</NSLabel>
                    <NSInputNumberStepper
                        name="structuredParkingSpaces"
                        value={values.structuredParkingSpaces && values.structuredParkingSpaces}
                        onChange={(count: number) => handleUpdateProForma([{ key: 'structuredParkingSpaces', value: count }])}
                        min={0.1}
                        inputGroupClassName="w-25"
                        step={1}
                    />
                </div>
            </RenderIf>

            <RenderIf isTrue={selectedParkingOptions.find(parkingOption => parkingOption.value === SURFACE_PARKING)}>
                <div className={FORM_ROW_CLASS_NAME}>
                    <NSLabel className={FORM_LABEL_CLASS_NAME}>Surface parking spaces</NSLabel>
                    <NSInputNumberStepper
                        name="surfaceParkingSpaces"
                        value={values.surfaceParkingSpaces}
                        onChange={(count: number) => handleUpdateProForma([{ key: 'surfaceParkingSpaces', value: count }])}
                        min={1}
                        inputGroupClassName="w-25"
                        step={1}
                    />
                </div>
            </RenderIf>

            <RenderIf isTrue={selectedParkingOptions.find(parkingOption => parkingOption.value === CAR_PARKING)}>
                <div className={FORM_ROW_CLASS_NAME}>
                    <NSLabel className={FORM_LABEL_CLASS_NAME}>Car parking spaces</NSLabel>
                    <NSInputNumberStepper
                        name="carParkingSpaces"
                        value={values.carParkingSpaces}
                        onChange={(count: number) => handleUpdateProForma([{ key: 'carParkingSpaces', value: count }])}
                        min={0.1}
                        inputGroupClassName="w-25"
                        step={1}
                    />
                </div>
            </RenderIf>

            <RenderIf isTrue={selectedParkingOptions.find(parkingOption => parkingOption.value === TRAILER_PARKING)}>
                <div className={FORM_ROW_CLASS_NAME}>
                    <NSLabel className={FORM_LABEL_CLASS_NAME}>Trailer parking spaces</NSLabel>
                    <NSInputNumberStepper
                        name="trailerParkingSpaces"
                        value={values.trailerParkingSpaces}
                        onChange={(count: number) => handleUpdateProForma([{ key: 'trailerParkingSpaces', value: count }])}
                        min={0.1}
                        inputGroupClassName="w-25"
                        step={1}
                    />
                </div>
            </RenderIf>
        </>
    );
};

export default ProFormaSizeParking;
