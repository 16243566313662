import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { faChartLineUp, faChartMixedUpCircleDollar, faGrid2 } from '@fortawesome/pro-solid-svg-icons';
import { ProFormaModelIds } from 'constants/proForma';
import { IOptionAnyValue } from 'bricks/types';
import { Periods } from 'components/ProFormaReturnsTable/constant';
import { IProjectionType, ProjectionType } from './types';

export const PRO_FORMA_TABLE_NAV = {
    PRO_FORMA: { name: 'Pro Forma', icon: faChartLineUp },
    RETURNS_AND_WATERFALL: { name: 'Returns & Waterfall Analysis', icon: faChartBar },
    SENSITIVITY: { name: 'Sensitivity', icon: faChartMixedUpCircleDollar },
    EXECUTIVE_SUMMARY: { name: 'Executive Summary', icon: faGrid2 },
};
export const PRO_FORMA_TABLE_NAV_NAMES = Object.values(PRO_FORMA_TABLE_NAV).map(nav => nav.name);

export const PRO_FORMA_TABS = {
    GENERAL_INFORMATION: { prefix: 'A', name: 'General Information' },
    DEVELOPMENT: { prefix: 'B', name: 'Development' },
    OPERATIONS: { prefix: 'C', name: 'Operations' },
    DISPOSITION: { prefix: 'D', name: 'Disposition' },
};
export const PRO_FORMA_TAB_NAMES = Object.values(PRO_FORMA_TABS).map(tab => tab.name);

export const RETURNS_AND_WATERFALL_TABS = {
    RETURNS: 'Returns',
    WATERFALL_ANALYSIS: 'Waterfall Analysis',
};
export const RETURNS_AND_WATERFALL_TAB_NAMES = Object.values(RETURNS_AND_WATERFALL_TABS);

export const FORM_ROW_CLASS_NAME = 'd-flex mb-2';
export const FORM_LABEL_CLASS_NAME = 'pl-0 col-4 my-auto font-weight-normal text-dark text-nowrap';
export const CALCULATE_SIZE_HEADER_CLASS_NAME = 'p-0 pb-1 text-dark font-weight-normal';

export const GENERAL_INFORMATION_EXPANDABLE_SECTIONS = [
    {
        number: 1,
        title: 'Basic Details',
        subtitle: 'Information about this investment\'s name and location',
    },
    {
        number: 2,
        title: 'Key Dates',
        subtitle: `Determine start date for this analysis. Typically this is your acquisition closing.
                    This date will be used as period 1 for cash flows.`,
    },
    {
        number: 3,
        title: 'Size',
        subtitle: 'Details about this investment\'s property size and building',
    },
];

export const STRUCTURED_PARKING = 'structured';
export const SURFACE_PARKING = 'surface';
export const CAR_PARKING = 'car';
export const TRAILER_PARKING = 'trailer';

export const parkingOptions = [
    { label: 'Structured', value: STRUCTURED_PARKING },
    { label: 'Surface', value: SURFACE_PARKING },
];

export const industrialParkingOptions = [
    { label: 'Car', value: CAR_PARKING },
    { label: 'Trailer', value: TRAILER_PARKING },
];

export const proFormaModelParkingOptions: Record<string, IOptionAnyValue[]> = {
    [ProFormaModelIds.APARTMENT]: parkingOptions,
    [ProFormaModelIds.INDUSTRIAL]: industrialParkingOptions,
    [ProFormaModelIds.RETAIL]: parkingOptions,
    [ProFormaModelIds.OFFICE]: parkingOptions,
};

export const proFormaModelsWithFarToggle = new Set([
    ProFormaModelIds.APARTMENT,
    ProFormaModelIds.OFFICE,
]);

export const POPULATE_USES_METHOD = {
    IMPORT_FROM_PREVIOUS_PROJECT: {
        title: 'Import from a previous deal or project',
        subtitle: 'Start with budget categorizations, estimates, and curve fits used previously.',
    },
    START_WITH_BACK_OF_ENVELOPE: {
        title: 'Start with my Back-Of-Envelope estimates',
        subtitle: 'Start with high level assumptions established using Back-Of-Envelope.',
    },
    USE_RECOMMENDED_CATEGORIES: {
        title: 'Use recommended categories',
        subtitle: 'Start with our recommended budget line types for this project type.',
    },
    START_FROM_SCRATCH: {
        title: 'Start from scratch',
    },
};

export const PROJECTION_TYPE: { [key: string]: ProjectionType } = {
    LINEAR: 'linear',
    NORMAL: 'normal',
    GAMMA: 'gamma',
};

export const PROJECTION_TYPES: IProjectionType[] = [
    {
        projectionType: PROJECTION_TYPE.LINEAR,
        projectionVariable: null,
        curveType: 'Linear',
        img: 'linear_projection.png',
        imgPrimary: 'linear_projection_primary.png',
    },
    {
        projectionType: PROJECTION_TYPE.NORMAL,
        projectionVariable: 7,
        curveType: 'Wide',
        img: 'normal_wide_projection.png',
        imgPrimary: 'normal_wide_projection_primary.png',
    },
    {
        projectionType: PROJECTION_TYPE.NORMAL,
        projectionVariable: 5,
        curveType: 'Medium',
        img: 'normal_medium_projection.png',
        imgPrimary: 'normal_medium_projection_primary.png',
    },
    {
        projectionType: PROJECTION_TYPE.NORMAL,
        projectionVariable: 3,
        curveType: 'Narrow',
        img: 'normal_narrow_projection.png',
        imgPrimary: 'normal_narrow_projection_primary.png',
    },
    {
        projectionType: PROJECTION_TYPE.GAMMA,
        projectionVariable: -1,
        curveType: 'L Skew',
        img: 'gamma_leftskew_projection.png',
        imgPrimary: 'gamma_leftskew_projection_primary.png',
    },
    {
        projectionType: PROJECTION_TYPE.GAMMA,
        projectionVariable: 1,
        curveType: 'R Skew',
        img: 'gamma_rightskew_projection.png',
        imgPrimary: 'gamma_rightskew_projection_primary.png',
    },
];

export const PRO_FORMA_DEVELOPMENT_UNIT_TYPE_KEY = 'proFormaDevelopmentUnitType';
export const PRO_FORMA_DEVELOPMENT_DEBT_METRIC_KEY = 'proFormaDevelopmentDebtMetric';

export const BULK_OPTION = {
    START_DATE: 'start_date',
    END_DATE: 'end_date',
    CURVE: 'curve',
    AMOUNT: 'amount',
};

export const BULK_ASSIGN_OPTIONS = [
    { label: 'Start date', value: BULK_OPTION.START_DATE },
    { label: 'End date', value: BULK_OPTION.END_DATE },
    { label: 'Curve', value: BULK_OPTION.CURVE },
    { label: 'Amount', value: BULK_OPTION.AMOUNT },
];

export const DEFAULT_SUBCATEGORY_NAME = 'New subcategory';
export const DEFAULT_BUDGET_LINE_NAME = 'New budget line';
export const IN_PROGRESS_TEXT = 'In progress';

// TO DO: Clean up mock data in https://app.clickup.com/2326533/v/l/6-181053511-1
export const mockProFormaUnits = 100; // TODO: fetch from C.2
export const MockRentableSquareFootage = 50000;

export const LOAN_RATE_TYPE = {
    FIXED: 'fixed',
    VARIABLE: 'variable',
};

export const PRO_FORMA_DEVELOPMENT_DEBT_RATE_OPTIONS = [
    { label: 'Fixed', value: LOAN_RATE_TYPE.FIXED },
    { label: 'Variable', value: LOAN_RATE_TYPE.VARIABLE },
];

export const DEBT_PRIVATE_UUID = '12c7af91-3f6f-423a-946a-e1c4255873ef';
export const DEBT_PUBLIC_LOCAL_UUID = '19e51005-d66b-4f4d-a589-0fcb7bfdbfb8';
export const DEBT_PUBLIC_NATIONAL_UUID = 'd2c0b5b3-d593-4d44-a30b-8aa3848b0603';
export const DEBT_PUBLIC_STATE_UUID = '80483a35-45ec-4a9a-bc38-dd3e3de59cda';
export const BONDS_SOURCE_TYPE_UUID = '4f42d654-c206-4c23-a247-33a66fe1ab85';

export const DEBT_SOURCE_TYPES = new Set([DEBT_PRIVATE_UUID, DEBT_PUBLIC_LOCAL_UUID, DEBT_PUBLIC_NATIONAL_UUID, DEBT_PUBLIC_STATE_UUID]);

export const currencyColumnKeys = ['pricePerUnit', 'pricePerSf', 'amountPerYear'];
export const stringColumnKeys = ['name', 'title'];
export const percentColumnKeys = ['annualGrowth', 'percentOfTotalRent'];

// Pro forma local storage keys corresponding to IProFormaLocalStorage
export const SELECTED_SCENARIO_KEY = 'selectedScenario';
export const SHOW_PROJECT_ACTUALS_KEY = 'showProjectActuals';
export const SELECTED_PERIOD_CADENCE_KEY = 'selectedPeriodCadence';
export const USES_EXPANDED_CATEGORIES = 'usesExpandedCategories';
export const HIDE_WARNINGS_KEY = 'hideWarnings';
export const SHOW_CENTS_KEY = 'showCents';
export const FORECAST_METRIC_KEY = 'forecastMetric';

export const DISABLED_PLACEHOLDER_PERIOD = 'placeholderPeriod';

export const CADENCE_UI_TEXT: {[key: string]: string} = {
    [Periods.MONTHLY]: 'Month',
    [Periods.QUARTERLY]: 'Quarter',
    [Periods.ANNUALLY]: 'Year',
};

// Operating income handlers
export const OPERATIONS_INCOME_WS_HANDLERS = {
    UNIT_MIX: {
        CREATE: 'create_operating_income_unit_mix',
        UPDATE: 'update_operating_income_unit_mix',
        DELETE: 'delete_operating_income_unit_mix',
    },
    GROSS_RENT_ADJUSTMENT: {
        UPDATE: 'update_operating_gross_rent_adjustment',
    },
    OTHER_INCOME: {
        CREATE: 'create_operating_income_other_income_line_item',
        UPDATE: 'update_operating_income_other_income_line_item',
        DELETE: 'delete_operating_income_other_income_line_item',
    },
    INCOME_ADJUSTMENT: {
        CREATE: '',
        UPDATE: '',
        DELETE: '',
    },
};

export const OPERATIONS_EXPENSES_WS_HANDLERS = {
    PROPERTY_TAXES: {
        CREATE: 'create_annual_tax_item',
        UPDATE: 'update_annual_tax_item_amount',
        DELETE: 'delete_annual_tax_item',
    },
    OPEX: {
        CREATE: 'create_operating_expense_line_item',
        UPDATE: 'update_operating_expense_line_item',
        DELETE: 'delete_operating_expense_line_item',
    },
    CAPEX: {
        CREATE: '',
        UPDATE: '',
        DELETE: '',
    },
};

export const DEFUALT_ROW_NAME_BY_HANDLER = {
    [OPERATIONS_INCOME_WS_HANDLERS.UNIT_MIX.CREATE]: 'New unit',
    [OPERATIONS_INCOME_WS_HANDLERS.OTHER_INCOME.CREATE]: 'New income',
    [OPERATIONS_INCOME_WS_HANDLERS.INCOME_ADJUSTMENT.CREATE]: 'New adjustment',
    [OPERATIONS_EXPENSES_WS_HANDLERS.OPEX.CREATE]: 'New operating expense',
    [OPERATIONS_EXPENSES_WS_HANDLERS.CAPEX.CREATE]: 'New capital expenditure',
};

export const INCOME_AND_EXPENSE_WS_EVENT_NAMES = [
    'unitMix:created',
    'unitMix:updated',
    'unitMix:deleted',
    'grossRentAdjustment:updated',
    'otherIncome:created',
    'otherIncome:updated',
    'otherIncome:deleted',
    'incomeAdjustment:created',
    'incomeAdjustment:updated',
    'incomeAdjustment:deleted',
    'opEx:updated',
    'capEx:updated',
    'propertyTax:updated',
];

export const TOTAL_ANTICIPATED_COST = 'Total Anticipated Cost';
export const TOTAL_REVISED_BUDGET = 'Total Revised Budget';

export const FORECAST_METRIC_OPTIONS_NON_EARLY_PLANNING = [
    { label: TOTAL_ANTICIPATED_COST, value: TOTAL_ANTICIPATED_COST },
    { label: TOTAL_REVISED_BUDGET, value: TOTAL_REVISED_BUDGET },
];
