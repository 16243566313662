import { config } from 'config';
import { BASE_URL } from './constants';

export const V1 = `${BASE_URL}/api`;

export const URLS = {
    // Cloudfront url for images
    IMAGE_CLOUDFRONT_URL: (name: string) => `https://d24iv9sojvq5iq.cloudfront.net/images/${name}`,
    REPORTS: {
        HOME: '/reports',
        NEW: '/reports/new/:reportType',
        REPORT_PAGE: '/reports/:reportId',
        REPORT_PREVIEW: '/reports/:reportId/preview',
    },
    REPORT_BUILDER: '/deal/:dealId/report_builder',
    DEALS: {
        HOME: '/deals',
        VIEW: '/deals/:dealId',
        FORM: '/deals/form/:dealId?',
        COMPARISON: '/deals/comparison/:dealId?',
    },
    DEAL_DASHBOARD: {
        HOME: '/deal/:dealId',
    },
    PRO_FORMA: {
        HOME: '/deal/:dealId/pro_forma',
        BASE_URL: '/deal/:dealId/pro_forma/:proFormaId',
        WIZARD: '/deal/:dealId/pro_forma/wizard/:proFormaId',
        TABLE: '/deal/:dealId/pro_forma/:proFormaId/table',
        UPLOAD: '/deal/:dealId/pro_forma/upload/:proFormaId?',
        UPLOADED: '/deal/:dealId/pro_forma/uploaded/:proFormaId',
    },
    DOCUMENTS: '/deal/:dealId/documents',

    // Project App URLs
    PROJECTS_APP: {
        APPROVALS: `${config.northspyreProjectsAppUrl}/ns/v2/portfolio_approvals`,
        PROJECTS: `${config.northspyreProjectsAppUrl}/ns/v2/projects`,
        ORGANIZATION_MANAGEMENT: `${config.northspyreProjectsAppUrl}/ns/v2/organizations`,
        USER_SETTINGS: `${config.northspyreProjectsAppUrl}/ns/v2/profile/user`,
        COST_ANALYTICS: `${config.northspyreProjectsAppUrl}/ns/v2/cost_analytics`,
        MARKET_DATA: `${config.northspyreProjectsAppUrl}/ns/v2/deeplook`,
        QUICK_ANALYTICS: `${config.northspyreProjectsAppUrl}/ns/v2/quick_analytics`,
        VENDOR_ANALYTICS: `${config.northspyreProjectsAppUrl}/ns/v2/vendor_analytics`,
        DEVELOPER_FEE: `${config.northspyreProjectsAppUrl}/ns/v2/portfolio_developer_fee`,
        GLOBAL_COST_FORECAST: `${config.northspyreProjectsAppUrl}/ns/v2/global_cost_forecast`,
        PORTFOLIO_CAPITAL_PLANNING: `${config.northspyreProjectsAppUrl}/ns/v2/portfolio_capital_planning`,
        PORTFOLIO_INVOICES: `${config.northspyreProjectsAppUrl}/ns/v2/portfolio_invoice_data_index`,
        PORTFOLIO_BUDGET_CATEGORIZATIONS: `${config.northspyreProjectsAppUrl}/ns/v2/portfolio_budget_categorizations`,
        VENDOR_DIRECTORY: `${config.northspyreProjectsAppUrl}/ns/v2/vendors`,
    },

    // External Links
    SUPPORT_URL: 'https://knowledge.northspyre.com/knowledge',
};

const API_ENDPOINTS = {
    BASE_URL,
    AUTHENTICATED_USER: () => `${V1}/users/me`,
    FEATURE_ACCESS: (organizationId: string) => `${V1}/organizations/${organizationId}/user_auth_data`,
    DEALS: (organizationId: string) => `${V1}/${organizationId}/deals`,
    DEAL_SUMMARIES: (organizationId: string) => `${V1}/${organizationId}/deals/summaries`,
    DEALS_BY_ID: (organizationId: string, dealId: number) => `${V1}/${organizationId}/deals/${dealId}`,
    DEALS_GROUPS: (organizationId: string) => `${V1}/${organizationId}/deal-groups`,
    DEALS_GROUPS_BY_ID: (organizationId: string, dealGroupId: number) => `${V1}/${organizationId}/deal-groups/${dealGroupId}`,
    DEAL_OVERVIEW_PDF: (organizationId: string, dealId: number) => `${V1}/${organizationId}/deals/${dealId}/overview/pdf`,
    DEAL_DIRECTORY: {
        TREE: (dealId: number) => `${V1}/deals/${dealId}/directories/tree`,
        DIRECTORY: (dealId: number, directoryId?: number) => `${V1}/deals/${dealId}/directories/${directoryId || ''}`,
    },
    DOCUMENTS: `${V1}/documents`,
    DOCUMENT_BY_ID: (documentId: number) => `${V1}/documents/${documentId}`,
    ASSOCIATE_DEALS_GROUPS: (organizationId: string) => `${V1}/${organizationId}/deal-groups/associate`,
    ORG_REPORTS: (orgId: string) => `${V1}/${orgId}/reports`,
    PROJECT_SOURCES_ACTUALS: (projectUuid: string) => `${V1}/subproject/${projectUuid}/sources-actuals`,
    REPORTS: {
        REPORT: (reportId: number) => `${V1}/reports/${reportId}`,
        PDF: (reportId: number) => `${V1}/reports/${reportId}/pdf`,
        PREVIEW: (reportId: number) => `${V1}/reports/${reportId}/preview`,
    },
    REPORT_BUILDER: {
        PDF: (dealId: number) => `${V1}/deals/${dealId}/report_builder/pdf`,
    },
    REPORT_BUILDER_TEMPLATE: (dealId: number) => `${V1}/deals/${dealId}/report_builder_templates`,
    TIMELINES: (orgId: string, dealId: number) => `${V1}/${orgId}/deals/${dealId}/timelines`,
    TIMELINE_BY_ID: (orgId: string, dealId: number, timelineId: string) => `${V1}/${orgId}/deals/${dealId}/timelines/${timelineId}`,
    BACK_OF_ENVELOPE: (orgId: string) => `${V1}/${orgId}/back-of-envelope`,
    BACK_OF_ENVELOPE_DEALS: (orgId: string) => `${V1}/${orgId}/back-of-envelope/deals`,
    BACK_OF_ENVELOPE_SCENARIOS: (orgId: string) => `${V1}/${orgId}/back-of-envelope/scenarios`,
    BOE_SCENARIO_COMP_PDF: (orgId: string, dealId: number) => `${V1}/${orgId}/back-of-envelope/${dealId}/comparison/pdf`,
    BOE_SCENARIO_DASHBOARD_PDF: (orgId: string, scenarioId: number) => `${V1}/${orgId}/back-of-envelope/${scenarioId}/dashboard/pdf`,
    BACK_OF_ENVELOPE_BY_ID: (orgId: string, scenarioId: string) => `${V1}/${orgId}/back-of-envelope/${scenarioId}`,
    PRO_FORMA: (dealId: number, proFormaId?: number) => `${V1}/deals/${dealId}/pro_formas${proFormaId ? `/${proFormaId}` : ''}`,
    ORGANIZATION_PRO_FORMA: (organizationId: string, proFormaId: number) => `${V1}/organizations/${organizationId}/pro_formas/${proFormaId}`,
    DUPLICATE_PRO_FORMA: (organizationId: string, proFormaId: number) => `${V1}/organizations/${organizationId}/pro_formas/${proFormaId}/duplicate`,
    PRO_FORMA_SOURCE: (organizationId: string, proFormaId: string) => `${V1}/organization/${organizationId}/pro_forma/${proFormaId}/pro_forma_source`,
    PRO_FORMA_EXECUTIVE_SUMMARY: (dealId: number, proFormaId: number) => `${V1}/deals/${dealId}/pro_formas/${proFormaId}/executive_summary`,
    PRO_FORMA_EXECUTIVE_SUMMARY_PDF: (dealId: number, proFormaId: number) => `${V1}/deals/${dealId}/pro_formas/${proFormaId}/executive_summary/pdf`,
    PRO_FORMA_BUILDABLE_LOT: (
        dealId: number, proFormaId: number, lotId?: number,
    ) => `${V1}/deals/${dealId}/pro_forma/${proFormaId}/gross_buildable_area_lot${lotId ? `/${lotId}` : ''}`,
    PRO_FORMA_SENSITIVITY_ANALYSIS: (dealId: number, proFormaId: number) => `${V1}/deals/${dealId}/pro_formas/${proFormaId}/sensitivity_analysis`,
    UPLOADED_PRO_FORMA: (organizationId: string, dealId: number) => `${V1}/${organizationId}/deals/${dealId}/uploaded_pro_formas`,
    GENERATE_PDF: () => `${V1}/generate_pdf`,
    BUDGET_CLASSIFICATIONS: (
        organizationId: string, proFormaId: string,
    ) => `${V1}/organization/${organizationId}/pro_forma/${proFormaId}/budget_classifications`,
    DEFAULT_BUDGET_CLASSIFICATIONS: (
        organizationId: string, proFormaId: string,
    ) => `${V1}/organization/${organizationId}/pro_forma/${proFormaId}/budget_classifications/default`,
    BUDGET_CLASSIFICATION: (
        organizationId: string, proFormaId: string,
    ) => `${V1}/organization/${organizationId}/pro_forma/${proFormaId}/budget_classification`,
    USES_TABLE: (organizationId: string, proFormaId: string) => `${V1}/organizations/${organizationId}/pro_formas/${proFormaId}/uses`,
    SOURCES_TABLE: (organizationId: string, proFormaId: string) => `${V1}/organizations/${organizationId}/pro_formas/${proFormaId}/sources`,
    MAPBOX: (address: string, APIKey: string) => `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?access_token=${APIKey}&limit=1`,
    SOURCE_TYPES: `${V1}/source_types`,
    ASSET_TYPES: `${V1}/asset_types`,
    CONSTRUCTION_TYPES: `${V1}/construction_types`,
    PRO_FORMA_WIZARD_STEP: (
        organizationId: string, proFormaId: string, stepNumber: number,
    ) => `${V1}/organizations/${organizationId}/pro_formas/${proFormaId}/step/${stepNumber}`,
    MSAS: `${V1}/msas`,
    MSA_BY_ZIP_CODE: (zipCode: string) => `${V1}/msas/zip-code/${zipCode}`,
    USER_ORGANIZATIONS: `${V1}/user_organizations`,
    ORGANIZATION: {
        LICENSES: (organizationId: string) => `${V1}/organization/${organizationId}/licenses`,
        ADD_ONS: (organizationId: string) => `${V1}/organization/${organizationId}/addons`,
        USERS: (organizationId: string) => `${V1}/organization/${organizationId}/users`,
    },
    DEAL_ATTRIBUTES: (organizationId: string) => `${V1}/organizations/${organizationId}/deal-attributes`,
    DROPDOWNS: `${V1}/dropdowns`,
    SUBPROJECT_STAGES: `${V1}/subproject_stages`,
    SUBPROJECT_STAGES_COUNT: (organizationId: string) => `${V1}/${organizationId}/deals/subproject_stages/count`,
    DEAL_DIRECTORY_TREE: (dealId: number) => `${V1}/deals/${dealId}/directories/tree`,
    DEAL_DOCUMENTS: {
        BULK: () => `${V1}/documents/bulk`,
    },
    FILE_STORAGE: `${V1}/file_storage`,
};

export const QUERY_KEYS = {
    ASSET_TYPES: 'assetTypes',
    CONSTRUCTION_TYPES: 'constructionTypes',
    MSAS: 'msas',
    ORGANIZATION: {
        LICENSES: 'organizationLicenses',
        ADD_ONS: 'organizationAddOns',
        USERS: 'organizationUsers',
    },
    USER_ORGANIZATIONS: 'userOrganizations',
    DEAL_ATTRIBUTES: 'dealAttributes',
    DEAL_DIRECTORY_TREE: 'dealDirectoryTree',
    DROPDOWNS: 'dropdowns',
};

export default API_ENDPOINTS;
