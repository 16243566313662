import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { useMutation } from '@tanstack/react-query';

interface IParams {
    dealId: number,
    directoryId: number;
}

const deleteDirectory = async ({ dealId, directoryId }: IParams): Promise<boolean> => {
    try {
        const response = await axiosInstance.delete(`${API_ENDPOINTS.DEAL_DIRECTORY.DIRECTORY(dealId, directoryId)}`);
        return response.data;
    } catch (error: any) {
        if (error.response?.status !== 500) {
            throw new Error(error.response.data);
        }
        throw new Error('An unexpected error occurred. Please try again.');
    }
};

export const useDeleteDirectory = () => useMutation({
    mutationFn: deleteDirectory,
});

export default useDeleteDirectory;
