import React, { useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { DealType } from 'constants/deals';
import PreviewFile from 'components/PreviewFile/PreviewFile';
import useAuthContext from 'contexts/AuthContext';
import { useGetDealById } from 'views/Deals/hooks/useGetDealById';
import useToast from 'hooks/useToast';
import { normalizeFileFormat } from 'views/DocumentLibrary/helpers';
import { NSButton } from '../../bricks';
import PageTitle from '../../components/PageTitle/PageTitle';
import DropzoneCard from '../DocumentLibrary/components/DropzoneCard/DropzoneCard';
import { ProFormaUploadTables } from './components/ProFormaUploadTables';
import { URLS } from '../../services/urls';
import {
    INITIAL_PROPERTY_RETURNS,
    INITIAL_PARTNERSHIP_RETURNS,
    INITIAL_DEVELOPMENT_RETURNS,
    INITIAL_OPERATIONS,
    INITIAL_VALUATION,
    INITIAL_DEVELOPMENT_USES,
    INITIAL_DEVELOPMENT_SOURCES,
    INITIAL_ACQUISITIONS,
} from './constants';

interface UploadedFile {
    id: number;
    file: File;
}

const ACCEPTED_FILE_TYPES = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
];

const ProFormaUpload = () => {
    const navigate = useNavigate();
    const { selectedOrganizationId } = useAuthContext();
    const [uploadedFile, setUploadedFile] = useState<UploadedFile | null>(null);
    const { dealId, proFormaId } = useParams<{ dealId: string; proFormaId?: string }>();
    const proFormaHomePath = generatePath(URLS.PRO_FORMA.HOME, { dealId });
    const { showSuccess, showError } = useToast();

    const { data: fetchedDeal } = useGetDealById({
        orgId: selectedOrganizationId!,
        dealId: Number(dealId),
        shouldFetch: !!Number(dealId),
    });

    // TODO: remove this dummy data when we have the API
    const existingMappings = proFormaId ? {
        propertyReturns: INITIAL_PROPERTY_RETURNS,
        partnershipReturns: INITIAL_PARTNERSHIP_RETURNS,
        developmentReturns: INITIAL_DEVELOPMENT_RETURNS,
        operations: INITIAL_OPERATIONS,
        valuation: INITIAL_VALUATION,
        developmentUses: INITIAL_DEVELOPMENT_USES,
        developmentSources: INITIAL_DEVELOPMENT_SOURCES,
        acquisitions: INITIAL_ACQUISITIONS,
    } : undefined;

    const handleFileUpload = async (
        event: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>,
        dropLocation: string,
    ): Promise<File[]> => {
        let fileList: FileList | null = null;

        if ('dataTransfer' in event && event.dataTransfer) {
            fileList = event.dataTransfer.files;
        } else if (event.target && (event.target as HTMLInputElement).files) {
            fileList = (event.target as HTMLInputElement).files;
        }

        const filesArray = fileList ? Array.from(fileList) : [];
        const file = filesArray[0];

        if (file) {
            if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
                showError('Please upload Microsoft Excel file types only');
                return [];
            }

            setUploadedFile({
                id: 1,
                file,
            });
            // TODO: add the logic to upload the file to the server.
            showSuccess('File uploaded successfully');
        }

        return filesArray;
    };

    // TODO: remove this dummy URI when the file is uploaded.
    const dummyURI = 'https://nsi-prod-document-storage.s3.amazonaws.com/334/9c67753c-212a-4e30-852b-1dc8a94dfbc0/ACR_v1.xlsx';

    return (
        <div className="h-100 d-flex flex-column">
            <PageTitle
                title={proFormaId ? 'Edit pro forma mapping' : 'Upload pro forma'}
                subtitle={proFormaId ? 'Edit the mapping for your uploaded pro forma.' : 'Use an existing .csv or .xlsx file to populate a new pro forma.'}
                containerClassName="mb-4"
            >
                <NSButton className="mr-2" color="secondary" text="Back" callback={() => navigate(proFormaHomePath)} />
                <NSButton color="primary" text="Finish" />
            </PageTitle>

            <div className="d-flex flex-grow-1">
                <div className="w-50 mr-4">
                    {!uploadedFile && !proFormaId
                        ? (
                            <div className="h-100">
                                <DropzoneCard
                                    getFilesFromEvent={handleFileUpload}
                                />
                            </div>
                        ) : (
                            <div className="h-100">
                                <PreviewFile
                                    uri={dummyURI}
                                    type={proFormaId ? 'xlsx' : normalizeFileFormat(uploadedFile?.file.type || '')}
                                    minHeight={800}
                                />
                            </div>
                        )}
                </div>

                <div className="w-50 d-flex flex-column">
                    <ProFormaUploadTables
                        isDisabled={!uploadedFile && !proFormaId}
                        dealType={fetchedDeal?.dealType?.name as DealType}
                        initialValues={existingMappings}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProFormaUpload;
