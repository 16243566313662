/* eslint-disable no-mixed-operators */
import React from 'react';
import './PaginationButtons.scss';
import { NSButton } from 'bricks';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
    itemsPerPage: number;
    totalItems: number;
}

const PaginationButtons: React.FC<PaginationProps> = ({
    currentPage, totalPages, onPageChange, itemsPerPage, totalItems,
}) => {
    const generatePageNumbers = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(i);
        }
        return pages;
    };

    return (
        <div className="PaginationButtons d-flex align-items-center justify-content-between">
            <span data-testid="pagination-info">
                {`${(currentPage - 1) * itemsPerPage + 1}-${Math.min(currentPage * itemsPerPage, totalItems)} of ${totalItems}`}
            </span>

            <div className="PaginationButtons__buttons d-flex align-items-center pl-3">
                <NSButton
                    className="PaginationButtons__buttons-button"
                    callback={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    color="secondary"
                    icon={faChevronLeft}
                    data-testid="prev-button"
                />
                {generatePageNumbers().map(page => (
                    <NSButton
                        key={page}
                        className="PaginationButtons__buttons-button"
                        callback={() => onPageChange(page)}
                        color={page === currentPage ? 'primary' : 'secondary'}
                        data-testid={`page-button-${page}`}
                    >
                        {page}
                    </NSButton>
                ))}
                <NSButton
                    className="PaginationButtons__buttons-button"
                    callback={() => onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    color="secondary"
                    icon={faChevronRight}
                    data-testid="next-button"
                />
            </div>
        </div>
    );
};

export default PaginationButtons;
