import React, { useState } from 'react';
import { NSCard, NSDropdown, NSInputNumberStepper } from 'bricks';
import { IOptionAnyValue } from 'bricks/types';
import ReadOnlyWrapper from 'components/ReadOnlyWrapper/ReadOnlyWrapper';
import { useGetProForma, useUpdateProForma } from 'queries/ProForma';
import { useQueryClient } from '@tanstack/react-query';
import { SENSITIVITY_OPTIONS } from './constants';
import DeleteSensitivityConfirmationModal from './DeleteSensitivityConfirmationModal/DeleteSensitivityConfirmationModal';
import './Sensitivity.scss';
import SensitivityTableContainer from './SensitivityTableContainer';

export interface ISensitivityProps {
    hasWriteAccessToProForma: boolean;
    dealId: number;
    proFormaId: number;
}

interface TableConfig {
    id: string;
    type: string;
    tableLabel: string;
}

const Sensitivity = ({ hasWriteAccessToProForma, dealId, proFormaId }: ISensitivityProps) => {
    const queryClient = useQueryClient();
    const { data: proForma } = useGetProForma(Number(dealId), Number(proFormaId));

    const [tables, setTables] = useState<TableConfig[]>([
        {
            id: 'main',
            type: 'Exit Capitalization Rate vs Gross Rent',
            tableLabel: 'Exit cap rate vs Gross Rent',
        },
    ]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [tableToDelete, setTableToDelete] = useState('');

    const { mutate: updateProForma } = useUpdateProForma();
    const handleUpdateProForma = (patchData: Object) => {
        updateProForma(
            { dealId: Number(dealId), proFormaId: Number(proFormaId), patchData },
            {
                onSuccess: () => queryClient.invalidateQueries({ queryKey: ['proFormaData'] }),
            },
        );
    };

    const handleSelectSensitivity = (option: IOptionAnyValue) => {
        if (option.value !== 'default') {
            const selectedOption = SENSITIVITY_OPTIONS.find(opt => opt.value === option.value);
            if (selectedOption) {
                setTables(prev => [...prev, {
                    id: `table-${Date.now()}`,
                    type: option.value as string,
                    tableLabel: option.label,
                }]);
            }
        }
    };

    const handleDeleteSensitivityConfirm = (tableId: string) => {
        setTableToDelete(tableId);
        setIsDeleteModalOpen(true);
    };

    const handleDeleteSensitivity = () => {
        setTables(prev => prev.filter(table => table.id !== tableToDelete));
        setTableToDelete('');
        setIsDeleteModalOpen(false);
    };

    return (
        <ReadOnlyWrapper isReadOnly={!hasWriteAccessToProForma}>
            <NSCard className="NSCard--level-3 d-flex flex-row justify-content-between align-items-center mb-2 p-2">
                <div>
                    <h4 className="text-white font-weight-bold mb-0 mt-0">Sensitivity analysis</h4>
                    <div>Set your Target Levered IRR to help generate analysis charts</div>
                </div>
                <div className="d-flex align-items-center">
                    <span className="text-white font-weight-bold mr-2">Target Levered IRR:</span>
                    <NSInputNumberStepper
                        percentage
                        name="targetLeveredIrr"
                        value={proForma?.targetLeveredIrr ? Number(proForma?.targetLeveredIrr) * 100 : null}
                        min={0}
                        step={0.1}
                        max={100}
                        onChange={(value: number) => handleUpdateProForma({
                            targetLeveredIrr: value ? (Number(value) / 100) : 0,
                        })}
                        inputGroupClassName="Sensitivity--input-width"
                    />
                </div>
            </NSCard>

            <NSCard className="p-2">
                <SensitivityTableContainer
                    dealId={dealId}
                    proFormaId={proFormaId}
                    tableConfig={tables.find(t => t.id === 'main')!}
                    onRequestDelete={handleDeleteSensitivityConfirm}
                />
            </NSCard>

            {tables
                .filter(table => table.id !== 'main')
                .map(table => (
                    <NSCard key={table.id} className="mb-2 p-2">
                        <SensitivityTableContainer
                            dealId={dealId}
                            proFormaId={proFormaId}
                            tableConfig={table}
                            onRequestDelete={handleDeleteSensitivityConfirm}
                        />
                    </NSCard>
                ))}

            <NSCard>
                <div className="Sensitivity__add-sensitivity">
                    <div className="Sensitivity__add-sensitivity__content">
                        <div className="Sensitivity__add-sensitivity__title pb-1">Add sensitivity</div>
                        <div className="Sensitivity__add-sensitivity__dropdown">
                            <NSDropdown
                                options={SENSITIVITY_OPTIONS}
                                selectedOption={{ label: 'Select sensitivity', value: 'default' }}
                                handleSelectOption={handleSelectSensitivity}
                                isFullWidth
                                disabled={!hasWriteAccessToProForma}
                            />
                        </div>
                    </div>
                </div>
            </NSCard>

            <DeleteSensitivityConfirmationModal
                isOpen={isDeleteModalOpen}
                toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                handleDelete={handleDeleteSensitivity}
            />
        </ReadOnlyWrapper>
    );
};

export default Sensitivity;
