import React from 'react';
import { ProFormaWizardProvider, useProFormaWizard } from './context/ProFormaWizardProvider';
import ProFormaWizardStepper from './components/ProFormaWizardStepper/ProFormaWizardStepper';
import ProFormaWizardStepOne from './components/ProFormaWizardStepOne/ProFormaWizardStepOne';
import ProFormaWizardStepTwo from './components/ProFormaWizardStepTwo/ProFormaWizardStepTwo';
import ProFormaWizardStepThree from './components/ProFormaWizardStepThree/ProFormaWizardStepThree';

const ProFormaWizardContent = () => {
    const { currentStep } = useProFormaWizard();

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return <ProFormaWizardStepOne />;
            case 2:
                return <ProFormaWizardStepTwo />;
            case 3:
                return <ProFormaWizardStepThree />;
            default:
                return <ProFormaWizardStepOne />;
        }
    };

    return (
        <div>
            <ProFormaWizardStepper />
            {renderStep()}
        </div>
    );
};

const ProFormaWizard = () => (
    <ProFormaWizardProvider>
        <ProFormaWizardContent />
    </ProFormaWizardProvider>
);

export default ProFormaWizard;
