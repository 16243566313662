import { IOptionAnyValue } from 'bricks/types';
import { ProFormaGenericColumn, ProFormaGenericRow } from 'views/ProFormaTable/components/ProFormaGenericTable/types';

export const calculationOptions: IOptionAnyValue[] = [
    { value: 'total_potential_income', label: 'Total potential income' },
    { value: 'effective_gross_revenue', label: 'Effective gross revenue' },
    { value: 'net_rental_income', label: 'Net rental income' },
    { value: 'net_operating_income', label: 'Net operating income' },
];

export const INITIAL_INCOME_ROWS: ProFormaGenericRow[] = [];

// TODO: Remove this mock data when socket is implemented

export const UNIT_MIX_BASE_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'name',
        field: 'name',
        header: 'Unit mix',
        format: 'none',
        type: 'text',
        isFixed: true,
    },
    {
        key: 'name1',
        field: 'name1',
        header: 'Unit mix',
        format: 'none',
        type: 'text',
        isFixed: true,
    },
    {
        key: 'units',
        field: 'units',
        header: 'Units',
        format: 'none',
        isFixed: true,
    },
    {
        key: 'averageSf',
        field: 'averageSf',
        header: 'Average SF',
        format: 'none',
        isFixed: true,
    },
    {
        key: 'pricePerSf',
        field: 'pricePerSf',
        header: '$/SF/Mo.',
        isFixed: true,
    },
    {
        key: 'pricePerUnit',
        field: 'pricePerUnit',
        header: '$/Unit/month',
        isFixed: true,
    },
    {
        key: 'amountPerYear',
        field: 'amountPerYear',
        header: 'Amount/year',
        isFixed: true,
    },
];

export const GROSS_RENT_ADJUSTMENT_BASE_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'name',
        field: 'name',
        header: 'Gross rent adjustments',
        format: 'none',
        type: 'text',
        isFixed: true,
        isReadOnly: (row: ProFormaGenericRow) => row.name === 'Concessions (free rent)' || row.name === 'Non-revenue (model) units' || true,
    },
    {
        key: 'invisibleUnits',
        field: 'invisibleUnits',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'invisibleSF',
        field: 'invisibleSF',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'pricePerSf',
        field: 'pricePerSf',
        header: '$/SF/Mo.',
        isFixed: true,
        isReadOnly: true,
    },
    {
        key: 'pricePerUnit',
        field: 'pricePerUnit',
        header: '$/Unit/month',
        isFixed: true,
        isReadOnly: true,
    },
    {
        key: 'amountPerYear',
        field: 'amountPerYear',
        header: 'Amount/year',
        isFixed: true,
        isReadOnly: true,
    },
];

export const OTHER_INCOME_BASE_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'name',
        field: 'name',
        header: 'Other income',
        format: 'none',
        type: 'text',
        isFixed: true,
    },
    {
        key: 'annualGrowth',
        field: 'annualGrowth',
        header: 'Annual growth',
        format: 'percentage' as const,
        isFixed: true,
    },
    {
        key: 'percentOfTotalRent',
        field: 'percentOfTotalRent',
        header: '% of total rent',
        format: 'percentage' as const,
        isFixed: true,
    },
    {
        key: 'pricePerSf',
        field: 'pricePerSf',
        header: '$/SF/Mo.',
        isFixed: true,
    },
    {
        key: 'pricePerUnit',
        field: 'pricePerUnit',
        header: '$/Unit/month',
        isFixed: true,
    },
    {
        key: 'amountPerYear',
        field: 'amountPerYear',
        header: 'Amount/year',
        isFixed: true,
    },
];

export const TOTAL_POTENTIAL_BASE_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'name',
        field: 'name',
        header: 'Total potential income',
        format: 'none' as const,
        isFixed: true,
    },
    {
        key: 'invisibleAnnualGrowth',
        field: 'invisibleAnnualGrowth',
        header: '',
        isHidden: true,
    },
    {
        key: 'invisiblePercentageTotalRent',
        field: 'invisiblePercentageTotalRent',
        header: '',
        isHidden: true,
    },
    {
        key: 'pricePerSf',
        field: 'pricePerSf',
        header: '',
        format: 'currency' as const,
        isFixed: true,
    },
    {
        key: 'pricePerUnit',
        field: 'pricePerUnit',
        header: '',
        format: 'currency' as const,
        isFixed: true,
    },
    {
        key: 'totalRent',
        field: 'totalRent',
        header: '',
        format: 'currency' as const,
        isFixed: true,
    },
];
