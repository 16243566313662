import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAuthContext } from 'contexts/AuthContext';
import { useGetProForma } from 'queries/ProForma';
import { FEATURE_IDS } from 'services/constants';
import { AccessLevels } from 'types/auth';
import { PERIOD_OPTIONS } from 'components/ProFormaReturnsTable/constant';
import { IOptionAnyValue } from 'bricks/types';
import { PageSpecificKeys, useLocalStorage } from 'helpers/localStorage';
import { PRO_FORMA_STATUS_PROJECT_CREATED } from 'views/ProFormaHome/constants';
import ProFormaSideNav from './components/ProFormaSideNav/ProFormaSideNav';
import {
    PRO_FORMA_TABS,
    PRO_FORMA_TAB_NAMES,
    PRO_FORMA_TABLE_NAV,
    PRO_FORMA_TABLE_NAV_NAMES,
    RETURNS_AND_WATERFALL_TABS,
    RETURNS_AND_WATERFALL_TAB_NAMES,
    SELECTED_PERIOD_CADENCE_KEY,
} from './constants';
import RenderIf from '../../components/RenderIf/RenderIf';
import ProForma from './components/ProForma/ProForma';
import ReturnsAndWaterfall from './components/ReturnsAndWaterfall/ReturnsAndWaterfall';
import ExecutiveSummary from './components/ExecutiveSummary/ExecutiveSummary';
import { getProFormaUrl } from './helpers';
import Sensitivity from './components/Sensitivity/Sensitivity';
import { ProFormaSocketProvider } from './components/ProForma/socketContext/ProFormaSocketProvider';
import { IProFormaPageLocalStorage } from './types';

const ProFormaTable = () => {
    const navigate = useNavigate();
    const { selectedOrganizationId, isFeatureAccessibleToUser } = useAuthContext();
    const userHasAccess = isFeatureAccessibleToUser([FEATURE_IDS.PRO_FORMA], AccessLevels.READ_WRITE_ACCESS);

    const [searchParams, setSearchParams] = useSearchParams();
    const { dealId, proFormaId } = useParams();

    const section = searchParams.get('section') || '';
    const tab = searchParams.get('tab') || '';

    const [activeSideNav, setActiveSideNav] = useState<string>(section);

    const { data: proForma } = useGetProForma(Number(dealId), Number(proFormaId));
    // eslint-disable-next-line max-len
    const hasWriteAccessToProForma = proForma?.proFormaStatusId === PRO_FORMA_STATUS_PROJECT_CREATED ? false : userHasAccess;

    const [proFormaPageLocalStorage] = useLocalStorage<IProFormaPageLocalStorage>(PageSpecificKeys.ProFormaPage, {});
    const currentProFormaLocalStorage = proForma && proForma.id in proFormaPageLocalStorage ? proFormaPageLocalStorage[proForma.id] : {};
    const savedSelectedPeriod = SELECTED_PERIOD_CADENCE_KEY in currentProFormaLocalStorage && currentProFormaLocalStorage[SELECTED_PERIOD_CADENCE_KEY];
    const initiallySelectedPeriodCadence = PERIOD_OPTIONS.find(period => period.value === savedSelectedPeriod);
    const [selectedPeriod, setSelectedPeriod] = React.useState<IOptionAnyValue>(initiallySelectedPeriodCadence || PERIOD_OPTIONS[0]);

    const navigateToSection = useCallback(
        (sideNavName: string, tabName?: string) => {
            setActiveSideNav(sideNavName);

            const updatedSearchParams: Record<string, string> = {
                section: sideNavName,
                ...(tabName ? { tab: tabName } : {}),
            };
            setSearchParams(updatedSearchParams);

            const url = getProFormaUrl(sideNavName, tabName);
            navigate(url, { replace: true });
        },
        [setActiveSideNav, setSearchParams, navigate],
    );

    useEffect(() => {
        const currentSection = PRO_FORMA_TABLE_NAV_NAMES.includes(section) ? section : PRO_FORMA_TABLE_NAV.PRO_FORMA.name;
        let currentTab = '';
        if (currentSection === PRO_FORMA_TABLE_NAV.PRO_FORMA.name) {
            currentTab = tab && PRO_FORMA_TAB_NAMES.includes(tab) ? tab : PRO_FORMA_TABS.GENERAL_INFORMATION.name;
        } else if (currentSection === PRO_FORMA_TABLE_NAV.RETURNS_AND_WATERFALL.name) {
            currentTab = tab && RETURNS_AND_WATERFALL_TAB_NAMES.includes(tab) ? tab : RETURNS_AND_WATERFALL_TABS.RETURNS;
        }
        if (currentSection !== section || currentTab !== tab) {
            navigateToSection(currentSection, currentTab);
        }
    }, [section, tab, navigateToSection]);

    return (
        <div className="d-flex align-items-stretch">
            <RenderIf isTrue={proForma}>
                <ProFormaSocketProvider organizationId={selectedOrganizationId!} dealId={proForma?.dealId!} proFormaId={proForma?.id!}>
                    <div className="flex-shrink-0">
                        <ProFormaSideNav
                            activeNav={activeSideNav}
                            navigateToSection={navigateToSection}
                            selectedPeriod={selectedPeriod}
                            setSelectedPeriod={setSelectedPeriod}
                            hasWriteAccessToProForma={hasWriteAccessToProForma}
                        />
                    </div>

                    <div className="flex-grow-1 overflow-hidden ml-3">
                        <RenderIf isTrue={activeSideNav === PRO_FORMA_TABLE_NAV.PRO_FORMA.name}>
                            <ProForma
                                navigateToSection={navigateToSection}
                                currentSection={section}
                                currentTab={tab}
                                hasWriteAccessToProForma={hasWriteAccessToProForma}
                            />
                        </RenderIf>

                        <RenderIf isTrue={activeSideNav === PRO_FORMA_TABLE_NAV.RETURNS_AND_WATERFALL.name}>
                            <ReturnsAndWaterfall
                                navigateToSection={navigateToSection}
                                currentSection={section}
                                currentTab={tab}
                                hasWriteAccessToProForma={hasWriteAccessToProForma}
                                selectedPeriod={selectedPeriod}
                            />
                        </RenderIf>

                        <RenderIf isTrue={activeSideNav === PRO_FORMA_TABLE_NAV.SENSITIVITY.name}>
                            <Sensitivity
                                hasWriteAccessToProForma={userHasAccess}
                                dealId={Number(dealId)}
                                proFormaId={Number(proFormaId)}
                            />
                        </RenderIf>

                        <RenderIf isTrue={activeSideNav === PRO_FORMA_TABLE_NAV.EXECUTIVE_SUMMARY.name}>
                            {dealId && proFormaId && (
                                <ExecutiveSummary
                                    dealId={+dealId}
                                    proFormaId={+proFormaId}
                                    hasWriteAccessToProForma={userHasAccess}
                                />
                            )}
                        </RenderIf>
                    </div>
                </ProFormaSocketProvider>
            </RenderIf>
        </div>
    );
};

export default ProFormaTable;
