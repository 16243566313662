import { NSButton, NSCard } from 'bricks';
import React from 'react';

export interface IReportSaveCardProps {
    header: string;
    description: string;
    handleSave: () => void;
    handleCancel: () => void;
    children: React.ReactNode;
}
const ReportSaveCard = ({
    header, description, handleSave, handleCancel, children,
}: IReportSaveCardProps) => (
    <NSCard className="NSCard--level-2 p-3 mt-2">
        <div className="d-flex justify-content-between align-items-center">
            <div>
                <h4 className="text-white m-0">{header}</h4>
                <p className="mb-0">{description}</p>
            </div>
            <div>
                <NSButton text="Cancel" callback={handleCancel} className="bg-none text-dark" />
                <NSButton text="Save" className="btn btn-primary ms-2" callback={handleSave} />
            </div>
        </div>
        <hr />
        {children}
    </NSCard>
);

export default ReportSaveCard;
