import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useAuthContext } from 'contexts/AuthContext';
import {
    NSDropdown, NSInput, NSLabel, NSModal,
} from 'bricks';
import { IOptionAnyValue } from 'bricks/types';
import useToast from 'hooks/useToast';
import { Deal } from 'views/Deals/types';
import { useCreateDealGroup } from 'views/Deals/hooks/useCreateDealGroup';
import { useAssociateDealsToDealGroup } from 'views/Deals/hooks/useAssociateToDealGroup';
import { dealGroupsTypes } from './constants';

export interface ICreateGroupModalProps {
    toggle: () => void;
    isOpen: boolean;
    deals: Deal[];
    onCreationSuccess?: () => void;
}

const CreateGroupModal = ({
    toggle, isOpen, deals, onCreationSuccess,
}: ICreateGroupModalProps) => {
    const { selectedOrganizationId } = useAuthContext();

    const [groupName, setGroupName] = useState('');
    const [selectedGroupType, setSelectedGroupType] = useState<IOptionAnyValue | undefined>();
    const queryClient = useQueryClient();
    const { showSuccess, showError } = useToast();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setGroupName(value);
    };

    const { mutate: createDealGroup, error: createError } = useCreateDealGroup();
    const { mutate: associateDealsToDealGroup, error: associateError } = useAssociateDealsToDealGroup();

    const cleanData = () => {
        setSelectedGroupType(undefined);
        setGroupName('');
    };

    const handleCreateDealGroup = () => {
        createDealGroup(
            {
                orgId: selectedOrganizationId!,
                postData: { name: groupName, typeId: Number(selectedGroupType?.id) },
            },
            {
                onSuccess: data => {
                    const dealGroupId = data.id;

                    associateDealsToDealGroup(
                        {
                            orgId: selectedOrganizationId!,
                            postData: {
                                associations: deals.map(deal => ({
                                    dealId: Number(deal.id),
                                    dealGroupId,
                                })),
                            },
                        },
                        {
                            onSuccess: () => {
                                showSuccess('Deal group created and deals associated successfully!');
                                queryClient.invalidateQueries({ queryKey: ['dealGroups', selectedOrganizationId!] });
                                toggle();
                                cleanData();
                                onCreationSuccess?.();
                            },
                            onError: error => {
                                showError(associateError?.message || 'Failed to associate deals. Please try again.');
                            },
                        },
                    );
                },
                onError: error => {
                    showError(createError?.message || 'Failed to create deal group. Please try again.');
                },
            },
        );
    };

    return (
        <NSModal
            toggle={toggle}
            isOpen={isOpen}
            modalHeaderClassName="bg-primary modal-colored-header"
            modalHeaderText="Create group"
            modalFooterButtonText="Create group"
            modalFooterIsButtonFunctionDisabled={groupName.trim().length === 0 && selectedGroupType === undefined}
            modalFooterIsShown
            size="lg"
            modalFooterButtonFunction={handleCreateDealGroup}
            modalFooterCancelButtonFunction={cleanData}
        >
            <NSInput
                id="groupName"
                type="text"
                label="New group name"
                name="groupName"
                placeholder="New group"
                value={groupName}
                onChange={handleInputChange}
                required
            />
            <NSLabel className="text-dark font-weight-normal pt-1">
                Group type
                <span className="text-danger"> *</span>
            </NSLabel>
            <NSDropdown
                options={dealGroupsTypes}
                selectedOption={selectedGroupType}
                placeholder="Group type"
                handleSelectOption={option => setSelectedGroupType(option)}
                isFullWidth
            />
        </NSModal>
    );
};

export default CreateGroupModal;
