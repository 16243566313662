import React from 'react';
import {
    UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu,
} from 'reactstrap';
import { NSButton, NSCheckbox } from 'bricks';
import { faLineColumns } from '@fortawesome/pro-solid-svg-icons';
import RenderIf from '../../../components/RenderIf/RenderIf';
import { TABLE_KEYS } from '../constants';
import { IOptionStringValue, IVisibleColumns } from '../types';

export interface IVisibleColumnsDropdownProps {
    visibleColumns: IVisibleColumns;
    updateVisibleColumns: (visibleColumns: IVisibleColumns) => void;
    columnOptions: IOptionStringValue[];
    isOptionDisplayed: (key: string) => boolean;
}

const VisibleColumnsDropdown = ({
    visibleColumns, updateVisibleColumns, columnOptions, isOptionDisplayed,
}: IVisibleColumnsDropdownProps) => {
    const isCheckedAll = Object.values(visibleColumns).filter(checked => checked).length === columnOptions.length;

    const toggleAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        const updatedVisibleColumns: IVisibleColumns = {};

        columnOptions.forEach(({ value }) => {
            updatedVisibleColumns[value] = isChecked || value === TABLE_KEYS.deal;
        });

        updateVisibleColumns(updatedVisibleColumns);
    };

    const toggleVisibleColumn = (key: string) => {
        const updatedVisibleColumns = {
            ...visibleColumns,
            [key]: key === TABLE_KEYS.deal ? true : !visibleColumns[key],
        };

        updateVisibleColumns(updatedVisibleColumns);
    };

    return (
        <UncontrolledDropdown>
            <DropdownToggle tag="div">
                <NSButton className="mr-2" icon={faLineColumns} outline={false} color="secondary" />
            </DropdownToggle>
            <DropdownMenu className="text-white overflow-auto" style={{ maxHeight: '350px' }}>
                <DropdownItem header className="text-uppercase text-primary m-0 px-2 py-1">
                    <small>Visible columns:</small>
                </DropdownItem>
                <DropdownItem className="px-2 py-1" toggle={false}>
                    <NSCheckbox
                        id={TABLE_KEYS.all}
                        labelText="All"
                        callback={toggleAll}
                        size="sm"
                        checked={isCheckedAll}
                        iconClassName="mr-2 cursor--pointer"
                        labelClassName="font-weight-normal text-white"
                    />
                </DropdownItem>

                {columnOptions.map(option => (
                    <RenderIf key={option.value} isTrue={isOptionDisplayed(option.value)}>
                        <DropdownItem className="px-2 py-1" toggle={false}>
                            <NSCheckbox
                                id={option.value}
                                labelText={option.label}
                                callback={() => toggleVisibleColumn(option.value)}
                                size="sm"
                                checked={!!visibleColumns[option.value]}
                                iconClassName="mr-2 cursor--pointer"
                                labelClassName="font-weight-normal text-white"
                            />
                        </DropdownItem>
                    </RenderIf>
                ))}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

export default VisibleColumnsDropdown;
