import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS from 'services/urls';

export interface IResponse {
    url: string;
}

export const getProFormaExecutiveSummaryPdf = async (
    dealId: number,
    proFormaId: number,
): Promise<IResponse> => {
    const response = await axiosInstance.get(
        API_ENDPOINTS.PRO_FORMA_EXECUTIVE_SUMMARY_PDF(dealId, proFormaId),
    );
    return response.data;
};

export const useGetProFormaExecutiveSummaryPdf = (
    dealId: number,
    proFormaId: number,
    options: { enabled: boolean },
) => useQuery({
    queryKey: ['ProFormaExecutiveSummary', dealId, proFormaId],
    queryFn: () => getProFormaExecutiveSummaryPdf(dealId, proFormaId),
    ...options,
});

export default useGetProFormaExecutiveSummaryPdf;
