/* eslint-disable operator-linebreak */
import React, {
    useEffect, useState, useMemo, useCallback,
} from 'react';
import { faArrowRight, faAngleRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import { isEmpty } from 'lodash';
import {
    FORECAST_METRIC_KEY, FORECAST_METRIC_OPTIONS_NON_EARLY_PLANNING, POPULATE_USES_METHOD, PRO_FORMA_DEVELOPMENT_UNIT_TYPE_KEY,
} from 'views/ProFormaTable/constants';
import RenderIf from 'components/RenderIf/RenderIf';
import ExpandableSection from 'components/ExpandableSection/ExpandableSection';
import {
    NSButton, NSCard, NSSelect,
} from 'bricks';
import NSInfoBanner from 'components/NSInfoBanner/NSInfoBanner';
import { IProFormaPageLocalStorage, IUnitTypeOption } from 'views/ProFormaTable/types';
import { UNIT_OF_MEASURE_TYPES } from 'constants/unitTypes';
import { getBudgetLinesDateRange, getProFormaDevelopmentUnitTypeOptions } from 'views/ProFormaTable/helpers';
import ReadOnlyWrapper from 'components/ReadOnlyWrapper/ReadOnlyWrapper';
import { format } from 'date-fns';
import { useGetProjectSourcesActuals } from 'queries/ProjectActuals';
import useToast from 'hooks/useToast';
import { IOptionAnyValue } from 'bricks/types';
import { PageSpecificKeys, useLocalStorage } from 'helpers/localStorage';
import { useAuthContext } from 'contexts/AuthContext';
import { useGetBackOfEnvelopeScenarios } from 'views/DealDashboard/BOE/hooks/useGetBOEScenarios';
import { useGetBackOfEnvelopeDeals } from 'views/DealDashboard/BOE/hooks/useGetBOEDeals';
import DealScenarioDropdown from 'components/DealScenarioDropdown/DealScenarioDropdown';
import UsesVsSourcesCard from './UsesVsSourcesCard/UsesVsSourcesCard';
import DevelopmentUsesTable from './DevelopmentUsesTable/DevelopmentUsesTable';
import DevelopmentSourcesTable from './DevelopmentSourcesTable/DevelopmentSourcesTable';
import ImportUsesModal from './ImportUsesModal/ImportUsesModal';
import { UsesTableProvider } from './DevelopmentUsesTable/context/UsesTableProvider';
import { useProFormaSocketContext } from '../socketContext/ProFormaSocketProvider';
import NewProjectActualsSnapshotModal from './NewProjectActualsSnapshotModal/NewProjectActualsSnapshotModal';
import ProjectSourcesActualsTable from './ProjectSourcesActualsTable/ProjectSourcesActualsTable';
import './ProFormaDevelopment.scss';
import UsesSnapshotSelect from './UsesSnapshotSelect/UsesSnapshotSelect';
import ImportSearchBar from './ImportSearchBar/ImportSearchBar';

export interface IProFormaDevelopmentProps {
    navigateToSection: (sideNavName: string, tabName?: string) => void;
    hasWriteAccessToProForma: boolean;
}

const ProFormaDevelopment = ({ navigateToSection, hasWriteAccessToProForma }: IProFormaDevelopmentProps) => {
    const {
        uses,
        sources,
        proForma,
        handleCreateDefaultBudgetClassifications,
        handleStartDevelopmentWithBOEEstimates,
        isDefaultBudgetClassificationsLoading,
        handleDeleteBudgetClassification,
        isBudgetClassificationDeletionLoading,
        shouldUseProjectActuals,
    } = useProFormaSocketContext();

    const [usesMethod, setUsesMethod] = useState<string | null>(null);
    const [isImportModalOpen, setIsImportModalOpen] = useState(false);
    const [selectedSnapshot, setSelectedSnapshot] = useState<number | null>(null);
    const [showNewSnapshotModal, setShowNewSnapshotModal] = useState(false);
    const [proFormaPageLocalStorage, setProFormaPageLocalStorage] = useLocalStorage<IProFormaPageLocalStorage>(PageSpecificKeys.ProFormaPage, {});

    const currentProFormaLocalStorage = proForma.id in proFormaPageLocalStorage ? proFormaPageLocalStorage[proForma.id] : {};
    const localStorageForecastMetric = FORECAST_METRIC_KEY in currentProFormaLocalStorage && currentProFormaLocalStorage[FORECAST_METRIC_KEY];
    const [selectedForecastMetric, setSelectedForecastMetric] = useState<IOptionAnyValue>(FORECAST_METRIC_OPTIONS_NON_EARLY_PLANNING[0]);

    const { showError } = useToast();

    const { data: projectSourcesActuals, error: sourcesActualsError, isLoading: areProjectSourcesActualsLoading } = useGetProjectSourcesActuals(
        proForma.subprojectId!,
        {
            enabled: shouldUseProjectActuals && Boolean(proForma.subprojectId),
        },
    ); // TODO: to update here https://app.clickup.com/t/86b486ntr

    const usesLocalStorageData: UNIT_OF_MEASURE_TYPES = JSON.parse(localStorage.getItem(PRO_FORMA_DEVELOPMENT_UNIT_TYPE_KEY) ?? '{}');

    const unitTypeOptions: IUnitTypeOption[] = getProFormaDevelopmentUnitTypeOptions(proForma.proFormaModelId);
    const existingUnitType =
        !isEmpty(usesLocalStorageData) && unitTypeOptions.length
            ? unitTypeOptions.filter(option => option.value === usesLocalStorageData)[0]
            : unitTypeOptions?.[0];

    const showUsesOverSourcesWarning =
        uses?.totals?.amount !== null && sources?.totals?.amount !== null && Number(uses?.totals?.amount) > Number(sources?.totals?.amount);

    const [unitType, setUnitType] = useState<IUnitTypeOption | null>(null);

    const toggleImportModal = () => setIsImportModalOpen(!isImportModalOpen);

    const startUsesFromScratch = () => {
        setUsesMethod(POPULATE_USES_METHOD.START_FROM_SCRATCH.title);
    };

    const toggleNewSnapshotModal = () => setShowNewSnapshotModal(!showNewSnapshotModal);

    const handleCreateSnapshot = (name: string) => {
        // handleCreateSnapshot(name); // TODO: Implement this https://app.clickup.com/t/2326533/PRODUCT-4521
        toggleNewSnapshotModal();
    };

    const handleStartFromScratch = () => {
        if (!uses?.uses?.length) {
            handleCreateDefaultBudgetClassifications(startUsesFromScratch);
        } else {
            setUsesMethod(POPULATE_USES_METHOD.START_FROM_SCRATCH.title);
        }
    };

    const updateLocalStorageByKey = (key: string, value: any) => {
        const updatedCurrentProForma = { ...currentProFormaLocalStorage, [key]: value };
        const updatedProFormaStorage = { ...proFormaPageLocalStorage, [proForma.id]: updatedCurrentProForma };
        setProFormaPageLocalStorage(updatedProFormaStorage as IProFormaPageLocalStorage);
    };

    const handleSelectForecastMetric = (forecastMetric: IOptionAnyValue) => {
        setSelectedForecastMetric(forecastMetric);
        updateLocalStorageByKey(FORECAST_METRIC_KEY, forecastMetric.value);
    };

    const deleteSuccessCallback = () => {
        setUsesMethod(null);
        toggleImportModal();
    };

    const handleDeleteBudgetCategories = () => {
        handleDeleteBudgetClassification([], true, deleteSuccessCallback);
    };

    const { selectedOrganizationId } = useAuthContext();
    const [selectedScenario, setSelectedScenario] = useState<IOptionAnyValue | undefined>();

    const { data: scenariosData } = useGetBackOfEnvelopeScenarios({
        orgId: selectedOrganizationId!,
        dealId: proForma.dealId,
    });

    const { data: dealsWithScenarios } = useGetBackOfEnvelopeDeals({
        orgId: selectedOrganizationId!,
    });

    const combinedScenarios = useMemo(() => {
        if (!scenariosData?.items && !dealsWithScenarios?.items) return [];

        const simplifiedScenarios = convertToSimplifiedScenario(dealsWithScenarios?.items || null);

        return [
            ...(simplifiedScenarios || []),
            ...(scenariosData?.items.map(item => ({
                name: item.name,
                id: item.id,
            })) || []),
        ];
    }, [scenariosData?.items, dealsWithScenarios?.items]);

    function convertToSimplifiedScenario(data: any[] | null) {
        if (!data) return null;

        return data.map(item => ({
            name: item.name,
            id: item.id,
            scenarios: [{ id: -1, name: 'default' }],
            hasNestedScenarios: true,
        }));
    }

    const handleSelectScenario = (option: IOptionAnyValue) => {
        if (!option || option.label === 'SEARCH' || option.label === 'CREATE' || option.label === 'BACK') {
            return;
        }

        setSelectedScenario(option);
    };

    const handleCreateScenario = (option: IOptionAnyValue) => {
        // Navigate to BOE page to create new scenario
        navigateToSection('back-of-envelope');
    };

    const handleProceedWithScenario = useCallback(() => {
        if (!selectedScenario) return;

        handleStartDevelopmentWithBOEEstimates(selectedScenario.id?.toString() ?? '', startUsesFromScratch);
    }, [selectedScenario]);

    if (!proForma) {
        // show loading screen instead
        return null;
    }

    const { earliestBudgetLinesStartDate, latestBudgetLinesEndDate } = getBudgetLinesDateRange(uses.uses || []);

    const resetSnapshot = () => {
        setSelectedSnapshot(null);
        handleSelectForecastMetric(FORECAST_METRIC_OPTIONS_NON_EARLY_PLANNING[0]);
    };

    useEffect(() => {
        let forecastMetric = FORECAST_METRIC_OPTIONS_NON_EARLY_PLANNING[0];
        if (localStorageForecastMetric) {
            forecastMetric = FORECAST_METRIC_OPTIONS_NON_EARLY_PLANNING.find(
                option => option.value === localStorageForecastMetric,
            ) || FORECAST_METRIC_OPTIONS_NON_EARLY_PLANNING[0];
        }
        setSelectedForecastMetric(forecastMetric);
    }, [localStorageForecastMetric]);

    useEffect(() => {
        if (sourcesActualsError) {
            showError('Something went wrong getting project sources.');
        }
    }, [sourcesActualsError]);

    useEffect(() => {
        if (!unitType && existingUnitType) {
            setUnitType(existingUnitType);
        }
    }, [unitType, existingUnitType]);

    return (
        <>
            <ExpandableSection
                number={1}
                title="Development Timeline"
                subtitle="Determine development timeline for this investment"
                className="NSCard--level-2"
                isExpanded
                animate
            >
                <ReadOnlyWrapper isReadOnly={!hasWriteAccessToProForma}>
                    <div className="d-flex m-3">
                        <NSCard className="NSCard--level-4 w-100 py-2 px-3 mr-3">
                            <div>
                                <h5 className="my-0 text-muted font-14">Development start</h5>
                                <RenderIf isTrue={earliestBudgetLinesStartDate !== null}>
                                    <small className="text-dark font-14">
                                        {earliestBudgetLinesStartDate && format(earliestBudgetLinesStartDate, 'MMMM yyyy')}
                                    </small>
                                </RenderIf>
                                <RenderIf isTrue={!earliestBudgetLinesStartDate}>
                                    <small className="text-dark font-italic font-14">In progress</small>
                                </RenderIf>
                            </div>
                        </NSCard>
                        <NSCard className="NSCard--level-4 w-100 py-2 px-3">
                            <div>
                                <h5 className="my-0 text-muted font-14">Development end</h5>
                                <RenderIf isTrue={latestBudgetLinesEndDate !== null}>
                                    <small className="text-dark font-14">
                                        {latestBudgetLinesEndDate && format(latestBudgetLinesEndDate, 'MMMM yyyy')}
                                    </small>
                                </RenderIf>
                                <RenderIf isTrue={!latestBudgetLinesEndDate}>
                                    <small className="text-dark font-italic font-14">In progress</small>
                                </RenderIf>
                            </div>
                        </NSCard>
                    </div>
                </ReadOnlyWrapper>
            </ExpandableSection>

            <RenderIf isTrue={showUsesOverSourcesWarning}>
                <NSInfoBanner
                    title="Warning: Uses exceed sources"
                    subtitle="Ensure funding is sufficient to cover uses."
                    className="mt-3 NSCard--level-4 NSCard__left-border--warning"
                    warning
                    customInfo={<UsesVsSourcesCard usesTotal={uses?.totals?.amount || 0} sourcesTotal={sources?.totals?.amount || 0} />}
                />
            </RenderIf>

            <ExpandableSection
                number={2}
                title="Uses"
                subtitle="Information about the investment' s allocation of funds"
                className="mt-3 NSCard--level-2"
                displaySectionButtons={Boolean(usesMethod || uses?.uses?.length)}
                sectionButtons={(
                    <>
                        <RenderIf isTrue={hasWriteAccessToProForma}>
                            <NSButton
                                text="Import Uses via different method"
                                className="NSButton--secondary-lighten mr-2"
                                callback={toggleImportModal}
                            />
                        </RenderIf>

                        <RenderIf isTrue={!hasWriteAccessToProForma && shouldUseProjectActuals && proForma.subprojectId}>
                            <div className="d-flex pr-1">
                                <RenderIf isTrue={Boolean(selectedSnapshot)}>
                                    <NSButton
                                        text="Reset"
                                        className="text-primary mr-1"
                                        callback={resetSnapshot}
                                    />
                                </RenderIf>
                                <div className="ProFormaDevelopment__expandable-section-button--border-right pr-1">
                                    <UsesSnapshotSelect
                                        selectedSnapshot={selectedSnapshot}
                                        setSelectedSnapshot={setSelectedSnapshot}
                                        toggleNewSnapshotModal={toggleNewSnapshotModal}
                                    />
                                </div>
                            </div>
                            <div className="mr-1">
                                <NSSelect
                                    name="forecastMetricSelect"
                                    isClearable={false}
                                    options={FORECAST_METRIC_OPTIONS_NON_EARLY_PLANNING}
                                    onChange={(option: IOptionAnyValue) => handleSelectForecastMetric(option)}
                                    value={selectedForecastMetric}
                                />
                            </div>
                        </RenderIf>
                    </>
                )}
                isExpanded
                animate
            >
                <ReadOnlyWrapper isReadOnly={!hasWriteAccessToProForma}>
                    <RenderIf isTrue={!uses?.uses?.length && !shouldUseProjectActuals && usesMethod === null}>
                        <Row className="m-3">
                            <Col m={12} xl={6} className="px-0">
                                <div className="h3 mt-0 text-dark mb-3">
                                    Select a method to populate
                                    {' '}
                                    <b>Uses</b>
                                    :
                                </div>
                                <NSCard
                                    className="NSCard--level-4 mb-2 p-2 cursor--pointer"
                                    onClick={() => setUsesMethod(POPULATE_USES_METHOD.IMPORT_FROM_PREVIOUS_PROJECT.title)}
                                >
                                    <div className="text-dark h5 mt-1 mb-1 font-16">{POPULATE_USES_METHOD.IMPORT_FROM_PREVIOUS_PROJECT.title}</div>
                                    <div className="mb-0 lh-sm">{POPULATE_USES_METHOD.IMPORT_FROM_PREVIOUS_PROJECT.subtitle}</div>
                                </NSCard>

                                <NSCard
                                    className={`NSCard--level-4 mb-2 p-2 ${combinedScenarios.length > 0 ? 'cursor--pointer' : ''}`}
                                    onClick={() => combinedScenarios.length > 0 && setUsesMethod(POPULATE_USES_METHOD.START_WITH_BACK_OF_ENVELOPE.title)}
                                    id="boe-card"
                                >
                                    <div className={`text-dark h5 mt-1 mb-1 font-16 ${combinedScenarios.length === 0 ? 'text-muted' : ''}`}>
                                        {POPULATE_USES_METHOD.START_WITH_BACK_OF_ENVELOPE.title}
                                    </div>
                                    <div className={`mb-0 lh-sm ${combinedScenarios.length === 0 ? 'text-muted' : ''}`}>
                                        {POPULATE_USES_METHOD.START_WITH_BACK_OF_ENVELOPE.subtitle}
                                    </div>
                                </NSCard>
                                {combinedScenarios.length === 0 && (
                                    <UncontrolledTooltip target="boe-card">
                                        No saved scenarios available.
                                    </UncontrolledTooltip>
                                )}

                                <NSCard
                                    className="NSCard--level-4 mb-2 p-2 cursor--pointer"
                                    onClick={() => setUsesMethod(POPULATE_USES_METHOD.USE_RECOMMENDED_CATEGORIES.title)}
                                >
                                    <div className="text-dark h5 mt-1 mb-1 font-16">{POPULATE_USES_METHOD.USE_RECOMMENDED_CATEGORIES.title}</div>
                                    <div className="mb-0 lh-sm">{POPULATE_USES_METHOD.USE_RECOMMENDED_CATEGORIES.subtitle}</div>
                                </NSCard>

                                <div role="presentation" onClick={handleStartFromScratch} className="d-flex mt-3 cursor--pointer">
                                    <RenderIf isTrue={isDefaultBudgetClassificationsLoading}>
                                        <span className="spinner-border spinner-border-sm" style={{ height: '20px', width: '20px' }} />
                                    </RenderIf>
                                    <RenderIf isTrue={!isDefaultBudgetClassificationsLoading}>
                                        <div className="text-primary">{POPULATE_USES_METHOD.START_FROM_SCRATCH.title}</div>
                                        <FontAwesomeIcon icon={faArrowRight} className="ml-1 my-auto text-primary" />
                                    </RenderIf>
                                </div>
                            </Col>
                        </Row>
                    </RenderIf>

                    <RenderIf isTrue={(uses?.uses?.length && !shouldUseProjectActuals) || usesMethod !== null}>
                        <div className="m-3">
                            <RenderIf isTrue={usesMethod === POPULATE_USES_METHOD.IMPORT_FROM_PREVIOUS_PROJECT.title}>
                                <ImportSearchBar
                                    onExitImport={() => setUsesMethod(null)}
                                    onSearch={value => console.log(value)}
                                />
                            </RenderIf>
                            <RenderIf isTrue={usesMethod === POPULATE_USES_METHOD.START_WITH_BACK_OF_ENVELOPE.title}>
                                <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center mb-2">
                                        <div
                                            role="presentation"
                                            onClick={() => {
                                                setUsesMethod(null);
                                                setSelectedScenario(undefined);
                                            }}
                                            className="d-flex align-items-center cursor--pointer text-primary"
                                        >
                                            <FontAwesomeIcon icon={faChevronLeft} className="px-1 font-10" />
                                            <span>Back</span>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <span className="text-white font-20 mb-1">Select a Back-Of-Envelope scenario:</span>
                                        <DealScenarioDropdown
                                            scenarios={combinedScenarios}
                                            handleSelectScenario={handleSelectScenario}
                                            handleCreateScenario={handleCreateScenario}
                                            includeSearch
                                            includeLabels
                                            preSelectScenario={selectedScenario}
                                        />
                                        <div className="mt-3">
                                            <NSButton
                                                text="Import"
                                                color="primary"
                                                callback={handleProceedWithScenario}
                                                disabled={!selectedScenario}
                                                icon={faAngleRight}
                                                iconPosition="right"
                                                isLoading={isDefaultBudgetClassificationsLoading}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </RenderIf>

                            {/*
                        <RenderIf isTrue={usesMethod === POPULATE_USES_METHOD.USE_RECOMMENDED_CATEGORIES.title}>
                        </RenderIf> */}

                            <RenderIf
                                isTrue={(usesMethod === POPULATE_USES_METHOD.START_FROM_SCRATCH.title || uses?.uses?.length) && unitType && proForma}
                            >
                                <UsesTableProvider navigateToSection={navigateToSection} isProFormaReadOnly={!hasWriteAccessToProForma}>
                                    <DevelopmentUsesTable
                                        uses={uses || {}}
                                        unitType={unitType!}
                                        setUnitType={setUnitType}
                                        unitTypeOptions={unitTypeOptions}
                                        proForma={proForma}
                                    />
                                </UsesTableProvider>
                            </RenderIf>

                        </div>
                    </RenderIf>
                    <RenderIf isTrue={shouldUseProjectActuals}>
                        <div className="p-3">UsesProjectActualsTable (placeholder)</div>
                    </RenderIf>
                </ReadOnlyWrapper>
            </ExpandableSection>
            <ExpandableSection
                number={3}
                title="Sources"
                subtitle="Information about the investment's sources of funds"
                className="mt-3 NSCard--level-2"
                isExpanded
                animate
            >
                <ReadOnlyWrapper isReadOnly={!hasWriteAccessToProForma}>
                    <RenderIf isTrue={!isEmpty(sources) && unitType && !shouldUseProjectActuals}>
                        <DevelopmentSourcesTable
                            sources={sources}
                            unitType={unitType!}
                            setUnitType={setUnitType}
                            unitTypeOptions={unitTypeOptions}
                            hasWriteAccessToProForma={hasWriteAccessToProForma}
                        />
                    </RenderIf>

                    <RenderIf
                        isTrue={shouldUseProjectActuals && projectSourcesActuals && !isEmpty(projectSourcesActuals)}
                        isLoading={areProjectSourcesActualsLoading}
                    >
                        <ProjectSourcesActualsTable
                            projectSourcesActuals={projectSourcesActuals!}
                        />
                    </RenderIf>
                </ReadOnlyWrapper>
            </ExpandableSection>

            <RenderIf isTrue={isImportModalOpen}>
                <ImportUsesModal
                    isOpen={isImportModalOpen}
                    toggle={toggleImportModal}
                    handleDeleteBudgetCategories={handleDeleteBudgetCategories}
                    isLoading={isBudgetClassificationDeletionLoading}
                />
            </RenderIf>

            <RenderIf isTrue={showNewSnapshotModal}>
                <NewProjectActualsSnapshotModal
                    isOpen={showNewSnapshotModal}
                    toggle={toggleNewSnapshotModal}
                    handleCreateSnapshot={handleCreateSnapshot}
                    isLoadingCallback={false} // TODO: Implement this https://app.clickup.com/t/2326533/PRODUCT-4521
                    forecastMetric={selectedForecastMetric.label}
                />
            </RenderIf>
        </>
    );
};

export default ProFormaDevelopment;
