import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import './ReportBuilder.scss';

import { useAuthContext } from 'contexts/AuthContext';
import PageTitle from 'components/PageTitle/PageTitle';
import { IOptionAnyValue } from 'bricks/types';
import CreateSection from './CreateSection/CreateSection';
import NewCombinedReportSection from './NewCombinedReportSection/NewCombinedReportSection';
import { IReportBuilderReport } from './constants';

const ReportBuilder = () => {
    const { selectedOrganizationId } = useAuthContext();

    const [selectedTemplate, setSelectedTemplate] = useState<IOptionAnyValue>();
    const [newReports, setNewReports] = useState<Array<IReportBuilderReport>>([]);

    return (
        <>
            <PageTitle title="Report Builder" displayBreadcrumb dealName="4th Street Highrise" pageMetaDataTitle="Report Builder" />
            <Row className="mt-3 d-flex">
                <Col sm={11} md={9} lg={4} className="pb-3">
                    <CreateSection
                        orgId={selectedOrganizationId!}
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        newReports={newReports}
                        setNewReports={setNewReports}
                    />
                </Col>
                <Col sm={11} md={9} lg={8} className="pb-3">
                    <NewCombinedReportSection
                        selectedTemplate={selectedTemplate}
                        newReports={newReports}
                        setNewReports={setNewReports}
                        orgId={selectedOrganizationId!}
                    />
                </Col>
            </Row>
        </>
    );
};

export default ReportBuilder;
