import React from 'react';
import classNames from 'classnames';

export interface INSLoadingScreenProps {
    message?: string;
    size?: 'sm';
}

const NSLoadingScreen = ({ message, size }: INSLoadingScreenProps) => (
    <div
        className="NSLoadingScreen d-flex flex-column align-items-center p-2 mx-auto"
        style={{ display: 'flex', justifyContent: 'center' }}
        data-testid="ns-loading-screen"
    >
        <img
            src="https://s3.amazonaws.com/nsi-logos/Northspyre_loading_logo.gif"
            alt="loading-logo"
            className={classNames([
                'NSLoadingScreen__img',
                {
                    'NSLoadingScreen__img--sm': size === 'sm',
                },
            ])}
        />
        <h5 className="mt-0">{message}</h5>
    </div>
);

export default NSLoadingScreen;
