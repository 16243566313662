import React from 'react';
import { NSInput } from '../../../bricks';
import './ProFormaSingleMapping.scss';

interface ProFormaSingleMappingProps {
    label: string;
    sheet: string;
    cell: string;
    onSheetChange: (value: string) => void;
    onCellChange: (value: string) => void;
}

const ProFormaSingleMapping: React.FC<ProFormaSingleMappingProps> = ({
    label,
    sheet,
    cell,
    onSheetChange,
    onCellChange,
}) => (
    <div className="ProFormaSingleMapping ProFormaMappingTable--highlighted">
        <div className="ProFormaSingleMapping__cell ProFormaSingleMapping__label">
            {label}
        </div>
        <div className="ProFormaSingleMapping__cell">
            <NSInput
                name="sheet-input"
                type="text"
                value={sheet}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSheetChange(e.target.value)}
                placeholder="Sheet reference"
            />
        </div>
        <div className="ProFormaSingleMapping__cell">
            <NSInput
                name="cell-input"
                type="text"
                value={cell}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onCellChange(e.target.value)}
                placeholder="Cell reference"
            />
        </div>
    </div>
);

export default ProFormaSingleMapping;
