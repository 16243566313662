import React, { useEffect, useState } from 'react';
import { NSModal, NSInput, NSSelect } from 'bricks';
import { Label } from 'reactstrap';
import { IDealDirectory } from 'views/DocumentLibrary/types';

interface FolderOption {
  label: string;
  value: number;
}

interface EditDirectoryModalProps {
  isOpen: boolean;
  toggle: () => void;
  directory: IDealDirectory;
  handleEditDirectory: (directory: IDealDirectory) => void;
  isLoadingDirectoryUpdate?: boolean;
  folderOptions?: FolderOption[];
}

export const EditDirectoryModal = ({
    isOpen,
    toggle,
    directory,
    handleEditDirectory,
    isLoadingDirectoryUpdate = false,
    folderOptions = [] as FolderOption[],
}: EditDirectoryModalProps) => {
    const [newDirectory, setNewDirectory] = useState<IDealDirectory>(directory);

    useEffect(() => {
        setNewDirectory(directory);
    }, [directory, isOpen]);

    const isSubmitDisabled
    = newDirectory?.directoryName === directory?.directoryName
    && newDirectory?.parentDirectoryId === directory?.parentDirectoryId;

    return (
        <NSModal
            isOpen={isOpen}
            toggle={toggle}
            modalHeaderText="Edit Folder"
            modalHeaderClassName="bg-primary text-white"
            modalBodyClassName="px-3 py-4"
            modalFooterButtonIsLoading={isLoadingDirectoryUpdate}
            modalFooterButtonFunction={() => handleEditDirectory(newDirectory)}
            modalFooterIsButtonFunctionDisabled={isSubmitDisabled}
            footerButtonOutline={false}
            modalFooterButtonText="Save"
        >
            <NSInput
                type="text"
                id="name"
                name="name"
                value={newDirectory?.directoryName}
                onChange={(e: any) => setNewDirectory(prev => ({ ...prev, directoryName: e.target.value }))}
                dataTestId="directory-name-input"
            />

            <Label className="mt-2">Folder location</Label>
            <NSSelect
                name="folder-locations"
                options={folderOptions}
                isClearable={false}
                value={
                    folderOptions.find(
                        option => option.value === newDirectory?.parentDirectoryId,
                    ) || { label: 'Home', value: null }
                }
                onChange={option => setNewDirectory(prev => ({
                    ...prev,
                    parentDirectoryId: option?.value ?? null,
                }))}
            />
        </NSModal>
    );
};

export default EditDirectoryModal;
