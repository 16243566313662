import React, { useState, useEffect, useMemo } from 'react';
import '../ProFormaIncomeTable.scss';
import ProFormaGenericTable from 'views/ProFormaTable/components/ProFormaGenericTable/ProFormaGenericTable';
import { ProFormaGenericRow, ProFormaGenericColumn } from 'views/ProFormaTable/components/ProFormaGenericTable/types';
import { IOptionAnyValue } from 'bricks/types';
import { formatUSDCurrencyTwoDigits } from 'ns_libs/formatter';
import { useScrollableSync } from 'views/ProFormaTable/hooks/useScrollableSync';
import { createCashFlowData, createTableCashFlowColumns, generateDateRange } from 'views/ProFormaTable/helpers';
import { IOperatingPeriodCashFlows } from 'views/ProFormaTable/types';
import { DISABLED_PLACEHOLDER_PERIOD, OPERATIONS_INCOME_WS_HANDLERS } from 'views/ProFormaTable/constants';
import {
    calculationOptions, UNIT_MIX_BASE_COLUMNS, GROSS_RENT_ADJUSTMENT_BASE_COLUMNS,
    OTHER_INCOME_BASE_COLUMNS, TOTAL_POTENTIAL_BASE_COLUMNS,
} from './constants';
import { useProFormaSocketContext } from '../../../socketContext/ProFormaSocketProvider';

const ApartmentIncomeTable = () => {
    const {
        incomeSummary, handleCreateOperationsRow, handleUpdateOperationsRow, handleDeleteOperationsRow,
        operatingPeriodCashFlows, proForma,
    } = useProFormaSocketContext();

    const [unitMixRows, setUnitMixRows] = useState<ProFormaGenericRow[]>([]);
    const [grossRentAdjustmentRows, setGrossRentAdjustmentRows] = useState<ProFormaGenericRow[]>([]);
    const [otherIncomeRows, setOtherIncomeRows] = useState<ProFormaGenericRow[]>([]);
    const [incomeAdjustmentsRows, setIncomeAdjustmentsRows] = useState<ProFormaGenericRow[]>([]);

    const { handleScroll, handleScrollableRef } = useScrollableSync();

    const periodOptions = useMemo(() => {
        const periodsDateRange = proForma.startDate && proForma.saleDate ? [proForma.startDate, proForma.saleDate] : null;

        const options = periodsDateRange !== null
            ? generateDateRange(periodsDateRange, 'MMM yyyy', true)
            : [{ label: 'MON YEAR', value: DISABLED_PLACEHOLDER_PERIOD }];
        return options;
    }, [proForma.startDate, proForma.saleDate]);

    const getOperatingPeriodData = (period: { value: string }, cashFlows: IOperatingPeriodCashFlows[]) => {
        const periodIndex = periodOptions.findIndex(p => p.value === period.value);

        // Pure index-based matching - ignore year/month values completely
        // This ensures we get data even if the year/month doesn't match exactly
        if (periodIndex >= 0 && periodIndex < cashFlows.length) {
            return cashFlows[periodIndex];
        }

        return undefined;
    };

    const handleSelectCalculation = (rowId: string) => (option: IOptionAnyValue | null) => {
        if (option) {
            setIncomeAdjustmentsRows(rows => rows.map(row => (
                row.id === rowId ? { ...row, selectedCalculation: option } : row
            )));
        }
    };

    const unitMixCashFlowColumns = useMemo(() => createTableCashFlowColumns(incomeSummary?.unitMix),
        [incomeSummary?.unitMix]);

    const grossRentAdjustmentCashFlowColumns = useMemo(() => createTableCashFlowColumns(incomeSummary?.rentAdjustments),
        [incomeSummary?.rentAdjustments]);

    const otherIncomeCashFlowColumns = useMemo(() => createTableCashFlowColumns(incomeSummary?.otherIncome),
        [incomeSummary?.otherIncome]);

    const incomeAdjustmentsCashFlowColumns = useMemo(() => createTableCashFlowColumns(incomeSummary?.incomeAdjustments),
        [incomeSummary?.incomeAdjustments]);

    const operatingPeriodColumns = useMemo(() => {
        if (!periodOptions?.length) return [];

        return periodOptions.map((period, index) => ({
            key: `column_${period.value}`,
            field: `column_${period.value}`,
            header: (
                <div className="h6 NSTable__thead__tr__th--grey-text text-uppercase text-nowrap text-secondary text-right mb-0">
                    <div>
                        MONTH
                        {' '}
                        {index}
                    </div>
                    <div className="h6 text-uppercase text-right text-nowrap mt-1">{period.label}</div>
                </div>
            ),
            format: 'currency' as const,
            isFixed: false,
        }));
    }, [periodOptions]);

    const unitMixColumns = useMemo(() => [
        ...UNIT_MIX_BASE_COLUMNS,
        ...unitMixCashFlowColumns,
        ...operatingPeriodColumns,
    ], [unitMixCashFlowColumns, operatingPeriodColumns]);

    const grossRentAdjustmentColumns = useMemo(() => [
        ...GROSS_RENT_ADJUSTMENT_BASE_COLUMNS,
        ...grossRentAdjustmentCashFlowColumns,
        ...operatingPeriodColumns,
    ], [grossRentAdjustmentCashFlowColumns, operatingPeriodColumns]);

    const otherIncomeColumns = useMemo(() => [
        ...OTHER_INCOME_BASE_COLUMNS,
        ...otherIncomeCashFlowColumns,
        ...operatingPeriodColumns,
    ], [otherIncomeCashFlowColumns, operatingPeriodColumns]);

    const incomeAdjustmentsColumns: ProFormaGenericColumn[] = useMemo(() => [
        {
            key: 'name',
            field: 'name',
            header: 'Income adjustments',
            format: 'none',
            type: 'text',
            isFixed: true,
            isReadOnly: (row: ProFormaGenericRow) => ['vacancy', 'credit-loss'].includes(row.id),
            dropdown: {
                label: 'Calculated on:',
                options: calculationOptions,
                // TODO: get initial calculation type from socket data
                getSelectedOption: (row: ProFormaGenericRow) => row.selectedCalculation,
                handleSelectOption: handleSelectCalculation,
                shouldShowDropdown: (row: ProFormaGenericRow) => ['vacancy', 'credit-loss'].includes(row.id),
            },
        },
        {
            key: 'invisibleUnits',
            field: 'invisibleUnits',
            header: '',
            isHidden: true,
            isFixed: true,
        },
        {
            key: 'percentOfIncome',
            field: 'percentOfIncome',
            header: '% of income',
            format: 'percentage',
            isFixed: true,
        },
        {
            key: 'pricePerSf',
            field: 'pricePerSf',
            header: '$/SF/Mo.',
            isFixed: true,
            isReadOnly: (row: ProFormaGenericRow) => ['vacancy', 'credit-loss'].includes(row.id),
        },
        {
            key: 'pricePerUnit',
            field: 'pricePerUnit',
            header: '$/Unit/month',
            isFixed: true,
            isReadOnly: (row: ProFormaGenericRow) => ['vacancy', 'credit-loss'].includes(row.id),
        },
        {
            key: 'totalAmountPerYear',
            field: 'totalAmountPerYear',
            header: 'Amount/year',
            isFixed: true,
            isReadOnly: (row: ProFormaGenericRow) => ['vacancy', 'credit-loss'].includes(row.id),
        },
        ...incomeAdjustmentsCashFlowColumns,
        ...operatingPeriodColumns,
    ], [incomeAdjustmentsCashFlowColumns, operatingPeriodColumns]);

    const totalPotentialColumns = useMemo(() => {
        const baseCols = TOTAL_POTENTIAL_BASE_COLUMNS.map(col => {
            let { header } = col;
            if (col.key === 'pricePerSf') {
                header = formatUSDCurrencyTwoDigits(incomeSummary?.totalPotentialIncome?.totalPricePerSf || 0);
            } else if (col.key === 'pricePerUnit') {
                header = formatUSDCurrencyTwoDigits(incomeSummary?.totalPotentialIncome?.totalPricePerUnit || 0);
            } else if (col.key === 'totalRent') {
                header = formatUSDCurrencyTwoDigits(incomeSummary?.totalPotentialIncome?.totalAmountPerYear || 0);
            }
            return { ...col, header };
        });

        const operatingCols = operatingPeriodColumns.map(col => {
            const period = periodOptions.find(p => `column_${p.value}` === col.key);
            if (!period) return col;

            const cashFlow = getOperatingPeriodData(period, operatingPeriodCashFlows);
            return {
                ...col,
                header: (
                    <div className="text-right">
                        {formatUSDCurrencyTwoDigits(cashFlow?.totalPotentialIncome || 0)}
                    </div>
                ),
            };
        });

        return [...baseCols, ...operatingCols];
    }, [incomeSummary?.totalPotentialIncome, operatingPeriodColumns, operatingPeriodCashFlows, periodOptions]);

    useEffect(() => {
        if (incomeSummary) {
            if (incomeSummary.unitMix) {
                setUnitMixRows(prevRows => {
                    const existingRowsMap = new Map(prevRows.map(row => [row.name, row]));

                    return (incomeSummary.unitMix || []).map(unit => {
                        const existingRow = existingRowsMap.get(unit.name);
                        const cashFlowData = createCashFlowData(unit.cashFlow || []);
                        const operatingPeriodData = Object.fromEntries(
                            periodOptions.map(period => {
                                const cashFlow = getOperatingPeriodData(period, operatingPeriodCashFlows);
                                return [
                                    `column_${period.value}`,
                                    String(cashFlow?.units?.find((u: { name: string; value: number }) => u.name === unit.name)?.value || 0),
                                ];
                            }),
                        );

                        return {
                            ...(existingRow || {}),
                            id: String(unit.id),
                            name: unit.name || '',
                            units: String(unit.units || 0),
                            averageSf: String(unit.averageSf || 0),
                            pricePerSf: String(unit.pricePerSf || 0),
                            pricePerUnit: String(unit.pricePerUnit || 0),
                            amountPerYear: String(unit.amountPerYear || 0),
                            ...cashFlowData,
                            ...operatingPeriodData,
                        };
                    });
                });
            }

            if (incomeSummary.rentAdjustments) {
                setGrossRentAdjustmentRows(prevRows => {
                    const existingRowsMap = new Map(prevRows.map(row => [row.name, row]));

                    return (incomeSummary.rentAdjustments || []).map(adjustment => {
                        const existingRow = existingRowsMap.get(adjustment.name);
                        const cashFlowData = createCashFlowData(adjustment.cashFlow || []);
                        const operatingPeriodData = Object.fromEntries(
                            periodOptions.map(period => {
                                const cashFlow = getOperatingPeriodData(period, operatingPeriodCashFlows);
                                return [
                                    `column_${period.value}`,
                                    String(cashFlow?.rentAdjustments?.find(
                                        (a: { name: string; value: number }) => a.name === adjustment.name,
                                    )?.value || 0),
                                ];
                            }),
                        );

                        return {
                            ...(existingRow || {}),
                            id: String(adjustment.id),
                            name: adjustment.name || '',
                            units: String(adjustment.units || 0), // Add units field
                            pricePerSf: String(adjustment.valuePerSf || 0),
                            pricePerUnit: String(adjustment.valuePerUnit || 0),
                            amountPerYear: String(adjustment.amountPerYear || 0),
                            ...cashFlowData,
                            ...operatingPeriodData,
                        };
                    });
                });
            }

            if (incomeSummary.otherIncome) {
                setOtherIncomeRows(prevRows => {
                    const existingRowsMap = new Map(prevRows.map(row => [row.name, row]));
                    return (incomeSummary.otherIncome || []).map(income => {
                        const existingRow = existingRowsMap.get(income.name);
                        const cashFlowData = createCashFlowData(income.cashFlow || []);
                        const operatingPeriodData = Object.fromEntries(
                            periodOptions.map(period => {
                                const cashFlow = getOperatingPeriodData(period, operatingPeriodCashFlows);

                                return [
                                    `column_${period.value}`,
                                    String(cashFlow?.otherIncome?.find((i: { name: string; value: number }) => i.name === income.name)?.value || 0),
                                ];
                            }),
                        );

                        return {
                            ...(existingRow || {}),
                            id: String(income.id),
                            name: income.name || '',
                            annualGrowth: String(income.annualGrowth || 0),
                            percentOfTotalRent: String(income.percentOfTotalRent || 0),
                            pricePerSf: String(income.pricePerSf || 0),
                            pricePerUnit: String(income.pricePerUnit || 0),
                            amountPerYear: String(income.amountPerYear || 0),
                            ...cashFlowData,
                            ...operatingPeriodData,
                        };
                    });
                });
            }

            if (incomeSummary.incomeAdjustments) {
                setIncomeAdjustmentsRows(prevRows => {
                    const existingRowsMap = new Map(prevRows.map(row => [row.name, row]));

                    return (incomeSummary.incomeAdjustments || []).map(adjustment => {
                        const existingRow = existingRowsMap.get(adjustment.name);
                        const cashFlowData = createCashFlowData(adjustment.cashFlow || []);
                        const operatingPeriodData = Object.fromEntries(
                            periodOptions.map(period => {
                                const cashFlow = getOperatingPeriodData(period, operatingPeriodCashFlows);
                                return [
                                    `column_${period.value}`,
                                    String(
                                        cashFlow?.rentAdjustments?.find(
                                            (a: { name: string; value: number }) => a.name === adjustment.name,
                                        )?.value || 0,
                                    ),
                                ];
                            }),
                        );

                        return {
                            ...(existingRow || {}),
                            id: String(adjustment.id),
                            name: adjustment.name || '',
                            percentOfIncome: String(adjustment.percentOfIncome || 0),
                            pricePerSf: String(adjustment.pricePerSf || 0),
                            pricePerUnit: String(adjustment.pricePerUnit || 0),
                            amountPerYear: String(adjustment.amountPerYear || 0),
                            ...cashFlowData,
                            ...operatingPeriodData,
                        };
                    });
                });
            }
        }
    }, [incomeSummary, operatingPeriodCashFlows, periodOptions]);

    return (
        <div className="ProFormaIncome p-3">
            <ProFormaGenericTable
                columns={unitMixColumns}
                rows={unitMixRows}
                setRows={setUnitMixRows}
                handleAddRow={() => handleCreateOperationsRow(OPERATIONS_INCOME_WS_HANDLERS.UNIT_MIX.CREATE)}
                handleDeleteRow={rowId => handleDeleteOperationsRow(OPERATIONS_INCOME_WS_HANDLERS.UNIT_MIX.DELETE, rowId)}
                handleUpdateRow={(rowId, dataKey) => handleUpdateOperationsRow(OPERATIONS_INCOME_WS_HANDLERS.UNIT_MIX.UPDATE, rowId, dataKey)}
                onScrollableRef={handleScrollableRef(0)}
                onScroll={handleScroll}
                totalText="Gross rent"
                totalValues={incomeSummary?.grossRent ? {
                    units: String(incomeSummary.grossRent.totalUnits || 0),
                    averageSf: String(incomeSummary.grossRent.averageSf || 0),
                    pricePerSf: String(incomeSummary.grossRent.totalPricePerSf || 0),
                    pricePerUnit: String(incomeSummary.grossRent.totalPricePerUnit || 0),
                    amountPerYear: String(incomeSummary.grossRent.totalAmountPerYear || 0),
                    ...createCashFlowData(incomeSummary.grossRent.totalCashFlow || []),
                    ...Object.fromEntries(
                        periodOptions.map(period => {
                            const cashFlow = getOperatingPeriodData(period, operatingPeriodCashFlows);
                            return [
                                `column_${period.value}`,
                                String(cashFlow?.grossRent || 0),
                            ];
                        }),
                    ),
                } : undefined}
            />
            <div className="pb-1" />
            <ProFormaGenericTable
                columns={grossRentAdjustmentColumns}
                rows={grossRentAdjustmentRows}
                setRows={setGrossRentAdjustmentRows}
                handleUpdateRow={(rowId, dataKey) => handleUpdateOperationsRow(
                    OPERATIONS_INCOME_WS_HANDLERS.GROSS_RENT_ADJUSTMENT.UPDATE,
                    rowId,
                    dataKey,
                )}
                onScrollableRef={handleScrollableRef(1)}
                onScroll={handleScroll}
                totalText="Total rental income"
                hideAddButton
                totalValues={incomeSummary?.totalRentalIncome ? {
                    pricePerSf: String(incomeSummary.totalRentalIncome.totalPricePerSf || 0),
                    pricePerUnit: String(incomeSummary.totalRentalIncome.totalPricePerUnit || 0),
                    amountPerYear: String(incomeSummary.totalRentalIncome.totalAmountPerYear || 0),
                    ...createCashFlowData(incomeSummary.totalRentalIncome.totalCashFlow || []),
                    ...Object.fromEntries(
                        periodOptions.map(period => {
                            const cashFlow = getOperatingPeriodData(period, operatingPeriodCashFlows);
                            return [
                                `column_${period.value}`,
                                String(cashFlow?.totalRentalIncome || 0),
                            ];
                        }),
                    ),
                } : undefined}
            />
            <div className="pb-1" />
            <ProFormaGenericTable
                columns={otherIncomeColumns}
                rows={otherIncomeRows}
                setRows={setOtherIncomeRows}
                handleAddRow={() => handleCreateOperationsRow(OPERATIONS_INCOME_WS_HANDLERS.OTHER_INCOME.CREATE)}
                handleDeleteRow={rowId => handleDeleteOperationsRow(OPERATIONS_INCOME_WS_HANDLERS.OTHER_INCOME.DELETE, rowId)}
                handleUpdateRow={(rowId, dataKey) => handleUpdateOperationsRow(OPERATIONS_INCOME_WS_HANDLERS.OTHER_INCOME.UPDATE, rowId, dataKey)}
                onScrollableRef={handleScrollableRef(2)}
                onScroll={handleScroll}
                totalText="Total other income"
                totalValues={incomeSummary?.totalOtherIncome ? {
                    annualGrowth: String(incomeSummary.totalOtherIncome.totalAnnualGrowth || 0),
                    percentOfTotalRent: String(incomeSummary.totalOtherIncome.percentOfTotalRent || 0),
                    pricePerSf: String(incomeSummary.totalOtherIncome.totalPricePerSf || 0),
                    pricePerUnit: String(incomeSummary.totalOtherIncome.totalPricePerUnit || 0),
                    amountPerYear: String(incomeSummary.totalOtherIncome.totalAmountPerYear || 0),
                    ...createCashFlowData(incomeSummary.totalOtherIncome.totalCashFlow || []),
                    ...Object.fromEntries(
                        periodOptions.map(period => {
                            const cashFlow = getOperatingPeriodData(period, operatingPeriodCashFlows);
                            return [
                                `column_${period.value}`,
                                String(cashFlow?.totalOtherIncome || 0),
                            ];
                        }),
                    ),
                } : undefined}
            />
            <div className="pb-1" />
            <ProFormaGenericTable
                columns={totalPotentialColumns}
                rows={[]}
                setRows={() => { }}
                hideTotalFooter
                hideAddButton
                onScrollableRef={handleScrollableRef(3)}
                onScroll={handleScroll}
            />
            <div className="pb-1" />
            <ProFormaGenericTable
                columns={incomeAdjustmentsColumns}
                rows={incomeAdjustmentsRows}
                setRows={setIncomeAdjustmentsRows}
                handleAddRow={() => handleCreateOperationsRow(OPERATIONS_INCOME_WS_HANDLERS.INCOME_ADJUSTMENT.CREATE)}
                handleDeleteRow={rowId => handleDeleteOperationsRow(OPERATIONS_INCOME_WS_HANDLERS.INCOME_ADJUSTMENT.DELETE, rowId)}
                handleUpdateRow={(rowId, dataKey) => handleUpdateOperationsRow(OPERATIONS_INCOME_WS_HANDLERS.INCOME_ADJUSTMENT.UPDATE, rowId, dataKey)}
                onScrollableRef={handleScrollableRef(4)}
                onScroll={handleScroll}
                totalText="Effective gross revenue"
                totalValues={incomeSummary?.effectiveGrossRevenue ? {
                    percentOfIncome: ' ', // Not displayed in the table
                    pricePerSf: String(incomeSummary.effectiveGrossRevenue.totalPricePerSf || 0),
                    pricePerUnit: String(incomeSummary.effectiveGrossRevenue.totalPricePerUnit || 0),
                    amountPerYear: String(incomeSummary.effectiveGrossRevenue.totalAmountPerYear || 0),
                    ...createCashFlowData(incomeSummary.effectiveGrossRevenue.totalCashFlow || []),
                    ...Object.fromEntries(
                        periodOptions.map(period => {
                            const cashFlow = getOperatingPeriodData(period, operatingPeriodCashFlows);
                            return [
                                `column_${period.value}`,
                                String(cashFlow?.effectiveGrossRevenue || 0),
                            ];
                        }),
                    ),
                } : undefined}
            />
        </div>
    );
};

export default ApartmentIncomeTable;
