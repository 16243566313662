/* eslint-disable no-unused-vars */
export const MAX_DEALS_FOR_PDF_EXPORT = 5;

export enum ComparisonReportPropertyGroup {
    PROPERTY_RETURNS = 'Property returns',
    PARTNERSHIP_RETURNS = 'Partnership returns',
    DEVELOPMENT_RETURNS = 'Development returns',
    DETAILS = 'Details',
    USES = 'Uses',
    INCOME = 'Income',
}

export const COMPARISON_REPORT_GROUPS: ComparisonReportPropertyGroup[] = [
    ComparisonReportPropertyGroup.PROPERTY_RETURNS,
    ComparisonReportPropertyGroup.PARTNERSHIP_RETURNS,
    ComparisonReportPropertyGroup.DEVELOPMENT_RETURNS,
    ComparisonReportPropertyGroup.DETAILS,
    ComparisonReportPropertyGroup.USES,
    ComparisonReportPropertyGroup.INCOME,
];

export enum ComparisonReportRow {
    // Property Returns
    UNLEVERED = 'Unlevered',
    LEVERED = 'Levered',

    // Partnership Returns
    LIMITED_PARTNER = 'Limited partner',
    GENERAL_PARTNER = 'General partner',

    // Development Returns
    YIELD_ON_COST = 'Yield-on-cost',
    MARKET_CAP_RATE = 'Market cap rate',
    DEVELOPMENT_SPREAD = 'Development spread',

    // Details
    UNITS = 'Units',
    NET_RENTABLE_SF = 'Net rentable SF',
    LAND_AREA = 'Land area',
    BUILDINGS = 'Building(s)',
    HEIGHT = 'Height',
    PARKING_SPACES = 'Parking spaces',
    PARKING_RATIO = 'Parking ratio',

    // Uses
    ACQUISITION_COSTS = 'Acquisition costs',
    HARD_COSTS = 'Hard costs',
    SOFT_COSTS = 'Soft costs',
    CARRY_COSTS = 'Carry costs',
    TOTAL_USES = 'Total uses',

    // Income
    EFFECTIVE_GROSS_REVENUE = 'Effective gross revenue',
    OPERATING_EXPENSES = 'Operating expenses',
    NET_OPERATING_INCOME = 'Net operating income',
    CAPITAL_EXPENDITURES = 'Capital expenditures',
    CASH_FLOW_FROM_OPERATIONS = 'Cash flow from operations',
    CAP_RATE = 'Cap rate',
    VALUATION = 'Valuation',
    PERCENT_OF_TOTAL_PROJECT_COST = '% of total project cost',
    VALUATION_PER_UNIT = 'Valuation / unit',
}

export const COMPARISON_REPORT_GROUPS_MAP: Record<ComparisonReportPropertyGroup, ComparisonReportRow[]> = {
    [ComparisonReportPropertyGroup.PROPERTY_RETURNS]: [ComparisonReportRow.UNLEVERED, ComparisonReportRow.LEVERED],
    [ComparisonReportPropertyGroup.PARTNERSHIP_RETURNS]: [ComparisonReportRow.LIMITED_PARTNER, ComparisonReportRow.GENERAL_PARTNER],
    [ComparisonReportPropertyGroup.DEVELOPMENT_RETURNS]: [
        ComparisonReportRow.YIELD_ON_COST,
        ComparisonReportRow.MARKET_CAP_RATE,
        ComparisonReportRow.DEVELOPMENT_SPREAD,
    ],
    [ComparisonReportPropertyGroup.DETAILS]: [
        ComparisonReportRow.UNITS,
        ComparisonReportRow.NET_RENTABLE_SF,
        ComparisonReportRow.LAND_AREA,
        ComparisonReportRow.BUILDINGS,
        ComparisonReportRow.HEIGHT,
        ComparisonReportRow.PARKING_SPACES,
        ComparisonReportRow.PARKING_RATIO,
    ],
    [ComparisonReportPropertyGroup.USES]: [
        ComparisonReportRow.ACQUISITION_COSTS,
        ComparisonReportRow.HARD_COSTS,
        ComparisonReportRow.SOFT_COSTS,
        ComparisonReportRow.CARRY_COSTS,
        ComparisonReportRow.TOTAL_USES,
    ],
    [ComparisonReportPropertyGroup.INCOME]: [
        ComparisonReportRow.EFFECTIVE_GROSS_REVENUE,
        ComparisonReportRow.OPERATING_EXPENSES,
        ComparisonReportRow.NET_OPERATING_INCOME,
        ComparisonReportRow.CAPITAL_EXPENDITURES,
        ComparisonReportRow.CASH_FLOW_FROM_OPERATIONS,
        ComparisonReportRow.CAP_RATE,
        ComparisonReportRow.VALUATION,
        ComparisonReportRow.PERCENT_OF_TOTAL_PROJECT_COST,
        ComparisonReportRow.VALUATION_PER_UNIT,
    ],
};
