import { useQuery } from '@tanstack/react-query';
import { config } from 'config';
import API_ENDPOINTS from 'services/urls';

interface IGeocodeQueryParams {
    address: string;
}

const getMapboxGeocodeFromAddress = async ({ address }: IGeocodeQueryParams): Promise<any> => {
    const response = await fetch(API_ENDPOINTS.MAPBOX(encodeURIComponent(address), config.mapboxGlApiKey));

    const data = await response.json();
    const bestMatch = data.features[0];
    if (!bestMatch || !bestMatch.center || bestMatch.center.length < 2) throw new Error('No valid geocode data returned');

    const county = bestMatch.context.find((item: { id: string; }) => item.id.startsWith('district.'))?.text || '';
    const zipCode = bestMatch.context.find((item: { id: string; }) => item.id.startsWith('postcode.'))?.text || '';
    const city = bestMatch.context.find((item: { id: string; }) => item.id.startsWith('place.'))?.text || '';
    const state = bestMatch.context.find((item: { id: string; }) => item.id.startsWith('region.'))?.text || '';

    return {
        lat: bestMatch.center[1],
        lng: bestMatch.center[0],
        county,
        zipCode,
        city,
        state,
    };
};

export const useGetCoordinates = (address: string) => useQuery({
    queryKey: ['mapCoordinates', address],
    queryFn: () => getMapboxGeocodeFromAddress({ address }),
    enabled: isQueryEnabled(address),
});

const isQueryEnabled = (address: string | null | undefined): boolean => {
    if (!address || address.trim() === '') return false; // If address is empty, disable

    const trimmedAddress = address.trim();

    const isZipCodeOnly = /^\d{5}$/.test(trimmedAddress); // Check if it's exactly 5 digits (valid zip)
    const isPurelyNumeric = /^\d+$/.test(trimmedAddress); // Check if address is only numbers

    if (isPurelyNumeric && trimmedAddress.length !== 5) return false; // Disable if purely numeric and not exactly 5

    return isZipCodeOnly || !isPurelyNumeric; // Enable if valid zip or valid full address
};

export default useGetCoordinates;
