import React, { useState } from 'react';
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEllipsisVertical,
    faCaretDown,
    faCaretRight,
} from '@fortawesome/free-solid-svg-icons';
import {
    faPenToSquare,
    faTrash,
    faDownload,
} from '@fortawesome/pro-regular-svg-icons';
import { formatDateWithMonth } from 'ns_libs/formatter';
import './DocumentLibraryTable.scss';
import { DealDirectoryItem, IDealDocument } from '../../types';
import { checkIfIsFile, getFileIcon } from '../../helpers';

interface FileRowProps {
    item: DealDirectoryItem;
    level: number;
    expanded: { [key: string]: boolean };
    onToggle: (id: number) => void;
    onSelect: (document: IDealDocument) => void;
    onEdit: (item: DealDirectoryItem) => void;
    onDelete: (item: DealDirectoryItem) => void;
}

const FileRow = ({
    item, level, expanded, onToggle, onSelect, onDelete, onEdit,
}: FileRowProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const isFile = checkIfIsFile(item);
    const isFolder = !isFile;
    const isExpanded = Boolean(isFolder && item.directoryId && expanded[item.directoryId]);
    const hasChildren = isFolder && (item?.children.length > 0 || item?.documents.length > 0);
    const baseClass = 'DocumentLibraryTable__indent';
    const levelClass = `${baseClass}--level-${level}`;
    const baseIndentClass = `${baseClass} ${levelClass}`;
    const fileModifier = 'DocumentLibraryTable__indent--file';
    const fileClass = isFile ? ` ${fileModifier}` : '';
    const indentClass = `${baseIndentClass}${fileClass}`;

    const handleRowClick = (e: React.MouseEvent) => {
        if (isFolder) {
            if (item.directoryId) onToggle(item.directoryId);
        } else {
            onSelect(item);
        }
    };

    const preventClickPropagation = (e: React.MouseEvent, callback: CallableFunction) => {
        e.stopPropagation();
        callback();
    };

    return (
        <>
            <tr
                className="NSTable__tbody__tr DocumentLibraryTable__row"
                onClick={handleRowClick}
                role="button"
                aria-label={isFolder ? `${isExpanded ? 'Collapse' : 'Expand'} folder ${item.directoryName}` : `Open file ${item.name}`}
            >
                <td className="NSTable__tbody__tr__td">
                    <div className={indentClass}>
                        {hasChildren && (
                            <span className={`DocumentLibraryTable__toggle-btn ${isExpanded ? 'text-muted' : ''}`}>
                                <FontAwesomeIcon icon={isExpanded ? faCaretDown : faCaretRight} />
                            </span>
                        )}
                        <FontAwesomeIcon
                            icon={getFileIcon({ fileFormat: isFile ? item.extension : undefined, isExpanded })}
                            className={`text-white mr-2 ${isExpanded && isFolder ? 'text-muted' : ''}`}
                        />
                        <span className={`${isExpanded && isFolder ? 'text-muted' : 'text-white'}`}>{isFile ? item.name : item.directoryName}</span>
                    </div>
                </td>
                <td className="NSTable__tbody__tr__td">
                    {isFile && (
                        <div className={`text-white ${item.type ? 'DocumentLibraryTable__document-type' : ''}`}>
                            {item.type || '-'}
                        </div>
                    )}
                </td>
                <td className="NSTable__tbody__tr__td">
                    <div className="text-white">{isFile ? item.extension || '' : ''}</div>
                </td>
                <td className="NSTable__tbody__tr__td">
                    <div className="text-white">{isFile ? formatDateWithMonth(item.updatedAt) || '' : ''}</div>
                </td>
                <td className="NSTable__tbody__tr__td">
                    <div className="text-white">{isFile ? item.size || '' : ''}</div>
                </td>
                <td className="NSTable__tbody__tr__td text-center DocumentLibraryTable__actions-column">
                    <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} direction="down">
                        <DropdownToggle
                            tag="div"
                            className="cursor--pointer DocumentLibraryTable__options-btn"
                            onClick={e => preventClickPropagation(e, () => setIsOpen(!isOpen))}
                        >
                            <FontAwesomeIcon data-testid="ellipsis-icon" icon={faEllipsisVertical} />
                        </DropdownToggle>
                        <DropdownMenu>
                            {isFolder ? (
                                <>
                                    <DropdownItem
                                        onClick={e => preventClickPropagation(e, () => onEdit(item))}
                                        data-testid="rename-folder-action"
                                        className="text-white DocumentLibraryTable__dropdown-item"
                                    >
                                        <div className="d-flex align-items-center justify-content-between w-100">
                                            <span>Rename folder</span>
                                            <FontAwesomeIcon icon={faPenToSquare} />
                                        </div>
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={e => preventClickPropagation(e, () => onDelete(item))}
                                        data-testid="delete-folder-action"
                                        className="text-white DocumentLibraryTable__dropdown-item"
                                    >
                                        <div className="d-flex align-items-center justify-content-between w-100">
                                            <span>Delete folder</span>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </div>
                                    </DropdownItem>
                                </>
                            ) : (
                                <>
                                    <DropdownItem
                                        onClick={e => preventClickPropagation(e, () => { })}
                                        data-testid="download-action"
                                        className="text-white DocumentLibraryTable__dropdown-item"
                                    >
                                        <div className="d-flex align-items-center justify-content-between w-100">
                                            <span>Download</span>
                                            <FontAwesomeIcon icon={faDownload} />
                                        </div>
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={e => preventClickPropagation(e, () => onSelect(item))}
                                        data-testid="edit-action"
                                        className="text-white DocumentLibraryTable__dropdown-item"
                                    >
                                        <div className="d-flex align-items-center justify-content-between w-100">
                                            <span>Edit</span>
                                            <FontAwesomeIcon icon={faPenToSquare} />
                                        </div>
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={e => preventClickPropagation(e, () => onDelete(item))}
                                        data-testid="delete-action"
                                        className="text-white DocumentLibraryTable__dropdown-item"
                                    >
                                        <div className="d-flex align-items-center justify-content-between w-100">
                                            <span>Delete</span>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </div>
                                    </DropdownItem>
                                </>
                            )}
                        </DropdownMenu>
                    </Dropdown>
                </td>
            </tr>
            {isExpanded && isFolder && item.children?.map(child => (
                <FileRow
                    key={`dir-${child.directoryId}`}
                    item={child}
                    level={level + 1}
                    expanded={expanded}
                    onToggle={onToggle}
                    onSelect={onSelect}
                    onEdit={onEdit}
                    onDelete={onDelete}
                />
            ))}
            {isExpanded && isFolder && item.documents?.map(child => (
                <FileRow
                    key={`file-${child.id}`}
                    item={child}
                    level={level + 1}
                    expanded={expanded}
                    onToggle={onToggle}
                    onSelect={onSelect}
                    onEdit={onEdit}
                    onDelete={onDelete}
                />
            ))}
        </>
    );
};

export default FileRow;
