import React, { useState, useEffect } from 'react';
import NSCellInput from 'bricks/NSCellInput/NSCellInput';
import { numeralFormatterCurrency } from 'ns_libs/formatter';
import {
    currencyColumnKeys, stringColumnKeys, percentColumnKeys,
} from 'views/ProFormaTable/constants';

interface CellInputWithDeferredUpdateProps {
    rowId: number | string;
    columnKey: string;
    value: any;
    isRightAligned?: boolean;
    customClassName?: string;
    disabled?: boolean;
    handleUpdateRow: (rowId: number, dataKey: string, value: any) => void;
}

const CellInputWithDeferredUpdate: React.FC<CellInputWithDeferredUpdateProps> = ({
    rowId,
    columnKey,
    value,
    isRightAligned = false,
    customClassName = '',
    disabled = false,
    handleUpdateRow,
}) => {
    const isCurrencyColumn = currencyColumnKeys.includes(columnKey);
    const isStringColumn = stringColumnKeys.includes(columnKey);
    const isPercentColumn = percentColumnKeys.includes(columnKey);
    const [localValue, setLocalValue] = useState<string>(value || '');
    const cellId = `${rowId}-${columnKey}`;

    let numberFormat = '0,0';
    if (isCurrencyColumn) numberFormat = '$0,0.00';
    if (isPercentColumn) numberFormat = '0,0.00';

    useEffect(() => {
        setLocalValue(value || '');
    }, [value]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLocalValue(e.target.value);
    };

    const handleBlur = () => {
        if (localValue !== value) {
            handleUpdateRow(Number(rowId), columnKey, localValue);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleUpdateRow(Number(rowId), columnKey, localValue);
        } else if (e.key === 'Escape') {
            setLocalValue(value || '');
        }
    };

    return (
        <NSCellInput
            isRightAligned={isRightAligned}
            cellId={cellId}
            value={isCurrencyColumn ? numeralFormatterCurrency(localValue) : localValue}
            onChange={handleChange}
            onBlur={handleBlur}
            onEnter={handleKeyDown}
            placeholder="Enter value"
            customClassName={customClassName}
            disabled={disabled}
            numberFormat={numberFormat}
            type={isStringColumn ? 'text' : 'number'}
            isPercentage={isPercentColumn}
        />
    );
};

export default CellInputWithDeferredUpdate;
