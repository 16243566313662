import { useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';

interface IUploadedProFormasQueryParams {
    organizationId: string;
    dealId: number;
}

export interface IUploadedProForma {
    id: number;
    dealType: string;
    organizationId: string;
    dealId: number;
    createdAt: string;
    updatedAt: string;
    createdBy: string | null;
    updatedBy: string | null;
    fileSize: number | null;
    s3Bucket: string;
    s3FilePath: string;
    s3Url: string;
    unleveredIrr: number | null;
    leveredIrr: number | null;
    limitedPartnerIrr: number | null;
    generalPartnerIrr: number | null;
    untrendedYieldOnCost: number | null;
    trendedYieldOnCost: number | null;
    effectiveGrossRevenue: number | null;
    totalOperatingExpenses: number | null;
    netOperatingIncome: number | null;
    capitalExpenditures: number | null;
    cashFlowFromOps: number | null;
    valuation: number | null;
    acquisitionCosts: number | null;
    hardCosts: number | null;
    softCosts: number | null;
    financingCosts: number | null;
    totalUses: number | null;
    sources: Array<{
        id?: number;
        name: string;
        amount: number;
    }>;
}

const getUploadedProFormas = async ({ organizationId, dealId }: IUploadedProFormasQueryParams): Promise<IUploadedProForma[]> => {
    const response = await axiosInstance.get(`${API_ENDPOINTS.UPLOADED_PRO_FORMA(organizationId, dealId)}`);
    return response.data;
};

export const useGetUploadedProFormasByDeal = ({ organizationId, dealId }: IUploadedProFormasQueryParams) => useQuery({
    queryKey: ['getUploadedProFormas', dealId],
    queryFn: () => getUploadedProFormas({ organizationId, dealId }),
    staleTime: 5000,
    refetchOnMount: true,
});
