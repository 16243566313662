import { IOptionAnyValue } from 'bricks/types';
import { IOperatingPeriodCashFlows } from 'views/ProFormaTable/types';
import { IMonthlyAnalysisPeriod } from './MonthlyAnalysisTable/MonthlyAnalysisTable';

export const getMonthlyAnalysisPeriods = (periodOptions: IOptionAnyValue[], operatingPeriodCashFlows: IOperatingPeriodCashFlows[]) => {
    const monthlyAnalysisPeriods: IMonthlyAnalysisPeriod[] = [];
    periodOptions.map(period => {
        const [year, month] = period.value.split('-');
        const targetCashFlow = (operatingPeriodCashFlows || []).find(
            analysis => analysis.operatingYear === Number(year) && analysis.operatingMonth === Number(month),
        );

        monthlyAnalysisPeriods.push({
            periodName: period.label,
            periodDate: period.value,
            leasedPercentage: targetCashFlow?.leasedPercentage || '—',
            economicOccupancyPercentage: targetCashFlow?.economicOccupancyPercentage || '—',
        });
        return null;
    });

    return monthlyAnalysisPeriods;
};

export const getFirstStabilizedMonth = (monthlyAnalysisPeriods: IMonthlyAnalysisPeriod[], stabilizationPercentage: number) => {
    let firstStabilizedMonth: string | null = null;

    monthlyAnalysisPeriods.map(period => {
        if (period.leasedPercentage !== '—' && Number(period.leasedPercentage) !== 0
        && Number(period.leasedPercentage) >= Number(stabilizationPercentage)) {
            firstStabilizedMonth = period.periodDate;
        }
        return null;
    });

    return firstStabilizedMonth;
};
