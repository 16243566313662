/* eslint-disable function-paren-newline */
/* eslint-disable indent */
import * as Yup from 'yup';
import { IOptionAnyValue } from 'bricks/types';

export const INITIAL_EXPENSES = [
    {
        id: 1, name: 'Capital reserves', annualGrowth: 3.0, amountPerYear: 0,
    },
    {
        id: 2, name: 'Contract services', annualGrowth: 3.0, amountPerYear: 0,
    },
    {
        id: 3, name: 'General and administrative', annualGrowth: 3.0, amountPerYear: 0,
    },
    {
        id: 4, name: 'Insurance', annualGrowth: 3.0, amountPerYear: 0,
    },
    {
        id: 5, name: 'Make ready cost', annualGrowth: 3.0, amountPerYear: 0,
    },
    {
        id: 6, name: 'Management fee', annualGrowth: 3.0, amountPerYear: 0,
    },
    {
        id: 7, name: 'Marketing', annualGrowth: 3.0, amountPerYear: 0,
    },
    {
        id: 8, name: 'Other capital expenditures', annualGrowth: 3.0, amountPerYear: 0,
    },
    {
        id: 9, name: 'Payroll', annualGrowth: 3.0, amountPerYear: 0,
    },
    {
        id: 10, name: 'Property taxes', annualGrowth: 3.0, amountPerYear: 0,
    },
    {
        id: 11, name: 'Repairs and maintenance', annualGrowth: 3.0, amountPerYear: 0,
    },
    {
        id: 12, name: 'Retail leasing cost reserves', annualGrowth: 3.0, amountPerYear: 0,
    },
    {
        id: 13, name: 'Utilities', annualGrowth: 3.0, amountPerYear: 0,
    },
];

export const stepOneValidationSchema = Yup.object().shape({
    landArea: Yup.number().required('Land area is required').min(0.1, 'Land area must be greater than 0'),
    buildings: Yup.number()
        .required('Number of buildings is required')
        .min(1, 'Number of buildings must be greater than 0')
        .integer('Must be a whole number'),
    buildableGrossSquareFootage: Yup.number().required('Built gross SF is required').min(1, 'Built gross SF must be greater than 0'),
    stories: Yup.number().required('Number of stories is required').min(1, 'Number of stories must be greater than 0').integer('Must be a whole number'),
    sellingCosts: Yup.number()
        .required('Selling costs is required')
        .min(1, 'Selling costs cannot be less than 1')
        .max(100, 'Selling costs cannot exceed 100%'),
    projectedCapRateAtSale: Yup.number()
        .required('Projected cap rate at sale is required')
        .min(0.1, 'Projected cap rate at sale cannot be less than 0.1')
        .max(100, 'Projected cap rate at sale cannot exceed 100%'),
    currentMarketCapRate: Yup.number()
        .required('Current market cap rate is required')
        .min(0.1, 'Current market cap rate cannot be less than 0.1')
        .max(100, 'Current market cap rate cannot exceed 100%'),
    structuredParkingSpaces: Yup.number().when(['parking'], ([parking]: IOptionAnyValue[][]) => {
        const parkingArray = parking || [];
        return parkingArray.flat().some(p => p.value === 'structured')
            ? Yup.number()
                .required('Structured parking spaces is required')
                .test('min-value', 'Structured parking spaces cannot be less than 1', value => value === undefined || value === null || value >= 1)
                .integer('Must be a whole number')
            : Yup.number();
    }),
    surfaceParkingSpaces: Yup.number().when(['parking'], ([parking]: IOptionAnyValue[][]) => {
        const parkingArray = parking || [];
        return parkingArray.flat().some(p => p.value === 'surface')
            ? Yup.number()
                .required('Surface parking spaces is required')
                .test('min-value', 'Surface parking spaces cannot be less than 1', value => value === undefined || value === null || value >= 1)
                .integer('Must be a whole number')
            : Yup.number();
    }),
    floorAreaRatio: Yup.number().when(['isFAR'], ([isFAR]) => (isFAR
        ? Yup.number()
            .required('FAR amount is required')
            .test('min-value', 'FAR amount must be greater than 0', value => value === undefined || value === null || value > 0)
        : Yup.number().nullable()),
    ),
    grossBuildableArea: Yup.number().when(['isFAR'], ([isFAR]) => (!isFAR
        ? Yup.number()
            .required('Gross buildable area is required')
            .test('min-value', 'Gross buildable area must be greater than 0', value => value === undefined || value === null || value > 0)
        : Yup.number().nullable()),
    ),
});

export const stepTwoValidationSchema = Yup.object().shape({
    acquisitionCosts: Yup.number().min(1, 'Must be greater than 0').required('Required'),
    hardCosts: Yup.number().min(0.1, 'Must be greater than 0').required('Required'),
    totalHardCosts: Yup.number().min(0.1, 'Must be greater than 0').required('Required'),
    hardCostType: Yup.string().oneOf(['sf', 'units'], 'Invalid cost type').required('Required'),
    softCosts: Yup.number().min(0.1, 'Must be greater than 0').required('Required'),
    totalSoftCosts: Yup.number().min(0.1, 'Must be greater than 0').required('Required'),
    softCostType: Yup.string().oneOf(['sf', 'units'], 'Invalid cost type').required('Required'),
    developerFee: Yup.number().min(0.1, 'Must be greater than 0').max(100, 'Must be less than or equal to 100').required('Required'),
    interestReserve: Yup.number().min(0.1, 'Must be greater than 0').required('Required'),
    sources: Yup.array()
        .of(
            Yup.object().shape({
                sourceTypeId: Yup.string().required('Source type is required'),
                name: Yup.string().required('Source name is required'),
                traunch: Yup.string().required('Traunch is required'),
                amount: Yup.number().required('Amount is required'),
            }),
        )
        .min(1, 'At least one source is required'),
});
