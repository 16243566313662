/* eslint-disable prefer-destructuring */
import React, { useState } from 'react';
import classNames from 'classnames';
import {
    Input, InputGroup, InputGroupAddon, InputGroupText, InputProps, Tooltip,
} from 'reactstrap';
import { FormikProps } from 'formik';
import { isNaN } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import RenderIf from '../../components/RenderIf/RenderIf';
import NSLabel from '../NSLabel/NSLabel';
/*
To use NSInput, you may pass in formikProps or manually pass in each optional of the
    optional props. If you choose not to pass in formikProps, you will need to pass in
    all of the optional props that you would need for the input to function as expected.
If formikProps are passed to NSInput, its handleChange, handleBlur, errors, and others
    will be used in the necessary fields of the input.
Should you choose to pass in formikProps with a complex structure for its values (e.g.
    nested object/array), you may also pass in other optional props to essentially
    'override' the formik values/errors/functions.
*/

export interface IProps extends Omit<InputProps, 'value'> {
    name: string;
    type: InputProps['type'];
    labelClassName?: string;
    label?: string;
    required?: boolean;
    value?: string | number | null;
    onChange?: any;
    onBlur?: any;
    onKeyDown?: any;
    invalid?: boolean;
    showErrorMsg?: boolean | null;
    errorMsg?: string | null;
    formikProps?: FormikProps<any>;
    prependInputAddon?: string | JSX.Element | null;
    appendInputAddon?: string | JSX.Element | null;
    id?: string;
    placeholder?: string;
    disabled?: boolean;
    inputClassName?: string;
    inputGroupClassName?: string;
    dataTestId?: string;
    min?: number | string;
    max?: number | string;
    maxLength?: number | string;
    step?: number | string;
    customClassName?: string;
    autoFocus?: boolean;
    selectOnFocus?: boolean;
    hideCancelIcon?: boolean;
    includeRange?: boolean;
    rangeInputClassName?: string;
    rows?: number;
    tooltipMsg?: string;
    isRightAligned?: boolean;
}

const NSInput = ({
    labelClassName = '',
    label = '',
    required = false,
    name,
    type,
    value = null,
    onChange = null,
    onBlur = null,
    onKeyDown = null,
    hideCancelIcon = false,
    invalid,
    showErrorMsg = null,
    errorMsg = null,
    formikProps,
    prependInputAddon = '',
    appendInputAddon = '',
    id = '',
    placeholder = '',
    disabled = false,
    inputClassName = '',
    dataTestId,
    min,
    max,
    maxLength,
    step,
    customClassName,
    autoFocus = false,
    selectOnFocus = false,
    inputGroupClassName,
    includeRange = false,
    rangeInputClassName,
    rows,
    tooltipMsg,
    isRightAligned = false,
}: IProps) => {
    const handleFocus = (event: any) => {
        if (type === 'number') {
            event.target.addEventListener(
                'wheel',
                (e: any) => {
                    e.preventDefault();
                },
                { passive: false },
            );
        }
        if (selectOnFocus) {
            event.target.select();
        }
    };

    const shouldShowErrorMessage = showErrorMsg !== null ? showErrorMsg : (formikProps?.errors[name] && formikProps?.touched[name]) || false;

    let errorMsgText: string = 'This field is required';
    if (errorMsg !== null) {
        errorMsgText = errorMsg!;
    } else if (typeof formikProps?.errors[name] === 'string') {
        errorMsgText = formikProps?.errors[name] as string;
    }

    const handleChangeFormikNumberInput = (event: React.ChangeEvent<HTMLInputElement>, field: string, fProps?: FormikProps<any>) => {
        const amount = event.target.valueAsNumber;
        if (!isNaN(amount) && fProps) {
            fProps.setFieldValue(field, amount);
        } else if (fProps) {
            fProps.setFieldValue(field, null);
        }
    };

    const handleChangeNumber = (
        event: React.ChangeEvent<HTMLInputElement>,
        field: string,
        fProps?: FormikProps<any>,
    ) => (onChange ? onChange(event) : handleChangeFormikNumberInput(event, field, fProps));

    const handleBlur = (event: any) => {
        if (onBlur !== null) {
            onBlur(event);
        }
        if (formikProps?.handleBlur) {
            formikProps.handleBlur(event);
        }
    };

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    return (
        <>
            <RenderIf isTrue={label}>
                <div className={`${tooltipMsg ? `d-flex align-items-baseline ${labelClassName}` : ''}`}>
                    <NSLabel for={name} className={`${!tooltipMsg ? labelClassName : ''} text-dark font-weight-normal`}>
                        {label}
                        <RenderIf isTrue={required}>
                            <span className="text-danger"> *</span>
                        </RenderIf>
                    </NSLabel>
                    <RenderIf isTrue={!!tooltipMsg}>
                        <div>
                            <FontAwesomeIcon className="px-1 text-white" id={`${id || name}-tooltip`} icon={faCircleInfo} />
                            <Tooltip target={`${id || name}-tooltip`} isOpen={tooltipOpen} toggle={toggleTooltip} placement="top">
                                {tooltipMsg}
                            </Tooltip>
                        </div>
                    </RenderIf>
                </div>
            </RenderIf>

            <InputGroup className={inputClassName}>
                <RenderIf isTrue={!!prependInputAddon}>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className={`py-0 ${inputGroupClassName}`}>{prependInputAddon}</InputGroupText>
                    </InputGroupAddon>
                </RenderIf>

                <RenderIf isTrue={type === 'number'}>
                    <Input
                        type="number"
                        id={id || name}
                        name={name}
                        placeholder={placeholder || '0'}
                        // Disabling since it seems we have no other simple and clean way to do this
                        // eslint-disable-next-line no-nested-ternary
                        value={value !== null ? value : typeof formikProps?.values[name] === 'number' ? formikProps?.values[name] : ''}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeNumber(event, name, formikProps)}
                        onBlur={handleBlur}
                        disabled={disabled}
                        data-testid={dataTestId}
                        min={min}
                        max={max}
                        step={step}
                        invalid={invalid || false}
                        className={classNames([`NSInput NSInput--type-number ${customClassName}`, { 'text-right': isRightAligned }])}
                        onFocus={handleFocus}
                        autoFocus={autoFocus}
                        onKeyDown={onKeyDown}
                    />
                </RenderIf>

                <RenderIf isTrue={type !== 'number'}>
                    <Input
                        id={id || name}
                        name={name}
                        type={type}
                        value={value !== null ? value : formikProps?.values[name] || ''}
                        onChange={onChange !== null ? onChange : formikProps?.handleChange || null}
                        onBlur={handleBlur}
                        invalid={Boolean(invalid !== null ? invalid : (formikProps?.errors[name] && formikProps?.touched[name]) || false)}
                        placeholder={placeholder}
                        disabled={disabled}
                        data-testid={dataTestId}
                        min={min}
                        max={max}
                        maxLength={maxLength as number}
                        step={step}
                        rows={type === 'textarea' ? rows : undefined}
                        className={classNames([
                            'NSInput',
                            {
                                'NSInput--hide-cancel-icon': hideCancelIcon,
                                [customClassName || '']: customClassName,
                            },
                            { 'text-right': isRightAligned },
                        ])}
                        onFocus={handleFocus}
                        autoFocus={autoFocus}
                        onKeyDown={onKeyDown}
                    />
                </RenderIf>

                <RenderIf isTrue={!!appendInputAddon}>
                    <InputGroupAddon addonType="append">
                        <InputGroupText className={`py-0 ${inputGroupClassName}`}>{appendInputAddon}</InputGroupText>
                    </InputGroupAddon>
                </RenderIf>
            </InputGroup>

            <RenderIf isTrue={type === 'number' && includeRange}>
                <Input
                    type="range"
                    id={`${id || name}-range`}
                    name={`${name}-range`}
                    // eslint-disable-next-line no-nested-ternary
                    value={value !== null ? value : typeof formikProps?.values[name] === 'number' ? formikProps?.values[name] : ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeNumber(event, name, formikProps)}
                    onBlur={handleBlur}
                    disabled={disabled}
                    data-testid={`${dataTestId}-range`}
                    min={min}
                    max={max}
                    step={step}
                    invalid={invalid || false}
                    onFocus={handleFocus}
                    className={`NSInput NSInput--type-number ${rangeInputClassName}`}
                    onKeyDown={onKeyDown}
                />
            </RenderIf>

            <RenderIf isTrue={shouldShowErrorMessage && errorMsgText}>
                <p className="text-danger small mt-1 mb-0">{errorMsgText as string}</p>
            </RenderIf>
        </>
    );
};

export default NSInput;
