export const userClasses = {
    CLASS_TURK: 1,
    CLASS_SUPER_ADMIN: 6,
    CLASS_GLOBAL_VENDOR_USER: 9,
    CLASS_CONSTRUCTION_USER: 10,
    CLASS_UNVERIFIED_VENDOR: 11,
    CLASS_CUSTOM: 12,
};

export const BUTTON_DISABLED_BY_AUTH_TOOLTIP = 'Your role does not permit use of this feature. Contact your administrator for assistance.';
