import React from 'react';
import { NSButton } from 'bricks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import {
    ActiveSections, IReportBuilderReport, NEW_REPORT_LABELS, ReportTypes,
} from 'views/ReportBuilder/constants';
import { useGetReports } from 'views/Reports/hooks';
import { IReport } from 'views/Reports/types';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { getReportTypeFromLabel, getReportTypeName } from 'views/ReportBuilder/helpers';
import NewReportButton from './NewReportButton/NewReportButton';

export interface IStartNewReportFormProps {
    orgId: string;
    setActiveSection: React.Dispatch<React.SetStateAction<string>>;
    newReports: Array<IReportBuilderReport>;
    setNewReports: React.Dispatch<React.SetStateAction<IReportBuilderReport[]>>;
}

const StartNewReportForm = ({
    orgId, setActiveSection, newReports, setNewReports,
}: IStartNewReportFormProps) => {
    const { data: reportData } = useGetReports({ pageSize: 30, currentPage: 1, orgId });
    const handleSavedReportSelection = (report: IReport) => {
        setNewReports([
            ...newReports,
            {
                savedReportId: report.id,
                reportType: ReportTypes.SAVED_REPORT,
                savedReportName: report.name,
                savedReportType: getReportTypeName(report.type),
                isEditing: false,
            },
        ]);
    };
    const handleNonSavedReportSelection = (reportType: ReportTypes) => {
        setNewReports([...newReports, { reportType, isEditing: true }]);
    };

    const setToInitialState = () => {
        setActiveSection(ActiveSections.INITIAL);
        setNewReports([]);
    };

    const isSavedReportInNewReports = (reportId: number) => newReports.some(report => report.savedReportId === reportId);

    const isReportTypeInNewReports = (reportType: ReportTypes) => newReports.some(report => report.reportType === reportType);

    return (
        <>
            <div className="d-flex align-items-center mb-1">
                <h4 role="presentation" className="text-primary m-0 p-0" style={{ cursor: 'pointer' }} onClick={() => setToInitialState()}>
                    Create
                </h4>
                <FontAwesomeIcon icon={faAngleRight} className="mx-1" size="xs" />
                <h4 className="text-white m-0 p-0">Start new report</h4>
            </div>
            <p className="m-0 mt-1 p-0">Select from several report types to add to your combined report</p>
            <hr />
            {Object.entries(NEW_REPORT_LABELS).map(([key, value]) => {
                if (!isReportTypeInNewReports(getReportTypeFromLabel(value))) {
                    return <NewReportButton key={key} reportName={value} handleReportSelection={handleNonSavedReportSelection} />;
                }
                return null;
            })}
            <hr />
            {reportData?.items.map(
                (report: IReport) => !isSavedReportInNewReports(report.id) && (
                    <NSButton
                        key={report.id}
                        callback={() => handleSavedReportSelection(report)}
                        className="mt-2 py-2 w-100"
                        color="secondary"
                        outline={false}
                    >
                        <div className="text-white d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon icon={faUser} size="sm" />
                                <h5 className="ml-2 my-0">{report.name}</h5>
                            </div>
                            <FontAwesomeIcon icon={faPlus} size="lg" />
                        </div>
                    </NSButton>
                ),
            )}
        </>
    );
};

export default StartNewReportForm;
