import RenderIf from 'components/RenderIf/RenderIf';
import React, { useState, useEffect } from 'react';
import 'css/partials/_bricks.NSCellInput.scss';
import NSInput from 'bricks/NSInput/NSInput';
import { InputType } from 'reactstrap/es/Input';
import classNames from 'classnames';
import {
    isFormattedCurrency, numeralFormatter, numeralFormatterCurrency, parseCurrencyToNumber,
} from 'ns_libs/formatter';

interface NSCellInputProps {
    value: any;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
    type?: InputType;
    placeholder?: string;
    customClassName?: string;
    labelClassName?: string;
    onEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    setCellId?: (id: string | undefined) => void;
    cellId?: string;
    isRightAligned?: boolean;
    disabled?: boolean;
    isPercentage?: boolean;
    numberFormat?: string;
}

const NSCellInput = ({
    value,
    onChange,
    onBlur,
    type,
    placeholder,
    customClassName,
    labelClassName,
    onEnter,
    setCellId,
    cellId,
    isRightAligned = false,
    disabled = false,
    isPercentage = false,
    numberFormat = '$0,0.00',
}: NSCellInputProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editingValue, setEditingValue] = useState<string>(value?.toString() || '');
    const isNumberType = type === 'number';

    useEffect(() => {
        if (!isEditing) {
            setEditingValue(value?.toString() || '');
        }
    }, [value, isEditing]);

    const handleMouseEnter = () => {
        setIsEditing(true);
        if (setCellId) setCellId(cellId!);
    };

    const handleMouseLeave = () => {
        setIsEditing(false);
        if (setCellId) setCellId(undefined);
        onBlur({ target: { value: editingValue } } as React.FocusEvent<HTMLInputElement>);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = e.target.value;
        if (isNumberType) newValue = newValue.replace(/[A-Za-z]/g, '');
        setEditingValue(newValue);
        onChange({ target: { value: newValue } } as React.ChangeEvent<HTMLInputElement>);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (onEnter) {
            onEnter(e);
        }
    };

    const isCurrencyNumber = isNumberType && isFormattedCurrency(value);
    let formattedDisplayValue = '';

    if (isPercentage) {
        formattedDisplayValue = `${numeralFormatter(parseCurrencyToNumber(value), '0.00')}%`;
    } else if (isCurrencyNumber) {
        formattedDisplayValue = numeralFormatterCurrency(parseCurrencyToNumber(value), numberFormat);
    } else {
        formattedDisplayValue = value || placeholder;
    }

    return (
        <div
            role="presentation"
            className={`NSCellInput text-primary align-middle ${customClassName}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <RenderIf isTrue={isEditing && !disabled}>
                <NSInput
                    id="NSCellInput"
                    type={type || 'text'}
                    name="NSCellInput"
                    inputClassName={`NSCellInput__input text-primary ${isRightAligned ? 'ml-0' : ''}`}
                    value={editingValue}
                    onChange={handleInputChange}
                    onBlur={() => {
                        setIsEditing(false);
                        onBlur({ target: { value: editingValue } } as React.FocusEvent<HTMLInputElement>);
                    }}
                    placeholder={placeholder}
                    onKeyDown={handleKeyDown}
                    isRightAligned={isRightAligned}
                />
            </RenderIf>
            <RenderIf isTrue={!isEditing || disabled}>
                <label
                    htmlFor="NSCellInput"
                    className={classNames(`${labelClassName} text-wrap font-weight-normal align-middle my-0 ml-1`, {
                        'text-right': isRightAligned,
                        NSCellInput__label: !isRightAligned,
                    })}
                >
                    {formattedDisplayValue}
                </label>
            </RenderIf>
        </div>
    );
};

export default NSCellInput;
