import { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import useS3Upload from './useS3Upload';
import useUploadUrls from './useUploadUrls';

interface UseAwsUploadProps {
    organizationCorrelationId: string;
    feature: string
    onError?: (message: string) => void;
}

// Descriptive error handling messages, this will tell what step hook failed
const ERROR_ACTION_CALL_MESSAGE = 'Please try again or contact customer support if the problem persists';
const ERROR_FAILED_PRESIGNED_URL = `Failed to obtain AWS presigned upload URL. ${ERROR_ACTION_CALL_MESSAGE}`;
const ERROR_FAILED_FILE_UPLOAD = `Failed to upload to AWS presigned upload URL. ${ERROR_ACTION_CALL_MESSAGE}`;

const useAwsUploadFiles = ({
    organizationCorrelationId, feature, onError,
}: UseAwsUploadProps) => {
    const [selectedHTML5FileToUpload, setSelectedHTML5FileToUpload] = useState<{ id: string, file: File }[]>([]);
    const [isReadyToUpload, setIsReadyToUpload] = useState(false);
    const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
    const [awsUploadedFiles, setAwsUploadedFiles] = useState<{id: string, name: string, objectKey: string, bucketName: string}[]>([]);

    const { mutateAsync: uploadToS3Bucket } = useS3Upload({
        onError: (error: any) => {
            onError?.(ERROR_FAILED_FILE_UPLOAD);
        },
        onSuccess: (res: any) => {
            const url = res.config.url!;
            const bucketName = url.split('//')[1].split('.')[0];
            const objectKey = res.config.data.get('key');
            const fileName = res?.config?.data.get('file').name;
            const targetFile = selectedHTML5FileToUpload.find(file => file.file.name === fileName);
            if (targetFile) {
                setAwsUploadedFiles(prevFiles => [...prevFiles, {
                    id: targetFile.id,
                    name: fileName,
                    objectKey,
                    bucketName,
                }]);
            }
        },
    });

    const uploadUrlResults = useUploadUrls(
        organizationCorrelationId,
        filesToUpload,
        false,
        feature,
        {
            enabled: Boolean(filesToUpload?.length) && isReadyToUpload,
            onError: (error: AxiosError) => onError?.(ERROR_FAILED_PRESIGNED_URL),
            onSuccess: async (res : any) => {
                const targetFile = filesToUpload.find(file => file.name === res.fileName);
                if (targetFile) {
                    uploadToS3Bucket({
                        urlData: res.url,
                        file: targetFile,
                    });
                    setFilesToUpload(prev => prev.filter(f => f !== targetFile));
                }
            },
        },
    );

    const isLoading = uploadUrlResults.some(result => result.isLoading);

    const handleUpload = () => {
        setIsReadyToUpload(true);
        const files = selectedHTML5FileToUpload.map(item => item.file);
        setFilesToUpload(files);
    };

    const clearAttachmentsStates = () => {
        setSelectedHTML5FileToUpload([]);
        setFilesToUpload([]);
        setIsReadyToUpload(false);
    };

    useEffect(() => () => {
        clearAttachmentsStates();
    }, []);

    return {
        selectedHTML5FileToUpload,
        setSelectedHTML5FileToUpload,
        filesToUpload,
        setFilesToUpload,
        isReadyToUpload,
        setIsReadyToUpload,
        clearAttachmentsStates,
        isLoading,
        handleUpload,
        awsUploadedFiles,
        setAwsUploadedFiles,
    };
};

export default useAwsUploadFiles;
