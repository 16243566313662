import {
    useState, useMemo, useRef, useEffect,
} from 'react';
import { INITIAL_EXPENSES } from 'views/ProFormaWizard/constants';

export interface IExpense {
    id: number;
    name: string;
    annualGrowth: number;
    amountPerYear: number;
}
export const useExpenses = (initialExpenses: IExpense[] = []) => {
    const [expenses, setExpenses] = useState<IExpense[]>(() => {
        if (initialExpenses.length > 0) {
            return initialExpenses.map(exp => ({
                ...exp,
                annualGrowth: Number(exp.annualGrowth) * 100,
                amountPerYear: Number(exp.amountPerYear),
            }));
        }
        return INITIAL_EXPENSES;
    });

    const lastIdRef = useRef(Math.max(
        ...expenses.map(exp => exp.id),
        ...INITIAL_EXPENSES.map(exp => exp.id),
    ));

    // Update expenses when initialExpenses changes and is not empty
    useEffect(() => {
        if (initialExpenses.length > 0) {
            setExpenses(initialExpenses.map(exp => ({
                ...exp,
                annualGrowth: Number(exp.annualGrowth) * 100,
                amountPerYear: Number(exp.amountPerYear),
            })));
        }
    }, [initialExpenses]);

    // Create a new expense row
    const createExpense = () => {
        const newExpense: IExpense = {
            id: ++lastIdRef.current,
            name: '',
            annualGrowth: 3,
            amountPerYear: 0,
        };
        setExpenses([...expenses, newExpense]);
    };

    // Update an existing expense row
    const updateExpense = (id: number, field: keyof IExpense, value: string | number) => {
        const updatedExpenses = expenses.map(exp => {
            if (exp.id === id) {
                const newValue = field === 'annualGrowth'
                    ? Math.min(100, Math.max(0, Number(value)))
                    : value;
                return { ...exp, [field]: newValue };
            }
            return exp;
        });
        setExpenses(updatedExpenses);
    };

    // Delete an expense row
    const deleteExpense = (id: number) => {
        setExpenses(expenses.filter(exp => exp.id !== id));
    };

    const totalAmount = useMemo(() => expenses.reduce((total, exp) => total + exp.amountPerYear, 0), [expenses]);

    return {
        expenses,
        createExpense,
        updateExpense,
        deleteExpense,
        totalAmount,
        setExpenses,
    };
};
