import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { useMutation } from '@tanstack/react-query';
import {
    ICreateDealGroupResponse, IErrorResponse, IRenameDealGroupRequest, IRenameDealGroupResponse,
} from '../types';

const renameDealGroup = async ({ postData, orgId, dealGroupId }: IRenameDealGroupRequest): Promise<IRenameDealGroupResponse> => {
    try {
        const response = await axiosInstance.put<ICreateDealGroupResponse>(API_ENDPOINTS.DEALS_GROUPS_BY_ID(orgId, dealGroupId), postData);
        return response.data;
    } catch (error: any) {
        if (error.response?.status !== 500) {
            const errorData: IErrorResponse = error.response.data;
            throw new Error(errorData.detail);
        }
        throw new Error('An unexpected error occurred. Please try again.');
    }
};

export const useRenameDealGroup = () => useMutation<IRenameDealGroupResponse, Error, IRenameDealGroupRequest>({
    mutationFn: renameDealGroup,
});
