import React from 'react';
import Dropzone, { defaultClassNames } from 'react-dropzone-uploader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/pro-solid-svg-icons';
import { Input, Label } from 'reactstrap';
import './DropzoneCard.scss';

export const DROP_LOCATION = {
    CARD: 'cardview',
    MODAL: 'modalview',
};

export interface IDropzoneCard {
    getFilesFromEvent: (event: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>, dropLocation: string) => Promise<File[]>;
    accept?: string;
}

const DropzoneCard = ({ getFilesFromEvent, accept = '*' }: IDropzoneCard) => (
    <Dropzone
        accept={accept}
        InputComponent={props => (
            <div className="justify-content-center align-items-center my-3">
                <div className="mb-2 text-center">
                    <FontAwesomeIcon icon={faUpload} className="secondary text-white" size="2x" />
                </div>
                <span>Drag and drop folders/files or</span>
                <Label className="ml-1 mb-0">
                    <u className="text-primary cursor-pointer h5">Browse</u>
                    <Input
                        className="d-none"
                        type="file"
                        accept={accept}
                        multiple
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            getFilesFromEvent(event, DROP_LOCATION.CARD).then((chosenFiles: File[]) => {
                                props.onFiles(chosenFiles);
                            });
                        }}
                    />
                </Label>
            </div>
        )}
        PreviewComponent={() => <span />}
        getFilesFromEvent={(event: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>) => getFilesFromEvent(event, DROP_LOCATION.CARD)}
        classNames={{
            ...defaultClassNames,
            dropzone: `${defaultClassNames.dropzone} DropzoneCard card d-flex justify-content-center align-items-center w-100 h-100`,
            dropzoneActive: 'DropzoneCard__active',
        }}
    />
);

export default DropzoneCard;
