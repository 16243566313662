import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { IDealDirectory } from 'views/DocumentLibrary/types';

interface IBreadcrumbNavigationProps {
    breadcrumb: IDealDirectory[];
    handleBreadcrumbClick: (index: number) => void;
}

const BreadcrumbNavigation = ({ breadcrumb, handleBreadcrumbClick }: IBreadcrumbNavigationProps) => (
    <Breadcrumb
        cssModule={{
            breadcrumb: 'breadcrumb d-flex align-items-center m-0 p-0 mb-2',
        }}
    >
        <BreadcrumbItem onClick={() => handleBreadcrumbClick(0)} className="BreadcrumbNavigation__primary-color cursor--pointer">
            Home
        </BreadcrumbItem>

        {breadcrumb.map((folder, index) => {
            const isLast = folder === breadcrumb[breadcrumb.length - 1];

            return (
                <React.Fragment key={folder.directoryId}>
                    <FontAwesomeIcon icon={faChevronRight} className="px-2 font-10" />
                    <BreadcrumbItem
                        onClick={!isLast ? () => handleBreadcrumbClick(index + 1) : undefined}
                        className={classNames({
                            'cursor--pointer': !isLast,
                            'BreadcrumbNavigation__primary-color': !isLast,
                        })}
                    >
                        {folder.directoryName}
                    </BreadcrumbItem>
                </React.Fragment>
            );
        })}
    </Breadcrumb>
);

export default BreadcrumbNavigation;
