import { useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';

export interface IResponse {
    id: number;
    orgId: string;
    dealId: number;
    reportName: string;
    reportBuilderReports: any;
}

const getReportBuilderTemplates = async (dealId: number): Promise<Array<IResponse>> => {
    const response = await axiosInstance.get(API_ENDPOINTS.REPORT_BUILDER_TEMPLATE(dealId));
    return response.data;
};

export const useGetReportBuilderTemplates = (dealId: number) => useQuery({
    queryKey: ['getReportBuilderTemplates', dealId],
    queryFn: () => getReportBuilderTemplates(dealId),
    staleTime: 5000,
    refetchOnMount: true,
});

export default useGetReportBuilderTemplates;
