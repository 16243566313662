import { NSCard, NSLabel } from 'bricks';
import NSInputNumberStepper from 'bricks/NSInputNumberStepper/NSInputNumberStepper';
import React, { useState, useEffect } from 'react';
import './ProFormaAssumptions.scss';
import { IAssumptions } from 'views/ProFormaTable/types';
import { useProFormaSocketContext } from '../../socketContext/ProFormaSocketProvider';

const ProFormaAssumptions = () => {
    const { handleUpdateProFormaAssumptions } = useProFormaSocketContext();
    const [marketCapRate, setMarketCapRate] = useState<number>(0);
    const [projectedCapRate, setProjectedCapRate] = useState<number>(0);
    const [sellingCosts, setSellingCosts] = useState<number>(0);
    const [saleMonth, setSaleMonth] = useState<number>(0);

    const { disposition } = useProFormaSocketContext();

    useEffect(() => {
        if (disposition) {
            setMarketCapRate((disposition.marketCapitalizationRate?.current) * 100);
            setProjectedCapRate((disposition.marketCapitalizationRate?.onSale) * 100);
            setSellingCosts((disposition.sellingCostsPercent) * 100);
            setSaleMonth(disposition.saleMonth || 0);
        }
    }, [disposition]);

    const labelClassNameNoWrap = 'pl-0 col-4 my-auto font-weight-normal text-dark text-nowrap';

    const renderSaleMonthDisplay = () => (saleMonth === 0 ? '-' : `${saleMonth}`);

    const updateAssumptions = (value: number, field: keyof IAssumptions) => {
        handleUpdateProFormaAssumptions({
            [field]: value / 100,
        });
    };

    return (
        <div className="d-flex justify-content-end pb-3">
            <div className="ProFormaAssumptions__inputs-container w-50 pl-3">
                <div className="d-flex align-items-center mt-2">
                    <NSLabel className={labelClassNameNoWrap}>Current market cap rate</NSLabel>
                    <NSInputNumberStepper
                        max={100}
                        percentage
                        name="marketCapRate"
                        value={marketCapRate || 0}
                        onChange={(value: number) => {
                            setMarketCapRate(value);
                            updateAssumptions(value, 'currentMarketCapRate');
                        }}
                        min={0}
                        step={0.1}
                        inputGroupClassName="ProFormaAssumptions__input-width"
                    />
                </div>

                <div className="d-flex align-items-center mt-2">
                    <NSLabel className={labelClassNameNoWrap}>Projected cap rate at sale</NSLabel>
                    <NSInputNumberStepper
                        max={100}
                        percentage
                        name="projectedCapRate"
                        value={projectedCapRate || 0}
                        onChange={(value: number) => {
                            setProjectedCapRate(value);
                            updateAssumptions(value, 'projectedCapRateAtSale');
                        }}
                        min={0}
                        step={0.1}
                        inputGroupClassName="ProFormaAssumptions__input-width"
                    />
                </div>

                <div className="d-flex align-items-center mt-2">
                    <NSLabel className={labelClassNameNoWrap}>Selling costs</NSLabel>
                    <NSInputNumberStepper
                        max={100}
                        percentage
                        name="sellingCosts"
                        value={sellingCosts || 0}
                        onChange={(value: number) => {
                            setSellingCosts(value);
                            updateAssumptions(value, 'sellingCosts');
                        }}
                        min={0}
                        step={0.1}
                        inputGroupClassName="ProFormaAssumptions__input-width"
                    />
                </div>
            </div>
            <div className="w-50">
                <NSCard className="NSCard--level-4 mr-3 mt-2">
                    <div className="OperatingInformation_stabilized-month-container flex-row justify-content-between align-items-center p-2">
                        <div className="text-muted">Sale month</div>
                        <div className="text-white">
                            {renderSaleMonthDisplay()}
                        </div>
                    </div>
                </NSCard>
            </div>
        </div>
    );
};

export default ProFormaAssumptions;
