import { NSLabel } from 'bricks';
import React from 'react';
import { FORM_LABEL_CLASS_NAME } from 'views/ProFormaTable/constants';
import NSInputNumberStepper from 'bricks/NSInputNumberStepper/NSInputNumberStepper';

export interface OperatingInfoValues {
    rentalGrowthPercentage: number;
    occupancyUponStabilizationPercentage: number;
    percentPreLeased: number;
    leaseUpPace: number;
    lengthOfLease: number;
    initialFreeRent: number;
    stabilizedFreeRent: number;
    vacancy: number;
    creditLoss: number;
}

interface OperatingInfoProps {
    onChange: (key: keyof OperatingInfoValues, value: number | Date | null) => void;
    values: OperatingInfoValues;
}

const OperatingInfo = ({ values, onChange }: OperatingInfoProps) => (
    <>
        <div className="ProFormaWizardStepOne__title">Operating information</div>
        <div className="ProFormaWizardStepOne__subtitle">Information about this investment's incoming revenue</div>
        <div className="d-flex align-items-center mt-2 w-50">
            <NSLabel className={FORM_LABEL_CLASS_NAME}>Occupancy upon stabilization</NSLabel>
            <NSInputNumberStepper
                name="occupancyUponStabilizationPercentage"
                value={values.occupancyUponStabilizationPercentage}
                onChange={(count: number) => onChange('occupancyUponStabilizationPercentage', count)}
                min={0}
                step={1}
                max={100}
                inputGroupClassName="ProFormaWizardStepOne__input-width"
                percentage
            />
        </div>
        <div className="d-flex align-items-center mt-2 w-50">
            <NSLabel className={FORM_LABEL_CLASS_NAME}>Rental growth</NSLabel>
            <NSInputNumberStepper
                name="rentalGrowthPercentage"
                value={Number(values.rentalGrowthPercentage)}
                onChange={(count: number) => onChange('rentalGrowthPercentage', count)}
                min={0.1}
                step={0.1}
                inputGroupClassName="ProFormaWizardStepOne__input-width"
            />
        </div>
        <div className="d-flex align-items-center mt-2 w-50">
            <NSLabel className={FORM_LABEL_CLASS_NAME}>% pre-leased</NSLabel>
            <NSInputNumberStepper
                max={100}
                percentage
                name="percentPreLeased"
                value={values.percentPreLeased}
                onChange={(count: number) => onChange('percentPreLeased', count)}
                min={1}
                step={1}
                inputGroupClassName="ProFormaWizardStepOne__input-width"
            />
        </div>
        <div className="d-flex align-items-center mt-2 w-50">
            <NSLabel className={FORM_LABEL_CLASS_NAME}>Lease-up pace (units/month)</NSLabel>
            <NSInputNumberStepper
                name="leaseUpPace"
                value={values.leaseUpPace}
                onChange={(count: number) => onChange('leaseUpPace', count)}
                min={1}
                step={1}
                inputGroupClassName="ProFormaWizardStepOne__input-width"
            />
        </div>
        <div className="d-flex align-items-center mt-2 w-50">
            <NSLabel className={FORM_LABEL_CLASS_NAME}>Length of lease (months)</NSLabel>
            <NSInputNumberStepper
                name="lengthOfLease"
                value={values.lengthOfLease}
                onChange={(count: number) => onChange('lengthOfLease', count)}
                min={1}
                step={1}
                inputGroupClassName="ProFormaWizardStepOne__input-width"
            />
        </div>
        <div className="d-flex align-items-center mt-2 w-50">
            <NSLabel className={FORM_LABEL_CLASS_NAME}>Initial free rent</NSLabel>
            <NSInputNumberStepper
                name="initialFreeRent"
                value={values.initialFreeRent}
                onChange={(count: number) => onChange('initialFreeRent', count)}
                min={1}
                step={1}
                inputGroupClassName="ProFormaWizardStepOne__input-width"
            />
        </div>
        <div className="d-flex align-items-center mt-2 w-50">
            <NSLabel className={FORM_LABEL_CLASS_NAME}>Stabilized free rent</NSLabel>
            <NSInputNumberStepper
                name="stabilizedFreeRent"
                value={values.stabilizedFreeRent}
                onChange={(count: number) => onChange('stabilizedFreeRent', count)}
                min={1}
                step={1}
                inputGroupClassName="ProFormaWizardStepOne__input-width"
            />
        </div>
        <div className="d-flex align-items-center mt-2 w-50">
            <NSLabel className={FORM_LABEL_CLASS_NAME}>General vacancy</NSLabel>
            <NSInputNumberStepper
                percentage
                max={100}
                name="vacancy"
                value={values.vacancy}
                onChange={(count: number) => onChange('vacancy', count)}
                min={1}
                step={1}
                inputGroupClassName="ProFormaWizardStepOne__input-width"
            />
        </div>
        <div className="d-flex align-items-center mt-2 w-50">
            <NSLabel className={FORM_LABEL_CLASS_NAME}>Credit loss</NSLabel>
            <NSInputNumberStepper
                percentage
                max={100}
                name="creditLoss"
                value={values.creditLoss}
                onChange={(count: number) => onChange('creditLoss', count)}
                min={1}
                step={1}
                inputGroupClassName="ProFormaWizardStepOne__input-width"
            />
        </div>
    </>
);

export default OperatingInfo;
