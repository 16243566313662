import { NEW_REPORT_LABELS, ReportTypes, savedReportTypeMap } from './constants';

export const getReportTypeFromLabel = (label: string): ReportTypes => {
    const labelToReportTypeMap: Record<string, ReportTypes> = Object.fromEntries(
        Object.entries(NEW_REPORT_LABELS).map(([key, value]) => [value, ReportTypes[key as keyof typeof ReportTypes]]),
    );

    return labelToReportTypeMap[label];
};

export const formatDealHeader = (header: string): string => {
    // Replace camelCase with spaces and sentence case
    const formatted = header.replace(/([A-Z])/g, ' $1').trim(); // Add spaces before capital letters
    return formatted.charAt(0).toUpperCase() + formatted.slice(1).toLowerCase(); // Capitalize the first letter, lowercase the rest
};

export function getReportTypeName(reportType: number): string {
    return savedReportTypeMap[reportType] || 'Unknown report type';
}
