import axios, { AxiosResponse, AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { S3PresignedUrlData } from 'types/s3Upload';

interface S3UploadParams {
    urlData: S3PresignedUrlData;
    file: File;
}

const uploadAsset = async ({ urlData, file }: S3UploadParams): Promise<AxiosResponse<Record<string, unknown>>> => {
    const formData = new FormData();

    Object.entries(urlData.fields).forEach(([key, value]) => {
        if (value) formData.append(key, value as string);
    });

    formData.append('file', file);

    const response = await axios.post(urlData.url, formData);
    return response;
};

export const useS3Upload
= <TError = AxiosError, TContext = unknown>(
    options?: UseMutationOptions<AxiosResponse<Record<string, unknown>>, TError, S3UploadParams, TContext>,
) => useMutation({
        mutationFn: uploadAsset,
        ...options,
    });

export default useS3Upload;
