import { stringify } from 'qs';
import { useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { IReportsQueryParams, IReportsResponse } from '../types';

export const getReportsQueryKey = 'getReports';

const getReports = async ({
    pageSize, currentPage, orgId, configured_filters, sorting,
}: IReportsQueryParams): Promise<IReportsResponse> => {
    const offset = (currentPage - 1) * pageSize;
    const response = await axiosInstance.get(`${API_ENDPOINTS.ORG_REPORTS(orgId)}`, {
        params: {
            offset,
            page_size: pageSize,
            configured_filters: JSON.stringify(configured_filters),
            ...sorting,
        },
        paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' }),
    });
    return response.data;
};

export const useGetReports = ({
    pageSize, currentPage, orgId, sorting, configured_filters,
}: IReportsQueryParams) => useQuery({
    queryKey: [getReportsQueryKey, orgId, currentPage, pageSize, sorting, configured_filters],
    queryFn: () => getReports({
        pageSize, currentPage, orgId, sorting, configured_filters,
    }),
    staleTime: 5000,
    refetchOnMount: true,
});

export default useGetReports;
