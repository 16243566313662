import React, { useEffect, useState } from 'react';
import { NSSelect } from 'bricks';
import { customStyles } from 'bricks/NSSelect/NSSelect';
import { IOptionAnyValue } from 'bricks/types';
import classNames from 'classnames';
import { isValidDate, isValidDateString } from 'ns_libs/formatter';
import { generateDateRange } from 'views/ProFormaTable/helpers';
import { PlaceholderProps, components } from 'react-select';
import { format } from 'date-fns';

export interface IPeriodsSelectProps {
    proFormaDateRange: string[];
    budgetLineStartDate?: string | null;
    budgetLineEndDate?: string | null;
    isStartDate: boolean;
    updateBudgetClassification: (dict: Object) => void;
}

// all local date formatting is done assuming dates are passed in UTC
const PeriodsSelect = ({
    budgetLineStartDate, budgetLineEndDate, isStartDate, updateBudgetClassification, proFormaDateRange,
}: IPeriodsSelectProps) => {
    const [formattedDate, setFormattedDate] = useState<string | null>(null);

    const selectedDate = isStartDate ? budgetLineStartDate : budgetLineEndDate;

    const startDatePeriodsDateRange = [proFormaDateRange[0], budgetLineEndDate || proFormaDateRange[1]];
    const endDatePeriodsDateRange = [budgetLineStartDate || proFormaDateRange[0], proFormaDateRange[1]];

    const defaultDateOptions = proFormaDateRange && generateDateRange(proFormaDateRange);
    const availableDateOptions = generateDateRange(isStartDate ? startDatePeriodsDateRange : endDatePeriodsDateRange);

    const isValidDateOrString = isValidDate(selectedDate) || isValidDateString(selectedDate);

    const CustomPlaceholder: React.FC<PlaceholderProps> = props => (
        <components.Placeholder {...props}>
            <div className="text-primary text-nowrap font-italic">+ Add date</div>
        </components.Placeholder>
    );

    const handleSelectDate = (date: IOptionAnyValue) => {
        if (isStartDate) {
            updateBudgetClassification({ startDate: date.value });
        } else {
            updateBudgetClassification({ endDate: date.value });
        }
    };

    useEffect(() => {
        if (isValidDateOrString && selectedDate) {
            setFormattedDate(format(selectedDate as string, 'MMM yyyy'));
        }
        if (selectedDate === null) {
            setFormattedDate(null);
        }
    }, [selectedDate]);

    return (
        <NSSelect
            name="periodsSelect"
            components={{
                Placeholder: CustomPlaceholder,
            }}
            options={availableDateOptions}
            value={availableDateOptions.find(option => option.label === formattedDate) || null}
            onChange={option => handleSelectDate(option)}
            isClearable={false}
            isSearchable={false}
            menuPortalTarget={document.body}
            menuPosition="absolute"
            formatOptionLabel={(option: { label: string; value: string }, context: { context: string }) => {
                const index = defaultDateOptions.findIndex(opt => opt.label === option.label);

                if (['menu', 'value'].includes(context.context)) {
                    return (
                        <div className={classNames('d-flex flex-column', { 'py-0': context.context === 'value' })}>
                            <small>
                                {`Month ${index + 1}`}
                            </small>
                            <div className={classNames({ 'text-primary': context.context === 'value' })}>{option.label}</div>
                        </div>
                    );
                }

                return option.label;
            }}
            styles={{
                ...customStyles,
                control: provided => ({
                    ...provided,
                    color: '#aab8c5',
                    backgroundColor: '#30343e',
                    borderRadius: 4,
                    cursor: 'pointer',
                    border: 'none',
                    width: '120px',
                    height: '42px',
                    '&:hover': {
                        border: 'none',
                        backgroundColor: '#464e5b',
                    },
                }),
                dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: state.isFocused ? '#F1F1F1' : '#30343e',
                    '&:hover': {
                        color: '#F1F1F1',
                        backgroundColor: '#464e5b',
                    },
                }),
                menuPortal: base => ({
                    ...base,
                    zIndex: 9999,
                }),
            }}
        />
    );
};

export default PeriodsSelect;
