import { NSButton, NSCard, NSLabel } from 'bricks';
import NSInputNumberStepper from 'bricks/NSInputNumberStepper/NSInputNumberStepper';
import React, { useEffect, useState } from 'react';
import './OperatingInformation.scss';
import { IOperatingAndPermanentDebtInfo, IPatchOperatingInfoData } from 'views/ProFormaTable/types';
import RenderIf from 'components/RenderIf/RenderIf';
import { format, parseISO } from 'date-fns';
import { generateDateRange } from 'views/ProFormaTable/helpers';
import { DISABLED_PLACEHOLDER_PERIOD } from 'views/ProFormaTable/constants';
import { ProFormaModelIds } from 'constants/proForma';
import classNames from 'classnames';
import MonthlyAnalysisTable, { IMonthlyAnalysisPeriod } from './MonthlyAnalysisTable/MonthlyAnalysisTable';
import { useProFormaSocketContext } from '../../socketContext/ProFormaSocketProvider';
import { getFirstStabilizedMonth, getMonthlyAnalysisPeriods } from './helpers';

const OperatingInformation: React.FC = () => {
    const {
        proForma, operatingInfo, operatingPeriodCashFlows, handleUpdateProFormaOperations,
    } = useProFormaSocketContext();

    const [isIncomeCalculation, setIsIncomeCalculation] = useState(false);
    const [operatingInfoData, setOperatingInfoData] = useState<Partial<IOperatingAndPermanentDebtInfo>>({});

    const labelClassName = 'pl-0 col-4 font-weight-normal text-dark align-self-center';
    const labelClassNameNoWrap = 'pl-0 col-4 my-auto font-weight-normal text-dark text-nowrap';

    const updateOperationsKeys = (keyValues: Partial<IPatchOperatingInfoData>) => {
        if (operatingInfo?.id) {
            handleUpdateProFormaOperations(operatingInfo.id, keyValues);
        }
    };

    const periodsDateRange = proForma.startDate && proForma.saleDate ? [proForma.startDate, proForma.saleDate] : null;

    const periodOptions = periodsDateRange !== null
        ? generateDateRange(periodsDateRange, 'MMM yyyy', true)
        : [{ label: 'MON YEAR', value: DISABLED_PLACEHOLDER_PERIOD }];

    const monthlyAnalysisPeriods: IMonthlyAnalysisPeriod[] = getMonthlyAnalysisPeriods(periodOptions, operatingPeriodCashFlows);
    const firstStabilizedMonth: string | null = getFirstStabilizedMonth(monthlyAnalysisPeriods, operatingInfo?.occupancyUponStabilizationPercentage || 0);

    useEffect(() => {
        if (operatingInfo) {
            setOperatingInfoData({
                operationStartDate: operatingInfo.operationStartDate,
                occupancyUponStabilizationPercentage: Math.round(Number(operatingInfo.occupancyUponStabilizationPercentage) * 100),
                rentalGrowthPercentage: operatingInfo.rentalGrowthPercentage,
                percentPreLeased: operatingInfo.percentPreLeased,
                leaseUpPace: operatingInfo.leaseUpPace,
                lengthOfLease: operatingInfo.lengthOfLease,
                initialFreeRent: operatingInfo.initialFreeRent,
                stabilizedFreeRent: operatingInfo.stabilizedFreeRent,
            });
        }
    }, [operatingInfo, operatingPeriodCashFlows]);

    return (
        <div className="d-flex justify-content-end pb-3">
            <div className="OperatingInformation__inputs-container w-50 pl-3">
                <div className="d-flex align-items-center mt-3">
                    <NSLabel className={`my-auto ${labelClassName}`}>Occupancy upon stabilization</NSLabel>
                    <NSInputNumberStepper
                        percentage
                        name="occupancyUponStabilizationPercentage"
                        value={operatingInfoData.occupancyUponStabilizationPercentage || null}
                        onChange={(value: number) => updateOperationsKeys({
                            occupancyUponStabilizationPercentage: value
                                ? parseFloat((Number(value) / 100).toFixed(2)) : 0,
                        })}
                        min={0}
                        step={1}
                        max={100}
                        inputGroupClassName="OperatingInformation__input-width"
                    />
                </div>
                <RenderIf isTrue={proForma.proFormaModelId !== ProFormaModelIds.APARTMENT}>
                    <div className="d-flex align-items-center mt-2">
                        <NSLabel className={`my-auto ${labelClassName}`}>Income calculation</NSLabel>
                        <div className="my-auto font-weight-normal text-dark text-nowrap">
                            <NSButton
                                color={isIncomeCalculation ? 'primary' : 'secondary'}
                                outline={false}
                                callback={() => setIsIncomeCalculation(true)}
                                text="Estimate"
                                className={classNames('px-3', { 'border border-primary bg-primary-lighten': false })}
                            />
                            <NSButton
                                color={isIncomeCalculation ? 'secondary' : 'primary'}
                                outline={false}
                                callback={() => setIsIncomeCalculation(false)}
                                text="Input actual tenants"
                                className={classNames('px-3', {
                                    'border border-primary bg-primary-lighten': false,
                                })}
                            />
                        </div>
                    </div>
                </RenderIf>
                <RenderIf isTrue={isIncomeCalculation || proForma.proFormaModelId === ProFormaModelIds.APARTMENT}>
                    <div className="d-flex align-items-center mt-2">
                        <NSLabel className={labelClassNameNoWrap}>Rental growth</NSLabel>
                        <NSInputNumberStepper
                            percentage
                            name="rentalGrowthPercentage"
                            value={operatingInfoData.rentalGrowthPercentage || null}
                            onChange={(value: number) => updateOperationsKeys({ rentalGrowthPercentage: value })}
                            min={0}
                            step={0.1}
                            max={100}
                            inputGroupClassName="OperatingInformation__input-width"
                        />
                    </div>

                    <div className="d-flex align-items-center mt-2">
                        <NSLabel className={labelClassNameNoWrap}>% pre-leased</NSLabel>
                        <NSInputNumberStepper
                            percentage
                            name="percentPreLeased"
                            value={operatingInfoData.percentPreLeased || null}
                            onChange={(value: number) => updateOperationsKeys({ percentPreLeased: value })}
                            min={0}
                            step={1}
                            max={100}
                            inputGroupClassName="OperatingInformation__input-width"
                        />
                    </div>

                    <div className="d-flex align-items-center mt-2">
                        <NSLabel className={labelClassName}>Lease-up pace (units/month)</NSLabel>
                        <NSInputNumberStepper
                            name="leaseUpPace"
                            value={operatingInfoData.leaseUpPace || null}
                            onChange={(value: number) => updateOperationsKeys({ leaseUpPace: value })}
                            min={0}
                            step={1}
                            inputGroupClassName="OperatingInformation__input-width"
                        />
                    </div>

                    <div className="d-flex align-items-center mt-2">
                        <NSLabel className={labelClassNameNoWrap}>Length of lease</NSLabel>
                        <NSInputNumberStepper
                            name="lengthOfLease"
                            value={operatingInfoData.lengthOfLease || null}
                            onChange={(value: number) => updateOperationsKeys({ lengthOfLease: value })}
                            min={1}
                            step={1}
                            customSuffix={(operatingInfo.lengthOfLease || 0) > 1 ? 'months' : 'month'}
                            inputGroupClassName="OperatingInformation__input-width"
                        />
                    </div>
                </RenderIf>
                <RenderIf isTrue={proForma.proFormaModelId === ProFormaModelIds.APARTMENT}>
                    <div className="d-flex align-items-center mt-2">
                        <NSLabel className={labelClassNameNoWrap}>Initial free rent</NSLabel>
                        <NSInputNumberStepper
                            name="initialFreeRent"
                            value={operatingInfoData.initialFreeRent || null}
                            onChange={(value: number) => updateOperationsKeys({ initialFreeRent: value })}
                            min={0}
                            step={1}
                            customSuffix={(operatingInfo.initialFreeRent || 0) > 1 ? 'months' : 'month'}
                            inputGroupClassName="OperatingInformation__input-width"
                        />
                    </div>

                    <div className="d-flex align-items-center mt-2">
                        <NSLabel className={labelClassName}>Stabilized free rent</NSLabel>
                        <NSInputNumberStepper
                            name="stabilizedFreeRent"
                            value={operatingInfoData.stabilizedFreeRent || null}
                            onChange={(value: number) => updateOperationsKeys({ stabilizedFreeRent: value })}
                            min={0}
                            step={1}
                            customSuffix={(operatingInfoData.stabilizedFreeRent || 0) > 1 ? 'months' : 'month'}
                            inputGroupClassName="OperatingInformation__input-width"
                        />
                    </div>
                </RenderIf>
            </div>
            <div className="w-50 mt-2">
                <NSCard className="NSCard--level-4 mr-3 mt-2">
                    <div className="flex-row justify-content-between align-items-center p-2 mb-1">
                        <div className="text-muted">Operation start</div>
                        <RenderIf isTrue={operatingInfoData.operationStartDate}>
                            <small className="text-dark font-14">
                                <div className="text-dark">
                                    {`${operatingInfoData?.operationStartDate
                                        ? format(parseISO(operatingInfoData?.operationStartDate), 'MMM yyyy')
                                        : null}`}
                                </div>
                            </small>
                        </RenderIf>
                        <RenderIf isTrue={!operatingInfoData.operationStartDate}>
                            <small className="text-dark font-italic font-14">In progress</small>
                        </RenderIf>
                    </div>
                </NSCard>
                <NSCard className="NSCard--level-4 mr-3 mt-2">
                    <div className="flex-row justify-content-between align-items-center p-2">
                        <div className="text-muted">1st stabilized month</div>
                        <RenderIf isTrue={firstStabilizedMonth}>
                            <small className="text-dark font-14">
                                <div className="text-dark">
                                    {`${firstStabilizedMonth
                                        ? format(parseISO(firstStabilizedMonth), 'MMM yyyy')
                                        : null}`}
                                </div>
                            </small>
                        </RenderIf>
                        <RenderIf isTrue={!firstStabilizedMonth}>
                            <small className="text-dark font-italic font-14">In progress</small>
                        </RenderIf>
                    </div>
                </NSCard>
                <MonthlyAnalysisTable periodOptions={periodOptions} monthlyAnalysisPeriods={monthlyAnalysisPeriods} />
            </div>
        </div>
    );
};

export default OperatingInformation;
