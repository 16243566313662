import React, { useState, useEffect, useCallback } from 'react';
import { ProFormaGenericRow } from 'views/ProFormaTable/components/ProFormaGenericTable/types';
import ProFormaGenericTable from 'views/ProFormaTable/components/ProFormaGenericTable/ProFormaGenericTable';
import { SaleProceeds } from 'views/ProFormaTable/types';
import {
    PROCEEDS_COLUMNS,
    NET_SALE_PROCEEDS_COLUMNS,
} from './constants';
import { useProFormaSocketContext } from '../../socketContext/ProFormaSocketProvider';

const ProFormaSaleProceeds = () => {
    const { disposition } = useProFormaSocketContext();

    const [proceedsRows, setProceedsRows] = useState<ProFormaGenericRow[]>([]);
    const [netSaleRows, setNetSaleRows] = useState<ProFormaGenericRow[]>([]);

    // Convert data to row format with string values
    const createRow = useCallback((id: string, title: string, data: SaleProceeds | undefined | null): ProFormaGenericRow => ({
        id,
        title,
        amountByUnit: data?.perUnit !== undefined ? String(data.perUnit) : undefined,
        amountBySF: data?.perSf !== undefined ? String(data.perSf) : undefined,
        amount: data?.amount !== undefined ? String(data.amount) : undefined,
        isReadOnly: true,
    }), []);

    // Helper to safely get data from disposition
    const getSaleProceedsData = useCallback((key: string): SaleProceeds | undefined => {
        if (!disposition) return undefined;
        const dispositionData = disposition as Record<string, any>;
        return dispositionData[key] as SaleProceeds;
    }, [disposition]);

    useEffect(() => {
        if (!disposition) {
            return;
        }

        const proceeds: ProFormaGenericRow[] = [];

        const grossSaleRow = createRow('grossSale', 'Gross sale proceeds', getSaleProceedsData('gross_sale_proceeds'));
        proceeds.push(grossSaleRow);

        const sellingCostsData = getSaleProceedsData('selling_costs');
        if (sellingCostsData) {
            const sellingCostsRow = createRow('sellingCost', 'Selling costs', {
                perUnit: sellingCostsData.perUnit ? -Math.abs(sellingCostsData.perUnit) : undefined,
                perSf: sellingCostsData.perSf ? -Math.abs(sellingCostsData.perSf) : undefined,
                amount: sellingCostsData.amount ? -Math.abs(sellingCostsData.amount) : undefined,
            });
            proceeds.push(sellingCostsRow);
        } else {
            // If no data, just create a row with dash values
            proceeds.push(createRow('sellingCost', 'Selling costs', null));
        }

        setProceedsRows(proceeds);

        // Create net sale rows - similar logic for loan payoff (negative numbers)
        const netSale: ProFormaGenericRow[] = [];

        const loanPayoffData = getSaleProceedsData('loan_payoff');
        if (loanPayoffData) {
            const loanPayoffRow = createRow('loanPayoff', 'Loan payoff', {
                perUnit: loanPayoffData.perUnit ? -Math.abs(loanPayoffData.perUnit) : undefined,
                perSf: loanPayoffData.perSf ? -Math.abs(loanPayoffData.perSf) : undefined,
                amount: loanPayoffData.amount ? -Math.abs(loanPayoffData.amount) : undefined,
            });
            netSale.push(loanPayoffRow);
        } else {
            netSale.push(createRow('loanPayoff', 'Loan payoff', null));
        }

        setNetSaleRows(netSale);
    }, [disposition, createRow, getSaleProceedsData]);

    const netSaleProceedsData = getSaleProceedsData('net_sale_proceeds');
    const netSaleProceedsTotal = {
        amountByUnit: netSaleProceedsData?.perUnit ?? '',
        amountBySF: netSaleProceedsData?.perSf ?? '',
        amount: netSaleProceedsData?.amount ?? '',
    };

    const equityProceedsData = getSaleProceedsData('equity_proceeds_from_sale');
    const equityProceedsTotal = {
        amountByUnit: equityProceedsData?.perUnit ?? '',
        amountBySF: equityProceedsData?.perSf ?? '',
        amount: equityProceedsData?.amount ?? '',
    };

    return (
        <div className="d-flex flex-column p-3">
            <ProFormaGenericTable
                columns={PROCEEDS_COLUMNS}
                rows={proceedsRows}
                setRows={setProceedsRows}
                hideAddButton
                totalText="Net sale proceeds"
                totalValues={netSaleProceedsTotal}
            />
            <ProFormaGenericTable
                columns={NET_SALE_PROCEEDS_COLUMNS}
                rows={netSaleRows}
                setRows={setNetSaleRows}
                hideHeader
                hideAddButton
                totalText="Equity proceeds from sale"
                totalValues={equityProceedsTotal}
                hideTotalFooter={false}
            />
        </div>
    );
};

export default ProFormaSaleProceeds;
