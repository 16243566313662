import React, { useState } from 'react';
import {
    ModalHeader, Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEllipsisVertical, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { NSButton } from 'bricks';
import classNames from 'classnames';
import RenderIf from 'components/RenderIf/RenderIf';
import './DocumentLibrarySlideout.scss';

interface DocumentLibraryHeaderProps {
    name: string;
    size?: string;
    toggle: () => void;
    editMode: boolean;
    toggleEditMode: () => void;
    handleSaveDocument: () => void;
    handleOpenDocument: () => void;
    handleDeleteDocument: () => void;
}

const DocumentLibraryHeader: React.FC<DocumentLibraryHeaderProps> = ({
    name,
    size,
    toggle,
    editMode,
    toggleEditMode,
    handleSaveDocument,
    handleOpenDocument,
    handleDeleteDocument,
}) => {
    const [openOptions, setOpenOptions] = useState(false);

    return (
        <ModalHeader
            tag="div"
            toggle={toggle}
            className="DocumentLibrarySlideout d-flex flex-row-reverse p-0 align-items-center"
            cssModule={{ 'modal-title': classNames('modal-title w-100'), close: 'Header__button btn btn-lg' }}
        >
            <div className="Header d-flex align-items-center">
                <div className="Header__left p-2 pl-3">
                    <h5>{name}</h5>
                    <p className="text-muted">{size}</p>
                </div>
                <div className="Header__right d-flex align-items-center justify-content-between">
                    <div>
                        <h4 className="text-dark">{name.split('.')[0]}</h4>
                    </div>
                    <div className="mr-2 d-flex">
                        <RenderIf isTrue={!editMode}>
                            <Dropdown isOpen={openOptions} toggle={() => setOpenOptions(!openOptions)} direction="down">
                                <DropdownToggle tag="button" className="Header__dropdown-toggle">
                                    <NSButton color="secondary" icon={faEllipsisVertical} />
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={handleOpenDocument} data-testid="download-action" className="text-white">
                                        <div className="d-flex align-items-center justify-content-between w-100">
                                            <span>Download</span>
                                            <FontAwesomeIcon icon={faDownload} />
                                        </div>
                                    </DropdownItem>
                                    <DropdownItem onClick={handleDeleteDocument} data-testid="delete-action" className="text-white">
                                        <div className="d-flex align-items-center justify-content-between w-100">
                                            <span>Delete</span>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </div>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <NSButton callback={toggleEditMode} className="ml-1" color="info">
                                Edit
                            </NSButton>
                        </RenderIf>
                        <RenderIf isTrue={editMode}>
                            <NSButton callback={handleSaveDocument} className="ml-1" color="info">
                                Save
                            </NSButton>
                        </RenderIf>

                    </div>
                </div>
            </div>
        </ModalHeader>
    );
};

export default DocumentLibraryHeader;
