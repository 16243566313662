export const DEFAULT_ROW_HEIGHT = 100;
// how many additional padding ticks to add to the x-axis outside of configured dates
export const NUM_TICKS_X_AXIS_PADDING = 7;

// Define constants for colors and labels
export const COLORS = {
    chartAreaBackground: '#3B414D',
    yAxisBackground: '#464E5B',
    xAxisHeaderBackground: '#464E5B',
    yAxisOverlap: '#525D6B',
    xAxisTickColor: '#707C89',
    yAxisTickColor: 'white',
};

export const LIGHT_MODE_COLORS = {
    scatterPoint: '#09b4fe',
    barBackground: 'rgba(9, 180, 254, 0.5)',
    barBorder: 'rgba(9, 180, 254, 1)',
    chartAreaBackground: '#f5f5f5',
    yAxisBackground: '#f5f5f5',
    xAxisHeaderBackground: '#f5f5f5',
    yAxisOverlap: '#f5f5f5',
    xAxisTickColor: '#a3a7b0',
    yAxisTickColor: 'black',
};

export const DEAL_ROW_COLORS = [
    { solid: '#FF679B', transparent: 'rgba(255, 103, 155, 0.3)' },
    { solid: '#0FB3FF', transparent: 'rgba(15, 159, 255, 0.3)' },
    { solid: '#F98B0D', transparent: 'rgba(249, 139, 13, 0.3)' },
    { solid: '#30A279', transparent: 'rgba(48, 162, 121, 0.3)' },
    { solid: '#B2E7FF', transparent: 'rgba(178, 231, 255, 0.3)' },
];
