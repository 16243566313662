import { IOptionAnyValue } from 'bricks/types';
import { ProFormaGenericColumn } from 'views/ProFormaTable/components/ProFormaGenericTable/types';

export const CONTRIBUTIONS_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'name', field: 'name', header: 'Contributions', format: 'none', showLabel: true, type: 'text',
    },
    {
        key: 'upTo', field: 'upTo', header: '', format: 'none', isHidden: true,
    },
    {
        key: 'empty', field: 'empty', header: '', format: 'none', isHidden: true,
    },
    {
        key: 'share', field: 'share', header: 'Share', format: 'percentage',
    },
    {
        key: 'amount', field: 'amount', header: 'Amount', format: 'currency', isReadOnly: true,
    },
    {
        key: 'column1', field: 'column1', header: 'MON 20XX', isFixed: false,
    },
    {
        key: 'column2', field: 'column2', header: 'MON 20XX', isFixed: false,
    },
    {
        key: 'column3', field: 'column3', header: 'MON 20XX', isFixed: false,
    },
    {
        key: 'column4', field: 'column4', header: 'MON 20XX', isFixed: false,
    },
];

export const getWaterfallColumns: (selectedIRR: IOptionAnyValue, setSelectedIRR: (val: IOptionAnyValue) => void) => ProFormaGenericColumn[] = (
    selectedIRR,
    setSelectedIRR,
) => [
    {
        key: 'name', field: 'name', header: 'Distribution Waterfall', format: 'none', isReadOnly: true,
    },
    {
        key: 'upTo',
        field: 'upTo',
        header: 'IRR',
        format: 'percentage',
        headerDropdown: {
            options: [
                { value: 'irr', label: 'IRR' },
                { value: 'equity-multiple', label: 'Equity Multiple' },
                { value: 'cash-cash', label: 'Cash-on-Cash' },
            ],
            value: selectedIRR,
            onChange: setSelectedIRR,
            placeholder: 'Select',
            label: 'hurdle',
        },
        showLabel: true,
        isReadOnly: row => row.id === '4th-tier',
    },
    {
        key: 'gpPromote', field: 'gpPromote', header: 'GP promote', format: 'percentage', isReadOnly: row => row.id === 'pref-return',
    },
    {
        key: 'distToGP', field: 'distToGP', header: 'Dist. % to GP', format: 'percentage', isReadOnly: row => row.id !== 'pref-return',
    },
    {
        key: 'distToLP', field: 'distToLP', header: 'Dist. % to LP', format: 'percentage', isReadOnly: row => row.id !== 'pref-return',
    },
    {
        key: 'column1', field: 'column1', header: '', isFixed: false,
    },
    {
        key: 'column2', field: 'column2', header: '', isFixed: false,
    },
    {
        key: 'column3', field: 'column3', header: '', isFixed: false,
    },
    {
        key: 'column4', field: 'column4', header: '', isFixed: false,
    },
];

export const DISTRIBUTIONS_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'name', field: 'name', header: 'Distributions', format: 'none', showLabel: true,
    },
    {
        key: 'upTo', field: 'upTo', header: '', format: 'none', isHidden: true,
    },
    {
        key: 'empty', field: 'empty', header: '', format: 'none', isHidden: true,
    },
    {
        key: 'share', field: 'share', header: 'Share', format: 'percentage',
    },
    {
        key: 'amount', field: 'amount', header: 'Amount', format: 'currency',
    },
    {
        key: 'column1', field: 'column1', header: '', isFixed: false,
    },
    {
        key: 'column2', field: 'column2', header: '', isFixed: false,
    },
    {
        key: 'column3', field: 'column3', header: '', isFixed: false,
    },
    {
        key: 'column4', field: 'column4', header: '', isFixed: false,
    },
];

export const CASH_FLOW_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'name', field: 'name', header: 'Net cash flow', format: 'none', showLabel: true,
    },
    {
        key: 'emx', field: 'emx', header: 'EMx', format: 'none',
    },
    {
        key: 'irr', field: 'irr', header: 'IRR', format: 'percentage',
    },
    {
        key: 'share', field: 'share', header: 'Share', format: 'percentage',
    },
    {
        key: 'amount', field: 'amount', header: 'Amount', format: 'currency',
    },
    {
        key: 'column1', field: 'column1', header: '', isFixed: false,
    },
    {
        key: 'column2', field: 'column2', header: '', isFixed: false,
    },
    {
        key: 'column3', field: 'column3', header: '', isFixed: false,
    },
    {
        key: 'column4', field: 'column4', header: '', isFixed: false,
    },
];
