import React, { useMemo } from 'react';
import {
    NSButton, NSCard, NSDropdown, NSInput, NSSwitch,
} from 'bricks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollar, faPercentage, faTrash } from '@fortawesome/pro-regular-svg-icons';
import RenderIf from 'components/RenderIf/RenderIf';
import { LOAN_CALCULATION_OPTIONS, LOAN_CALCULATION_TYPES } from 'views/DealDashboard/BOE/components/SlideoutForm/constants';
import { useGetSourceTypes } from 'queries/ProFormaSource';
import { ISourceProps } from './types';

const Source = ({
    source, traunchOptions, onChange, onRemove, errors, touched, onBlur,
}: ISourceProps) => {
    const {
        id, name, amount, loanCalculationType, loanToCost, loanToValue, debtYield, calculateDebt, traunch, sourceTypeId,
    } = source;
    const { data: sourceTypes } = useGetSourceTypes();

    const typeOptions = useMemo(
        () => sourceTypes?.map(type => ({
            label: type.name,
            value: type.correlationId,
        })) || [],
        [sourceTypes],
    );

    const selectedTraunch = useMemo(() => traunchOptions.find(item => item.value === traunch), [traunchOptions, traunch]);
    const selectedType = useMemo(() => typeOptions.find(item => item.value === sourceTypeId), [typeOptions, sourceTypeId]);
    const loanCalculationTypeOption = useMemo(() => {
        if (loanCalculationType) {
            return LOAN_CALCULATION_OPTIONS.find(item => item.value === loanCalculationType);
        }

        if (loanToCost) {
            return LOAN_CALCULATION_OPTIONS.find(item => item.value === LOAN_CALCULATION_TYPES.LTC);
        }
        if (loanToValue) {
            return LOAN_CALCULATION_OPTIONS.find(item => item.value === LOAN_CALCULATION_TYPES.LTV);
        }
        if (debtYield) {
            return LOAN_CALCULATION_OPTIONS.find(item => item.value === LOAN_CALCULATION_TYPES.DY);
        }

        return undefined;
    }, [loanCalculationType, loanToCost, loanToValue, debtYield]);

    const getCalculateState = useMemo(() => {
        if (typeof calculateDebt !== 'undefined') return calculateDebt;

        if (loanToCost || loanToValue || debtYield) return true;

        return false;
    }, [calculateDebt, loanToCost, loanToValue, debtYield]);

    const showAmount = useMemo(() => {
        if (!getCalculateState) return true;

        if (!loanCalculationTypeOption?.value) return false;

        if (loanCalculationTypeOption.value === LOAN_CALCULATION_TYPES.LTC) return true;

        return false;
    }, [getCalculateState, loanCalculationTypeOption]);

    const handleInputChange = (id: string, name: string, value: number) => {
        const newValue = Math.min(Math.max(value, 0), 100);
        onChange(id, name, newValue);
    };

    return (
        <NSCard className="p-2 w-100 d-flex flex-row mb-1 align-items-start">
            <div className="flex-grow-0 mx-1">
                <span className="d-flex mr-2 mb-1 text-dark">Fund. traunch</span>
                <NSDropdown
                    options={traunchOptions}
                    selectedOption={selectedTraunch}
                    handleSelectOption={option => {
                        onChange(id, 'traunch', option.value);
                        onBlur(id, 'traunch');
                    }}
                    containerClassName="mr-2"
                    isFullWidth
                    showErrorMsg={touched?.traunch && !!errors?.traunch}
                    errorMsg={touched?.traunch ? errors?.traunch : ''}
                />
            </div>

            <div className="flex-grow-1 mx-1">
                <span className="d-flex mr-2 mb-1 text-dark">Name</span>
                <NSInput
                    id="name"
                    type="text"
                    name="name"
                    value={name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(id, 'name', event.target.value)}
                    onBlur={() => onBlur(id, 'name')}
                    showErrorMsg={touched?.name && !!errors?.name}
                    errorMsg={touched?.name ? errors?.name : ''}
                />
            </div>

            <div className="flex-grow-1 mx-1">
                <div className="d-flex flex-direction-row justify-content-between">
                    <span className="mr-2 mb-1 text-dark">Type</span>
                    <RenderIf isTrue={selectedType?.label.includes('Debt')}>
                        <div>
                            <span className="mr-2 mb-1 text-dark">Calculate</span>
                            <NSSwitch
                                id={id}
                                name="calculateSwitch"
                                checked={getCalculateState}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    onChange(id, 'calculateDebt', event.target.checked);
                                }}
                                containerClassName="text-white"
                            />
                        </div>
                    </RenderIf>
                </div>
                <NSDropdown
                    options={typeOptions}
                    selectedOption={selectedType}
                    placeholder="Select type"
                    handleSelectOption={option => onChange(id, 'sourceTypeId', option.value)}
                    containerClassName="mr-2"
                    isFullWidth
                    showErrorMsg={touched?.type && !!errors?.type}
                    errorMsg={touched?.type ? errors?.type : ''}
                />
            </div>

            <RenderIf isTrue={getCalculateState}>
                <div className="flex-grow-0 mx-1">
                    <NSDropdown
                        containerClassName="mb-1"
                        options={LOAN_CALCULATION_OPTIONS}
                        selectedOption={loanCalculationTypeOption}
                        handleSelectOption={option => {
                            onChange(id, 'loanCalculationType', option.value);
                            onBlur(id, 'loanCalculationType');
                        }}
                        customSelectedOptionText={loanCalculationTypeOption?.label}
                        badgeColor="dark-lighten"
                        isBadgeToggle
                    />
                    <RenderIf isTrue={loanCalculationTypeOption?.value === LOAN_CALCULATION_TYPES.LTC}>
                        <NSInput
                            id="loanToCost"
                            type="number"
                            name="loanToCost"
                            appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                            value={loanToCost}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                handleInputChange(id, 'loanToCost', event.target.valueAsNumber);
                            }}
                            onBlur={() => onBlur(id, 'loanToCost')}
                            showErrorMsg={touched?.loanToCost && !!errors?.loanToCost}
                            errorMsg={touched?.loanToCost ? errors?.loanToCost : ''}
                        />
                    </RenderIf>
                    <RenderIf isTrue={loanCalculationTypeOption?.value === LOAN_CALCULATION_TYPES.LTV}>
                        <NSInput
                            id="loanToValue"
                            type="number"
                            name="loanToValue"
                            appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                            value={loanToValue}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                handleInputChange(id, 'loanToValue', event.target.valueAsNumber);
                            }}
                            onBlur={() => onBlur(id, 'loanToValue')}
                            showErrorMsg={touched?.loanToValue && !!errors?.loanToValue}
                            errorMsg={touched?.loanToValue ? errors?.loanToValue : ''}
                        />
                    </RenderIf>
                    <RenderIf isTrue={loanCalculationTypeOption?.value === LOAN_CALCULATION_TYPES.DY}>
                        <NSInput
                            id="debtYield"
                            type="number"
                            name="debtYield"
                            appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                            value={debtYield}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                handleInputChange(id, 'debtYield', event.target.valueAsNumber);
                            }}
                            onBlur={() => onBlur(id, 'debtYield')}
                            showErrorMsg={touched?.debtYield && !!errors?.debtYield}
                            errorMsg={touched?.debtYield ? errors?.debtYield : ''}
                        />
                    </RenderIf>
                </div>
            </RenderIf>

            <RenderIf isTrue={showAmount}>
                <div className="flex-grow-1 mx-1">
                    <span className="d-flex mr-2 mb-1 text-dark">Amount</span>
                    <NSInput
                        disabled={getCalculateState && loanCalculationTypeOption?.value === LOAN_CALCULATION_TYPES.LTC}
                        id="amount"
                        type="number"
                        name="amount"
                        placeholder="Enter amount"
                        prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                        value={amount || ''}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(id, 'amount', event.target.value)}
                        onBlur={() => onBlur(id, 'amount')}
                        showErrorMsg={touched?.amount && !!errors?.amount}
                        errorMsg={touched?.amount ? errors?.amount : ''}
                    />
                </div>
            </RenderIf>

            <NSButton icon={faTrash} color="danger" callback={() => onRemove(id)} className="ml-2" outline />
        </NSCard>
    );
};

export default Source;
