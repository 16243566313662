import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS from 'services/urls';
import { ProFormaExecutiveSummary } from '../types';

export const getProFormaExecutiveSummary = async (
    dealId: number,
    proFormaId: number,
): Promise<ProFormaExecutiveSummary> => {
    const response = await axiosInstance.get(
        API_ENDPOINTS.PRO_FORMA_EXECUTIVE_SUMMARY(dealId, proFormaId),
    );
    return response.data;
};

export const useGetProFormaExecutiveSummary = (
    dealId: number,
    proFormaId: number,
    options: { enabled: boolean },
) => useQuery({
    queryKey: ['ProFormaExecutiveSummary', dealId, proFormaId],
    queryFn: () => getProFormaExecutiveSummary(dealId, proFormaId),
    ...options,
});

export default useGetProFormaExecutiveSummary;
