/* eslint-disable no-unused-vars */

import { IProFormaSourceTableRow } from 'views/ProFormaTable/types';

export enum DEVELOPMENT_SOURCE_METRIC {
    DEBT_YIELD = 'debt_yield',
    LTC = 'loan_to_cost',
    LTV = 'loan_to_value',
}

export const PRO_FORMA_SOURCE_METRIC_OPTIONS = [
    {
        label: 'Debt yield',
        value: DEVELOPMENT_SOURCE_METRIC.DEBT_YIELD,
    },
    {
        label: 'LTC',
        value: DEVELOPMENT_SOURCE_METRIC.LTC,
    },
    {
        label: 'LTV',
        value: DEVELOPMENT_SOURCE_METRIC.LTV,
    },
];

export const PRO_FORMA_METRIC_TO_FIELD_VALUES: Record<DEVELOPMENT_SOURCE_METRIC, keyof IProFormaSourceTableRow> = {
    [DEVELOPMENT_SOURCE_METRIC.DEBT_YIELD]: 'debtYield',
    [DEVELOPMENT_SOURCE_METRIC.LTC]: 'loanToCost',
    [DEVELOPMENT_SOURCE_METRIC.LTV]: 'loanToValue',
};
