import { ProFormaGenericColumn, ProFormaGenericRow } from 'views/ProFormaTable/components/ProFormaGenericTable/types';

export const PROCEEDS_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'title', field: 'title', header: 'Proceeds', format: 'none', isReadOnly: true,
    },
    {
        key: 'amountByUnit', field: 'stabilizationValue', header: '$ / unit', format: 'currency', isReadOnly: true,
    },
    {
        key: 'amountBySF', field: 'costPercentage', header: '$ / SF', format: 'currency', isReadOnly: true,
    },
    {
        key: 'amount', field: 'amount', header: 'Amount', format: 'currency', isReadOnly: true,
    },
];

export const NET_SALE_PROCEEDS_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'title', field: 'title', header: 'Net sale proceeds', format: 'none', isReadOnly: true,
    },
    {
        key: 'amountByUnit', field: 'amountByUnit', header: '$119,704', format: 'none', isReadOnly: true,
    },
    {
        key: 'amountBySF', field: 'amountBySF', header: '$221,402', format: 'none', isReadOnly: true,
    },
    {
        key: 'amount', field: 'amount', header: '$35,911,081', format: 'none', isReadOnly: true,
    },
];

export const EQUITY_PROCEEDS_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'title', field: 'title', header: 'Equity proceeds from sale', format: 'none', isReadOnly: true,
    },
    {
        key: 'amountByUnit', field: 'amountByUnit', header: '$119,704', format: 'none', isReadOnly: true,
    },
    {
        key: 'amountBySF', field: 'amountBySF', header: '$221,402', format: 'none', isReadOnly: true,
    },
    {
        key: 'amount', field: 'amount', header: '$35,911,081', format: 'none', isReadOnly: true,
    },
];

export const PROCEEDS_ROWS_INITIAL_STATE: ProFormaGenericRow[] = [
    {
        id: 'grossSale',
        title: 'Gross sale proceeds',
        amountByUnit: '249876',
        amountBySF: '425331',
        amount: '74962801',
    },
    {
        id: 'sellingCost',
        title: 'Selling costs',
        amountByUnit: '49876',
        amountBySF: '25331',
        amount: '14962801',
        isNegative: true,
    },
];

export const NET_SALE_ROWS_INITIAL_STATE: ProFormaGenericRow[] = [
    {
        id: 'loanPayoff',
        title: 'Loan payoff',
        amountByUnit: '126424',
        amountBySF: '225331',
        amount: '379927278',
        isNegative: true,
    },
];
