import React from 'react';
import {
    DropdownItem, UncontrolledTooltip,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { AccessLevels } from 'types/auth';
import useAuthContext from 'contexts/AuthContext';
import { BUTTON_DISABLED_BY_AUTH_TOOLTIP, userClasses } from 'constants/auth';
import RenderIf from '../../components/RenderIf/RenderIf';
import { IOptionAnyValue } from '../types';
import './NSDropdown.scss';

export interface INSDropdownItemProps {
    option: IOptionAnyValue;
    handleSelectOption: (option: IOptionAnyValue) => void;
    optionClassName?: string;
    hideOptionCheckmarkIcon?: boolean;
    selectedOption?: IOptionAnyValue;
}

const NSDropdownItem = ({
    option, handleSelectOption, optionClassName, hideOptionCheckmarkIcon, selectedOption,
}: INSDropdownItemProps) => {
    const { isFeatureAccessibleToUser, role, user } = useAuthContext();
    let itemIsDisabled = option.disabled || false;
    let currentDisabledTooltip = option.disabledTooltip || '';

    if (option.featureId) {
        let requiredAccessLevel = AccessLevels.READ_ACCESS;
        if (!option.allowReadOnlyAccess) {
            requiredAccessLevel = AccessLevels.READ_WRITE_ACCESS;
        }
        const userHasAccess = isFeatureAccessibleToUser([option.featureId], requiredAccessLevel);
        if (!userHasAccess) {
            itemIsDisabled = true;
            currentDisabledTooltip = BUTTON_DISABLED_BY_AUTH_TOOLTIP;
        }
    } else if (!option.allowReadOnlyAccess) {
        if (role?.isReadOnly && user?.userClass !== userClasses.CLASS_SUPER_ADMIN) {
            itemIsDisabled = true;
            currentDisabledTooltip = BUTTON_DISABLED_BY_AUTH_TOOLTIP;
        }
    }

    const tooltipId = `dropdown-tooltip-${String(option.value).replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-_]/g, '')}`;

    return (
        <div key={option.value} className="position-relative">
            {/* Wrap DropdownItem for Tooltip */}
            <div id={tooltipId} className="d-flex align-items-center">
                <DropdownItem
                    onClick={() => handleSelectOption(option)}
                    className={classNames([
                        `NSDropdown__dropdown-menu__dropdowm-item ${optionClassName}`,
                        {
                            'NSDropdown__dropdown-menu__dropdowm-item__disabled': itemIsDisabled,
                            'ReadOnlyWrapper--enable-pointer-events': !itemIsDisabled,
                        },
                    ])}
                    disabled={itemIsDisabled}
                    toggle={option.toggle ?? true}
                >
                    <div className="d-flex">
                        {option.component || option.label}
                        <RenderIf isTrue={!hideOptionCheckmarkIcon && selectedOption?.value === option.value}>
                            <FontAwesomeIcon icon={faCheck} className="ml-1" />
                        </RenderIf>
                    </div>
                </DropdownItem>
            </div>

            {/* Tooltip for disabled items */}
            {itemIsDisabled && currentDisabledTooltip && tooltipId && (
                <UncontrolledTooltip
                    target={tooltipId}
                    modifiers={{
                        preventOverflow: {
                            boundariesElement: 'viewport', // allows tooltip to actually attach to the dropdown item
                        },
                    }}
                >
                    {currentDisabledTooltip || 'This option is disabled'}
                </UncontrolledTooltip>
            )}
        </div>
    );
};

export default NSDropdownItem;
