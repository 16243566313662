import React, { useCallback, useEffect, useState } from 'react';
import {
    Col,
    Row,
    Table,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowsFromLine,
} from '@fortawesome/pro-regular-svg-icons';
import RenderIf from 'components/RenderIf/RenderIf';
import {
    useDealDirectoryOptions, useDeleteDocument, useDeleteDirectory, useUpdateDirectory,
} from 'views/DocumentLibrary/hooks';
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal';
import { sortDocuments, sortItems } from 'views/DocumentLibrary/helpers';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from 'services/urls';
import SortingIcon from '../../../../components/DraggableTable/DraggableHeader/SortingIcon/SortingIcon';
import './DocumentLibraryTable.scss';
import {
    IDealDocument, IDealDirectory, DealDirectoryItem,
} from '../../types';
import DocumentLibrarySlideout from '../DocumentLibrarySlideout/DocumentLibrarySlideout';
import DocumentRow from './DocumentRow';
import BreadcrumbNavigation from './BreadcrumbNavigation';
import FileRow from './FileRow';
import DropzoneCard from '../DropzoneCard/DropzoneCard';
import { EditDirectoryModal } from '../EditDirectoryModal/EditDirectoryModal';

export interface IProps {
    data: IDealDirectory;
    dealId: number;
    isCardView: boolean;
    onDocumentSelect: (file: IDealDocument) => void
    getFilesFromEvent: (event: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>, dropLocation: string) => Promise<File[]>;
}

const DocumentTable = ({
    dealId,
    data,
    isCardView,
    onDocumentSelect,
    getFilesFromEvent,
}: IProps): JSX.Element => {
    const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});
    const [allExpanded, setAllExpanded] = useState(false);
    const [sortConfig, setSortConfig] = useState<{ column: string; direction: 'asc' | 'desc' } | null>(null);
    // eslint-disable-next-line no-unused-vars
    const [openSlideout, setOpenSlideout] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [documentDetail, setDocumentDetail] = useState<IDealDocument>();
    const [cardViewData, setCardViewData] = useState<(DealDirectoryItem)[]>([]);
    const [breadcrumb, setBreadcrumb] = useState<IDealDirectory[]>([]);
    const [itemToDelete, setItemToDelete] = useState<DealDirectoryItem | null>(null);
    const [itemToUpdate, setItemToUpdate] = useState<DealDirectoryItem | null>(null);
    const queryClient = useQueryClient();

    const { mutate: deleteDocument } = useDeleteDocument();
    const { mutate: deleteDirectory } = useDeleteDirectory();
    const { mutate: updateDirectory } = useUpdateDirectory();

    const handleConfirmDelete = () => {
        if (!itemToDelete) return;
        // @ts-ignore
        if (itemToDelete?.extension) {
            // @ts-ignore
            deleteDocument(itemToDelete.id, {
                onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DEAL_DIRECTORY_TREE, Number(dealId)] }),
            });
        } else {
            // @ts-ignore
            deleteDirectory({ dealId, directoryId: itemToDelete.directoryId }, {
                onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DEAL_DIRECTORY_TREE, Number(dealId)] }),
            });
        }

        setItemToDelete(null);
    };

    const handleEditDirectory = (updatedDirectory: IDealDirectory) => {
        updateDirectory({
            data: {
                name: updatedDirectory.directoryName,
                parentDirectoryId: updatedDirectory.parentDirectoryId,
            },
            directoryId: updatedDirectory.directoryId,
            dealId,
        }, {
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DEAL_DIRECTORY_TREE, Number(dealId)] });
                setItemToUpdate(null);
            },
        });
    };

    const handleCancelDelete = () => {
        setItemToDelete(null);
    };

    const handleToggleSlideout = useCallback(() => setOpenSlideout(prev => !prev), []);

    const handleSelectDocument = useCallback((document: IDealDocument) => {
        setDocumentDetail(document);
        onDocumentSelect(document);
        handleToggleSlideout();
    }, [handleToggleSlideout]);

    const handleToggle = (id: number) => {
        setExpanded(prev => {
            const next = { ...prev, [id]: !prev[id] };
            return next;
        });
    };

    const handleExpandAll = () => {
        const newExpandedState = !allExpanded;
        setAllExpanded(newExpandedState);

        const getAllFolderIds = (items: IDealDirectory[]): number[] => items.reduce<number[]>((acc, item) => {
            if (item.children?.length) {
                if (item.directoryId) acc.push(item.directoryId);

                acc.push(...getAllFolderIds(item.children));
            }
            return acc;
        }, []);

        const folderIds = getAllFolderIds(data.children);
        const newExpanded = folderIds.reduce(
            (acc, id) => {
                acc[id] = newExpandedState;
                return acc;
            },
            {} as { [key: string]: boolean },
        );

        setExpanded(newExpanded);
    };

    const handleSort = (column: string) => {
        setSortConfig(prevConfig => {
            if (!prevConfig || prevConfig.column !== column) {
                return { column, direction: 'asc' };
            }
            if (prevConfig.direction === 'asc') {
                return { column, direction: 'desc' };
            }
            return null; // Reset sorting
        });
    };

    const sortedItems = [...sortItems(data.children, sortConfig), ...sortDocuments(data.documents, sortConfig)];

    useEffect(() => setCardViewData(sortedItems), []);

    const findDirectoryById = (
        directoryId: number, items: (IDealDirectory | IDealDocument)[],
    ): IDealDirectory | null => items.reduce<IDealDirectory | null>((found, doc) => {
        if (found) return found; // return immediately
        if ('children' in doc) {
            if (doc.directoryId === directoryId) return doc;
            if (doc.children) return findDirectoryById(directoryId, doc.children);
        }
        return null;
    }, null);

    const handleCardViewToggle = useCallback((directoryId: number) => {
        const selectedDirectory = findDirectoryById(directoryId, sortedItems);

        if (!selectedDirectory) return;

        setCardViewData([
            ...sortItems(selectedDirectory.children || [], sortConfig),
            ...sortDocuments(selectedDirectory.documents, sortConfig),
        ]);

        setBreadcrumb(prev => {
            const existingIndex = prev.findIndex(doc => doc.directoryId === directoryId);
            return existingIndex !== -1 ? prev.slice(0, existingIndex + 1) : [...prev, selectedDirectory];
        });

        handleToggle(directoryId);
    }, [handleToggle, sortedItems]);

    const handleBreadcrumbClick = (index: number) => {
        if (index === 0) {
            setBreadcrumb([]);
            setCardViewData(sortedItems);
        } else {
            const newBreadcrumb = breadcrumb.slice(0, index);
            const selectedFolder = newBreadcrumb[index - 1];

            if (selectedFolder) {
                setBreadcrumb(newBreadcrumb);
                setCardViewData([
                    ...sortItems(selectedFolder.children || [], sortConfig),
                    ...sortDocuments(selectedFolder.documents, sortConfig),
                ]);
            }
        }
    };

    const renderColumnHeader = (label: string) => (
        <div className="d-flex align-items-center justify-content-between cursor--pointer" role="button" tabIndex={0} aria-label={`Sort by ${label}`}>
            <span>{label}</span>
            <SortingIcon
                isSorted={sortConfig?.column === label}
                sortedDirection={sortConfig?.column === label ? sortConfig.direction : null}
                columnId={label.toLowerCase().replace(' ', '-')}
                onAscendingSort={() => handleSort(label)}
                onDescendingSort={() => handleSort(label)}
            />
        </div>
    );

    const folderLocationOptions = useDealDirectoryOptions(Number(dealId));

    return (
        <div className="NSTable">

            <RenderIf isTrue={!isCardView}>
                <Table hover responsive>
                    <thead className="NSTable__thead">
                        <tr className="NSTable__thead__tr">
                            <th className="NSTable__thead__tr__th py-1">
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    <div className="d-flex align-items-center">
                                        <FontAwesomeIcon
                                            icon={faArrowsFromLine}
                                            className="DocumentLibraryTable__expand-btn cursor--pointer mr-2"
                                            onClick={handleExpandAll}
                                            title={allExpanded ? 'Collapse all folders' : 'Expand all folders'}
                                        />
                                        <span>Name</span>
                                    </div>
                                    <SortingIcon
                                        isSorted={sortConfig?.column === 'name'}
                                        sortedDirection={sortConfig?.column === 'name' ? sortConfig.direction : null}
                                        columnId="name"
                                        onAscendingSort={() => handleSort('name')}
                                        onDescendingSort={() => handleSort('name')}
                                    />
                                </div>
                            </th>
                            <th className="NSTable__thead__tr__th py-1">{renderColumnHeader('Type')}</th>
                            <th className="NSTable__thead__tr__th py-1">{renderColumnHeader('Format')}</th>
                            <th className="NSTable__thead__tr__th py-1">{renderColumnHeader('Date modified')}</th>
                            <th className="NSTable__thead__tr__th py-1">{renderColumnHeader('Size')}</th>
                            <th className="NSTable__thead__tr__th py-1 DocumentLibraryTable__actions-column" />
                        </tr>
                    </thead>
                    <tbody className="NSTable__tbody">
                        {sortedItems.map(item => (
                            <FileRow
                                key={'id' in item ? `doc-${item.id}` : `dir-${item.directoryId}`}
                                item={item}
                                level={0}
                                expanded={expanded}
                                onToggle={handleToggle}
                                onEdit={setItemToUpdate}
                                onSelect={handleSelectDocument}
                                onDelete={() => setItemToDelete(item)}
                            />
                        ))}
                    </tbody>
                </Table>
            </RenderIf>

            <RenderIf isTrue={isCardView}>
                <div className="NSCard--level-1 p-2">
                    {breadcrumb.length > 0 && <BreadcrumbNavigation breadcrumb={breadcrumb} handleBreadcrumbClick={handleBreadcrumbClick} />}

                    <Row className="pl-2">
                        <Col md="3" sm="6" className="DocumentLibraryTable__dropzone p-0 m-0 mb-2">
                            <DropzoneCard getFilesFromEvent={getFilesFromEvent} />
                        </Col>
                        {cardViewData.map(item => (
                            <DocumentRow
                                key={'id' in item ? `doc-${item.id}` : `dir-${item.directoryId}`}
                                doc={item}
                                onToggle={handleCardViewToggle}
                                onSelect={handleSelectDocument}
                            />
                        ))}
                    </Row>
                </div>
            </RenderIf>

            {documentDetail && (
                <DocumentLibrarySlideout
                    isOpen={openSlideout}
                    toggle={handleToggleSlideout}
                    document={documentDetail}
                    onDelete={() => setItemToDelete(documentDetail)}
                />
            )}
            <EditDirectoryModal
                // @ts-ignore
                isOpen={Boolean(itemToUpdate && !itemToUpdate?.extension)}
                toggle={() => setItemToUpdate(null)}
                directory={itemToUpdate as IDealDirectory}
                folderOptions={folderLocationOptions}
                handleEditDirectory={handleEditDirectory}
            />
            <ConfirmationModal
                isOpen={!!itemToDelete}
                danger
                toggle={handleCancelDelete}
                onCancel={handleCancelDelete}
                onConfirm={handleConfirmDelete}
                // @ts-ignore
                modalHeaderText={`Delete ${itemToDelete?.extension ? 'document' : 'folder'}`}
            >
                <p>
                    Are you sure you want to delete
                    {
                        // @ts-ignore
                        ` ${itemToDelete?.extension ? 'document ' : 'folder'}`
                    }
                    <i>
                        {
                            // @ts-ignore
                            ` ${itemToDelete?.extension ? itemToDelete?.name : itemToDelete?.directoryName}`
                        }
                    </i>
                    ?
                </p>
            </ConfirmationModal>
        </div>
    );
};

export default DocumentTable;
