import {
    faDownload, faEdit, faEllipsisVertical, faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import {
    CardText,
    Card,
    CardBody,
    Col,
    Dropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
} from 'reactstrap';
import { NSButton } from '../../../../bricks';
import { checkIfIsFile, getFileIcon, iconColorByFilenameExtension } from '../../helpers';
import { DealDirectoryItem, IDealDocument } from '../../types';
import './DocumentRow.scss';

interface DocumentRowProps {
    doc: DealDirectoryItem;
    onSelect: (document: IDealDocument) => void;
    onToggle: (directoryId: number) => void;
}

const DocumentOptions: React.FC<{ onDownload: () => void }> = ({ onDownload }) => {
    const [openOptions, setOpenOptions] = useState(false);

    return (
        <Dropdown className="DocumentRow__dropdown" isOpen={openOptions} toggle={() => setOpenOptions(!openOptions)} direction="down">
            <DropdownToggle tag="button" className="DocumentRow__dropdown-toggle">
                <NSButton color="secondary" icon={faEllipsisVertical} />
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={onDownload} data-testid="download-action" className="text-white">
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <span>Download</span>
                        <FontAwesomeIcon icon={faDownload} />
                    </div>
                </DropdownItem>
                <DropdownItem onClick={() => { }} data-testid="edit-action" className="text-white">
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <span>Edit</span>
                        <FontAwesomeIcon icon={faEdit} />
                    </div>
                </DropdownItem>
                <DropdownItem onClick={() => { }} data-testid="delete-action" className="text-white">
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <span>Delete</span>
                        <FontAwesomeIcon icon={faTrash} />
                    </div>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};

const DocumentRow: React.FC<DocumentRowProps> = ({ doc, onSelect, onToggle }) => {
    const isFile = checkIfIsFile(doc);
    const handleOpenDocument = useCallback(() => {
        if ('s3Url' in doc && doc.s3Url) window.open(doc.s3Url, '_blank');
    }, [doc]);

    const handleRowClick = (e: React.MouseEvent) => {
        if ((e.target as HTMLElement).closest('.DocumentRow__dropdown')) return;
        if (isFile) {
            onSelect(doc);
        } else if (doc.directoryId)onToggle(doc.directoryId);
    };

    const renderDocumentIcon = (doc: DealDirectoryItem) => {
        const iconClass = classNames('DocumentRow__big-icon p-2 rounded', {
            [`DocumentRow__${isFile && 'extension' in doc ? iconColorByFilenameExtension[doc.extension] : ''}`]: true,
        });

        const iconElement = (
            <FontAwesomeIcon
                className={classNames('', {
                    DocumentRow__icon: !('children' in doc && doc.children),
                    'DocumentRow__big-icon': 'children' in doc && doc.children,
                })}
                icon={getFileIcon({ fileFormat: 'extension' in doc ? doc.extension : undefined, isExpanded: false })}
                color="white"
            />
        );

        return 'children' in doc && doc.children ? iconElement : <div className={iconClass}>{iconElement}</div>;
    };

    return (
        <Col md="3" sm="6" className="p-0 m-0 mb-2" onClick={handleRowClick}>
            <Card className="DocumentRow__doc-card">
                <DocumentOptions onDownload={handleOpenDocument} />
                <CardBody>
                    <div className="d-flex align-items-center">
                        {renderDocumentIcon(doc)}
                    </div>

                    <CardText tag="h4" className={isFile ? 'mt-2' : 'mt-4'}>{isFile ? doc.name : doc.directoryName}</CardText>

                    {isFile && (
                        <>
                            <CardText className="m-0 mt-2 mb-1 text-muted small">{doc.size}</CardText>
                            <CardText className="text-muted m-0">
                                Uploaded
                                {' '}
                                {doc.updatedAt}
                            </CardText>
                        </>
                    )}
                </CardBody>
            </Card>
        </Col>
    );
};

export default DocumentRow;
