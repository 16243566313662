import React, { useEffect, useState } from 'react';
import { IReportBuilderReport, NEW_REPORT_LABELS, ReportTypes } from 'views/ReportBuilder/constants';
import { NSCheckbox, NSLabel } from 'bricks';
import { IOptionAnyValue } from 'bricks/types';
import ProFormaSelector from 'views/Reports/components/ProFormaSelector/ProFormaSelector';
import ReportSaveCard from '../components/ReportSaveCard/ReportSaveCard';

export interface IProps {
    report: IReportBuilderReport;
    setNewReports: React.Dispatch<React.SetStateAction<IReportBuilderReport[]>>;
    dealId: number;
}
const ProFormaExecutiveSummarySaveCard = ({ report, setNewReports, dealId }: IProps) => {
    const [selectedProForma, setSelectedProForma] = useState<IOptionAnyValue>();
    const [isIncludePrintedOnDateChecked, setIsIncludePrintedOnDateChecked] = React.useState<boolean>(false);

    useEffect(() => {
        if (report.selectedProFormaId && report.selectedProFormaName) {
            setSelectedProForma({
                value: report.selectedProFormaId,
                label: report.selectedProFormaName,
            });
            setIsIncludePrintedOnDateChecked(!!report.includePrintedOnDate);
        }
    }, [report.selectedProFormaId, report.selectedProFormaName]);

    const handleSave = () => {
        setNewReports(prevReports => {
            const reportIndex = prevReports.findIndex(r => r.reportType === ReportTypes.PRO_FORMA_EXECUTIVE_SUMMARY);

            if (reportIndex === -1) {
                return prevReports;
            }

            const updatedReports = [...prevReports];
            updatedReports[reportIndex] = {
                ...prevReports[reportIndex],
                isEditing: false,
                selectedProFormaId: selectedProForma?.value,
                selectedProFormaName: selectedProForma?.label,
                includePrintedOnDate: isIncludePrintedOnDateChecked,
            };
            return updatedReports;
        });
    };

    const handleCancel = () => {
        setNewReports(prevReports => {
            const reportIndex = prevReports.findIndex(r => r.reportType === ReportTypes.PRO_FORMA_EXECUTIVE_SUMMARY);

            if (reportIndex === -1 || !prevReports[reportIndex]) {
                return prevReports;
            }

            const updatedReports = [...prevReports];
            const report = prevReports[reportIndex];

            if (report.selectedProFormaId) {
                updatedReports[reportIndex] = {
                    ...report,
                    isEditing: false,
                };
            } else {
                updatedReports.splice(reportIndex, 1);
            }

            return updatedReports;
        });
    };

    return (
        <ReportSaveCard
            header={NEW_REPORT_LABELS.PRO_FORMA_EXECUTIVE_SUMMARY}
            description="A exportable version of a Pro Forma's Executive Summary screen"
            handleSave={handleSave}
            handleCancel={handleCancel}
        >
            <NSLabel>Select Pro Forma</NSLabel>
            <ProFormaSelector
                dealId={dealId}
                proFormaId={selectedProForma?.value}
                onChangeProFormaSelection={(option: IOptionAnyValue) => setSelectedProForma(option)}
                dropdownClassName="Deal-selector__dropdown w-100"
                showDefaultOption
            />
            <hr />
            <NSCheckbox
                id="include-printed-on-date-pro-forma-executive-summary"
                checked={isIncludePrintedOnDateChecked}
                labelText="Include printed on date"
                callback={() => setIsIncludePrintedOnDateChecked(!isIncludePrintedOnDateChecked)}
            />
        </ReportSaveCard>
    );
};

export default ProFormaExecutiveSummarySaveCard;
