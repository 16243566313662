import React, { useEffect, useState } from 'react';
import {
    NSCard, NSDropdown, NSInput, NSLabel,
} from 'bricks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeftRight, faDollar, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import './Uses.scss';
import { numeralFormatter, numeralFormatterCurrency } from 'ns_libs/formatter';
import { Tooltip } from 'reactstrap';
import classNames from 'classnames';
import { calculateDeveloperFeePercent, calculateTotalDeveloperFee } from 'views/ProFormaWizard/helpers/calculateTotalDeveloperFeeHelper';
import { ProFormaModelIds } from 'constants/proForma';
import { FormikErrors, FormikTouched } from 'formik';

const HARD_COSTS_UNITS = [
    { value: 'sf', label: 'per SF' },
    { value: 'units', label: 'per Unit' },
];

export interface IUses {
    acquisitionCosts: number;
    hardCosts: number;
    totalHardCosts: number;
    hardCostType: string;
    softCosts: number;
    totalSoftCosts: number;
    softCostType: string;
    developerFee: number;
    interestReserve: number;
}

export interface IUsesProps {
    dealUnits?: number;
    dealSF?: number;
    proFormaModelId?: number;
    values: IUses;
    totalSources: number;
    onChange: (key: string, value: number | string) => void;
    onBlur: (key: string) => void;
    errors?: FormikErrors<IUses>;
    touched?: FormikTouched<IUses>;
}

const Uses = ({
    dealUnits, dealSF, proFormaModelId, values, totalSources, onChange, onBlur, errors, touched,
}: IUsesProps) => {
    const [totalDeveloperFee, setTotalDeveloperFee] = useState(0);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
    const filteredUnits = proFormaModelId === ProFormaModelIds.APARTMENT ? HARD_COSTS_UNITS : HARD_COSTS_UNITS.filter(unit => unit.value === 'sf');

    const updateCosts = (
        type: 'hardCosts' | 'totalHardCosts' | 'softCosts' | 'totalSoftCosts',
        newValue: number,
        relatedType: 'totalHardCosts' | 'hardCosts' | 'totalSoftCosts' | 'softCosts',
        multiplierType: 'hardCostType' | 'softCostType',
    ) => {
        const multiplier = values[multiplierType] === 'units' ? dealUnits : dealSF;

        if (type.includes('total')) {
            const baseValue = Number(numeralFormatter(newValue / (multiplier ?? 1), '0.00'));
            onChange(relatedType, baseValue);
            onChange(type, newValue);
        } else {
            const totalValue = Number(numeralFormatter(newValue * (multiplier ?? 1), '0.00'));
            onChange(type, newValue);
            onChange(relatedType, totalValue);
        }
    };

    useEffect(() => {
        // Only update costs if the type changes after initial mount
        if (touched?.hardCostType) {
            updateCosts('hardCosts', values.hardCosts, 'totalHardCosts', 'hardCostType');
        }
    }, [values.hardCostType]);

    useEffect(() => {
        // Only update costs if the type changes after initial mount
        if (touched?.softCostType) {
            updateCosts('softCosts', values.softCosts, 'totalSoftCosts', 'softCostType');
        }
    }, [values.softCostType]);

    useEffect(() => {
        const newTotalDeveloperFee = calculateTotalDeveloperFee(values);
        setTotalDeveloperFee(newTotalDeveloperFee);
    }, [values.acquisitionCosts, values.totalHardCosts, values.interestReserve, values.developerFee]);

    const handleDeveloperFeeChange = (newDeveloperFeeAmount: number) => {
        const newDeveloperFeePercent = calculateDeveloperFeePercent(newDeveloperFeeAmount, values);
        onChange('developerFeePercent', newDeveloperFeePercent);
        setTotalDeveloperFee(newDeveloperFeePercent);
    };
    const totalUses = values.acquisitionCosts + values.totalHardCosts + values.totalHardCosts + values.interestReserve + totalDeveloperFee;
    const totalUsesFormatted = numeralFormatterCurrency(totalUses);

    return (
        <div className="w-100">
            <small>1 of 2</small>
            <div className="Uses__title">Uses</div>
            <div className="Uses__subtitle pb-2">
                Add any information about this investment's uses. You will be able to add more detailed information later.
            </div>
            <div className="d-flex flex-row pb-2">
                <NSCard className="NSCard--level-2 p-2 mr-2 w-50 ml-0 mr-0">
                    <NSInput
                        id="acquisitionCosts"
                        type="number"
                        label="Acquisition costs"
                        name="acquisitionCosts"
                        placeholder="Enter amount"
                        prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                        value={values.acquisitionCosts || ''}
                        onChange={(e: { target: { value: number } }) => onChange('acquisitionCosts', Number(e.target.value))}
                        onBlur={() => onBlur('acquisitionCosts')}
                        showErrorMsg={touched?.acquisitionCosts && !!errors?.acquisitionCosts}
                        errorMsg={touched?.acquisitionCosts ? errors?.acquisitionCosts : ''}
                    />
                </NSCard>
                <NSCard className="NSCard--level-2 p-2 w-50 ml-0 mr-0">
                    <NSLabel className="text-dark font-weight-normal" htmlFor="hardCosts">
                        Hard costs
                    </NSLabel>
                    <div className="d-flex flex-row justify-content-center align-items-baseline">
                        <div className="d-flex flex-column">
                            <NSInput
                                id="hardCosts"
                                dataTestId="hardCosts"
                                type="number"
                                name="hardCosts"
                                placeholder="Enter amount"
                                prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                                value={values.hardCosts ? Number(numeralFormatter(values.hardCosts, '0.00')) : ''}
                                onChange={(e: { target: { value: number } }) => {
                                    updateCosts('hardCosts', Number(e.target.value), 'totalHardCosts', 'hardCostType');
                                }}
                                appendInputAddon={(
                                    <NSDropdown
                                        options={filteredUnits}
                                        selectedOption={filteredUnits.find(option => option.value === values.hardCostType)}
                                        customSelectedOptionText={filteredUnits.find(option => option.value === values.hardCostType)?.label}
                                        handleSelectOption={option => onChange('hardCostType', option.value)}
                                        badgeColor=""
                                        isBadgeToggle
                                        toggleClassName="p-0 m-0"
                                    />
                                )}
                                onBlur={() => onBlur('hardCosts')}
                                showErrorMsg={touched?.hardCosts && !!errors?.hardCosts}
                                errorMsg={touched?.hardCosts ? errors?.hardCosts : ''}
                            />
                        </div>
                        <FontAwesomeIcon icon={faLeftRight} className="mx-2" />
                        <div className="d-flex flex-column">
                            <NSInput
                                id="totalHardCosts"
                                type="number"
                                name="totalHardCosts"
                                value={values.totalHardCosts || ''}
                                onChange={(e: { target: { value: number } }) => {
                                    updateCosts('totalHardCosts', Number(e.target.value), 'hardCosts', 'hardCostType');
                                }}
                                prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                                appendInputAddon={<div>Total</div>}
                                onBlur={() => {
                                    onBlur('totalHardCosts');
                                    onBlur('hardCosts');
                                }}
                                showErrorMsg={touched?.totalHardCosts && !!errors?.totalHardCosts}
                                errorMsg={touched?.totalHardCosts ? errors?.totalHardCosts : ''}
                            />
                        </div>
                    </div>
                </NSCard>
            </div>
            <div className="d-flex flex-row pb-2">
                <NSCard className="NSCard--level-2 p-2 mr-2 w-50 ml-0 mr-0">
                    <NSLabel className="text-dark font-weight-normal">Soft costs</NSLabel>
                    <div className="d-flex flex-row justify-content-center align-items-baseline">
                        <div className="d-flex flex-column">
                            <NSInput
                                id="softCosts"
                                dataTestId="softCosts"
                                type="number"
                                name="softCosts"
                                placeholder="Enter amount"
                                prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                                value={values.softCosts ? Number(numeralFormatter(values.softCosts, '0.00')) : ''}
                                onChange={(e: { target: { value: number } }) => {
                                    updateCosts('softCosts', Number(e.target.value), 'totalSoftCosts', 'softCostType');
                                }}
                                appendInputAddon={(
                                    <NSDropdown
                                        options={filteredUnits}
                                        selectedOption={filteredUnits.find(option => option.value === values.softCostType)}
                                        customSelectedOptionText={filteredUnits.find(option => option.value === values.softCostType)?.label}
                                        handleSelectOption={option => onChange('softCostType', option.value)}
                                        badgeColor=""
                                        isBadgeToggle
                                        toggleClassName="p-0 m-0"
                                    />
                                )}
                                onBlur={() => onBlur('softCosts')}
                                showErrorMsg={touched?.softCosts && !!errors?.softCosts}
                                errorMsg={touched?.softCosts ? errors?.softCosts : ''}
                            />
                        </div>
                        <FontAwesomeIcon icon={faLeftRight} className="mx-2" />
                        <div className="d-flex flex-column">
                            <NSInput
                                id="totalSoftCosts"
                                type="number"
                                name="totalSoftCosts"
                                value={values.totalSoftCosts || ''}
                                onChange={(e: { target: { value: number } }) => {
                                    updateCosts('totalSoftCosts', Number(e.target.value), 'softCosts', 'softCostType');
                                }}
                                prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                                appendInputAddon={<div>Total</div>}
                                onBlur={() => {
                                    onBlur('totalSoftCosts');
                                    onBlur('softCosts');
                                }}
                                showErrorMsg={touched?.totalSoftCosts && !!errors?.totalSoftCosts}
                                errorMsg={touched?.totalSoftCosts ? errors?.totalSoftCosts : ''}
                            />
                        </div>
                    </div>
                </NSCard>
                <div className="d-flex flex-row w-50">
                    <NSCard className="NSCard--level-2 px-2">
                        <div className="d-flex flex-row pt-2">
                            <NSLabel className="text-dark font-weight-normal">Developer fee</NSLabel>
                            <FontAwesomeIcon className="px-1 text-white" id="developer-fee-tooltip" icon={faCircleInfo} />
                            <Tooltip target="developer-fee-tooltip" isOpen={tooltipOpen} toggle={toggleTooltip} placement="top">
                                Developer fee is calculated on total uses.
                            </Tooltip>
                        </div>
                        <div className="d-flex flex-row justify-content-center align-items-baseline">
                            <div className="Uses__developer-fee-percent">
                                <NSInput
                                    id="developerFee"
                                    dataTestId="developerFee"
                                    inputClassName="pr-2"
                                    type="number"
                                    name="developerFee"
                                    placeholder="Enter percentage"
                                    value={values.developerFee || ''}
                                    onChange={(e: { target: { value: number } }) => onChange('developerFee', Number(e.target.value))}
                                    appendInputAddon={<div>%</div>}
                                    onBlur={() => onBlur('developerFee')}
                                    showErrorMsg={touched?.developerFee && !!errors?.developerFee}
                                    errorMsg={touched?.developerFee ? errors?.developerFee : ''}
                                />
                            </div>
                            <div className="Uses__developer-fee-amount">
                                <NSInput
                                    id="developerFeeAmount"
                                    type="number"
                                    name="developerFeeAmount"
                                    value={totalDeveloperFee}
                                    onChange={(e: { target: { value: number } }) => handleDeveloperFeeChange(Number(e.target.value))}
                                    prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                                    onBlur={() => onBlur('developerFeeAmount')}
                                />
                            </div>
                        </div>
                    </NSCard>
                    <NSCard className="NSCard--level-2 flex-grow-1 ml-2 p-2">
                        <NSInput
                            id="interestReserve"
                            dataTestId="interestReserve"
                            type="number"
                            label="Interest reserve"
                            name="interestReserve"
                            placeholder="Enter amount"
                            prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                            value={values.interestReserve || ''}
                            onChange={(e: { target: { value: number } }) => onChange('interestReserve', Number(e.target.value))}
                            onBlur={() => onBlur('interestReserve')}
                            showErrorMsg={touched?.interestReserve && !!errors?.interestReserve}
                            errorMsg={touched?.interestReserve ? errors?.interestReserve : ''}
                        />
                    </NSCard>
                </div>
            </div>
            <NSCard
                className={classNames('p-2 mt-1 justify-content-center', {
                    Uses__total: totalSources >= totalUses,
                    'Uses__total--highlight': totalSources < totalUses,
                })}
            >
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <span className="font-weight-bold text-white">Total uses:</span>
                    <div className="d-flex flex-row align-items-center justify-content-center">
                        {totalSources < totalUses ? <NSLabel className="Uses__label-warning Uses__total-label">Uses exceed sources</NSLabel> : null}
                        <span className="font-weight-bold text-white">{totalUsesFormatted}</span>
                    </div>
                </div>
            </NSCard>
        </div>
    );
};

export default Uses;
