/* eslint-disable no-param-reassign */
import { Scenario } from '../types';

export function highlightFields(array: Scenario[], criteria: Record<string, string>): Scenario[] {
    if (!array || !Array.isArray(array) || array.length === 0) return [];

    if (array.length === 1) {
        Object.keys(array[0].cellClasses || {}).forEach(field => {
            delete array[0].cellClasses[field];
        });
        return array;
    }

    Object.keys(criteria).forEach(field => {
        const isLeast = criteria[field] === 'least';

        const targetValue = isLeast
            ? Math.min(...array.map(item => parseFloat(item[field])).filter(value => !Number.isNaN(value)))
            : Math.max(...array.map(item => parseFloat(item[field])).filter(value => !Number.isNaN(value)));

        array.forEach(item => {
            if (!item.cellClasses) item.cellClasses = {};
            const value = parseFloat(item[field]);
            if (value === targetValue && !Number.isNaN(value)) {
                item.cellClasses[field] = 'green-highlight';
            }
        });
    });

    return array;
}
