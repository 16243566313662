import { useState, useMemo } from 'react';
import { IOptionAnyValue } from 'bricks/types';
import { IDeal, IDealSelection } from '../types';
import { useGetOrgDealSummaries } from '.';

type IDealSelectionManager = {
    deals: IDeal[];
    dealOptions: IOptionAnyValue[];
    selectedDeals: IDealSelection[];
    addSelectedDeal: (dealSelection?: IOptionAnyValue) => void;
    removeSelectedDeal: (dealId: number) => void;
    clearSelectedDeals: () => void;
    updateSelectedProFormaId: (selectedDealId: number, selectedProFormaId: number) => void;
    restoreInitialSelection: () => void;
};

export const useDealSelectionManager = (orgId: string, initialSelectedDeals: IDealSelection[] = []): IDealSelectionManager => {
    // State to manage selected deals, initialized with the optional starting set
    const [selectedDeals, setSelectedDeals] = useState<IDealSelection[]>(initialSelectedDeals);

    // Fetch deals for org
    const { data: dealSummaries } = useGetOrgDealSummaries(orgId, { enabled: true });

    // Map deal summaries to IDeal objects
    const deals: IDeal[] = useMemo(() => (
        dealSummaries?.map(deal => ({
            id: deal.id,
            dealName: deal.dealName,
            dealType: deal.dealType,
            project: 'Placeholder Project', // Replace this with actual data once available
            projectType: 'Placeholder ProjectType', // Replace this with actual data once available
            defaultProFormaId: deal.defaultProFormaId,
        })) || []
    ), [dealSummaries]);

    // Generate options for selecting deals
    const dealOptions = useMemo(() => (
        deals?.map(deal => ({
            value: deal.id,
            label: deal.dealName,
            disabled: selectedDeals.some(selected => selected.deal.id === deal.id), // Disable if already selected
        })) || []
    ), [deals, selectedDeals]);

    // Add a deal to the selected list
    const addSelectedDeal = (dealSelection?: IOptionAnyValue) => {
        if (!dealSelection) return;

        const selectedDeal = deals.find(deal => deal.id === dealSelection.value);
        if (!selectedDeal) return;

        setSelectedDeals(prev => [...prev, { deal: selectedDeal, proFormaId: selectedDeal.defaultProFormaId, proForma: undefined }]);
    };

    // Remove a deal from the selected list
    const removeSelectedDeal = (dealId: number) => {
        setSelectedDeals(prev => prev.filter(d => d.deal.id !== dealId));
    };

    // Clear all selected deals
    const clearSelectedDeals = () => {
        setSelectedDeals([]);
    };

    // Update selected deal with pro forma selection
    const updateSelectedProFormaId = (selectedDealId: number, selectedProFormaId: number) => {
        const updatedDeals = selectedDeals.map(i => {
            if (i.deal.id === selectedDealId) {
                return { ...i, proFormaId: selectedProFormaId };
            }
            return i;
        });
        setSelectedDeals(updatedDeals);
    };

    // Restore selected deals to initial state
    const restoreInitialSelection = () => {
        setSelectedDeals(initialSelectedDeals);
    };

    // Expose deals, options, and management functions
    return {
        deals,
        dealOptions,
        selectedDeals,
        addSelectedDeal,
        removeSelectedDeal,
        clearSelectedDeals,
        updateSelectedProFormaId,
        restoreInitialSelection,
    };
};

export default useDealSelectionManager;
