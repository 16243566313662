import { IRowCells, ITableData } from 'types/excelExport';
import { ColSortConfig, SortDirection } from 'types/sort';
import { numeralFormatterCurrency } from 'ns_libs/formatter';
import { AttributeKey } from 'views/Reports/types';
import { AttributeKeysMap, dollarValueAttributes, percentageValueAttributes } from './ConfigurationSidebar/constants';

const EXCEL_NSBLUE = 'e6e8e8';
const EXCEL_WHITE = '0FB3FF';
const BORDER_COLOR = '000000';

const excelPercentFormat = '0.00%';
const excelUSDFormat = '$#,##0.00';
const defaultFontSize = 12;
const borderStyle = { style: 'medium', color: { argb: BORDER_COLOR } };

export const formatExcelExportData = (title: string, subtitle: string, data: {
    id: number;
    values: string[];
}[], columns: AttributeKey[]) => {
    const topHeaderRows = [
        {
            rowFont: { bold: true, size: defaultFontSize },
            rowCells: [{ value: title }],
        },
        {
            rowFont: { bold: true, size: defaultFontSize },
            rowCells: [{ value: subtitle }],
        },
    ];

    const tableHeaderLabelsRow = {
        rowFont: {
            bold: true,
            size: 12,
            color: { argb: EXCEL_NSBLUE },
        },
        rowFill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: EXCEL_WHITE },
        },
        rowCells: columns.map((col, idx) => ({
            value: AttributeKeysMap[col],
            width: 20,
            border: {
                top: borderStyle,
                left: idx === 0 ? borderStyle : undefined,
                right: idx === columns.length - 1 ? borderStyle : undefined,
            },
        })),
    };

    const newTable = data.map((deal, dealIdx) => {
        const newRow: ITableData = {
            rowCells: [],
        };
        deal.values.forEach((cell: string, idx: number) => {
            const newCell: IRowCells = {
                value: cell,
                border: {
                    left: idx === 0 ? borderStyle : undefined,
                    right: idx === deal.values.length - 1 ? borderStyle : undefined,
                    bottom: dealIdx === data.length - 1 ? borderStyle : undefined,
                },
            };
            if (dollarValueAttributes.includes(AttributeKeysMap[columns[idx]])) newCell.numFmt = excelUSDFormat;
            if (percentageValueAttributes.includes(AttributeKeysMap[columns[idx]])) newCell.numFmt = excelPercentFormat;
            return newRow.rowCells.push(newCell);
        });
        return newRow;
    });

    return {
        topHeaderRows,
        reportTables: [
            {
                tableHeaderRows: [tableHeaderLabelsRow],
                tableData: newTable,
                tableFooter: {
                    rowCells: [],
                },
            },
        ],
    };
};

export const formatPipelineDealsTableData = (
    data: any[],
    selectedColumns: AttributeKey[],
    colSortConfig: ColSortConfig<AttributeKey> | undefined,
): { id: number; values: string[] }[] => {
    const selectedColumnsData = data.map(row => {
        const formattedRow: { id: number; values: string[] } = {
            id: row.deal_id,
            values: [],
        };

        selectedColumns.forEach((col: string) => {
            let formattedValue = '';

            if (percentageValueAttributes.includes(AttributeKeysMap[col])) {
                formattedValue = row[col] !== null && row[col] !== undefined ? `${row[col]}%` : '';
            } else if (dollarValueAttributes.includes(AttributeKeysMap[col])) {
                formattedValue = row[col] !== null && row[col] !== undefined ? numeralFormatterCurrency(row[col]) : '';
            } else if (col === 'created_at') {
                formattedValue = row[col] ? new Date(row[col]).toISOString().split('T')[0] : '';
            } else {
                formattedValue = row[col] ?? '';
            }

            formattedRow.values.push(formattedValue);
        });

        return formattedRow;
    });

    const sortedData = selectedColumnsData.sort((a, b) => {
        if (!colSortConfig) {
            return 0;
        }
        const { key, direction } = colSortConfig;

        const columnIndex = selectedColumns.indexOf(key);

        if (columnIndex === -1) return 0;

        const aValue = a.values[columnIndex];
        const bValue = b.values[columnIndex];

        if (direction === SortDirection.ASC) {
            return aValue > bValue ? 1 : -1;
        }
        return aValue < bValue ? 1 : -1;
    });

    return sortedData;
};
