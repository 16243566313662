import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAuthContext from 'contexts/AuthContext';
import React from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { formatUTCtoLocal } from 'ns_libs/formatter';
import NSTable from '../../../../bricks/NSTable/NSTable';
import { headerCellClassName } from '../../constants';
import { URLS } from '../../../../services/urls';
import { useGetUploadedProFormasByDeal } from '../../../../queries/UploadedProForma/useGetUploadedProFormasByDeal';

const UploadedProFormasTable = () => {
    const navigate = useNavigate();
    const { dealId } = useParams<{ dealId: string }>();
    const { selectedOrganizationId } = useAuthContext();
    const { data: uploadedProFormas } = useGetUploadedProFormasByDeal({
        organizationId: selectedOrganizationId as string,
        dealId: Number(dealId),
    });

    const handleRowClick = (id: number) => {
        const path = generatePath(URLS.PRO_FORMA.UPLOADED, {
            dealId: Number(dealId),
            proFormaId: id,
        });
        navigate(path);
    };

    return (
        <NSTable>
            <thead className="NSTable__thead">
                <tr className="NSTable__thead__tr">
                    <th className="NSTable__thead__tr__th text-dark">Name</th>
                    <th className="NSTable__thead__tr__th">
                        <div className={headerCellClassName}>
                            <span>Uploaded</span>
                            <FontAwesomeIcon icon="sort" />
                        </div>
                    </th>
                    <th className="NSTable__thead__tr__th">
                        <div className={headerCellClassName}>
                            <span>Uploaded by</span>
                            <FontAwesomeIcon icon="sort" />
                        </div>
                    </th>
                    <th className="NSTable__thead__tr__th">
                        <div className={headerCellClassName}>
                            <span>Size</span>
                            <FontAwesomeIcon icon="sort" />
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                {uploadedProFormas?.map(item => (
                    <tr
                        key={item.id}
                        onClick={() => handleRowClick(item.id)}
                        className="NSTable__tbody__tr cursor-pointer hover:bg-gray-100"
                    >
                        <td className="NSTable__tbody__tr__td text-primary cursor--pointer">
                            {item.s3FilePath.substring(item.s3FilePath.lastIndexOf('/') + 1)}
                        </td>
                        <td className="NSTable__tbody__tr__td">
                            {formatUTCtoLocal(item.createdAt, 'M/d/yy h:mm a')}
                        </td>
                        <td className="NSTable__tbody__tr__td">
                            {item.createdBy || 'N/A'}
                        </td>
                        <td className="NSTable__tbody__tr__td">
                            {item.fileSize ? `${item.fileSize}MB` : 'N/A'}
                        </td>
                    </tr>
                ))}
            </tbody>
        </NSTable>
    );
};

export default UploadedProFormasTable;
