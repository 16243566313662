import { NSButton, NSCard } from 'bricks';
import React from 'react';
import { faArrowLeftToLine, faArrowRight, faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { useProFormaWizard } from '../../context/ProFormaWizardProvider';
import './ProFormaWizardStepper.scss';

const ProFormaWizardStepper = () => {
    const {
        currentStep, handleNextStep, handlePrevStep, isLoading,
    } = useProFormaWizard();

    const steps = ['Basic information', 'Development', 'Operations'];

    return (
        <NSCard className="ProFormaWizardStepper__card shadow">
            <div className="d-flex mx-2 justify-content-between align-items-center">
                <div className="ProFormaWizardStepper__buttonContainer">
                    <NSButton
                        className="ProFormaWizardStepper__backButton"
                        icon={currentStep === 1 ? faArrowLeftToLine : faArrowLeft}
                        color="secondary"
                        text={currentStep === 1 ? 'Exit' : 'Back'}
                        callback={handlePrevStep}
                        disabled={isLoading}
                    />
                </div>

                <ol className="ProFormaWizardStepper__horizontalStepper">
                    {steps.map((step, index) => (
                        <li
                            key={step}
                            className={`ProFormaWizardStepper__horizontalStepper__item 
                                ${currentStep === index + 1 ? 'ProFormaWizardStepper__horizontalStepper__item--active' : ''}`}
                        >
                            {currentStep === index + 1 ? <div className="ProFormaWizardStepper__horizontalStepper__small--active" /> : null}
                            <p
                                className={`ProFormaWizardStepper__horizontalStepper__title ${
                                    currentStep === index + 1 ? 'ProFormaWizardStepper__horizontalStepper__title--active' : ''
                                }`}
                            >
                                {step}
                            </p>
                        </li>
                    ))}
                </ol>

                <div className="ProFormaWizardStepper__buttonContainer">
                    <NSButton color="secondary" text="Save draft" className="" callback={() => console.log('Save draft clicked')} disabled={isLoading} />
                    <NSButton
                        className="ProFormaWizardStepper__nextButton"
                        icon={faArrowRight}
                        iconPosition="right"
                        color="primary"
                        text={currentStep === 3 ? 'Finalize' : 'Next'}
                        callback={handleNextStep}
                        isLoading={isLoading}
                        disabled={isLoading}
                    />
                </div>
            </div>
        </NSCard>
    );
};

export default ProFormaWizardStepper;
