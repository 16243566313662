/* eslint-disable no-unused-vars */
import * as Yup from 'yup';

import { buildDealSchemaFields } from './utils';

export type IFormValues = Yup.InferType<ReturnType<typeof buildDealSchemaFields>>;

export interface IVariables {
    postData: IFormValues;
    orgId: string;
}

export interface IUpdateVariables {
    postData: Partial<IFormValues>;
    orgId: string;
    dealId: number;
}

export interface IDealAttribute {
    id: number;
    name: DefaultAttributes | string;
    required: boolean;
    fixed: boolean;
    correlationId: string;
    inputType?: 'text' | 'number' | 'amount' | 'select' | 'multi_select';
    autopopulated: boolean;
    organizationId?: number;
    options?: string[];
    isCustom: boolean;
}

export interface IDropdownOption {
    id: number;
    name: string;
}

export interface IDropdowns {
    dealTypes: IDropdownOption[];
    leadSources: IDropdownOption[];
    leasingStrategies: IDropdownOption[];
    ranks: IDropdownOption[];
}

export interface ICustomAttributeValue {
    attributeId: string;
    valueText?: string;
    valueNumber?: number;
    valueSingleSelect?: string;
    valueMultiSelect?: string[];
}

export enum DefaultAttributes {
    NAME = 'Name',
    ADDRESS = 'Address',
    CITY = 'City',
    COUNTY = 'County',
    STATE = 'State',
    ZIP_CODE = 'Zip code',
    MARKET = 'Market',
    SUBMARKET = 'Submarket',
    DATE_CREATED = 'Date created',
    DESCRIPTION = 'Description',
    DEAL_TYPE = 'Deal type',
    ASSET_CLASS = 'Asset class',
    CONSTRUCTION_TYPE = 'Construction type',
    PARKING_TYPE = 'Parking type',
    UNITS = 'Units',
    PHASE = 'Phase',
    LEAD_SOURCE = 'Lead source',
    LEASING_STRATEGY = 'Leasing strategy',
    RANK = 'Rank',
    ACQUISITION_PRICE = 'Acquisition price',
    TARGET_RETURN_ON_COST = 'Target return on cost',
    TARGET_RETURN_ON_EQUITY = 'Target return on equity',
    TARGET_PROFIT_RETURN = 'Target profit return',
    IRR = 'IRR',
    IRR_PROJECT_ACTUALS = 'IRR (using project actuals)',
    EQUITY_MULTIPLE = 'Equity multiple',
    EQUITY_MULTIPLE_PROJECT_ACTUALS = 'Equity multiple (using project actuals)',
    DEAL_CREATOR = 'Deal creator',
    DEAL_OWNER = 'Deal owner',
    DEVELOPMENT_MANAGER = 'Development manager',
    INVESTOR_LP = 'Investor - LP',
    LENDER_PRIVATE = 'Lender (private)',
    LEGAL_SERVICES = 'Legal services',
    PROJECT_ARCHITECT = 'Project architect',
    SURVEYOR = 'Surveyor',
    ENVIRONMENTAL_CONSULTANT = 'Environmental consultant',
}
