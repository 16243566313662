import React, { forwardRef, useState } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollar, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { useAuthContext } from 'contexts/AuthContext';
import { IOptionAnyValue } from 'bricks/types';
import useToast from 'hooks/useToast';
import NSSwitch from 'bricks/NSSwitch/NSSwitch';
import { useLocation, useNavigate } from 'react-router-dom';
import { Deal } from 'views/Deals/types';
import { UnitTypeIds } from 'constants/unitTypes';
import RenderIf from 'components/RenderIf/RenderIf';
import { useCreateDeal } from './hooks/useCreateDeal';
import { DefaultAttributes, IFormValues } from './types';
import {
    buildDealSchemaFields,
} from './utils';
import { useUpdateDeal } from './hooks/useUpdateDeal';
import { useGetAttributes } from './hooks/useGetAttributes';
import DefaultAttributeInput from './components/DefaultAttributeInput';
import DefaultAttributeDropdown from './components/DefaultAttributeDropdown';
import AddressSection from './components/AddressSection';
import UnitsSection from './components/UnitsSection';
import { useGetDealFormSelectOptions } from './hooks/useGetDealFormSelectOptions';
import CustomAttributeInput from './components/CustomAttributeInput/CustomAttributeInput';

interface DealFormProps {
    setMapCoordinates: React.Dispatch<React.SetStateAction<{ center: { lat: number; lng: number } }>>;
    initialValues?: Deal;
}

const DealForm = forwardRef<FormikProps<IFormValues>, DealFormProps>(({ setMapCoordinates, initialValues }, ref) => {
    const navigate = useNavigate();
    const { selectedOrganizationId } = useAuthContext();
    const { showSuccess, showError, ToastContainerElement } = useToast();
    const {
        data: {
            assetTypeOptions,
            dealTypeOptions,
            leadSourceOptions,
            leasingStrategyOptions,
            rankOptions,
            constructionTypeOptions,
            phaseOptions,
            marketOptions,
            organizationUserOptions,
        },
        getSelectedAssetType,
        loadingStates,
    } = useGetDealFormSelectOptions(selectedOrganizationId);
    const { data: attributesConfig, isLoading: isLoadingAttributes } = useGetAttributes();
    const customAttributes = attributesConfig?.filter(({ isCustom }) => isCustom) || [];

    const initialSubTypeOptions = initialValues?.assetType?.correlationId
        ? getSelectedAssetType(initialValues.assetType?.correlationId)?.subAssetTypes
            ?.filter(({ description }) => description)
            ?.map(({ correlationId, description }) => ({
                label: description!,
                value: correlationId,
            })) || []
        : [];
    const [subTypeOptions, setSubTypeOptions] = useState<IOptionAnyValue<string>[]>(initialSubTypeOptions);

    const [showRequiredOnly, setShowRequiredOnly] = useState<boolean>(false);
    const toggleShowRequiredOnly = () => {
        setShowRequiredOnly(!showRequiredOnly);
    };

    const { mutate: createDeal } = useCreateDeal();
    const { mutate: updateDeal } = useUpdateDeal();

    const location = useLocation();
    const scenarioId = location.state?.scenarioId;

    const handleSubmit = async (values: IFormValues) => {
        if (isLoadingAttributes) return;
        if (initialValues && initialValues?.id) {
            updateDeal(
                {
                    orgId: selectedOrganizationId!,
                    dealId: Number(initialValues.id),
                    postData: values,
                },
                {
                    onSuccess: data => {
                        navigate('/deals');
                        setTimeout(() => showSuccess('Deal updated successfully!'), 500);
                    },
                    onError: error => {
                        console.error('Error updating deal:', error);
                        showError('Failed to update deal. Please try again.');
                    },
                },
            );
        } else {
            createDeal(
                {
                    orgId: selectedOrganizationId!,
                    postData: {
                        ...values,
                        ...(scenarioId && typeof scenarioId === 'number' ? { scenarioId } : {}),
                    },
                },
                {
                    onSuccess: data => {
                        showSuccess('Deal created successfully!');
                        setTimeout(() => navigate(-1), 2000);
                    },
                    onError: error => {
                        console.error('Error creating deal:', error);
                        showError('Failed to create deal. Please try again.');
                    },
                },
            );
        }
    };

    const defaultValues: IFormValues = {
        dealName: initialValues?.dealName,
        address: initialValues?.address || undefined,
        city: initialValues?.city || undefined,
        county: initialValues?.county || undefined,
        state: initialValues?.state || undefined,
        latitude: initialValues?.latitude || undefined,
        longitude: initialValues?.longitude || undefined,
        zipCode: initialValues?.zipCode || undefined,
        coordinatesSwitch: Boolean(initialValues && !initialValues.address && initialValues?.latitude && initialValues?.longitude),
        description: initialValues?.description || undefined,
        acquisitionPrice: initialValues?.acquisitionPrice,
        marketId: initialValues?.market?.correlationId,
        submarket: initialValues?.subMarket || undefined,
        dealTypeId: initialValues?.dealType?.id,
        assetTypeId: initialValues?.assetType?.correlationId,
        assetSubtypeId: initialValues?.assetSubtype?.correlationId,
        constructionTypeId: initialValues?.constructionType?.correlationId,
        phaseId: initialValues?.subprojectStage?.correlationId,
        dealOwnerId: initialValues?.dealOwner?.correlationId,
        leadSourceId: initialValues?.leadSource?.id,
        leasingStrategyId: initialValues?.leasingStrategy?.id,
        rankId: initialValues?.rank?.id,
        grossBuildSf: initialValues?.dealUnitTypes?.find(unitType => unitType.unitType === UnitTypeIds.GROSS_SF)?.value,
        rentableSf: initialValues?.dealUnitTypes?.find(unitType => unitType.unitType === UnitTypeIds.RENTABLE_SF)?.value,
        acres: initialValues?.dealUnitTypes?.find(unitType => unitType.unitType === UnitTypeIds.ACRE)?.value,
        residentialUnits: initialValues?.dealUnitTypes?.find(unitType => unitType.unitType === UnitTypeIds.RESIDENTIAL_UNIT)?.value,
        lots: initialValues?.dealUnitTypes?.find(unitType => unitType.unitType === UnitTypeIds.LOT)?.value,
        megawatts: initialValues?.dealUnitTypes?.find(unitType => unitType.unitType === UnitTypeIds.MEGAWATT)?.value,
        beds: initialValues?.dealUnitTypes?.find(unitType => unitType.unitType === UnitTypeIds.BED)?.value,
        customAttributes: initialValues?.customAttributes?.map(
            ({
                attributeId, valueText, valueNumber, valueSingleSelect, valueMultiSelect,
            }) => ({
                attributeId,
                ...(valueText && { valueText }),
                ...(valueNumber && { valueNumber }),
                ...(valueSingleSelect && { valueSingleSelect }),
                ...(valueMultiSelect && { valueMultiSelect }),
            }),
        ) || [],
    };

    const hasRequiredFieldInSection = (attributes: DefaultAttributes[]) => {
        if (!showRequiredOnly) return true;
        return attributes.some(attr => attributesConfig?.find(config => config.name === attr)?.required);
    };

    const hasRequiredCustomAttributes = () => {
        if (!showRequiredOnly) return true;
        return customAttributes.some(attr => attr.required);
    };

    return (
        <div className="w-50 p-3 mr-2 CreateDeal__form">
            <div className="d-flex justify-content-between text-white mb-1">
                <div className="text-uppercase text-primary">
                    General
                    {isLoadingAttributes && (
                        <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            className=""
                            size="sm"
                        />
                    )}
                </div>
                <NSSwitch
                    id="showRequiredOnly"
                    name="showRequiredOnly"
                    checked={showRequiredOnly}
                    onChange={toggleShowRequiredOnly}
                    label="Only show required fields"
                />
            </div>
            <Formik<IFormValues>
                initialValues={defaultValues}
                validationSchema={buildDealSchemaFields(attributesConfig)}
                onSubmit={handleSubmit}
                innerRef={ref}
            >
                <Form>
                    <DefaultAttributeInput
                        label="Deal name"
                        name="dealName"
                        placeholder="Enter deal name"
                        attribute={DefaultAttributes.NAME}
                        showRequiredOnly={showRequiredOnly}
                    />
                    <AddressSection
                        showRequiredOnly={showRequiredOnly}
                        setMapCoordinates={setMapCoordinates}
                    />
                    <DefaultAttributeDropdown
                        label="Market"
                        name="marketId"
                        options={marketOptions}
                        attribute={DefaultAttributes.MARKET}
                        showRequiredOnly={showRequiredOnly}
                        className="mt-2"
                        isLoading={loadingStates.markets}
                    />
                    <DefaultAttributeInput
                        label="Submarket"
                        name="submarket"
                        placeholder="Enter submarket"
                        attribute={DefaultAttributes.SUBMARKET}
                        showRequiredOnly={showRequiredOnly}
                        className="mt-2 w-50"
                    />

                    <DefaultAttributeInput
                        label="Description"
                        name="description"
                        placeholder="Enter deal description"
                        attribute={DefaultAttributes.DESCRIPTION}
                        showRequiredOnly={showRequiredOnly}
                        type="textarea"
                        rows={4}
                        className="mt-2"
                    />

                    <div className="CreateDeal__form__divider my-3" />
                    <div>
                        <div className="text-uppercase text-primary mb-1">
                            Deal Information
                            {isLoadingAttributes && (
                                <FontAwesomeIcon
                                    icon={faSpinner}
                                    spin
                                    className=""
                                    size="sm"
                                />
                            )}
                        </div>
                        <div className="d-flex mt-2">
                            <DefaultAttributeDropdown
                                label="Deal type"
                                name="dealTypeId"
                                options={dealTypeOptions}
                                attribute={DefaultAttributes.DEAL_TYPE}
                                showRequiredOnly={showRequiredOnly}
                                className="w-50 mr-2"
                                isLoading={loadingStates.dealTypes}
                            />
                            <DefaultAttributeDropdown
                                label="Asset class"
                                name="assetTypeId"
                                options={assetTypeOptions}
                                attribute={DefaultAttributes.ASSET_CLASS}
                                showRequiredOnly={showRequiredOnly}
                                className="w-50"
                                isLoading={loadingStates.assetTypes}
                                onSelect={(option, setFieldValue) => {
                                    const selectedAssetType = getSelectedAssetType(option.value);
                                    const subTypes = selectedAssetType?.subAssetTypes;
                                    const newSubTypeOptions = subTypes
                                        ?.filter(({ description }) => description)
                                        ?.map(({ correlationId, description }) => ({
                                            label: description!,
                                            value: correlationId,
                                        })) || [];

                                    setSubTypeOptions(newSubTypeOptions);
                                    setFieldValue('hasSubtype', newSubTypeOptions.length > 0);
                                    setFieldValue('assetSubtypeId', undefined);
                                }}
                            />
                            <DefaultAttributeDropdown
                                label="Subtype"
                                name="assetSubtypeId"
                                options={subTypeOptions}
                                attribute={DefaultAttributes.ASSET_CLASS}
                                showRequiredOnly={showRequiredOnly}
                                className="w-50 ml-2"
                                additionalRenderCondition={subTypeOptions.length > 0}
                                additionalRequiredCondition={!!subTypeOptions.length}
                            />
                        </div>
                        <UnitsSection showRequiredOnly={showRequiredOnly} />
                        <div className="d-flex mt-2">
                            <DefaultAttributeDropdown
                                label="Construction type"
                                name="constructionTypeId"
                                options={constructionTypeOptions}
                                attribute={DefaultAttributes.CONSTRUCTION_TYPE}
                                showRequiredOnly={showRequiredOnly}
                                className="w-50 mr-2"
                                isLoading={loadingStates.constructionTypes}
                            />
                            <DefaultAttributeDropdown
                                label="Phase"
                                name="phaseId"
                                options={phaseOptions}
                                attribute={DefaultAttributes.PHASE}
                                showRequiredOnly={showRequiredOnly}
                                className="w-50 mr-2"
                                isLoading={loadingStates.phases}
                            />
                        </div>

                        <div className="d-flex mt-2">
                            <DefaultAttributeDropdown
                                label="Lead source"
                                name="leadSourceId"
                                options={leadSourceOptions}
                                attribute={DefaultAttributes.LEAD_SOURCE}
                                showRequiredOnly={showRequiredOnly}
                                className="w-50"
                                isLoading={loadingStates.leadSources}
                            />
                            <DefaultAttributeDropdown
                                label="Leasing strategy"
                                name="leasingStrategyId"
                                options={leasingStrategyOptions}
                                attribute={DefaultAttributes.LEASING_STRATEGY}
                                showRequiredOnly={showRequiredOnly}
                                className="w-50 ml-2"
                                isLoading={loadingStates.leasingStrategies}
                            />
                            <DefaultAttributeDropdown
                                label="Rank"
                                name="rankId"
                                options={rankOptions}
                                attribute={DefaultAttributes.RANK}
                                showRequiredOnly={showRequiredOnly}
                                className="w-50 ml-2"
                                isLoading={loadingStates.ranks}
                            />
                        </div>
                    </div>

                    <RenderIf isTrue={hasRequiredFieldInSection([DefaultAttributes.ACQUISITION_PRICE])}>
                        <div className="CreateDeal__form__divider my-3" />
                        <div className="text-uppercase text-primary mb-1">
                            Deal Metrics
                            {isLoadingAttributes && (
                                <FontAwesomeIcon
                                    icon={faSpinner}
                                    spin
                                    className=""
                                    size="sm"
                                />
                            )}
                        </div>
                        <DefaultAttributeInput
                            label="Acquisition price"
                            name="acquisitionPrice"
                            placeholder="Enter amount"
                            attribute={DefaultAttributes.ACQUISITION_PRICE}
                            showRequiredOnly={showRequiredOnly}
                            prependInputAddon={<FontAwesomeIcon color="white" icon={faDollar} />}
                            className="mb-3"
                        />
                    </RenderIf>

                    <RenderIf isTrue={hasRequiredFieldInSection([DefaultAttributes.DEAL_OWNER])}>
                        <div className="mb-3">
                            <div className="text-uppercase text-primary mb-1">
                                Personnel
                                {isLoadingAttributes && (
                                    <FontAwesomeIcon
                                        icon={faSpinner}
                                        spin
                                        className=""
                                        size="sm"
                                    />
                                )}
                            </div>
                            <div className="d-flex mt-2">
                                <DefaultAttributeDropdown
                                    label="Deal owner"
                                    name="dealOwnerId"
                                    options={organizationUserOptions}
                                    attribute={DefaultAttributes.DEAL_OWNER}
                                    showRequiredOnly={showRequiredOnly}
                                    className="w-50"
                                    isLoading={loadingStates.organizationUsers}
                                />
                            </div>
                            {/* TODO: Add Development manager */}
                            {/* TODO: Add Investor - LP */}
                        </div>
                    </RenderIf>

                    <RenderIf isTrue={customAttributes.length > 0 && hasRequiredCustomAttributes()}>
                        <div className="CreateDeal__form__divider my-3" />
                        <div className="mb-3">
                            <div className="text-uppercase text-primary mb-1">
                                Custom Attributes
                                {isLoadingAttributes && (
                                    <FontAwesomeIcon
                                        icon={faSpinner}
                                        spin
                                        className="ml-2"
                                        size="sm"
                                    />
                                )}
                            </div>
                            <div className="d-flex flex-wrap">
                                {customAttributes.map((attribute, index) => (
                                    <CustomAttributeInput
                                        key={attribute.correlationId}
                                        attribute={attribute}
                                        className="w-50 pr-2 mt-2"
                                        showRequiredOnly={showRequiredOnly}
                                        isLoading={isLoadingAttributes}
                                    />
                                ))}
                            </div>
                        </div>
                    </RenderIf>
                    {ToastContainerElement}
                </Form>
            </Formik>
        </div>
    );
});

export default DealForm;
