import React, { useState } from 'react';
import {
    DropdownMenu, Dropdown, DropdownToggle,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { NSBadge } from '../index';
import RenderIf from '../../components/RenderIf/RenderIf';
import { IOptionAnyValue } from '../types';
import './NSDropdown.scss';
import NSDropdownItem from './NSDropdownItem';

export interface INSDropdownProps {
    options: IOptionAnyValue[];
    selectedOption?: IOptionAnyValue;
    handleSelectOption: (option: IOptionAnyValue) => void;
    isBadgeToggle?: boolean;
    badgeColor?: string;
    customSelectedOptionText?: string;
    containerClassName?: string;
    optionClassName?: string;
    placeholder?: string;
    toggleClassName?: string;
    disabled?: boolean;
    disableOnSingleOption?: boolean;
    hideOptionCheckmarkIcon?: boolean;
    menuRight?: boolean;
    customToggle?: React.ReactNode;
    showErrorMsg?: boolean;
    errorMsg?: string;
    fixedBottomContent?: React.ReactNode;
    useBodyContainer?: boolean;
    appendedDropdownItemButton?: React.ReactNode;
}

const NSDropdown = ({
    options,
    selectedOption,
    handleSelectOption,
    isBadgeToggle,
    badgeColor,
    customSelectedOptionText,
    containerClassName,
    optionClassName,
    isFullWidth,
    placeholder,
    toggleClassName,
    disabled,
    disableOnSingleOption = true,
    hideOptionCheckmarkIcon = false,
    menuRight = false,
    customToggle,
    showErrorMsg = false,
    errorMsg = '',
    fixedBottomContent,
    useBodyContainer = false,
    appendedDropdownItemButton,
}: INSDropdownProps & { isFullWidth?: boolean }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const toggle = () => setIsDropdownOpen(!isDropdownOpen);
    const toggleDropdown = options.length > 1 || !selectedOption ? toggle : () => { }; // No-op function if only 1 option

    return (
        <>
            <Dropdown
                isOpen={isDropdownOpen}
                toggle={disableOnSingleOption ? toggleDropdown : toggle}
                className={`NSDropdown ${isFullWidth ? 'w-100' : ''} ${containerClassName}`}
            >
                <DropdownToggle
                    {...(isBadgeToggle && { tag: 'div' })}
                    disabled={disabled}
                    className={classNames([
                        'text-nowrap',
                        toggleClassName,
                        {
                            'cursor--pointer': options.length > 1,
                            'd-block w-100': isFullWidth,
                            'ReadOnlyWrapper--enable-pointer-events': !disabled,
                        },
                    ])}
                >
                    <RenderIf isTrue={isBadgeToggle && !customToggle}>
                        <NSBadge color={badgeColor} className={isFullWidth ? 'w-100' : ''}>
                            <h6 className="m-0 font-weight-normal font-14">
                                {customSelectedOptionText || selectedOption?.label || placeholder}
                                <RenderIf isTrue={options.length > 1}>
                                    <FontAwesomeIcon icon={faCaretDown} className="ml-1" size="xs" />
                                </RenderIf>
                            </h6>
                        </NSBadge>
                    </RenderIf>
                    <RenderIf isTrue={!isBadgeToggle && !customToggle}>
                        <div className="d-flex justify-content-between align-items-center">
                            <span
                                className={classNames({
                                    'text-muted': !customSelectedOptionText && !selectedOption?.label,
                                })}
                            >
                                {customSelectedOptionText || selectedOption?.label || placeholder}
                            </span>
                            <FontAwesomeIcon icon={faCaretDown} className="ml-2" size="xs" />
                        </div>
                    </RenderIf>
                    <RenderIf isTrue={customToggle}>{customToggle}</RenderIf>
                </DropdownToggle>
                <DropdownMenu
                    container={useBodyContainer ? 'body' : undefined}
                    className={classNames(['NSDropdown__dropdown-menu overflow-y--auto', { 'w-100': isFullWidth }])}
                    right={menuRight}
                >
                    <div className="NSDropdown__options">
                        {options.map(option => (
                            <NSDropdownItem
                                key={option.value}
                                option={option}
                                handleSelectOption={handleSelectOption}
                                optionClassName={optionClassName}
                                hideOptionCheckmarkIcon={hideOptionCheckmarkIcon}
                                selectedOption={selectedOption}
                            />
                        ))}
                        <RenderIf isTrue={!selectedOption && appendedDropdownItemButton}>
                            {appendedDropdownItemButton}
                        </RenderIf>
                    </div>
                    {fixedBottomContent && (
                        <button
                            type="button"
                            className="NSDropdown__fixed-bottom"
                            onClick={toggle}
                            onKeyDown={e => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    toggle();
                                }
                            }}
                        >
                            {fixedBottomContent}
                        </button>
                    )}
                </DropdownMenu>
            </Dropdown>
            {showErrorMsg && errorMsg && <p className="text-danger small mt-1 mb-0">{errorMsg}</p>}
        </>
    );
};

export default NSDropdown;
