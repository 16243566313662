import React, { useEffect, useState } from 'react';
import {
    faCog, faDownload, faRocket, faSearchDollar,
} from '@fortawesome/free-solid-svg-icons';
import {
    NSButton, NSCard, NSSelect, NSSwitch,
} from 'bricks';
import RenderIf from 'components/RenderIf/RenderIf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageSpecificKeys, useLocalStorage } from 'helpers/localStorage';
import { customStyles } from 'bricks/NSSelect/NSSelect';
import { IProFormaPageLocalStorage } from 'views/ProFormaTable/types';
import {
    SELECTED_SCENARIO_KEY, SHOW_PROJECT_ACTUALS_KEY, HIDE_WARNINGS_KEY, SHOW_CENTS_KEY,
    SELECTED_PERIOD_CADENCE_KEY,
    IN_PROGRESS_TEXT,
} from 'views/ProFormaTable/constants';
import { PRO_FORMA_STATUS_PROJECT_CREATED } from 'views/ProFormaHome/constants';
import { IOptionAnyValue } from 'bricks/types';
import { PERIOD_OPTIONS } from 'components/ProFormaReturnsTable/constant';
import {
    faBalanceScale, faChartMixedUpCircleDollar, faCheck, faChevronLeft, faChevronRight, faHandHoldingDollar,
} from '@fortawesome/pro-solid-svg-icons';
import {
    DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,
} from 'reactstrap';
import { numeralFormatter, percentFormatter } from 'ns_libs/formatter';
import { useProFormaSocketContext } from '../../ProForma/socketContext/ProFormaSocketProvider';

const mockScenarioOptions = [
    { label: 'Mock Scenario 1', value: 1 },
    { label: 'Mock Scenario 2', value: 2 },
    { label: 'Mock Scenario 3', value: 3 },
];

export interface IProFormaSideNavFooterProps {
    setSelectedPeriod: (period: IOptionAnyValue) => void;
    selectedPeriod: IOptionAnyValue;
    isCollapsed?: boolean;
}

const ProFormaSideNavFooter = ({ setSelectedPeriod, selectedPeriod, isCollapsed } : IProFormaSideNavFooterProps) => {
    const {
        proForma, shouldUseProjectActuals, setShouldUseProjectActuals, handleUpdateProForma, returnsData,
    } = useProFormaSocketContext();
    const [proFormaPageLocalStorage, setProFormaPageLocalStorage] = useLocalStorage<IProFormaPageLocalStorage>(PageSpecificKeys.ProFormaPage, {});
    const currentProFormaLocalStorage = proForma.id in proFormaPageLocalStorage ? proFormaPageLocalStorage[proForma.id] : {};

    const localStorageScenarioId = SELECTED_SCENARIO_KEY in currentProFormaLocalStorage && Number(currentProFormaLocalStorage[SELECTED_SCENARIO_KEY]);
    const initiallySelectedOption = localStorageScenarioId && mockScenarioOptions.find(option => option.value === localStorageScenarioId);
    const [selectedScenario, setSelectedScenario] = useState<{ label: string; value: number }>(initiallySelectedOption || mockScenarioOptions[0]);

    const savedSelectedPeriod = SELECTED_PERIOD_CADENCE_KEY in currentProFormaLocalStorage && currentProFormaLocalStorage[SELECTED_PERIOD_CADENCE_KEY];
    const initiallySelectedPeriodCadence = PERIOD_OPTIONS.find(period => period.value === savedSelectedPeriod);

    const initialHideWarnings = HIDE_WARNINGS_KEY in currentProFormaLocalStorage && Boolean(currentProFormaLocalStorage[HIDE_WARNINGS_KEY]);
    const [hideWarnings, setHideWarnings] = useState<boolean>(initialHideWarnings || false);

    const initialShowCents = SHOW_CENTS_KEY in currentProFormaLocalStorage && Boolean(currentProFormaLocalStorage[SHOW_CENTS_KEY]);
    const [showCents, setShowCents] = useState<boolean>(initialShowCents || false);

    const [isSelectingPeriodCadence, setIsSelectingPeriodCadence] = useState(false);
    const toggleSelectingPeriodCadence = () => setIsSelectingPeriodCadence(!isSelectingPeriodCadence);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        if (isDropdownOpen) setIsSelectingPeriodCadence(false);
        setIsDropdownOpen(!isDropdownOpen);
    };

    // Key stats values
    const getCardValue = (value: number, formatter: Function | null, appendValue: string = '') => {
        const formatFn = formatter || ((v: number) => numeralFormatter(v, '0,0.0'));
        if (value === 0) return IN_PROGRESS_TEXT;
        return `${formatFn(value)}${appendValue}`;
    };

    const leveredIrr = getCardValue(returnsData?.netLeveredIrr || 0, percentFormatter);
    const leveredEmx = getCardValue(returnsData?.netLeveredEquityMultiple || 0, null, 'x');
    const stabilizedYieldOnCost = getCardValue(returnsData?.yieldOnCost?.trended || 0, percentFormatter);
    const netSaleProceeds = getCardValue(returnsData?.totalNetSaleProceeds || 0, (v: number) => numeralFormatter(v, '$0,0'));

    const updateLocalStorageByKey = (key: string, value: any) => {
        const updatedCurrentProForma = { ...currentProFormaLocalStorage, [key]: value };
        const updatedProFormaStorage = { ...proFormaPageLocalStorage, [proForma.id]: updatedCurrentProForma };
        setProFormaPageLocalStorage(updatedProFormaStorage as IProFormaPageLocalStorage);
    };

    const handleSelectScenario = (selectedScenario: { label: string; value: number }) => {
        setSelectedScenario(selectedScenario);
        updateLocalStorageByKey(SELECTED_SCENARIO_KEY, selectedScenario.value);
    };

    const handleToggleUseProjectActuals = () => {
        setShouldUseProjectActuals(!shouldUseProjectActuals);
        updateLocalStorageByKey(SHOW_PROJECT_ACTUALS_KEY, !shouldUseProjectActuals);
    };

    const handleToggleHideWarnings = () => {
        setHideWarnings(!hideWarnings);
        updateLocalStorageByKey(HIDE_WARNINGS_KEY, !hideWarnings);
    };

    const handleToggleShowCents = () => {
        setShowCents(!showCents);
        updateLocalStorageByKey(SHOW_CENTS_KEY, !showCents);
    };

    const handleSelectPeriod = (period: IOptionAnyValue) => {
        setSelectedPeriod(period);
        updateLocalStorageByKey(SELECTED_PERIOD_CADENCE_KEY, period.value);
        setIsSelectingPeriodCadence(false);
    };

    useEffect(() => {
        if (initiallySelectedOption && localStorageScenarioId !== selectedScenario.value) setSelectedScenario(initiallySelectedOption);
        if (initialHideWarnings && initialHideWarnings !== hideWarnings) setHideWarnings(initialHideWarnings);
        if (initialShowCents && initialShowCents !== showCents) setShowCents(initialShowCents);
        if (initiallySelectedPeriodCadence && initiallySelectedPeriodCadence !== selectedPeriod) setSelectedPeriod(initiallySelectedPeriodCadence);
    }, [initiallySelectedOption, initialHideWarnings, initialShowCents, initiallySelectedPeriodCadence]);

    return (
        <NSCard className="NSCard--level-4 p-2 pt-1 mt-3 ProFormaSideNav__footer w-100">
            <RenderIf isTrue={proForma.proFormaStatusId === PRO_FORMA_STATUS_PROJECT_CREATED}>
                <div className="ProFormaSideNav__footer__display-none d-flex align-items-center mb-2">
                    <NSSwitch
                        id="useProjectActuals"
                        name="useProjectActuals"
                        checked={shouldUseProjectActuals}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleToggleUseProjectActuals()}
                        containerClassName="text-white"
                    />
                    <span className="ml-2 text-dark">Use project actuals</span>
                </div>
                <NSCard
                    className="ProFormaSideNav__footer__display-none p-2 mb-2 w-100 text-muted position-relative overflow-hidden font-13 NSCard--level-5"
                >
                    <div>Mock 334 Core Avanue</div>
                    <div className="text-dark font-weight-bold">Mock Core & Shell Renovation</div>
                    <RenderIf isTrue={shouldUseProjectActuals}>
                        <NSSelect
                            name="projectScenarioSelect"
                            formClassName="pt-1"
                            options={mockScenarioOptions}
                            value={selectedScenario}
                            onChange={option => handleSelectScenario(option)}
                            isClearable={false}
                            isSearchable={false}
                            menuPosition="fixed"
                            styles={{
                                ...customStyles,
                                control: styles => ({
                                    ...styles,
                                    border: 'none',
                                    backgroundColor: '#4F5A6A',
                                }),
                            }}
                        />
                    </RenderIf>
                </NSCard>
            </RenderIf>

            <div className="d-flex">
                <NSCard className="p-2 mb-1 mr-1 w-100 text-muted position-relative overflow-hidden font-13 ProFormaSideNav__footer__metric-card">
                    <FontAwesomeIcon icon={faSearchDollar} className="text-primary ProFormaSideNav__footer__metric-card__bg-icon" />
                    <div>Levered IRR</div>
                    <div className="text-dark font-weight-bold">{leveredIrr}</div>
                </NSCard>
                <NSCard className="p-2 mb-1 w-100 text-muted position-relative overflow-hidden font-13 ProFormaSideNav__footer__metric-card">
                    <FontAwesomeIcon icon={faChartMixedUpCircleDollar} className="text-primary ProFormaSideNav__footer__metric-card__bg-icon" />
                    <div>Levered EMx</div>
                    <div className="text-dark font-weight-bold">{leveredEmx}</div>
                </NSCard>
            </div>
            <div className="d-flex">
                <NSCard className="p-2 mb-0 mr-1 w-100 text-muted position-relative overflow-hidden font-13 ProFormaSideNav__footer__metric-card">
                    <FontAwesomeIcon icon={faBalanceScale} className="text-primary ProFormaSideNav__footer__metric-card__bg-icon" />
                    <div>Stabilized yield-on-cost</div>
                    <div className="text-dark font-weight-bold">{stabilizedYieldOnCost}</div>
                </NSCard>
                <NSCard className="p-2 mb-0 w-100 text-muted position-relative overflow-hidden font-13 ProFormaSideNav__footer__metric-card">
                    <FontAwesomeIcon icon={faHandHoldingDollar} className="text-primary ProFormaSideNav__footer__metric-card__bg-icon" />
                    <div>Net sale proceeds</div>
                    <div className="text-dark font-weight-bold">{netSaleProceeds}</div>
                </NSCard>
            </div>

            <RenderIf isTrue={proForma.proFormaStatusId !== PRO_FORMA_STATUS_PROJECT_CREATED}>
                <div className="ProFormaSideNav__footer__buttons-container mt-2 d-flex align-items-center p-0">
                    <UncontrolledDropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
                        <DropdownToggle tag="div">
                            <NSButton icon={faCog} className="mr-1 NSButton--secondary-lighten cursor--pointer" />
                        </DropdownToggle>
                        <DropdownMenu
                            container="body"
                            className="p-0 m-0 bg-transparent z-index-100"
                            style={{ width: '250px' }}
                        >

                            <NSCard className="NSCard--level-5 shadow-none py-2">
                                <RenderIf isTrue={!isSelectingPeriodCadence}>
                                    <DropdownItem
                                        toggle={false}
                                        className="d-flex justify-content-between align-items-center width-100"
                                        onClick={toggleSelectingPeriodCadence}
                                    >
                                        <span>Period cadence</span>
                                        <FontAwesomeIcon icon={faChevronRight} size="sm" />
                                    </DropdownItem>
                                    <DropdownItem
                                        toggle={false}
                                        className="d-flex justify-content-between align-items-center text-wrap"
                                    >
                                        <span>Hide warnings for Back-of-Envelope and pro forma discrepencies</span>
                                        <NSSwitch
                                            id="hideDiscrepencyWarningsToggle"
                                            name="hideDiscrepencyWarningsToggle"
                                            checked={hideWarnings}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleToggleHideWarnings()}
                                            containerClassName="text-white"
                                        />
                                    </DropdownItem>
                                    <DropdownItem
                                        toggle={false}
                                        className="d-flex justify-content-between align-items-center"
                                    >
                                        <span>Show cents</span>
                                        <NSSwitch
                                            id="showCentsToggle"
                                            name="showCentsToggle"
                                            checked={showCents}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleToggleShowCents()}
                                            containerClassName="text-white"
                                        />
                                    </DropdownItem>
                                </RenderIf>
                                <RenderIf isTrue={isSelectingPeriodCadence}>
                                    <DropdownItem
                                        toggle={false}
                                        className="d-flex align-items-center text-primary"
                                        onClick={toggleSelectingPeriodCadence}
                                    >
                                        <FontAwesomeIcon icon={faChevronLeft} size="sm" className="mr-1" />
                                        <span>Back</span>
                                    </DropdownItem>
                                    {PERIOD_OPTIONS.map((period: IOptionAnyValue) => (
                                        <DropdownItem
                                            key={period.value}
                                            className="d-flex justify-content-between align-items-center"
                                            onClick={() => handleSelectPeriod(period)}
                                        >
                                            <span>{period.label}</span>
                                            <RenderIf isTrue={selectedPeriod.value === period.value}>
                                                <FontAwesomeIcon icon={faCheck} size="sm" className="text-primary" />
                                            </RenderIf>
                                        </DropdownItem>
                                    ))}
                                </RenderIf>
                            </NSCard>
                        </DropdownMenu>
                    </UncontrolledDropdown>

                    <NSButton icon={faDownload} className="mr-1 NSButton--secondary-lighten" callback={() => {}} />
                    <NSButton
                        id="create-project-from-deal-btn"
                        text={isCollapsed ? '' : 'Create project from deal'}
                        className={`w-100 ${!isCollapsed && 'text-truncate'}`}
                        icon={faRocket}
                        color="primary"
                        callback={() => handleUpdateProForma([{
                            key: 'proFormaStatusId',
                            value: PRO_FORMA_STATUS_PROJECT_CREATED,
                        }])}
                    />
                </div>
            </RenderIf>
        </NSCard>
    );
};

export default ProFormaSideNavFooter;
