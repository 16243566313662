import { faPen, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NSCard } from 'bricks';
import React from 'react';

export interface IReportDetailCardProps {
    header: string;
    handleEdit: () => void;
    handleDelete: () => void;
    children: React.ReactNode;
}
const ReportDetailCard = ({
    header, handleEdit, handleDelete, children,
}: IReportDetailCardProps) => (
    <NSCard className="NSCard--level-2 p-3 mt-2">
        <div className="d-flex justify-content-between align-items-center">
            <h4 className="text-white m-0">{header}</h4>
            <div>
                <FontAwesomeIcon
                    icon={faTrash}
                    color="white"
                    data-testid="trash-icon"
                    size="lg"
                    className="pl-2 cursor--pointer"
                    onClick={handleDelete}
                />
                <FontAwesomeIcon icon={faPen} color="white" data-testid="edit-icon" size="lg" className="pl-2 cursor--pointer" onClick={handleEdit} />
            </div>
        </div>
        <hr />
        {children}
    </NSCard>
);

export default ReportDetailCard;
