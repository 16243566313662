import React from 'react';
import { AttributeKey } from 'views/Reports/types';
import AddColumns from './AddColumns/AddColumns';
import SelectedColumns from './SelectedColumns/SelectedColumns';

interface IAttributesProps {
    className?: string;
    selectedColumns: AttributeKey[];
    setSelectedColumns: (columns: AttributeKey[]) => void;
}
const Attributes = ({ className, selectedColumns, setSelectedColumns }: IAttributesProps) => (
    <div className={`Attributes d-flex flex-column ${className}`}>
        <SelectedColumns selectedColumns={selectedColumns} setSelectedColumns={setSelectedColumns} />
        <hr />
        <AddColumns selectedColumns={selectedColumns} setSelectedColumns={setSelectedColumns} />
    </div>
);

export default Attributes;
