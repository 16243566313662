import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS from 'services/urls';

export const getReportPreview = async (reportId: number): Promise<any> => {
    const response = await axiosInstance.get(API_ENDPOINTS.REPORTS.PREVIEW(reportId));
    return response.data;
};

export const useGetReportPdf = (reportId: number, options: { enabled: boolean }) => useQuery({
    queryKey: ['reportPreview', reportId],
    queryFn: () => getReportPreview(reportId),
    ...options,
});

export default useGetReportPdf;
