import React from 'react';
import { NSTable } from 'bricks';
import RenderIf from 'components/RenderIf/RenderIf';
import { DISABLED_PLACEHOLDER_PERIOD } from 'views/ProFormaTable/constants';
import { IOptionAnyValue } from 'bricks/types';

export interface IMonthlyAnalysisPeriod {
    periodName: string;
    periodDate: string;
    leasedPercentage: string | null;
    economicOccupancyPercentage: string | null;
}

export interface IMonthlyAnalysisTableProps {
    periodOptions: IOptionAnyValue[];
    monthlyAnalysisPeriods: IMonthlyAnalysisPeriod[];
}

const MonthlyAnalysisTable = ({ periodOptions, monthlyAnalysisPeriods }: IMonthlyAnalysisTableProps) => (
    <div className="d-flex my-2 mr-3 NSTable--sticky-headers__table-container-with-scrollbar">
        <NSTable className="NSTable--no-footer NSTable--sticky-headers NSTable--sticky-headers--full-width NSTable--sticky-headers--sticky-first-column">
            <thead className="NSTable__thead">
                <tr className="NSTable__thead__tr">
                    <th className="NSTable__thead__tr__th NSTable__thead__tr__th--width-140
                    NSTable__thead__tr__th--right-border align-middle py-1"
                    >
                        Monthly analysis
                    </th>
                    {periodOptions?.map((period, index) => (
                        <th
                            key={period.value}
                            className="NSTable__thead__tr__th align-middle NSTable__thead__tr__th--grey-bg py-1"
                        >
                            <RenderIf isTrue={period.value === DISABLED_PLACEHOLDER_PERIOD}>
                                <div className="h6 NSTable__thead__tr__th--grey-text
                                        NSTable__thead__tr__th--opacity-3 text-uppercase text-nowrap text-secondary text-right mb-0"
                                >
                                    Month #
                                </div>
                                <div className="h6 text-uppercase text-right text-nowrap NSTable__thead__tr__th--opacity-3 mt-1">
                                    {period.label}
                                </div>
                            </RenderIf>
                            <RenderIf isTrue={period.value !== DISABLED_PLACEHOLDER_PERIOD}>
                                <div className="h6 NSTable__thead__tr__th--grey-text text-uppercase text-nowrap text-secondary text-right mb-0">
                                    Month
                                    {' '}
                                    {index}
                                </div>
                                <div className="h6 text-uppercase text-right text-nowrap mt-1">{period.label}</div>
                            </RenderIf>
                        </th>
                    ))}
                </tr>
            </thead>

            <tbody className="NSTable__tbody">
                <tr className="NSTable__tbody__tr">
                    <td className="NSTable__tbody__tr__td NSTable__tbody__tr__td--right-border align-middle
                    NSTable__tbody__tr__td__input text-dark"
                    >
                        Leased %
                    </td>
                    {monthlyAnalysisPeriods.map(period => (
                        <td key={`leased-${period.periodName}`} className="text-dark text-right">
                            {period.leasedPercentage !== '—'
                                ? `${Math.round(Number(period.leasedPercentage) * 100)}%` : '—'}
                        </td>
                    ))}
                </tr>
                <tr className="NSTable__tbody__tr">
                    <td className="NSTable__tbody__tr__td NSTable__tbody__tr__td--right-border align-middle
                    NSTable__tbody__tr__td__input text-dark"
                    >
                        Economic occ. %
                    </td>
                    {monthlyAnalysisPeriods.map(period => (
                        <td key={`economic-occ-${period.periodName}`} className="text-dark text-right">
                            {period.economicOccupancyPercentage !== '—'
                                ? `${Math.round(Number(period.economicOccupancyPercentage) * 100)}%` : '—'}
                        </td>
                    ))}
                </tr>
            </tbody>
        </NSTable>
    </div>
);

export default MonthlyAnalysisTable;
