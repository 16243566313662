import React, { useState } from 'react';
import {
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

import { URLS } from 'services/urls';
import { useOrganizationAccess } from 'hooks/useOrganizationAccess';
import { NSBadge } from 'bricks';
import '../../NSNavbar.scss';

const UtilitiesMenu: React.FC = () => {
    const [isUtilitiesMenuOpen, setIsUtilitiesMenuOpen] = useState<boolean>(false);
    const toggleUtilitiesMenu = () => setIsUtilitiesMenuOpen(!isUtilitiesMenuOpen);
    const { hasEnterpriseLicense } = useOrganizationAccess();

    return (
        <Dropdown
            isOpen={isUtilitiesMenuOpen}
            toggle={toggleUtilitiesMenu}
            onMouseEnter={() => setIsUtilitiesMenuOpen(true)}
            onMouseLeave={() => setIsUtilitiesMenuOpen(false)}
            data-testid="utilities-menu-dropdown"
            className="d-flex align-items-center px-2"
        >
            <DropdownToggle tag="span" data-testid="utilities-menu-toggle" className="NSNavbar__dropdown_toggle">
                Utilities
                <FontAwesomeIcon
                    className="icon ml-2"
                    icon={faCaretDown}
                />
            </DropdownToggle>
            {isUtilitiesMenuOpen && (
                <DropdownMenu className="NSNavbar__dropdown_Menu">
                    <DropdownItem
                        className="text-wrap mt-1"
                    >
                        <a href={URLS.PROJECTS_APP.PORTFOLIO_BUDGET_CATEGORIZATIONS} className="NSNavbar__dropdown_link">
                            Budget Categorizations
                        </a>
                    </DropdownItem>
                    {hasEnterpriseLicense ? (
                        <DropdownItem className="text-wrap">
                            <a href={URLS.PROJECTS_APP.PORTFOLIO_INVOICES} className="NSNavbar__dropdown_link">
                                Invoices
                            </a>
                        </DropdownItem>
                    ) : (
                        <DropdownItem className="text-wrap" disabled>
                            <div className="d-flex align-items-center h-100">
                                Invoices
                                <NSBadge color="primary-lighten" className="ml-2">Enterprise</NSBadge>
                            </div>
                        </DropdownItem>
                    )}
                    <DropdownItem className="text-wrap">
                        <a href={URLS.PROJECTS_APP.VENDOR_DIRECTORY} className="NSNavbar__dropdown_link">
                            Vendor Directory
                        </a>
                    </DropdownItem>
                </DropdownMenu>
            )}
        </Dropdown>
    );
};

export default UtilitiesMenu;
