import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { useMutation } from '@tanstack/react-query';
import { IBackOfEnvelopePayload, IVariables } from '../types';

const createBackOfEnvelope = async ({ postData, orgId }: IVariables): Promise<IBackOfEnvelopePayload> => {
    const response = await axiosInstance.post(`${API_ENDPOINTS.BACK_OF_ENVELOPE(orgId)}`, postData);
    return response.data;
};

export const useCreateBackOfEnvelope = () => useMutation({
    mutationFn: createBackOfEnvelope,
});
