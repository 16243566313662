import { ProFormaGenericColumn, ProFormaGenericRow } from 'views/ProFormaTable/components/ProFormaGenericTable/types';

export const OVERVIEW = 'Overview';
export const GP_EXPANDED = 'GP Detail';
export const LP_EXPANDED = 'LP Detail';

export const WATERFALL_TABS = [OVERVIEW, GP_EXPANDED, LP_EXPANDED];

export const WATERFALL_SUMMARY_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'title', field: 'title', header: 'Summary', format: 'none', isReadOnly: true,
    },
    {
        key: 'contribution', field: 'contribution', header: 'Contributions', format: 'currency', isReadOnly: true,
    },
    {
        key: 'netProfit', field: 'netProfit', header: 'Net profit', format: 'currency', isReadOnly: true,
    },
    {
        key: 'roi', field: 'roi', header: 'ROI', format: 'percentage', isReadOnly: true,
    },
    {
        key: 'emx', field: 'emx', header: 'EMx', format: 'none', isReadOnly: true,
    },
    {
        key: 'irr', field: 'irr', header: 'IRR', format: 'percentage', isReadOnly: true,
    },
];

export const WATERFALL_SUMMARY_ROWS_INITIAL_STATE: ProFormaGenericRow[] = [
    {
        id: 'generalPartner',
        title: 'General Partner Name',
        contribution: '249876',
        netProfit: '425331',
        roi: '55.7',
        emx: '55.7',
        irr: '55.7',
    },
    {
        id: 'limitedPartner',
        title: 'Limited Partner Name',
        contribution: '49876',
        netProfit: '25331',
        roi: '66.8',
        emx: '66.8',
        irr: '66.8',
    },
];

// OVERVIEW rows
const INITIAL_OVERVIEW_CONTRIBUTIONS_ROWS: ProFormaGenericRow[] = [
    {
        id: 'contribution-1',
        name: 'General Partner Name',
        share: '10',
        amount: '1894.34',
        label: 'GP',
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
    {
        id: 'contribution-2',
        name: 'Limited Partner Name',
        share: '90',
        amount: '5894.34',
        label: 'LP',
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
];

const INITIAL_OVERVIEW_DISTRIBUTION_WATERFALL_ROWS: ProFormaGenericRow[] = [
    {
        id: 'pref-return',
        name: 'Pref. return',
        upTo: '10.0',
        gpPromote: '0',
        distToGP: '10.0',
        distToLP: '90',
        amount: '5894.34',
        label: 'up to',
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
    {
        id: '2nd-tier',
        name: '2nd tier',
        upTo: '12.0',
        gpPromote: '0',
        distToGP: '10.0',
        distToLP: '90',
        amount: '5894.34',
        label: 'up to',
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
    {
        id: '3rd-tier',
        name: '3rd tier',
        upTo: '15.0',
        gpPromote: '0',
        distToGP: '10.0',
        distToLP: '90',
        amount: '5894.34',
        label: 'up to',
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
    {
        id: '4th-tier',
        name: '4th tier',
        upTo: '8',
        gpPromote: '0',
        distToGP: '10.0',
        distToLP: '90',
        amount: '5894.34',
        label: 'above',
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
];

const INITIAL_OVERVIEW_DISTRIBUTIONS_ROWS: ProFormaGenericRow[] = [
    {
        id: 'distribution-1',
        name: 'General Partner Name',
        share: '20',
        amount: '1894.34',
        label: 'GP',
        isReadOnly: true,
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
    {
        id: 'distribution-2',
        name: 'Limited Partner Name',
        share: '80',
        amount: '5894.34',
        label: 'LP',
        isReadOnly: true,
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
];

const INITIAL_OVERVIEW_CASH_FLOW_ROWS: ProFormaGenericRow[] = [
    {
        id: 'cash-flow-1',
        name: 'General Partner Name',
        emx: '7.84',
        irr: '29.67',
        share: '20',
        amount: '1894.34',
        label: 'GP',
        isReadOnly: true,
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
    {
        id: 'cash-flow-2',
        name: 'Limited Partner Name',
        emx: '7.84',
        irr: '81.9',
        share: '80',
        amount: '5894.34',
        label: 'LP',
        isReadOnly: true,
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
];

// GP rows
const INITIAL_GP_CONTRIBUTIONS_ROWS: ProFormaGenericRow[] = [
    {
        id: 'contribution-1',
        name: 'General Partner 1 Name',
        share: '10',
        amount: '1894.34',
        label: 'GP',
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
    {
        id: 'contribution-2',
        name: 'General Partner 2 Name',
        share: '90',
        amount: '5894.34',
        label: 'GP',
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
];

const INITIAL_GP_DISTRIBUTION_WATERFALL_ROWS: ProFormaGenericRow[] = [
    {
        id: 'pref-return',
        name: 'Pref. return',
        upTo: '10.0',
        gpPromote: '0',
        distToGP: '10.0',
        distToLP: '90',
        amount: '5894.34',
        label: 'up to',
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
    {
        id: '2nd-tier',
        name: '2nd tier',
        upTo: '12.0',
        gpPromote: '0',
        distToGP: '10.0',
        distToLP: '90',
        amount: '5894.34',
        label: 'up to',
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
    {
        id: '3rd-tier',
        name: '3rd tier',
        upTo: '15.0',
        gpPromote: '0',
        distToGP: '10.0',
        distToLP: '90',
        amount: '5894.34',
        label: 'up to',
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
    {
        id: '4th-tier',
        name: '4th tier',
        upTo: '8',
        gpPromote: '0',
        distToGP: '10.0',
        distToLP: '90',
        amount: '5894.34',
        label: 'above',
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
];

const INITIAL_GP_DISTRIBUTIONS_ROWS: ProFormaGenericRow[] = [
    {
        id: 'distribution-1',
        name: 'General Partner 1 Name',
        share: '20',
        amount: '1894.34',
        label: 'GP',
        isReadOnly: true,
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
    {
        id: 'distribution-2',
        name: 'General Partner 2 Name',
        share: '80',
        amount: '5894.34',
        label: 'GP',
        isReadOnly: true,
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
];

const INITIAL_GP_CASH_FLOW_ROWS: ProFormaGenericRow[] = [
    {
        id: 'cash-flow-1',
        name: 'General Partner 1 Name',
        emx: '7.84',
        irr: '29.67',
        share: '20',
        amount: '1894.34',
        label: 'GP',
        isReadOnly: true,
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
    {
        id: 'cash-flow-2',
        name: 'General Partner 2 Name',
        emx: '7.84',
        irr: '81.9',
        share: '80',
        amount: '5894.34',
        label: 'GP',
        isReadOnly: true,
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
];

// LP rows
const INITIAL_LP_CONTRIBUTIONS_ROWS: ProFormaGenericRow[] = [
    {
        id: 'contribution-1',
        name: 'Limited Partner 1 Name',
        share: '10',
        amount: '1894.34',
        label: 'LP',
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
    {
        id: 'contribution-2',
        name: 'Limited Partner 2 Name',
        share: '90',
        amount: '5894.34',
        label: 'LP',
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
];

const INITIAL_LP_DISTRIBUTION_WATERFALL_ROWS: ProFormaGenericRow[] = [
    {
        id: 'pref-return',
        name: 'Pref. return',
        upTo: '10.0',
        gpPromote: '0',
        distToGP: '10.0',
        distToLP: '90',
        amount: '5894.34',
        label: 'up to',
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
    {
        id: '2nd-tier',
        name: '2nd tier',
        upTo: '12.0',
        gpPromote: '0',
        distToGP: '10.0',
        distToLP: '90',
        amount: '5894.34',
        label: 'up to',
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
    {
        id: '3rd-tier',
        name: '3rd tier',
        upTo: '15.0',
        gpPromote: '0',
        distToGP: '10.0',
        distToLP: '90',
        amount: '5894.34',
        label: 'up to',
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
    {
        id: '4th-tier',
        name: '4th tier',
        upTo: '8',
        gpPromote: '0',
        distToGP: '10.0',
        distToLP: '90',
        amount: '5894.34',
        label: 'above',
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
];

const INITIAL_LP_DISTRIBUTIONS_ROWS: ProFormaGenericRow[] = [
    {
        id: 'distribution-1',
        name: 'Limited Partner 1 Name',
        share: '20',
        amount: '1894.34',
        label: 'LP',
        isReadOnly: true,
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
    {
        id: 'distribution-2',
        name: 'Limited Partner 2 Name',
        share: '80',
        amount: '5894.34',
        label: 'LP',
        isReadOnly: true,
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
];

const INITIAL_LP_CASH_FLOW_ROWS: ProFormaGenericRow[] = [
    {
        id: 'cash-flow-1',
        name: 'Limited Partner 1 Name',
        emx: '7.84',
        irr: '29.67',
        share: '20',
        amount: '1894.34',
        label: 'LP',
        isReadOnly: true,
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
    {
        id: 'cash-flow-2',
        name: 'Limited Partner 2 Name',
        emx: '7.84',
        irr: '81.9',
        share: '80',
        amount: '5894.34',
        label: 'LP',
        isReadOnly: true,
        column1: '5894.34',
        column2: '5894.34',
        column3: '5894.34',
        column4: '5894.34',
    },
];

export const INITIAL_FINANCIAL_DATA = {
    [OVERVIEW]: {
        contributionsRows: INITIAL_OVERVIEW_CONTRIBUTIONS_ROWS,
        distributionWaterfallRows: INITIAL_OVERVIEW_DISTRIBUTION_WATERFALL_ROWS,
        distributionsRows: INITIAL_OVERVIEW_DISTRIBUTIONS_ROWS,
        netCashFlowRows: INITIAL_OVERVIEW_CASH_FLOW_ROWS,
    },
    [GP_EXPANDED]: {
        contributionsRows: INITIAL_GP_CONTRIBUTIONS_ROWS,
        distributionWaterfallRows: INITIAL_GP_DISTRIBUTION_WATERFALL_ROWS,
        distributionsRows: INITIAL_GP_DISTRIBUTIONS_ROWS,
        netCashFlowRows: INITIAL_GP_CASH_FLOW_ROWS,
    },
    [LP_EXPANDED]: {
        contributionsRows: INITIAL_LP_CONTRIBUTIONS_ROWS,
        distributionWaterfallRows: INITIAL_LP_DISTRIBUTION_WATERFALL_ROWS,
        distributionsRows: INITIAL_LP_DISTRIBUTIONS_ROWS,
        netCashFlowRows: INITIAL_LP_CASH_FLOW_ROWS,
    },
};
