export const DEAL_MESSAGES = {
    DELETE: {
        SINGLE_CONFIRMATION: 'Are you sure you want to delete the selected deal?',
        MULTIPLE_CONFIRMATION: 'Are you sure you want to delete the selected deals?',
        SUCCESS_SINGLE: 'Deal has been successfully deleted.',
        SUCCESS_MULTIPLE: 'Selected deals have been successfully deleted.',
        ERROR_NO_SELECTION: 'No deals selected to delete.',
        ERROR_GENERIC: 'An error occurred while deleting the deals.',
    },
} as const;
