/* eslint-disable no-unused-vars */
import getEnvironmentalVariables from './getEnvironmentalVariables';

export const { BASE_URL } = getEnvironmentalVariables();

export const ORGANIZATION_USER_FEATURE_ACCESS_QUERY_KEY = 'OrganizationUserFeatureAccess' as const;

export const FEATURE_IDS = {
    DEAL_PIPELINE: 141,
    REPORTING_ACROSS_DEALS: 142,
    DEAL_INCOMING_APPROVALS: 143,
    BACK_OF_ENVELOPE: 144,
    DEAL_DASHBOARD: 145,
    CONTACT_LIST: 146,
    COMPANY_LIST: 147,
    DEAL_DOCUMENTS: 148,
    DEAL_REPORT_BUILDER: 149,
    PRO_FORMA: 150,
    DEAL_ORGANIZATION_MANAGEMENT: 151,
};

export enum FEATURES_LIST {
    DEAL_DOCUMENTS ='deal_documents',
}

export const USER_CLASSES = {
    SUPER_ADMIN: 6,
    CUSTOM: 12,
};

export const SELECTED_ORGANIZATION_ID = 'selected_organization_id' as const;
