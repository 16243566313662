import React, { useState } from 'react';
import { DealType } from 'constants/deals';
import { NSCheckbox } from '../../../bricks';
import RenderIf from '../../../components/RenderIf/RenderIf';
import ProFormaMappingTable from './ProFormaMappingTable';
import ProFormaSingleMapping from './ProFormaSingleMapping';
import './ProFormaUploadTables.scss';
import {
    INITIAL_PROPERTY_RETURNS,
    INITIAL_PARTNERSHIP_RETURNS,
    INITIAL_DEVELOPMENT_RETURNS,
    INITIAL_OPERATIONS,
    INITIAL_VALUATION,
    INITIAL_DEVELOPMENT_USES,
    INITIAL_DEVELOPMENT_SOURCES,
    INITIAL_ACQUISITIONS,
} from '../constants';

interface InitialValues {
    propertyReturns?: typeof INITIAL_PROPERTY_RETURNS;
    partnershipReturns?: typeof INITIAL_PARTNERSHIP_RETURNS;
    developmentReturns?: typeof INITIAL_DEVELOPMENT_RETURNS;
    operations?: typeof INITIAL_OPERATIONS;
    valuation?: typeof INITIAL_VALUATION;
    developmentUses?: typeof INITIAL_DEVELOPMENT_USES;
    developmentSources?: typeof INITIAL_DEVELOPMENT_SOURCES;
    acquisitions?: typeof INITIAL_ACQUISITIONS;
}

interface ProFormaUploadTablesProps {
    isDisabled?: boolean;
    initialValues?: InitialValues;
    dealType?: DealType;
}

export const ProFormaUploadTables: React.FC<ProFormaUploadTablesProps> = ({
    isDisabled = false,
    initialValues,
    dealType = 'Development',
}) => {
    const [propertyReturns, setPropertyReturns] = useState(initialValues?.propertyReturns || INITIAL_PROPERTY_RETURNS);
    const [partnershipReturns, setPartnershipReturns] = useState(initialValues?.partnershipReturns || INITIAL_PARTNERSHIP_RETURNS);
    const [developmentReturns, setDevelopmentReturns] = useState(initialValues?.developmentReturns || INITIAL_DEVELOPMENT_RETURNS);
    const [operations, setOperations] = useState(initialValues?.operations || INITIAL_OPERATIONS);
    const [valuation, setValuation] = useState(initialValues?.valuation || INITIAL_VALUATION);
    const [developmentUses, setDevelopmentUses] = useState(initialValues?.developmentUses || INITIAL_DEVELOPMENT_USES);
    const [developmentSources, setDevelopmentSources] = useState(initialValues?.developmentSources || INITIAL_DEVELOPMENT_SOURCES);
    const [aquisitions, setAquisitions] = useState(initialValues?.acquisitions || INITIAL_ACQUISITIONS);

    const handleChange = <T extends { id: string }>(
        setState: React.Dispatch<React.SetStateAction<T[]>>,
        rowId: string,
        field: 'sheet' | 'cell' | 'label',
        value: string,
    ) => {
        if (isDisabled) return;
        setState(prev => prev.map(row => (row.id === rowId ? { ...row, [field]: value } : row)));
    };

    const handleValuationChange = (field: 'sheet' | 'cell', value: string) => {
        if (isDisabled) return;
        setValuation(prev => ({ ...prev, [field]: value }));
    };

    const columns = [
        {
            key: 'label',
            header: 'Property returns',
            isCellInput: true,
            onChange: (item: { id: string; }, value: string) => handleChange(setPropertyReturns, item.id, 'label', value),
        },
        {
            key: 'sheet',
            header: 'Sheet',
            isInput: true,
            onChange: (item: { id: string; }, value: string) => handleChange(setPropertyReturns, item.id, 'sheet', value),
        },
        {
            key: 'cell',
            header: 'Cell',
            isInput: true,
            onChange: (item: { id: string; }, value: string) => handleChange(setPropertyReturns, item.id, 'cell', value),
        },
    ];

    return (
        <div className={`ProFormaUploadTables NSCard--level-1 p-2 ${isDisabled ? 'ProFormaUploadTables--disabled' : ''}`}>
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="ProFormaUploadTables--title text-white">Review uploaded values</div>
                <div className="d-flex flex-row align-items-center">
                    {/* TODO: Integrate this later */}
                    <NSCheckbox
                        id="usesMappingForAllFutureDeals"
                        checked
                        labelClassName="cursor--pointer"
                        containerClassName="mr-0"
                        callback={() => { }}
                    />
                    <div className="text-white">Use this mapping for all future development deals</div>
                </div>
            </div>
            <div className="text-muted pb-2">Map values from your uploaded file to our default metrics.</div>
            <div className="ProFormaUploadTables--separator pt-2" />
            <div className="text-muted pt-1">1 OF 3</div>
            <div className="text-white font-weight-bold pb-1">Returns</div>
            <ProFormaMappingTable
                className="pb-2"
                data={propertyReturns}
                columns={[{ ...columns[0], header: 'Property returns', isCellInput: false }, columns[1], columns[2]]}
                getRowKey={item => item.id}
            />
            <ProFormaMappingTable
                className="pb-2"
                data={partnershipReturns}
                columns={[
                    {
                        ...columns[0],
                        header: 'Partnership returns',
                        isCellInput: false,
                        onChange: (item, value) => handleChange(setPartnershipReturns, item.id, 'label', value),
                    },
                    {
                        ...columns[1],
                        onChange: (item, value) => handleChange(setPartnershipReturns, item.id, 'sheet', value),
                    },
                    {
                        ...columns[2],
                        onChange: (item, value) => handleChange(setPartnershipReturns, item.id, 'cell', value),
                    },
                ]}
                getRowKey={item => item.id}
            />
            <ProFormaMappingTable
                data={developmentReturns}
                columns={[
                    {
                        ...columns[0],
                        header: 'Development returns',
                        isCellInput: false,
                        onChange: (item, value) => handleChange(setDevelopmentReturns, item.id, 'label', value),
                    },
                    {
                        ...columns[1],
                        onChange: (item, value) => handleChange(setDevelopmentReturns, item.id, 'sheet', value),
                    },
                    {
                        ...columns[2],
                        onChange: (item, value) => handleChange(setDevelopmentReturns, item.id, 'cell', value),
                    },
                ]}
                getRowKey={item => item.id}
            />
            <div className="text-muted pt-2">2 OF 3</div>
            <div className="text-white font-weight-bold pb-1">Operations</div>
            <ProFormaMappingTable
                className="pb-2"
                data={operations}
                columns={[
                    {
                        ...columns[0],
                        header: 'Operation',
                        isCellInput: false,
                        onChange: (item, value) => handleChange(setOperations, item.id, 'label', value),
                    },
                    {
                        ...columns[1],
                        onChange: (item, value) => handleChange(setOperations, item.id, 'sheet', value),
                    },
                    {
                        ...columns[2],
                        onChange: (item, value) => handleChange(setOperations, item.id, 'cell', value),
                    },
                ]}
                getRowKey={item => item.id}
            />
            <ProFormaSingleMapping
                label="Valuation"
                sheet={valuation.sheet}
                cell={valuation.cell}
                onSheetChange={value => handleValuationChange('sheet', value)}
                onCellChange={value => handleValuationChange('cell', value)}
            />
            <div className="text-muted pt-2">3 OF 3</div>
            <div className="text-white font-weight-bold pb-1">Development</div>
            <ProFormaMappingTable
                className="pb-2"
                data={developmentUses}
                columns={[
                    {
                        ...columns[0],
                        header: 'Uses',
                        isCellInput: false,
                        onChange: (item, value) => handleChange(setDevelopmentUses, item.id, 'label', value),
                    },
                    {
                        ...columns[1],
                        onChange: (item, value) => handleChange(setDevelopmentUses, item.id, 'sheet', value),
                    },
                    {
                        ...columns[2],
                        onChange: (item, value) => handleChange(setDevelopmentUses, item.id, 'cell', value),
                    },
                ]}
                getRowKey={item => item.id}
            />
            <RenderIf isTrue={dealType === 'Development'}>
                <ProFormaMappingTable
                    data={developmentSources}
                    columns={[
                        {
                            ...columns[0],
                            header: 'Sources',
                            onChange: (item, value) => handleChange(setDevelopmentSources, item.id, 'label', value),
                        },
                        {
                            ...columns[1],
                            onChange: (item, value) => handleChange(setDevelopmentSources, item.id, 'sheet', value),
                        },
                        {
                            ...columns[2],
                            onChange: (item, value) => handleChange(setDevelopmentSources, item.id, 'cell', value),
                        },
                    ]}
                    getRowKey={item => item.id}
                />
            </RenderIf>
            <RenderIf isTrue={dealType !== 'Development'}>
                <ProFormaMappingTable
                    data={aquisitions}
                    columns={[
                        {
                            ...columns[0],
                            header: 'Aquisition',
                            isCellInput: false,
                            onChange: (item, value) => handleChange(setAquisitions, item.id, 'label', value),
                        },
                        {
                            ...columns[1],
                            onChange: (item, value) => handleChange(setAquisitions, item.id, 'sheet', value),
                        },
                        {
                            ...columns[2],
                            onChange: (item, value) => handleChange(setAquisitions, item.id, 'cell', value),
                        },
                    ]}
                    getRowKey={item => item.id}
                />
            </RenderIf>
        </div>
    );
};
