import { useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';

interface IProFormaWizardStepParams {
    orgId: string;
    proFormaId: string;
    stepNumber: number;
}

const getProFormaWizardStep = async ({ orgId, proFormaId, stepNumber }: IProFormaWizardStepParams) => {
    const url = `${API_ENDPOINTS.PRO_FORMA_WIZARD_STEP(orgId, proFormaId, stepNumber)}`;
    const response = await axiosInstance.get(url);
    return response.data;
};

export const useGetProFormaWizardStep = ({ orgId, proFormaId, stepNumber }: IProFormaWizardStepParams) => useQuery({
    queryKey: ['pro-forma-wizard-step', orgId, proFormaId, stepNumber],
    queryFn: () => getProFormaWizardStep({ orgId, proFormaId, stepNumber }),
    staleTime: 5000,
    refetchOnMount: true,
});
