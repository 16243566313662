import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import {
    NSButton, NSDropdown, NSInput, NSSelect,
} from 'bricks';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generatePath, Link, useNavigate } from 'react-router-dom';

import { useAuthContext } from 'contexts/AuthContext';
import RenderIf from 'components/RenderIf/RenderIf';
import { URLS } from 'services/urls';
import { camelToSnakeCase } from 'ns_libs/formatter';
import DealsTable from './DealsTable/DealsTable';
import NavMenu, { DEALS_SECTIONS } from './NavMenu/NavMenu';
import VisibleColumnsDropdown from './VisibleColumnsDropdown/VisibleColumnsDropdown';
import './Deals.scss';
import { COLUMN_OPTIONS, getInitialColumnVisibility } from './constants';
import { Deal, IVisibleColumns } from './types';
import { DealsOverviewProvider } from './context/DealsOverviewProvider';
import PipelineSidebar from './PipelineSidebar/PipelineSidebar';
import MapView from './MapView/MapView';
import { useGetDeals } from './hooks/useFetchDeals';
import useDebounce from '../../hooks/useDebounce';
import { useGetDealGroups } from './hooks/useGetDealGroups';
import DealGroupMenu from './DealsTable/BulkActions/DealGroupMenu';
import DeleteDealGroupModal from './DealsTable/BulkActions/DeleteDealGroupModal';
import RenameDealGroupModal from './DealsTable/BulkActions/RenameDealGroupModal';
import { PageSpecificKeys, useLocalStorage } from '../../helpers/localStorage';
import { getSubprojectStageIds } from './helpers/subprojectStages';

const Deals = () => {
    const { selectedOrganizationId } = useAuthContext();

    const locallySavedActiveSection = window.localStorage.getItem('dealsActiveSection');
    const [activeSection, setActiveSection] = useState<string | null>(locallySavedActiveSection || DEALS_SECTIONS.MAP);
    const [searchValue, setSearchValue] = useState<string>('');

    const [locallyStoredVisibleColumns, setLocallyStoredVisibleColumns] = useLocalStorage(PageSpecificKeys.DealsPage, getInitialColumnVisibility());
    const [locallyStoredColumnOrder, setLocallyStoredColumnOrder] = useLocalStorage<string[]>(
        PageSpecificKeys.DealsPageColumnOrder,
        COLUMN_OPTIONS.map(col => col.value).filter(
            key => key !== 'select' && key !== 'actions',
        ), // Default column order without first and last columns
    );

    const [visibleColumns, setVisibleColumns] = useState<IVisibleColumns>(locallyStoredVisibleColumns);
    const [columnOrder, setColumnOrder] = useState<string[]>(() => ['select', ...locallyStoredColumnOrder, 'actions']);
    const [isExpandedPipeline, setIsExpandedPipeline] = useState<boolean>(() => {
        const storedValue = window.localStorage.getItem('isExpandedProjectPipeline');
        return storedValue ? JSON.parse(storedValue) : false;
    });
    const [selectedStages, setSelectedStages] = useState<Record<string, boolean>>({});
    const [selectedDealGroup, setSelectedDealGroup] = useState<number | undefined>(undefined);
    const [selectedDeal, setSelectedDeal] = useState<Deal>({} as Deal);
    const [isOpenDeleteDealGroupModal, setIsOpenDeleteDealGroupModal] = useState(false);
    const [isOpenRenameDealGroupModal, setIsOpenRenameDealGroupModal] = useState(false);
    const [sortBy, setSortBy] = useState<string | undefined>();
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | undefined>();

    const debouncedValue = useDebounce(searchValue, 500);
    const navigate = useNavigate();

    const handleDealSelection = useCallback((deal: Deal) => {
        setSelectedDeal(deal);
    }, []);

    // Pagination state
    const [pageSize, setPageSize] = useState(() => Number(localStorage.getItem('pageSize')) || 10);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        localStorage.setItem('pageSize', pageSize.toString());
    }, [pageSize]);

    const isOptionDisplayed = (key: string) => true; // Adjust logic

    useEffect(() => {
        const locallySavedSelectedStages = window.localStorage.getItem('dealPipelineSelectedStages');
        if (locallySavedSelectedStages) {
            setSelectedStages(JSON.parse(locallySavedSelectedStages));
        }
    }, []);

    const selectedSubprojectStageIds = getSubprojectStageIds(selectedStages);

    // Fetch deals using the custom hook
    const { data: dealGroups, isLoading: isLoadingDealGroups } = useGetDealGroups(selectedOrganizationId!);
    const { data: deals, isLoading: isLoadingDeals } = useGetDeals({
        pageSize,
        currentPage,
        orgId: selectedOrganizationId!,
        searchValue: debouncedValue,
        sortBy,
        sortOrder,
        groupId: selectedDealGroup,
        subprojectStageIds: getSubprojectStageIds(selectedStages),
    });

    useEffect(() => {
        if (!isLoadingDeals && deals?.items.length === 0 && selectedSubprojectStageIds === '') {
            navigate(generatePath(URLS.DEALS.FORM, { dealId: 'new' }));
        } else if (deals?.items?.[0]) {
            setSelectedDeal(deals.items[0]);
        }
    }, [deals, isLoadingDeals]);

    const handleSort = (columnName: string, order: 'asc' | 'desc') => {
        setSortBy(camelToSnakeCase(columnName));
        setSortOrder(order);
    };

    const handleAscendingSort = (columnName: string) => {
        handleSort(columnName, 'asc');
    };

    const handleDescendingSort = (columnName: string) => {
        handleSort(columnName, 'desc');
    };

    const isEmpty = deals?.items.length === 0;

    const updateVisibleColumns = (columns: IVisibleColumns) => {
        setVisibleColumns(columns);
        setLocallyStoredVisibleColumns(columns); // Persist updates in localStorage
    };

    const updateColumnOrder = (newOrder: string[]) => {
        const firstColumnKey = 'select';
        const lastColumnKey = 'actions';

        const filteredOrder = newOrder.filter(
            key => key !== firstColumnKey && key !== lastColumnKey,
        );

        setColumnOrder(newOrder);
        setLocallyStoredColumnOrder(filteredOrder);
    };

    const handleChangeSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const handleDealGroupChange = (option: { label: string; value: number | null } | null) => {
        setSelectedDealGroup(option?.value || undefined);
    };

    const dealGroupOptions = dealGroups
        ? [{ label: 'All deals', value: undefined }, ...dealGroups.map(group => ({ label: group.name, value: group.id }))]
        : [];

    const createOptions = [
        { label: 'Create deal', value: 'create' },
        { label: 'Bulk upload deals', value: 'bulkUpload' },
    ];

    useEffect(() => setCurrentPage(1), [debouncedValue]);

    const toggleDeleteDealGroupModal = () => setIsOpenDeleteDealGroupModal(!isOpenDeleteDealGroupModal);
    const toggleRenameDealGroupModal = () => setIsOpenRenameDealGroupModal(!isOpenRenameDealGroupModal);
    const dealGroupName = dealGroupOptions.find(option => option.value === selectedDealGroup) || null;

    const onDeleteDealGroupSuccess = () => {
        setSelectedDealGroup(undefined);
    };

    const emptyMessage = selectedDealGroup ? 'No deals in this group.' : 'No deals within phase(s).';

    return (
        <DealsOverviewProvider
            selectedStages={selectedStages}
            setSelectedStages={setSelectedStages}
            isExpandedPipeline={isExpandedPipeline}
            setIsExpandedPipeline={setIsExpandedPipeline}
        >
            <PipelineSidebar isExpandedPipeline={isExpandedPipeline} setIsExpandedPipeline={setIsExpandedPipeline} />
            <div
                className={classNames('Deals', {
                    'Deals--empty': isEmpty,
                    'Deals--padding-collapsed': !isExpandedPipeline,
                    'Deals--padding-expanded': isExpandedPipeline,
                })}
            >
                <div className="Deals__header-container d-flex justify-content-between align-items-center">
                    <div>
                        <p className="Deals__header-container__title">Deals</p>
                        <p className="Deals__header-container__subtitle">
                            {deals?.itemCount}
                            {' '}
                            active deals
                        </p>
                    </div>

                    <div className="d-flex align-items-center">
                        {selectedDealGroup !== undefined ? (
                            <DealGroupMenu onRename={toggleRenameDealGroupModal} onDelete={toggleDeleteDealGroupModal} />
                        ) : null}
                        <NSSelect
                            formClassName="Deals__select"
                            options={dealGroupOptions}
                            value={dealGroupName}
                            onChange={handleDealGroupChange}
                            name="DealGroup"
                            menuPosition="absolute"
                            isClearable={false}
                            isLoading={isLoadingDealGroups}
                            tabIndex={0}
                        />
                        <div className="Deals__vertical-divider" />
                        <Link to={URLS.REPORTS.HOME} className="mr-2">
                            <NSButton
                                innerContentClassName="NSButton--inner-content-bold"
                                color="secondary"
                                outline={false}
                                id="reports-btn"
                                allowReadOnlyAccess
                            >
                                Reports
                            </NSButton>
                        </Link>
                        <NSDropdown
                            options={createOptions}
                            selectedOption={createOptions[0]}
                            handleSelectOption={selectedOption => {
                                if (selectedOption?.value === 'create') {
                                    navigate(generatePath(URLS.DEALS.FORM, { dealId: 'new' }));
                                } else if (selectedOption?.value === 'bulkUpload') {
                                    // TODO change to bulk upload route
                                    navigate(generatePath(URLS.DEALS.HOME));
                                }
                            }}
                            toggleClassName="bg-primary font-weight-bold rounded"
                            hideOptionCheckmarkIcon
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-between mt-2">
                    <NavMenu activeSection={activeSection} setActiveSection={setActiveSection} />
                    <div className="d-flex w-25">
                        <VisibleColumnsDropdown
                            visibleColumns={visibleColumns}
                            updateVisibleColumns={updateVisibleColumns}
                            columnOptions={COLUMN_OPTIONS}
                            isOptionDisplayed={isOptionDisplayed}
                        />
                        <NSInput
                            type="search"
                            name="search-bar"
                            placeholder="Search"
                            value={searchValue}
                            onChange={handleChangeSearchValue}
                            customClassName="bg-secondary border-0 px-0 w-100"
                            inputGroupClassName="bg-secondary border-0"
                            prependInputAddon={<FontAwesomeIcon className="text-muted" icon={faMagnifyingGlass} />}
                        />
                    </div>
                </div>
                <RenderIf isTrue={selectedSubprojectStageIds !== '' && deals?.items && deals?.items?.length === 0}>
                    <div className="d-flex text-center text-white align-items-center justify-content-center Deals__no-deals-message">
                        {emptyMessage}
                    </div>
                </RenderIf>
                <RenderIf isTrue={activeSection === DEALS_SECTIONS.MAP && deals?.items && deals?.items?.length > 0}>
                    <MapView selectedDeal={selectedDeal} />
                    <DealsTable
                        deals={deals?.items || []}
                        visibleColumns={visibleColumns}
                        selectedDeal={selectedDeal}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalItems={deals?.itemCount || 0}
                        handleSelectDeal={handleDealSelection}
                        onAscendingSort={handleAscendingSort}
                        onDescendingSort={handleDescendingSort}
                        columnOrder={columnOrder}
                        onColumnOrderChange={updateColumnOrder}
                    />
                </RenderIf>
                <RenderIf isTrue={activeSection === DEALS_SECTIONS.LIST && deals?.items && deals?.items?.length > 0}>
                    <DealsTable
                        className="pt-2"
                        deals={deals?.items || []}
                        visibleColumns={visibleColumns}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalItems={deals?.itemCount || 0}
                        handleSelectDeal={handleDealSelection}
                        onAscendingSort={handleAscendingSort}
                        onDescendingSort={handleDescendingSort}
                        columnOrder={columnOrder}
                        onColumnOrderChange={updateColumnOrder}
                    />
                </RenderIf>
                <RenameDealGroupModal
                    toggle={toggleRenameDealGroupModal}
                    isOpen={isOpenRenameDealGroupModal}
                    orgId={selectedOrganizationId!}
                    dealGroupId={selectedDealGroup}
                    currentGroupName={dealGroupName !== null ? dealGroupName.label : null}
                />
                <DeleteDealGroupModal
                    toggle={toggleDeleteDealGroupModal}
                    isOpen={isOpenDeleteDealGroupModal}
                    dealGroupId={selectedDealGroup}
                    dealName={dealGroupName !== null ? dealGroupName.label : null}
                    onDeleteSuccess={onDeleteDealGroupSuccess}
                />
            </div>
        </DealsOverviewProvider>
    );
};

export default Deals;
