import React, { useState } from 'react';
import {
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faUsers } from '@fortawesome/pro-solid-svg-icons';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import { IOrganization } from 'hooks/useGetUserOrganizations';
import { useAuthContext } from 'contexts/AuthContext';
import { URLS } from 'services/urls';
import './OrganizationMenu.scss';

export interface IOrganizationMenuProps {
    organizations: IOrganization[];
}

const OrganizationMenu = ({ organizations }: IOrganizationMenuProps) => {
    const { selectedOrganizationId, setSelectedOrganizationId } = useAuthContext();
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleIsExpanded = () => setIsExpanded(!isExpanded);

    const updateSelectedOrgUUID = (orgUUID: string) => {
        setSelectedOrganizationId(orgUUID);
    };

    const sortedOrgs = [...organizations].sort((a, b) => {
        if (a.correlationId === selectedOrganizationId) {
            return -1;
        }
        if (b.correlationId === selectedOrganizationId) {
            return 1;
        }
        return a.name.localeCompare(b.name);
    });
    const selectedOrg = sortedOrgs.find(org => org.correlationId === selectedOrganizationId);

    return (
        <Dropdown isOpen={isExpanded} toggle={toggleIsExpanded} direction="down">
            <DropdownToggle tag="span">
                <div
                    className={classNames('d-flex align-items-center justify-content-between cursor-pointer text-white rounded-lg border p-1', {
                        'border-primary': isExpanded,
                    })}
                    onClick={toggleIsExpanded}
                    onKeyDown={toggleIsExpanded}
                    role="button"
                    tabIndex={0}
                    data-testid="org-menu-toggle"
                >
                    <div className="d-block pl-1" style={{ lineHeight: '31px' }}>
                        <FontAwesomeIcon className="icon" icon={faUsers} fixedWidth />
                    </div>
                    <div className="ml-2" data-testid={`selected-org-${selectedOrg?.name}`}>
                        {selectedOrg?.name || ''}
                    </div>
                    <FontAwesomeIcon className="icon p-1" icon={isExpanded ? faCaretUp : faCaretDown} fixedWidth data-testid="caret-icon" />
                </div>

                <DropdownMenu className="mt-1" style={{ width: '300px' }}>
                    <OverlayScrollbarsComponent
                        options={{
                            scrollbars: { autoHide: 'leave' },
                            overflow: { y: 'scroll' },
                        }}
                        className="pr-2 overflow-auto"
                        style={{ maxHeight: '600px' }}
                        defer
                    >
                        {sortedOrgs.map(org => (
                            <DropdownItem
                                tag={Link}
                                to={URLS.DEALS.HOME}
                                onClick={() => updateSelectedOrgUUID(org.correlationId)}
                                title={org.name}
                                key={org.correlationId}
                                className="OrganizationMenu__item ml-1"
                                active={org.correlationId === selectedOrganizationId}
                            >
                                <div className="text-truncate">{org.name}</div>
                            </DropdownItem>
                        ))}
                    </OverlayScrollbarsComponent>
                </DropdownMenu>
            </DropdownToggle>
        </Dropdown>
    );
};

export default OrganizationMenu;
