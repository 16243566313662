/* eslint-disable no-unused-vars */

import React, { useEffect, useMemo, useState } from 'react';
import {
    NSCard, NSLabel, NSSelect,
} from 'bricks';
import NSInputNumberStepper from 'bricks/NSInputNumberStepper/NSInputNumberStepper';
import './ProFormaPermanentDebt.scss';
import RenderIf from 'components/RenderIf/RenderIf';
import { ProFormaGenericRow } from 'views/ProFormaTable/components/ProFormaGenericTable/types';
import ProFormaGenericTable from 'views/ProFormaTable/components/ProFormaGenericTable/ProFormaGenericTable';
import { useScrollableSync } from 'views/ProFormaTable/hooks/useScrollableSync';
import { formatUSDCurrency, formatUSDCurrencyTwoDigits } from 'ns_libs/formatter';
import { createCashFlowColumns, createCashFlowData } from 'views/ProFormaTable/helpers';
import { IPatchOperatingInfoData } from 'views/ProFormaTable/types';
import { format, parseISO } from 'date-fns';
import {
    DEBT_SERVICE_COLUMNS,
    INTEREST_OPTIONS,
    TOTAL_DEBTSERVICE_COLUMNS,
} from './constants';
import { useProFormaSocketContext } from '../../socketContext/ProFormaSocketProvider';

const ProFormaPermanentDebt = () => {
    const { operatingInfo, handleUpdateProFormaOperations } = useProFormaSocketContext();

    // loanFundingDate is same as stabilizedMonth by default, if user didn't choose a date
    // Check how we find stabilized month in MonthlyAnalysisTable and use here
    // TODO: https://app.clickup.com/t/2326533/PRODUCT-4442
    const loanFundingDate = null;

    const updateOperationsKeys = (keyValues: Partial<IPatchOperatingInfoData>) => {
        if (operatingInfo?.id) {
            handleUpdateProFormaOperations(operatingInfo.id, keyValues);
        }
    };

    // Debt service tables
    const [debtServiceRows, setDebtServiceRows] = useState<ProFormaGenericRow[]>([]);
    const { handleScroll, handleScrollableRef } = useScrollableSync();

    const debtServiceFlowColumns = useMemo(() => createCashFlowColumns(operatingInfo?.debtService?.cashFlow),
        [operatingInfo.debtService]);

    const debtServiceColumns = useMemo(() => [...DEBT_SERVICE_COLUMNS, ...debtServiceFlowColumns],
        [debtServiceFlowColumns]);

    useEffect(() => {
        if (operatingInfo.debtService) {
            const cashFlowColumns = createCashFlowData(operatingInfo.debtService.cashFlow || []);
            setDebtServiceRows([{
                id: '1',
                title: 'Debt service',
                stabilizationValue: String(operatingInfo.debtService.percentOfStabilizedValue),
                costPercentage: String(operatingInfo.debtService.percentOfCost),
                amount: String(operatingInfo.debtService.totalAmount),
                annualPayment: String(operatingInfo.debtService.annualPayment),
                ...cashFlowColumns,
            }]);
        }
    }, [operatingInfo.debtService]);

    const totalCashFlowDebtServiceColumns = useMemo(() => {
        const baseCols = TOTAL_DEBTSERVICE_COLUMNS.map(col => {
            let { header } = col;
            if (col.key === 'annualPayment') {
                header = formatUSDCurrencyTwoDigits(operatingInfo?.cashFlowAfterFinancing?.totalAnnualCashFlow || 0);
            }
            return { ...col, header };
        });

        if (operatingInfo?.cashFlowAfterFinancing?.cashFlow) {
            operatingInfo?.cashFlowAfterFinancing?.cashFlow.forEach(cf => {
                baseCols.push({
                    key: `column_${cf.month}`,
                    field: `column_${cf.month}`,
                    header: formatUSDCurrencyTwoDigits(cf.value || 0),
                    format: 'currency',
                    isFixed: false,
                });
            });
        }

        return baseCols;
    }, [operatingInfo?.cashFlowAfterFinancing]);

    return (
        <div>
            <div className="d-flex justify-content-end pb-3">
                <div className="ProFormaPermanentDebt__inputs-container w-50 pl-3">
                    <div className="d-flex align-items-center mt-2">
                        <NSLabel className="pl-0 col-4 my-auto font-weight-normal text-dark text-nowrap">Interest only?</NSLabel>
                        <NSSelect
                            options={INTEREST_OPTIONS}
                            name="isInterestOnly"
                            value={INTEREST_OPTIONS.find(option => operatingInfo.isInterestOnly === option.value)}
                            onChange={option => updateOperationsKeys({ isInterestOnly: option.value })}
                            isClearable={false}
                        />
                    </div>

                    <RenderIf isTrue={!operatingInfo.isInterestOnly}>
                        <div className="d-flex align-items-center mt-2">
                            <NSLabel className="pl-0 col-4 my-auto font-weight-normal text-dark text-nowrap">Amortization</NSLabel>
                            <NSInputNumberStepper
                                name="amortizationInYears"
                                value={operatingInfo.amortizationInYears || null}
                                onChange={(value: number) => updateOperationsKeys({ amortizationInYears: value })}
                                min={0}
                                step={1}
                                customSuffix="years"
                                inputGroupClassName="ProFormaPermanentDebt__input-width"
                            />
                        </div>
                    </RenderIf>

                    <div className="d-flex align-items-center mt-2">
                        <NSLabel className="pl-0 col-4 my-auto font-weight-normal text-dark text-nowrap">APR</NSLabel>
                        <NSInputNumberStepper
                            name="annualPercentageRate"
                            value={operatingInfo.annualPercentageRate || null}
                            onChange={(value: number) => updateOperationsKeys({ annualPercentageRate: value })}
                            min={0}
                            step={0.1}
                            max={100}
                            percentage
                            inputGroupClassName="ProFormaPermanentDebt__input-width"
                        />
                    </div>
                    <div className="d-flex align-items-center mt-2">
                        <NSLabel className="pl-0 col-4 my-auto font-weight-normal text-dark text-nowrap">Fees & closing costs</NSLabel>
                        <NSInputNumberStepper
                            name="feesAndClosingCostsPercentage"
                            value={operatingInfo.feesAndClosingCostsPercentage || null}
                            onChange={(value: number) => updateOperationsKeys({ feesAndClosingCostsPercentage: value })}
                            min={0}
                            step={0.1}
                            max={100}
                            percentage
                            inputGroupClassName="ProFormaPermanentDebt__input-width"
                        />
                    </div>
                </div>
                <div className="w-50 pr-3 d-flex flex-column">
                    <div className="d-flex">
                        <NSCard className="NSCard--level-4 mt-2 w-50">
                            <div className="ProFormaPermanentDebt_stabilized-month-container flex-row justify-content-between align-items-center p-2">
                                <div className="text-muted">Loan funding</div>
                                <div className="text-white">
                                    {`${formatUSDCurrency(operatingInfo.loanFundingAmount || 0)} 
                                    ${loanFundingDate ? `(${format(parseISO(loanFundingDate), 'MMM yyyy')})` : null}`}
                                </div>
                            </div>
                        </NSCard>

                        <NSCard className="NSCard--level-4 mt-2 w-50 ml-2">
                            <div className="ProFormaPermanentDebt_stabilized-month-container flex-row justify-content-between align-items-center p-2">
                                <div className="text-muted">Loan payoff</div>
                                <div className="text-white">
                                    {`${formatUSDCurrency(operatingInfo.loanPayoffAmount || 0)}
                                    ${operatingInfo.loanPayoffDate ? `(${format(parseISO(operatingInfo.loanPayoffDate), 'MMM yyyy')})` : null}`}
                                </div>
                            </div>
                        </NSCard>
                    </div>

                    <div className="d-flex">
                        <NSCard className="NSCard--level-4 mt-2 w-50">
                            <div className="ProFormaPermanentDebt_stabilized-month-container flex-row justify-content-between align-items-center p-2">
                                <div className="text-muted">Debt service coverage ratio</div>
                                <div className="text-white">
                                    {operatingInfo.debtServiceCoverageRatio ? `${operatingInfo.debtServiceCoverageRatio}X` : null}
                                </div>
                            </div>
                        </NSCard>

                        <NSCard className="NSCard--level-4 mt-2 w-50 ml-2">
                            <div className="ProFormaPermanentDebt_stabilized-month-container flex-row justify-content-between align-items-center p-2">
                                <div className="text-muted">Fees & closing costs</div>
                                <div className="text-white">
                                    {formatUSDCurrency(operatingInfo.feesAndClosingCostsAmount || 0)}
                                </div>
                            </div>
                        </NSCard>
                    </div>

                    <div className="d-flex">
                        <NSCard className="NSCard--level-4 mt-2 w-100">
                            <div className="ProFormaPermanentDebt_stabilized-month-container flex-row justify-content-between align-items-center p-2">
                                <div className="text-muted">Cash-on-cash on return</div>
                                <div className="text-white">
                                    {operatingInfo.cashOnCashReturn
                                        ? `${operatingInfo.cashOnCashReturn}% (Month ${operatingInfo.cashOnCashReturnMonth})`
                                        : null}
                                </div>
                            </div>
                        </NSCard>
                    </div>
                </div>
            </div>
            <div className="ProFormaPermanentDebt__scrollable-section p-3">
                <ProFormaGenericTable
                    columns={debtServiceColumns}
                    rows={debtServiceRows}
                    setRows={setDebtServiceRows}
                    hideAddButton
                    hideTotalFooter
                    onScrollableRef={handleScrollableRef(0)}
                    onScroll={handleScroll}
                />
                <div className="pb-1" />
                <ProFormaGenericTable
                    columns={totalCashFlowDebtServiceColumns}
                    rows={[]}
                    setRows={() => { }}
                    hideTotalFooter
                    hideAddButton
                    onScrollableRef={handleScrollableRef(1)}
                    onScroll={handleScroll}
                />
            </div>
        </div>
    );
};

export default ProFormaPermanentDebt;
