import { useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';

interface IProFormasQueryParams {
    dealId: number;
}

interface IBOEScenario {
    id: number;
    name: string;
}

export interface IProForma {
    id: number;
    name: string;
    isActive: boolean;
    createdAt: string;
    updatedAt: string;
    createdBy: string;
    updatedBy: string;
    boeScenarioId: number | null;
    boeScenario: IBOEScenario | null;
    proFormaStatusId: number;
}

const getProFormas = async ({ dealId }: IProFormasQueryParams): Promise<IProForma[]> => {
    const response = await axiosInstance.get(`${API_ENDPOINTS.PRO_FORMA(dealId)}`);
    return response.data;
};

export const useGetProFormasByDeal = ({ dealId }: IProFormasQueryParams) => useQuery({
    queryKey: ['getProFormas', dealId],
    queryFn: () => getProFormas({ dealId }),
    staleTime: 5000,
    refetchOnMount: true,
});
