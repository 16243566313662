import React from 'react';
import { numeralFormatterCurrency, percentFormatter } from 'ns_libs/formatter';
import { Col, Row } from 'reactstrap';
import { ICostMetricsData } from '../../../types';

export const CostMetrics: React.FC<{ data: ICostMetricsData }> = ({ data }) => {
    const placeholder = '-';
    return (
        data && (
            <div className="text-white text-right">
                <div>{percentFormatter(data.usagePercent)}</div>
                <Row>
                    <Col xs="6">$ / Build .SF:</Col>
                    <Col xs="6" className="text-truncate">
                        {numeralFormatterCurrency(data.costPerBuildSF)}
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">$ / Unit:</Col>
                    <Col xs="6" className="text-truncate">
                        {numeralFormatterCurrency(data.costPerUnit) || placeholder}
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">Amount:</Col>
                    <Col xs="6" className="text-truncate">
                        {numeralFormatterCurrency(data.amount)}
                    </Col>
                </Row>
            </div>
        )
    );
};

export default CostMetrics;
