import React, {
    useState, useCallback, useEffect, useMemo,
} from 'react';
import { NSSlideout } from 'bricks';
import { useUpdateDocument } from 'views/DocumentLibrary/hooks';
import { IDealDocument } from 'views/DocumentLibrary/types';
import { DealDocumentType } from 'views/DocumentLibrary/constants';
import DocumentLibraryHeader from './DocumentLibraryHeader';
import DocumentPreview from './DocumentPreview';
import DocumentDetails from './DocumentDetails';
import './DocumentLibrarySlideout.scss';

interface DocumentLibrarySlideoutProps {
    isOpen: boolean;
    toggle: () => void;
    document: IDealDocument;
    onDelete: (document: IDealDocument) => void;
}

const DocumentLibrarySlideout: React.FC<DocumentLibrarySlideoutProps> = ({
    isOpen, toggle, document, onDelete,
}) => {
    // const {
    //     uri, name, size, format, folder, documentType, tasks, dateModified,
    // } = document;
    // DUMMY VALUES:
    const uri = 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf';
    const name = 'my doc.pdf';
    const size = '1.2 MB';
    const format = 'pdf';
    const folder = 'folder';
    const documentType = DealDocumentType.LEASE_AGREEMENT;
    const tasks = ['task1', 'task2'];
    const dateModified = '2021-09-01';
    const directoryId = 1;
    const documentId = 1;

    const [editMode, setEditMode] = useState(false);
    const [docDetails, setDocDetails] = useState({
        documentName: name, folder, documentType, activities: tasks, format, size, dateModified, directoryId,
    });

    const { mutate: updateDocument } = useUpdateDocument();

    useEffect(() => {
        setDocDetails({
            documentName: name, folder, documentType, activities: tasks, format, size, dateModified, directoryId,
        });
    }, [document]);

    const handleOpenDocument = useCallback(() => {
        window.open(uri, '_blank');
    }, [uri]);

    const toggleEditMode = useCallback(() => setEditMode(prev => !prev), []);

    const handleSaveDocument = useCallback(() => {
        const data = {
            name: docDetails.documentName,
            type: docDetails.documentType,
            directoryId: docDetails.directoryId,
            activities: docDetails.activities,
        };
        updateDocument({ documentId, data });
        toggleEditMode();
    }, [documentId, docDetails]);

    const handleDetailsChange = (field: string, value: any) => {
        setDocDetails(prev => ({ ...prev, [field]: value }));
    };

    const handleDeleteDocument = useCallback(() => {
        onDelete(document);
        toggle();
    }, [documentId]);

    const FilePreview = useMemo(() => (
        <DocumentPreview
            uri={uri}
            format={format}
            handleOpenDocument={handleOpenDocument}
        />
    ),
    [uri, format, handleOpenDocument]);

    return (
        <NSSlideout
            customHeader={(
                <DocumentLibraryHeader
                    name={docDetails.documentName}
                    size={size}
                    toggle={toggle}
                    editMode={editMode}
                    toggleEditMode={toggleEditMode}
                    handleSaveDocument={handleSaveDocument}
                    handleOpenDocument={handleOpenDocument}
                    handleDeleteDocument={handleDeleteDocument}
                />
            )}
            size="xl"
            isOpen={isOpen}
            toggle={toggle}
            modalBodyClassName="DocumentLibrarySlideout p-0"
        >
            <div className="d-flex h-100">
                {FilePreview}
                <DocumentDetails
                    editMode={editMode}
                    documentName={docDetails.documentName}
                    folder={docDetails.folder}
                    format={docDetails.format}
                    size={docDetails.size}
                    dateModified={docDetails.dateModified}
                    documentType={docDetails.documentType}
                    activities={docDetails.activities}
                    onChange={handleDetailsChange}
                />
            </div>
        </NSSlideout>
    );
};

export default DocumentLibrarySlideout;
