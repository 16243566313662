import React from 'react';
import './TimelineChart.scss';
import {
    Chart as ChartJS, LinearScale, BarController, ScatterController, BarElement, PointElement, Legend,
} from 'chart.js';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'react-chartjs-2';
import { allPlugins } from 'components/TimelineChart/chartPlugins';
import useTimelineChartData, { UseTimelineChartDataParams } from './useTimelineChartData';
import { DEFAULT_ROW_HEIGHT } from './constants';

ChartJS.register(LinearScale, BarController, ScatterController, BarElement, PointElement, Legend, ChartDataLabels, ...allPlugins);

export interface TimelineChartProps<T extends Record<string, any>> extends UseTimelineChartDataParams<T> {
    enableScrollView?: boolean;
    lightMode?: boolean;
    rowHeight?: number;
}

const TimelineChart = <T extends Record<string, any>>({
    data,
    scatterXFields,
    barXFields,
    yField,
    enableScrollView = true,
    hideYAxisLabels = false,
    lightMode = false,
    rowHeight = DEFAULT_ROW_HEIGHT,
}: TimelineChartProps<T>) => {
    const {
        chartData, options, isChartReady, chartRef,
    } = useTimelineChartData({
        data, scatterXFields, barXFields, yField, hideYAxisLabels, lightMode,
    });

    const DATA_BASED_ROW_HEIGHT = data.length * rowHeight;
    const MAX_CHART_HEIGHT = rowHeight * 3.7;
    let chartHeight = enableScrollView ? Math.min(DATA_BASED_ROW_HEIGHT, MAX_CHART_HEIGHT) : DATA_BASED_ROW_HEIGHT;

    // Add some space for header
    if (chartHeight < 150) chartHeight += 80;

    return (
        <div
            data-testid="timeline-chart"
            data-ready={isChartReady}
            className="chart-container"
            style={{ height: `${chartHeight}px` }}
        >
            <div style={{ height: Math.max(data.length * rowHeight, chartHeight) }} className="m-0 p-0">
                <Chart ref={chartRef} type="bar" data={chartData} options={options} />
            </div>
        </div>
    );
};

export default TimelineChart;
