import { useState, useEffect } from 'react';
import { IOtherIncome } from '../types';

interface UseOtherIncomeManagerProps {
    initialIncomes?: IOtherIncome[];
}

const DEFAULT_INCOMES: IOtherIncome[] = [
    {
        id: 1,
        name: 'RUBS (Ratio Utility Billing System)',
        annualGrowth: 5,
        valuePerUnitPerMonth: 50,
        amountPerYear: 600,
        percentageOfTotalRentalIncome: 0,
    },
    {
        id: 2,
        name: 'Parking',
        annualGrowth: 3,
        valuePerUnitPerMonth: 100,
        amountPerYear: 1200,
        percentageOfTotalRentalIncome: 0,
    },
];

export const useOtherIncomeManager = ({ initialIncomes = [] }: UseOtherIncomeManagerProps) => {
    const [incomeArray, setIncomeArray] = useState<IOtherIncome[]>(() => {
        if (initialIncomes?.length > 0) {
            return initialIncomes.map(income => ({
                id: income.id || Date.now(),
                name: income.name || '',
                annualGrowth: Number(income.annualGrowth) * 100,
                valuePerUnitPerMonth: Number(income.valuePerUnitPerMonth) || 0,
                amountPerYear: Number(income.amountPerYear) || 0,
                percentageOfTotalRentalIncome: Number(income.percentageOfTotalRentalIncome) * 100,
            }));
        }
        return DEFAULT_INCOMES;
    });

    // Only update when initialIncomes changes and is not empty
    useEffect(() => {
        if (initialIncomes?.length > 0) {
            const newIncomes = initialIncomes.map(income => ({
                id: income.id || Date.now(),
                name: income.name || '',
                annualGrowth: Number(income.annualGrowth) * 100,
                valuePerUnitPerMonth: Number(income.valuePerUnitPerMonth) || 0,
                amountPerYear: Number(income.amountPerYear) || 0,
                percentageOfTotalRentalIncome: Number(income.percentageOfTotalRentalIncome) * 100,
            }));
            setIncomeArray(newIncomes);
        }
    }, [initialIncomes]);

    const handleAddOrDuplicateIncome = (incomeId?: number) => {
        setIncomeArray(prevArray => {
            if (!incomeId) {
                return [...prevArray, {
                    id: Date.now(),
                    name: '',
                    annualGrowth: 3,
                    valuePerUnitPerMonth: 0,
                    amountPerYear: 0,
                    percentageOfTotalRentalIncome: 0,
                }];
            }

            const incomeToDuplicate = prevArray.find(income => income.id === incomeId);
            if (!incomeToDuplicate) return prevArray;

            const duplicatedIncome = {
                ...incomeToDuplicate,
                id: Date.now(),
            };
            return [...prevArray, duplicatedIncome];
        });
    };

    const handleDeleteIncome = (incomeId: number) => {
        setIncomeArray(prevArray => prevArray.filter(income => income.id !== incomeId));
    };

    const handleUpdateIncome = (updatedIncome: IOtherIncome) => {
        setIncomeArray(prevArray => prevArray.map(income => {
            if (income.id === updatedIncome.id) {
                const annualGrowth = Math.min(100, Math.max(0, Number(updatedIncome.annualGrowth || 0)));
                const percentageOfTotalRentalIncome = Math.min(100, Math.max(0, Number(updatedIncome.percentageOfTotalRentalIncome || 0)));

                return {
                    ...income,
                    ...updatedIncome,
                    annualGrowth,
                    valuePerUnitPerMonth: Number(updatedIncome.valuePerUnitPerMonth || 0),
                    amountPerYear: Number(updatedIncome.valuePerUnitPerMonth || 0) * 12,
                    percentageOfTotalRentalIncome,
                };
            }
            return income;
        }));
    };

    const getApiData = () => incomeArray.map(income => ({
        ...income,
        annualGrowth: (Number(income.annualGrowth) || 0) / 100,
        percentageOfTotalRentalIncome: (Number(income.percentageOfTotalRentalIncome) || 0) / 100,
    }));

    return {
        incomeArray,
        handleAddOrDuplicateIncome,
        handleDeleteIncome,
        handleUpdateIncome,
        setIncomeArray,
        getApiData,
    };
};
