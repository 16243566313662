import React, { useState, useMemo } from 'react';
import '../ProFormaIncomeTable.scss';
import ProFormaGenericTable from 'views/ProFormaTable/components/ProFormaGenericTable/ProFormaGenericTable';
import { ProFormaGenericRow } from 'views/ProFormaTable/components/ProFormaGenericTable/types';
import { formatUSDCurrencyTwoDigits } from 'ns_libs/formatter';
import { useScrollableSync } from 'views/ProFormaTable/hooks/useScrollableSync';
import RenderIf from 'components/RenderIf/RenderIf';
import {
    BASE_RECOVERY_COLUMNS,
    BASE_UNIT_MIX_BASE_COLUMNS,
    BASE_INCOME_ADJUSTMENTS_COLUMNS,
    BASE_OTHER_INCOME_COLUMNS,
    BASE_ACTUAL_TENANTS_UNIT_MIX_COLUMNS,
    BASE_ACTUAL_RECOVERY_COLUMNS,
    BASE_ACTUAL_OTHER_INCOME_COLUMNS,
    BASE_ACTUAL_INCOME_ADJUSTMENTS_COLUMNS,
    BASE_ACTUAL_TOTAL_POTENTIAL_COLUMNS,
    BASE_TOTAL_POTENTIAL_COLUMNS,
} from './constants';

const DataCenterIncomeTable = () => {
    const [unitMixRows, setUnitMixRows] = useState<ProFormaGenericRow[]>([
        {
            id: '1',
            floor: '1',
            allocation: '30%',
            sf: '30,000',
            numberOfRacks: '100',
            kwPerRack: '10',
            pricePerRack: '100,000',
            pricePerKwMonth: '10',
            annualRentYearOne: '300,000',
        },

    ]);

    const [recoveryRows, setRecoveryRows] = useState<ProFormaGenericRow[]>([
        {
            id: '1',
            recovery: 'Power',
            recoveryStart: 'Month 1',
            pricePerKwYear: '800',
            amountPerYear: '320000',
        },
    ]);

    const [otherIncomeRows, setOtherIncomeRows] = useState<ProFormaGenericRow[]>([
        {
            id: '1',
            name: 'Cross Connect',
            start: 'Month 1',
            annualGrowth: '0.03',
            pricePerRackYear: '1200',
            pricePerSfYear: '12',
            amountPerYear: '60000',
        },
    ]);

    const [incomeAdjustmentsRows, setIncomeAdjustmentsRows] = useState<ProFormaGenericRow[]>([
        {
            id: '1',
            name: 'Vacancy',
            invisibleUnits: '',
            percentOfIncome: '0.05',
            pricePerRackYear: '60',
            pricePerKwYear: '40',
            totalAmountPerYear: '55000',
        },
    ]);

    const [actualUnitMixRows, setActualUnitMixRows] = useState<ProFormaGenericRow[]>([
        {
            id: '1',
            tenantName: 'Tech Corp',
            sf: '5000',
            numberOfRacks: '25',
            kwPerRack: '6',
            leaseStartDate: '2024-01-01',
            rentStartDate: '2024-02-01',
            rentStep: 'Annual',
            rentGrowth: '0.03',
            pricePerRack: '1100',
            pricePerKwMonth: '145',
            annualRentYearOne: '330000',
        },
    ]);

    const [actualRecoveryRows, setActualRecoveryRows] = useState<ProFormaGenericRow[]>([
        {
            id: '1',
            recovery: 'Power',
            recoveryStart: 'Month 1',
            recoveryPercentage: '1',
            proRateShare: '0.5',
            pricePerSfYear: '32',
            amountPerYear: '160000',
        },
    ]);

    const [actualOtherIncomeRows, setActualOtherIncomeRows] = useState<ProFormaGenericRow[]>([
        {
            id: '1',
            name: 'Cross Connect',
            start: 'Month 1',
            annualGrowth: '0.03',
            rentalIncome: '0.05',
            pricePerRackYear: '1000',
            pricePerKwYear: '120',
            amountPerYear: '25000',
        },
    ]);

    const [actualIncomeAdjustmentsRows, setActualIncomeAdjustmentsRows] = useState<ProFormaGenericRow[]>([
        {
            id: '1',
            name: 'Vacancy',
            percentOfIncome: '0.05',
            pricePerSFMonth: '2.5',
            pricePerUnitMonth: '50',
            totalAmountPerYear: '30000',
        },
    ]);

    const { handleScroll, handleScrollableRef } = useScrollableSync();

    const periodOptions = useMemo(() => [
        { label: 'MON 26', value: 'month-26' },
        { label: 'MON 27', value: 'month-27' },
    ], []);

    const operatingPeriodColumns = useMemo(() => {
        if (!periodOptions?.length) return [];

        return periodOptions.map((period, index) => ({
            key: `column_${period.value}`,
            field: `column_${period.value}`,
            header: (
                <div className="h6 NSTable__thead__tr__th--grey-text text-uppercase text-nowrap text-secondary text-right mb-0">
                    <div>
                        MONTH
                        {' '}
                        {index}
                    </div>
                    <div className="h6 text-uppercase text-right text-nowrap mt-1">{period.label}</div>
                </div>
            ),
            format: 'currency' as const,
            isFixed: false,
        }));
    }, [periodOptions]);

    const estimateUnitMixColumns = useMemo(() => [...BASE_UNIT_MIX_BASE_COLUMNS, ...operatingPeriodColumns], [operatingPeriodColumns]);

    const estimateRecoveryColumns = useMemo(() => [...BASE_RECOVERY_COLUMNS, ...operatingPeriodColumns], [operatingPeriodColumns]);

    const estimateOtherIncomeColumns = useMemo(() => [...BASE_OTHER_INCOME_COLUMNS, ...operatingPeriodColumns], [operatingPeriodColumns]);

    const estimateIncomeAdjustmentsColumns = useMemo(() => [...BASE_INCOME_ADJUSTMENTS_COLUMNS, ...operatingPeriodColumns], [operatingPeriodColumns]);

    const actualUnitMixColumns = useMemo(() => [...BASE_ACTUAL_TENANTS_UNIT_MIX_COLUMNS, ...operatingPeriodColumns], [operatingPeriodColumns]);

    const actualRecoveryColumns = useMemo(() => [...BASE_ACTUAL_RECOVERY_COLUMNS, ...operatingPeriodColumns], [operatingPeriodColumns]);

    const actualOtherIncomeColumns = useMemo(() => [...BASE_ACTUAL_OTHER_INCOME_COLUMNS, ...operatingPeriodColumns], [operatingPeriodColumns]);

    const actualIncomeAdjustmentsColumns = useMemo(() => [...BASE_ACTUAL_INCOME_ADJUSTMENTS_COLUMNS, ...operatingPeriodColumns], [operatingPeriodColumns]);

    const totalPotentialColumns = useMemo(() => {
        const baseCols = BASE_TOTAL_POTENTIAL_COLUMNS.map(col => {
            let { header } = col;
            if (col.key === 'totalPricePerSfYear') {
                header = formatUSDCurrencyTwoDigits(0);
            } else if (col.key === 'totalAmountPerYear') {
                header = formatUSDCurrencyTwoDigits(0);
            }
            return { ...col, header };
        });

        const operatingCols = operatingPeriodColumns.map(col => {
            const period = periodOptions.find(p => `column_${p.value}` === col.key);
            if (!period) return col;

            return {
                ...col,
                header: (
                    <div className="text-right">
                        {formatUSDCurrencyTwoDigits(0)}
                    </div>
                ),
            };
        });

        return [...baseCols, ...operatingCols];
    }, [operatingPeriodColumns, periodOptions]);

    const totalPotentialActualTenantsColumns = useMemo(() => {
        const baseCols = BASE_ACTUAL_TOTAL_POTENTIAL_COLUMNS.map(col => {
            let { header } = col;
            if (col.key === 'totalPricePerRackYear') {
                header = formatUSDCurrencyTwoDigits(0);
            } else if (col.key === 'totalAmountPerYear') {
                header = formatUSDCurrencyTwoDigits(0);
            }
            return { ...col, header };
        });

        const operatingCols = operatingPeriodColumns.map(col => {
            const period = periodOptions.find(p => `column_${p.value}` === col.key);
            if (!period) return col;
            return {
                ...col,
                header: (
                    <div className="text-right">
                        {formatUSDCurrencyTwoDigits(0)}
                    </div>
                ),
            };
        });

        return [...baseCols, ...operatingCols];
    }, [operatingPeriodColumns, periodOptions]);

    // TODO: remove this and get variable from socket
    const isEstimate = true;

    return (
        <div className="ProFormaIncome p-3">
            <RenderIf isTrue={isEstimate}>
                <>
                    <ProFormaGenericTable
                        columns={estimateUnitMixColumns}
                        rows={unitMixRows}
                        setRows={setUnitMixRows}
                        onScrollableRef={handleScrollableRef(0)}
                        onScroll={handleScroll}
                        totalText="Total rental income"
                        firstColumnWidth="140px"
                        numFirstColumns={2}
                        totalValues={{
                            allocation: false,
                        }}
                    />
                    <div className="pb-1" />
                    <ProFormaGenericTable
                        columns={estimateRecoveryColumns}
                        rows={recoveryRows}
                        setRows={setRecoveryRows}
                        onScrollableRef={handleScrollableRef(1)}
                        onScroll={handleScroll}
                        totalText="Total recovery"
                        totalValues={{
                            recoveryStart: false,
                            pricePerKwYear: false,
                        }}
                        hideAddButton
                    />
                    <div className="pb-1" />
                    <ProFormaGenericTable
                        columns={estimateOtherIncomeColumns}
                        rows={otherIncomeRows}
                        setRows={setOtherIncomeRows}
                        onScrollableRef={handleScrollableRef(2)}
                        onScroll={handleScroll}
                        totalText="Total other income"
                        totalValues={{
                            start: false,
                            annualGrowth: false,
                        }}
                    />
                    <div className="pb-1" />
                    <ProFormaGenericTable
                        columns={totalPotentialColumns}
                        rows={[]}
                        setRows={() => { }}
                        hideTotalFooter
                        hideAddButton
                        onScrollableRef={handleScrollableRef(3)}
                        onScroll={handleScroll}
                    />
                    <div className="pb-1" />
                    <ProFormaGenericTable
                        columns={estimateIncomeAdjustmentsColumns}
                        rows={incomeAdjustmentsRows}
                        setRows={setIncomeAdjustmentsRows}
                        onScrollableRef={handleScrollableRef(4)}
                        onScroll={handleScroll}
                        totalText="Effective gross revenue"
                        totalValues={{
                            percentOfIncome: false,
                        }}
                    />
                </>
            </RenderIf>
            <RenderIf isTrue={!isEstimate}>
                <>
                    <ProFormaGenericTable
                        columns={actualUnitMixColumns}
                        rows={actualUnitMixRows}
                        setRows={setActualUnitMixRows}
                        onScrollableRef={handleScrollableRef(0)}
                        onScroll={handleScroll}
                        totalText="Total rental income"
                    />
                    <div className="pb-1" />
                    <ProFormaGenericTable
                        columns={actualRecoveryColumns}
                        rows={actualRecoveryRows}
                        setRows={setActualRecoveryRows}
                        onScrollableRef={handleScrollableRef(1)}
                        onScroll={handleScroll}
                        totalText="Total recovery income"
                        totalValues={{
                            recoveryStart: false,
                            recoveryPercentage: false,
                            proRateShare: false,
                        }}
                        hideAddButton
                    />
                    <div className="pb-1" />
                    <ProFormaGenericTable
                        columns={actualOtherIncomeColumns}
                        rows={actualOtherIncomeRows}
                        setRows={setActualOtherIncomeRows}
                        onScrollableRef={handleScrollableRef(2)}
                        onScroll={handleScroll}
                        totalText="Total other income"
                        totalValues={{
                            start: false,
                            annualGrowth: false,
                            rentalIncome: false,
                        }}
                    />
                    <div className="pb-1" />
                    <ProFormaGenericTable
                        columns={totalPotentialActualTenantsColumns}
                        rows={[]}
                        setRows={() => { }}
                        hideTotalFooter
                        hideAddButton
                        onScrollableRef={handleScrollableRef(3)}
                        onScroll={handleScroll}
                    />
                    <div className="pb-1" />
                    <ProFormaGenericTable
                        columns={actualIncomeAdjustmentsColumns}
                        rows={actualIncomeAdjustmentsRows}
                        setRows={setActualIncomeAdjustmentsRows}
                        onScrollableRef={handleScrollableRef(2)}
                        onScroll={handleScroll}
                        totalText="Total other income"
                        totalValues={{
                            percentOfIncome: false,
                        }}
                    />
                </>
            </RenderIf>
        </div>
    );
};

export default DataCenterIncomeTable;
