import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
    IProFormaUses, ISelectedProjection, ProjectionType, DevelopmentUnitOfMeasureTypes,
} from 'views/ProFormaTable/types';
import { numeralFormatterCurrency } from 'ns_libs/formatter';
import RenderIf from 'components/RenderIf/RenderIf';
import { NSCheckbox } from 'bricks';
import { URLS } from 'services/urls';
import { PROJECTION_TYPE, PROJECTION_TYPES } from 'views/ProFormaTable/constants';
import { PRO_FORMA_UNIT_TO_FIELD_VALUES } from 'constants/unitTypes';
import NSCellInput from 'bricks/NSCellInput/NSCellInput';
import { useProFormaSocketContext } from 'views/ProFormaTable/components/ProForma/socketContext/ProFormaSocketProvider';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { IOptionAnyValue } from 'bricks/types';
import PeriodsSelect from '../../../../PeriodsSelect/PeriodsSelect';
import CurveFitModal from '../../../../CurveFitModal/CurveFitModal';
import { useUsesTableContext } from '../../../context/UsesTableProvider';
import PeriodCellValue from '../../../PeriodCellValue/PeriodCellValue';

export interface IUsesTableBudgetLine {
    budgetClassification: IProFormaUses;
    unitType: DevelopmentUnitOfMeasureTypes;
    periodOptions: IOptionAnyValue[];
}

const UsesTableBudgetLine = ({ budgetClassification, unitType, periodOptions }: IUsesTableBudgetLine) => {
    const { proFormaId } = useParams();
    const {
        proForma,
        handleUpdateBudgetClassifications,
        isBudgetClassificationUpdatingLoading,
        handleDeleteBudgetClassification,
        isBudgetClassificationDeletionLoading,
    } = useProFormaSocketContext();

    const { selectedCategories, updateSelectedCategories, isProFormaReadOnly } = useUsesTableContext();
    const [isHovered, setIsHovered] = useState(false);
    const [budgetLineNickname, setBudgetLineNickname] = useState(budgetClassification.budgetLineNickname || '');
    const [budgetClassificationAmount, setBudgetClassificationAmount] = useState(budgetClassification.amount || '');
    const [curveFitModalOpen, setCurveFitModalOpen] = useState(false);
    const [openDeleteBudgetLineModal, setOpenDeleteBudgetLineModal] = useState(false);

    const selectedUnitTotal = budgetClassification[PRO_FORMA_UNIT_TO_FIELD_VALUES[unitType]];

    const toggleDeleteBudgetLineModal = () => setOpenDeleteBudgetLineModal(!openDeleteBudgetLineModal);
    const toggleCurveFitModal = () => setCurveFitModalOpen(!curveFitModalOpen);

    const selectedProjectionsCurveList = PROJECTION_TYPES.filter(projection => projection.projectionType === budgetClassification.projectionType);
    const selectedCurveByVariable = selectedProjectionsCurveList?.find(
        projection => projection.projectionVariable === Number(budgetClassification.projectionVariable),
    );

    let selectedCurve: ISelectedProjection = {
        name: selectedProjectionsCurveList[0]?.curveType,
        value: budgetClassification.projectionVariable || null,
        imgPrimary: selectedProjectionsCurveList[0]?.imgPrimary,
    };

    if (
        budgetClassification.projectionType
        && [PROJECTION_TYPE.NORMAL, PROJECTION_TYPE.GAMMA].includes(budgetClassification.projectionType)
        && selectedCurveByVariable
    ) {
        selectedCurve = {
            name: selectedCurveByVariable.curveType,
            value: budgetClassification.projectionVariable || null,
            imgPrimary: selectedCurveByVariable.imgPrimary,
        };
    }

    const updateSuccessCallback = () => {
        if (curveFitModalOpen) toggleCurveFitModal();
    };

    const updateBudgetClassification = (updatedValues: Object) => {
        if (!proFormaId) return;
        if (budgetClassification.isEditable) {
            handleUpdateBudgetClassifications([{ id: budgetClassification.id, ...updatedValues }], updateSuccessCallback);
        }
    };

    const handleUpdateProjection = (projectionType: ProjectionType, projectionVariable: number | null) => {
        const targetProjection = PROJECTION_TYPES.find(projection => projection.projectionType === projectionType);
        if (targetProjection) {
            updateBudgetClassification({
                projectionType,
                projectionVariable,
            });
        }
    };

    const handleBudgetLineUpdate = (event: React.KeyboardEvent<HTMLInputElement> | null, updatedValues: Object) => {
        if (!event) updateBudgetClassification(updatedValues);
        else if (event.key === 'Enter') updateBudgetClassification(updatedValues);
        else if (event.key === 'Escape') setBudgetLineNickname(budgetClassification.budgetLineNickname || '');
    };

    const deleteBudgetLine = () => {
        handleDeleteBudgetClassification([budgetClassification.id], false, toggleDeleteBudgetLineModal);
    };

    const hasNoSelectedCategories = Object.values(selectedCategories).every(categoryLevel => !categoryLevel.length);

    const proFormaDateRange = proForma?.startDate && proForma?.saleDate ? [proForma.startDate, proForma.saleDate] : [];

    return (
        <>
            <tr className="NSTable__tbody__tr NSTable__tbody__tr--level-3">
                <td
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    className="NSTable__tbody__tr__td NSTable__tbody__tr__td--right-border NSTable__tbody__tr__td__input
                    d-flex justify-content-between align-items-center h-100"
                >
                    <div className="d-flex align-items-center h-100">
                        <RenderIf isTrue={budgetClassification.isEditable && !isProFormaReadOnly}>
                            <NSCheckbox
                                id={`selectSubcategory-${budgetClassification.id}`}
                                checked={selectedCategories.budgetClassifications.includes(budgetClassification.id)}
                                callback={() => updateSelectedCategories(null, null, [budgetClassification.id])}
                                containerClassName="custom-control-inline align-middle"
                            />
                        </RenderIf>
                        <NSCellInput
                            value={budgetClassification.budgetLineNickname}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBudgetLineNickname(e.target.value)}
                            placeholder="Enter budget line name"
                            customClassName={classNames({
                                'ml-5 pl-1': !budgetClassification.isEditable,
                                'ml-4': budgetClassification.isEditable,
                                'text-dark': !budgetClassification.isEditable,
                            })}
                            onEnter={(e: React.KeyboardEvent<HTMLInputElement>) => handleBudgetLineUpdate(e, { budgetLineNickname })}
                            onBlur={() => updateBudgetClassification({ budgetLineNickname })}
                            disabled={!budgetClassification.isEditable}
                        />
                    </div>
                    <RenderIf isTrue={hasNoSelectedCategories && budgetClassification.isEditable}>
                        <FontAwesomeIcon
                            icon={faTrash}
                            onClick={() => isHovered && toggleDeleteBudgetLineModal()}
                            className={classNames('text-muted cursor--pointer pr-2 hover:text-dark', { invisible: !isHovered })}
                        />
                    </RenderIf>
                </td>
                <td className="NSTable__tbody__tr__td NSTable__tbody__tr__td__input">
                    <RenderIf isTrue={budgetClassification.isEditable}>
                        <PeriodsSelect
                            proFormaDateRange={proFormaDateRange!}
                            budgetLineStartDate={budgetClassification.startDate}
                            budgetLineEndDate={budgetClassification.endDate}
                            updateBudgetClassification={updateBudgetClassification}
                            isStartDate
                        />
                    </RenderIf>
                </td>
                <td className="NSTable__tbody__tr__td NSTable__tbody__tr__td__input">
                    <RenderIf isTrue={budgetClassification.isEditable}>
                        <PeriodsSelect
                            proFormaDateRange={proFormaDateRange!}
                            budgetLineStartDate={budgetClassification.startDate}
                            budgetLineEndDate={budgetClassification.endDate}
                            updateBudgetClassification={updateBudgetClassification}
                            isStartDate={false}
                        />
                    </RenderIf>
                </td>
                <td className="NSTable__tbody__tr__td align-middle text-primary cursor--pointer text-primary text-nowrap" onClick={toggleCurveFitModal}>
                    <RenderIf isTrue={budgetClassification.isEditable}>
                        <RenderIf isTrue={budgetClassification.projectionType}>
                            <img
                                src={URLS.IMAGE_CLOUDFRONT_URL(selectedCurve.imgPrimary)}
                                alt={`selectedCurve-${selectedCurve.name}`}
                                width="16px"
                                className="mx-auto"
                            />
                            <small className="mt-0 ml-1">{selectedCurve.name}</small>
                        </RenderIf>
                        <RenderIf isTrue={!budgetClassification.projectionType}>
                            <div className="text-primary text-nowrap font-italic">+ Add</div>
                        </RenderIf>
                    </RenderIf>
                </td>
                <td className="NSTable__tbody__tr__td">
                    <div className="d-flex justify-content-end text-dark">
                        {selectedUnitTotal !== null ? numeralFormatterCurrency(selectedUnitTotal) : '—'}
                    </div>
                </td>
                <td className="NSTable__tbody__tr__td NSTable__tbody__tr__td--right-border
                NSTable__tbody__tr__td__input NSTable__tbody__tr__td--width-100"
                >
                    <div className="d-flex align-items-center justify-content-end">
                        <NSCellInput
                            value={budgetClassification.amount}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBudgetClassificationAmount(e.target.value)}
                            onEnter={(e: React.KeyboardEvent<HTMLInputElement>) => handleBudgetLineUpdate(
                                e, { amount: String(Number(budgetClassificationAmount)) },
                            )}
                            onBlur={() => updateBudgetClassification({ amount: String(Number(budgetClassificationAmount)) })}
                            type="number"
                            placeholder="—"
                            isRightAligned
                            customClassName={classNames('mr-1', {
                                'text-dark ': !budgetClassification.isEditable,
                            })}
                            disabled={!budgetClassification.isEditable}
                        />
                    </div>
                </td>
                {periodOptions?.map(period => {
                    const targetPeriod = budgetClassification.periods?.find(p => p.periodName === period.label);

                    return (
                        <PeriodCellValue
                            key={period.value}
                            period={period}
                            value={targetPeriod?.projected === null || budgetClassification.amount === null
                                ? '—' : numeralFormatterCurrency(targetPeriod?.projected)}
                            cellClassName="NSTable__tbody__tr__td NSTable__tbody__tr__td--width-100 text-dark text-right"
                        />
                    );
                })}
            </tr>

            <RenderIf isTrue={curveFitModalOpen}>
                <CurveFitModal
                    toggle={toggleCurveFitModal}
                    isOpen={curveFitModalOpen}
                    headerText="Curve"
                    handleUpdateProjection={handleUpdateProjection}
                    isLoadingCallback={isBudgetClassificationUpdatingLoading}
                    selectedCurve={selectedCurveByVariable}
                />
            </RenderIf>

            <RenderIf isTrue={openDeleteBudgetLineModal}>
                <ConfirmationModal
                    toggle={toggleDeleteBudgetLineModal}
                    isOpen={openDeleteBudgetLineModal}
                    onConfirm={deleteBudgetLine}
                    onCancel={toggleDeleteBudgetLineModal}
                    modalHeaderText={`Delete ${budgetClassification.budgetLineNickname}`}
                    modalFooterButtonText="Yes, delete"
                    modalFooterButtonIsLoading={isBudgetClassificationDeletionLoading}
                    danger
                >
                    <div className="text-dark font-weight-bold mb-1">
                        Are you sure you want to delete
                        {budgetClassification.budgetLineNickname}
                        ?
                    </div>
                    <div>Your work will not be able to be recovered.</div>
                </ConfirmationModal>
            </RenderIf>
        </>
    );
};

export default UsesTableBudgetLine;
