import React from 'react';
import { formatUSDCurrency } from 'ns_libs/formatter';
import { SummaryQuickStats } from 'views/ProFormaTable/types';
import ExecutiveSummaryCard from '../ExecutiveSummaryCard/ExecutiveSummaryCard';

interface IExecutiveSummaryQuickStatsProps {
    stats: SummaryQuickStats;
}

const ExecutiveSummaryQuickStats = ({ stats }: IExecutiveSummaryQuickStatsProps) => {
    const statSections = [
        {
            id: 'quickStats',
            title: 'Quick stats',
            items: [
                {
                    id: 'growthRate',
                    label: 'Growth rate - Rental income / Expenses',
                    value: `${stats.growthRate.rentalIncome}% / ${stats.growthRate.expenses}%`,
                },
                {
                    id: 'effectiveRent',
                    label: 'Effective rent - Per SF / Per unit',
                    value: `${stats.effectiveRent.perSf} / ${stats.effectiveRent.perUnit}`,
                },
                { id: 'generalVacancyCreditLoss', label: 'General vacancy & credit loss', value: `${stats.generalVacancyCreditLoss}%` },
                { id: 'operatingExpenseRatio', label: 'Operating expense ratio', value: `${stats.operatingExpenseRatio}%` },
                { id: 'breakevenOccupancy', label: 'Breakeven occupancy (untrended)', value: `${stats.breakevenOccupancy}%` },
                { id: 'dispositionNetProceeds', label: 'Disposition net proceeds', value: formatUSDCurrency(stats.dispositionNetProceeds) },
                {
                    id: 'loanPayoff', label: 'Loan payoff', value: formatUSDCurrency(Math.abs(stats.loanPayoff)), isNegative: true,
                },
            ],
        },
        {
            id: 'stats',
            items: [
                { id: 'equityProceedsFromSale', label: 'Equity proceeds from sale', value: formatUSDCurrency(stats.equityProceedsFromSale) },
                { id: 'cashOnCashReturn', label: 'Cash-on-cash return (Month 34)', value: `${stats.cashOnCashReturn}x` },
            ],
        },
    ];

    return (
        <div className="d-flex justify-content-between align-items-center mb-3">
            <ExecutiveSummaryCard containerClassName="w-100" sections={statSections} />
        </div>
    );
};

export default ExecutiveSummaryQuickStats;
