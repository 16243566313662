import { ProFormaGenericColumn } from 'views/ProFormaTable/components/ProFormaGenericTable/types';

export const INTEREST_OPTIONS = [
    { label: 'No', value: false },
    { label: 'Yes', value: true },
];

export const DEBT_SERVICE_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'title',
        field: 'title',
        header: '',
        format: 'none',
        isReadOnly: true,
    },
    {
        key: 'stabilizationValue',
        field: 'stabilizationValue',
        header: '% of stab. value',
        format: 'percentage',
        isReadOnly: true,
    },
    {
        key: 'costPercentage',
        field: 'costPercentage',
        header: '% of cost',
        format: 'percentage',
        isReadOnly: true,
    },
    {
        key: 'amount',
        field: 'amount',
        header: 'Amount',
        format: 'currency',
    },
    {
        key: 'annualPayment',
        field: 'annualPayment',
        header: 'Annual payment',
        format: 'currency',
        isReadOnly: true,
    },
];

export const TOTAL_DEBTSERVICE_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'title',
        field: 'title',
        header: 'Cash flow after financing',
        format: 'none',
        isReadOnly: true,
    },
    {
        key: 'annualPayment',
        field: 'annualPayment',
        header: '',
        format: 'none',
        isReadOnly: true,
    },
];

export const formatDateWithMonthAndYear = (date: Date) => date.toLocaleString('en-US', { month: 'short', year: 'numeric' });
