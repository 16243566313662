/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AppRouter from './AppRouter.tsx';
import { Auth0TokenProvider } from './contexts/Auth0TokenContext';
import { config } from './config';
import './css/main.scss';
import './css/globals/_cursor.scss';

const queryClient = new QueryClient();

const App = () => (
    <QueryClientProvider client={queryClient}>
        <Auth0Provider
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: config.auth0Audience,
            }}
            domain={config.auth0Domain}
            clientId={config.auth0ClientId}
        >
            <Auth0TokenProvider>
                <AppRouter />
            </Auth0TokenProvider>
        </Auth0Provider>
    </QueryClientProvider>
);

export default App;
