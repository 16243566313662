import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS, { QUERY_KEYS } from 'services/urls';
import { IDropdowns } from '../types';

const getDealFormLocalCatalogs = async (): Promise<IDropdowns> => {
    const response = await axiosInstance.get<IDropdowns>(
        API_ENDPOINTS.DROPDOWNS,
    );
    return response.data;
};

export const useGetDealFormLocalCatalogs = (
    options?: Omit<UseQueryOptions<IDropdowns>, 'queryKey' | 'queryFn'>,
) => useQuery<IDropdowns, Error>({
    queryKey: [QUERY_KEYS.DROPDOWNS],
    queryFn: getDealFormLocalCatalogs,
    ...(options ?? {}),
});
