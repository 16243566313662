import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import NSSwitch from 'bricks/NSSwitch/NSSwitch';
import RenderIf from 'components/RenderIf/RenderIf';
import { geocodeAddress } from 'utils/geocoding';
import useDebounce from 'hooks/useDebounce';
import { useGetMarketByZip } from 'hooks/useGetMarketByZip';
import { DefaultAttributes, IFormValues } from '../../types';
import DefaultAttributeInput from '../DefaultAttributeInput';
import CoordinatesInput from './CoordinatesInput';

interface AddressSectionProps {
    showRequiredOnly: boolean;
    setMapCoordinates: React.Dispatch<React.SetStateAction<{ center: { lat: number; lng: number } }>>;
}

const AddressSection: React.FC<AddressSectionProps> = ({ showRequiredOnly, setMapCoordinates }) => {
    const { values, setFieldValue } = useFormikContext<IFormValues>();
    const useCoordinates = !!values.coordinatesSwitch;

    const { refetch: getMarketByZip } = useGetMarketByZip(values.zipCode || '', { enabled: false });

    const debouncedLat = useDebounce(values.latitude, 1000);
    const debouncedLng = useDebounce(values.longitude, 1000);
    const debouncedAddress = useDebounce(values.address, 1000);
    const debouncedCity = useDebounce(values.city, 1000);
    const debouncedCounty = useDebounce(values.county, 1000);
    const debouncedState = useDebounce(values.state, 1000);
    const debouncedZipCode = useDebounce(values.zipCode, 1000);

    useEffect(() => {
        async function updateMapCenter() {
            if (useCoordinates) {
                if (!debouncedLat || !debouncedLng) return;
                setMapCoordinates({ center: { lat: debouncedLat, lng: debouncedLng } });
            } else {
                const addressParts = [
                    debouncedAddress,
                    debouncedCity,
                    debouncedCounty,
                    debouncedState,
                    debouncedZipCode,
                ].filter(Boolean).join(', ');
                if (!addressParts) return;
                const coordinates = await geocodeAddress(addressParts);
                if (!coordinates) return;
                const { lat, lng } = coordinates;
                if (lat && lng) {
                    setMapCoordinates({ center: { lat, lng } });
                }
            }
        }
        updateMapCenter();
    }, [
        useCoordinates,
        debouncedLat,
        debouncedLng,
        debouncedAddress,
        debouncedCity,
        debouncedCounty,
        debouncedState,
        debouncedZipCode,
    ]);

    useEffect(() => {
        if (debouncedZipCode) {
            updateMarketByZipCode();
            updateCountyByZipCode();
        }
    }, [debouncedZipCode]);

    const handleCoordinatesSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue('coordinatesSwitch', e.target.checked);
    };

    const updateMarketByZipCode = async () => {
        if (values.zipCode) {
            try {
                const marketData = await getMarketByZip();
                if (marketData.data?.msaData.correlationId) {
                    setFieldValue('marketId', marketData.data?.msaData.correlationId);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    const updateCountyByZipCode = async () => {
        if (values.zipCode) {
            const geoInfo = await geocodeAddress(values.zipCode);
            const { county } = geoInfo || {};
            if (county && !values.county) setFieldValue('county', county);
        }
    };

    return (
        <>
            <RenderIf isTrue={!useCoordinates}>
                <DefaultAttributeInput
                    label="Address"
                    name="address"
                    placeholder="Enter address"
                    attribute={DefaultAttributes.ADDRESS}
                    showRequiredOnly={showRequiredOnly}
                    additionalCondition={!useCoordinates}
                    className="mt-2"
                />
                <div className="d-flex my-2">
                    <DefaultAttributeInput
                        label="City"
                        name="city"
                        placeholder="Enter city"
                        attribute={DefaultAttributes.CITY}
                        showRequiredOnly={showRequiredOnly}
                        additionalCondition={!useCoordinates}
                        className="w-50 mr-2"
                    />
                    <DefaultAttributeInput
                        label="County"
                        name="county"
                        placeholder="Enter county"
                        attribute={DefaultAttributes.COUNTY}
                        showRequiredOnly={showRequiredOnly}
                        additionalCondition={!useCoordinates}
                        className="w-50 mr-2"
                    />
                    <DefaultAttributeInput
                        label="State"
                        name="state"
                        placeholder="Enter state"
                        attribute={DefaultAttributes.STATE}
                        showRequiredOnly={showRequiredOnly}
                        additionalCondition={!useCoordinates}
                        className="mr-2"
                    />
                    <DefaultAttributeInput
                        label="Zip"
                        name="zipCode"
                        placeholder="Enter zip"
                        attribute={DefaultAttributes.ZIP_CODE}
                        showRequiredOnly={showRequiredOnly}
                        additionalCondition={!useCoordinates}
                    />
                </div>
            </RenderIf>
            <RenderIf isTrue={useCoordinates}>
                <CoordinatesInput
                    attribute={DefaultAttributes.ADDRESS}
                    showRequiredOnly={showRequiredOnly}
                    className="my-2"
                />
            </RenderIf>
            <NSSwitch
                id="coordinatesSwitch"
                name="coordinatesSwitch"
                data-testid="coordinatesSwitch"
                checked={useCoordinates}
                onChange={handleCoordinatesSwitch}
                label="Use coordinates"
                containerClassName="text-white"
            />
        </>
    );
};

export default AddressSection;
