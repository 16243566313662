import { NSModal, NSCheckbox } from 'bricks';
import React from 'react';

interface DeleteRowConfirmationModalProps {
    isOpen: boolean;
    toggle: () => void;
    handleDelete: () => void;
    doNotShowAgain?: boolean;
    setDoNotShowAgain?: (value: boolean) => void;
}

const DeleteRowConfirmationModal = ({
    isOpen, toggle, handleDelete, doNotShowAgain, setDoNotShowAgain,
}: DeleteRowConfirmationModalProps) => {
    const handleCheckboxChange = (value: boolean) => {
        setDoNotShowAgain?.(value);
        localStorage.setItem('proFormaTableDeleteConfirmation', value.toString());
    };

    return (
        <NSModal
            isOpen={isOpen}
            toggle={toggle}
            modalHeaderText="Delete row"
            modalHeaderClassName="text-white bg-danger"
            modalFooterButtonText="Yes, delete"
            modalFooterButtonColor="danger"
            modalFooterCancelButtonText="Cancel"
            modalFooterButtonFunction={handleDelete}
        >
            <div className="mb-1">
                <div className="text-white">Are you sure you want to delete this row?</div>
                <small>Your work will not be saved.</small>
            </div>

            <NSCheckbox
                labelClassName="text-white"
                id="doNotShowAgain"
                labelText="Do not show this warning again"
                checked={doNotShowAgain || false}
                callback={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckboxChange(e.target.checked)}
            />
        </NSModal>
    );
};

export default DeleteRowConfirmationModal;
