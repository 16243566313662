import { useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { IPipelineReportQueryParams, IPipelineReportDealsResponse } from '../types';

const getPipelineReportDeals = async ({
    pageSize,
    currentPage,
    orgId,
    selectedColumns,
    configuredFilters,
}: IPipelineReportQueryParams): Promise<IPipelineReportDealsResponse> => {
    const offset = (currentPage - 1) * pageSize;

    const response = await axiosInstance.get(`${API_ENDPOINTS.ORG_REPORTS(orgId)}/pipeline_report`, {
        params: {
            page: offset,
            page_size: pageSize,
            selected_columns: selectedColumns,
            configured_filters: JSON.stringify(configuredFilters),
        },
        paramsSerializer: params => {
            const queryString = Object.entries(params)
                .flatMap(([key, value]) => {
                    if (key === 'configured_filters' && typeof value === 'string') {
                        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
                    }
                    if (Array.isArray(value)) {
                        return value.map(v => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`);
                    }
                    if (value !== undefined && value !== null) {
                        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
                    }
                    return [];
                })
                .join('&');
            return queryString;
        },
    });

    return response.data;
};

export const useGetPipelineReportDeals = ({
    pageSize, currentPage, orgId, selectedColumns, configuredFilters,
}: IPipelineReportQueryParams) => useQuery({
    queryKey: ['getPipelineReportDeals', orgId, currentPage, pageSize, selectedColumns, configuredFilters],
    queryFn: () => getPipelineReportDeals({
        pageSize, currentPage, orgId, selectedColumns, configuredFilters,
    }),
    staleTime: 5000,
    refetchOnMount: true,
    enabled: selectedColumns.length > 0,
});
