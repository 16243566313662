import React from 'react';
import './EmptyState.scss';
import { NSCard } from 'bricks';
import classNames from 'classnames';

export type IProps = {
    title?: string;
    subtitle?: string;
    children?: React.ReactNode;
    className?: string;
};

const EmptyState = ({
    title, subtitle, children, className,
}: IProps) => (
    <NSCard className={classNames('emptyState d-flex flex-column justify-content-center align-items-center', className)}>
        <h3 className="emptyState__title mb-1 text-white">{title}</h3>
        <p className="emptyState__description mt-0 mb-2 text-muted">{subtitle}</p>
        {children}
    </NSCard>
);

export default EmptyState;
