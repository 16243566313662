import { useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { IComparisonReportData } from '../types';

interface IComparisonReportQueryParams {
    orgId: string;
    proFormaIds: number[];
}

const getComparisonReport = async ({ orgId, proFormaIds }: IComparisonReportQueryParams): Promise<IComparisonReportData[]> => {
    const response = await axiosInstance.get(`${API_ENDPOINTS.ORG_REPORTS(orgId)}/comparison_report`, {
        params: {
            // Send as repeated query parameters
            pro_forma_ids: proFormaIds,
        },
        paramsSerializer: params => Object.entries(params)
            .map(([key, value]) => (Array.isArray(value)
                ? value.map(v => `${key}=${encodeURIComponent(v)}`).join('&')
                : `${key}=${encodeURIComponent(value)}`))
            .join('&'),
    });
    return response.data;
};

export const useGetComparisonReport = ({ orgId, proFormaIds }: IComparisonReportQueryParams, options: { enabled: boolean }) => useQuery({
    queryKey: ['getComparisonReport', orgId, proFormaIds],
    queryFn: () => getComparisonReport({ orgId, proFormaIds }),
    staleTime: 5000,
    ...options,
});

export default useGetComparisonReport;
