import axiosInstance from 'ns_libs/nsAxios';
import { useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';

interface ISourceTypes {
    id: number;
    name: string;
    correlationId: string;
}

const getSourceTypes = async (): Promise<ISourceTypes[]> => {
    const response = await axiosInstance.get(API_ENDPOINTS.SOURCE_TYPES);
    return response.data;
};

const useGetSourceTypes = () => useQuery({
    queryKey: ['SourceTypes'],
    queryFn: () => getSourceTypes(),
});

export default useGetSourceTypes;
