import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS, { QUERY_KEYS } from 'services/urls';
import { AddonWithFeaturesSchema } from 'types/organization';

const fetchOrganizationAddons = async (orgCorrelationId: string): Promise<AddonWithFeaturesSchema[]> => {
    const { data } = await axiosInstance.get(API_ENDPOINTS.ORGANIZATION.ADD_ONS(orgCorrelationId));
    return data;
};

export const useGetOrganizationAddons = (orgCorrelationId: string) => useQuery({
    queryKey: [QUERY_KEYS.ORGANIZATION.ADD_ONS, orgCorrelationId],
    queryFn: () => fetchOrganizationAddons(orgCorrelationId),
    enabled: !!orgCorrelationId,
    staleTime: Infinity,
});
