import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS, { QUERY_KEYS } from 'services/urls';
import useAuthContext from 'contexts/AuthContext';
import { IDealAttribute } from '../types';

const getDealAttributes = async (
    orgId: string,
): Promise<IDealAttribute[]> => {
    const response = await axiosInstance.get<IDealAttribute[]>(
        API_ENDPOINTS.DEAL_ATTRIBUTES(orgId),
    );
    return response.data;
};

export const useGetAttributes = (
    options?: Omit<UseQueryOptions<IDealAttribute[]>, 'queryKey' | 'queryFn'>,
) => {
    const { selectedOrganizationId } = useAuthContext();
    return useQuery<IDealAttribute[], Error>({
        queryKey: [QUERY_KEYS.DEAL_ATTRIBUTES, selectedOrganizationId],
        queryFn: () => getDealAttributes(selectedOrganizationId!),
        enabled: Boolean(selectedOrganizationId),
        ...(options ?? {}),
    });
};
