import { NSButton, NSDropdown } from 'bricks';
import PageTitle from 'components/PageTitle/PageTitle';
import React, { useMemo, useState } from 'react';
import { IOptionAnyValue } from 'bricks/types';
import { downloadFileFromUrl } from 'helpers/file/download';
import NSInfoBanner from 'components/NSInfoBanner/NSInfoBanner';
import { IReport } from '../types';
import { ExportOptions } from '../constants';
import ReportTitle from './ReportTitle';
import UpdateReportTitleAndDescriptionModal from '../components/SaveReportModal/UpdateReportTitleAndDescriptionModal';
import { getReportPdf } from '../hooks/useGetReportPdf';

export interface IReportHeaderProps {
    reportId?: number;
    reportName: string;
    setReportName: (name: string) => void;
    reportDescription: string;
    setReportDescription: (description: string) => void;
    isEditing?: boolean;
    onClickBack: () => void;
    onClickExport: () => void;
    exportDisabled?: boolean;
    onClickSave: () => void;
    onClickCancel: () => void;
    saveDisabled?: boolean;
    onClickEdit: () => void;
    savedReports?: Pick<IReport, 'name'>[];
    onChangeSavedReport?: (report: Pick<IReport, 'name'>) => void;
    warningText?: string;
    exportOptions?: IOptionAnyValue[];
}

export const ReportHeader = ({
    reportId,
    reportName,
    setReportName,
    reportDescription,
    setReportDescription,
    exportDisabled = false,
    saveDisabled = false,
    isEditing = false,
    onClickBack,
    onClickExport,
    onClickEdit,
    onClickSave,
    onClickCancel,
    savedReports,
    onChangeSavedReport,
    warningText,
    exportOptions,
}: IReportHeaderProps) => {
    const [selectedSavedReport, setSelectedSavedReport] = useState<IOptionAnyValue>();
    const [showUpdateReportTitleAndDescriptionModal, setShowUpdateReportTitleAndDescriptionModal] = useState(false);

    const savedReportsOptions: IOptionAnyValue[] = useMemo(() => {
        if (savedReports) {
            return savedReports.map(report => ({
                label: report.name,
                value: report.name,
            }));
        }
        return [];
    }, [savedReports]);

    const handleChangeSavedReportSelection = (option: IOptionAnyValue) => {
        setSelectedSavedReport(option);
        onChangeSavedReport?.({ name: option.label });
    };

    const exportOptionsArray = useMemo(() => (
        exportOptions || [
            { label: ExportOptions.PDF, value: ExportOptions.PDF },
            { label: ExportOptions.EXCEL, value: ExportOptions.EXCEL },
        ]
    ), [exportOptions]);

    const handleUpdateReportNameAndDescription = (values: { title: string; description: string }) => {
        setReportName(values.title);
        setReportDescription(values.description);
        setShowUpdateReportTitleAndDescriptionModal(false);
    };

    const handlePdfExport = async () => {
        if (reportId) {
            try {
                const response = await getReportPdf(reportId);
                const pdfUrl = response.url;
                if (!pdfUrl) {
                    console.error('Failed to export report PDF: No URL returned');
                    return;
                }
                await downloadFileFromUrl(pdfUrl, reportName, 'pdf', true);
            } catch (error) {
                console.error('Failed to export report PDF:', error);
            }
        }
    };

    const handleSelectExportOption = (option: IOptionAnyValue) => {
        switch (option.value) {
            case ExportOptions.PDF:
                handlePdfExport();
                break;
            case ExportOptions.EXCEL:
                onClickExport();
                break;
            default:
                break;
        }
    };

    return (
        <PageTitle
            title={(
                <ReportTitle
                    reportName={reportName}
                    isEditing={isEditing}
                    setShowUpdateReportTitleAndDescriptionModal={setShowUpdateReportTitleAndDescriptionModal}
                />
            )}
            pageMetaDataTitle={reportName}
            subtitle={reportDescription}
            breadcrumbBackLinkText="Back"
            breadcrumbBackLinkCallback={onClickBack}
        >
            <div className="d-flex">
                {warningText && <NSInfoBanner title={warningText} className="NSCard__left-border--warning bg-warning-lighten mr-2 pr-2 mb-0 " warning />}
                {savedReports?.length && (
                    <NSDropdown
                        containerClassName="mr-2"
                        options={savedReportsOptions}
                        selectedOption={selectedSavedReport}
                        handleSelectOption={handleChangeSavedReportSelection}
                        placeholder="Select saved report"
                    />
                )}
                {!isEditing && (
                    <NSDropdown
                        containerClassName="mr-2"
                        options={exportOptionsArray}
                        customSelectedOptionText="Export"
                        handleSelectOption={handleSelectExportOption}
                        disabled={exportDisabled}
                    />
                )}
                {isEditing && reportId && (
                    <NSButton className="btn-secondary mr-2" callback={onClickCancel} text="Cancel" dataTestId="cancel-report-btn" />
                )}
                {isEditing ? (
                    <NSButton className="btn-primary" callback={onClickSave} text="Save report" dataTestId="save-report-btn" disabled={saveDisabled} />
                ) : (
                    <NSButton className="btn-secondary" callback={onClickEdit} text="Edit" />
                )}
            </div>
            <UpdateReportTitleAndDescriptionModal
                showModal={showUpdateReportTitleAndDescriptionModal}
                setShowModal={setShowUpdateReportTitleAndDescriptionModal}
                reportName={reportName}
                reportDescription={reportDescription}
                handleSaveReport={handleUpdateReportNameAndDescription}
            />
        </PageTitle>
    );
};

export default ReportHeader;
