/* eslint-disable operator-linebreak */
import React, { useMemo, useState } from 'react';
import { NSTable } from 'bricks';
import { numeralFormatterCurrency, percentFormatter } from 'ns_libs/formatter';
import { IOptionAnyValue } from 'bricks/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'reactstrap';
import { CashFlowPeriodData, ProFormaReturnsPeriodData, CostMetrics } from 'views/Reports/types';
import {
    AggregateReportProperties, Periods, PropertyKeyToLabelMap, spacerRows, CostMetricKeyToLabelMap, PRO_FORMA_RETURN_TOOLTIPS,
} from './constant';

export interface IProps {
    proFormaReturns: Record<string, ProFormaReturnsPeriodData>;
    cashFlowData: Record<string, CashFlowPeriodData>;
    selectedPeriod: IOptionAnyValue;
    costMetrics: CostMetrics;
}

export const ProFormaReturnsTable = ({
    selectedPeriod, proFormaReturns, cashFlowData, costMetrics,
}: IProps) => {
    const dateKeys = Object.keys(proFormaReturns.totalDevelopmentCost.periods);
    const [openTooltips, setOpenTooltips] = useState<Record<string, boolean>>({});
    const toggleTooltip = (tooltipId: string) => {
        setOpenTooltips(prev => ({ ...prev, [tooltipId]: !prev[tooltipId] }));
    };

    const periodLabel = useMemo(() => {
        if (selectedPeriod.value === Periods.MONTHLY) {
            return 'MONTH';
        }
        if (selectedPeriod.value === Periods.QUARTERLY) {
            return 'QUARTER';
        }
        if (selectedPeriod.value === Periods.ANNUALLY) {
            return 'YEAR';
        }
        return '';
    }, [selectedPeriod]);

    return (
        <div>
            <div className="NSTable--sticky-headers__table-container" style={{ maxHeight: 'none' }}>
                <NSTable className="NSTable NSTable--sticky-headers NSTable--sticky-headers--two-sticky-columns mb-1">
                    <thead>
                        <tr key="header-row">
                            <th className="align-middle text-white NSTable__thead__tr__th" key="property-header">Property</th>
                            <th className="align-middle text-white" key="amount-header">Amount</th>
                            {dateKeys.map((date, index) => (
                                <th key={`header-${date}`} className="NSTable__thead__tr text-nowrap text-white">
                                    <span className="text-muted font-weight-light h6">{`${periodLabel} ${index + 1}`}</span>
                                    <br />
                                    {date}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(proFormaReturns).map(prop => {
                            const propLabel = PropertyKeyToLabelMap[prop as keyof typeof PropertyKeyToLabelMap];
                            const tooltip = PRO_FORMA_RETURN_TOOLTIPS[propLabel as AggregateReportProperties];

                            return (
                                <React.Fragment key={`${prop}-fragment`}>
                                    <tr key={`${prop}-row`} className="NSTable__tbody__tr">
                                        <td className="text-white">
                                            {propLabel}
                                            {tooltip && (
                                                <>
                                                    <FontAwesomeIcon className="px-1 text-white" id={`${prop}-tooltip`} icon={faCircleInfo} />
                                                    <Tooltip
                                                        target={`${prop}-tooltip`}
                                                        isOpen={openTooltips[`${prop}-tooltip`] || false}
                                                        toggle={() => toggleTooltip(`${prop}-tooltip`)}
                                                        placement="top"
                                                        delay={{ show: 0, hide: 300 }}
                                                        style={{
                                                            textAlign: 'left',
                                                            minWidth: '250px',
                                                        }}
                                                    >
                                                        <span className="font-weight-bold">{propLabel}</span>
                                                        <div>{tooltip}</div>
                                                    </Tooltip>
                                                </>
                                            )}
                                        </td>
                                        <td className="text-white">{numeralFormatterCurrency(proFormaReturns[prop].amount)}</td>
                                        {Object.values(proFormaReturns[prop].periods).map((value: any) => (
                                            <td key={`pro-forma-returns-${prop}-${value}`} className="text-white">
                                                {numeralFormatterCurrency(value)}
                                            </td>
                                        ))}
                                    </tr>
                                    {spacerRows.includes(propLabel) && (
                                        <tr className="NSTable__spacer-row" key={`${prop}-spacer-row`}>
                                            <td colSpan={dateKeys.length + 4} className="pt-0 pb-1" />
                                        </tr>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </NSTable>
                <NSTable className="NSTable NSTable--sticky-headers--four-sticky-columns mb-1">
                    <thead>
                        <tr>
                            <th className="align-middle" key="property-label-header" />
                            <th className="align-middle text-white" key="equity-multiple-header">Equity multiple</th>
                            <th className="align-middle text-white" key="equity-irr">IRR</th>
                            <th className="align-middle text-white" key="equity-net-profit">Net profit</th>
                            {dateKeys.map(date => (
                                <th key={`header-${date}`} className="NSTable__thead__tr text-nowrap">
                                    {' '}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(cashFlowData).map(prop => {
                            const propLabel = PropertyKeyToLabelMap[prop as keyof typeof PropertyKeyToLabelMap];
                            return (
                                <tr key={`cash-flow-${prop}`} className="NSTable__tbody__tr text-end">
                                    <td
                                        className="text-white"
                                        key={`cash-flow-label-${prop}`}
                                    >
                                        {propLabel}
                                    </td>
                                    <td
                                        className="text-white"
                                        key={`cash-flow-equity-multiple-label-${prop}`}
                                    >
                                        {percentFormatter(cashFlowData[prop].equityMultiple)}
                                    </td>
                                    <td
                                        className="text-white"
                                        key={`cash-flow-irr-label-${prop}`}
                                    >
                                        {numeralFormatterCurrency(cashFlowData[prop].irr)}
                                    </td>
                                    <td
                                        className="text-white"
                                        key={`cash-flow-net-profit-${prop}`}
                                    >
                                        {numeralFormatterCurrency(cashFlowData[prop].netProfit)}
                                    </td>
                                    {Object.keys(cashFlowData[prop].periods).map(period => (
                                        <td key={`cash-flow-${period}-${cashFlowData[prop].periods[period]}`} className="text-white">
                                            {numeralFormatterCurrency(cashFlowData[prop].periods[period])}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </NSTable>
            </div>
            <NSTable className="NSTable text-dark">
                <thead className="NSTable__thead">
                    <tr className="NSTable__thead__tr">
                        <th className="NSTable__thead__tr__th align-middle" key="label-header" />
                        <th className="NSTable__thead__tr__th align-middle" key="untrended-header">Untrended</th>
                        <th className="NSTable__thead__tr__th align-middle" key="trended-header">Trended</th>
                        <th className="NSTable__thead__tr__th align-middle" key="sale-header">Sale</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(costMetrics).map(([metricName, metric]) => (
                        <tr key={`cost-metric-${metricName}`} className="NSTable__tbody__tr text-end">
                            <td
                                className="align-middle"
                                key={`${metricName}-label`}
                            >
                                {CostMetricKeyToLabelMap[metricName as keyof typeof CostMetricKeyToLabelMap]}
                            </td>
                            <td className="align-middle" key={`${metricName}-untrended`}>{percentFormatter(metric.untrended ? metric.untrended : 0)}</td>
                            <td className="align-middle" key={`${metricName}-trended`}>{percentFormatter(metric.trended ? metric.trended : 0)}</td>
                            <td className="align-middle" key={`${metricName}-sale`}>{percentFormatter(metric.sale ? metric.sale : 0)}</td>
                        </tr>
                    ))}
                </tbody>
            </NSTable>
        </div>
    );
};

export default ProFormaReturnsTable;
