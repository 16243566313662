import React, {
    Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { NSTable, NSButton, NSDropdown } from 'bricks';
import { PRO_FORMA_UNIT_TO_FIELD_VALUES, UNIT_TYPE_NAMES } from 'constants/unitTypes';
import { DEVELOPMENT_SOURCE_METRIC, PRO_FORMA_METRIC_TO_FIELD_VALUES, PRO_FORMA_SOURCE_METRIC_OPTIONS } from 'constants/sources';
import {
    DevelopmentUnitOfMeasureTypes, IDebtMetricOption, IProFormaSourceTableRow, ISourcesSummary, IUnitTypeOption,
} from 'views/ProFormaTable/types';
import { PRO_FORMA_DEVELOPMENT_UNIT_TYPE_KEY, PRO_FORMA_DEVELOPMENT_DEBT_METRIC_KEY, DISABLED_PLACEHOLDER_PERIOD } from 'views/ProFormaTable/constants';
import RenderIf from 'components/RenderIf/RenderIf';
import { numeralFormatterCurrency } from 'ns_libs/formatter';
import { isEmpty } from 'lodash';
import { IOptionAnyValue } from 'bricks/types';
import { generateDateRange } from 'views/ProFormaTable/helpers';
import AddSourceModal from './AddSourceModal/AddSourceModal';
import Source from './Source/Source';
import { useProFormaSocketContext } from '../../socketContext/ProFormaSocketProvider';
import PeriodCellValue from '../DevelopmentUsesTable/PeriodCellValue/PeriodCellValue';

export interface IDevelopmentSourcesTable {
    sources: ISourcesSummary;
    unitType: IUnitTypeOption;
    setUnitType: Dispatch<SetStateAction<IUnitTypeOption | null>>;
    unitTypeOptions: IUnitTypeOption[];
    hasWriteAccessToProForma: boolean;
}
const DevelopmentSourcesTable = ({
    sources,
    unitType,
    setUnitType,
    unitTypeOptions,
    hasWriteAccessToProForma,
}: IDevelopmentSourcesTable) => {
    const { proForma, handleCreateProFormaSource } = useProFormaSocketContext();
    const debtMetricLocalStorageData: DEVELOPMENT_SOURCE_METRIC = JSON.parse(localStorage.getItem(PRO_FORMA_DEVELOPMENT_DEBT_METRIC_KEY) ?? '{}');
    const existingDebtMetric = !isEmpty(debtMetricLocalStorageData)
        ? PRO_FORMA_SOURCE_METRIC_OPTIONS.filter(option => option.value === debtMetricLocalStorageData)[0]
        : PRO_FORMA_SOURCE_METRIC_OPTIONS[0];

    const [openAddSourceModal, setOpenAddSourceModal] = useState(false);
    const [isCreateProFormaSourcePending, setIsCreateProFormaSourcePending] = useState(false);
    const [debtMetric, setDebtMetric] = useState<IDebtMetricOption | null>(null);

    const toggleAddSourceModal = () => setOpenAddSourceModal(!openAddSourceModal);

    const createSourceCallback = (isSuccess: boolean = true) => {
        setIsCreateProFormaSourcePending(false);
        if (isSuccess) setOpenAddSourceModal(false);
    };

    const handleClickAddSource = (sourceTypeId: string, sourceName: string) => {
        setIsCreateProFormaSourcePending(true);

        const max = !sources.sources.length ? 0 : Math.max(...sources.sources.map(source => source.traunch || 0));

        handleCreateProFormaSource(
            {
                sourceTypeId,
                name: sourceName,
                traunch: max + 1,
                amount: '0',
            },
            createSourceCallback,
        );
    };

    const handleUpdateUnitType = (option: IUnitTypeOption) => {
        setUnitType(option);
        localStorage.setItem(PRO_FORMA_DEVELOPMENT_UNIT_TYPE_KEY, JSON.stringify(option.value));
    };

    const handleUpdateDebtMetric = (option: IDebtMetricOption) => {
        setDebtMetric(option);
        localStorage.setItem(PRO_FORMA_DEVELOPMENT_DEBT_METRIC_KEY, JSON.stringify(option.value));
    };

    const selectedMetric = debtMetric && PRO_FORMA_METRIC_TO_FIELD_VALUES[debtMetric?.value as DEVELOPMENT_SOURCE_METRIC];

    const selectedUnitTotal = sources.totals[PRO_FORMA_UNIT_TO_FIELD_VALUES[unitType.value as DevelopmentUnitOfMeasureTypes]];

    const unavailableUnitTypes: string[] = [];

    unitTypeOptions.map(option => {
        const costPerUnitTotal = sources.totals[PRO_FORMA_UNIT_TO_FIELD_VALUES[option.value as DevelopmentUnitOfMeasureTypes]];
        return costPerUnitTotal === null ? unavailableUnitTypes.push(option.value) : null;
    });

    const updatedUnitTypeOptions = unitTypeOptions.map(option => ({
        ...option,
        disabled: unavailableUnitTypes.includes(option.value),
    }));

    const periodsDateRange = proForma.startDate && proForma.saleDate ? [proForma.startDate, proForma.saleDate] : null;

    const placeholderPeriod = [{ label: 'MON YEAR', value: DISABLED_PLACEHOLDER_PERIOD }];
    const periodOptions = periodsDateRange !== null && sources.sources.length
        ? generateDateRange(periodsDateRange, 'MMM yyyy', true)
        : placeholderPeriod;

    useEffect(() => {
        if (!debtMetric && existingDebtMetric.value) {
            setDebtMetric(existingDebtMetric);
        }
    }, [debtMetric, existingDebtMetric]);

    return (
        <div className="d-flex m-3 NSTable--sticky-headers__table-container-with-scrollbar">
            <NSTable className="NSTable--sticky-headers NSTable--sticky-headers--full-width NSTable--sticky-headers--six-sticky-columns">
                <thead className="NSTable__thead">
                    <tr className="NSTable__thead__tr">
                        <th className="NSTable__thead__tr__th NSTable__thead__tr__th--right-border align-middle py-1">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center ml-1">
                                    <span className="ml-0">Name</span>
                                </div>
                                <NSButton
                                    className="px-2 text-muted cursor--pointer hover:text-dark"
                                    size="sm"
                                    iconOnly
                                    icon={faPlus}
                                    callback={toggleAddSourceModal}
                                />
                            </div>
                        </th>
                        <th className="NSTable__thead__tr__th align-middle py-1">
                            <div className="d-flex justify-content-end">
                                <NSDropdown
                                    options={PRO_FORMA_SOURCE_METRIC_OPTIONS}
                                    selectedOption={debtMetric as IOptionAnyValue}
                                    handleSelectOption={option => handleUpdateDebtMetric(option)}
                                    badgeColor="dark-lighten"
                                    isBadgeToggle
                                    menuRight
                                />
                            </div>
                        </th>
                        <th className="NSTable__thead__tr__th align-middle py-1">
                            <div className="d-flex justify-content-end">Fund. traunch</div>
                        </th>
                        <th className="NSTable__thead__tr__th align-middle py-1 px-1 text-nowrap">
                            <div className="d-flex justify-content-end">% of sources</div>
                        </th>
                        <th className="NSTable__thead__tr__th align-middle py-1 text-nowrap">
                            <div className="text-right">
                                <span className="mr-1">Cost per</span>
                                <NSDropdown
                                    options={updatedUnitTypeOptions}
                                    selectedOption={{ label: UNIT_TYPE_NAMES[unitType.value].abbreviated, value: unitType.value }}
                                    handleSelectOption={option => handleUpdateUnitType(option)}
                                    containerClassName="ReadOnlyWrapper--enable-pointer-events"
                                    badgeColor="dark-lighten"
                                    isBadgeToggle
                                    useBodyContainer
                                    menuRight
                                />
                            </div>
                        </th>
                        <th className="NSTable__thead__tr__th align-middle text-right text-nowrap py-1 NSTable__thead__tr__th--right-border">
                            Amount
                        </th>
                        {periodOptions?.map((period, index) => (
                            <th
                                key={period.value}
                                className="NSTable__thead__tr__th align-middle
                                NSTable__thead__tr__th--grey-bg NSTable__thead__tr__th--width-150 py-1"
                            >
                                <RenderIf isTrue={period.value === DISABLED_PLACEHOLDER_PERIOD}>
                                    <div className="h6 NSTable__thead__tr__th--grey-text
                                        NSTable__thead__tr__th--opacity-3 text-uppercase text-nowrap text-secondary text-right mb-0"
                                    >
                                        Month #
                                    </div>
                                    <div className="h6 text-uppercase text-right text-nowrap NSTable__thead__tr__th--opacity-3 mt-1">
                                        {period.label}
                                    </div>
                                </RenderIf>
                                <RenderIf isTrue={period.value !== DISABLED_PLACEHOLDER_PERIOD}>
                                    <div className="h6 NSTable__thead__tr__th--grey-text text-uppercase text-nowrap text-secondary text-right mb-0">
                                        Month
                                        {' '}
                                        {index}
                                    </div>
                                    <div className="h6 text-uppercase text-right text-nowrap mt-1">{period.label}</div>
                                </RenderIf>
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody className="NSTable__tbody">
                    <RenderIf isTrue={sources.sources.length}>
                        {sources.sources.map(source => (
                            <Source
                                key={source.id}
                                source={source}
                                totalSourcesAmount={sources.sources.length}
                                unitType={unitType.value as DevelopmentUnitOfMeasureTypes}
                                selectedMetric={selectedMetric as keyof IProFormaSourceTableRow}
                                hasWriteAccessToProForma={hasWriteAccessToProForma}
                                periodOptions={periodOptions}
                            />
                        ))}
                    </RenderIf>

                    <RenderIf isTrue={!sources.sources.length}>
                        <tr className="NSTable__tbody_tr NSTable__tbody__tr--level-1">
                            <td className="NSTable__tbody__tr__td align-middle" colSpan={6}>
                                <div className="text-center text-dark mt-5">Your added sources will appear here.</div>
                                <div className="text-center mb-5">
                                    Click the plus
                                    {' '}
                                    <span className="text-dark">[+]</span>
                                    {' '}
                                    symbol in the
                                    {' '}
                                    <span className="text-dark">Name</span>
                                    {' '}
                                    header to
                                    add your first source.
                                </div>
                            </td>
                            {periodOptions.map(period => (
                                <PeriodCellValue
                                    key={period.value}
                                    period={{ label: 'MON YEAR', value: DISABLED_PLACEHOLDER_PERIOD }}
                                    value="—"
                                    cellClassName="NSTable__tbody__tr__td text-dark text-right"
                                />
                            ))}
                        </tr>
                    </RenderIf>
                </tbody>

                <tfoot className="NSTable__tfoot">
                    <tr className="NSTable__tfoot__tr">
                        <td className="NSTable__tfoot__tr__td NSTable__tfoot__tr__td--right-border text-left NSTable__tfoot__tr__td--width-400">
                            <div className="ml-2">Total</div>
                        </td>

                        <td className="NSTable__tfoot__tr__td" />
                        <td className="NSTable__tfoot__tr__td" />
                        <td className="NSTable__tfoot__tr__td text-right">{sources.sources.length ? '100.00%' : '—'}</td>
                        <td className="NSTable__tfoot__tr__td text-right text-nowrap">
                            {selectedUnitTotal !== null ? numeralFormatterCurrency(selectedUnitTotal) : '—'}
                        </td>
                        <td className="NSTable__tfoot__tr__td text-right text-nowrap">
                            {sources.totals?.amount !== null ? numeralFormatterCurrency(sources.totals?.amount) : '—'}
                        </td>
                        {periodOptions.map(period => {
                            const targetPeriod = sources.periodsTotals?.find(p => p.periodName === period.label);

                            return (
                                <PeriodCellValue
                                    key={period.value}
                                    period={period}
                                    value={targetPeriod?.amountToFund !== null ? numeralFormatterCurrency(targetPeriod?.amountToFund) : '—'}
                                    cellClassName="NSTable__tfoot__tr__td text-dark text-right"
                                />
                            );
                        })}
                    </tr>
                </tfoot>
            </NSTable>

            <RenderIf isTrue={openAddSourceModal}>
                <AddSourceModal
                    toggle={toggleAddSourceModal}
                    isOpen={openAddSourceModal}
                    isLoading={isCreateProFormaSourcePending}
                    handleClickAddSource={handleClickAddSource}
                />
            </RenderIf>
        </div>
    );
};

export default DevelopmentSourcesTable;
