import { ISubprojectStage, ISubprojectStageCount } from '../types';

interface IStage {
    id: string;
    name: string;
    subprojects_count: number;
}

export const mapSubprojectStagesToCounts = (
    stages: ISubprojectStage[],
    counts: ISubprojectStageCount[],
): IStage[] => stages.map(stage => ({
    id: stage.correlationId,
    name: stage.name,
    subprojects_count: counts.find(count => count.subprojectStageId === stage.correlationId)?.dealCount || 0,
}));

// Create subproject_stage query string from selected stages
export const getSubprojectStageIds = (selectedStages: Record<string, boolean>) => {
    if (!selectedStages) return undefined;
    const selectedIds = Object.entries(selectedStages)
        .filter(([_, isSelected]) => isSelected)
        .map(([id]) => `subproject_stages=${id}`)
        .join('&');
    return selectedIds;
};
