import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { Formik, Form, FormikTouched } from 'formik';
import { ProFormaModelIds } from 'constants/proForma';
import { useProFormaWizard } from 'views/ProFormaWizard/context/ProFormaWizardProvider';
import { stepTwoValidationSchema } from 'views/ProFormaWizard/constants';
import { useParams } from 'react-router-dom';
import { numeralFormatter } from 'ns_libs/formatter';
import { useAuthContext } from 'contexts/AuthContext';
import Uses, { IUses } from './Uses/Uses';
import { ISource } from './Sources/Source/types';
import { sourcesInitialState } from './Sources/Source/constants';
import Sources from './Sources/Sources';
import { useGetProFormaWizardStep } from '../../hooks/useGetProFormaWizardStep';
import { useUpdateProFormaWizardStep } from '../../hooks/useUpdateProFormaWizardStep';

export type StepTwoValues = IUses & {
    sources: ISource[];
};

const ProFormaWizardStepTwo = () => {
    const { registerStepHandler, getStepData } = useProFormaWizard();
    const { proFormaId } = useParams<{ dealId: string; proFormaId: string }>();
    const { selectedOrganizationId } = useAuthContext();

    const { data: stepData, isLoading } = useGetProFormaWizardStep({
        orgId: selectedOrganizationId!,
        proFormaId: proFormaId || '',
        stepNumber: 2,
    });

    const stepOneData = getStepData(1);
    const dealUnits = stepOneData?.buildings || 0;
    const dealSF = stepOneData?.buildableGrossSquareFootage || 0;

    const updateStep = useUpdateProFormaWizardStep();

    const initialSources = useMemo(() => stepData?.sources || sourcesInitialState, [stepData]);

    const initialValues = useMemo(
        () => ({
            acquisitionCosts: Number(numeralFormatter(stepData?.acquisitionCosts ?? 0, '0.00')),
            hardCosts: Number(numeralFormatter(stepData?.hardCosts ?? 0, '0.00')),
            totalHardCosts: Number(numeralFormatter((stepData?.hardCosts ?? 0) * dealSF, '0.00')),
            hardCostType: stepData?.hardCostType ?? 'sf',
            softCosts: Number(numeralFormatter(stepData?.softCosts ?? 0, '0.00')),
            totalSoftCosts: Number(numeralFormatter((stepData?.softCosts ?? 0) * dealSF, '0.00')),
            softCostType: stepData?.softCostType ?? 'sf',
            developerFee: Number(numeralFormatter(stepData?.developerFee ?? 0, '0.00')),
            interestReserve: Number(numeralFormatter(stepData?.interestReserve ?? 0, '0.00')),
            sources: initialSources,
        }),
        [stepData, dealSF, initialSources],
    ) as StepTwoValues;

    // Asset type is hardcoded here – TODO: retrieve from API if needed.
    const [proFormaModelId] = useState<ProFormaModelIds>(ProFormaModelIds.APARTMENT);

    if (isLoading || !stepData) {
        return <div>Loading...</div>;
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={stepTwoValidationSchema}
            onSubmit={() => {}}
            validateOnChange={false}
            validateOnBlur={false}
            validateOnMount={false}
            enableReinitialize
        >
            {formik => {
                const {
                    values, setFieldValue, validateForm, errors, touched, setFieldTouched, setTouched,
                } = formik;

                const getData = useCallback(() => {
                    const data = { ...values };
                    return new Promise((resolve, reject) => {
                        updateStep.mutate({
                            orgId: selectedOrganizationId!,
                            proFormaId: proFormaId || '',
                            stepNumber: 2,
                            data,
                        }, {
                            onSuccess: result => resolve(result),
                            onError: error => reject(error),
                        });
                    });
                }, [values, proFormaId, updateStep, selectedOrganizationId]);

                useEffect(() => {
                    registerStepHandler(2, {
                        validate: async () => {
                            const validationErrors = await validateForm();
                            if (Object.keys(validationErrors).length > 0) {
                                const touchedFields = Object.keys(validationErrors).reduce<FormikTouched<StepTwoValues>>((acc, key) => {
                                    if (key === 'sources' && Array.isArray(validationErrors.sources)) {
                                        const sourcesTouched = validationErrors.sources.map(sourceError => {
                                            if (!sourceError) return {};
                                            return Object.keys(sourceError).reduce((sourceAcc, field) => ({
                                                ...sourceAcc,
                                                [field]: true,
                                            }), {});
                                        });
                                        acc.sources = sourcesTouched as FormikTouched<ISource>[];
                                    } else {
                                        (acc as any)[key] = true;
                                    }
                                    return acc;
                                }, {} as FormikTouched<StepTwoValues>);
                                setTouched(touchedFields);
                                return false;
                            }
                            return true;
                        },
                        getData,
                    });
                }, [registerStepHandler, validateForm, getData]);

                const handleInputChange = (key: string, value: any) => {
                    setFieldValue(key, value);
                    const newTouched: FormikTouched<StepTwoValues> = {
                        ...touched,
                        [key]: false,
                    };

                    if (key === 'hardCosts') {
                        newTouched.totalHardCosts = false;
                    } else if (key === 'totalHardCosts') {
                        newTouched.hardCosts = false;
                    } else if (key === 'softCosts') {
                        newTouched.totalSoftCosts = false;
                    } else if (key === 'totalSoftCosts') {
                        newTouched.softCosts = false;
                    }

                    setTouched(newTouched);
                };

                const handleSourcesChange = (newSources: ISource[]) => {
                    setFieldValue('sources', newSources);
                    const newTouched: FormikTouched<StepTwoValues> = { ...touched };
                    if (newTouched.sources) {
                        newTouched.sources = newSources.map(() => ({})) as FormikTouched<ISource>[];
                    }
                    setTouched(newTouched);
                };

                const handleSourcesBlur = (index: number, field: string) => {
                    setFieldTouched(`sources.${index}.${field}`, false, false);
                };

                const totalUses = values.acquisitionCosts + values.totalHardCosts + values.totalSoftCosts + values.interestReserve + values.developerFee;
                const totalSources = useMemo(() => values.sources.reduce((acc: number, curr: ISource) => acc + Number(curr.amount), 0), [values.sources]);

                return (
                    <Form>
                        <Uses
                            values={values}
                            onChange={handleInputChange}
                            onBlur={() => {}}
                            dealSF={dealSF}
                            dealUnits={dealUnits}
                            proFormaModelId={proFormaModelId}
                            totalSources={totalSources}
                            errors={errors}
                            touched={touched}
                        />
                        <Sources
                            sources={values.sources}
                            onChange={handleSourcesChange}
                            onBlur={handleSourcesBlur}
                            totalUses={totalUses}
                            totalSources={totalSources}
                            errors={errors.sources}
                            touched={touched.sources}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ProFormaWizardStepTwo;
