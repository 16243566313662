import React, { useCallback } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useGetDealById } from 'views/Deals/hooks/useGetDealById';
import useAuthContext from 'contexts/AuthContext';
import { NSButton, NSCard } from '../../bricks';
import PageTitle from '../../components/PageTitle/PageTitle';
import DocumentPreview from '../DocumentLibrary/components/DocumentLibrarySlideout/DocumentPreview';
import { URLS } from '../../services/urls';
import { DealType } from '../../constants/deals';
import RenderIf from '../../components/RenderIf/RenderIf';

const ProFormaUploaded = () => {
    const navigate = useNavigate();
    const { dealId, proFormaId } = useParams<{ dealId: string; proFormaId: string }>();
    const proFormaHomePath = generatePath(URLS.PRO_FORMA.HOME, { dealId });
    const { selectedOrganizationId } = useAuthContext();
    const { data: fetchedDeal } = useGetDealById({
        orgId: selectedOrganizationId!,
        dealId: Number(dealId),
        shouldFetch: !!Number(dealId),
    });

    // Mocked file data - in real implementation this would come from props or API
    const uploadedFile = {
        name: '09.23.2024_upload',
        size: 2444059, // size in bytes
        // eslint-disable-next-line max-len
        uri: 'https://nsi-prod-document-storage.s3.amazonaws.com/334/9c67753c-212a-4e30-852b-1dc8a94dfbc0/ACR_v1.xlsx', // This would be the actual file URL
        format: 'xlsx',
    };

    // TODO: Replace with actual data
    const returns = [
        { title: 'UNLEVERED IRR', value: '6.18%' },
        { title: 'GENERAL PARTNER IRR', value: '24.45%' },
        { title: 'UNTRENDED YIELD-ON-COST', value: '6.18%' },
        { title: 'LEVERED IRR', value: '6.18%' },
        { title: 'LIMITED PARTNER IRR', value: '24.45%' },
        { title: 'TRENDED YIELD-ON-COST', value: '6.18%' },
    ];

    const handleOpenDocument = useCallback(() => {
        window.open(uploadedFile.uri, '_blank');
    }, [uploadedFile.uri]);

    const handleEditMapping = useCallback(() => {
        const path = generatePath(URLS.PRO_FORMA.UPLOAD, { dealId, proFormaId });
        navigate(path);
    }, [dealId, proFormaId, navigate]);

    return (
        <div className="h-100 d-flex flex-column">
            <PageTitle
                title={uploadedFile.name}
                breadcrumbBackLinkText="Back"
                breadcrumbBackLinkCallback={() => navigate(proFormaHomePath)}
                containerClassName="mb-4"
            >
                <NSButton color="primary" text="Edit mapping" callback={handleEditMapping} />
            </PageTitle>

            <div className="d-flex flex-grow-1">
                <div className="w-50 mr-4">
                    <NSCard>
                        <DocumentPreview
                            uri={uploadedFile.uri}
                            format={uploadedFile.format}
                            handleOpenDocument={handleOpenDocument}
                            minHeight={800}
                        />
                    </NSCard>
                </div>

                <div className="w-50 d-flex flex-column">
                    <div className="mb-2">
                        <div className="text-muted mb-1">RETURNS</div>
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '8px' }}>
                            {returns.map(item => (
                                <div key={item.title} className="bg-secondary p-2 rounded" style={{ minWidth: 0 }}>
                                    <div className="text-muted text-truncate w-100">{item.title}</div>
                                    <h3 className="text-white text-truncate m-0 w-100">{item.value}</h3>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="separator mb-3" />
                    <div className="text-muted mb-1">OPERATIONS</div>
                    <RenderIf isTrue={fetchedDeal?.dealType?.name === DealType.Development}>
                        <NSCard>
                            <div className="p-2">
                                <div className="d-flex justify-content-between">
                                    <span className="text-muted pb-1">Effective gross revenue</span>
                                    <span className="text-white">$2,444,059</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className="text-muted">Total operating expenses</span>
                                    <span className="text-white">$2,444,059</span>
                                </div>
                                <div className="separator my-2" />
                                <div className="d-flex justify-content-between">
                                    <span className="text-muted pb-1 font-weight-bold">Net operating income</span>
                                    <span className="text-white">$2,444,059</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className="text-muted">Capital expenditures</span>
                                    <span className="text-white">$2,444,059</span>
                                </div>
                                <div className="separator my-2" />
                                <div className="d-flex justify-content-between font-weight-bold">
                                    <span className="text-muted">Cash flow from operations</span>
                                    <span className="text-white">$2,444,059</span>
                                </div>
                            </div>
                        </NSCard>
                        <div className="text-muted mb-1">USES</div>
                        <NSCard>
                            <div className="p-2">
                                <div className="d-flex justify-content-between">
                                    <span className="text-muted">Acquisition costs</span>
                                    <span className="text-white">$2,444,059</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className="text-muted">Hard costs</span>
                                    <span className="text-white">$2,444,059</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className="text-muted">Soft costs</span>
                                    <span className="text-white">$2,444,059</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className="text-muted">Financing costs</span>
                                    <span className="text-white">$2,444,059</span>
                                </div>
                                <div className="separator my-2" />
                                <div className="d-flex justify-content-between font-weight-bold">
                                    <span>Total uses</span>
                                    <span className="text-white">$2,444,059</span>
                                </div>
                            </div>
                        </NSCard>
                        <div className="text-muted mb-1">SOURCES</div>
                        <NSCard>
                            <div className="p-2">
                                <div className="d-flex justify-content-between">
                                    <span className="text-muted">Construction loan</span>
                                    <span className="text-white">$2,444,059</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className="text-muted">Mezzanine loan</span>
                                    <span className="text-white">$2,444,059</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className="text-muted">Limited partner</span>
                                    <span className="text-white">$2,444,059</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className="text-muted">General partner</span>
                                    <span className="text-white">$2,444,059</span>
                                </div>
                                <div className="separator my-2" />
                                <div className="d-flex justify-content-between font-weight-bold">
                                    <span>Total sources</span>
                                    <span className="text-white">$2,444,059</span>
                                </div>
                            </div>
                        </NSCard>
                    </RenderIf>
                    <RenderIf isTrue={fetchedDeal?.dealType?.name === DealType.Acquisition}>
                        <NSCard>
                            <div className="p-2">
                                <div className="d-flex justify-content-between">
                                    <span className="text-muted pb-1">Effective gross revenue</span>
                                    <span className="text-white">$2,444,059</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className="text-muted">Total operating expenses</span>
                                    <span className="text-white">$2,444,059</span>
                                </div>
                                <div className="separator my-2" />
                                <div className="d-flex justify-content-between font-weight-bold">
                                    <span className="text-muted pb-1">Net operating income</span>
                                    <span className="text-white">$2,444,059</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className="text-muted">Capital expenditures</span>
                                    <span className="text-white">$2,444,059</span>
                                </div>
                                <div className="separator my-2" />
                                <div className="d-flex justify-content-between font-weight-bold">
                                    <span className="text-muted">Cash flow from operations</span>
                                    <span className="text-white">$2,444,059</span>
                                </div>
                            </div>
                        </NSCard>
                        <div className="text-muted mb-1">ACQUISITION</div>
                        <NSCard>
                            <div className="p-2 mb-0">
                                <div className="d-flex justify-content-between">
                                    <span className="text-muted">Acquisition costs</span>
                                    <span className="text-white">$2,444,059</span>
                                </div>
                            </div>
                        </NSCard>
                    </RenderIf>
                </div>
            </div>
        </div>
    );
};

export default ProFormaUploaded;
