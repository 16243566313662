import { NSInput, NSLabel, NSModal } from 'bricks';
import { format } from 'date-fns';
import React, { useState } from 'react';

export interface INewProjectActualsSnapshotModalProps {
    isOpen: boolean;
    toggle: () => void;
    handleCreateSnapshot: (name: string) => void;
    isLoadingCallback: boolean;
    forecastMetric: string;
}

const NewProjectActualsSnapshotModal = ({
    isOpen, toggle, handleCreateSnapshot, isLoadingCallback, forecastMetric,
}: INewProjectActualsSnapshotModalProps) => {
    const today = new Date();
    const formattedDate = format(today, 'M.d.yyyy');
    const defaultName = `New snapshot_${formattedDate}`;
    const defaultMessageDate = format(today, "MMMM d yyyy 'at' h:mma");

    const [name, setName] = useState<string>(defaultName);

    return (
        <NSModal
            isOpen={isOpen}
            toggle={toggle}
            modalHeaderText="New snapshot"
            modalHeaderClassName="text-dark bg-primary"
            modalFooterButtonText="Create snapshot"
            modalFooterButtonFunction={() => handleCreateSnapshot(name)}
            modalFooterButtonIsLoading={isLoadingCallback}
            modalFooterIsButtonFunctionDisabled={name.trim().length === 0}
        >
            <NSLabel>Snapshot name</NSLabel>
            <NSInput
                id="spapshotName"
                type="text"
                name="spapshotName"
                placeholder="Enter snapshot name"
                value={name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
                required
            />

            <div className="text-dark mt-2">
                {`This will create a snapshot based on your ${forecastMetric} for ${defaultMessageDate}.`}
            </div>
        </NSModal>
    );
};

export default NewProjectActualsSnapshotModal;
