import React, { useMemo } from 'react';
import { NSDropdown } from 'bricks';
import { useGetReportBuilderTemplates } from 'views/ReportBuilder/hooks';
import { IOptionAnyValue } from 'bricks/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { ActiveSections, IReportBuilderReport } from 'views/ReportBuilder/constants';
import { useParams } from 'react-router-dom';

export interface ISelectSavedTemplateFormProps {
    setActiveSection: React.Dispatch<React.SetStateAction<string>>;
    selectedTemplate: IOptionAnyValue | undefined;
    setSelectedTemplate: React.Dispatch<React.SetStateAction<IOptionAnyValue | undefined>>;
    setNewReports: React.Dispatch<React.SetStateAction<IReportBuilderReport[]>>;
}

const SelectSavedTemplateForm = ({
    setActiveSection, selectedTemplate, setSelectedTemplate, setNewReports,
}: ISelectSavedTemplateFormProps) => {
    const { dealId: dealIdString } = useParams<{ dealId: string; proFormaId: string }>();
    const dealId = Number(dealIdString);

    const { data: savedReportTemplates } = useGetReportBuilderTemplates(dealId);

    const savedReportTemplateOptions: IOptionAnyValue[] = useMemo(() => {
        if (savedReportTemplates) {
            return savedReportTemplates.map(reportTemplate => ({
                label: reportTemplate.reportName,
                value: reportTemplate.id,
            }));
        }
        return [];
    }, [savedReportTemplates]);

    const handleTemplateSelection = (selectedOption: IOptionAnyValue) => {
        setSelectedTemplate(selectedOption);
        const selectedTemplateReports = savedReportTemplates?.find(template => template.id === selectedOption.value)?.reportBuilderReports;
        setNewReports(
            selectedTemplateReports?.map((report: any) => ({
                ...report,
                savedReportId: report.metaData.savedReportId,
                savedReportName: report.metaData.savedReportName,
                savedReportType: report.metaData.savedReportType,
                selectedBOEScenarioIds: report.metaData.boeScenarioIds,
                selectedBOEScenarioNames: report.metaData.boeScenarioNames,
                selectedProFormaId: report.metaData.proFormaId,
                selectedProFormaName: report.metaData.proFormaName,
                selectedDealAttributes: report.metaData.dealAttributes,
                includePrintedOnDate: report.metaData.includePrintedOnDate,
                includeTargetReturns: report.metaData.includeTargetReturns,
            })) || [],
        );
    };

    const setToInitialState = () => {
        setActiveSection(ActiveSections.INITIAL);
        setSelectedTemplate(undefined);
    };

    return (
        <>
            <div className="d-flex align-items-center mb-1">
                <h4 role="presentation" className="text-primary m-0 p-0" style={{ cursor: 'pointer' }} onClick={() => setToInitialState()}>
                    Create
                </h4>
                <FontAwesomeIcon icon={faAngleRight} className="mx-1" size="xs" />
                <h4 className="text-white m-0 p-0">Select saved template</h4>
            </div>
            <p className="m-0 mt-1 p-0">Choose one of your saved templates for your next report</p>
            <hr />
            <p className="text-white mb-1">Saved templates</p>
            <NSDropdown
                containerClassName="mt-1 pr-3"
                options={savedReportTemplateOptions}
                selectedOption={selectedTemplate}
                handleSelectOption={handleTemplateSelection}
                placeholder="Select..."
                isFullWidth
            />
        </>
    );
};

export default SelectSavedTemplateForm;
