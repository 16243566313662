import { NSDropdown, NSInput } from 'bricks';
import { IOptionAnyValue } from 'bricks/types';
import React, { useCallback, useMemo, useState } from 'react';
import './DealScenarioDropdown.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { useAuthContext } from 'contexts/AuthContext';
import { useGetBackOfEnvelopeScenarios } from 'views/DealDashboard/BOE/hooks/useGetBOEScenarios';
import { INestedOptionAnyValue, SimplifiedScenario } from './types';
import {
    BackOption, DealScenariosOption, createNewScenarioOption, UncategorizedScenariosOption,
} from './constants';
import { filterOptions } from './helpers/searchFilter';
import { transformScenarios } from './helpers/buildScenarios';

interface IDealScenarioDropdown {
    scenarios: SimplifiedScenario[];
    preSelectScenario?: IOptionAnyValue;
    includeCreate?: boolean;
    includeSearch?: boolean;
    includeLabels?: boolean;
    cleanOnSelect?: boolean;
    handleCreateScenario: (option: IOptionAnyValue) => void;
    handleSelectScenario: (option: IOptionAnyValue) => void;
    setSelectedScenarioId?: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const DealScenarioDropdown = ({
    scenarios,
    handleCreateScenario,
    includeCreate,
    handleSelectScenario,
    preSelectScenario,
    cleanOnSelect,
    includeLabels,
    includeSearch,
    setSelectedScenarioId,
}: IDealScenarioDropdown) => {
    const { selectedOrganizationId } = useAuthContext();

    const [dropdownSelectedScenario, setDropdownSelectedScenario] = useState<IOptionAnyValue | undefined>(preSelectScenario);
    const [breadcrumbs, setBreadcrumbs] = useState<INestedOptionAnyValue[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');
    const [selectedDealId, setSelectedDealId] = useState<number | undefined>(undefined);
    const [selectedDealName, setSelectedDealName] = useState<string | undefined>();

    const { refetch } = useGetBackOfEnvelopeScenarios({
        orgId: selectedOrganizationId!,
        dealId: selectedDealId,
    });

    const { dealScenarios, uncategorizedScenarios } = transformScenarios(scenarios);

    const mainOptions: INestedOptionAnyValue[] = useMemo(
        () => [
            ...(includeLabels && dealScenarios.length > 0 ? [DealScenariosOption] : []),
            ...dealScenarios,
            ...(includeLabels && uncategorizedScenarios.length > 0 ? [UncategorizedScenariosOption] : []),
            ...uncategorizedScenarios,
        ],
        [dealScenarios, uncategorizedScenarios],
    );

    const [currentOptions, setCurrentOptions] = useState<INestedOptionAnyValue[]>(mainOptions);

    const handleGoBack = () => {
        if (breadcrumbs.length > 0) {
            const newBreadcrumbs = [...breadcrumbs];
            newBreadcrumbs.pop();
            setBreadcrumbs(newBreadcrumbs);
            setCurrentOptions(newBreadcrumbs.length > 0 ? newBreadcrumbs[newBreadcrumbs.length - 1].submenu! : mainOptions);
            // Reset deal info when going back
            setSelectedDealId(undefined);
            setSelectedDealName(undefined);
        }
    };

    const handleSelectOption = (option: IOptionAnyValue) => {
        if (option.value === 'new') {
            handleCreateScenario(option);
        } else if (option.value === 'back') {
            handleGoBack();
        } else if (option.value === 'search') {
            // NOOP;
        } else if (!cleanOnSelect) {
            if (setSelectedScenarioId) {
                setSelectedScenarioId(option.value);
            }
            setDropdownSelectedScenario(option);
        }
    };

    const handleOptionSelect = async (option: INestedOptionAnyValue) => {
        if (option.submenu) {
            if (option.id) {
                // Update state to trigger refetch
                setSelectedDealId(option.id);
                setSelectedDealName(option.label);

                const { data } = await refetch();

                // Update currentOptions with fetched scenarios
                if (data?.items) {
                    const scenarios = data.items.map(scenario => ({
                        id: Number(scenario.id),
                        value: scenario.name,
                        label: scenario.name,
                    }));
                    setCurrentOptions(scenarios); // Update options with fetched scenarios
                }
            }

            // Update breadcrumbs
            setBreadcrumbs(prev => [...prev, option]);
        } else {
            handleSelectOption(option);
            setSearchValue('');
            setCurrentOptions(mainOptions);
            handleSelectScenario(option);
        }
    };

    const handleSearchTerm = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchValue(event.target.value);
            const filteredOptions = filterOptions(mainOptions, event.target.value);
            setCurrentOptions(filteredOptions);
        },
        [mainOptions],
    );

    const searchOption = {
        label: 'SEARCH',
        value: 'search',
        disabled: false,
        toggle: false,
        submenu: undefined,
        component: (
            <NSInput
                type="search"
                name="search-bar"
                placeholder="Search"
                value={searchValue}
                onChange={handleSearchTerm}
                customClassName="bg-secondary border-0 px-0 w-100"
                inputGroupClassName="bg-secondary border-0"
                prependInputAddon={<FontAwesomeIcon className="text-muted" icon={faMagnifyingGlass} />}
            />
        ),
    };

    const search = includeSearch ? [searchOption] : [];

    const dropdownOptions = useMemo(
        () => [
            ...(breadcrumbs.length > 0 ? [BackOption] : search),
            ...currentOptions,
        ],
        [currentOptions, breadcrumbs, searchValue],
    );

    return (
        <div className="DealScenarioDropdown">
            <NSDropdown
                isFullWidth
                options={dropdownOptions}
                selectedOption={dropdownSelectedScenario}
                handleSelectOption={handleOptionSelect}
                placeholder="Select scenario"
                optionClassName="dropdown-item"
                fixedBottomContent={
                    includeCreate
                        ? createNewScenarioOption(
                            () => handleCreateScenario({
                                value: 'new',
                                label: '+ New scenario',
                                ...(selectedDealId ? {
                                    dealId: selectedDealId,
                                    dealName: selectedDealName,
                                } : {}),
                            }),
                            selectedDealName,
                        ).component
                        : undefined
                }
            />
        </div>
    );
};

export default DealScenarioDropdown;
