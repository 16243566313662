import { stringify } from 'qs';
import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS, { QUERY_KEYS } from 'services/urls';
import { IDealDirectory } from '../types';

interface IDealDirectoryTreeQueryParams {
    dealId: number;
    onlyDir?: boolean; // Optional, defaults to false if not provided
    options?: { enabled?: boolean };
}

const getDealDirectoryTree = async ({ dealId, onlyDir = false }: IDealDirectoryTreeQueryParams): Promise<IDealDirectory> => {
    const response = await axiosInstance.get(`${API_ENDPOINTS.DEAL_DIRECTORY.TREE(dealId)}`, {
        params: { only_dir: onlyDir },
        paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' }),
    });
    return response.data;
};

export const useGetDealDirectoryTree = ({ dealId, onlyDir = false, options = {} }: IDealDirectoryTreeQueryParams) => useQuery({
    queryKey: [QUERY_KEYS.DEAL_DIRECTORY_TREE, dealId, onlyDir],
    queryFn: () => getDealDirectoryTree({ dealId, onlyDir }),
    refetchOnMount: true,
    ...options,
});

export default useGetDealDirectoryTree;
