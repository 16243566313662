/* eslint-disable no-unused-vars */

import React from 'react';
import { formatDate, formatUSDCurrency } from 'ns_libs/formatter';
import { URLS } from 'services/urls';
import { generatePath, Link } from 'react-router-dom';
import { Deal, IOptionStringValue } from './types';

export enum TABLE_KEYS {
    all = 'all',
    // General
    deal = 'dealName', // name
    address = 'address',
    city = 'city',
    county = 'county',
    state = 'state',
    zipCode = 'zipCode',
    market = 'market',
    submarket = 'subMarket',
    dateCreated = 'createdAt',
    description = 'description',

    // Deal info
    dealType = 'dealType.name',
    assetType = 'assetType.name',
    constructionType = 'constructionType.name',
    parkingType = 'parkingType',
    units = 'units', // ???
    subprojectStage = 'subprojectStage.name',
    leadSource = 'leadSource.name',
    leasingStrategy = 'leasingStrategy.name',
    rank = 'rank.name',

    // Deal metrics
    acquisitionPrice = 'acquisitionPrice',
    targetROC = 'targetReturnOnCost',
    targetROE = 'targetReturnOnEquity',
    targetPR = 'targetProfitReturn',
    irr = 'irr',
    equityMultiple = 'equityMultiple ',

    // Personnel
    createdBy = 'creatorName', // deal creator
    dealOwner = 'dealOwner.name',
    developmentManager = 'developmentManager.name',

    // Capital partners
    investor = 'investors.0.name',
    lender = 'lenders.0.name',

    // Vendors
    legalServices = 'legalServices.0.name',
    projectArchitect = 'projectArchitects.0.name',
    surveyor = 'surveyors.0.name',
    environmentalConsultant = 'environmentalConsultants.0.name',
}

export const COLUMN_OPTIONS: Array<
    IOptionStringValue & {
        sortable?: boolean;
        cellRenderer?: (row: Deal) => JSX.Element;
    }
> = [
    // General
    {
        label: 'Deal',
        value: TABLE_KEYS.deal,
        sortable: true,
        cellRenderer: row => <Link to={generatePath(URLS.DEAL_DASHBOARD.HOME, { dealId: row.id || '' })}>{row.dealName}</Link>,
    },
    { label: 'Address', value: TABLE_KEYS.address, sortable: true },
    { label: 'City', value: TABLE_KEYS.city, sortable: true },
    { label: 'County', value: TABLE_KEYS.county, sortable: true },
    { label: 'State', value: TABLE_KEYS.state, sortable: true },
    { label: 'Zip code', value: TABLE_KEYS.zipCode, sortable: true },
    { label: 'Market', value: TABLE_KEYS.market, sortable: true },
    { label: 'Submarket', value: TABLE_KEYS.submarket, sortable: true },
    {
        label: 'Date created', value: TABLE_KEYS.dateCreated, sortable: true, cellRenderer: row => <span>{formatDate(row.createdAt)}</span>,
    },
    { label: 'Description', value: TABLE_KEYS.description, sortable: true },

    // Deal info
    { label: 'Deal type', value: TABLE_KEYS.dealType, sortable: true },
    { label: 'Asset type', value: TABLE_KEYS.assetType, sortable: true },
    {
        label: 'Construction type',
        value: TABLE_KEYS.constructionType,
        sortable: true,
    },
    { label: 'Parking type', value: TABLE_KEYS.parkingType, sortable: true },
    { label: 'Units', value: TABLE_KEYS.units, sortable: true },
    { label: 'Phase', value: TABLE_KEYS.subprojectStage, sortable: true },
    { label: 'Lead source', value: TABLE_KEYS.leadSource, sortable: true },
    {
        label: 'Leasing strategy',
        value: TABLE_KEYS.leasingStrategy,
        sortable: true,
    },
    { label: 'Rank', value: TABLE_KEYS.rank, sortable: true },

    // Personnel
    { label: 'Created by', value: TABLE_KEYS.createdBy, sortable: true },
    { label: 'Deal owner', value: TABLE_KEYS.dealOwner, sortable: true },
    {
        label: 'Development manager',
        value: TABLE_KEYS.developmentManager,
        sortable: true,
    },
    // Deal metrics
    {
        label: 'Acquisition price',
        value: TABLE_KEYS.acquisitionPrice,
        sortable: true,
        cellRenderer: row => <div style={{ textAlign: 'right' }}>{formatUSDCurrency(row.acquisitionPrice)}</div>,
    },
    { label: 'Target return on cost', value: TABLE_KEYS.targetROC, sortable: true },
    { label: 'Target return on equity', value: TABLE_KEYS.targetROE, sortable: true },
    { label: 'Target profit return', value: TABLE_KEYS.targetPR, sortable: true },
    { label: 'IRR', value: TABLE_KEYS.irr, sortable: true },
    {
        label: 'Equity multiple',
        value: TABLE_KEYS.equityMultiple,
        sortable: true,
    },

    // Capital partners
    { label: 'Investor', value: TABLE_KEYS.investor, sortable: true },
    { label: 'Lender', value: TABLE_KEYS.lender, sortable: true },

    // Vendors
    { label: 'Legal services', value: TABLE_KEYS.legalServices, sortable: true },
    {
        label: 'Project architect',
        value: TABLE_KEYS.projectArchitect,
        sortable: true,
    },
    { label: 'Surveyor', value: TABLE_KEYS.surveyor, sortable: true },
    {
        label: 'Environmental consultant',
        value: TABLE_KEYS.environmentalConsultant,
        sortable: true,
    },
];

const INITIAL_VISIBLE_COLUMNS: Partial<Record<TABLE_KEYS, boolean>> = {
    [TABLE_KEYS.deal]: true,
    [TABLE_KEYS.address]: true,
    [TABLE_KEYS.dateCreated]: true,
    [TABLE_KEYS.acquisitionPrice]: true,
    [TABLE_KEYS.createdBy]: true,
};

export const getInitialColumnVisibility = (): Record<string, boolean> => {
    const defaultVisibility: Record<string, boolean> = {};

    COLUMN_OPTIONS.forEach(column => {
        defaultVisibility[column.value] = INITIAL_VISIBLE_COLUMNS[column.value as TABLE_KEYS] ?? false;
    });

    return defaultVisibility;
};
