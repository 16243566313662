import React, { useEffect, useState } from 'react';
import { NSInput, NSModal } from '../../../../../bricks';
import { useProFormaSocketContext } from '../socketContext/ProFormaSocketProvider';

interface IEditProFormaModalProps {
    showEditProFormaModal: boolean;
    toggleEditProFormaModal: () => void;
}

const EditProFormaModal = ({ showEditProFormaModal, toggleEditProFormaModal }: IEditProFormaModalProps) => {
    const { proForma, handleUpdateProForma } = useProFormaSocketContext();
    const [name, setName] = useState<string>(proForma?.name || '');
    const [description, setDescription] = useState<string>(proForma?.description || '');

    const handleSave = () => {
        handleUpdateProForma(
            [
                { key: 'name', value: name },
                { key: 'description', value: description },
            ],
            toggleEditProFormaModal,
        );
    };

    useEffect(() => {
        setName(proForma?.name || '');
        setDescription(proForma?.description || '');
    }, [proForma]);

    return (
        <NSModal
            isOpen={showEditProFormaModal}
            toggle={toggleEditProFormaModal}
            modalHeaderText="Edit pro forma"
            modalHeaderClassName="text-white bg-primary"
            modalFooterButtonText="Save"
            modalFooterButtonFunction={handleSave}
        >
            <NSInput
                label="Name"
                type="text"
                name="name"
                placeholder="Enter pro forma name"
                labelClassName="mb-0"
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                required
            />
            <NSInput
                label="Description"
                type="textarea"
                name="description"
                placeholder="Enter optional description for this pro forma"
                labelClassName="mb-0 mt-2"
                value={description}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value)}
            />
        </NSModal>
    );
};

export default EditProFormaModal;
