import { IOptionAnyValue } from 'bricks/types';
import { ProFormaGenericColumn } from 'views/ProFormaTable/components/ProFormaGenericTable/types';

export const CALCULATION_OPTIONS: IOptionAnyValue[] = [
    { value: 'operating_expenses', label: 'Operating expenses' },
    { value: 'total_potential_income', label: 'Total potential income' },
    { value: 'effective_gross_revenue', label: 'Effective gross revenue' },
    { value: 'net_rental_income', label: 'Net rental income' },
    { value: 'net_operating_income', label: 'Net operating income' },
];

export const OPERATING_EXPENSES_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'annualGrowth',
        field: 'annualGrowth',
        header: 'Annual growth',
        format: 'percentage',
        isFixed: true,
    },
    {
        key: 'effectiveGrossRevenue',
        field: 'effectiveGrossRevenue',
        header: '% of effective gross revenue',
        format: 'percentage',
        isFixed: true,
    },
    {
        key: 'amountUnitYear',
        field: 'amountUnitYear',
        header: '$/Unit/year',
        format: 'currency',
        isFixed: true,
    },
    {
        key: 'amountByYear',
        field: 'amountByYear',
        header: 'Amount/year',
        format: 'currency',
        isFixed: true,
    },
];

export const NET_OPERATION_INCOME_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'title',
        field: 'title',
        header: 'Net operating income',
        format: 'none',
        isReadOnly: true,
        isFixed: true,
    },
    {
        key: 'annualPayment',
        field: 'annualPayment',
        header: '',
        format: 'none',
        isReadOnly: true,
        isFixed: true,
    },
];

export const CAPITAL_EXPENDITURES_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'title',
        field: 'title',
        header: 'Capital expenditures',
        format: 'none',
        isFixed: true,
    },
    {
        key: 'annualGrowth',
        field: 'annualGrowth',
        header: 'Annual growth',
        format: 'percentage',
        isFixed: true,
    },
    {
        key: 'effectiveGrossRevenue',
        field: 'effectiveGrossRevenue',
        header: '% of effective gross revenue',
        format: 'percentage',
        isFixed: true,
    },
    {
        key: 'amountUnitYear',
        field: 'amountUnitYear',
        header: '$/Unit/year',
        format: 'currency',
        isFixed: true,
    },
    {
        key: 'amountByYear',
        field: 'amountByYear',
        header: 'Amount/year',
        format: 'currency',
        isFixed: true,
    },
];

export const CASH_FLOW_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'title',
        field: 'title',
        header: 'Cash flow from operations',
        format: 'none',
        isReadOnly: true,
        isFixed: true,
    },
    {
        key: 'amountByYear',
        field: 'amountByYear',
        header: '$4,310,61',
        format: 'none',
        isReadOnly: true,
        isFixed: true,
    },
];

export const formatDateWithMonthAndYear = (date: Date) => date.toLocaleString('en-US', { month: 'short', year: 'numeric' });
