import React from 'react';
import { FormGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { NSInput } from 'bricks';

export interface IProps {
    id?: string;
    containerClassName?: string;
    placeholder?: string;
    searchValue: string;
    handleChangeSearchValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

const NSSearchBar = ({
    id, containerClassName, placeholder, searchValue, handleChangeSearchValue, disabled,
}: IProps) => (
    <FormGroup id={id} className={`NSSearchBar ${containerClassName}`}>
        <NSInput
            type="search"
            name="search-bar"
            value={searchValue}
            onChange={handleChangeSearchValue}
            placeholder={placeholder || 'Search...'}
            prependInputAddon={<FontAwesomeIcon className="text-white" icon={faMagnifyingGlass} />}
            disabled={disabled}
        />
    </FormGroup>
);

export default NSSearchBar;
