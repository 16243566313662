import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import { UUID } from 'crypto';
import API_ENDPOINTS, { QUERY_KEYS } from 'services/urls';

export interface IOrganization {
    id: number;
    name: string;
    logoUrl: string;
    hasDealAccess: boolean;
    correlationId: UUID;
}

export interface IUser {
    id: number;
    email: string;
    name: string;
    userClass: number;
    correlationId: string;
}

export interface IUserOrganization {
    organizationId: number;
    user: IUser;
    isOrgAdmin: boolean;
    organization: IOrganization;
}

const fetchUserOrganizations = async (): Promise<IUserOrganization[]> => {
    const { data } = await axiosInstance.get<IUserOrganization[]>(API_ENDPOINTS.USER_ORGANIZATIONS);
    return data;
};

const useGetUserOrganizations = () => useQuery<IUserOrganization[], Error>({
    queryKey: [QUERY_KEYS.USER_ORGANIZATIONS],
    queryFn: fetchUserOrganizations,
    staleTime: 1000 * 60 * 5,
});

export default useGetUserOrganizations;
