import { NSModal } from 'bricks';
import React from 'react';

export interface IDeleteModalConfirmationModalProps {
    isOpen: boolean;
    toggle: () => void;
    numberOfReportsToDelete?: number;
    handleDelete: () => void;
    title?: string;
}

const DeleteModalConfirmationModal = ({
    isOpen, toggle, numberOfReportsToDelete = 1, handleDelete, title,
}: IDeleteModalConfirmationModalProps) => (
    <NSModal
        isOpen={isOpen}
        toggle={toggle}
        modalHeaderText={title || `Delete ${numberOfReportsToDelete} selected ${numberOfReportsToDelete > 1 ? 'reports' : 'report'}`}
        modalHeaderClassName="text-white bg-danger"
        modalFooterButtonText="Delete"
        modalFooterButtonColor="danger"
        modalFooterButtonFunction={() => {
            handleDelete();
        }}
    >
        <div className="text-dark h5 mb-0">{`Are you sure you want to delete ${numberOfReportsToDelete > 1 ? 'these reports' : 'the report'}?`}</div>
    </NSModal>
);

export default DeleteModalConfirmationModal;
