/* eslint-disable operator-linebreak */
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { NORTHSPYRE_TIER_TYPES } from 'bricks/constants';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS, { QUERY_KEYS } from 'services/urls';
import { IOrganizationLicenseTierExtended, IOrganizationLicense, ILicenseTiersByType } from 'types/organization';

const getOrganizationLicenses = async (organizationId: string): Promise<IOrganizationLicense[]> => {
    const response = await axiosInstance.get<IOrganizationLicense[]>(API_ENDPOINTS.ORGANIZATION.LICENSES(organizationId));
    return response.data;
};

const transformLicensesData = (licenses: IOrganizationLicense[]): ILicenseTiersByType => {
    const groups: Record<string, IOrganizationLicenseTierExtended[]> = {
        [NORTHSPYRE_TIER_TYPES.NORTHSPYRE_PLAN]: [],
        [NORTHSPYRE_TIER_TYPES.NORTHSPYRE_PLAN_PLUS]: [],
        [NORTHSPYRE_TIER_TYPES.NORTHSPYRE_PRO]: [],
        [NORTHSPYRE_TIER_TYPES.NORTHSPYRE_HISTORICAL_DATA]: [],
        [NORTHSPYRE_TIER_TYPES.NORTHSPYRE_FOR_VENDORS]: [],
        [NORTHSPYRE_TIER_TYPES.NORTHSPYRE_ENTERPRISE]: [],
    };

    licenses.forEach(orgLicense => {
        orgLicense.licenseTiers.forEach(licenseTier => {
            const tierWithOrg: IOrganizationLicenseTierExtended = {
                ...licenseTier,
                organizationLicenseUuid: orgLicense.organizationLicenseUuid,
            };

            if (groups[licenseTier.tierType]) {
                groups[licenseTier.tierType].push(tierWithOrg);
            }
        });
    });

    return groups;
};

export const useGetOrganizationLicenses = (
    organizationId: string,
    options?: Omit<UseQueryOptions<IOrganizationLicense[], Error, ILicenseTiersByType>, 'queryKey' | 'queryFn'>,
) => useQuery<IOrganizationLicense[], Error, ILicenseTiersByType>({
    queryKey: [QUERY_KEYS.ORGANIZATION.LICENSES, organizationId],
    queryFn: () => getOrganizationLicenses(organizationId),
    staleTime: Infinity,
    select: transformLicensesData,
    ...(options ?? {}),
});
