import React from 'react';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NSButton } from 'bricks';
import { SimplifiedScenario } from './types';

export const BackOption = {
    label: 'BACK',
    value: 'back',
    disabled: false,
    submenu: undefined,
    toggle: false,
    component: (
        <div className="menu">
            <FontAwesomeIcon icon={faChevronLeft} className="mr-1" />
            BACK
        </div>
    ),
};

export const DealScenariosOption = {
    value: 'Deal Scenarios',
    label: 'DEAL SCENARIOS',
    disabled: true,
    component: <span className="text-primary">DEAL SCENARIOS</span>,
};

export const UncategorizedScenariosOption = {
    value: 'Uncategorized Scenarios',
    label: 'UNCATEGORIZED',
    disabled: true,
    component: <span className="text-primary">UNCATEGORIZED</span>,
};

export const createNewScenarioOption = (handleCreateScenario: () => void, dealName?: string) => ({
    value: 'new',
    label: '+ New scenario',
    disabled: false,
    component: (
        <div className="w-100 p-2 button-container">
            <NSButton
                color="primary"
                outline
                className="w-100"
                text={dealName ? `+ New scenario in ${dealName}` : '+ New scenario'}
                callback={handleCreateScenario}
            />
        </div>
    ),
});

// TODO: remove after merge, use for testing purposes
export const simplifiedScenarios: SimplifiedScenario[] = [
    {
        name: 'Multifamily in Dumbo',
        id: 'multifamily_dumbo',
        scenarios: [{ id: -1, name: 'default' }],
    },
    {
        name: '4th Street Highrise',
        id: '4th_street_highrise',
        scenarios: [{ id: -1, name: 'default' }],
    },
    {
        name: '09.12.2024_Scenario',
        id: 'uncategorized_1',
    },
    {
        name: '09.14.2024_Scenario',
        id: 'uncategorized_2',
    },
];

export const noNestedScenarios: SimplifiedScenario[] = [
    { name: '08.16.2024_Scenario', id: '08.16.2024_Scenario' },
    { name: '08.24.2024_Scenario', id: '08.24.2024_Scenario' },
    { name: '08.12.2024_Scenario', id: '08.12.2024_Scenario' },
    { name: '08.22.2024_Scenario', id: '08.22.2024_Scenario' },
    { name: '09.12.2024_Scenario', id: 'uncategorized_1' },
    { name: '09.14.2024_Scenario', id: 'uncategorized_2' },
];

export const onlyNestedScenarios: SimplifiedScenario[] = [
    {
        name: 'Multifamily in Dumbo',
        id: 'multifamily_dumbo',
        scenarios: [
            { name: '08.16.2024_Scenario', id: '08.16.2024_Scenario' },
            { name: '08.24.2024_Scenario', id: '08.24.2024_Scenario' },
        ],
    },
    {
        name: '4th Street Highrise',
        id: '4th_street_highrise',
        scenarios: [
            { name: '08.12.2024_Scenario', id: '08.12.2024_Scenario' },
            { name: '08.22.2024_Scenario', id: '08.22.2024_Scenario' },
        ],
    },
    {
        name: 'Sunset Ridge Estates',
        id: 'sunset_ridge_estates',
        scenarios: [
            { name: '08.11.2024_Scenario', id: '08.11.2024_Scenario' },
            { name: '08.21.2024_Scenario', id: '08.21.2024_Scenario' },
        ],
    },
    {
        name: 'Evergreen Heights',
        id: 'evergreen_heights',
        scenarios: [
            { name: '08.13.2024_Scenario', id: '08.13.2024_Scenario' },
            { name: '08.23.2024_Scenario', id: '08.23.2024_Scenario' },
        ],
    },
];
