import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS, { QUERY_KEYS } from 'services/urls';
import { IConstructionType } from 'types/constructionTypes';

const getConstructionTypes = async (): Promise<IConstructionType[]> => {
    const response = await axiosInstance.get<IConstructionType[]>(API_ENDPOINTS.CONSTRUCTION_TYPES);
    return response.data;
};

export const useGetConstructionTypes = (
    options?: Omit<UseQueryOptions<IConstructionType[]>, 'queryKey' | 'queryFn'>,
) => useQuery<IConstructionType[], Error>({
    queryKey: [QUERY_KEYS.CONSTRUCTION_TYPES],
    queryFn: () => getConstructionTypes(),
    staleTime: Infinity,
    ...(options ?? {}),
});
