import React, { useEffect, useMemo, useState } from 'react';
import ProFormaGenericTable from 'views/ProFormaTable/components/ProFormaGenericTable/ProFormaGenericTable';
import { ProFormaGenericColumn, ProFormaGenericRow } from 'views/ProFormaTable/components/ProFormaGenericTable/types';
import { NSButton, NSInputNumberStepper, NSLabel } from 'bricks';
import NSCellInput from 'bricks/NSCellInput/NSCellInput';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { IOptionAnyValue } from 'bricks/types';
import { useScrollableSync } from 'views/ProFormaTable/hooks/useScrollableSync';
import { createCashFlowData, createTableCashFlowColumns } from 'views/ProFormaTable/helpers';
import { formatUSDCurrencyTwoDigits } from 'ns_libs/formatter';
import { PropTaxItem } from 'views/ProFormaTable/types';
import { OPERATIONS_EXPENSES_WS_HANDLERS } from 'views/ProFormaTable/constants';
import RenderIf from 'components/RenderIf/RenderIf';
import {
    CALCULATION_OPTIONS,
    CAPITAL_EXPENDITURES_COLUMNS,
    CASH_FLOW_COLUMNS,
    NET_OPERATION_INCOME_COLUMNS,
    OPERATING_EXPENSES_COLUMNS,
} from './constants';
import './ProFormaExpenses.scss';
import { useProFormaSocketContext } from '../../socketContext/ProFormaSocketProvider';

const ProFormaExpenses = () => {
    const {
        expenses, handleCreateOperationsRow, handleDeleteOperationsRow, handleUpdateOperationsRow,
    } = useProFormaSocketContext();
    const [operatingExpensesRows, setOperatingExpensesRows] = useState<ProFormaGenericRow[]>([]);
    const [capitalExpendituresRows, setCapitalExpendituresRows] = useState<ProFormaGenericRow[]>([]);
    const { handleScroll, handleScrollableRef } = useScrollableSync();

    const [yearTaxes, setYearTaxes] = useState<PropTaxItem[]>(expenses?.taxPercentagePerYear ?? []);
    const [millRate, setMillRate] = useState<number>(expenses.millRate);

    const handleSelectCalculation = (rowId: string) => (option: IOptionAnyValue | null) => {
        if (option) {
            setOperatingExpensesRows(rows => rows.map(row => (row.id === rowId ? { ...row, selectedCalculation: option } : row)));
        }
    };

    const handleMillRateChange = (value: number) => {
        const newValue = Math.min(Math.max(value, 0), 100);
        setMillRate(newValue);
    };

    const canAddPropertyTax = !yearTaxes?.length || (yearTaxes?.length && yearTaxes[yearTaxes.length - 1].value < 1);
    const handleUpdatePropertyTax = (propTax: PropTaxItem) => {
        const syntheticEvent = {
            target: {
                value: (propTax.value / 100).toString(),
            },
        } as React.ChangeEvent<HTMLInputElement>;

        handleUpdateOperationsRow(
            OPERATIONS_EXPENSES_WS_HANDLERS.PROPERTY_TAXES.UPDATE,
            propTax.id,
            'value',
        )(syntheticEvent);
    };

    const renderPropYearRow = (propTax: PropTaxItem, index: number) => (
        <div className="d-flex align-items-center mt-2">
            <NSLabel className="pl-0 col-4 my-auto font-weight-normal text-dark">
                Prop tax: year
                {' '}
                {index + 1}
                {' '}
                anticipated % of full
            </NSLabel>
            <NSInputNumberStepper
                name={`propTax_${index}`}
                value={propTax.value * 100}
                onChange={(value: number) => handleUpdatePropertyTax({ ...propTax, value })}
                min={0}
                max={100}
                step={1}
                percentage
                inputGroupClassName="ProFormaPermanentDebt__input-width"
            />
            <NSButton
                className="ml-2 cursor-pointer"
                callback={() => handleDeleteOperationsRow(OPERATIONS_EXPENSES_WS_HANDLERS.PROPERTY_TAXES.DELETE, propTax.id)}
                icon={faTrash}
            />
        </div>
    );

    const operatingExpenseCustomCellsColumn: ProFormaGenericColumn = {
        key: 'title',
        field: 'title',
        header: 'Operating expenses',
        format: 'none',
        isReadOnly: (row: ProFormaGenericRow) => ['capital-reserves', 'property-taxes'].includes(row.id),
        dropdown: {
            label: 'Calculated in:',
            options: CALCULATION_OPTIONS,
            getSelectedOption: (row: ProFormaGenericRow) => row.selectedCalculation,
            handleSelectOption: handleSelectCalculation,
            shouldShowDropdown: (row: ProFormaGenericRow) => row.id === 'capital-reserves',
        },
        customCell: {
            shouldShowCustomCell: (row: ProFormaGenericRow) => row.id === 'property-taxes',
            renderCell: () => (
                <div className="ProFormaExpenses__custom-cell d-flex flex-row aling-items-center py-1 px-2 ml-2">
                    <div className="pr-1 text-white">Mill rate:</div>
                    <NSCellInput
                        value={millRate || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleMillRateChange(Number(e.target.value))}
                        placeholder="0"
                        isRightAligned
                        onBlur={() => { }}
                        isPercentage
                    />
                </div>
            ),
        },
    };

    const netOperatingIncomeColumns = useMemo(() => {
        const baseCols = NET_OPERATION_INCOME_COLUMNS.map(col => {
            let { header } = col;
            if (col.key === 'annualPayment') {
                header = formatUSDCurrencyTwoDigits(expenses?.netOperatingIncome?.totalAmountPerYear || 0);
            }
            return { ...col, header };
        });

        if (expenses?.netOperatingIncome?.totalCashFlow) {
            expenses?.netOperatingIncome?.totalCashFlow.forEach(cf => {
                baseCols.push({
                    key: `column_${cf.month}`,
                    field: `column_${cf.month}`,
                    header: formatUSDCurrencyTwoDigits(cf.value || 0),
                    format: 'currency',
                    isFixed: false,
                });
            });
        }

        return baseCols;
    }, [expenses?.netOperatingIncome]);

    const totalCashFlowOperationsColumns = useMemo(() => {
        const baseCols = CASH_FLOW_COLUMNS.map(col => {
            let { header } = col;
            if (col.key === 'amountByYear') {
                header = formatUSDCurrencyTwoDigits(expenses?.totalCashFlowOperations?.totalAmountPerYear || 0);
            }
            return { ...col, header };
        });

        if (expenses?.totalCashFlowOperations?.totalCashFlow) {
            expenses?.totalCashFlowOperations?.totalCashFlow.forEach(cf => {
                baseCols.push({
                    key: `column_${cf.month}`,
                    field: `column_${cf.month}`,
                    header: formatUSDCurrencyTwoDigits(cf.value || 0),
                    format: 'currency',
                    isFixed: false,
                });
            });
        }

        return baseCols;
    }, [expenses?.totalCashFlowOperations]);

    const capitalExpendituresCashFlowColumns = useMemo(() => createTableCashFlowColumns(expenses?.capitalExpenditures),
        [expenses?.capitalExpenditures]);

    const capitalExpendituresColumns = useMemo(() => [...CAPITAL_EXPENDITURES_COLUMNS, ...capitalExpendituresCashFlowColumns],
        [capitalExpendituresCashFlowColumns]);

    const operatingExpensesCashFlowColumns = useMemo(() => createTableCashFlowColumns(expenses?.operatingExpenses),
        [expenses?.operatingExpenses]);

    const operatingExpensesColumns = useMemo(() => [operatingExpenseCustomCellsColumn, ...OPERATING_EXPENSES_COLUMNS, ...operatingExpensesCashFlowColumns],
        [operatingExpensesCashFlowColumns]);

    useEffect(() => {
        if (expenses) {
            setMillRate(expenses.millRate);
            setYearTaxes(expenses?.taxPercentagePerYear);
            if (expenses.operatingExpenses) {
                setOperatingExpensesRows(prevRows => {
                    const existingRowsMap = new Map(prevRows.map(row => [row.name, row]));

                    return (expenses.operatingExpenses || []).map(operatingExpense => {
                        const existingRow = existingRowsMap.get(operatingExpense.name);
                        const rowId = operatingExpense.name ? operatingExpense.name.toLowerCase().replace(/\s+/g, '-') : `unit-${Date.now()}`;

                        return {
                            ...(existingRow || {}),
                            id: rowId,
                            title: operatingExpense.name || '',
                            annualGrowth: String(operatingExpense.annualGrowth || 0),
                            effectiveGrossRevenue: String(operatingExpense.percentOfEffectiveGrossRevenue || 0),
                            amountUnitYear: String(operatingExpense.costPerUnitPerYear || 0),
                            amountByYear: String(operatingExpense.amountPerYear || 0),
                            ...createCashFlowData(operatingExpense.cashFlow || []),
                        };
                    });
                });
            }
            if (expenses.capitalExpenditures) {
                setCapitalExpendituresRows(prevRows => {
                    const existingRowsMap = new Map(prevRows.map(row => [row.name, row]));

                    return (expenses.capitalExpenditures || []).map(capital => {
                        const existingRow = existingRowsMap.get(capital.name);
                        const rowId = capital.name ? capital.name.toLowerCase().replace(/\s+/g, '-') : `unit-${Date.now()}`;

                        return {
                            ...(existingRow || {}),
                            id: rowId,
                            title: capital.name || '',
                            annualGrowth: String(capital.annualGrowth || 0),
                            effectiveGrossRevenue: String(capital.percentOfEffectiveGrossRevenue || 0),
                            amountUnitYear: String(capital.costPerUnitPerYear || 0),
                            amountByYear: String(capital.amountPerYear || 0),
                            ...createCashFlowData(capital.cashFlow || []),
                        };
                    });
                });
            }
        }
    }, [expenses]);

    return (
        <div className="ProFormaExpenses d-flex flex-column pb-3">
            <div className="ProFormaExpenses__inputs-container w-50 p-3">
                {yearTaxes?.map((item, index) => renderPropYearRow(item, index))}
                <RenderIf isTrue={canAddPropertyTax}>
                    <NSButton
                        className="ProFormaExpenses__add_year d-flex align-items-center mt-3"
                        callback={() => handleCreateOperationsRow(OPERATIONS_EXPENSES_WS_HANDLERS.PROPERTY_TAXES.CREATE)}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                        <p>Add year</p>
                    </NSButton>
                </RenderIf>
            </div>
            <div className="ProFormaExpenses__separator mx-3" />
            <div className="p-3">
                <ProFormaGenericTable
                    columns={operatingExpensesColumns}
                    rows={operatingExpensesRows}
                    setRows={setOperatingExpensesRows}
                    handleAddRow={() => handleCreateOperationsRow(OPERATIONS_EXPENSES_WS_HANDLERS.OPEX.CREATE)}
                    handleDeleteRow={rowId => handleDeleteOperationsRow(OPERATIONS_EXPENSES_WS_HANDLERS.OPEX.DELETE, rowId)}
                    handleUpdateRow={(rowId, dataKey) => handleUpdateOperationsRow(OPERATIONS_EXPENSES_WS_HANDLERS.OPEX.UPDATE, rowId, dataKey)}
                    onScrollableRef={handleScrollableRef(0)}
                    onScroll={handleScroll}
                    totalText="Total operating expenses"
                    totalValues={expenses.operatingExpenses ? {
                        annualGrowth: String(expenses.totalOperatingExpenses.totalAnnualGrowth || 0),
                        effectiveGrossRevenue: String(expenses.totalOperatingExpenses.totalPercentOfEffectiveGrossRevenue || 0),
                        amountUnitYear: String(expenses.totalOperatingExpenses.totalCostPerUnitPerYear || 0),
                        amountByYear: String(expenses.totalOperatingExpenses.totalAmountPerYear || 0),
                        ...createCashFlowData(expenses.totalOperatingExpenses.totalCashFlow || []),
                    } : undefined}
                />
                <div className="pb-1" />
                <ProFormaGenericTable
                    columns={netOperatingIncomeColumns}
                    rows={[]}
                    setRows={() => { }}
                    hideTotalFooter
                    hideAddButton
                    onScrollableRef={handleScrollableRef(1)}
                    onScroll={handleScroll}
                />
                <div className="pb-1" />
                <ProFormaGenericTable
                    columns={capitalExpendituresColumns}
                    rows={capitalExpendituresRows}
                    setRows={setCapitalExpendituresRows}
                    handleAddRow={() => handleCreateOperationsRow(OPERATIONS_EXPENSES_WS_HANDLERS.CAPEX.CREATE)}
                    handleDeleteRow={rowId => handleDeleteOperationsRow(OPERATIONS_EXPENSES_WS_HANDLERS.CAPEX.DELETE, rowId)}
                    handleUpdateRow={(rowId, dataKey) => handleUpdateOperationsRow(OPERATIONS_EXPENSES_WS_HANDLERS.CAPEX.UPDATE, rowId, dataKey)}
                    onScrollableRef={handleScrollableRef(2)}
                    onScroll={handleScroll}
                    totalText="Total capital expenditures"
                    totalValues={expenses.operatingExpenses ? {
                        annualGrowth: ' ',
                        effectiveGrossRevenue: String(expenses.totalOperatingExpenses.totalPercentOfEffectiveGrossRevenue || 0),
                        amountUnitYear: String(expenses.totalOperatingExpenses.totalCostPerUnitPerYear || 0),
                        amountByYear: String(expenses.totalOperatingExpenses.totalAmountPerYear || 0),
                        ...createCashFlowData(expenses.totalOperatingExpenses.totalCashFlow || []),
                    } : undefined}
                />
                <div className="pb-1" />
                <ProFormaGenericTable
                    columns={totalCashFlowOperationsColumns}
                    rows={[]}
                    setRows={() => { }}
                    hideTotalFooter
                    hideAddButton
                    onScrollableRef={handleScrollableRef(4)}
                    onScroll={handleScroll}
                />
            </div>
        </div>
    );
};

export default ProFormaExpenses;
