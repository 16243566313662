import React, { useState } from 'react';
import classNames from 'classnames';

import {
    NSButton, NSInput, NSInputNumberStepper,
    NSLabel,
} from 'bricks';
import {
    FORM_ROW_CLASS_NAME,
    FORM_LABEL_CLASS_NAME,
    proFormaModelsWithFarToggle,
} from 'views/ProFormaTable/constants';
import NSSelect, { customStyles } from 'bricks/NSSelect/NSSelect';
import RenderIf from 'components/RenderIf/RenderIf';
import { IOptionAnyValue } from 'bricks/types';
import { IProFormaValues, IUpdateProFormaKeyValues } from 'views/ProFormaTable/types';
import { PRO_FORMA_SIZE_UNIT_OPTIONS } from 'constants/unitTypes';
import CalculateSizeCard from '../../CalculateSizeCard/CalculateSizeCard';
import { useProFormaSocketContext } from '../../../socketContext/ProFormaSocketProvider';

export interface IProFormaSizeAreaFieldsProps {
    values: IProFormaValues;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelect: (name: string, option: string | IOptionAnyValue) => void;
    handleOnKeyDown: (event: React.KeyboardEvent<HTMLInputElement> | null, keyValues: IUpdateProFormaKeyValues[]) => void;
    isFar?: boolean;
    setIsFar?: (isFar: boolean) => void;
}

const ProFormaSizeAreaFields = ({
    values, handleInputChange, handleSelect, handleOnKeyDown, isFar = false, setIsFar = () => {},
}: IProFormaSizeAreaFieldsProps) => {
    const {
        handleCreateBuildableLot,
        handleDeleteBuildableLot,
        handleUpdateBuildableLot,
        buildableLots,
        handleUpdateProForma,
        proForma,
    } = useProFormaSocketContext();

    const hasGrossBuildableLots = Boolean(buildableLots.grossBuildableAreaLots.length);
    const shouldShowFarToggle = proFormaModelsWithFarToggle.has(proForma.proFormaModelId);

    const [showLots, setShowLots] = useState(hasGrossBuildableLots);

    const toggleShowLots = () => setShowLots(!showLots);

    const handleChangeFloorAreaRatio = (value: number) => {
        handleUpdateProForma([{ key: 'floorAreaRatio', value: String(value) }]);
        setShowLots(false);
    };

    return (
        <>
            <div className={FORM_ROW_CLASS_NAME}>
                <NSLabel className={FORM_LABEL_CLASS_NAME}>Land area</NSLabel>
                <NSInput
                    type="number"
                    name="landArea"
                    customClassName="NSInput__appended-child--with-select"
                    value={values.landArea}
                    onChange={handleInputChange}
                    onBlur={() => handleUpdateProForma([{ key: 'landArea', value: values.landArea }])}
                    onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                        handleOnKeyDown(event, [{ key: 'landArea', value: values.landArea }]);
                    }}
                    placeholder="Enter land area"
                    inputGroupClassName="p-0"
                    appendInputAddon={(
                        <NSSelect
                            name="landAreaUnitTypeId"
                            options={PRO_FORMA_SIZE_UNIT_OPTIONS}
                            value={PRO_FORMA_SIZE_UNIT_OPTIONS.find(opt => opt.value === values.landAreaUnitTypeId)}
                            onChange={option => {
                                handleSelect('landAreaUnitTypeId', option.value);
                                handleUpdateProForma([{ key: 'landAreaUnitTypeId', value: option.value }]);
                            }}
                            onBlur={() => handleUpdateProForma([{ key: 'landAreaUnitTypeId', value: values.landAreaUnitTypeId }])}
                            isClearable={false}
                            styles={{
                                ...customStyles,
                                control: styles => ({
                                    ...styles,
                                    border: 'none',
                                    backgroundColor: '#47515d',
                                    width: '80px',
                                }),
                            }}
                        />
                    )}
                />
            </div>

            <div className={FORM_ROW_CLASS_NAME}>

                <RenderIf isTrue={!shouldShowFarToggle}>
                    <NSLabel className={FORM_LABEL_CLASS_NAME}>Gross build. area</NSLabel>
                </RenderIf>

                <RenderIf isTrue={shouldShowFarToggle}>
                    <div className={FORM_LABEL_CLASS_NAME}>
                        <NSButton
                            color="secondary"
                            outline={false}
                            callback={() => setIsFar(true)}
                            text="FAR"
                            className={classNames('px-3', { 'border border-primary bg-primary-lighten': isFar })}
                        />
                        <NSButton
                            color="secondary"
                            outline={false}
                            callback={() => setIsFar(false)}
                            text="Gross build. area"
                            className={classNames({ 'border border-primary bg-primary-lighten': !isFar })}
                        />
                    </div>
                    <RenderIf isTrue={isFar}>
                        <NSInputNumberStepper
                            name="floorAreaRatio"
                            value={values.floorAreaRatio!}
                            onChange={handleChangeFloorAreaRatio}
                            min={0.1}
                            inputGroupClassName="w-25"
                            step={0.1}
                        />
                    </RenderIf>
                </RenderIf>

                <RenderIf isTrue={!isFar || !shouldShowFarToggle}>
                    <NSInput
                        type="number"
                        name="grossBuildableArea"
                        value={values.grossBuildableArea}
                        onChange={handleInputChange}
                        onBlur={() => handleUpdateProForma([{ key: 'grossBuildableArea', value: values.grossBuildableArea }])}
                        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                            handleOnKeyDown(event, [{ key: 'grossBuildableArea', value: values.grossBuildableArea }]);
                        }}
                        placeholder="Enter gross buildable area"
                        inputGroupClassName="p-0"
                        disabled={hasGrossBuildableLots}
                        appendInputAddon={<div className="px-2">ft&sup2;</div>}
                    />
                </RenderIf>
            </div>

            <RenderIf isTrue={!isFar || !shouldShowFarToggle}>
                <CalculateSizeCard
                    onCreateLot={handleCreateBuildableLot!}
                    onDeleteLot={handleDeleteBuildableLot!}
                    onUpdateLot={handleUpdateBuildableLot!}
                    lots={buildableLots.grossBuildableAreaLots}
                    totalGrossBuildableArea={buildableLots.totalGrossBuildableArea}
                    showLots={showLots}
                    toggleShowLots={toggleShowLots}
                />
            </RenderIf>
        </>
    );
};

export default ProFormaSizeAreaFields;
