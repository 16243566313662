import { ProFormaGenericColumn } from 'views/ProFormaTable/components/ProFormaGenericTable/types';

export const BASE_RENTAL_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'floor',
        field: 'floor',
        header: 'Floor',
        isFixed: true,
    },
    {
        key: 'allocation',
        field: 'allocation',
        header: 'Allocation',
        isFixed: true,
    },
    {
        key: 'invisibleUnits',
        field: 'invisibleUnits',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'sf',
        field: 'sf',
        header: 'SF',
        isFixed: true,
    },
    {
        key: 'invisibleUnits',
        field: 'invisibleUnits',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'pricePerSf',
        field: 'pricePerSf',
        header: '$/SF/year',
        isFixed: true,
    },
    {
        key: 'invisibleUnits',
        field: 'invisibleUnits',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'annualRent',
        field: 'annualRent',
        header: 'Annual rent (yr 1)',
        isFixed: true,
    },
];

export const BASE_RECOVERY_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'recoveryType',
        field: 'recoveryType',
        header: 'Recovery (avg. per tenant)',
        isFixed: true,
    },
    {
        key: 'invisibleUnits',
        field: 'invisibleUnits',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'recStart',
        field: 'recStart',
        header: 'Rec. start',
        isFixed: true,
    },
    {
        key: 'recPercent',
        field: 'recPercent',
        header: 'Rec. %',
        isFixed: true,
    },
    {
        key: 'pricePerSf',
        field: 'pricePerSf',
        header: '$/SF/year',
        isFixed: true,
    },
    {
        key: 'invisibleUnits',
        field: 'invisibleUnits',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'amountYear',
        field: 'amountYear',
        header: 'Amount/year',
        isFixed: true,
    },
];

export const BASE_ACTUAL_OTHER_INCOME_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'otherIncomeType',
        field: 'otherIncomeType',
        header: 'Other income',
        isFixed: true,
    },
    {
        key: 'invisibleUnits',
        field: 'invisibleUnits',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'invisibleUnits',
        field: 'invisibleUnits',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'start',
        field: 'start',
        header: 'Start',
        isFixed: true,
    },
    {
        key: 'annualGrowth',
        field: 'annualGrowth',
        header: 'Annual growth',
        isFixed: true,
    },
    {
        key: 'percentOfIncome',
        field: 'percentOfIncome',
        header: '% of rental income',
        isFixed: true,
    },
    {
        key: 'pricePerSf',
        field: 'pricePerSf',
        header: '$/SF/year',
        isFixed: true,
    },
    {
        key: 'amountYear',
        field: 'amountYear',
        header: 'Amount/year',
        isFixed: true,
    },
];

export const BASE_ESTIMATE_OTHER_INCOME_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'otherIncomeType',
        field: 'otherIncomeType',
        header: 'Other income',
        isFixed: true,
    },
    {
        key: 'invisibleUnits',
        field: 'invisibleUnits',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'start',
        field: 'start',
        header: 'Start',
        isFixed: true,
    },
    {
        key: 'annualGrowth',
        field: 'annualGrowth',
        header: 'Annual growth',
        isFixed: true,
    },
    {
        key: 'percentOfIncome',
        field: 'percentOfIncome',
        header: '% of rental income',
        isFixed: true,
    },
    {
        key: 'pricePerSf',
        field: 'pricePerSf',
        header: '$/SF/year',
        isFixed: true,
    },
    {
        key: 'amountYear',
        field: 'amountYear',
        header: 'Amount/year',
        isFixed: true,
    },
];

export const BASE_ACTUAL_INCOME_ADJUSTMENTS_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'name',
        field: 'name',
        header: 'Income adjustments',
        isFixed: true,
    },
    {
        key: 'invisibleUnits',
        field: 'invisibleUnits',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'invisibleUnits',
        field: 'invisibleUnits',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'invisibleUnits',
        field: 'invisibleUnits',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'percentOfIncome',
        field: 'percentOfIncome',
        header: '% of total pot. income',
        format: 'percentage',
        isFixed: true,
    },
    {
        key: 'pricePerSf',
        field: 'pricePerSf',
        header: '$/SF/Mo.',
        isFixed: true,
    },
    {
        key: 'pricePerUnit',
        field: 'pricePerUnit',
        header: '$/Unit/month',
        isFixed: true,
    },
    {
        key: 'amountYear',
        field: 'amountYear',
        header: 'Amount/year',
        isFixed: true,
    },
];

export const BASE_ESTIMATE_INCOME_ADJUSTMENTS_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'name',
        field: 'name',
        header: 'Income adjustments',
        isFixed: true,
    },
    {
        key: 'invisibleUnits',
        field: 'invisibleUnits',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'invisibleUnits',
        field: 'invisibleUnits',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'percentOfIncome',
        field: 'percentOfIncome',
        header: '% of total pot. income',
        format: 'percentage',
        isFixed: true,
    },
    {
        key: 'pricePerSf',
        field: 'pricePerSf',
        header: '$/SF/Mo.',
        isFixed: true,
    },
    {
        key: 'pricePerUnit',
        field: 'pricePerUnit',
        header: '$/Unit/month',
        isFixed: true,
    },
    {
        key: 'amountYear',
        field: 'amountYear',
        header: 'Amount/year',
        isFixed: true,
    },
];

export const BASE_ACTUAL_TOTAL_POTENTIAL_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'potentialIncomeType',
        field: 'potentialIncomeType',
        header: 'Total potential income',
        isFixed: true,
    },
    {
        key: 'invisibleUnits',
        field: 'invisibleUnits',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'invisibleUnits',
        field: 'invisibleUnits',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'invisibleUnits',
        field: 'invisibleUnits',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'invisibleUnits',
        field: 'invisibleUnits',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'invisibleUnits',
        field: 'invisibleUnits',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'pricePerSf',
        field: 'pricePerSf',
        header: '$/SF/year',
        isFixed: true,
    },
    {
        key: 'amountYear',
        field: 'amountYear',
        header: 'Amount/year',
        isFixed: true,
    },
];

export const BASE_ESTIMATE_TOTAL_POTENTIAL_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'potentialIncomeType',
        field: 'potentialIncomeType',
        header: 'Total potential income',
        isFixed: true,
    },
    {
        key: 'invisibleUnits',
        field: 'invisibleUnits',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'invisibleUnits',
        field: 'invisibleUnits',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'invisibleUnits',
        field: 'invisibleUnits',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'invisibleUnits',
        field: 'invisibleUnits',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'pricePerSf',
        field: 'pricePerSf',
        header: '$/SF/year',
        isFixed: true,
    },
    {
        key: 'amountYear',
        field: 'amountYear',
        header: 'Amount/year',
        isFixed: true,
    },
];

export const BASE_ACTUAL_RENTAL_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'suite',
        field: 'suite',
        header: 'Suite',
        isFixed: true,
    },
    {
        key: 'tenant',
        field: 'tenant',
        header: 'Tenant',
        isFixed: true,
    },
    {
        key: 'leaseStart',
        field: 'leaseStart',
        header: 'Lease start',
        isFixed: true,
    },
    {
        key: 'rentStart',
        field: 'rentStart',
        header: 'Rent start',
        isFixed: true,
    },
    {
        key: 'rentGrowth',
        field: 'rentGrowth',
        header: 'Rent growth',
        isFixed: true,
        format: 'percentage',
    },
    {
        key: 'rentStep',
        field: 'rentStep',
        header: 'Rent step',
        isFixed: true,
        format: 'percentage',
    },
    {
        key: 'rsf',
        field: 'rsf',
        header: 'RSF',
        isFixed: true,
    },
    {
        key: 'pricePerRsf',
        field: 'pricePerRsf',
        header: '$/RSF/year',
        isFixed: true,
    },
    {
        key: 'annualRent',
        field: 'annualRent',
        header: 'Annual rent (yr 1)',
        isFixed: true,
    },
];

export const BASE_ACTUAL_RECOVERY_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'recoveryType',
        field: 'recoveryType',
        header: 'Recovery',
        isFixed: true,
    },
    {
        key: 'invisibleUnits',
        field: 'invisibleUnits',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'invisibleUnits',
        field: 'invisibleUnits',
        header: '',
        isHidden: true,
        isFixed: true,
    },
    {
        key: 'recStart',
        field: 'recStart',
        header: 'Rec. start',
        isFixed: true,
    },
    {
        key: 'recPercent',
        field: 'recPercent',
        header: 'Rec. %',
        isFixed: true,
        format: 'percentage',
    },
    {
        key: 'proRataShare',
        field: 'proRataShare',
        header: 'Pro rata share',
        isFixed: true,
        format: 'percentage',
    },
    {
        key: 'pricePerRsf',
        field: 'pricePerRsf',
        header: '$/RSF/year',
        isFixed: true,
    },
    {
        key: 'amountYear',
        field: 'amountYear',
        header: 'Amount/year',
        isFixed: true,
    },
];
