import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS, { QUERY_KEYS } from 'services/urls';
import { IAssetType } from 'types/assetType';

const getAssetTypes = async (): Promise<IAssetType[]> => {
    const response = await axiosInstance.get<IAssetType[]>(API_ENDPOINTS.ASSET_TYPES);
    return response.data;
};

export const useGetAssetTypes = (options?: Omit<UseQueryOptions<IAssetType[]>, 'queryKey' | 'queryFn'>) => useQuery<IAssetType[], Error>({
    queryKey: [QUERY_KEYS.ASSET_TYPES],
    queryFn: () => getAssetTypes(),
    staleTime: Infinity,
    ...(options ?? {}),
});
