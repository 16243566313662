import React from 'react';
import { TimelineData } from 'views/Reports/types';
import TimelineChart from 'components/TimelineChart/TimelineChart';
import { LABELS } from './constants';

export interface IProps {
    data: TimelineData[];
    enableScrollView?: boolean;
    lightMode?: boolean;
}

export const DealTimelineChart: React.FC<IProps> = ({ data = [], enableScrollView = true, lightMode = false }) => (
    <div className="px-2 w-100">
        <TimelineChart
            data={data}
            scatterXFields={[
                { name: 'startDate', label: LABELS.startDate },
                { name: 'operationStartDate', label: LABELS.operationStartDate },
                { name: 'saleMonth', label: LABELS.saleMonth },
                { name: 'firstStabilizedMonth', label: LABELS.firstStabilizedMonth },
            ]}
            barXFields={{
                start: 'developmentStartDate',
                end: 'developmentEndDate',
                label: LABELS.developmentPeriod,
            }}
            enableScrollView={enableScrollView}
            yField="dealName"
            lightMode={lightMode}
        />
    </div>
);
export default DealTimelineChart;
