import React, { useCallback, useEffect, useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { useParams } from 'react-router-dom';
import './DealDashboard.scss';

import { NSNavTabs, NSDropdown } from 'bricks';
import { IOptionAnyValue } from 'bricks/types';
import { useAuthContext } from 'contexts/AuthContext';
import { useSlideout } from 'contexts/BackOfEnvelopeContext';
import RenderIf from 'components/RenderIf/RenderIf';
import PageTitle from 'components/PageTitle/PageTitle';
import { useGetDealById } from 'views/Deals/hooks/useGetDealById';
import useToast from 'hooks/useToast';
import { downloadFileFromUrl } from 'helpers/file/download';
import { Deal } from 'views/Deals/types';
import { FEATURE_IDS } from 'services/constants';
import { AccessLevels } from 'types/auth';
import {
    DEAL_OVERVIEW, DEAL_OVERVIEW_TABS, BACK_OF_ENVELOPE, DEAL_ACTIVITY, ExportOptions,
} from './constants';
import Overview from './Overview/Overview';
import BackOfEnvelope from './BOE/BackOfEnvelope';
import { getDealOverviewPdf } from './Overview/hooks/useGetDealOverviewPdf';
import { getScenarioDashboardPdf } from './Overview/hooks/useGetScenarioDashboardPdf';
import { useGetBackOfEnvelopeScenarios } from './BOE/hooks/useGetBOEScenarios';
import { PageSpecificKeys, useLocalStorage } from '../../helpers/localStorage';
import { ILocallyStoredActiveTabName } from './types';

const DealDashboard = () => {
    const { dealId } = useParams<{ dealId: string }>();
    const { selectedOrganizationId, isFeatureAccessibleToUser } = useAuthContext();
    let visibleDealOverviewTabs = DEAL_OVERVIEW_TABS;

    const [locallyStoredActiveTabName, setLocallyStoredActiveTabName] = useLocalStorage<ILocallyStoredActiveTabName>(
        PageSpecificKeys.DealDashboardPage,
        {},
    );
    const [activeTabName, setActiveTabName] = useState(locallyStoredActiveTabName[dealId as string]?.tab || DEAL_OVERVIEW);
    const defaultScenarioId = locallyStoredActiveTabName[dealId as string]?.scenarioId;
    const { ToastContainerElement } = useToast();
    const { selectedScenarioId } = useSlideout();

    const { data: fetchedDeal, isLoading } = useGetDealById({
        orgId: selectedOrganizationId!,
        dealId: Number(dealId),
        shouldFetch: !!Number(dealId),
    });

    const { data: { items: boeScenarios } = {} } = useGetBackOfEnvelopeScenarios({ orgId: selectedOrganizationId!, dealId: Number(dealId) });

    useEffect(() => {
        const activeTabInfo = locallyStoredActiveTabName[dealId as string];
        setLocallyStoredActiveTabName(prev => ({ ...prev, [dealId as string]: { ...activeTabInfo, tab: activeTabName } }));
    }, [activeTabName, dealId]);

    const handleScenarioChange = useCallback((scenarioId: number) => {
        const activeTabInfo = locallyStoredActiveTabName[dealId as string];
        setLocallyStoredActiveTabName(prev => ({ ...prev, [dealId as string]: { ...activeTabInfo, scenarioId: scenarioId.toString() } }));
    }, []);

    const handlePdfExport = async (pdfUrl: string, fileName: string) => {
        if (pdfUrl) {
            await downloadFileFromUrl(pdfUrl, fileName, 'pdf', true);
        } else {
            console.error('Failed to export report PDF: No URL returned');
        }
    };

    const exportDealOverviewPdf = async (deal?: Deal) => {
        if (deal) {
            const response = await getDealOverviewPdf(deal.organizationId, deal.id);
            const pdfUrl = response.url;
            handlePdfExport(pdfUrl, `${deal.dealName}-deal-overview`);
        }
    };

    const exportScenarioDashboardPdf = async (deal?: Deal) => {
        const scenarioId = selectedScenarioId || boeScenarios?.[0]?.id;
        if (deal && scenarioId) {
            const response = await getScenarioDashboardPdf(deal.organizationId, Number(scenarioId));
            const pdfUrl = response.url;
            handlePdfExport(pdfUrl, `${deal.dealName}-scenario-dashboard`);
        }
    };

    const isEmptySavedScenarios = !boeScenarios || boeScenarios?.length === 0;

    const exportOptions = Object.values(ExportOptions)
        .filter(option => !(option === ExportOptions.SCENARIO_DASHBOARD && isEmptySavedScenarios))
        .map(option => ({
            value: option,
            label: option,
        }));

    const handleSelectExportOption = async (option: IOptionAnyValue) => {
        try {
            switch (option.value) {
                case ExportOptions.SCENARIO_DASHBOARD:
                    exportScenarioDashboardPdf(fetchedDeal);
                    break;
                case ExportOptions.DEAL_OVERVIEW:
                    exportDealOverviewPdf(fetchedDeal);
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.error('Failed to export report PDF:', error);
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!fetchedDeal) {
        return <div>No deal selected</div>;
    }

    const BoEfeatureIsAccessible = isFeatureAccessibleToUser([FEATURE_IDS.BACK_OF_ENVELOPE], AccessLevels.READ_ACCESS);

    if (!BoEfeatureIsAccessible) {
        visibleDealOverviewTabs = visibleDealOverviewTabs.filter(tab => tab !== BACK_OF_ENVELOPE);
    }
    return (
        <div className="DealDashboard">
            <PageTitle
                title={fetchedDeal.dealName}
                dealName={fetchedDeal.dealName}
                pageMetaDataTitle="Deal dashboard"
                subtitle={`${fetchedDeal.address} ${fetchedDeal.city} ${fetchedDeal.state} ${fetchedDeal.zipCode}`}
                displayBreadcrumb
                boldTitle
            >
                <NSDropdown
                    containerClassName="mr-2"
                    options={exportOptions}
                    customSelectedOptionText="Export"
                    handleSelectOption={handleSelectExportOption}
                    disabled={!fetchedDeal}
                    menuRight
                />
            </PageTitle>

            <NSNavTabs
                containerClassName="d-flex mt-2 DealDashboard__separator"
                navItems={visibleDealOverviewTabs.map((navName, index) => ({
                    callback: () => setActiveTabName(navName),
                    name: navName,
                    isActive: activeTabName === navName,
                    id: `deal-overview-${index}`,
                    className: 'text-dark text-nowrap px-4 mr-2',
                }))}
            />

            <TabContent activeTab={activeTabName} className="DealDashboard__tab-content d-flex w-100">
                <RenderIf isTrue={activeTabName === DEAL_OVERVIEW}>
                    <TabPane className="d-flex flex-column w-100" tabId={DEAL_OVERVIEW}>
                        <Overview deal={fetchedDeal} />
                    </TabPane>
                </RenderIf>
                <RenderIf isTrue={activeTabName === BACK_OF_ENVELOPE}>
                    <TabPane className="d-flex flex-column w-100" tabId={BACK_OF_ENVELOPE}>
                        <BackOfEnvelope
                            orgId={selectedOrganizationId!}
                            dealId={Number(dealId)}
                            boeScenarios={boeScenarios}
                            handleScenarioChange={handleScenarioChange}
                            defaultScenarioId={defaultScenarioId ? Number(defaultScenarioId) : undefined}
                        />
                    </TabPane>
                </RenderIf>
                <RenderIf isTrue={activeTabName === DEAL_ACTIVITY}>
                    <TabPane className="d-flex flex-column w-100 pt-2" tabId={DEAL_ACTIVITY}>
                        testing zone
                    </TabPane>
                </RenderIf>
            </TabContent>
            {ToastContainerElement}
        </div>
    );
};

export default DealDashboard;
