import { INestedOptionAnyValue } from '../types';

export const filterOptions = (options: INestedOptionAnyValue[], searchText: string): INestedOptionAnyValue[] => {
    const normalizedSearchText = searchText.toLowerCase();

    const filterRecursive = (options: INestedOptionAnyValue[]): INestedOptionAnyValue[] => options
        .map(option => {
            const matches = option.label.toLowerCase().includes(normalizedSearchText);

            const filteredSubmenu = option.submenu ? filterRecursive(option.submenu) : [];

            // Include the current option if it matches or its submenu has matches
            if (matches || filteredSubmenu.length > 0) {
                return {
                    ...option,
                    submenu: filteredSubmenu.length > 0 ? filteredSubmenu : undefined,
                };
            }

            return null;
        })
        .filter(Boolean) as INestedOptionAnyValue[];

    return filterRecursive(options);
};
