import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS from 'services/urls';

export interface IResponse {
    url: string;
}

export const getReportPdf = async (reportId: number): Promise<IResponse> => {
    const response = await axiosInstance.get(API_ENDPOINTS.REPORTS.PDF(reportId));
    return response.data;
};

export const useGetReportPdf = (
    reportId: number,
    options: { enabled: boolean },
) => useQuery({
    queryKey: ['reportPdf', reportId],
    queryFn: () => getReportPdf(reportId),
    ...options,
});

export default useGetReportPdf;
