import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { useMutation } from '@tanstack/react-query';
import { IFormValues, IVariables } from '../types';

const createDeal = async ({ postData, orgId }: IVariables): Promise<IFormValues> => {
    const response = await axiosInstance.post(`${API_ENDPOINTS.DEALS(orgId)}`, postData);
    return response.data;
};

export const useCreateDeal = () => useMutation({
    mutationFn: createDeal,
});
