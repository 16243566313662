import { useState, useEffect } from 'react';
import { geocodeAddress } from 'utils/geocoding';
import { Deal } from 'views/Deals/types';

export const DEFAULT_MAP_CENTER = { lng: -98.5795, lat: 39.8283 };

export const useMapCoordinates = (deal: Pick<Deal, 'longitude' | 'latitude' | 'address' | 'city' | 'state' | 'zipCode'> | null) => {
    const [coordinates, setCoordinates] = useState<{
        lat: number;
        lng: number
    } | null>(null);

    useEffect(() => {
        const getCoordinates = async () => {
            if (!deal) {
                setCoordinates(null);
                return;
            }

            // If we already have coordinates, use them
            if (deal.latitude && deal.longitude) {
                setCoordinates({ lat: deal.latitude, lng: deal.longitude });
                return;
            }

            // If we have address details, geocode them
            if (deal.address && deal.city && deal.state) {
                const searchQuery = `${deal.address}, ${deal.city}, ${deal.state} ${deal.zipCode || ''}`.trim();
                const result = await geocodeAddress(searchQuery);
                if (result?.lat && result?.lng) {
                    setCoordinates({
                        lat: result.lat,
                        lng: result.lng,
                    });
                }
            } else {
                setCoordinates(null);
            }
        };

        getCoordinates();
    }, [deal]);

    const lng = coordinates?.lng ?? DEFAULT_MAP_CENTER.lng;
    const lat = coordinates?.lat ?? DEFAULT_MAP_CENTER.lat;

    return { lat, lng, coordinates };
};
