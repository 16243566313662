/* eslint-disable no-unused-vars */

export enum DealType {
    Acquisition = 'Acquisition',
    Development = 'Development',
}

export const DealTypeToId: Record<DealType, number> = {
    [DealType.Acquisition]: 1,
    [DealType.Development]: 2,
};
