import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthContext } from 'contexts/AuthContext';
import { useGetDealById } from 'views/Deals/hooks/useGetDealById';
import {
    faFilter,
    faFileArchive,
    faMagnifyingGlass,
    faLineColumns,
    faList,
} from '@fortawesome/pro-solid-svg-icons';
import { formatDateWithMonth } from 'ns_libs/formatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DocumentTable from 'views/DocumentLibrary/components/DocumentLibraryTable/DocumentLibraryTable';
import useAwsUploadFiles from 'hooks/useAWSUpload';
import { QUERY_KEYS } from 'services/urls';
import { useQueryClient } from '@tanstack/react-query';
import useToast from 'hooks/useToast';
import { FEATURES_LIST } from 'services/constants';
import RenderIf from 'components/RenderIf/RenderIf';
import PageTitle from 'components/PageTitle/PageTitle';
import { NSButton, NSInput } from 'bricks';
import DropzoneCard from './components/DropzoneCard/DropzoneCard';
import './Documents.scss';
import { IDealDocument } from './types';
import UploadDocumentLibrary from './components/UploadDocumentModal/UploadDocumentModal';
import RecentDocumentCard from './components/RecentDocumentCard/RecentDocumentCard';
import CreateFolderModal from './components/CreateFolderModal/CreateFolderModal';
import { useCreateMultipleDealDocuments, useGetDealDirectoryTree } from './hooks';
import { normalizeFileFormat } from './helpers';

const Documents = () => {
    const { selectedOrganizationId } = useAuthContext();
    const { dealId } = useParams<{ dealId: string }>();
    const { showError, showSuccess } = useToast();
    const queryClient = useQueryClient();

    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
    const [recentlyOpened, setRecentlyOpened] = useState<IDealDocument[]>([]);
    const [cardView, setCardView] = useState(false);
    const [selectedDirectoryId, setSelectedDirectoryId] = useState<number>(0);

    const { data: dealDirectoryTree } = useGetDealDirectoryTree({ dealId: Number(dealId)!, options: { enabled: !!dealId } });
    const { mutate: createDealDocuments } = useCreateMultipleDealDocuments();

    const {
        selectedHTML5FileToUpload,
        setSelectedHTML5FileToUpload,
        isReadyToUpload,
        clearAttachmentsStates,
        handleUpload,
        awsUploadedFiles,
        setAwsUploadedFiles,
    } = useAwsUploadFiles({
        organizationCorrelationId: selectedOrganizationId!,
        feature: FEATURES_LIST.DEAL_DOCUMENTS,
        onError: (message: string) => {
            showError(`Failed to upload file: ${message}`);
        },
    });

    const handleDocumentSelect = (doc: IDealDocument) => {
        setRecentlyOpened(prev => {
            const filtered = prev.filter(item => item.id !== doc.id);
            return [doc, ...filtered].slice(0, 4);
        });
    };

    const toggleCreateFolderModal = () => setShowCreateFolderModal(prev => !prev);
    const toggleUploadModal = () => setShowUploadModal(prev => !prev);
    const toggleCardView = () => setCardView(prev => !prev);
    const { data: fetchedDeal, isLoading } = useGetDealById({
        orgId: selectedOrganizationId!,
        dealId: Number(dealId),
        shouldFetch: !!Number(dealId),
    });

    const getFilesFromEvent = async (
        event: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>, dropLocation: string,
    ): Promise<File[]> => {
        let fileList: FileList | null = null;

        if ('dataTransfer' in event && event.dataTransfer) {
            fileList = event.dataTransfer.files;
        } else if (event.target && (event.target as HTMLInputElement).files) {
            fileList = (event.target as HTMLInputElement).files;
        }

        const filesArray = fileList ? Array.from(fileList) : [];

        const newIndexedFiles = filesArray.map((file, i) => ({
            file,
            id: crypto.randomUUID(),
        }));

        setSelectedHTML5FileToUpload(prev => [...prev, ...newIndexedFiles]);
        return filesArray;
    };

    useEffect(() => {
        if (awsUploadedFiles.length && awsUploadedFiles.length === selectedHTML5FileToUpload.length && isReadyToUpload) {
            const postFiles = selectedHTML5FileToUpload.map(file => {
                const uploadedFile = awsUploadedFiles.find(uploadedFile => uploadedFile.id === file.id)!;
                return {
                    dealId: Number(dealId),
                    organizationId: selectedOrganizationId!,
                    directoryId: selectedDirectoryId && selectedDirectoryId !== 0 ? selectedDirectoryId : null,
                    name: file.file.name,
                    extension: normalizeFileFormat(file.file.type)!,
                    size: Math.round(file.file.size / 100),
                    s3Bucket: uploadedFile.bucketName,
                    s3Path: uploadedFile.objectKey,
                    activities: [],
                };
            });

            createDealDocuments({
                postData: postFiles,
            },
            {
                onSuccess: data => {
                    showSuccess(`Document${postFiles.length > 1 ? 's' : ''} uploaded successfully`);
                    setAwsUploadedFiles([]);
                    clearAttachmentsStates();
                    setShowUploadModal(false);
                    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DEAL_DIRECTORY_TREE, Number(dealId)] });
                },
                onError: error => {
                    showError(`Failed to upload document${postFiles.length > 1 ? 's' : ''}. Please try again.`);
                },
            });
        }
    }, [awsUploadedFiles, selectedHTML5FileToUpload]);

    if (!fetchedDeal && !isLoading) {
        return <div>No deal selected</div>;
    }

    return (
        <>
            <PageTitle title="Documents" displayBreadcrumb dealName={fetchedDeal?.dealName} pageMetaDataTitle="Documents" containerClassName="mb-3">
                <NSButton color="secondary" outline={false} callback={toggleCreateFolderModal} text="Create folder" className="mr-2" />
                <NSButton color="primary" outline={false} callback={toggleUploadModal} text="Upload file" />
            </PageTitle>
            <div>
                <RenderIf isTrue={recentlyOpened.length > 0}>
                    <div className="d-flex align-items-center">
                        {recentlyOpened.map(doc => (
                            <RecentDocumentCard
                                key={doc.id}
                                filename={doc.name}
                                format={doc.extension}
                                dateModified={formatDateWithMonth(doc.updatedAt)}
                            />
                        ))}
                    </div>
                    <div className="separator my-2" />
                </RenderIf>
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div>
                        <RenderIf isTrue={cardView}>
                            <NSButton color="secondary" outline={false} icon={faList} callback={toggleCardView} text="List" className="mr-1" />
                        </RenderIf>
                        <RenderIf isTrue={!cardView}>
                            <NSButton color="secondary" outline={false} icon={faFileArchive} callback={toggleCardView} text="Card" className="mr-1" />
                        </RenderIf>
                        <NSButton color="secondary" outline={false} icon={faFilter} callback={() => { }} text="Filter" />
                    </div>
                    <div className="d-flex flex-row ">
                        <NSButton className="mr-2" icon={faLineColumns} outline={false} color="secondary" />
                        <NSInput
                            type="search"
                            name="search-bar"
                            placeholder="Search"
                            value=""
                            onChange={(value: any) => { }}
                            customClassName="bg-secondary border-0 px-0 w-100"
                            inputGroupClassName="bg-secondary border-0"
                            prependInputAddon={<FontAwesomeIcon className="text-muted" icon={faMagnifyingGlass} />}
                        />
                    </div>
                </div>
                {dealDirectoryTree?.documents?.length === 0 && dealDirectoryTree?.children?.length === 0 && (
                    <div className="Documents__dropzone-card--container pt-2">
                        <DropzoneCard getFilesFromEvent={getFilesFromEvent} />
                    </div>
                )}
                {dealDirectoryTree && (dealDirectoryTree?.documents?.length > 0 || dealDirectoryTree?.children?.length > 0) && (
                    <div className="mt-3">
                        <DocumentTable
                            dealId={Number(dealId)}
                            data={dealDirectoryTree}
                            onDocumentSelect={handleDocumentSelect}
                            isCardView={cardView}
                            getFilesFromEvent={getFilesFromEvent}
                        />
                    </div>
                )}
            </div>
            <UploadDocumentLibrary
                directoryTree={dealDirectoryTree}
                toggle={toggleUploadModal}
                isOpen={showUploadModal}
                getFilesFromEvent={getFilesFromEvent}
                onConfirm={handleUpload}
                filesToUpload={selectedHTML5FileToUpload}
                setFilesToUpload={setSelectedHTML5FileToUpload}
                selectedDirectoryId={selectedDirectoryId}
                setSelectedDirectoryId={setSelectedDirectoryId}
                backdrop="static"
            />
            {
                showCreateFolderModal && <CreateFolderModal isOpen toggle={toggleCreateFolderModal} />
            }
        </>
    );
};

export default Documents;
