import React, { useEffect, useMemo } from 'react';
import NSDropdown from 'bricks/NSDropdown/NSDropdown';
import { IOptionAnyValue } from 'bricks/types';
import { useGetProFormasByDeal } from 'queries/ProForma/useGetProFormasByDeal';

export interface IProFormaSelectorProps {
    dealId: number;
    proFormaId?: number;
    onChangeProFormaSelection: (newSelection: IOptionAnyValue) => void;
    dropdownClassName?: string;
    showDefaultOption?: boolean;
}

const ProFormaSelector = ({
    dealId, proFormaId, onChangeProFormaSelection, dropdownClassName, showDefaultOption = false,
} : IProFormaSelectorProps) => {
    const { data: proFormas } = useGetProFormasByDeal({ dealId });

    const proFormasOptions: IOptionAnyValue[] = useMemo(() => {
        if (proFormas) {
            return proFormas.map(proForma => ({
                label: proForma.name,
                value: proForma.id,
            }));
        }
        return [];
    }, [proFormas]);

    const selectedProFormaOption = proFormasOptions?.find(proForma => proForma.value === proFormaId);

    useEffect(() => {
        if (showDefaultOption && proFormas?.length && !selectedProFormaOption) {
            const activeProForma = proFormas.find(proForma => proForma.isActive);
            if (activeProForma) {
                onChangeProFormaSelection({
                    label: activeProForma.name,
                    value: activeProForma.id,
                });
            } else {
                onChangeProFormaSelection(proFormasOptions[0]);
            }
        }
    }, [showDefaultOption, proFormasOptions, selectedProFormaOption]);

    return (
        <NSDropdown
            containerClassName="mt-1 pr-3"
            options={proFormasOptions}
            selectedOption={selectedProFormaOption}
            handleSelectOption={onChangeProFormaSelection}
            placeholder="Select pro forma"
            isFullWidth
            toggleClassName={dropdownClassName}
        />
    );
};

export default ProFormaSelector;
