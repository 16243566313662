import {
    faFolder,
    faFolderBlank,
} from '@fortawesome/free-solid-svg-icons';
import {
    faFilePdf,
    faFileWord,
    faFileExcel,
    faFilePowerpoint,
    faFileImage,
    faFileVideo,
    faFileAudio,
    faFileCode,
    faFileZipper,
    faFileLines,
} from '@fortawesome/pro-regular-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { IOptionNumberValue } from 'bricks/types';
import { DealDirectoryItem, IDealDirectory, IDealDocument } from './types';

interface GetFileIconParams {
    fileFormat?: string;
    isExpanded: boolean;
}

export const getFileIcon = ({ fileFormat, isExpanded }: GetFileIconParams): IconDefinition => {
    const isFolder = !fileFormat;

    if (isFolder) {
        return isExpanded ? faFolderBlank : faFolder;
    }

    const format = fileFormat?.toLowerCase();
    switch (format) {
        case 'pdf':
            return faFilePdf;
        case 'doc':
        case 'docx':
            return faFileWord;
        case 'xls':
        case 'xlsx':
            return faFileExcel;
        case 'ppt':
        case 'pptx':
            return faFilePowerpoint;
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'gif':
        case 'bmp':
        case 'svg':
            return faFileImage;
        case 'mp4':
        case 'mov':
        case 'avi':
        case 'wmv':
            return faFileVideo;
        case 'mp3':
        case 'wav':
        case 'ogg':
            return faFileAudio;
        case 'js':
        case 'ts':
        case 'html':
        case 'css':
        case 'json':
            return faFileCode;
        case 'zip':
        case 'rar':
        case '7z':
            return faFileZipper;
        default:
            return faFileLines; // Generic file icon for unknown types
    }
};

const mimeToExtension: Record<string, string> = {
    'application/pdf': 'pdf',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
    'image/png': 'png',
    'image/jpeg': 'jpg',
    'image/gif': 'gif',
    'image/bmp': 'bmp',
    'image/svg+xml': 'svg',
    'video/mp4': 'mp4',
    'video/quicktime': 'mov',
    'video/x-msvideo': 'avi',
    'audio/mpeg': 'mp3',
    'audio/wav': 'wav',
    'application/json': 'json',
    'text/html': 'html',
    'text/css': 'css',
    'application/javascript': 'js',
    'application/zip': 'zip',
    'application/x-rar-compressed': 'rar',
    'application/x-7z-compressed': '7z',
};

export const normalizeFileFormat = (fileFormat?: string): string | undefined => {
    if (!fileFormat) return undefined;
    return mimeToExtension[fileFormat.toLowerCase()] || fileFormat.split('/').pop();
};

const DARK_BLUE = 'dark_blue';
const NS_EXCEL_GREEN = 'ns_excel_green';
const BLUE = 'blue';

export const iconColorByFilenameExtension: { [key: string]: string } = {
    pdf: BLUE,
    doc: DARK_BLUE,
    docx: DARK_BLUE,
    pptx: BLUE,
    ppt: BLUE,
    xls: NS_EXCEL_GREEN,
    xlsx: NS_EXCEL_GREEN,
    tiff: BLUE,
    jpg: BLUE,
    jpeg: BLUE,
    bmp: BLUE,
    gif: BLUE,
    png: BLUE,
    mp4: BLUE,
    mov: BLUE,
};

export const extractDirectories = (tree: IDealDirectory, includeRoot: boolean = false) => {
    const directories: IOptionNumberValue[] = [];

    if (includeRoot) {
        directories.push({ label: 'Home', value: 0 });
    }

    tree?.children?.forEach((child: IDealDirectory) => {
        if (child?.children?.length) {
            const subDirectories = extractDirectories(child);
            directories.push(...subDirectories);
        }
        directories.push({ label: child.directoryName, value: child.directoryId });
    });

    return directories;
};

export const checkIfIsFile = (item: DealDirectoryItem): item is IDealDocument => typeof item === 'object' && item !== null && 'extension' in item;
export const checkIfIsDirectory = (item: DealDirectoryItem): item is IDealDirectory => 'children' in item;

export const sortDocuments = (
    documents: IDealDocument[],
    sortConfig: { column: string; direction: 'asc' | 'desc' } | null,
): IDealDocument[] => documents.sort((a, b) => {
    if (!sortConfig) {
        return a.name.localeCompare(b.name);
    }

    const { column, direction } = sortConfig;
    const modifier = direction === 'asc' ? 1 : -1;

    switch (column.toLowerCase()) {
        case 'name':
            return modifier * a.name.localeCompare(b.name);
        case 'type':
            return modifier * (a.type || '').localeCompare(b.type || '');
        case 'format':
            return modifier * (a.extension || '').localeCompare(b.extension || '');
        case 'date modified':
            return modifier * (a.createdAt || '').localeCompare(b.createdAt || '');
        case 'size':
            // Simple string comparison for now - could be enhanced to handle actual file sizes
            return modifier * ((a.size || 0) - (b.size || 0));
        default:
            return 0;
    }
});

export const sortItems = (
    items: IDealDirectory[],
    sortConfig: { column: string; direction: 'asc' | 'desc' } | null,
): IDealDirectory[] => items
    .map(item => {
        if (item.children) {
            return {
                ...item,
                children: sortItems(item.children, sortConfig),
                documents: sortDocuments(item.documents, sortConfig),
            };
        }
        return item;
    })
    .sort((a, b) => (a.directoryName || '').localeCompare(b.directoryName || ''));
