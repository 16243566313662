import React, { useState, useEffect } from 'react';
import { ProFormaGenericRow } from 'views/ProFormaTable/components/ProFormaGenericTable/types';
import ProFormaGenericTable from 'views/ProFormaTable/components/ProFormaGenericTable/ProFormaGenericTable';
import { useProFormaSocketContext } from '../../socketContext/ProFormaSocketProvider';
import { BASE_FORECAST_COLUMNS } from './constants';
import './ProFormaForecastTable.scss';

const getColumnTitle = (key: string): string => {
    switch (key) {
        case 'untrended':
            return 'Untrended';
        case 'trended':
            return 'Trended';
        case 'sale':
            return 'Sale';
        default:
            return '';
    }
};

// TODO: Get proper period numbers
const getColumnSubtitle = (key: string): string => {
    switch (key) {
        case 'untrended':
            return '(Today)';
        case 'trended':
            return '(Period 33 - 44)';
        case 'sale':
            return '(Period 45 - 56)';
        default:
            return '';
    }
};

const ProFormaForecastTable = () => {
    const { disposition } = useProFormaSocketContext();
    const [rentalIncomeRows, setRentalIncomeRows] = useState<ProFormaGenericRow[]>([]);
    const [potentialIncomeRows, setPotentialIncomeRows] = useState<ProFormaGenericRow[]>([]);
    const [effectiveGrossRevenueRows, setEffectiveGrossRevenueRows] = useState<ProFormaGenericRow[]>([]);
    const [operatingExpensesRows, setOperatingExpensesRows] = useState<ProFormaGenericRow[]>([]);
    const [capitalExpendituresRows, setCapitalExpendituresRows] = useState<ProFormaGenericRow[]>([]);

    useEffect(() => {
        // Rental Income section
        const rentalIncome: ProFormaGenericRow[] = [
            {
                id: 'gross-rent',
                name: 'Gross rent',
                untrended: disposition?.totalRentalIncome?.untrended?.toString() || '',
                trended: disposition?.totalRentalIncome?.trended?.toString() || '',
                sale: disposition?.totalRentalIncome?.sale?.toString() || '',
            },
            {
                id: 'gross-rent-adjustments',
                name: 'Gross rent adjustments',
                untrended: disposition?.effectiveGrossRevenue?.[0]?.untrended?.toString() || '',
                trended: disposition?.effectiveGrossRevenue?.[0]?.trended?.toString() || '',
                sale: disposition?.effectiveGrossRevenue?.[0]?.sale?.toString() || '',
                isNegative: true,
            },
        ];
        setRentalIncomeRows(rentalIncome);

        // Potential Income section
        const potentialIncome: ProFormaGenericRow[] = [
            {
                id: 'total-other-income',
                name: 'Total other income',
                untrended: disposition?.totalPotentialIncome?.untrended?.toString() || '',
                trended: disposition?.totalPotentialIncome?.trended?.toString() || '',
                sale: disposition?.totalPotentialIncome?.sale?.toString() || '',
            },
        ];
        setPotentialIncomeRows(potentialIncome);

        const effectiveGrossRevenue: ProFormaGenericRow[] = [
            {
                id: 'income-adjustments',
                name: 'Income adjustments',
                untrended: disposition?.effectiveGrossRevenue?.[0]?.untrended?.toString() || '',
                trended: disposition?.effectiveGrossRevenue?.[0]?.trended?.toString() || '',
                sale: disposition?.effectiveGrossRevenue?.[0]?.sale?.toString() || '',
                isNegative: true,
            },
        ];
        setEffectiveGrossRevenueRows(effectiveGrossRevenue);

        // Operating Expenses section
        const operatingExpenses: ProFormaGenericRow[] = [
            {
                id: 'total-operating-expenses',
                name: 'Total operating expenses',
                untrended: disposition?.totalOperatingExpenses?.untrended?.toString() || '',
                trended: disposition?.totalOperatingExpenses?.trended?.toString() || '',
                sale: disposition?.totalOperatingExpenses?.sale?.toString() || '',
                isNegative: true,
            },
        ];
        setOperatingExpensesRows(operatingExpenses);

        // Capital Expenditures section
        const capitalExpenditures: ProFormaGenericRow[] = [
            {
                id: 'total-capital-expenditures',
                name: 'Total capital expenditures',
                untrended: disposition?.totalCapitalExpenditures?.untrended?.toString() || '',
                trended: disposition?.totalCapitalExpenditures?.trended?.toString() || '',
                sale: disposition?.totalCapitalExpenditures?.sale?.toString() || '',
                isNegative: true,
            },
        ];
        setCapitalExpendituresRows(capitalExpenditures);
    }, [disposition]);

    const forecastColumns = BASE_FORECAST_COLUMNS.map(col => {
        if (['untrended', 'trended', 'sale'].includes(col.key)) {
            return {
                ...col,
                header: (
                    <div className="ProFormaForecastTable__header">
                        <div>{getColumnTitle(col.key)}</div>
                        <small className="text-muted">{getColumnSubtitle(col.key)}</small>
                    </div>
                ),
            };
        }
        return col;
    });

    return (
        <div className="d-flex flex-column p-3">
            <ProFormaGenericTable
                columns={forecastColumns}
                rows={rentalIncomeRows}
                setRows={setRentalIncomeRows}
                hideAddButton
                totalText="Total rental income"
                totalValues={{
                    untrended: disposition?.totalRentalIncome?.untrended?.toString() || '',
                    trended: disposition?.totalRentalIncome?.trended?.toString() || '',
                    sale: disposition?.totalRentalIncome?.sale?.toString() || '',
                }}
            />
            <ProFormaGenericTable
                columns={forecastColumns}
                rows={potentialIncomeRows}
                setRows={setPotentialIncomeRows}
                hideAddButton
                hideHeader
                totalText="Total potential income"
                totalValues={{
                    untrended: disposition?.totalPotentialIncome?.untrended?.toString() || '',
                    trended: disposition?.totalPotentialIncome?.trended?.toString() || '',
                    sale: disposition?.totalPotentialIncome?.sale?.toString() || '',
                }}
            />
            <ProFormaGenericTable
                columns={forecastColumns}
                rows={effectiveGrossRevenueRows}
                setRows={setEffectiveGrossRevenueRows}
                hideAddButton
                hideHeader
                totalText="Effective gross revenue"
                totalValues={{
                    untrended: disposition?.totalEffectiveGrossRevenue?.untrended?.toString() || '',
                    trended: disposition?.totalEffectiveGrossRevenue?.trended?.toString() || '',
                    sale: disposition?.totalEffectiveGrossRevenue?.sale?.toString() || '',
                }}
            />
            <ProFormaGenericTable
                hideHeader
                columns={forecastColumns}
                rows={operatingExpensesRows}
                setRows={setOperatingExpensesRows}
                hideAddButton
                totalText="Net operating income"
                totalValues={{
                    untrended: disposition?.netOperatingIncome?.untrended?.toString() || '',
                    trended: disposition?.netOperatingIncome?.trended?.toString() || '',
                    sale: disposition?.netOperatingIncome?.sale?.toString() || '',
                }}
            />
            <ProFormaGenericTable
                columns={forecastColumns}
                rows={capitalExpendituresRows}
                setRows={setCapitalExpendituresRows}
                hideAddButton
                hideHeader
                totalText="Cash flow from operations"
                totalValues={{
                    untrended: disposition?.cashFlowFromOperations?.untrended?.toString() || '',
                    trended: disposition?.cashFlowFromOperations?.trended?.toString() || '',
                    sale: disposition?.cashFlowFromOperations?.sale?.toString() || '',
                }}
            />
        </div>
    );
};

export default ProFormaForecastTable;
