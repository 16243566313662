import React from 'react';
import { OptionType } from 'bricks/NSSelectWithCheckboxes/NSSelectWithCheckboxes';
import { IOptionNumberValue } from 'bricks/types';
import ReportFilters from './ReportFilters';
import { FILTERS_PARAM_KEYS } from './constants';

export interface IReportsControlProps {
    filterSettings: Partial<Record<FILTERS_PARAM_KEYS, IOptionNumberValue[]>> | undefined;
    setFilterSettings: React.Dispatch<React.SetStateAction<Partial<Record<FILTERS_PARAM_KEYS, IOptionNumberValue[]>> | undefined>>;
    reportTypeOptions: OptionType[];
    dealOptions: OptionType[];
}

const ReportsControl = ({
    filterSettings, setFilterSettings, reportTypeOptions, dealOptions,
}: IReportsControlProps) => (
    <div className="Reports-control d-flex justify-content-between my-3">
        <ReportFilters
            filterSettings={filterSettings}
            setFilterSettings={setFilterSettings}
            reportTypeOptions={reportTypeOptions}
            dealOptions={dealOptions}
        />
        <input type="text" placeholder="Search" className="Reports-control__search-input bg-secondary text-white px-2" />
    </div>
);

export default ReportsControl;
