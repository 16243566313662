import axiosInstance from 'ns_libs/nsAxios';
import { QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';

import { AxiosError } from 'axios';
import { ORGANIZATION_USER_FEATURE_ACCESS_QUERY_KEY } from 'services/constants';
import { IUserAuthData } from 'types/auth';

type TQueryKey = [typeof ORGANIZATION_USER_FEATURE_ACCESS_QUERY_KEY, string];

const getUserAuthData = async ({ queryKey }: QueryFunctionContext<TQueryKey>): Promise<IUserAuthData> => {
    const response = await axiosInstance.get(API_ENDPOINTS.FEATURE_ACCESS(queryKey[1]));
    return response.data;
};

const useGetUserAuthData = (
    organizationId: string,
    options?: Omit<UseQueryOptions<IUserAuthData, AxiosError, IUserAuthData, TQueryKey>, 'queryKey' | 'queryFn'>,
) => useQuery({
    queryKey: [ORGANIZATION_USER_FEATURE_ACCESS_QUERY_KEY, organizationId],
    queryFn: getUserAuthData,
    ...options,
});

export default useGetUserAuthData;
