import React from 'react';
import { Outlet, Navigate, useMatches } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { NSLoadingScreen } from 'bricks';
import useAuthContext from 'contexts/AuthContext';
import { AccessLevels, RouteHandle } from 'types/auth';
import NoAccess from 'components/NoAccess/NoAccess';

const NSRoute = () => {
    const { isAuthenticated, isLoading } = useAuth0();
    const { isLoadingAuthData, isFeatureAccessibleToUser } = useAuthContext();

    const matches = useMatches();
    const matchWithFeatureIds = matches.find(match => Array.isArray((match.handle as RouteHandle)?.featureIds));

    const featureIds = (matchWithFeatureIds?.handle as RouteHandle)?.featureIds || [];

    const userHasAccess = isFeatureAccessibleToUser(featureIds, AccessLevels.READ_ACCESS);

    if (isLoading || isLoadingAuthData) {
        return <NSLoadingScreen />;
    }

    if (!isAuthenticated) {
        return <Navigate to="/" replace />;
    }
    return userHasAccess ? <Outlet /> : <NoAccess />;
};

export default NSRoute;
