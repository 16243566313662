import { useMutation } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS from 'services/urls';

interface IResponse {
    url: string;
}

interface ICreateReportBuilderReportData {
    reportType: string;
    metaData: any;
}

interface ICreateReportPostData {
    orgId: string;
    reportBuilderReports: ICreateReportBuilderReportData[];
}

interface ICreateReportParams {
    postData: ICreateReportPostData;
    dealId: number;
}

export const createCombinedReportPdf = async ({ postData, dealId }: ICreateReportParams): Promise<IResponse> => {
    try {
        const response = await axiosInstance.post(`${API_ENDPOINTS.REPORT_BUILDER.PDF(dealId)}`, postData);
        return response.data;
    } catch (error: any) {
        if (error.response?.status !== 500) {
            throw new Error(error.response.data);
        }
        throw new Error('An unexpected error occurred. Please try again.');
    }
};

export const useCreateCombinedReportPdf = () => useMutation({
    mutationFn: createCombinedReportPdf,
});

export default useCreateCombinedReportPdf;
