import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NSButton } from 'bricks';
import RenderIf from 'components/RenderIf/RenderIf';
import useAuthContext from 'contexts/AuthContext';
import React from 'react';
import { Link } from 'react-router-dom';
import { URLS } from 'services/urls';

const NoAccess = () => {
    const { role } = useAuthContext();
    return (
        <div className="text-center h-100 w-100 position-absolute d-flex flex-column justify-content-center">
            <FontAwesomeIcon icon={faLock} className="mb-1 text-white" size="5x" />
            <h2 className="text-white">You don't have access to this page. Contact a system administrator to request access.</h2>
            <RenderIf isTrue={!!role}>
                <NSButton dataTestId="back-to-deals-btn" outline={false} color="secondary" className="d-block mx-auto">
                    <Link to={URLS.DEALS.HOME} className="text-white">
                        Back to Deals
                    </Link>
                </NSButton>
            </RenderIf>
        </div>
    );
};

export default NoAccess;
