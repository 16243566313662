import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { useMutation } from '@tanstack/react-query';
import { ICreateDealDocumentPostData, IDealDocument } from '../types';

interface ICreateMultipleDealDocuments {
    postData: ICreateDealDocumentPostData[];
}

const createMultipleDealDocuments = async ({ postData }: ICreateMultipleDealDocuments): Promise<IDealDocument[]> => {
    try {
        const response = await axiosInstance.post(API_ENDPOINTS.DEAL_DOCUMENTS.BULK(), postData);
        return response.data;
    } catch (error: any) {
        if (error.response?.status !== 500) {
            throw new Error(error.response.data);
        }
        throw new Error('An unexpected error occurred. Please try again.');
    }
};

export const useCreateMultipleDealDocuments = () => useMutation({
    mutationFn: createMultipleDealDocuments,
});

export default useCreateMultipleDealDocuments;
