export const defaultPeriods = {
    'Jan 2023': 4364.63214488499,
    'Mar 2023': 2128.4748489905073,
    'Apr 2023': 1878.1144712397906,
    'May 2023': 3925.12447987615,
    'Jun 2023': 2294.2972849011485,
    'Jul 2023': 2295.6832216898906,
    'Aug 2023': 1893.506957332425,
    'Sep 2023': 2013.0654425893654,
    'Oct 2023': 2181.3692314871087,
    'Nov 2023': 1932.610521673614,
};

export const defaultReturnsData = {
    proFormaReturns: {
        total_development_cost: {
            amount: 0,
            periods: {},
        },
        total_interest: {
            amount: 0,
            periods: {},
        },
        total_loan_draws_during_development: {
            amount: 0,
            periods: {},
        },
        perm_loan_funding: {
            amount: 0,
            periods: {},
        },
        cash_flow_from_operations: {
            amount: 0,
            periods: {},
        },
        perm_loan_debt_service: {
            amount: 0,
            periods: {},
        },
        net_sale_proceeds: {
            amount: 0,
            periods: {},
        },
        construction_loan_payoff: {
            amount: 0,
            periods: {},
        },
        permanent_loan_payoff: {
            amount: 0,
            periods: {},
        },
        equity_proceeds_from_capital_events: {
            amount: 0,
            periods: {},
        },
    },
    cashFlowData: {
        net_unlevered_cash_flow: {
            amount: 0,
            equityMultiple: 0,
            irr: 0,
            netProfit: 0,
            periods: {},
        },
        net_levered_cash_flow: {
            amount: 0,
            equityMultiple: 0,
            irr: 0,
            netProfit: 0,
            periods: {},
        },
    },
    costMetrics: {
        yieldOnCost: {
            untrended: 0,
            trended: 0,
            sale: 0,
            isHighlighted: null,
        },
        marketCapRate: {
            untrended: 0,
            trended: 0,
            sale: 0,
            isHighlighted: null,
        },
        developmentSpread: {
            untrended: 0,
            trended: 0,
            sale: 0,
            isHighlighted: null,
        },
    },
};

export const transformReturnsData = (returnsData: any) => {
    if (!returnsData) {
        return defaultReturnsData;
    }

    return {
        proFormaReturns: {
            totalDevelopmentCost: {
                amount: Number(returnsData.totalProjectCost),
                periods: defaultPeriods,
            },
            totalInterest: {
                amount: Number(returnsData.totalLoanInterest),
                periods: defaultPeriods,
            },
            totalLoanDrawsDuringDevelopment: {
                amount: Number(returnsData.totalConstructionLoanDraws),
                periods: defaultPeriods,
            },
            permLoanFunding: {
                amount: 0,
                periods: defaultPeriods,
            },
            cashFlowFromOperations: {
                amount: Number(returnsData.totalCashFlowFromOperations),
                periods: defaultPeriods,
            },
            permLoanDebtService: {
                amount: 0,
                periods: defaultPeriods,
            },
            netSaleProceeds: {
                amount: Number(returnsData.totalNetSaleProceeds),
                periods: defaultPeriods,
            },
            constructionLoanPayoff: {
                amount: Number(returnsData.totalConstructionLoanPayoff),
                periods: defaultPeriods,
            },
            permanentLoanPayoff: {
                amount: 0,
                periods: defaultPeriods,
            },
            equityProceedsFromCapitalEvents: {
                amount: Number(returnsData.totalEquityProceedFromCapitalEvents),
                periods: defaultPeriods,
            },
        },
        cashFlowData: {
            netUnleveredCashFlow: {
                equityMultiple: Number(returnsData.netUnleveredEquityMultiple),
                irr: Number(returnsData.netUnleveredIrr),
                netProfit: Number(returnsData.netUnleveredNetProfit),
                amount: 0,
                periods: defaultPeriods,
            },
            netLeveredCashFlow: {
                equityMultiple: Number(returnsData.netLeveredEquityMultiple),
                irr: Number(returnsData.netLeveredIrr),
                amount: 0,
                netProfit: Number(returnsData.netLeveredCashFlow),
                periods: defaultPeriods,
            },
        },
        costMetrics: {
            yieldOnCost: {
                untrended: returnsData.yieldOnCost?.untrended,
                trended: returnsData.yieldOnCost?.trended,
                sale: returnsData.yieldOnCost?.sale,
                isHighlighted: null,
            },
            marketCapRate: {
                untrended: returnsData.marketCapitalizationRate?.current,
                trended: returnsData.marketCapitalizationRate?.trended,
                sale: returnsData.marketCapitalizationRate?.onSale,
                isHighlighted: null,
            },
            developmentSpread: {
                untrended: returnsData.developmentSpread?.untrended,
                trended: returnsData.developmentSpread?.trended,
                sale: returnsData.developmentSpread?.sale,
                isHighlighted: null,
            },
        },
    };
};
