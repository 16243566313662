import React, { ReactElement, ReactNode, useRef } from 'react';
import {
    ModalFooter, ModalBody, ModalHeader, Modal,
} from 'reactstrap';
import { NSButton } from '..';
import RenderIf from '../../components/RenderIf/RenderIf';

export interface INSModalProps {
    toggle: (arg?: any | null) => void;
    isOpen: boolean;
    children?: ReactNode;
    className?: string;
    modalClassName?: string;
    modalHeaderClassName?: string;
    modalHeaderText?: string | JSX.Element;
    modalFooterButtonText?: string | ReactNode;
    returnFocusAfterClose?: boolean;
    modalFooterButtonColor?: string;
    modalFooterButtonFunction?: () => void;
    modalFooterButtonAllowReadOnlyAccess?: boolean;
    modalFooterCancelButtonText?: string;
    modalFooterCancelButtonFunction?: () => void;
    modalFooterIsCancelButtonDisabled?: boolean;
    modalFooterIsButtonFunctionDisabled?: boolean;
    modalFooterDisabledTooltip?: string;
    modalFooterIsShown?: boolean;
    modalHeaderIsShown?: boolean;
    overlay?: boolean;
    cancelButtonOnly?: boolean;
    footerButtonOutline?: boolean;
    modalBodyClassName?: string;
    customFooter?: ReactElement;
    customHeader?: ReactElement;
    modalFooterButtonFormId?: string;
    modalFooterButtonType?: string;
    contentClassName?: string;
    size?: string;
    unmountOnClose?: boolean;
    scrollable?: boolean;
    centered?: boolean;
    modalFooterButtonClassName?: string;
    modalFooterCancelButtonClassName?: string;
    modalFooterButtonIsLoading?: boolean;
    backdrop?: boolean | 'static';
}

const NSModal = ({
    toggle,
    isOpen,
    modalClassName = '',
    modalHeaderClassName = '',
    modalHeaderText = 'Default Modal Header Text',
    modalFooterButtonText = 'Submit',
    returnFocusAfterClose = false,
    modalFooterButtonColor = 'primary',
    modalFooterButtonFunction = () => {},
    modalFooterButtonAllowReadOnlyAccess = false,
    modalFooterCancelButtonText = 'Cancel',
    modalFooterCancelButtonFunction = () => {},
    modalFooterIsCancelButtonDisabled = false,
    modalFooterIsButtonFunctionDisabled = false,
    modalFooterDisabledTooltip = '',
    modalFooterIsShown = true,
    modalHeaderIsShown = true,
    children = null,
    overlay = false,
    cancelButtonOnly = false,
    footerButtonOutline = false,
    modalBodyClassName = '',
    customFooter,
    customHeader,
    modalFooterButtonFormId = '',
    modalFooterButtonType = '',
    contentClassName = '',
    size = '',
    unmountOnClose = false,
    scrollable = false,
    centered = false,
    modalFooterButtonClassName = '',
    modalFooterCancelButtonClassName = '',
    modalFooterButtonIsLoading,
    ...rest
}: INSModalProps) => {
    const modalRef = useRef(null);

    const handleCallback = () => {
        if (modalFooterCancelButtonFunction) {
            modalFooterCancelButtonFunction();
        }
        toggle(null);
    };

    return (
        <Modal
            ref={modalRef}
            isOpen={isOpen}
            toggle={toggle}
            className={`NSModal ${modalClassName}`}
            returnFocusAfterClose={returnFocusAfterClose}
            contentClassName={`NSModal__modal-content ${contentClassName}`}
            size={size}
            centered={centered}
            scrollable={scrollable}
            cssModule={{
                'modal-backdrop': 'modal-backdrop NSModal__modal-backdrop--show',
            }}
            {...rest}
        >
            <RenderIf isTrue={customHeader}>{customHeader}</RenderIf>

            <RenderIf isTrue={!customHeader && !overlay && modalHeaderIsShown}>
                <ModalHeader className={modalHeaderClassName} toggle={toggle}>
                    {modalHeaderText}
                </ModalHeader>
            </RenderIf>

            <ModalBody className={modalBodyClassName}>{children}</ModalBody>

            <RenderIf isTrue={!overlay && modalFooterIsShown}>
                <ModalFooter>
                    <RenderIf isTrue={customFooter}>{customFooter}</RenderIf>
                    <RenderIf isTrue={!customFooter}>
                        <NSButton
                            callback={handleCallback}
                            text={modalFooterCancelButtonText}
                            className={`bg-none text-dark ${modalFooterCancelButtonClassName || ''}`}
                            disabled={modalFooterIsCancelButtonDisabled}
                        />

                        <RenderIf isTrue={!cancelButtonOnly}>
                            <NSButton
                                id="NSModal_footer_btn"
                                className={modalFooterButtonClassName}
                                outline={footerButtonOutline}
                                color={modalFooterButtonColor}
                                disabled={modalFooterIsButtonFunctionDisabled}
                                disabledTooltip={modalFooterDisabledTooltip}
                                callback={modalFooterButtonFunction}
                                {...(modalFooterButtonFormId && { formId: modalFooterButtonFormId })}
                                {...(modalFooterButtonType && { buttonType: modalFooterButtonType })}
                                allowReadOnlyAccess={modalFooterButtonAllowReadOnlyAccess}
                                isLoading={modalFooterButtonIsLoading}
                            >
                                {modalFooterButtonText}
                            </NSButton>
                        </RenderIf>
                    </RenderIf>
                </ModalFooter>
            </RenderIf>
        </Modal>
    );
};

export default NSModal;
