/* eslint-disable operator-linebreak */
import React, { useState } from 'react';
import { NSNavTabs } from 'bricks';
import { TabContent } from 'reactstrap';
import RenderIf from 'components/RenderIf/RenderIf';
import ReadOnlyWrapper from 'components/ReadOnlyWrapper/ReadOnlyWrapper';
import TopLevelAnalysis from './TopLevelAnalysis/TopLevelAnalysis';
import ProFormaGenericTable from '../../ProFormaGenericTable/ProFormaGenericTable';
import {
    INITIAL_FINANCIAL_DATA,
    WATERFALL_SUMMARY_COLUMNS,
    WATERFALL_SUMMARY_ROWS_INITIAL_STATE,
    WATERFALL_TABS,
    GP_EXPANDED,
    LP_EXPANDED,
    OVERVIEW,
} from './constants';
import { ProFormaGenericRow } from '../../ProFormaGenericTable/types';
import './WaterfallAnalysis.scss';
import ContributionsAndDistributions from './ContributionsAndDistributions/ContributionsAndDistributions';

export interface IWaterfallAnalysisProps {
    hasWriteAccessToProForma: boolean;
}

const WaterfallAnalysis = ({ hasWriteAccessToProForma }: IWaterfallAnalysisProps) => {
    const [activeTabName, setActiveTabName] = useState<string>(OVERVIEW);
    const [tabsDisplay, setTabsDisplay] = useState({ showMultipleLP: false, showMultipleGP: false });
    const [waterfallSummaryRows, setWaterfallSummaryRows] = useState<ProFormaGenericRow[]>(WATERFALL_SUMMARY_ROWS_INITIAL_STATE);

    const [financialData, setFinancialData] = useState(INITIAL_FINANCIAL_DATA);

    const updateFinancialData = (tab: string, key: string, value: ProFormaGenericRow[]) => {
        setFinancialData(prev => ({
            ...prev,
            [tab]: { ...prev[tab as keyof typeof INITIAL_FINANCIAL_DATA], [key]: value },
        }));
    };

    const handleTabsDisplayChange = (key: string, value: boolean) => {
        setTabsDisplay(prev => ({ ...prev, [key]: value }));
    };

    const tabs = WATERFALL_TABS.filter(tab => (
        (tabsDisplay.showMultipleLP && tab === LP_EXPANDED) ||
            (tabsDisplay.showMultipleGP && tab === GP_EXPANDED) ||
            ((tabsDisplay.showMultipleLP || tabsDisplay.showMultipleGP) && tab === OVERVIEW)
    ));

    return (
        <ReadOnlyWrapper isReadOnly={!hasWriteAccessToProForma}>
            <div className="WaterfallAnalysis">
                <TopLevelAnalysis onChange={handleTabsDisplayChange} />
                <div className="WaterfallAnalysis__separator mx-3 mb-3" />
                <div className="w-100 px-3 pb-3">
                    <ProFormaGenericTable
                        columns={WATERFALL_SUMMARY_COLUMNS}
                        rows={waterfallSummaryRows}
                        setRows={setWaterfallSummaryRows}
                        hideAddButton
                        hideTotalFooter
                    />
                </div>
                <div className="WaterfallAnalysis__separator mx-3 mb-3" />
                <div className="w-100 px-3 pb-3">
                    <RenderIf isTrue={!tabsDisplay.showMultipleGP && !tabsDisplay.showMultipleLP}>
                        <ContributionsAndDistributions
                            {...financialData[OVERVIEW]}
                            setContributionsRows={rows => updateFinancialData(OVERVIEW, 'contributionsRows', rows)}
                            setDistributionWaterfallRows={rows => updateFinancialData(OVERVIEW, 'distributionWaterfallRows', rows)}
                            setDistributionsRows={rows => updateFinancialData(OVERVIEW, 'distributionsRows', rows)}
                            setNetCashFlowRows={rows => updateFinancialData(OVERVIEW, 'netCashFlowRows', rows)}
                        />
                    </RenderIf>
                    <RenderIf isTrue={tabsDisplay.showMultipleLP || tabsDisplay.showMultipleGP}>
                        <NSNavTabs
                            containerClassName="d-flex mt-2"
                            navItems={tabs.map((navName, index) => ({
                                callback: () => setActiveTabName(navName),
                                name: navName,
                                isActive: activeTabName === navName,
                                id: `watefall-analysis-${index}`,
                                className: 'WaterfallAnalysis__tabs text-dark text-nowrap px-4 mr-2',
                            }))}
                        />

                        <TabContent activeTab={activeTabName} className="NSCard--level-3 d-flex w-100 h-100 pl-3 pt-3 pb-3">
                            <ContributionsAndDistributions
                                {...financialData[activeTabName as keyof typeof INITIAL_FINANCIAL_DATA]}
                                setContributionsRows={rows => updateFinancialData(activeTabName, 'contributionsRows', rows)}
                                setDistributionWaterfallRows={rows => updateFinancialData(activeTabName, 'distributionWaterfallRows', rows)}
                                setDistributionsRows={rows => updateFinancialData(activeTabName, 'distributionsRows', rows)}
                                setNetCashFlowRows={rows => updateFinancialData(activeTabName, 'netCashFlowRows', rows)}
                            />
                        </TabContent>
                    </RenderIf>
                </div>
            </div>
        </ReadOnlyWrapper>
    );
};

export default WaterfallAnalysis;
