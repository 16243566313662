import { useQueryClient } from '@tanstack/react-query';
import {
    NSInput, NSLabel, NSModal, NSSelect,
} from 'bricks';
import React, { useState } from 'react';
import { QUERY_KEYS } from 'services/urls';
import { useCreateDealDirectory, useDealDirectoryOptions } from 'views/DocumentLibrary/hooks';
import useToast from 'hooks/useToast';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';

export interface ICreateFolderModalProps {
    toggle: () => void;
    isOpen: boolean;
}

const CreateFolderModal = ({
    isOpen, toggle,
}: ICreateFolderModalProps) => {
    const { dealId } = useParams<{ dealId: string }>();
    const [newFolderName, setNewFolderName] = useState<string>('');
    const [selectedDirectory, setSelectedDirectory] = useState<number | null>(null);
    const [errors, setErrors] = useState<{ name?: string }>({});
    const queryClient = useQueryClient();
    const { showSuccess, showError } = useToast();

    const validationSchema = Yup.object({
        name: Yup.string().required('Folder name is required'),
    });

    const folderLocationOptions = useDealDirectoryOptions(Number(dealId));

    const { mutate: createDirectory, isPending: isCreatingDirectory } = useCreateDealDirectory();

    const handleCreateFolder = async () => {
        try {
            await validationSchema.validate({ name: newFolderName }, { abortEarly: false });
            setErrors({});
            createDirectory(
                { dealId: Number(dealId), postData: { name: newFolderName, parentDirectoryId: selectedDirectory, dealId: Number(dealId) } },
                {
                    onSuccess: data => {
                        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.DEAL_DIRECTORY_TREE, Number(dealId)] });
                        showSuccess('Folder created successfully');
                        toggle();
                    },
                    onError: () => {
                        showError('Failed to create folder. Please try again.');
                    },
                },
            );
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const validationErrors: { [key: string]: string } = {};
                err.inner.forEach(error => {
                    if (error.path) validationErrors[error.path] = error.message;
                });
                setErrors(validationErrors);
            }
        }
    };

    return (
        <NSModal
            isOpen={isOpen}
            toggle={toggle}
            modalFooterCancelButtonText="Cancel"
            modalFooterCancelButtonFunction={toggle}
            modalFooterIsCancelButtonDisabled={isCreatingDirectory}
            footerButtonOutline={false}
            modalFooterButtonText={isCreatingDirectory ? 'CREATING DIRECTORY...' : 'CREATE'}
            modalFooterButtonFunction={() => handleCreateFolder()}
            modalFooterIsButtonFunctionDisabled={isCreatingDirectory}
            modalHeaderText="Create New Folder"
            modalHeaderClassName="bg-primary text-white"
            modalBodyClassName="px-3 py-4"
        >
            <NSInput
                type="text"
                id="name"
                name="name"
                placeholder="Name your folder"
                value={newFolderName}
                onChange={(
                    e: React.ChangeEvent<HTMLInputElement>,
                ) => setNewFolderName(e.target.value)}
            />
            {errors.name && <div className="text-danger mt-1">{errors.name}</div>}
            <NSLabel className="mt-3">Folder location</NSLabel>
            <NSSelect
                name="folder-locations"
                options={folderLocationOptions}
                defaultValue={{ label: 'Home', value: null }}
                isClearable={false}
                onChange={option => setSelectedDirectory(option.value)}
            />
        </NSModal>
    );
};

export default CreateFolderModal;
