import { config } from 'config';
import API_ENDPOINTS from 'services/urls';

export interface GeocodingResult {
    lat: number;
    lng: number;
    county: string;
    zipCode: string;
    city: string;
    state: string;
    address: string;
}

export const geocodeAddress = async (searchQuery: string): Promise<GeocodingResult | null> => {
    try {
        const encodedQuery = encodeURIComponent(searchQuery);
        const response = await fetch(API_ENDPOINTS.MAPBOX(encodedQuery, config.mapboxGlApiKey));
        const data = await response.json();

        const bestMatch = data.features[0];
        if (!bestMatch || !bestMatch.center || bestMatch.center.length < 2) return null;

        const county = bestMatch.context.find((item: { id: string; }) => item.id.startsWith('district.'))?.text || '';
        const zipCode = bestMatch.context.find((item: { id: string; }) => item.id.startsWith('postcode.'))?.text || '';
        const city = bestMatch.context.find((item: { id: string; }) => item.id.startsWith('place.'))?.text || '';
        const state = bestMatch.context.find((item: { id: string; }) => item.id.startsWith('region.'))?.text || '';
        const address = bestMatch.place_name;

        return {
            lat: bestMatch.center[1],
            lng: bestMatch.center[0],
            county,
            zipCode,
            city,
            state,
            address,
        };
    } catch (error) {
        console.error('Geocoding error:', error);
        return null;
    }
};
