import React, { useState, useEffect } from 'react';
import { LocalStorageKeys } from './types';

function useLocalStorage<T>(key: LocalStorageKeys, initialValue: T): [T, React.Dispatch<React.SetStateAction<T>>] {
    const [localStorageData, setLocalStorageData] = useState<T>(() => {
        try {
            const stored = window.localStorage.getItem(key);
            return stored ? (JSON.parse(stored) as T) : initialValue;
        } catch (error) {
            console.error(`Error parsing localStorage key "${key}":`, error);
            return initialValue;
        }
    });

    useEffect(() => {
        try {
            window.localStorage.setItem(key, JSON.stringify(localStorageData));
        } catch (error) {
            console.error(`Error setting localStorage key "${key}":`, error);
        }
    }, [key, localStorageData]);

    return [localStorageData, setLocalStorageData];
}

export default useLocalStorage;
