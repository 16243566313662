import React from 'react';
import { useField, useFormikContext } from 'formik';
import { NSDropdown, NSLabel } from 'bricks';
import { IOptionAnyValue } from 'bricks/types';
import RenderIf from 'components/RenderIf/RenderIf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { DefaultAttributes } from '../../types';
import { useGetAttributes } from '../../hooks/useGetAttributes';

interface DefaultAttributeDropdownProps {
  name: string;
  label: string;
  options: IOptionAnyValue[];
  attribute: DefaultAttributes;
  showRequiredOnly: boolean;
  className?: string;
  onSelect?: (option: IOptionAnyValue, setFieldValue: (field: string, value: any) => void) => void;
  additionalRenderCondition?: boolean;
  additionalRequiredCondition?: boolean;
  isLoading?: boolean;
}

const DefaultAttributeDropdown: React.FC<DefaultAttributeDropdownProps> = ({
    name,
    label,
    options,
    attribute,
    showRequiredOnly,
    className = '',
    onSelect,
    additionalRenderCondition = true,
    additionalRequiredCondition = true,
    isLoading = false,
}) => {
    const { data: attributesConfig } = useGetAttributes();
    const [field, meta] = useField(name);
    const { setFieldValue } = useFormikContext();

    const getAttributeIsRequired = (field: DefaultAttributes): boolean => {
        const attributeConfig = attributesConfig?.find(({ name }) => name === field);
        if (!attributeConfig) return false;
        if (additionalRequiredCondition) return attributeConfig.required && additionalRequiredCondition;
        return attributeConfig.required;
    };

    const isRequired = getAttributeIsRequired(attribute);
    const shouldRender = (!showRequiredOnly || (showRequiredOnly && isRequired)) && additionalRenderCondition;

    const selectedOption = field.value ? options.find(option => option.value === field.value) : undefined;

    const handleSelectOption = (option: IOptionAnyValue) => {
        setFieldValue(name, option.value);
        if (onSelect) {
            onSelect(option, setFieldValue);
        }
    };

    return (
        <RenderIf isTrue={shouldRender}>
            <div className={className}>
                <NSLabel className="text-dark font-weight-normal">
                    {label}
                    {isRequired && <span className="text-danger"> *</span>}
                    {isLoading && (
                        <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            className="ml-2"
                            size="sm"
                        />
                    )}
                </NSLabel>
                <NSDropdown
                    options={options}
                    selectedOption={selectedOption}
                    handleSelectOption={handleSelectOption}
                    isFullWidth
                    placeholder={isLoading ? 'Loading...' : 'Please select one'}
                    disabled={isLoading}
                />
                {meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}
            </div>
        </RenderIf>
    );
};

export default DefaultAttributeDropdown;
