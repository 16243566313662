import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';

import {
    NSInput, NSLabel, NSCard, NSInputNumberStepper,
    NSSwitch,
} from 'bricks';
import { IOptionAnyValue } from 'bricks/types';
import {
    FORM_ROW_CLASS_NAME,
    FORM_LABEL_CLASS_NAME,
    IN_PROGRESS_TEXT,
    mockProFormaUnits,
    MockRentableSquareFootage,
} from 'views/ProFormaTable/constants';
import RenderIf from 'components/RenderIf/RenderIf';
import { numeralFormatter } from 'ns_libs/formatter';
import { getProFormaModelDisplayCards } from 'views/ProFormaTable/helpers';
import { IProFormaValues, IUpdateProFormaKeyValues } from 'views/ProFormaTable/types';
import { useProFormaSocketContext } from '../../socketContext/ProFormaSocketProvider';
import ProFormaSizeParking from './ProFormaSizeParking/ProFormaSizeParking';
import ProFormaSizeAreaFields from './ProFormaSizeAreaFields/ProFormaSizeAreaFields';

export interface IProFormaSizeDetailsProps {
    values: IProFormaValues;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelect: (name: string, option: string | IOptionAnyValue) => void;
    handleOnKeyDown: (event: React.KeyboardEvent<HTMLInputElement> | null, keyValues: IUpdateProFormaKeyValues[]) => void;
}

const ProFormaSizeDetails = ({
    values, handleInputChange, handleSelect, handleOnKeyDown,
}: IProFormaSizeDetailsProps) => {
    const {
        buildableLots,
        handleUpdateProForma,
        proForma,
    } = useProFormaSocketContext();

    const hasGrossBuildableLots = Boolean(buildableLots.grossBuildableAreaLots.length);

    const [isFar, setIsFar] = useState(!hasGrossBuildableLots && proForma.grossBuildableArea === null);
    const [floorAreaVaries, setFloorAreaVaries] = useState(false);

    const displayCards = getProFormaModelDisplayCards(proForma.proFormaModelId, values, mockProFormaUnits, MockRentableSquareFootage, isFar);

    return (
        <Row>
            <Col md={12} lg={8}>
                <ProFormaSizeAreaFields
                    values={values}
                    handleInputChange={handleInputChange}
                    handleSelect={handleSelect}
                    handleOnKeyDown={handleOnKeyDown}
                    isFar={isFar}
                    setIsFar={setIsFar}
                />

                <div className={FORM_ROW_CLASS_NAME}>
                    <NSLabel className={FORM_LABEL_CLASS_NAME}>Buildings</NSLabel>
                    <NSInputNumberStepper
                        name="buildings"
                        value={values.buildings}
                        onChange={(count: number) => handleUpdateProForma([{ key: 'buildings', value: String(count) }])}
                        min={1}
                        inputGroupClassName="w-25"
                        step={1}
                    />
                </div>

                <div className={FORM_ROW_CLASS_NAME}>
                    <NSLabel className={FORM_LABEL_CLASS_NAME}>{values.buildings <= 1 ? 'Stories' : 'Avg. # of stories'}</NSLabel>
                    <NSInputNumberStepper
                        name="averageStories"
                        value={values.averageStories}
                        onChange={(count: number) => handleUpdateProForma([{ key: 'averageStories', value: String(count) }])}
                        min={1}
                        inputGroupClassName="w-25"
                        step={1}
                    />
                </div>
                <RenderIf isTrue={values.buildings === 1 && values.averageStories > 1}>
                    <div className={FORM_ROW_CLASS_NAME}>
                        <div className={FORM_LABEL_CLASS_NAME} />
                        <NSSwitch
                            name="floorAreaVaries"
                            id="1"
                            checked={floorAreaVaries}
                            containerClassName="pr-1"
                            onChange={() => setFloorAreaVaries(!floorAreaVaries)}
                        />
                        <NSLabel className={FORM_LABEL_CLASS_NAME}>
                            My floor area varies
                        </NSLabel>
                    </div>
                </RenderIf>

                <div className={FORM_ROW_CLASS_NAME}>
                    <NSLabel className={FORM_LABEL_CLASS_NAME}>Built gross SF</NSLabel>
                    <NSInput
                        type="number"
                        name="builtGrossSquareFootage"
                        value={values.builtGrossSquareFootage}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                        onBlur={() => handleUpdateProForma([{ key: 'builtGrossSquareFootage', value: values.builtGrossSquareFootage }])}
                        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                            handleOnKeyDown(event, [{ key: 'builtGrossSquareFootage', value: values.builtGrossSquareFootage }]);
                        }}
                        placeholder="Enter built gross SF"
                        inputGroupClassName="p-0"
                        appendInputAddon={<div className="px-2">ft&sup2;</div>}
                    />
                </div>

                <ProFormaSizeParking values={values} />

            </Col>
            <Col md={12} lg={4}>
                <Row className="mx-n1">
                    {displayCards.map(({
                        label, value, format, valueSuffix, fullWidth,
                    }) => (
                        <Col className="px-1 mb-2" md={12} lg={fullWidth ? 12 : 6} key={label}>
                            <NSCard className="NSCard--level-3 p-2 h-100">
                                <div>{label}</div>
                                <RenderIf isTrue={!value}>
                                    <div className="text-dark font-italic">{IN_PROGRESS_TEXT}</div>
                                </RenderIf>
                                <RenderIf isTrue={!!value}>
                                    <div className="text-dark">
                                        {numeralFormatter(value, format)}
                                        {valueSuffix && (
                                            <span>
                                                &nbsp;
                                                {valueSuffix}
                                            </span>
                                        )}
                                    </div>
                                </RenderIf>
                            </NSCard>
                        </Col>
                    ))}
                </Row>
            </Col>
        </Row>
    );
};

export default ProFormaSizeDetails;
