/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import {
    createBrowserRouter, RouterProvider, createRoutesFromElements, Route, Outlet,
} from 'react-router-dom';
import LayoutWithNavbar from 'components/LayoutWithNavbar/LayoutWithNavbar';
import DealDashboard from 'views/DealDashboard/DealDashboard';
import CreateDeal from 'views/Deals/CreateDeal/CreateDeal';
import ScenarioComparison from 'views/ScenarioComparison/ScenarioComparison';
import ProFormaWizard from 'views/ProFormaWizard/ProFormaWizard';
import ReportBuilder from 'views/ReportBuilder/ReportBuilder';
import { FEATURE_IDS } from 'services/constants';
import Documents from 'views/DocumentLibrary/Documents';
import { useAuth0TokenContext } from 'contexts/Auth0TokenContext';
import { NSLoadingScreen } from 'bricks';
import { AuthProvider } from 'contexts/AuthContext';
import { ToastContainer } from 'react-toastify';
import { Button } from 'reactstrap';
import ProFormaUpload from 'views/ProFormaUploaded/ProFormaUpload';
import ProFormaUploaded from 'views/ProFormaUploaded/ProFormaUploaded';
import ProFormaTable from './views/ProFormaTable/ProFormaTable';
import Reports from './views/Reports/Reports';
import Counter from './views/Counter/Counter.jsx';
import NSRoute from './components/NSRoute/NSRoute';
import Deals from './views/Deals/Deals';
import { URLS } from './services/urls';
import ProFormaHome from './views/ProFormaHome/ProFormaHome';
import { ReportPageRouter } from './views/Reports/ReportPageRouter';
import { BackOfEnvelopeProvider } from './contexts/BackOfEnvelopeContext';
import { ReportPreview } from './views/Reports/ReportTypes/ReportPreview';

const NotFound = () => <div>404 - Page Not Found</div>;

// Needed so that navbar and deals dashboard have acces to router params (dealId)
const BackOfEnvelopeWrapper = () => (
    <BackOfEnvelopeProvider>
        <Outlet />
    </BackOfEnvelopeProvider>
);

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route
                element={(
                    <BackOfEnvelopeProvider>
                        <LayoutWithNavbar />
                    </BackOfEnvelopeProvider>
                )}
            >
                <Route path="/" element={<Counter />} />
                <Route element={<NSRoute />}>
                    <Route path={URLS.REPORTS.HOME} element={<Reports />} handle={{ featureIds: [FEATURE_IDS.REPORTING_ACROSS_DEALS] }} />
                    <Route path={URLS.REPORTS.NEW} element={<ReportPageRouter />} handle={{ featureIds: [FEATURE_IDS.REPORTING_ACROSS_DEALS] }} />
                    <Route path={URLS.REPORTS.REPORT_PAGE} element={<ReportPageRouter />} handle={{ featureIds: [FEATURE_IDS.REPORTING_ACROSS_DEALS] }} />
                    <Route path={URLS.REPORTS.REPORT_PREVIEW} element={<ReportPreview />} handle={{ featureIds: [FEATURE_IDS.REPORTING_ACROSS_DEALS] }} />
                    <Route path={URLS.REPORT_BUILDER} element={<ReportBuilder />} handle={{ featureIds: [FEATURE_IDS.DEAL_REPORT_BUILDER] }} />
                    <Route path={URLS.DEALS.HOME} element={<Deals />} handle={{ featureIds: [FEATURE_IDS.DEAL_PIPELINE] }} />
                    <Route element={<BackOfEnvelopeWrapper />}>
                        <Route path={URLS.DEAL_DASHBOARD.HOME} element={<DealDashboard />} handle={{ featureIds: [FEATURE_IDS.DEAL_DASHBOARD] }} />
                        <Route path={URLS.PRO_FORMA.HOME} element={<ProFormaHome />} handle={{ featureIds: [FEATURE_IDS.PRO_FORMA] }} />
                        <Route path={URLS.PRO_FORMA.TABLE} element={<ProFormaTable />} handle={{ featureIds: [FEATURE_IDS.PRO_FORMA] }} />
                    </Route>
                    <Route path={URLS.DEALS.COMPARISON} element={<ScenarioComparison />} handle={{ featureIds: [FEATURE_IDS.BACK_OF_ENVELOPE] }} />
                    <Route path={URLS.DEALS.FORM} element={<CreateDeal />} handle={{ featureIds: [FEATURE_IDS.DEAL_PIPELINE] }} />
                    <Route path={URLS.PRO_FORMA.HOME} element={<ProFormaHome />} handle={{ featureIds: [FEATURE_IDS.PRO_FORMA] }} />
                    <Route path={URLS.DOCUMENTS} element={<Documents />} handle={{ featureIds: [FEATURE_IDS.PRO_FORMA] }} />
                    <Route path={URLS.PRO_FORMA.TABLE} element={<ProFormaTable />} handle={{ featureIds: [FEATURE_IDS.PRO_FORMA] }} />
                    <Route path={URLS.PRO_FORMA.WIZARD} element={<ProFormaWizard />} handle={{ featureIds: [FEATURE_IDS.PRO_FORMA] }} />
                    <Route path={URLS.PRO_FORMA.UPLOAD} element={<ProFormaUpload />} handle={{ featureIds: [FEATURE_IDS.PRO_FORMA] }} />
                    <Route path={URLS.PRO_FORMA.UPLOADED} element={<ProFormaUploaded />} handle={{ featureIds: [FEATURE_IDS.PRO_FORMA] }} />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Route>
        </>,
    ),
);

const AppRouter = () => {
    const { isLoading, isAuthenticated, login } = useAuth0TokenContext();

    const PublicComponent = (
        <div className="pt-5 pl-4">
            <header className="App-header mb-4">
                <p>Welcome to Northspyre Deal</p>
                <Button className="mx-2" onClick={login}>
                    Login
                </Button>
            </header>
        </div>
    );

    if (isLoading) {
        return <NSLoadingScreen />;
    }

    if (!isAuthenticated) {
        return PublicComponent;
    }

    return (
        <AuthProvider>
            <RouterProvider router={router} />
            <ToastContainer style={{ whiteSpace: 'break-spaces' }} />
        </AuthProvider>
    );
};

export default AppRouter;
