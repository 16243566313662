import React from 'react';
import { useField, useFormikContext } from 'formik';
import {
    NSInput, NSDropdown, NSLabel, NSSelectWithCheckboxes,
} from 'bricks';
import RenderIf from 'components/RenderIf/RenderIf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollar, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CUSTOM_ATTRIBUTE_IS_REQUIRED_ERROR_SEPARATOR, CUSTOM_ATTRIBUTE_IS_REQUIRED_ERROR_SUFFIX } from 'components/DealForm/utils';
import { IDealAttribute, IFormValues } from '../../types';

interface CustomAttributeInputProps {
    attribute: IDealAttribute;
    showRequiredOnly?: boolean;
    className?: string;
    isLoading?: boolean;
    additionalCondition?: boolean;
}

const CustomAttributeInput: React.FC<CustomAttributeInputProps> = ({
    attribute,
    showRequiredOnly = false,
    className = '',
    isLoading = false,
    additionalCondition = true,
}) => {
    const { values, setFieldValue } = useFormikContext<IFormValues>();
    const [, meta] = useField('customAttributes');

    const getFieldError = () => {
        if (!meta.error || !meta.touched) return null;
        if (typeof meta.error === 'string') {
            const errors = meta.error.split(CUSTOM_ATTRIBUTE_IS_REQUIRED_ERROR_SEPARATOR);
            const attributeError = errors.find(error => error.startsWith(attribute.name + CUSTOM_ATTRIBUTE_IS_REQUIRED_ERROR_SUFFIX));
            return attributeError || null;
        }
        if (typeof meta.error === 'object' && meta.error[attribute.correlationId]) {
            return meta.error[attribute.correlationId];
        }
        return null;
    };

    const shouldRender = (!showRequiredOnly || (showRequiredOnly && attribute.required)) && additionalCondition;

    const handleChange = (value: string | number | string[]) => {
        const customAttributeValue = {
            attributeId: attribute.correlationId,
            ...(attribute.inputType === 'text' && { valueText: value as string }),
            ...(attribute.inputType === 'number' && { valueNumber: Number(value) }),
            ...(attribute.inputType === 'amount' && { valueNumber: Number(value) }),
            ...(attribute.inputType === 'select' && { valueSingleSelect: value as string }),
            ...(attribute.inputType === 'multi_select' && { valueMultiSelect: value as string[] }),
        };

        const currentCustomAttributes = values.customAttributes || [];
        const attributeIndex = currentCustomAttributes.findIndex(
            attr => attr.attributeId === attribute.correlationId,
        );

        if (attributeIndex >= 0) {
            const newCustomAttributes = [...currentCustomAttributes];
            newCustomAttributes[attributeIndex] = customAttributeValue;
            setFieldValue('customAttributes', newCustomAttributes);
        } else {
            setFieldValue('customAttributes', [...currentCustomAttributes, customAttributeValue]);
        }
    };

    const getCurrentValue = () => {
        const currentCustomAttributes = values.customAttributes || [];
        const currentAttribute = currentCustomAttributes.find(
            attr => attr.attributeId === attribute.correlationId,
        );

        if (!currentAttribute) return '';

        switch (attribute.inputType) {
            case 'text': return currentAttribute.valueText || '';
            case 'number':
            case 'amount': return currentAttribute.valueNumber || '';
            case 'select': return currentAttribute.valueSingleSelect || '';
            case 'multi_select': return currentAttribute.valueMultiSelect || [];
            default: return '';
        }
    };

    const getInputValue = () => {
        const value = getCurrentValue();
        if (Array.isArray(value)) return '';
        return value;
    };

    const renderDropdown = () => {
        const options = attribute.options
            ?.filter((opt): opt is string => opt !== undefined && opt !== null)
            ?.map(opt => ({ label: opt, value: opt })) || [];

        if (attribute.inputType === 'multi_select') {
            const currentValue = getCurrentValue();
            const selectedOptions = currentValue && Array.isArray(currentValue)
                ? currentValue
                    .filter((val): val is string => val !== undefined && val !== null)
                    .map(val => ({ label: val, value: val }))
                : [];

            return (
                <>
                    <NSLabel className="text-dark font-weight-normal">
                        {attribute.name}
                        {attribute.required && <span className="text-danger"> *</span>}
                        {isLoading && <FontAwesomeIcon icon={faSpinner} spin className="ml-2" size="sm" />}
                    </NSLabel>
                    <NSSelectWithCheckboxes
                        name={`customAttributes.${attribute.correlationId}`}
                        options={options}
                        value={selectedOptions}
                        onChange={selected => handleChange(selected.map(opt => opt.value))}
                        isDisabled={isLoading}
                        placeholder="Select options"
                        withSelectAllOption
                        isNSMulti
                        isClearable={false}
                        menuPlacement="top"
                    />
                    {getFieldError() && <div className="text-danger">{getFieldError()}</div>}
                </>
            );
        }

        const currentValue = getCurrentValue();
        const selectedOption = currentValue && typeof currentValue === 'string'
            ? { label: currentValue, value: currentValue }
            : undefined;

        return (
            <>
                <NSLabel className="text-dark font-weight-normal">
                    {attribute.name}
                    {attribute.required && <span className="text-danger"> *</span>}
                    {isLoading && <FontAwesomeIcon icon={faSpinner} spin className="ml-2" size="sm" />}
                </NSLabel>
                <NSDropdown
                    options={options}
                    selectedOption={selectedOption}
                    handleSelectOption={option => handleChange(option.value)}
                    isFullWidth
                    placeholder={isLoading ? 'Loading...' : 'Please select one'}
                    disabled={isLoading}
                />
                {getFieldError() && <div className="text-danger">{getFieldError()}</div>}
            </>
        );
    };

    const renderInput = () => (
        <NSInput
            label={attribute.name}
            type={attribute.inputType === 'number' || attribute.inputType === 'amount' ? 'number' : 'text'}
            value={getInputValue()}
            name={`customAttributes.${attribute.correlationId}`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
            required={attribute.required}
            prependInputAddon={attribute.inputType === 'amount' ? <FontAwesomeIcon icon={faDollar} /> : undefined}
            errorMsg={getFieldError()}
            showErrorMsg={!!getFieldError()}
            disabled={isLoading}
        />
    );

    return (
        <RenderIf isTrue={shouldRender}>
            <div className={className}>
                {(attribute.inputType === 'select' || attribute.inputType === 'multi_select')
                    ? renderDropdown()
                    : renderInput()}
            </div>
        </RenderIf>
    );
};

export default CustomAttributeInput;
