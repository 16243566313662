import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { useMutation } from '@tanstack/react-query';
import { IAssociateDealsToDealGroupVariables, IErrorResponse } from '../types';

const associateDealsToDealGroup = async ({ postData, orgId }: IAssociateDealsToDealGroupVariables): Promise<string> => {
    try {
        const response = await axiosInstance.post<string>(`${API_ENDPOINTS.ASSOCIATE_DEALS_GROUPS(orgId)}`, postData);
        return response.data;
    } catch (error: any) {
        if (error.response.status !== 500) {
            const errorData: IErrorResponse = error.response.data;
            throw new Error(errorData.detail);
        }
        throw new Error('An unexpected error occurred. Please try again.');
    }
};

export const useAssociateDealsToDealGroup = () => useMutation<string, Error, IAssociateDealsToDealGroupVariables>({
    mutationFn: associateDealsToDealGroup,
});
