import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS from 'services/urls';
import { SensitivityAnalysisParams, SensitivityAnalysisResponse } from '../types';

export const getProFormaSensitivityAnalysis = async (
    dealId: number,
    proFormaId: number,
    params: SensitivityAnalysisParams,
): Promise<SensitivityAnalysisResponse> => {
    const response = await axiosInstance.get(
        API_ENDPOINTS.PRO_FORMA_SENSITIVITY_ANALYSIS(dealId, proFormaId),
        {
            params: {
                parameters: params.parameters,
                firstParameterStepMultiplier: params.firstParameterStepMultiplier || 1,
                secondParameterStepMultiplier: params.secondParameterStepMultiplier || 1,
            },
        },
    );
    return response.data;
};

export const useGetProFormaSensitivityAnalysis = (
    dealId: number,
    proFormaId: number,
    params: SensitivityAnalysisParams,
    options: { enabled: boolean } = { enabled: true },
) => useQuery({
    queryKey: ['ProFormaSensitivityAnalysis', dealId, proFormaId, params],
    queryFn: () => getProFormaSensitivityAnalysis(dealId, proFormaId, params),
    ...options,
});

export default useGetProFormaSensitivityAnalysis;
