import React, { MouseEventHandler, useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp, IconProp } from '@fortawesome/fontawesome-svg-core';
import useAuthContext from 'contexts/AuthContext';
import { AccessLevels } from 'types/auth';
import { BUTTON_DISABLED_BY_AUTH_TOOLTIP, userClasses } from 'constants/auth';
import RenderIf from '../../components/RenderIf/RenderIf';
import { subprojectLifeStages, NSBUTTON_SIZES } from '../constants';

type IconPosition = 'left' | 'right';

export interface INSButtonProps {
    id?: string;
    color?: string;
    text?: string;
    icon?: IconProp;
    iconPosition?: IconPosition;
    size?: SizeProp;
    disabled?: boolean;
    disabledTooltip?: string | React.JSX.Element; // must provide a unique button `id` to show
    tooltipAutohide?: boolean;
    callback?: Function;
    tooltip?: string | React.JSX.Element;
    className?: string;
    innerContentClassName?: string;
    includeTooltip?: boolean;
    allowReadOnlyAccess?: boolean;
    subprojectLifeStage?: number;
    iconOnly?: boolean;
    outline?: boolean;
    buttonType?: string;
    iconSize?: SizeProp | undefined;
    children?: React.ReactNode;
    formId?: string;
    onMouseDown?: MouseEventHandler<HTMLButtonElement>;
    isLoading?: boolean;
    dataTestId?: string;
    ariaLabel?: string;
    featureId?: number;
}

const NSButton = ({
    id = '',
    color = '',
    text = '',
    icon,
    iconPosition = 'left',
    size,
    disabled = false,
    disabledTooltip = '',
    tooltipAutohide = true,
    callback = () => false,
    tooltip = '',
    className = '',
    innerContentClassName,
    includeTooltip = true,
    allowReadOnlyAccess = false,
    subprojectLifeStage = subprojectLifeStages.ACTIVE,
    iconOnly = false,
    outline = false,
    buttonType = 'button',
    iconSize = 'sm',
    children = null,
    formId,
    onMouseDown = () => {},
    isLoading = false,
    dataTestId,
    ariaLabel,
    featureId,
    ...rest
}: INSButtonProps) => {
    const [hover, setHover] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const {
        isFeatureAccessibleToUser,
        isLoadingAuthData,
        role,
        user,
    } = useAuthContext();
    let btnIsDisabled = disabled;
    let currentDisabledTooltip = disabledTooltip || '';
    const btnDataIsLoading = isLoading || isLoadingAuthData;

    const toggleHover = () => setHover(!hover);

    const toggleTooltip = () => setShowTooltip(!showTooltip);

    const btnColor = color || '';
    const btnText = text || '';
    let btnTitle = '';
    const btnIcon = icon || null;
    const btnIconSize = iconSize || 'sm';
    const btnId = id || '';
    let btnTooltip: string | React.JSX.Element | null = tooltip || null;

    const loadingSpinner = () => (
        <span
            data-testid="loading-spinner"
            className={classNames('spinner-border', {
                'spinner-border-sm': size !== NSBUTTON_SIZES.LARGE,
            })}
            style={size === NSBUTTON_SIZES.LARGE ? { height: '25px', width: '25px' } : {}}
        />
    );

    const iconOnlyCallback = () => {
        if (callback && !btnIsDisabled && !btnDataIsLoading) {
            callback();
        }
    };

    if (featureId) {
        let requiredAccessLevel = AccessLevels.READ_ACCESS;
        if (!allowReadOnlyAccess) {
            requiredAccessLevel = AccessLevels.READ_WRITE_ACCESS;
        }
        const userHasAccess = isFeatureAccessibleToUser([featureId], requiredAccessLevel);
        if (!userHasAccess) {
            btnIsDisabled = true;
            currentDisabledTooltip = BUTTON_DISABLED_BY_AUTH_TOOLTIP;
        }
    } else if (!allowReadOnlyAccess) {
        if (role?.isReadOnly && user?.userClass !== userClasses.CLASS_SUPER_ADMIN) {
            btnIsDisabled = true;
            currentDisabledTooltip = BUTTON_DISABLED_BY_AUTH_TOOLTIP;
        }
    }

    if (btnIsDisabled) {
        btnTitle = (btnTooltip as string) || '';
        btnTooltip = currentDisabledTooltip || '';
    }

    return (
        <>
            <RenderIf isTrue={!iconOnly}>
                {/* wrapper workaround for showing tooltips when button is disabled. */}
                <span id={`${btnId}-tooltip-wrapper`} className="tooltip-wrapper" data-testid="tooltip-wrapper">
                    <Button
                        id={btnId}
                        onMouseEnter={toggleHover}
                        onMouseLeave={toggleHover}
                        onMouseDown={onMouseDown}
                        color={btnColor}
                        outline={outline}
                        disabled={btnIsDisabled || btnDataIsLoading}
                        size={size}
                        onClick={callback as React.MouseEventHandler<HTMLButtonElement> | undefined}
                        onKeyDown={callback as React.KeyboardEventHandler<HTMLButtonElement> | undefined}
                        title={btnTitle}
                        className={`NSButton rounded ${className || ''}`}
                        type={buttonType as 'button' | 'reset' | 'submit' | undefined}
                        data-testid={dataTestId}
                        aria-label={ariaLabel}
                        {...(formId && { form: formId })}
                        {...rest}
                    >
                        <span className={innerContentClassName}>
                            <RenderIf isTrue={btnDataIsLoading}>{loadingSpinner()}</RenderIf>

                            <RenderIf isTrue={!btnDataIsLoading}>
                                <RenderIf isTrue={btnIcon && iconPosition === 'left'}>
                                    <FontAwesomeIcon className={`icon ${btnText ? 'mr-1' : ''}`} icon={btnIcon as IconProp} size={btnIconSize} />
                                </RenderIf>

                                {children || btnText}

                                <RenderIf isTrue={btnIcon && iconPosition === 'right'}>
                                    <FontAwesomeIcon className={`icon ${btnText ? 'ml-1' : ''}`} icon={btnIcon as IconProp} size={btnIconSize} />
                                </RenderIf>
                            </RenderIf>
                        </span>
                    </Button>
                </span>

                <RenderIf isTrue={btnId && btnTooltip && includeTooltip}>
                    <Tooltip target={`${btnId}-tooltip-wrapper`} isOpen={showTooltip} toggle={toggleTooltip} autohide={tooltipAutohide}>
                        {btnTooltip}
                    </Tooltip>
                </RenderIf>
            </RenderIf>

            <RenderIf isTrue={iconOnly}>
                <FontAwesomeIcon
                    className={className}
                    icon={btnIcon as IconProp}
                    size={btnIconSize}
                    id={btnId}
                    onClick={iconOnlyCallback}
                    onKeyDown={iconOnlyCallback}
                    data-testid={dataTestId}
                    aria-label={ariaLabel}
                    aria-hidden={false}
                    focusable
                    {...rest}
                />

                <RenderIf isTrue={btnTooltip && btnId && includeTooltip}>
                    <Tooltip target={btnId as string | HTMLElement | React.RefObject<HTMLElement>} isOpen={showTooltip} toggle={toggleTooltip}>
                        {btnTooltip}
                    </Tooltip>
                </RenderIf>
            </RenderIf>
        </>
    );
};

export default NSButton;
