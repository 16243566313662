import React from 'react';
import { NSButton } from 'bricks';
import { faUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import PreviewFile from 'components/PreviewFile/PreviewFile';
import './DocumentLibrarySlideout.scss';

interface DocumentPreviewProps {
    uri?: string;
    format?: string;
    handleOpenDocument: () => void;
    minHeight?: number;
}

const DocumentPreview: React.FC<DocumentPreviewProps> = ({
    uri, format, handleOpenDocument, minHeight,
}) => (
    <div className="Content__preview p-2 pl-3 d-flex flex-column h-100">
        <div className="pb-1 pr-0 ml-auto">
            <NSButton callback={handleOpenDocument} color="secondary" icon={faUpRightFromSquare} />
        </div>
        <div className="flex-grow-1">
            <PreviewFile uri={uri} type={format} minHeight={minHeight} />
        </div>
    </div>
);

export default DocumentPreview;
