import { IDispositionData } from 'views/ProFormaTable/types';
import { formatValueOrDash } from 'views/ProFormaTable/components/ProFormaGenericTable/ProFormaGenericTable';

const formatValue = (value: number | undefined): string => {
    if (value === undefined || value === null) return formatValueOrDash('');
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

interface ColumnHeaderText {
    title: string;
    subtitlePrefix?: string;
    subtitle: string;
}

// TODO: Get proper period numbers
export const getValuationColumnHeaderText = (key: string): ColumnHeaderText => {
    switch (key) {
        case 'untrended':
            return {
                title: 'Untrended',
                subtitle: '(Today)',
            };
        case 'trended':
            return {
                title: 'Trended',
                subtitle: '(Period 33 - 44)',
            };
        case 'sale':
            return {
                title: 'Sale',
                subtitle: '(Period 45 - 56)',
            };
        default:
            return {
                title: '',
                subtitle: '',
            };
    }
};

export const getStabilizationColumnHeaderText = (key: string, disposition?: IDispositionData): ColumnHeaderText => {
    const defaultValue = formatValueOrDash('');

    if (!disposition?.stabilizedValue || !disposition?.stabilizedValuePerSf) {
        return {
            title: defaultValue,
            subtitlePrefix: '$ / SF: ',
            subtitle: defaultValue,
        };
    }

    switch (key) {
        case 'untrended': {
            return {
                title: formatValue(disposition.stabilizedValue.untrended),
                subtitlePrefix: '$ / SF: ',
                subtitle: formatValue(disposition.stabilizedValuePerSf.untrended),
            };
        }
        case 'trended': {
            return {
                title: formatValue(disposition.stabilizedValue.trended),
                subtitlePrefix: '$ / SF: ',
                subtitle: formatValue(disposition.stabilizedValuePerSf.trended),
            };
        }
        case 'sale': {
            return {
                title: formatValue(disposition.stabilizedValue.sale),
                subtitlePrefix: '$ / SF: ',
                subtitle: formatValue(disposition.stabilizedValuePerSf.sale),
            };
        }
        default:
            return {
                title: defaultValue,
                subtitlePrefix: '$ / SF: ',
                subtitle: defaultValue,
            };
    }
};
