import React from 'react';
import './Returns.scss';
import ProFormaReturnsTable from 'components/ProFormaReturnsTable/ProFormaReturnsTable';
import { IOptionAnyValue } from 'bricks/types';
import { useProFormaSocketContext } from '../../ProForma/socketContext/ProFormaSocketProvider';
import { transformReturnsData } from './helpers';

export interface IReturnsProps {
    selectedPeriod: IOptionAnyValue;
}

const Returns = ({ selectedPeriod }: IReturnsProps) => {
    const { returnsData } = useProFormaSocketContext();

    const transformedData = React.useMemo(() => transformReturnsData(returnsData), [returnsData]);

    return (
        <div className="Returns">
            {transformedData && (
                <ProFormaReturnsTable
                    proFormaReturns={transformedData.proFormaReturns}
                    cashFlowData={transformedData.cashFlowData}
                    costMetrics={transformedData.costMetrics}
                    selectedPeriod={selectedPeriod}
                />
            )}
        </div>
    );
};

export default Returns;
