import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import Map from 'components/Map/Map';
import { useMapCoordinates } from 'hooks/useMapCoordinates';
import DataPanel from './components/DataPanel';
import { Deal } from '../types';
import './MapView.scss';

interface IMapViewProps {
    selectedDeal: Deal;
}

const MapView = ({ selectedDeal }: IMapViewProps) => {
    const [showMap, setShowMap] = useState(false);
    const { lat, lng } = useMapCoordinates(selectedDeal);

    useEffect(() => {
        const timer = setTimeout(() => setShowMap(true), 100);
        return () => clearTimeout(timer);
    }, [selectedDeal]);

    return (
        <Row className="MapView pt-2 pl-2 pr-2 pb-0">
            <Col xs="6" className="d-block p-0">
                {showMap && <Map data-testid="map" lng={lng} lat={lat} zoom={10} height="370px" />}
            </Col>
            <Col xs="6" className="p-0">
                <DataPanel data-testid="data-panel" detail={selectedDeal} />
            </Col>
        </Row>
    );
};

export default MapView;
