import { useState, useEffect, useRef } from 'react';
import { TUnit } from '../types';

interface UseUnitManagerProps {
    initialUnits?: TUnit[];
    proFormaModelId: number;
}

const normalizeUnit = (unit: TUnit) => ({
    ...unit,
    id: unit.id || Date.now(),
    name: unit.name || 'Default name',
    averageSquareFootage: Number(unit.averageSquareFootage) || 0,
    amountPerMonth: Number(unit.amountPerMonth) || 0,
    units: Number(unit.units) || 0,
    totalRent: Number(unit.totalRent) || 0,
});

export const useUnitManager = ({ initialUnits = [] }: UseUnitManagerProps) => {
    const [unitArray, setUnitArray] = useState<TUnit[]>(() => {
        if (initialUnits.length > 0) {
            return initialUnits.map(normalizeUnit);
        }
        return [{ id: Date.now(), name: 'Default name' }];
    });

    const lastInitialUnitsRef = useRef<string>('');

    useEffect(() => {
        const currentInitialUnitsStr = JSON.stringify(initialUnits);
        if (currentInitialUnitsStr !== lastInitialUnitsRef.current && initialUnits.length > 0) {
            lastInitialUnitsRef.current = currentInitialUnitsStr;
            const newUnits = initialUnits.map(normalizeUnit);
            setUnitArray(newUnits);
        } else if (initialUnits.length === 0 && unitArray.length === 0) {
            setUnitArray([{ id: Date.now(), name: 'Default name' }]);
        }
    }, [initialUnits]);

    const handleDuplicateOrAdd = (unitId?: number) => {
        setUnitArray(prevArray => {
            if (!unitId) {
                return [...prevArray, { id: Date.now(), name: 'Default name' }];
            }

            const unitToDuplicate = prevArray.find(unit => unit.id === unitId);
            if (!unitToDuplicate) return prevArray;

            const duplicatedUnit = {
                ...unitToDuplicate,
                id: Date.now(),
            };
            return [...prevArray, duplicatedUnit];
        });
    };

    const handleDelete = (unitId: number) => {
        setUnitArray(prevArray => prevArray.filter(unit => unit.id !== unitId));
    };

    const handleUpdate = (updatedUnit: TUnit) => {
        setUnitArray(prevArray => prevArray.map(unit => {
            if (unit.id === updatedUnit.id) {
                return normalizeUnit(updatedUnit);
            }
            return unit;
        }));
    };

    return {
        unitArray,
        handleDuplicateOrAdd,
        handleDelete,
        handleUpdate,
        setUnitArray,
    };
};
