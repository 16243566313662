import { ProFormaGenericColumn, ProFormaGenericRow } from 'views/ProFormaTable/components/ProFormaGenericTable/types';

export const MILL_RATES = {
    untrended: '1.10',
    trended: '1.20',
    sale: '1.23',
} as const;

export const INITIAL_REVENUE_RENTAL_INCOME_ROWS: ProFormaGenericRow[] = [
    {
        id: 'gross-rent',
        name: 'Gross rent',
        untrended: '6436800',
        trended: '6573619',
        sale: '6770271',
    },
    {
        id: 'concessions',
        name: 'Concessions',
        untrended: '-362714',
        trended: '-370991',
        sale: '-382093',
        isNegative: true,
    },
    {
        id: 'non-revenue-units',
        name: 'Non-revenue units',
        untrended: '-362714',
        trended: '-370991',
        sale: '-382093',
        isNegative: true,
    },
];

export const INITIAL_REVENUE_POTENTIAL_INCOME_ROWS: ProFormaGenericRow[] = [
    {
        id: 'rubs',
        name: 'RUBS',
        untrended: '817472',
        trended: '846206',
        sale: '871592',
    },
    {
        id: 'parking',
        name: 'Parking income',
        untrended: '817472',
        trended: '846206',
        sale: '871592',
    },
];

export const INITIAL_EFFECTIVE_GROSS_REVENUE_ROWS: ProFormaGenericRow[] = [
    {
        id: 'general-vacancy',
        name: 'General vacancy',
        untrended: '-362714',
        trended: '-370991',
        sale: '-382093',
        isNegative: true,
    },
    {
        id: 'credit-loss',
        name: 'Credit loss',
        untrended: '-72543',
        trended: '-74198',
        sale: '-76419',
        isNegative: true,
    },
];

export const INITIAL_OPERATING_EXPENSES_ROWS: ProFormaGenericRow[] = [
    {
        id: 'contract-services',
        name: 'Contract services',
        untrended: '105000',
        trended: '108691',
        sale: '111951',
    },
    {
        id: 'general-admin',
        name: 'General and administrative',
        untrended: '240000',
        trended: '248436',
        sale: '255889',
    },
    {
        id: 'insurance',
        name: 'Insurance',
        untrended: '105000',
        trended: '108691',
        sale: '111951',
    },
    {
        id: 'make-ready',
        name: 'Make ready cost',
        untrended: '130000',
        trended: '134570',
        sale: '138607',
    },
    {
        id: 'management-fee',
        name: 'Management fee',
        untrended: '204570',
        trended: '209239',
        sale: '215501',
    },
    {
        id: 'marketing',
        name: 'Marketing',
        untrended: '130000',
        trended: '134570',
        sale: '138607',
    },
    {
        id: 'payroll',
        name: 'Payroll',
        untrended: '395100',
        trended: '408988',
        sale: '421257',
    },
    {
        id: 'property-taxes',
        name: 'Property taxes',
        untrended: '862377',
        trended: '892689',
        sale: '919470',
    },
    {
        id: 'repairs',
        name: 'Repairs and maintenance',
        untrended: '315000',
        trended: '326072',
        sale: '335854',
    },
    {
        id: 'utilities',
        name: 'Utilities',
        untrended: '280000',
        trended: '289842',
        sale: '298537',
    },
];

export const INITIAL_CAPITAL_EXPENDITURES_ROWS: ProFormaGenericRow[] = [
    {
        id: 'miscellaneous-capital-reserves',
        name: 'Miscellaneous capital reserves',
        untrended: '60000',
        trended: '62109',
        sale: '63972',
    },
    {
        id: 'other-capital-expenditures',
        name: 'Other capital expenditures',
        untrended: '25000',
        trended: '25879',
        sale: '26655',
    },
];

export const BASE_FORECAST_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'name',
        field: 'name',
        header: 'Revenue',
        format: 'none',
        isReadOnly: true,
    },
    {
        key: 'invisibleSF', field: 'invisibleSF', header: '', isHidden: true,
    },
    {
        key: 'untrended',
        field: 'untrended',
        header: '',
        format: 'currency',
        isReadOnly: true,
    },
    {
        key: 'trended',
        field: 'trended',
        header: '',
        format: 'currency',
        isReadOnly: true,
    },
    {
        key: 'sale',
        field: 'sale',
        header: '',
        format: 'currency',
        isReadOnly: true,
    },
];

export const TOTAL_POTENTIAL_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'name', field: 'name', header: 'Net operating income', format: 'none',
    },
    {
        key: 'invisibleUnits', field: 'invisibleUnits', header: '', isHidden: true,
    },
    {
        key: 'untrended',
        field: 'untrended',
        header: 'Untrended',
        format: 'currency',
        isReadOnly: true,
    },
    {
        key: 'trended',
        field: 'trended',
        header: 'Trended',
        format: 'currency',
        isReadOnly: true,
    },
    {
        key: 'sale',
        field: 'sale',
        header: 'Sale',
        format: 'currency',
        isReadOnly: true,
    },
];

export const CASH_FLOW_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'name', field: 'name', header: 'Cash flow from operations', format: 'none',
    },
    {
        key: 'invisibleUnits', field: 'invisibleUnits', header: '', isHidden: true,
    },
    {
        key: 'untrended',
        field: 'untrended',
        header: 'Untrended',
        format: 'currency',
        isReadOnly: true,
    },
    {
        key: 'trended',
        field: 'trended',
        header: 'Trended',
        format: 'currency',
        isReadOnly: true,
    },
    {
        key: 'sale',
        field: 'sale',
        header: 'Sale',
        format: 'currency',
        isReadOnly: true,
    },
];
