import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useAuthContext } from 'contexts/AuthContext';
import { NSLabel, NSModal } from 'bricks';
import { useDeleteDeal } from 'views/Deals/hooks/useDeleteDeal';
import useToast from 'hooks/useToast';
import { Deal } from 'views/Deals/types';
import { DEAL_MESSAGES } from 'views/Deals/constants/messages';

export interface IDeleteDealModalProps {
    toggle: () => void;
    isOpen: boolean;
    deals: Deal[];
    onDeleteSuccess?: () => void;
}

const DeleteDealModal = ({
    toggle, isOpen, deals, onDeleteSuccess,
}: IDeleteDealModalProps) => {
    const { selectedOrganizationId } = useAuthContext();
    const queryClient = useQueryClient();

    const { mutateAsync: deleteDeal, error: deleteError } = useDeleteDeal();
    const { showSuccess, showError } = useToast();

    const handleDelete = async () => {
        if (!deals || deals.length === 0) {
            showError(DEAL_MESSAGES.DELETE.ERROR_NO_SELECTION);
            return;
        }

        try {
            await Promise.all(deals.map(deal => deleteDeal({ organizationId: selectedOrganizationId!, dealId: deal.id })));

            const successMessage = deals.length === 1 ? DEAL_MESSAGES.DELETE.SUCCESS_SINGLE : DEAL_MESSAGES.DELETE.SUCCESS_MULTIPLE;
            showSuccess(successMessage);
            queryClient.invalidateQueries({ queryKey: ['deals', selectedOrganizationId!] });
            onDeleteSuccess?.();
            toggle();
        } catch (error: any) {
            showError(deleteError?.message || DEAL_MESSAGES.DELETE.ERROR_GENERIC);
        }
    };

    const confirmationMessage = deals.length === 1
        ? DEAL_MESSAGES.DELETE.SINGLE_CONFIRMATION
        : DEAL_MESSAGES.DELETE.MULTIPLE_CONFIRMATION;

    return (
        <NSModal
            toggle={toggle}
            isOpen={isOpen}
            modalHeaderClassName="bg-danger modal-colored-header"
            modalHeaderText={deals.length === 1 ? 'Delete deal' : 'Delete deals'}
            modalFooterButtonText="Confirm"
            modalFooterIsButtonFunctionDisabled={!deals}
            modalFooterIsShown
            modalFooterButtonFunction={handleDelete}
            modalFooterButtonColor="danger"
        >
            <div className="d-flex flex-column">
                <NSLabel className="text-dark font-weight-normal pt-1 mb-0">{confirmationMessage}</NSLabel>
            </div>
        </NSModal>
    );
};

export default DeleteDealModal;
