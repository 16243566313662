import React, { useEffect, useState } from 'react';
import { IReportBuilderReport, NEW_REPORT_LABELS, ReportTypes } from 'views/ReportBuilder/constants';
import { NSCheckbox, NSLabel, NSMultiSelect } from 'bricks';
import { useGetBackOfEnvelopeScenarios } from 'views/DealDashboard/BOE/hooks/useGetBOEScenarios';
import { IOptionAnyValue } from 'bricks/types';
import ReportSaveCard from '../components/ReportSaveCard/ReportSaveCard';

export interface IProps {
    report: IReportBuilderReport;
    setNewReports: React.Dispatch<React.SetStateAction<IReportBuilderReport[]>>;
    orgId: string;
    dealId: number;
}
const BOEScenarioComparisonSaveCard = ({
    report, setNewReports, orgId, dealId,
}: IProps) => {
    const [selectedScenarios, setSelectedScenarios] = useState<IOptionAnyValue[]>([]);
    const [isIncludePrintedOnDateChecked, setIsIncludePrintedOnDateChecked] = React.useState<boolean>(false);

    const { data: scenarios } = useGetBackOfEnvelopeScenarios({
        orgId,
        dealId,
    });

    const dropdownOptions = scenarios?.items.map(scenario => ({
        label: scenario.name,
        value: scenario.id,
    }));

    useEffect(() => {
        if (report.selectedBOEScenarioIds && scenarios?.items) {
            const matchingScenarios = scenarios.items
                .filter(scenario => report.selectedBOEScenarioIds?.includes(Number(scenario.id)))
                .map(scenario => ({
                    label: scenario.name,
                    value: scenario.id,
                }));
            setSelectedScenarios(matchingScenarios);
            setIsIncludePrintedOnDateChecked(!!report.includePrintedOnDate);
        }
    }, [report.selectedBOEScenarioIds, scenarios]);

    const handleSave = () => {
        setNewReports(prevReports => {
            const reportIndex = prevReports.findIndex(r => r.reportType === ReportTypes.BACK_OF_ENVELOPE_SCENARIO_COMPARISON);

            if (reportIndex === -1) {
                return prevReports;
            }

            const updatedReports = [...prevReports];
            updatedReports[reportIndex] = {
                ...prevReports[reportIndex],
                isEditing: false,
                selectedBOEScenarioIds: selectedScenarios.map(scenario => scenario.value),
                selectedBOEScenarioNames: selectedScenarios.map(scenario => scenario.label),
                includePrintedOnDate: isIncludePrintedOnDateChecked,
            };
            return updatedReports;
        });
    };

    const handleCancel = () => {
        setNewReports(prevReports => {
            const reportIndex = prevReports.findIndex(r => r.reportType === ReportTypes.BACK_OF_ENVELOPE_SCENARIO_COMPARISON);

            if (reportIndex === -1 || !prevReports[reportIndex]) {
                return prevReports;
            }

            const updatedReports = [...prevReports];
            const report = prevReports[reportIndex];

            if (report.selectedBOEScenarioIds && report.selectedBOEScenarioIds.length > 0) {
                updatedReports[reportIndex] = {
                    ...report,
                    isEditing: false,
                };
            } else {
                updatedReports.splice(reportIndex, 1);
            }

            return updatedReports;
        });
    };

    return (
        <ReportSaveCard
            header={NEW_REPORT_LABELS.BACK_OF_ENVELOPE_SCENARIO_COMPARISON}
            description="A side-by-side comparison of Back-Of-Envelope scenario metrics"
            handleSave={handleSave}
            handleCancel={handleCancel}
        >
            <NSLabel>Select scenario</NSLabel>
            {dropdownOptions && (
                <NSMultiSelect
                    componentClassName="mt-0"
                    selectName="Select scenario"
                    options={dropdownOptions}
                    value={selectedScenarios}
                    onChange={setSelectedScenarios}
                />
            )}
            <hr />
            <NSCheckbox
                id="include-printed-on-date"
                checked={isIncludePrintedOnDateChecked}
                labelText="Include printed on date"
                callback={() => setIsIncludePrintedOnDateChecked(!isIncludePrintedOnDateChecked)}
            />
        </ReportSaveCard>
    );
};

export default BOEScenarioComparisonSaveCard;
