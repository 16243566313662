import { ProFormaModelIds } from 'constants/proForma';

export const getDefaultVacancy = (proFormaModelId: number): number => {
    switch (proFormaModelId) {
        case ProFormaModelIds.RETAIL:
            return 8;
        case ProFormaModelIds.INDUSTRIAL:
            return 6;
        case ProFormaModelIds.OFFICE:
            return 10;
        case ProFormaModelIds.APARTMENT:
            return 6;
        default:
            return 0;
    }
};

export const getDefaultCreditLoss = (proFormaModelId: number): number => {
    switch (proFormaModelId) {
        case ProFormaModelIds.RETAIL:
            return 3;
        case ProFormaModelIds.INDUSTRIAL:
            return 2;
        case ProFormaModelIds.OFFICE:
            return 2;
        case ProFormaModelIds.APARTMENT:
            return 1;
        default:
            return 0;
    }
};
