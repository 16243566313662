/* eslint-disable no-unused-vars */
export enum DealDocumentType {
    DRAWINGS_SCHEMATIC_DESIGN = 'Drawings - Schematic Design',
    COMPS = 'Comps',
    ENVIRONMENTAL_PHASE_I_ASSESSMENT = 'Environmental Phase I Assessment',
    SALES_BROCHURE = 'Sales Brochure',
    ENVIRONMENTAL_PHASE_II_ASSESSMENT = 'Environmental Phase II Assessment',
    INVESTMENT_MEMO = 'Investment Memo',
    FINANCIAL_PRO_FORMA = 'Financial Pro Forma',
    OFFERING_MEMO = 'Offering Memo',
    JV_AGREEMENT = 'JV Agreement',
    ZONING_REPORT = 'Zoning Report',
    LEASE_AGREEMENT = 'Lease Agreement',
    MARKET_FEASIBILITY_STUDY = 'Market Feasibility Study',
    LOAN_FINANCING_AGREEMENT = 'Loan/Financing Agreement',
    REAL_ESTATE_TAXES = 'Real Estate Taxes',
    MUNICIPAL_GOVERNMENT_DOCUMENTS = 'Municipal/Government Documents',
    PERMITS_ENTITLEMENTS = 'Permits/Entitlements',
    PURCHASE_AGREEMENT = 'Purchase Agreement'
}
