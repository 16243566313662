/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import './SortingIcon.scss';

type SortingIconProps = {
    isSorted: boolean;
    sortedDirection: 'asc' | 'desc' | null;
    columnId: string;
    onAscendingSort: () => void;
    onDescendingSort: () => void;
};

export const SortingIcon: React.FC<SortingIconProps> = ({
    isSorted, sortedDirection, columnId, onAscendingSort, onDescendingSort,
}) => {
    const handleSort = () => {
        if (isSorted) {
            if (sortedDirection === 'asc') {
                onDescendingSort();
            } else {
                onAscendingSort();
            }
        } else {
            onAscendingSort();
        }
    };

    return (
        <div className="d-flex flex-column align-items-center SortingIcon cursor--pointer " onClick={handleSort}>
            <FontAwesomeIcon
                data-testid={`sort-${columnId}-up`}
                id={`sort-${columnId}-up`}
                icon={faSortUp}
                className={`SortingIcon__icon ${isSorted && sortedDirection === 'asc' ? 'active' : 'text-muted'}`}
            />
            <FontAwesomeIcon
                data-testid={`sort-${columnId}-down`}
                id={`sort-${columnId}-down`}
                icon={faSortDown}
                className={`SortingIcon__icon ${isSorted && sortedDirection === 'desc' ? 'active' : 'text-muted'}`}
            />
        </div>
    );
};

export default SortingIcon;
