import { useGetAssetTypes } from 'hooks/useGetAssetTypes';
import { useGetConstructionTypes } from 'hooks/useGetConstructionTypes';
import { useGetMarkets } from 'hooks/useGetMarkets';
import { useGetSubprojectsStages } from 'views/Deals/hooks/useGetSubprojectStages';
import { useGetOrganizationUsers } from 'hooks/useGetOrganizationUsers';
import { useGetDealFormLocalCatalogs } from './useGetDealFormLocalCatalogs';

export const useGetDealFormSelectOptions = (selectedOrganizationId?: string) => {
    const { data: organizationUsersRaw, isLoading: isLoadingOrganizationUsers } = useGetOrganizationUsers(selectedOrganizationId!);
    const { data: assetTypeValuesRaw, isLoading: isLoadingAssetTypes } = useGetAssetTypes();
    const { data: constructionTypeValuesRaw, isLoading: isLoadingConstructionTypes } = useGetConstructionTypes();
    const { data: marketValuesRaw, isLoading: isLoadingMarkets } = useGetMarkets();
    const { data: subprojectStagesRaw, isLoading: isLoadingSubprojectStages } = useGetSubprojectsStages();
    const {
        data: {
            dealTypes,
            leadSources,
            leasingStrategies,
            ranks,
        } = {},
        isLoading: isLoadingLocalCatalogs,
    } = useGetDealFormLocalCatalogs();

    const organizationUserOptions = organizationUsersRaw?.map(({ user }) => ({ label: user.name, value: user.correlationId })) || [];
    const assetTypeOptions = assetTypeValuesRaw?.map(({ correlationId, name }) => ({ label: name, value: correlationId })) || [];
    const constructionTypeOptions = constructionTypeValuesRaw?.map(({ correlationId, name }) => ({ label: name, value: correlationId })) || [];
    const marketOptions = marketValuesRaw?.map(({ correlationId, name }) => ({ label: name, value: correlationId })) || [];
    const phaseOptions = subprojectStagesRaw?.map(({ correlationId, name }) => ({ label: name, value: correlationId })) || [];
    const dealTypeOptions = dealTypes?.map(({ id, name }) => ({ label: name, value: id })) || [];
    const leadSourceOptions = leadSources?.map(({ id, name }) => ({ label: name, value: id })) || [];
    const leasingStrategyOptions = leasingStrategies?.map(({ id, name }) => ({ label: name, value: id })) || [];
    const rankOptions = ranks?.map(({ id, name }) => ({ label: name, value: id })) || [];

    const getSelectedAssetType = (assetTypeId: string) => assetTypeValuesRaw?.find(({ correlationId }) => correlationId === assetTypeId);

    return {
        data: {
            assetTypeOptions,
            constructionTypeOptions,
            marketOptions,
            phaseOptions,
            dealTypeOptions,
            leadSourceOptions,
            leasingStrategyOptions,
            rankOptions,
            organizationUserOptions,
        },
        getSelectedAssetType,
        loadingStates: {
            assetTypes: isLoadingAssetTypes,
            constructionTypes: isLoadingConstructionTypes,
            markets: isLoadingMarkets,
            phases: isLoadingSubprojectStages,
            leadSources: isLoadingLocalCatalogs,
            leasingStrategies: isLoadingLocalCatalogs,
            ranks: isLoadingLocalCatalogs,
            dealTypes: isLoadingLocalCatalogs,
            organizationUsers: isLoadingOrganizationUsers,
        },
    };
};
