import React, { createContext, useContext, useState } from 'react';

interface DealsOverviewContextType {
    selectedStages: Record<string, boolean>;
    setSelectedStages: (stages: Record<string, boolean>) => void;
    isExpandedPipeline: boolean;
    setIsExpandedPipeline: (isExpanded: boolean) => void;
    handleClickStage: (stageId: string) => void;
    projectCountsByStageId: Record<string, number> | null;
    setProjectCountsByStageId: (counts: Record<string, number>) => void;
}

interface DealsOverviewProviderProps {
    selectedStages: Record<string, boolean>;
    setSelectedStages: (stages: Record<string, boolean>) => void;
    isExpandedPipeline: boolean;
    setIsExpandedPipeline: (isExpanded: boolean) => void;
    children: React.ReactNode;
}

export const DealsOverviewContext = createContext<DealsOverviewContextType | null>(null);

export const DealsOverviewProvider: React.FC<DealsOverviewProviderProps> = ({
    selectedStages,
    setSelectedStages,
    isExpandedPipeline,
    setIsExpandedPipeline,
    children,
}) => {
    const [projectCountsByStageId, setProjectCountsByStageId] = useState<Record<number, number>>({});

    const handleClickStage = (stageId: string) => {
        const updatedSelectedStages = {
            ...selectedStages,
            [stageId]: !selectedStages?.[stageId],
        };
        window.localStorage.setItem('dealPipelineSelectedStages', JSON.stringify(updatedSelectedStages));
        setSelectedStages(updatedSelectedStages);
    };

    return (
        <DealsOverviewContext.Provider
            value={{
                selectedStages,
                setSelectedStages,
                isExpandedPipeline,
                setIsExpandedPipeline,
                handleClickStage,
                projectCountsByStageId,
                setProjectCountsByStageId,
            }}
        >
            {children}
        </DealsOverviewContext.Provider>
    );
};

export const useDealsOverview = (): DealsOverviewContextType => {
    const context = useContext(DealsOverviewContext);
    if (!context) {
        throw new Error('useDealsOverview must be used within a DealsOverviewProvider');
    }
    return context;
};
