import React, { useMemo, useState } from 'react';
import { NSButton } from 'bricks';
import { IOptionAnyValue } from 'bricks/types';
import { AttributeKey } from 'views/Reports/types';
import { IFilter } from './types';
import FilterModal from './FilterModal/FilterModal';
import Filter from './Filter';
import { AttributeKeysMap } from '../constants';

interface IFilterSectionProps {
    className?: string;
    filters: IFilter[];
    setFilters: (filters: IFilter[]) => void;
}

const FiltersSection = ({ className, filters, setFilters }: IFilterSectionProps) => {
    const [isFilterModalShown, setIsFilterModalShown] = useState<boolean>(false);

    const attributeOptions: IOptionAnyValue[] = useMemo(
        () => Object.entries(AttributeKeysMap).map(
            ([key, value]) => ({
                label: value,
                value: key,
                disabled: filters.some(f => f.attribute === key),
            }),
        ), [filters],
    );

    const toggleFilterModal = () => setIsFilterModalShown(!isFilterModalShown);

    const addFilter = (filter: IFilter) => {
        setFilters([...filters, filter]);
    };

    const removeFilter = (attribute: AttributeKey) => {
        const newFilters = filters.filter(f => f.attribute !== attribute);
        setFilters(newFilters);
    };

    const editFilter = (attribute: AttributeKey, filter: IFilter) => {
        const updatedFilters = filters.map(f => {
            if (f.attribute === attribute) {
                return filter;
            }
            return f;
        });
        setFilters(updatedFilters);
    };

    return (
        <div className={className}>
            <div>
                {filters.map(filter => (
                    <Filter
                        key={filter.attribute}
                        filter={filter}
                        editFilter={editFilter}
                        removeFilter={removeFilter}
                        attributeOptions={attributeOptions}
                    />
                ))}
                <NSButton className="text-primary cursor--pointer m-0 mt-1 p-0" callback={() => setIsFilterModalShown(true)}>
                    + Add filter
                </NSButton>
            </div>
            <FilterModal toggle={toggleFilterModal} isOpen={isFilterModalShown} saveFilter={addFilter} attributeOptions={attributeOptions} />
        </div>
    );
};

export default FiltersSection;
