import React from 'react';
import StepWizard from 'react-step-wizard';
import { NSModal } from 'bricks';
import { useParams } from 'react-router-dom';
import { IOptionAnyValue } from 'bricks/types';
import CreateProFormaStep1 from './components/CreateProFormaStep1/CreateProFormaStep1';
import CreateProFormaStep2 from './components/CreateProFormaStep2/CreateProFormaStep2';
import { CREATE_PRO_FORMA_STEPS } from './constants';

export interface ICreateProFormaModalProps {
    toggle: () => void;
    isOpen: boolean;
    initialScenario?: IOptionAnyValue;
    showScenarioStep?: boolean;
}

const CreateProFormaModal = ({
    toggle, isOpen, initialScenario, showScenarioStep,
}: ICreateProFormaModalProps) => {
    const { dealId } = useParams<{ dealId: string }>();

    return (
        <NSModal
            toggle={toggle}
            isOpen={isOpen}
            modalHeaderClassName="bg-primary modal-colored-header"
            modalHeaderText="Create pro forma"
            modalFooterIsShown={false}
            size="lg"
        >
            <StepWizard
                initialStep={initialScenario && showScenarioStep ? CREATE_PRO_FORMA_STEPS.STEP_1 : CREATE_PRO_FORMA_STEPS.STEP_2}
                transitions={{
                    enterRight: '',
                    exitRight: '',
                }}
            >
                <CreateProFormaStep1
                    stepName={String(CREATE_PRO_FORMA_STEPS.STEP_1)}
                    dealId={Number(dealId)}
                    toggle={toggle}
                    initialScenario={initialScenario}
                />
                <CreateProFormaStep2
                    stepName={String(CREATE_PRO_FORMA_STEPS.STEP_2)}
                    dealId={Number(dealId)}
                    toggle={toggle}
                    initialScenario={initialScenario}
                    showScenarioStep={showScenarioStep}
                />
            </StepWizard>
        </NSModal>
    );
};

export default CreateProFormaModal;
