import React from 'react';

export const HeaderRow = ({ data, headerRowFormatter }: { data: any[]; headerRowFormatter: (col: any) => React.ReactNode }) => (
    <tr className="NSTable--custom-header">
        <td key="empty-header-cell">{/* empty left-most header row cell */}</td>
        {data.map(col => (
            <td key={col?.dealId}>{headerRowFormatter(col)}</td>
        ))}
    </tr>
);

export default HeaderRow;
