import React, {
    createContext, useContext, useEffect, useState, useRef,
} from 'react';
import { io } from 'socket.io-client';
import {
    IProFormaSocketRoomData,
    IProFormaGeneralInfo,
    IUpdateProFormaKeyValues,
    ICreateBudgetClassificationPostBody,
    ICreateProFormaSourceRequestData,
    IBudgetClassificationPatchData,
    IGrossBuildableAreaLotsData,
    IGrossBuildableAreaLot,
    IUsesSummary,
    ISourcesSummary,
    ISourcePatchData,
    IOperatingAndPermanentDebtInfo,
    IIncomeSummary,
    IExpenseSummary,
    IProFormaPageLocalStorage,
    IPatchOperatingInfoData,
    IOperatingPeriodCashFlows,
    IDispositionData,
    IProFormaReturnsData,
    IAssumptions,
} from 'views/ProFormaTable/types';
import { PageSpecificKeys, useLocalStorage } from 'helpers/localStorage';
import {
    INCOME_AND_EXPENSE_WS_EVENT_NAMES, SHOW_PROJECT_ACTUALS_KEY, DEFUALT_ROW_NAME_BY_HANDLER,
} from 'views/ProFormaTable/constants';
import useToast from 'hooks/useToast';
import { useAuth0TokenContext } from '../../../../../contexts/Auth0TokenContext';
import { config } from '../../../../../config';

const joinRoom = (socket: any, roomId: string) => new Promise((resolve, reject) => {
    socket.emit('join_room', roomId, (response: any) => {
        if (response && response.error) {
            console.error('Error joining room:', response.error);
            reject(response.error);
        } else {
            console.log('Successfully joined room:', roomId);
            resolve(response);
        }
    });
});

const getProForma = (socket: any, roomId: string) => new Promise((resolve, reject) => {
    socket.emit('get_pro_forma', { room: roomId });

    socket.on('pro_forma:fetched', (data: IProFormaSocketRoomData) => {
        console.log('Pro forma data:', data);
        resolve(data);
    });
}) as Promise<IProFormaSocketRoomData>;

const defaultValues: IProFormaSocketContext = {
    proForma: {
        id: 0,
        proFormaStatusId: 0,
        proFormaModelId: 0,
        dealName: '',
        dealId: 0,
        boeScenarioId: 0,
        name: '',
        description: '',
        address: '',
        city: '',
        county: '',
        state: '',
        zipCode: '',
        startDate: '',
        saleDate: '',
        landArea: null,
        landAreaUnitTypeId: 6,
        floorAreaRatio: null,
        grossBuildableArea: null,
        buildings: 1,
        averageStories: 1,
        builtGrossSquareFootage: null,
        structuredParkingSpaces: null,
        surfaceParkingSpaces: null,
        carParkingSpaces: null,
        trailerParkingSpaces: null,
        isActive: false,
        createdAt: '',
        updatedAt: '',
        subprojectId: null,
    },
    handleUpdateProForma: () => { },
    handleCreateDefaultBudgetClassifications: () => { },
    isDefaultBudgetClassificationsLoading: false,
    handleCreateBudgetClassification: () => { },
    isBudgetClassificationLoading: false,
    handleUpdateBudgetClassifications: () => { },
    isBudgetClassificationUpdatingLoading: false,
    handleDeleteBudgetClassification: () => { },
    isBudgetClassificationDeletionLoading: false,
    handledDeleteSource: () => { },
    isSourceDeletionLoading: false,
    handleCreateProFormaSource: () => { },
    buildableLots: { totalGrossBuildableArea: 0, grossBuildableAreaLots: [] },
    uses: {} as IUsesSummary,
    sources: {} as ISourcesSummary,
    handleCreateBuildableLot: () => { },
    handleUpdateBuildableLot: () => { },
    handleDeleteBuildableLot: () => { },
    handleUpdateSource: () => { },
    operatingInfo: {} as IOperatingAndPermanentDebtInfo,
    operatingPeriodCashFlows: [] as IOperatingPeriodCashFlows[],
    incomeSummary: {} as IIncomeSummary,
    expenses: {} as IExpenseSummary,
    shouldUseProjectActuals: false,
    setShouldUseProjectActuals: () => { },
    handleUpdateProFormaOperations: () => { },
    handleCreateOperationsRow: () => { },
    handleUpdateOperationsRow: () => (_e: React.ChangeEvent<HTMLInputElement>) => { },
    handleDeleteOperationsRow: () => { },
    setDisposition: () => { },
    disposition: undefined,
    handleUpdateProFormaAssumptions: () => { },
    returnsData: {} as IProFormaReturnsData,
    handleStartDevelopmentWithBOEEstimates: () => { },
};

const ProFormaSocketContext = createContext<IProFormaSocketContext>(defaultValues);

interface IProFormaSocketProviderProps {
    organizationId: string;
    dealId: number;
    proFormaId: number;
    children: React.ReactNode;
}

const ProFormaSocketProvider = ({
    organizationId, dealId, proFormaId, children,
}: IProFormaSocketProviderProps) => {
    const roomId = `${organizationId}/deal/${dealId}/pro_forma/${proFormaId}`;
    const socketRef = useRef<any>(null);
    const { showError } = useToast();

    const [proForma, setProForma] = useState<IProFormaGeneralInfo>();
    const [uses, setUses] = useState<IUsesSummary>({} as IUsesSummary);
    const [sources, setSources] = useState<ISourcesSummary>({} as ISourcesSummary);
    const [buildableLots, setBuildableLots] = useState<IGrossBuildableAreaLotsData>({ totalGrossBuildableArea: 0, grossBuildableAreaLots: [] });
    const [isDefaultBudgetClassificationsLoading, setIsDefaultBudgetClassificationsLoading] = useState(false);
    const [isBudgetClassificationLoading, setIsBudgetClassificationLoading] = useState(false);
    const [isSourceDeletionLoading, setIsSourceDeletionLoading] = useState(false);
    const [isBudgetClassificationUpdatingLoading, setIsBudgetClassificationUpdatingLoading] = useState(false);
    const [isBudgetClassificationDeletionLoading, setIsBudgetClassificationDeletionLoading] = useState(false);
    const [operatingInfo, setOperatingInfo] = useState<IOperatingAndPermanentDebtInfo>({} as IOperatingAndPermanentDebtInfo);
    const [operatingPeriodCashFlows, setOperatingPeriodCashFlows] = useState<IOperatingPeriodCashFlows[]>([]);
    const [incomeSummary, setIncomeSummary] = useState<IIncomeSummary>({} as IIncomeSummary);
    const [expenses, setExpenses] = useState<IExpenseSummary>({} as IExpenseSummary);
    const [returnsData, setReturnsData] = useState<IProFormaReturnsData>({} as IProFormaReturnsData);
    const [disposition, setDispositionState] = useState<IDispositionData | undefined>(undefined);

    const [proFormaPageLocalStorage] = useLocalStorage<IProFormaPageLocalStorage>(PageSpecificKeys.ProFormaPage, {});
    const currentProFormaLocalStorage = proFormaId in proFormaPageLocalStorage ? proFormaPageLocalStorage[proFormaId] : {};
    const showProjectActuals = SHOW_PROJECT_ACTUALS_KEY in currentProFormaLocalStorage && Boolean(currentProFormaLocalStorage[SHOW_PROJECT_ACTUALS_KEY]);
    const [shouldUseProjectActuals, setShouldUseProjectActuals] = useState(showProjectActuals || false);

    const { isAuthenticated, accessToken } = useAuth0TokenContext();

    const checkSocketConnection = () => {
        if (!socketRef.current) {
            console.error('WebSocket connection is not established');
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (isAuthenticated && accessToken) {
            const socket = io(config.webSocketBaseUrl, {
                transports: ['websocket'],
                path: '/api/socket.io',
                auth: {
                    token: accessToken,
                },
                rejectUnauthorized: config.rejectUnauthorizedWebsocket,
            });

            socket.on('connect', async () => {
                console.log('Socket ID:', socket.id);
                console.log('Joining room:', roomId);

                try {
                    console.log('Getting pro forma data: ', roomId);
                    await joinRoom(socket, roomId);
                    const data: IProFormaSocketRoomData = await getProForma(socket, roomId);
                    setProForma(data.generalInfo);
                    setUses(data.usesSummary);
                    setSources(data.sourcesSummary);
                    setBuildableLots(data.buildableLots);
                    setOperatingInfo(data.operatingAndPermanentDebtInfo);
                    setOperatingPeriodCashFlows(data.operatingPeriodCashFlows);
                    setIncomeSummary(data.incomeSummary);
                    setExpenses(data.expenseSummary);
                    setDispositionState(data.disposition);
                    setShouldUseProjectActuals(showProjectActuals || false);
                    setReturnsData(data.returns);
                } catch (error) {
                    console.error('Error during connection workflow:', error);
                }
            });

            socket.on('pro_forma:updated', (data: IProFormaSocketRoomData) => {
                console.log('The pro forma was updated:', data);
                setProForma(data.generalInfo);
                setUses(data.usesSummary);
                setSources(data.sourcesSummary);
                setOperatingInfo(data.operatingAndPermanentDebtInfo);
                setOperatingPeriodCashFlows(data.operatingPeriodCashFlows);
                setBuildableLots(data.buildableLots);
                setIncomeSummary(data.incomeSummary);
                setExpenses(data.expenseSummary);
                setReturnsData(data.returns);
                if (data.disposition) {
                    setDispositionState(data.disposition);
                }
            });

            socket.on('pro_forma:pro_forma_source:created', (data: IProFormaSocketRoomData) => {
                console.log('Pro forma source created:', data);
                setUses(data.usesSummary);
                setSources(data.sourcesSummary);
                // TODO: data will be the entire updated pro forma as saved in redis, set all state variables
                // setOperatingCashFlow(data.operatingCashFlow);
            });

            socket.on('pro_forma:default_budget_classifications:created', (data: IProFormaSocketRoomData) => {
                console.log('Default budget classifications were created:', data);
                setProForma(data.generalInfo);
                setUses(data.usesSummary);
                setSources(data.sourcesSummary);
            });

            socket.on('pro_forma:budget_classification:created', (data: IProFormaSocketRoomData) => {
                console.log('Budget classification was created:', data);
                setProForma(data.generalInfo);
                setUses(data.usesSummary);
                setSources(data.sourcesSummary);
            });

            socket.on('pro_forma:source:updated', (data: IProFormaSocketRoomData) => {
                console.log('Source was updated:', data);
                setProForma(data.generalInfo);
                setUses(data.usesSummary);
                setSources(data.sourcesSummary);
            });

            socket.on('pro_forma:operations:updated', (data: IProFormaSocketRoomData) => {
                console.log('Pro forma operations was updated:', data);
                setProForma(data.generalInfo);
                setOperatingInfo(data.operatingAndPermanentDebtInfo);
            });

            socket.on('pro_forma:budget_classification:updated', (data: IProFormaSocketRoomData) => {
                console.log('Budget classifications were updated:', data);
                setProForma(data.generalInfo);
                setUses(data.usesSummary);
                setSources(data.sourcesSummary);
                setOperatingInfo(data.operatingAndPermanentDebtInfo);
            });

            socket.on('pro_forma:budget_classification:deleted', (data: IProFormaSocketRoomData) => {
                console.log('Budget classification was deleted:', data);
                setProForma(data.generalInfo);
                setUses(data.usesSummary);
                setSources(data.sourcesSummary);
            });

            socket.on('pro_forma:source:deleted', (data: IProFormaSocketRoomData) => {
                console.log('Source was deleted:', data);
                setProForma(data.generalInfo);
                setUses(data.usesSummary);
                setSources(data.sourcesSummary);
            });

            socket.on('pro_forma:gross_buildable_lot:created', (data: IProFormaSocketRoomData) => {
                console.log('Groos buildable lot was created:', data);
                setProForma(data.generalInfo);
                setBuildableLots(data.buildableLots);
            });

            socket.on('pro_forma:gross_buildable_lot:updated', (data: IProFormaSocketRoomData) => {
                console.log('Groos buildable lot was updated:', data);
                setProForma(data.generalInfo);
                setBuildableLots(data.buildableLots);
            });

            socket.on('pro_forma:gross_buildable_lot:deleted', (data: IProFormaSocketRoomData) => {
                console.log('Groos buildable lot was deleted:', data);
                setProForma(data.generalInfo);
                setBuildableLots(data.buildableLots);
            });

            INCOME_AND_EXPENSE_WS_EVENT_NAMES.forEach(eventName => {
                socket.on(`pro_forma:${eventName}`, (data: IProFormaSocketRoomData) => {
                    console.log(`pro_forma:${eventName} event received:`, data);
                    setIncomeSummary(data.incomeSummary);
                    setExpenses(data.expenseSummary);
                });
            });

            socket.on('pro_forma:disposition:updated', (data: IProFormaSocketRoomData) => {
                console.log('Disposition was updated:', data);
                if (data.disposition) {
                    setDispositionState(data.disposition);
                }
            });

            socket.on('connect_error', error => {
                console.error('WebSocket connection error:', error);
            });

            socketRef.current = socket;

            socket.emit('get_disposition', { room: roomId });

            socket.on('disposition:fetched', (data: { disposition: IDispositionData }) => {
                console.log('Disposition data fetched:', data);
                if (data && data.disposition) {
                    setDispositionState(data.disposition);
                }
            });

            return () => {
                console.log('Cleaning up WebSocket connection');
                if (socket) {
                    socket.disconnect();
                    socketRef.current = null;
                }
            };
        }

        return () => { };
    }, [isAuthenticated, accessToken, config.webSocketBaseUrl, proFormaId]);

    const handleUpdateProForma = (keyValues: IUpdateProFormaKeyValues[], successCallback?: () => void) => {
        if (!checkSocketConnection()) return;

        const data = keyValues.reduce(
            (acc, { key, value }) => {
                acc[key] = value;
                return acc;
            },
            {} as Record<string, string | number | null>,
        );

        socketRef.current.emit('update_pro_forma', {
            room: roomId,
            pro_forma_id: proFormaId,
            data,
        });

        socketRef.current.once('pro_forma:updated', (data: IProFormaSocketRoomData) => {
            setProForma(data.generalInfo);
            setUses(data.usesSummary);
            setSources(data.sourcesSummary);

            if (successCallback) successCallback();
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            showError('Error updating pro forma - please try again.');
        });
    };

    const handleCreateProFormaSource = (data: ICreateProFormaSourceRequestData, callback?: (isSuccess?: boolean) => void) => {
        if (!checkSocketConnection()) return;

        socketRef.current.emit('create_pro_forma_source', {
            room: roomId,
            pro_forma_id: proFormaId,
            organization_id: organizationId,
            data,
        });

        socketRef.current.once('pro_forma:pro_forma_source:created', (data: IProFormaSocketRoomData) => {
            setUses(data.usesSummary);
            setSources(data.sourcesSummary);

            if (callback) callback();
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            showError('Error creating a source - please try again.');

            if (callback) callback(false);
        });
    };

    const handleCreateDefaultBudgetClassifications = (startUsesFromScratch: () => void) => {
        if (!checkSocketConnection()) return;

        setIsDefaultBudgetClassificationsLoading(true);
        socketRef.current.emit('create_default_budget_classifications', {
            room: roomId,
            pro_forma_id: proFormaId,
            organization_id: organizationId,
        });

        socketRef.current.once('pro_forma:default_budget_classifications:created', (data: IProFormaSocketRoomData) => {
            console.log('Default budget classifications were created:', data);
            setIsDefaultBudgetClassificationsLoading(false);
            startUsesFromScratch();
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            setIsDefaultBudgetClassificationsLoading(false);
            showError('Error creating default budget classifications - please try again.');
        });
    };

    const handleStartDevelopmentWithBOEEstimates = (scenarioId: string, startUsesFromScratch: () => void) => {
        if (!checkSocketConnection()) return;

        setIsDefaultBudgetClassificationsLoading(true);
        socketRef.current.emit('start_development_with_boe_estimates', {
            room: roomId,
            pro_forma_id: proFormaId,
            organization_id: organizationId,
            scenario_id: scenarioId,
        });

        socketRef.current.once('pro_forma:start_development_with_boe_estimates:created', (data: IProFormaSocketRoomData) => {
            console.log('Development started with BOE estimates:', data);
            setUses(data.usesSummary);
            setIsDefaultBudgetClassificationsLoading(false);
            startUsesFromScratch();
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            setIsDefaultBudgetClassificationsLoading(false);
            showError('Error starting development with BOE estimates - please try again.');
        });
    };

    const handleCreateBudgetClassification = (postBody: ICreateBudgetClassificationPostBody, expandCategory: () => void) => {
        if (!checkSocketConnection()) return;

        setIsBudgetClassificationLoading(true);
        socketRef.current.emit('create_budget_classification', {
            room: roomId,
            pro_forma_id: proFormaId,
            organization_id: organizationId,
            new_budget_classification: postBody,
        });

        socketRef.current.once('pro_forma:budget_classification:created', (data: IProFormaSocketRoomData) => {
            console.log('Budget classification was created:', data);
            setIsBudgetClassificationLoading(false);
            expandCategory();
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            setIsBudgetClassificationLoading(false);
            showError('Error creating budget classification - please try again.');
        });
    };

    const handleUpdateBudgetClassifications = (patchBody: IBudgetClassificationPatchData[], successCallback?: () => void) => {
        if (!checkSocketConnection()) return;

        setIsBudgetClassificationUpdatingLoading(true);
        socketRef.current.emit('update_budget_classifications', {
            room: roomId,
            pro_forma_id: proFormaId,
            organization_id: organizationId,
            update_budget_classifications: {
                budget_classifications: patchBody,
            },
        });

        socketRef.current.once('pro_forma:budget_classification:updated', (data: IProFormaSocketRoomData) => {
            console.log('Budget classifications were updated:', data);
            setIsBudgetClassificationUpdatingLoading(false);
            if (successCallback) successCallback();
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            setIsBudgetClassificationUpdatingLoading(false);
            showError('Error updating budget classifications - please try again.');
        });
    };

    const handleDeleteBudgetClassification = (budgetClassificationIds: number[], deleteAll: boolean = false, successCallback: () => void) => {
        if (!checkSocketConnection()) return;

        setIsBudgetClassificationDeletionLoading(true);
        socketRef.current.emit('delete_budget_classification', {
            room: roomId,
            organization_id: organizationId,
            pro_forma_id: proFormaId,
            budget_classification_ids: budgetClassificationIds,
            delete_all: deleteAll,
        });

        socketRef.current.once('pro_forma:budget_classification:deleted', (data: IProFormaSocketRoomData) => {
            console.log('Budget classification was deleted:', data);
            setIsBudgetClassificationDeletionLoading(false);
            if (successCallback) successCallback();
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            setIsBudgetClassificationDeletionLoading(false);
            showError('Error deleting budget classification - please try again.');
        });
    };

    const handleUpdateSource = (proFormaSourceId: number, patchData: ISourcePatchData, successCallback?: () => void) => {
        if (!checkSocketConnection()) return;

        socketRef.current.emit('update_source', {
            room: roomId,
            organization_id: organizationId,
            pro_forma_id: proFormaId,
            pro_forma_source_id: proFormaSourceId,
            data: patchData,
        });

        socketRef.current.once('pro_forma:source:updated', (data: IProFormaSocketRoomData) => {
            console.log('Source was updated:', data);
            if (successCallback) successCallback();
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            showError('Error updating source - please try again.');
        });
    };

    const handledDeleteSource = (proFormaSourceId: number) => {
        if (!checkSocketConnection()) return;

        setIsSourceDeletionLoading(true);
        socketRef.current.emit('delete_source', {
            room: roomId,
            pro_forma_source_id: proFormaSourceId,
        });

        socketRef.current.once('pro_forma:source:deleted', (data: IProFormaSocketRoomData) => {
            console.log('Source was deleted:', data);
            setIsSourceDeletionLoading(false);
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            setIsSourceDeletionLoading(false);
            showError('Error deleting a source - please try again.');
        });
    };

    const handleUpdateProFormaOperations = (proFormaOperationsId: number, patchData: IPatchOperatingInfoData) => {
        if (!checkSocketConnection()) return;

        socketRef.current.emit('update_pro_forma_operations', {
            room: roomId,
            pro_forma_id: proFormaId,
            pro_forma_operations_id: proFormaOperationsId,
            data: patchData,
        });

        socketRef.current.once('pro_forma:operations:updated', (data: IProFormaSocketRoomData) => {
            console.log('Pro forma operations was updated:', data);
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            showError('Error updating pro forma operations - please try again.');
        });
    };

    const handleCreateBuildableLot = () => {
        console.log('Creating gross buildable lot');
        if (!checkSocketConnection()) return;

        socketRef.current.emit('create_gross_buildable_lot', {
            room: roomId,
            pro_forma_id: proFormaId,
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            showError('Error creating gross buildable lot - please try again.');
        });
    };

    const handleUpdateBuildableLot = (lotId: number, dataKey: keyof IGrossBuildableAreaLot, value: string | number) => {
        console.log('Updating gross buildable lot');
        if (!checkSocketConnection()) return;

        socketRef.current.emit('update_gross_buildable_lot', {
            room: roomId,
            pro_forma_id: proFormaId,
            lot_id: lotId,
            data: {
                [dataKey]: value,
            },
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            showError('Error updating gross buildable lot - please try again.');
        });
    };

    const handleDeleteBuildableLot = (lotId: number) => {
        console.log('Deleting gross buildable lot');
        if (!checkSocketConnection()) return;

        socketRef.current.emit('delete_gross_buildable_lot', {
            room: roomId,
            pro_forma_id: proFormaId,
            lot_id: lotId,
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            showError('Error deleting gross buildable lot - please try again.');
        });
    };

    const handleCreateOperationsRow = (handler: string) => {
        console.log('Creating operating income row', handler);
        if (!checkSocketConnection()) return;

        socketRef.current.emit(handler, {
            room: roomId,
            data: {
                name: DEFUALT_ROW_NAME_BY_HANDLER[handler],
            },
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            showError('Error creating operating income row - please try again.');
        });
    };

    const handleUpdateOperationsRow = (handler: string, rowId: number, dataKey: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(`Updating operating income row with ID ${rowId} with data {${dataKey}: ${e.target.value}}`, handler);
        if (!checkSocketConnection()) return;

        socketRef.current.emit(handler, {
            room: roomId,
            data: {
                id: Number(rowId),
                [dataKey]: e.target.value,
            },
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            showError('Error updating operating income row - please try again.');
        });
    };

    const handleDeleteOperationsRow = (handler: string, rowId: number) => {
        console.log('Deleting operating income row', handler);
        if (!checkSocketConnection()) return;

        socketRef.current.emit(handler, {
            room: roomId,
            data: {
                id: Number(rowId),
            },
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            showError('Error deleting operating income row - please try again.');
        });
    };

    const setDisposition = (dispositionData: IDispositionData) => {
        setDispositionState(dispositionData);
        setProForma(prevProForma => {
            if (!prevProForma) return prevProForma;
            return {
                ...prevProForma,
                disposition: dispositionData,
            };
        });
    };

    const handleUpdateProFormaAssumptions = (patchData: Partial<IAssumptions>) => {
        if (!checkSocketConnection()) return;

        socketRef.current.emit('update_disposition_assumptions', {
            room: roomId,
            pro_forma_id: proFormaId,
            data: patchData,
        });

        socketRef.current.once('pro_forma:disposition:updated', (data: IProFormaSocketRoomData) => {
            console.log('Pro forma disposition was updated:', data);
        });

        socketRef.current.once('error', (error: any) => {
            console.error('WebSocket error:', error);
            showError('Error updating pro forma dispositions assumptions - please try again.');
        });
    };

    return (
        <ProFormaSocketContext.Provider
            value={{
                proForma: proForma || ({} as IProFormaGeneralInfo),
                handleUpdateProForma,
                handleCreateDefaultBudgetClassifications,
                isDefaultBudgetClassificationsLoading,
                handleCreateBudgetClassification,
                isBudgetClassificationLoading,
                handleUpdateBudgetClassifications,
                isBudgetClassificationUpdatingLoading,
                handleDeleteBudgetClassification,
                isBudgetClassificationDeletionLoading,
                handledDeleteSource,
                handleUpdateSource,
                isSourceDeletionLoading,
                handleUpdateProFormaOperations,
                handleCreateProFormaSource,
                handleCreateBuildableLot,
                handleUpdateBuildableLot,
                handleDeleteBuildableLot,
                buildableLots,
                uses,
                sources,
                operatingInfo,
                operatingPeriodCashFlows,
                expenses,
                incomeSummary,
                shouldUseProjectActuals,
                setShouldUseProjectActuals,
                handleCreateOperationsRow,
                handleUpdateOperationsRow,
                handleDeleteOperationsRow,
                setDisposition,
                disposition,
                handleUpdateProFormaAssumptions,
                returnsData,
                handleStartDevelopmentWithBOEEstimates,
            }}
        >
            {children}
        </ProFormaSocketContext.Provider>
    );
};

const useProFormaSocketContext = () => useContext(ProFormaSocketContext);

export {
    ProFormaSocketContext, ProFormaSocketProvider, useProFormaSocketContext, defaultValues,
};

export interface IProFormaSocketContext {
    proForma: IProFormaGeneralInfo;
    handleUpdateProForma: (keyValues: IUpdateProFormaKeyValues[], successCallback?: () => void) => void;
    handledDeleteSource: (proFormaSourceId: number) => void;
    isSourceDeletionLoading: boolean;
    handleCreateDefaultBudgetClassifications: (startUsesFromScratch: () => void) => void;
    isDefaultBudgetClassificationsLoading: boolean;
    handleCreateBudgetClassification: (postBody: ICreateBudgetClassificationPostBody, expandCategory: () => void) => void;
    isBudgetClassificationLoading: boolean;
    handleUpdateBudgetClassifications: (patchBody: IBudgetClassificationPatchData[], successCallback?: () => void) => void;
    isBudgetClassificationUpdatingLoading: boolean;
    handleDeleteBudgetClassification: (budgetClassificationIds: number[], deleteAll: boolean, successCallback: () => void) => void;
    isBudgetClassificationDeletionLoading: boolean;
    handleCreateProFormaSource: (data: ICreateProFormaSourceRequestData, callback?: (isSuccess?: boolean) => void) => void;
    handleUpdateSource: (proFormaSourceId: number, patchData: ISourcePatchData, successCallback?: () => void) => void;
    uses: IUsesSummary;
    sources: ISourcesSummary;
    handleCreateBuildableLot?: () => void;
    handleUpdateBuildableLot?: (lotId: number, dataKey: keyof IGrossBuildableAreaLot, value: string | number) => void;
    handleDeleteBuildableLot?: (lotId: number) => void;
    handleUpdateProFormaOperations: (proFormaOperationsId: number, patchData: IPatchOperatingInfoData) => void;
    buildableLots: IGrossBuildableAreaLotsData;
    operatingInfo: IOperatingAndPermanentDebtInfo;
    operatingPeriodCashFlows: IOperatingPeriodCashFlows[];
    incomeSummary: IIncomeSummary;
    expenses: IExpenseSummary;
    shouldUseProjectActuals: boolean;
    setShouldUseProjectActuals: (value: boolean) => void;
    handleCreateOperationsRow: (handler: string) => void;
    handleUpdateOperationsRow: (handler: string, rowId: number, dataKey: string) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleDeleteOperationsRow: (handler: string, rowId: number) => void;
    setDisposition: (dispositionData: IDispositionData) => void;
    disposition?: IDispositionData;
    handleUpdateProFormaAssumptions: (patchData: Partial<IAssumptions>) => void;
    returnsData: IProFormaReturnsData;
    handleStartDevelopmentWithBOEEstimates: (scenarioId: string, startUsesFromScratch: () => void) => void;
}
