import axiosInstance from 'ns_libs/nsAxios';
import { useMutation } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import { IProFormaData } from 'views/ProFormaTable/types';

interface ICreateProFormaProps {
    dealId: number;
    selectedOrganizationId: string;
    scenarioId?: number; // Optional parameter

}

const createProForma = async ({ dealId, selectedOrganizationId, scenarioId }: ICreateProFormaProps): Promise<IProFormaData> => {
    const endpoint = scenarioId
        ? `${API_ENDPOINTS.PRO_FORMA(dealId)}?selectedOrganizationId=${selectedOrganizationId}&scenarioId=${scenarioId}`
        : `${API_ENDPOINTS.PRO_FORMA(dealId)}?selectedOrganizationId=${selectedOrganizationId}`;

    const response = await axiosInstance.post(endpoint);
    return response.data;
};

const useCreateProForma = () => useMutation({
    mutationFn: createProForma,
});

export default useCreateProForma;
