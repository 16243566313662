import React from 'react';
import { ComparisonTable } from './components';
import { IGridConfig } from './types';
import './ComparisonGrid.scss';

export interface IProps {
    gridConfig?: IGridConfig;
    isEditMode?: boolean;
    renderAddToComparison: () => React.ReactNode;
    maxHeight?: string | number;
}

export const ComparisonGrid = ({
    gridConfig, renderAddToComparison, maxHeight = '80vh', isEditMode = false,
}: IProps) => (
    <div className="ComparisonGrid d-flex p-2">
        {gridConfig && <ComparisonTable gridConfig={gridConfig} maxHeight={maxHeight} />}
        {isEditMode && <div className="ComparisonGrid__add-to-comparison-section ml-3 p-3">{renderAddToComparison()}</div>}
    </div>
);

export default ComparisonGrid;
