/* eslint-disable no-undef */
/* eslint-disable operator-linebreak */
import React, {
    forwardRef, useCallback, useEffect, useRef,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPercentage } from '@fortawesome/free-solid-svg-icons';
import { faChevronDoubleRight, faChevronRight, faDollar } from '@fortawesome/pro-regular-svg-icons';
import { Formik, Form, FormikProps } from 'formik';
import { useQueryClient } from '@tanstack/react-query';
import './BOESlideoutForm.scss';

import { useAuthContext } from 'contexts/AuthContext';
import { NSCard, NSDropdown, NSInput } from 'bricks';
import useToast from 'hooks/useToast';
import RenderIf from 'components/RenderIf/RenderIf';
import { Deal } from 'views/Deals/types';
import { numeralFormatterCurrency } from 'ns_libs/formatter';
import { useGetAssetTypes } from 'hooks/useGetAssetTypes';
import { generatePath, useNavigate } from 'react-router-dom';
import { URLS } from 'services/urls';
import {
    COST_BASIS_OPTIONS,
    COST_BASIS_TYPES,
    LOAN_CALCULATION_OPTIONS,
    LOAN_CALCULATION_TYPES,
    UNIT_OF_TIME_OPTIONS,
    validationSchema,
    ASSET_TYPE,
    ALLOWED_ASSET_TYPES,
} from './constants';
import { BOESlideoutFormValues } from './types';
import { useCreateBackOfEnvelope } from './hooks/useCreateBOE';
import { prepareBackOfEnvelopePayload } from './helpers';
import { useBOECalculations, useNumberFormatter } from './hooks';
import { useUpdateBackOfEnvelope } from './hooks/useUpdateBOE';
import SlideOutReturns from './SlideOutReturns';
import { getDefaultValues } from '../../helper';

interface BOESlideoutFormProps {
    initialValues?: BOESlideoutFormValues | null;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    setShowLoanSizingModal: React.Dispatch<React.SetStateAction<boolean>>;
    isEditing?: boolean;
    orgId?: string;
    deal?: Deal;
    toggleSlideout: () => void;
}

const DISPLAY_UNIT_OF_TIME_OPTIONS = UNIT_OF_TIME_OPTIONS.map(option => ({
    ...option,
    // eslint-disable-next-line no-nested-ternary
    label: option.label === 'Monthly' ? 'months' : option.label === 'Annually' ? 'years' : option.label,
}));

const BOESlideoutForm = forwardRef<FormikProps<BOESlideoutFormValues>, BOESlideoutFormProps>(
    ({
        initialValues, setShowModal, setShowLoanSizingModal, isEditing, orgId, deal, toggleSlideout,
    }, ref) => {
        const { selectedOrganizationId } = useAuthContext();

        const getDisplayValue = useCallback((value: number | null | undefined, placeholder: string = 'TBD'): string => {
            if (value === 0 || Number.isNaN(value)) {
                return placeholder;
            }
            return numeralFormatterCurrency(value ?? 0);
        }, []);

        const { mutate: createBackOfEnvelope } = useCreateBackOfEnvelope();
        const { mutate: updateEnvelope } = useUpdateBackOfEnvelope();
        const { data: assetTypeValuesRaw } = useGetAssetTypes();
        const assetTypeOptions = assetTypeValuesRaw
            ?.filter(type => ALLOWED_ASSET_TYPES.includes(type.name))
            .map(({ correlationId, name }) => ({ label: name, value: correlationId }))
            .sort((a, b) => a.label.localeCompare(b.label)) || [];

        const defaultValues = getDefaultValues({ assetTypes: assetTypeOptions, initialAssetTypeId: deal?.assetType?.correlationId });

        const { formatNumber } = useNumberFormatter();
        const queryClient = useQueryClient();
        const { showSuccess, showError } = useToast();

        const navigate = useNavigate();

        const handleSubmit = (values: BOESlideoutFormValues): void => {
            const payload = prepareBackOfEnvelopePayload(values);

            if (isEditing) {
                updateEnvelope(
                    {
                        orgId: selectedOrganizationId!,
                        patchData: { ...payload, dealId: values.dealId },
                        scenarioId: values.id!!,
                    },
                    {
                        onSuccess: data => {
                            console.log('Scenario updated successfully:', data);

                            queryClient.invalidateQueries({ queryKey: ['back-of-envelope'] });
                            toggleSlideout();
                            showSuccess('Back of Envelope successfully updated!');
                        },
                        onError: error => {
                            console.error('Error updating scenario:', error);
                            showError('Failed to update Back of Envelope. Please try again.');
                        },
                    },
                );
            } else {
                createBackOfEnvelope(
                    {
                        orgId: selectedOrganizationId!,
                        postData: { ...payload, ...(values.dealId ? { dealId: values.dealId } : {}) },
                    },
                    {
                        onSuccess: data => {
                            console.log('Scenario created successfully:', data);
                            queryClient.invalidateQueries({ queryKey: ['back-of-envelope'] });
                            queryClient.invalidateQueries({ queryKey: ['back-of-envelope-scenarios', orgId, deal?.id] });
                            toggleSlideout();

                            if (values.createNewDeal) {
                                showSuccess('Back of Envelope successfully created! Redirecting to create new deal form...');
                                navigate(generatePath(URLS.DEALS.FORM, { dealId: 'new' }), {
                                    state: { scenarioId: data.id },
                                });
                            } else {
                                showSuccess('Back of Envelope successfully created!');
                            }
                        },
                        onError: error => {
                            console.error('Error creating scenario:', error);
                            showError('Failed to create Back of Envelope. Please try again.');
                        },
                    },
                );
            }
        };

        return (
            <Formik innerRef={ref} initialValues={initialValues || defaultValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                {({
                    values, handleChange, handleBlur, setFieldValue, errors, touched, isValid, dirty,
                }) => {
                    const isApartment = values.selectedAssetType?.label === ASSET_TYPE.RENTAL_APARTMENT;

                    const {
                        sfPerUnit,
                        exitValuation,
                        netRentable,
                        grossPotentialRent,
                        expenseTotal,
                        netOperatingIncome,
                        developerFeeTotal,
                        interestReserveTotal,
                        loanAmount,
                        equityCost,
                        hardCostsTotal,
                        softCostsTotal,
                        returnOnCost,
                        returnOnEquity,
                        profitReturn,
                        totalDevelopmentCosts,
                    } = useBOECalculations(values);

                    // Iterative Calculation
                    useEffect(() => {
                        setFieldValue('developerFeeTotal', developerFeeTotal);
                        setFieldValue('totalDevelopmentCosts', totalDevelopmentCosts);
                        setFieldValue('loanAmount', loanAmount);
                        setFieldValue('interestReserveTotal', interestReserveTotal);
                        setFieldValue('equityCost', equityCost);
                    }, [developerFeeTotal, hardCostsTotal, softCostsTotal, loanAmount, interestReserveTotal, equityCost, setFieldValue]);

                    const previousValuesRef = useRef({
                        rentPriceSubdiv: values.rentPriceSubdiv,
                        expenseCostSubdiv: values.expenseCostSubdiv,
                        softCostsSubdiv: values.softCostsSubdiv,
                        hardCostsSubdiv: values.hardCostsSubdiv,
                    });

                    const showNetOperatingIncome = grossPotentialRent && expenseTotal;
                    const showExitCapRate = showNetOperatingIncome && exitValuation;
                    const showTotalDevelopmentCosts =
                        values.acquisitionCosts && hardCostsTotal && softCostsTotal && developerFeeTotal && interestReserveTotal;
                    const showTotalDevFee = values.developerFee && showTotalDevelopmentCosts;
                    const showLoanAmount = values.loanTerm && values.contructionFinancing;
                    const showRequiredEquity = showLoanAmount && totalDevelopmentCosts;

                    return (
                        <Form className="BOESlideoutForm">
                            <div>
                                <NSCard className="p-3 w-50">
                                    <span className="mr-2 mb-1 text-primary font-weight-bold BOESlideoutForm__title">ASSET TYPE</span>
                                    <NSDropdown
                                        options={assetTypeOptions}
                                        selectedOption={values.selectedAssetType}
                                        handleSelectOption={option => {
                                            setFieldValue('selectedAssetType', option);

                                            if (option?.label !== ASSET_TYPE.RENTAL_APARTMENT) {
                                                // Store current values before overwriting them
                                                previousValuesRef.current = {
                                                    rentPriceSubdiv: values.rentPriceSubdiv,
                                                    expenseCostSubdiv: values.expenseCostSubdiv,
                                                    softCostsSubdiv: values.softCostsSubdiv,
                                                    hardCostsSubdiv: values.hardCostsSubdiv,
                                                };

                                                const perSfOption = 'sf';
                                                setFieldValue('rentPriceSubdiv', perSfOption);
                                                setFieldValue('expenseCostSubdiv', perSfOption);
                                                setFieldValue('softCostsSubdiv', perSfOption);
                                                setFieldValue('hardCostsSubdiv', perSfOption);
                                            } else {
                                                // Restore previous values
                                                setFieldValue('rentPriceSubdiv', previousValuesRef.current.rentPriceSubdiv);
                                                setFieldValue('expenseCostSubdiv', previousValuesRef.current.expenseCostSubdiv);
                                                setFieldValue('softCostsSubdiv', previousValuesRef.current.softCostsSubdiv);
                                                setFieldValue('hardCostsSubdiv', previousValuesRef.current.hardCostsSubdiv);
                                            }
                                        }}
                                        customSelectedOptionText={values.selectedAssetType?.label}
                                        containerClassName="mr-2"
                                        isFullWidth
                                        toggleClassName="BOESlideoutForm__asset-type-dropdown"
                                    />
                                </NSCard>
                                <div className="BOESlideoutForm__separator" />
                                <NSCard className="p-3 w-50">
                                    <span className="mr-2 mb-1 text-primary font-weight-bold BOESlideoutForm__title">PROPERTY SIZE</span>
                                    <div className="d-flex justify-content-between">
                                        <div className="flex-grow-1 mr-2">
                                            <NSInput
                                                id="grossSquareFootage"
                                                type="number"
                                                label="Built gross SF"
                                                name="grossSquareFootage"
                                                placeholder="Enter amount"
                                                appendInputAddon={<span>SF</span>}
                                                value={values.grossSquareFootage}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                errorMsg={touched.grossSquareFootage && errors.grossSquareFootage ? errors.grossSquareFootage : null}
                                                showErrorMsg={!!(touched.grossSquareFootage && errors.grossSquareFootage)}
                                            />
                                            <div className="mt-1">
                                                Net rentable SF:
                                                {' '}
                                                <span className="text-white">{formatNumber(netRentable)}</span>
                                            </div>
                                        </div>
                                        <div className="flex-grow-2">
                                            <NSInput
                                                id="efficiency"
                                                label="Efficency ratio"
                                                name="efficiency"
                                                placeholder=""
                                                appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                                                type="number"
                                                inputClassName="mb-1"
                                                value={values.efficiency}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    const newValue = Math.min(Math.max(event.target.valueAsNumber, 0), 100);
                                                    setFieldValue('efficiency', newValue);
                                                }}
                                                onBlur={handleBlur}
                                                errorMsg={touched.efficiency && errors.efficiency ? errors.efficiency : null}
                                                showErrorMsg={!!(touched.efficiency && errors.efficiency)}
                                                includeRange
                                            />
                                        </div>
                                    </div>
                                </NSCard>
                                <div className="d-flex">
                                    <NSCard className="p-3 w-50">
                                        <span className="mr-2 mb-1 text-primary font-weight-bold BOESlideoutForm__title">INCOME</span>
                                        <div>
                                            <div className="d-flex mb-1">
                                                <NSDropdown
                                                    options={UNIT_OF_TIME_OPTIONS}
                                                    selectedOption={UNIT_OF_TIME_OPTIONS.find(option => option.value === values.rentPriceTime)}
                                                    customSelectedOptionText={
                                                        UNIT_OF_TIME_OPTIONS.find(option => option.value === values.rentPriceTime)?.label || ''
                                                    }
                                                    handleSelectOption={option => setFieldValue('rentPriceTime', option.value)}
                                                    badgeColor="dark-lighten"
                                                    isBadgeToggle
                                                />
                                                <span className="ml-2 text-white">Rent price</span>
                                            </div>
                                            <NSInput
                                                id="rentPrice"
                                                type="number"
                                                label=""
                                                name="rentPrice"
                                                placeholder="Enter amount"
                                                prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                                                appendInputAddon={(
                                                    <NSDropdown
                                                        options={
                                                            isApartment
                                                                ? COST_BASIS_OPTIONS
                                                                : COST_BASIS_OPTIONS.filter(option => option?.value === COST_BASIS_TYPES.PER_SF)
                                                        }
                                                        selectedOption={COST_BASIS_OPTIONS.find(option => option.value === values.rentPriceSubdiv)}
                                                        customSelectedOptionText={
                                                            COST_BASIS_OPTIONS.find(option => option.value === values.rentPriceSubdiv)?.label || ''
                                                        }
                                                        handleSelectOption={option => setFieldValue('rentPriceSubdiv', option.value)}
                                                        badgeColor=""
                                                        isBadgeToggle
                                                        toggleClassName="p-0 m-0"
                                                    />
                                                )}
                                                value={values.rentPrice}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                errorMsg={touched.rentPrice && errors.rentPrice ? errors.rentPrice : null}
                                                showErrorMsg={!!(touched.rentPrice && errors.rentPrice)}
                                            />
                                            <div className="mt-2">
                                                <NSInput
                                                    id="units"
                                                    label={values.selectedAssetType?.label === ASSET_TYPE.RENTAL_APARTMENT ? 'Units' : 'Suites'}
                                                    name="units"
                                                    placeholder=""
                                                    type="number"
                                                    inputClassName="mb-1"
                                                    value={values.units}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errorMsg={touched.units && errors.units ? errors.units : null}
                                                    showErrorMsg={!!(touched.units && errors.units)}
                                                />
                                                <span className="mt-1">
                                                    SF per unit:
                                                    {' '}
                                                    <span className="text-white">{formatNumber(sfPerUnit)}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </NSCard>
                                    <FontAwesomeIcon className="align-self-center mx-2" icon={faChevronDoubleRight} />
                                    <NSCard className="d-flex justify-content-center flex-grow-1 align-self-center p-3 mb-0">
                                        <span className="d-flex align-items-center">Gross potential rent</span>
                                        <span className="BOESlideoutForm__ text-white">
                                            <span className="font-weight-bold">{getDisplayValue(grossPotentialRent, '-')}</span>
                                            <span>{grossPotentialRent ? ' annually' : ''}</span>
                                        </span>
                                    </NSCard>
                                </div>
                                <div className="d-flex">
                                    <NSCard className="p-3 w-50">
                                        <span className="mr-2 mb-1 text-primary font-weight-bold BOESlideoutForm__title">EXPENSES</span>
                                        <div>
                                            <div className="d-flex mb-1">
                                                <NSDropdown
                                                    options={UNIT_OF_TIME_OPTIONS}
                                                    selectedOption={UNIT_OF_TIME_OPTIONS.find(option => option.value === values.expenseCostTime)}
                                                    customSelectedOptionText={
                                                        UNIT_OF_TIME_OPTIONS.find(option => option.value === values.expenseCostTime)?.label || ''
                                                    }
                                                    handleSelectOption={option => setFieldValue('expenseCostTime', option.value)}
                                                    badgeColor="dark-lighten"
                                                    isBadgeToggle
                                                />
                                                <span className="ml-2 text-white">Expense cost</span>
                                            </div>
                                            <NSInput
                                                id="expenses"
                                                type="number"
                                                label=""
                                                name="expenses"
                                                placeholder="Enter amount"
                                                inputClassName="mb-1"
                                                prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                                                appendInputAddon={(
                                                    <NSDropdown
                                                        options={
                                                            isApartment
                                                                ? COST_BASIS_OPTIONS
                                                                : COST_BASIS_OPTIONS.filter(option => option?.value === COST_BASIS_TYPES.PER_SF)
                                                        }
                                                        selectedOption={COST_BASIS_OPTIONS.find(option => option.value === values.expenseCostSubdiv)}
                                                        customSelectedOptionText={
                                                            COST_BASIS_OPTIONS.find(option => option.value === values.expenseCostSubdiv)?.label || ''
                                                        }
                                                        handleSelectOption={option => setFieldValue('expenseCostSubdiv', option.value)}
                                                        badgeColor=""
                                                        isBadgeToggle
                                                        toggleClassName="p-0 m-0"
                                                    />
                                                )}
                                                value={values.expenses}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                errorMsg={touched.expenses && errors.expenses ? errors.expenses : null}
                                                showErrorMsg={!!(touched.expenses && errors.expenses)}
                                            />
                                            <span className="mt-1">
                                                Expenses total:
                                                {' '}
                                                <span className="text-white">{getDisplayValue(expenseTotal)}</span>
                                            </span>
                                        </div>
                                    </NSCard>
                                    <FontAwesomeIcon className="align-self-center mx-2" icon={faChevronDoubleRight} />
                                    <NSCard className="d-flex justify-content-center flex-grow-1 align-self-center p-3 mb-0">
                                        <span className="d-flex align-items-center">Net operating income</span>
                                        <span className="BOESlideoutForm__ text-white">
                                            <span className="font-weight-bold">
                                                {showNetOperatingIncome ? getDisplayValue(netOperatingIncome, '-') : '-'}
                                            </span>
                                            <span>{showNetOperatingIncome ? ' annually' : ''}</span>
                                        </span>
                                    </NSCard>
                                </div>
                                <div className="d-flex">
                                    <NSCard className="p-3 w-50">
                                        <span className="mr-2 mb-1 text-primary font-weight-bold BOESlideoutForm__title">EXIT VALUE</span>
                                        <NSInput
                                            id="exitCapRate"
                                            type="number"
                                            label="Assumed exit cap rate"
                                            name="exitCapRate"
                                            placeholder="Enter amount"
                                            appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                                            value={values.exitCapRate}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                const newValue = Math.min(Math.max(event.target.valueAsNumber, 0), 100);
                                                setFieldValue('exitCapRate', newValue);
                                            }}
                                            onBlur={handleBlur}
                                            errorMsg={touched.exitCapRate && errors.exitCapRate ? errors.exitCapRate : null}
                                            showErrorMsg={!!(touched.exitCapRate && errors.exitCapRate)}
                                            rangeInputClassName="mt-1"
                                            includeRange
                                        />
                                    </NSCard>
                                    <FontAwesomeIcon className="align-self-center mx-2" icon={faChevronDoubleRight} />
                                    <NSCard className="d-flex justify-content-center flex-grow-1 align-self-center p-3 mb-0">
                                        <span className="d-flex align-items-center">Exit valuation</span>
                                        <span className="BOESlideoutForm__ text-white font-weight-bold">
                                            {showExitCapRate ? getDisplayValue(exitValuation, '-') : '-'}
                                        </span>
                                    </NSCard>
                                </div>
                                <div className="d-flex">
                                    <NSCard className="p-3 w-50">
                                        <span className="mr-2 mb-1 text-primary font-weight-bold BOESlideoutForm__title">DEVELOPMENT COSTS</span>
                                        <NSInput
                                            id="acquisitionCosts"
                                            type="number"
                                            label="Acquisition costs"
                                            name="acquisitionCosts"
                                            placeholder="Enter amount"
                                            prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                                            value={values.acquisitionCosts}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errorMsg={touched.acquisitionCosts && errors.acquisitionCosts ? errors.acquisitionCosts : null}
                                            showErrorMsg={!!(touched.acquisitionCosts && errors.acquisitionCosts)}
                                        />
                                        <NSInput
                                            id="hardCosts"
                                            type="number"
                                            label="Hard costs"
                                            name="hardCosts"
                                            placeholder="Enter amount"
                                            labelClassName="mt-2"
                                            prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                                            appendInputAddon={(
                                                <NSDropdown
                                                    options={
                                                        isApartment
                                                            ? COST_BASIS_OPTIONS
                                                            : COST_BASIS_OPTIONS.filter(option => option?.value === COST_BASIS_TYPES.PER_SF)
                                                    }
                                                    selectedOption={COST_BASIS_OPTIONS.find(option => option.value === values.hardCostsSubdiv)}
                                                    customSelectedOptionText={
                                                        COST_BASIS_OPTIONS.find(option => option.value === values.hardCostsSubdiv)?.label || ''
                                                    }
                                                    handleSelectOption={option => setFieldValue('hardCostsSubdiv', option.value)}
                                                    badgeColor=""
                                                    isBadgeToggle
                                                    toggleClassName="p-0 m-0"
                                                />
                                            )}
                                            value={values.hardCosts}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errorMsg={touched.hardCosts && errors.hardCosts ? errors.hardCosts : null}
                                            showErrorMsg={!!(touched.hardCosts && errors.hardCosts)}
                                        />
                                        <span className="mt-1">
                                            Hard costs total:
                                            {' '}
                                            <span className="text-white">{getDisplayValue(hardCostsTotal)}</span>
                                        </span>
                                        <NSInput
                                            id="softCosts"
                                            type="number"
                                            label="Soft costs"
                                            name="softCosts"
                                            placeholder="Enter amount"
                                            labelClassName="mt-3"
                                            prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                                            appendInputAddon={(
                                                <NSDropdown
                                                    options={
                                                        isApartment
                                                            ? COST_BASIS_OPTIONS
                                                            : COST_BASIS_OPTIONS.filter(option => option?.value === COST_BASIS_TYPES.PER_SF)
                                                    }
                                                    selectedOption={COST_BASIS_OPTIONS.find(option => option.value === values.softCostsSubdiv)}
                                                    customSelectedOptionText={
                                                        COST_BASIS_OPTIONS.find(option => option.value === values.softCostsSubdiv)?.label || ''
                                                    }
                                                    handleSelectOption={option => setFieldValue('softCostsSubdiv', option.value)}
                                                    badgeColor=""
                                                    isBadgeToggle
                                                    toggleClassName="p-0 m-0"
                                                />
                                            )}
                                            value={values.softCosts}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errorMsg={touched.softCosts && errors.softCosts ? errors.softCosts : null}
                                            showErrorMsg={!!(touched.softCosts && errors.softCosts)}
                                        />
                                        <span className="mt-1">
                                            Soft costs total:
                                            {' '}
                                            <span className="text-white">{getDisplayValue(softCostsTotal)}</span>
                                        </span>
                                        <NSInput
                                            id="developerFee"
                                            type="number"
                                            label="Developer fee"
                                            name="developerFee"
                                            placeholder="Enter amount"
                                            labelClassName="mt-3"
                                            inputClassName="w-50"
                                            appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                                            value={values.developerFee}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            tooltipMsg="Developer fee is calculated on total development costs."
                                            errorMsg={touched.developerFee && errors.developerFee ? errors.developerFee : null}
                                            showErrorMsg={!!(touched.developerFee && errors.developerFee)}
                                        />
                                        <span className="mt-1">
                                            Developer fee total:
                                            {' '}
                                            <span className="text-white">{showTotalDevFee ? getDisplayValue(values.developerFeeTotal) : 'TBD'}</span>
                                        </span>
                                        <span className="mt-2 text-white">Financing costs</span>
                                        <NSCard className="NSCard--level-2 p-2 mt-1">
                                            <div className="d-flex">
                                                <div className="mr-2">
                                                    <NSInput
                                                        id="apr"
                                                        type="number"
                                                        label="APR"
                                                        name="apr"
                                                        placeholder="Enter amount"
                                                        appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                                                        value={values.apr}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            const newValue = Math.min(Math.max(event.target.valueAsNumber, 0), 100);
                                                            setFieldValue('apr', newValue);
                                                        }}
                                                        onBlur={handleBlur}
                                                        errorMsg={touched.apr && errors.apr ? errors.apr : null}
                                                        showErrorMsg={!!(touched.apr && errors.apr)}
                                                        rangeInputClassName="mt-1"
                                                        includeRange
                                                    />
                                                </div>
                                                <div>
                                                    <NSInput
                                                        id="avgLoanBalance"
                                                        type="number"
                                                        label="Average loan balance"
                                                        name="avgLoanBalance"
                                                        placeholder="Enter amount"
                                                        appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                                                        value={values.avgLoanBalance}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            const newValue = Math.min(Math.max(event.target.valueAsNumber, 0), 100);
                                                            setFieldValue('avgLoanBalance', newValue);
                                                        }}
                                                        onBlur={handleBlur}
                                                        errorMsg={touched.avgLoanBalance && errors.avgLoanBalance ? errors.avgLoanBalance : null}
                                                        showErrorMsg={!!(touched.avgLoanBalance && errors.avgLoanBalance)}
                                                        rangeInputClassName="mt-1"
                                                        includeRange
                                                    />
                                                </div>
                                            </div>
                                            <NSInput
                                                id="loanTerm"
                                                type="number"
                                                label="Loan term"
                                                name="loanTerm"
                                                placeholder="Enter amount"
                                                labelClassName="mt-2"
                                                appendInputAddon={(
                                                    <NSDropdown
                                                        options={DISPLAY_UNIT_OF_TIME_OPTIONS}
                                                        selectedOption={DISPLAY_UNIT_OF_TIME_OPTIONS.find(option => option.value === values.loanTermTime)}
                                                        customSelectedOptionText={
                                                            DISPLAY_UNIT_OF_TIME_OPTIONS.find(option => option.value === values.loanTermTime)?.label || ''
                                                        }
                                                        handleSelectOption={option => setFieldValue('loanTermTime', option.value)}
                                                        badgeColor=""
                                                        isBadgeToggle
                                                        toggleClassName="p-0 m-0"
                                                    />
                                                )}
                                                value={values.loanTerm}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                errorMsg={touched.loanTerm && errors.loanTerm ? errors.loanTerm : null}
                                                showErrorMsg={!!(touched.loanTerm && errors.loanTerm)}
                                            />
                                            <span className="mt-1">
                                                Interest reserve total:
                                                {' '}
                                                <span className="text-white">{getDisplayValue(values.interestReserveTotal)}</span>
                                            </span>
                                        </NSCard>
                                    </NSCard>
                                    <FontAwesomeIcon className="align-self-center mx-2" icon={faChevronDoubleRight} />
                                    <NSCard className="d-flex justify-content-center flex-grow-1 align-self-center p-3 mb-0">
                                        <span className="">Total development costs (uses)</span>
                                        <span className="BOESlideoutForm__ text-white font-weight-bold">
                                            {showTotalDevelopmentCosts ? getDisplayValue(values.totalDevelopmentCosts, '-') : '-'}
                                        </span>
                                    </NSCard>
                                </div>
                                <div className="d-flex">
                                    <NSCard className="p-3 pb-3 w-50 mb-0">
                                        <div className="d-flex justify-content-between">
                                            <div className="mr-2 mb-1 text-primary font-weight-bold BOESlideoutForm__title">CONSTRUCTION FINANCING</div>
                                            <div
                                                aria-hidden
                                                className="mr-2 mb-1 text-primary font-weight-bold BOESlideoutForm__title BOESlideoutForm__loan-sizing"
                                                onClick={() => setShowLoanSizingModal(true)}
                                            >
                                                Loan sizing test
                                                <span />
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </div>
                                        </div>
                                        <div className="d-flex mb-1">
                                            <NSDropdown
                                                options={LOAN_CALCULATION_OPTIONS}
                                                selectedOption={values.selectedLoanCalculationOptions}
                                                handleSelectOption={option => setFieldValue('selectedLoanCalculationOptions', option)}
                                                customSelectedOptionText={values.selectedLoanCalculationOptions?.label}
                                                badgeColor="dark-lighten"
                                                isBadgeToggle
                                            />
                                        </div>
                                        <NSInput
                                            id="contructionFinancing"
                                            type="number"
                                            label=""
                                            name="contructionFinancing"
                                            placeholder="Enter amount"
                                            appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                                            value={values.contructionFinancing}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                const newValue = Math.min(Math.max(event.target.valueAsNumber, 0), 100);
                                                setFieldValue('contructionFinancing', newValue);
                                            }}
                                            onBlur={handleBlur}
                                            errorMsg={touched.contructionFinancing && errors.contructionFinancing ? errors.contructionFinancing : null}
                                            showErrorMsg={!!(touched.contructionFinancing && errors.contructionFinancing)}
                                            rangeInputClassName="mt-1"
                                            includeRange
                                        />
                                        <RenderIf isTrue={values.selectedLoanCalculationOptions?.value === LOAN_CALCULATION_TYPES.LTV}>
                                            <NSInput
                                                id="marketCapRate"
                                                type="number"
                                                label="Market cap rate"
                                                name="marketCapRate"
                                                placeholder="Enter amount"
                                                appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                                                value={values.marketCapRate}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    const newValue = Math.min(Math.max(event.target.valueAsNumber, 0), 100);
                                                    setFieldValue('marketCapRate', newValue);
                                                }}
                                                onBlur={handleBlur}
                                                errorMsg={touched.marketCapRate && errors.marketCapRate ? errors.marketCapRate : null}
                                                showErrorMsg={!!(touched.marketCapRate && errors.marketCapRate)}
                                                labelClassName="mt-2"
                                                rangeInputClassName="mt-1"
                                                includeRange
                                            />
                                        </RenderIf>
                                    </NSCard>

                                    <FontAwesomeIcon className="align-self-center mx-2" icon={faChevronDoubleRight} />
                                    <div className="d-row justify-content-center align-self-center flex-grow-1">
                                        <NSCard className="p-3 mb-2">
                                            <span className="d-flex align-items-center">Loan amount</span>
                                            <span className="BOESlideoutForm__ text-white font-weight-bold">
                                                {showLoanAmount ? getDisplayValue(values.loanAmount, '-') : '-'}
                                            </span>
                                        </NSCard>
                                        <NSCard className="p-3 mb-0">
                                            <div className="d-flex align-items-center">Required equity</div>
                                            <span className="BOESlideoutForm__ text-white font-weight-bold">
                                                {showRequiredEquity ? getDisplayValue(values.equityCost, '-') : '-'}
                                            </span>
                                        </NSCard>
                                    </div>
                                </div>
                                <SlideOutReturns
                                    targetProfitReturn={profitReturn}
                                    targetReturnOnCost={returnOnCost}
                                    targetReturnOnEquity={returnOnEquity}
                                    forceExpanded
                                />
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        );
    },
);

export default BOESlideoutForm;
