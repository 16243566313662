import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../../../ns_libs/nsAxios';
import API_ENDPOINTS from '../../../services/urls';
import { ISubprojectStage } from '../types';

export const getSubprojectsStages = async () => {
    const response = await axiosInstance.get(API_ENDPOINTS.SUBPROJECT_STAGES);
    return response.data;
};

export const useGetSubprojectsStages = () => useQuery<ISubprojectStage[], Error>({
    queryKey: ['subprojectsStages'],
    queryFn: async () => getSubprojectsStages(),
    staleTime: Infinity,
});
