import React, { Dispatch, SetStateAction } from 'react';
import { NSBadge, NSSelect } from 'bricks';
import { customStyles } from 'bricks/NSSelect/NSSelect';
import { IOptionAnyValue } from 'bricks/types';
import RenderIf from 'components/RenderIf/RenderIf';
import classNames from 'classnames';

export interface IUsesSnapshotSelectProps {
    selectedSnapshot: number | null;
    setSelectedSnapshot: Dispatch<SetStateAction<number | null>>;
    toggleNewSnapshotModal: () => void;
}

const UsesSnapshotSelect = ({ selectedSnapshot, setSelectedSnapshot, toggleNewSnapshotModal }: IUsesSnapshotSelectProps) => {
    const snapshotOptions = [
        { label: 'Snapshot 1', value: 1 },
        { label: 'Snapshot 2', value: 2 },
    ];

    const handleSelectSnapshot = (option: IOptionAnyValue) => {
        if (option.value !== 0) {
            setSelectedSnapshot(option.value);
        } else {
            toggleNewSnapshotModal();
        }
    };

    const newSnapshotButtonOption = { label: '+ New snapshot', value: 0 };

    return (
        <NSSelect
            name="usesSnapshotSelect"
            options={[...snapshotOptions, newSnapshotButtonOption]}
            value={snapshotOptions.find(option => option.value === selectedSnapshot) || null}
            onChange={option => handleSelectSnapshot(option)}
            isClearable={false}
            isSearchable={false}
            menuPortalTarget={document.body}
            menuPosition="absolute"
            placeholder="View snapshot"
            formatOptionLabel={(option: { label: string; value: number }, context: { context: string }) => (
                <div className="d-flex justify-content-between">
                    <RenderIf isTrue={context.context === 'value'}>
                        <div>
                            Snapshot:
                            <span className="text-dark ml-1">{option.label}</span>
                        </div>
                    </RenderIf>

                    <RenderIf isTrue={context.context === 'menu'}>
                        <div className={classNames({ 'text-primary': option.label === newSnapshotButtonOption.label })}>{option.label}</div>
                    </RenderIf>

                    <RenderIf isTrue={option.value === selectedSnapshot && context.context === 'menu'}>
                        <NSBadge color="primary-lighten ml-1">Active</NSBadge>
                    </RenderIf>
                </div>
            )}
            styles={{
                ...customStyles,
                placeholder: (provided: any) => ({
                    ...provided,
                    color: '#aab8c5!important',
                    whiteSpace: 'nowrap',
                }),
                singleValue: (provided: any) => ({
                    ...provided,
                    color: '#aab8c5!important',
                }),
            }}
        />
    );
};

export default UsesSnapshotSelect;
