import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftToLine, faArrowRightToLine, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { Nav, NavItem } from 'reactstrap';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { URLS } from 'services/urls';
import RenderIf from 'components/RenderIf/RenderIf';
import { IOptionAnyValue } from 'bricks/types';
import { PRO_FORMA_TABLE_NAV } from '../../constants';
import { NSButton, NSCard } from '../../../../bricks';
import EditProFormaModal from '../ProForma/EditProFormaModal/EditProFormaModal';
import ProFormaSideNavFooter from './ProFormaSideNavFooter/ProFormaSideNavFooter';
import './ProFormaSideNav.scss';
import { useProFormaSocketContext } from '../ProForma/socketContext/ProFormaSocketProvider';

export interface IProFormaSideNavProps {
    activeNav: string;
    navigateToSection: (sideNavName: string, tabName?: string) => void;
    hasWriteAccessToProForma: boolean;
    setSelectedPeriod: (period: IOptionAnyValue) => void;
    selectedPeriod: IOptionAnyValue;
}

const ProFormaSideNav = ({
    activeNav,
    navigateToSection,
    setSelectedPeriod,
    selectedPeriod,
    hasWriteAccessToProForma,
}: IProFormaSideNavProps) => {
    const { proForma } = useProFormaSocketContext();
    const navigate = useNavigate();
    const { dealId } = useParams<{ dealId: string }>();

    const [showEditProFormaModal, setShowEditProFormaModal] = useState(false);
    const toggleEditProFormaModal = () => setShowEditProFormaModal(!showEditProFormaModal);

    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [isManuallyCollapsed, setIsManuallyCollapsed] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 1200px)');
        const handleResize = () => {
            const smallScreen = mediaQuery.matches;
            setIsSmallScreen(smallScreen);
            setIsCollapsed(smallScreen || isManuallyCollapsed);
        };

        handleResize();
        mediaQuery.addEventListener('change', handleResize);
        return () => mediaQuery.removeEventListener('change', handleResize);
    }, [isManuallyCollapsed]);

    const toggleSideNav = () => {
        if (!isSmallScreen) {
            const newCollapsedState = !isCollapsed;
            setIsManuallyCollapsed(newCollapsedState);
            setIsCollapsed(newCollapsedState);
        }
    };

    const navItemClass = (tag: string) => classNames('d-flex align-items-center px-4 cursor--pointer py-2', {
        'bg-primary-lighten text-primary font-weight-bold': tag === activeNav,
        'text-muted': tag !== activeNav,
    });

    const navigateToProFormaList = () => {
        const path = generatePath(URLS.PRO_FORMA.HOME, { dealId });
        navigate(path);
    };

    const hiddenWhenCollapsedClass = classNames({
        'ProFormaSideNav--hidden': isCollapsed,
    });

    return (
        <div className={classNames('ProFormaSideNav-wrapper', { 'ProFormaSideNav-wrapper--collapsed': isCollapsed })}>
            <NSCard className="NSCard--level-2 ProFormaSideNav">
                <div className={`px-3 pt-3 ${hiddenWhenCollapsedClass}`}>
                    <NSButton
                        callback={navigateToProFormaList}
                        color="primary"
                        className="font-13 px-2 mb-2"
                        size="sm"
                        outline
                        allowReadOnlyAccess
                    >
                        <FontAwesomeIcon icon={faChevronLeft} className="mr-1" size="xs" />
                        Exit
                    </NSButton>
                    <h4 className="text-dark mb-0 font-weight-bold">{proForma?.name}</h4>
                    <h5 className="text-muted mt-1 font-16 text-wrap">{proForma?.description}</h5>
                    <RenderIf isTrue={hasWriteAccessToProForma}>
                        <span role="presentation" onClick={toggleEditProFormaModal} className="mt-1 mb-0 font-13 text-primary cursor--pointer">
                            Edit name/description
                        </span>
                    </RenderIf>
                </div>
                <hr className={`mb-0 ${hiddenWhenCollapsedClass}`} />

                <Nav tabs className="ProFormaSideNav__nav flex-column w-100 border-0">
                    {Object.entries(PRO_FORMA_TABLE_NAV).map(([key, nav]) => (
                        <NavItem key={key} className={navItemClass(nav.name)} onClick={() => navigateToSection(nav.name)} data-toggle="tab">
                            <FontAwesomeIcon icon={nav.icon} className="py-1" />
                            <div className={`pl-1 ${hiddenWhenCollapsedClass}`} aria-hidden="true">
                                <span>{nav.name}</span>
                            </div>
                        </NavItem>
                    ))}
                </Nav>
                <ProFormaSideNavFooter setSelectedPeriod={setSelectedPeriod} selectedPeriod={selectedPeriod} isCollapsed={isCollapsed} />

                <RenderIf isTrue={!isSmallScreen}>
                    <FontAwesomeIcon
                        icon={isCollapsed ? faArrowRightToLine : faArrowLeftToLine}
                        size="sm"
                        className="ProFormaSideNav__toggle-button text-dark p-2 cursor--pointer rounded"
                        onClick={toggleSideNav}
                    />
                </RenderIf>
            </NSCard>
            <EditProFormaModal showEditProFormaModal={showEditProFormaModal} toggleEditProFormaModal={toggleEditProFormaModal} />
        </div>
    );
};

export default ProFormaSideNav;
