import React from 'react';
import { IGridConfig } from 'components/ComparisonGrid';
import { NSDropdown } from 'bricks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { useScenarioContext } from './context/ScenarioComparisonProvider';
import { Scenario } from './types';
import { formatCurrencyComparison, formatLoanTerm, formatPercentageComparison } from './helper';

export const MAX_SCENARIOS_FOR_PDF = 5;

function headerDefaultFormatter(data: Scenario): JSX.Element {
    const { getAvailableOptions, switchScenario, removeScenario } = useScenarioContext();

    const handleSelection = (scenario: { value: string; label: string }) => {
        switchScenario(data.id, { id: scenario.value, name: scenario.label });
    };

    const handleRemove = () => {
        removeScenario(data.id);
    };

    return (
        <div className="d-flex align-items-center w-100 h-100 mr-2 p-2">
            <NSDropdown
                options={getAvailableOptions()}
                selectedOption={{ label: data.name, value: data.id }}
                handleSelectOption={handleSelection}
                placeholder="Select scenario"
                toggleClassName="px-1 py-0 mr-1 m-0 ScenarioComparison__badge-selector"
            />
            <FontAwesomeIcon className="ScenarioComparison__x-mark-button" icon={faXmark} size="lg" onClick={handleRemove} />
        </div>
    );
}

export const gridConfig: Omit<IGridConfig, 'data'> = {
    headerRowFormatter: headerDefaultFormatter,
    groups: [
        {
            groupLabel: 'Returns',
            rows: [
                { label: 'Return on cost', formatter: deal => formatPercentageComparison(deal.returnOnCost), fieldName: 'returnOnCost' },
                { label: 'Return on equity', formatter: deal => formatPercentageComparison(deal.returnOnEquity), fieldName: 'returnOnEquity' },
                { label: 'Profit return', formatter: deal => formatCurrencyComparison(deal.profitReturn), fieldName: 'profitReturn' },
            ],
        },
        {
            groupLabel: 'Propery size',
            rows: [
                { label: 'Built gross SF', formatter: deal => formatCurrencyComparison(deal.builtSf), fieldName: 'builtSf' },
                {
                    label: 'Building efficiency',
                    formatter: deal => formatPercentageComparison(deal.buildingEfficiency * 100),
                    fieldName: 'buildingEfficiency',
                },
                { label: 'Net rentable SF', formatter: deal => formatCurrencyComparison(deal.netRentableSf), fieldName: 'netRentableSf' },
            ],
        },
        {
            groupLabel: 'Income',
            rows: [
                {
                    label: 'Rent price per unit',
                    formatter: deal => formatCurrencyComparison(deal.monthlyRentPricePerUnit),
                    fieldName: 'monthlyRentPricePerUnit',
                },
                { label: 'Units', formatter: deal => deal.numberOfUnits },
                { label: 'SF per unit', formatter: deal => formatCurrencyComparison(deal.sfPerUnit), fieldName: 'sfPerUnit' },
                {
                    label: 'Gross potential rent',
                    formatter: deal => formatCurrencyComparison(deal.grossPotentialRent),
                    fieldName: 'grossPotentialRent',
                },
            ],
        },
        {
            groupLabel: 'Expenses',
            rows: [
                {
                    label: 'Expense cost per unit',
                    formatter: deal => formatCurrencyComparison(deal.monthlyExpenseCostPerUnit),
                    fieldName: 'monthlyExpenseCostPerUnit',
                },
                { label: 'Expenses total', formatter: deal => formatCurrencyComparison(deal.expensesTotal), fieldName: 'expensesTotal' },
                {
                    label: 'Net operating income',
                    formatter: deal => formatCurrencyComparison(deal.netOperatingIncome),
                    fieldName: 'netOperatingIncome',
                },
            ],
        },
        {
            groupLabel: 'Exit value',
            rows: [
                { label: 'Exit cap rate', formatter: deal => formatPercentageComparison(deal.assumedExitCapRate * 100), fieldName: 'assumedExitCapRate' },
                { label: 'Exit valuation', formatter: deal => formatCurrencyComparison(deal.exitValuation), fieldName: 'exitValuation' },
            ],
        },
        {
            groupLabel: 'Development costs',
            rows: [
                { label: 'Acquisition costs', formatter: deal => formatCurrencyComparison(deal.acquisition), fieldName: 'acquisition' },
                { label: 'Hard cost per SF', formatter: deal => formatCurrencyComparison(deal.hardCostsPerSf), fieldName: 'hardCostsPerSf' },
                { label: 'Total hard costs', formatter: deal => formatCurrencyComparison(deal.hardCostsTotal), fieldName: 'hardCostsTotal' },
                { label: 'Soft costs per SF', formatter: deal => formatCurrencyComparison(deal.softCostsPerSf), fieldName: 'softCostsPerSf' },
                { label: 'Total soft costs', formatter: deal => formatCurrencyComparison(deal.softCostsTotal), fieldName: 'softCostsTotal' },
                { label: 'Developer fee', formatter: deal => formatPercentageComparison(deal.developerFeeRate * 100), fieldName: 'developerFeeRate' },
                { label: 'APR', formatter: deal => formatPercentageComparison(deal.annualPercentageRate * 100), fieldName: 'annualPercentageRate' },
                {
                    label: 'Average loan balance',
                    formatter: deal => formatPercentageComparison(deal.averageLoanBalance * 100),
                    fieldName: 'averageLoanBalance',
                },
                {
                    label: 'Loan term',
                    formatter: deal => formatLoanTerm(deal),
                    fieldName: 'loanTermMonths',
                },
                {
                    label: 'Interest reserve total',
                    formatter: deal => formatCurrencyComparison(deal.interestReserveTotal),
                    fieldName: 'interestReserveTotal',
                },
                {
                    label: 'Total development costs',
                    formatter: deal => formatCurrencyComparison(deal.totalDevelopmentCosts),
                    fieldName: 'totalDevelopmentCosts',
                },
            ],
        },
    ],
};

export const highlightCriteria = {
    returnOnCost: 'greatest',
    returnOnEquity: 'greatest',
    profitReturn: 'greatest',
    exitValuation: 'greatest',
    buildingEfficiency: 'greatest',
    netOperatingIncome: 'greatest',
    totalDevelopmentCosts: 'least',
};
