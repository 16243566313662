import React from 'react';
import {
    Card, CardBody, CardTitle, Col, Row,
} from 'reactstrap';
import { NSButton } from 'bricks';
import { formatDate, formatUSDCurrency } from 'ns_libs/formatter';
import { Link } from 'react-router-dom';
import { Deal } from '../../types';
import { useGetSubprojectsStages } from '../../hooks/useGetSubprojectStages';

interface IDataPanelProps {
    detail: Deal;
}
const DataPanel = ({ detail }: IDataPanelProps) => {
    const { data: subprojectStages } = useGetSubprojectsStages();

    const stageName = subprojectStages?.find(
        stage => stage.correlationId === detail?.subprojectStage?.correlationId,
    )?.name;

    return (
        <Card className="h-100 mb-0 border-0 shadow-none">
            <CardBody className="p-2">
                <CardTitle className="border-bottom pb-2 pt-0 mb-2">
                    <Row>
                        <Col xs="9">
                            <h4 className="m-0 text-dark">{detail.dealName}</h4>
                            <p className="text-muted small mb-0">{`${detail.address} ${detail.city} ${detail.state} ${detail.zipCode}`}</p>
                        </Col>
                        <Col xs="3" className="d-flex justify-content-end align-items-center">
                            <Link to={detail?.id ? `/deal/${detail.id}` : '#'} className="text-decoration-none text-white">
                                <NSButton disabled={!detail || !detail.id} className="btn-sm" color="secondary">
                                    View deal
                                </NSButton>
                            </Link>
                        </Col>
                    </Row>
                </CardTitle>
                <Row>
                    <Col>
                        <p className="text-uppercase font-weight-bold text-primary small mb-2">Details</p>
                    </Col>
                </Row>
                <Row className="MapView__Card__Details">
                    <Col xs="6">
                        <p>
                            <span className="text-muted">Market</span>
                            <br />
                            <h5 className="text-dark mt-0">{detail.market?.name}</h5>
                        </p>
                        <p>
                            <span className="text-muted">Deal type</span>
                            <br />
                            <h5 className="text-dark mt-0">{detail.dealType?.name}</h5>
                        </p>
                        <p>
                            <span className="text-muted">Asset class</span>
                            <br />
                            <h5 className="text-dark mt-0">{detail.assetType?.name}</h5>
                        </p>
                        <p>
                            <span className="text-muted">Construction type</span>
                            <br />
                            <h5 className="text-dark mt-0">{detail.constructionType?.name}</h5>
                        </p>
                        <p>
                            <span className="text-muted">Date created</span>
                            <br />
                            <h5 className="text-dark mt-0">{formatDate(detail.createdAt)}</h5>
                        </p>
                    </Col>
                    <Col xs="6">
                        <p>
                            <span className="text-muted">County</span>
                            <br />
                            <h5 className="text-dark mt-0">{detail.county}</h5>
                        </p>
                        <p>
                            <span className="text-muted">Deal owner</span>
                            <br />
                            <h5 className="text-dark mt-0">{detail.dealOwner?.name}</h5>
                        </p>
                        <p>
                            <span className="text-muted">Acquisition price</span>
                            <br />
                            <h5 className="text-dark mt-0">{formatUSDCurrency(detail.acquisitionPrice)}</h5>
                        </p>
                        <p>
                            <span className="text-muted">Phase</span>
                            <br />
                            <h5 className="text-dark mt-0">{stageName}</h5>
                        </p>
                        <p>
                            <span className="text-muted">Lead source</span>
                            <br />
                            <h5 className="text-dark mt-0">{detail.leadSource?.name}</h5>
                        </p>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

DataPanel.propTypes = {};

export default DataPanel;
