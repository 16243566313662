import React, {
    createContext, useContext, useState, useCallback, useRef, useEffect, useMemo,
} from 'react';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { FormikProps } from 'formik';

import { useAuthContext } from 'contexts/AuthContext';
import { NSSlideout, NSButton } from 'bricks';
import DealScenarioDropdown from 'components/DealScenarioDropdown/DealScenarioDropdown';
import { BOESlideoutFormValues } from 'views/DealDashboard/BOE/components/SlideoutForm/types';
import { getDefaultValues, mapScenarioToFormValues } from 'views/DealDashboard/BOE/helper';
import BOESlideoutForm from 'views/DealDashboard/BOE/components/SlideoutForm/BOESlideoutForm';
import { LOAN_CALCULATION_TYPES } from 'views/DealDashboard/BOE/components/SlideoutForm/constants';
import { useDeleteBackOfEnvelope } from 'views/DealDashboard/BOE/components/SlideoutForm/hooks/useDeleteBOE';
import { IOptionAnyValue } from 'bricks/types';
import { useGetBackOfEnvelope } from 'views/DealDashboard/BOE/hooks/useGetBOE';
import { INestedOptionAnyValue, SimplifiedScenario } from 'components/DealScenarioDropdown/types';
import { useGetBackOfEnvelopeDeals } from 'views/DealDashboard/BOE/hooks/useGetBOEDeals';
import { useGetBackOfEnvelopeScenarios } from 'views/DealDashboard/BOE/hooks/useGetBOEScenarios';
import { useGetDealById } from 'views/Deals/hooks/useGetDealById';
import SaveModal from 'views/DealDashboard/BOE/components/SaveModal/SaveModal';
import LoanSizingModal from 'views/DealDashboard/BOE/components/LoanTestModal/LoanSizingModal';
import { ILoanSizingOption, ILoanSizingValue } from 'views/DealDashboard/BOE/components/LoanTestModal/types';
import { useGetAssetTypes } from 'hooks/useGetAssetTypes';
import './BackOfEnvelopeContext.scss';
import useToast from 'hooks/useToast';
import { PageSpecificKeys, useLocalStorage } from 'helpers/localStorage';
import { ILocallyStoredActiveTabName } from 'views/DealDashboard/types';

interface BackOfEnvelopeContextProps {
    formikRef: React.RefObject<any>;
    isOpen: boolean;
    initialValues: BOESlideoutFormValues | null;
    toggleSlideout: () => void;
    resetFormValues: () => void;
    setInitialValues: (values: BOESlideoutFormValues | null) => void;
    setHasEditingValues: (editing: boolean) => void;
    setSelectedScenarioId: (id: number | undefined) => void;
    setSelectedSlideoutScenarioId: (id: number | undefined) => void;
    setSelectedScenario: (scenario: IOptionAnyValue | undefined) => void;
    getSlideoutValues: any;
    selectedScenarioId: number | undefined;
    selectedScenario: IOptionAnyValue | undefined;
}

export const BackOfEnvelopeContext = createContext<BackOfEnvelopeContextProps | undefined>(undefined);

export const useSlideout = () => {
    const context = useContext(BackOfEnvelopeContext);
    if (!context) {
        throw new Error('useSlideout must be used within a SlideoutProvider');
    }
    return context;
};

function convertToSimplifiedScenario(data: SimplifiedScenario[] | null): SimplifiedScenario[] | null {
    if (!data) return null;

    return data.map(item => ({
        name: item.name,
        id: item.id,
        scenarios: [{ id: -1, name: 'default' }],
        hasNestedScenarios: true,
    }));
}

export const BackOfEnvelopeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { selectedOrganizationId } = useAuthContext();
    const { showSuccess } = useToast();

    const [showSaveModal, setShowSaveModal] = useState(false);
    const [showLoanSizingModal, setShowLoanSizingModal] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [hasEditingValues, setHasEditingValues] = useState(false);
    const [isCreatingNewScenario, setIsCreatingNewScenario] = useState(false);
    const [selectedSlideoutScenarioId, setSelectedSlideoutScenarioId] = useState<number | undefined>(undefined);
    const [initialValues, setInitialValues] = useState<BOESlideoutFormValues | null>(null);
    const [selectedScenarioId, setSelectedScenarioId] = useState<number | undefined>(undefined);
    const [selectedScenario, setSelectedScenario] = useState<IOptionAnyValue | undefined>(undefined);
    const [selectedDealForModal, setSelectedDealForModal] = useState<number | null>(null);

    const { dealId } = useParams();
    const queryClient = useQueryClient();

    const { data: dropdownDeals } = useGetBackOfEnvelopeDeals({ orgId: selectedOrganizationId! }); // DEALS WITH NESTED SCENARIOS
    const { data: dropdownScenarios } = useGetBackOfEnvelopeScenarios({ orgId: selectedOrganizationId! }); // UNCATEGORIZED SCENARIOS
    const { mutate: deleteScenario } = useDeleteBackOfEnvelope();
    const { refetch: refetchSlideout } = useGetBackOfEnvelope({
        orgId: selectedOrganizationId!,
        scenarioId: selectedSlideoutScenarioId,
        isForm: true,
    });
    const { data: overviewScenarioData } = useGetBackOfEnvelope({
        orgId: selectedOrganizationId!,
        scenarioId: selectedScenarioId,
    });

    const { data: fetchedDeal } = useGetDealById({
        orgId: selectedOrganizationId!,
        dealId: Number(dealId),
        shouldFetch: !!Number(dealId),
    });

    const [, setLocallyStoredActiveTabName] = useLocalStorage<ILocallyStoredActiveTabName>(
        PageSpecificKeys.DealDashboardPage,
        {},
    );

    const { data: assetTypeValuesRaw } = useGetAssetTypes();
    const assetTypes = assetTypeValuesRaw?.map(({ correlationId, name }) => ({ label: name, value: correlationId })) || [];

    const defaultValues = getDefaultValues({ assetTypes, initialAssetTypeId: fetchedDeal?.assetType?.correlationId });

    const formikRef = useRef<FormikProps<BOESlideoutFormValues>>(null);

    const simplifiedScenarios = convertToSimplifiedScenario(dropdownDeals?.items || null);

    const combinedScenarios = [
        ...(simplifiedScenarios || []),
        ...(dropdownScenarios?.items.map(item => ({
            name: item.name,
            id: item.id,
        })) || []),
    ];

    const resetFormValues = () => {
        if (formikRef?.current) {
            formikRef.current.resetForm({ values: defaultValues });
            setHasEditingValues(false);
            setInitialValues(null);
        }
    };

    const toggleSlideout = useCallback(() => {
        setIsOpen(prev => {
            if (prev) {
                resetFormValues();
            }
            return !prev;
        });
    }, [resetFormValues]);

    const getSlideoutValues = () => {
        const formValues = mapScenarioToFormValues({
            scenario: overviewScenarioData || null,
            options: { assetTypes },
            initialValues: { dealAssetTypeId: fetchedDeal?.assetType?.correlationId },
        });

        if (formikRef?.current && formValues) {
            formikRef.current.setValues(formValues);
        } else {
            setInitialValues(formValues);
        }

        setHasEditingValues(true);
    };

    const handleSave = async () => {
        if (formikRef.current) {
            if (hasEditingValues) {
                const selectedScenarioInUncategorized = dropdownScenarios?.items.find(scenario => scenario.id === selectedSlideoutScenarioId);
                if (selectedScenarioInUncategorized) {
                    setSelectedDealForModal(Number(dealId));
                    setShowSaveModal(true);
                } else {
                    formikRef.current.submitForm();
                }
                return;
            }

            const errors = await formikRef.current.validateForm();
            if (Object.keys(errors).length === 0) {
                setSelectedDealForModal(Number(dealId));
                setShowSaveModal(true);
            } else {
                formikRef.current.setTouched(Object.keys(errors).reduce((acc, key) => ({ ...acc, [key]: true }), {}));
            }
        }
    };

    const handleDelete = useCallback(() => {
        if (!formikRef.current?.values.id) return;

        deleteScenario(
            { orgId: selectedOrganizationId!, scenarioId: Number(formikRef.current?.values.id) },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: ['back-of-envelope-scenarios'] });
                    queryClient.invalidateQueries({ queryKey: ['back-of-envelope', selectedOrganizationId!] });
                    setSelectedScenarioId(undefined);
                    setSelectedScenario(undefined);

                    // Remove scenario from storage
                    setLocallyStoredActiveTabName(prev => {
                        const newState = { ...prev };
                        if (newState[fetchedDeal?.id as number]) {
                            delete newState[fetchedDeal?.id as number].scenarioId;
                        }
                        return newState;
                    });

                    toggleSlideout();
                    showSuccess('Scenario deleted successfully!');
                },
                onError: error => {
                    console.error('Error deleting scenario:', error);
                },
            },
        );
    }, [selectedSlideoutScenarioId, selectedScenarioId, fetchedDeal?.id, setLocallyStoredActiveTabName]);

    const handleSelectScenarioInSlideout = useCallback(
        (option: IOptionAnyValue) => {
            if (option.label === 'SEARCH' || option.label === 'CREATE' || option.label === 'BACK') {
                return;
            }

            setSelectedSlideoutScenarioId(() => {
                const scenarioId = option.id || option.value;

                refetchSlideout().then(({ data }) => {
                    const formValues = mapScenarioToFormValues({
                        scenario: data || null,
                        options: { assetTypes },
                        initialValues: { dealAssetTypeId: fetchedDeal?.assetType?.correlationId },
                    });

                    if (formikRef.current && formValues) {
                        formikRef.current.setValues(formValues);
                        setHasEditingValues(true);
                    }
                });
                return scenarioId;
            });
        },
        [mapScenarioToFormValues],
    );

    const handleCreateScenario = useCallback((option: INestedOptionAnyValue) => {
        if (formikRef?.current) {
            formikRef.current.setFieldValue('id', null);
        }
        setHasEditingValues(false);
        setIsCreatingNewScenario(true);
        setSelectedDealForModal(option.dealId ? Number(option.dealId) : null);
        setShowSaveModal(true);
    }, []);

    useEffect(() => {
        if (!isCreatingNewScenario) {
            setHasEditingValues(!!formikRef?.current?.values.id);
        }
    }, [formikRef.current?.values, isOpen, isCreatingNewScenario]);

    const isEditing = useMemo(() => hasEditingValues && !isCreatingNewScenario, [hasEditingValues, isCreatingNewScenario]);

    return (
        <BackOfEnvelopeContext.Provider
            value={{
                formikRef,
                isOpen,
                initialValues,
                toggleSlideout,
                resetFormValues,
                setHasEditingValues,
                setInitialValues,
                setSelectedScenarioId,
                setSelectedSlideoutScenarioId,
                setSelectedScenario,
                selectedScenarioId,
                selectedScenario,
                getSlideoutValues,
            }}
        >
            {children}
            {isOpen && (
                <>
                    <NSSlideout
                        key={isOpen ? 'slideout-open' : 'slideout-closed'}
                        modalHeaderText="Back-Of-Envelope"
                        modalHeaderSubtitleText="Quickly assess the financial feasibility of this deal."
                        modalHeaderTextClassName="text-dark mb-1"
                        modalHeaderSubtitleTextClassName="mt-0"
                        modalBodyClassName="pb-0 px-3 pt-3"
                        contentClassName="BackOfEnvelope__content"
                        customHeaderActions={(
                            <div className="d-flex">
                                {combinedScenarios && (
                                    <DealScenarioDropdown
                                        preSelectScenario={{ label: initialValues?.name || '', value: initialValues?.id }}
                                        scenarios={combinedScenarios}
                                        handleCreateScenario={handleCreateScenario}
                                        handleSelectScenario={handleSelectScenarioInSlideout}
                                        setSelectedScenarioId={setSelectedSlideoutScenarioId}
                                        includeCreate
                                        includeLabels
                                        includeSearch
                                    />
                                )}
                                {isEditing ? (
                                    <>
                                        <NSButton color="danger" icon={faTrashCan} callback={handleDelete} className="mx-2" />
                                        <NSButton color="primary" text="Save" callback={handleSave} />
                                    </>
                                ) : (
                                    <>
                                        <NSButton color="secondary" text="Clear" className="mx-2" callback={resetFormValues} allowReadOnlyAccess />
                                        <NSButton color="primary" text="Save" callback={handleSave} />
                                    </>
                                )}
                            </div>
                        )}
                        isOpen={isOpen}
                        toggle={toggleSlideout}
                    >
                        <BOESlideoutForm
                            ref={formikRef}
                            initialValues={initialValues}
                            isEditing={isEditing}
                            setShowModal={() => {}}
                            setShowLoanSizingModal={() => setShowLoanSizingModal(true)}
                            deal={fetchedDeal}
                            orgId={selectedOrganizationId}
                            toggleSlideout={toggleSlideout}
                        />
                    </NSSlideout>
                    <SaveModal
                        selectedScenarioName={
                            selectedSlideoutScenarioId ? combinedScenarios?.find(scenario => scenario.id === selectedSlideoutScenarioId)?.name : ''
                        }
                        dealId={selectedDealForModal}
                        toggle={() => {
                            setShowSaveModal(!showSaveModal);
                            setSelectedDealForModal(null);
                            setIsCreatingNewScenario(false);
                        }}
                        isOpen={showSaveModal}
                        onConfirm={(createNewDeal: boolean = false) => {
                            if (createNewDeal && formikRef.current) {
                                formikRef.current.setFieldValue('createNewDeal', createNewDeal);
                            }
                            if (formikRef.current) {
                                formikRef.current.submitForm();
                            }
                        }}
                        onCancel={() => {
                            setShowSaveModal(false);
                            setSelectedDealForModal(null);
                            setIsCreatingNewScenario(false);
                        }}
                        setFieldValue={formikRef.current?.setFieldValue}
                        modalHeaderText="Save scenario"
                        modalFooterButtonText="Go back to selection"
                        modalFooterCancelButtonText="Cancel"
                        backdrop="static"
                    />
                    <LoanSizingModal
                        boeValues={formikRef.current?.values}
                        toggle={() => setShowLoanSizingModal(!showLoanSizingModal)}
                        isOpen={showLoanSizingModal}
                        onConfirm={(option: ILoanSizingOption, type: LOAN_CALCULATION_TYPES, values: ILoanSizingValue) => {
                            // Reset market cap rate
                            formikRef.current?.setFieldValue('marketCapRate', 0);

                            formikRef.current?.setFieldValue('selectedLoanCalculationOptions', option);
                            formikRef.current?.setFieldValue('contructionFinancing', values.contructionFinancing);
                            if (type === LOAN_CALCULATION_TYPES.LTV) {
                                formikRef.current?.setFieldValue('marketCapRate', values.marketCapRate);
                            }
                        }}
                        onCancel={() => {
                            setShowLoanSizingModal(false);
                        }}
                        backdrop="static"
                    />
                </>
            )}
        </BackOfEnvelopeContext.Provider>
    );
};
