import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NSBadge, NSCard } from 'bricks';
import classNames from 'classnames';
import React, { ReactNode, useState } from 'react';
import { Tooltip } from 'reactstrap';
import { IReportType, ReportType as TypeOfReport } from 'views/Reports/types';

export interface IReportTypeProps {
    type: IReportType;
    isSelected: boolean;
    isDisabled?: boolean;
    disabledTooltip?: ReactNode;
    onClick: () => void;
}

const ReportType = ({
    type, isSelected = false, isDisabled = false, disabledTooltip, onClick,
}: IReportTypeProps) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const toggleTooltip = () => setShowTooltip(!showTooltip);

    const cardId = `report-type-${type.name.toLowerCase().split(' ').join('-')}`;

    return (
        <NSCard
            id={cardId}
            className={classNames([
                'Report-type m-1 text-center flex-grow-1 h-100 d-flex align-items-center justify-content-center',
                {
                    'Report-type--selected': isSelected,
                    'Report-type--disabled': isDisabled,
                },
            ])}
            onClick={isDisabled ? () => {} : onClick}
        >
            {type.type === TypeOfReport.Aggregate && (
                <div>
                    <NSBadge color="primary-lighten" className="mb-1">
                        Recommended for mixed-use
                    </NSBadge>
                </div>
            )}
            <div id={`${cardId}-info`}>
                <FontAwesomeIcon id={`${cardId}-icon`} icon={type.icon} size="3x" className="text-primary" />
                <h4 className="text-white font-weight-bold mb-0">{type.name}</h4>
                {type.description && <span>{type.description}</span>}
            </div>

            {disabledTooltip && isDisabled && (
                <Tooltip target={`${cardId}-info`} isOpen={showTooltip} toggle={toggleTooltip} innerClassName="Report-type__tooltip">
                    {disabledTooltip}
                </Tooltip>
            )}
        </NSCard>
    );
};

export default ReportType;
