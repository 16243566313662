import { numeralFormatterCurrency } from 'ns_libs/formatter';

export const SENSITIVITY_CONFIG = {
    'Gross rent vs Vacancy rate': {
        displayValue: 'netOperatingIncome',
        formatter: numeralFormatterCurrency,
    },
    'Exit cap rate vs Gross rent': {
        displayValue: 'exitValuation',
        formatter: numeralFormatterCurrency,
    },
    'Lease-up pace vs Gross rent': {
        displayValue: 'netOperatingIncome',
        formatter: numeralFormatterCurrency,
    },
    'Operating expenses vs Gross rent': {
        displayValue: 'netOperatingIncome',
        formatter: numeralFormatterCurrency,
    },
    'Exit cap rate vs Hard + soft costs': {
        displayValue: 'exitValuation',
        formatter: numeralFormatterCurrency,
    },
    'Interest rate vs [LTC]': {
        displayValue: 'constructionDebtAmount',
        formatter: numeralFormatterCurrency,
    },
} as const;

export const SENSITIVITY_OPTIONS = [
    {
        value: 'Gross Rent vs Vacancy Rate',
        label: 'Gross rent vs Vacancy rate',
        verticalLabel: 'Gross rent',
        horizontalLabel: 'Vacancy rate',
        increments: { vertical: 1, horizontal: 1 },
    },
    {
        value: 'Exit Capitalization Rate vs Gross Rent',
        label: 'Exit cap rate vs Gross rent',
        verticalLabel: 'Exit cap rate',
        horizontalLabel: 'Gross rent',
        increments: { vertical: 0.1, horizontal: 1 },
    },
    {
        value: 'Lease Up Pace vs Gross Rent',
        label: 'Lease-up pace vs Gross rent',
        verticalLabel: 'Lease-up pace',
        horizontalLabel: 'Gross rent',
        increments: { vertical: 1, horizontal: 1 },
    },
    {
        value: 'Operating Expenses vs Gross rent',
        label: 'Operating expenses vs Gross rent',
        verticalLabel: 'Operating expenses',
        horizontalLabel: 'Gross rent',
        increments: { vertical: 1, horizontal: 1 },
    },
    {
        value: 'Exit Capitalization Rate vs Hard and Soft Costs',
        label: 'Exit cap rate vs Hard + soft costs',
        verticalLabel: 'Hard + soft costs',
        horizontalLabel: 'Exit cap rate',
        increments: { vertical: 1, horizontal: 1 },
    },
    {
        value: 'Interest Rate vs Loan to Cost',
        label: 'Interest rate vs [LTC]',
        verticalLabel: 'Interest rate',
        horizontalLabel: 'LTC',
        increments: { vertical: 0.1, horizontal: 1 },
    },
];
