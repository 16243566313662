import { AggregateReportProperties, PropertyKeyToLabelMap, spacerRows } from 'components/ProFormaReturnsTable/constant';
import { IAggregateReportResponse } from 'views/Reports/types';

const EXCEL_NSBLUE = 'e6e8e8';
const EXCEL_WHITE = '0FB3FF';
const BORDER_COLOR = '000000';
const LIGHT_GRAY = 'F2F2F2';
const EXCEL_DARK_GRAY = 'BFBFBF';

const excelDecimalFormat = '#,##0.00';
const excelPercentFormat = '0.00%';
const excelBracesFormat = '"("#,##0.00")"';
const defaultFontSize = 12;
const borderStyle = { style: 'medium', color: { argb: BORDER_COLOR } };
const thinBorderStyle = { style: 'thin', color: { argb: BORDER_COLOR } };

export const formatExcelExportData = (title: string, subtitle: string, data: IAggregateReportResponse) => {
    const { proFormaReturns, cashFlowData } = data;

    const grayRows = [
        AggregateReportProperties.TOTAL_DEVELOPMENT_COST,
        AggregateReportProperties.TOTAL_INTEREST,
        AggregateReportProperties.TOTAL_LOAN_DRAWS_DURING_DEVELOPMENT,
        AggregateReportProperties.CASH_FLOW_FROM_OPERATIONS,
        AggregateReportProperties.NET_SALE_PROCEEDS,
        AggregateReportProperties.EQUITY_PROCEEDS_FROM_CAPITAL_EVENTS,
    ];
    const borderedCell = [...spacerRows, AggregateReportProperties.PERMANENT_LOAN_PAYOFF];
    const bracesCell = [AggregateReportProperties.CONSTRUCTION_LOAN_PAYOFF, AggregateReportProperties.PERMANENT_LOAN_PAYOFF];

    const periodColumnHeaders = Object.keys(proFormaReturns.total_development_cost.periods);

    const topHeaderRows = [
        {
            rowFont: { bold: true, size: defaultFontSize },
            rowCells: [{ value: title }],
        },
        {
            rowFont: { bold: false, size: defaultFontSize },
            rowCells: [{ value: subtitle }],
        },
    ];

    const tableHeaderLabelsRow = {
        rowFont: {
            bold: true,
            size: defaultFontSize,
            color: { argb: EXCEL_NSBLUE },
        },
        rowFill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: EXCEL_WHITE },
        },
        rowCells: [
            {
                value: 'Property',
                width: 30,
                border: {
                    top: borderStyle,
                },
            },
            {
                value: '',
                width: 30,
                border: {
                    top: borderStyle,
                },
            },
            {
                value: '',
                width: 30,
                border: {
                    top: borderStyle,
                },
            },
            {
                value: 'Amount',
                width: 30,
                border: {
                    top: borderStyle,
                },
                alignment: { horizontal: 'right' },
            },
            {
                value: '',
                width: 1,
                border: {
                    left: thinBorderStyle,
                    right: thinBorderStyle,
                },
                cellFill: {
                    type: 'pattern',
                },
            },
            ...periodColumnHeaders.map((col, idx) => ({
                value: col,
                border: {
                    top: borderStyle,
                    right: idx === periodColumnHeaders.length - 1 ? thinBorderStyle : undefined,
                },
                alignment: { horizontal: 'right' },
            })),
        ],
    };

    const proFormaReturnsTableData = Object.keys(proFormaReturns).map((prop, rowIdx) => {
        const propLabel = PropertyKeyToLabelMap[prop as keyof typeof PropertyKeyToLabelMap];
        const isSpacerRow = borderedCell.includes(propLabel);

        const grayRowFill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: propLabel === AggregateReportProperties.EQUITY_PROCEEDS_FROM_CAPITAL_EVENTS ? EXCEL_DARK_GRAY : LIGHT_GRAY },
        };

        let bottomRowStyle: typeof borderStyle | typeof thinBorderStyle | undefined;
        if (rowIdx === Object.keys(proFormaReturns).length - 1) {
            bottomRowStyle = borderStyle;
        } else if (isSpacerRow) {
            bottomRowStyle = thinBorderStyle;
        }

        return {
            rowFill: grayRows.includes(propLabel) ? grayRowFill : undefined,
            rowCells: [
                {
                    value: propLabel,
                    border: {
                        left: borderStyle,
                        bottom: bottomRowStyle,
                    },
                },
                {
                    value: '',
                    border: {
                        bottom: bottomRowStyle,
                    },
                },
                {
                    value: '',
                    border: {
                        bottom: bottomRowStyle,
                    },
                },
                {
                    value: proFormaReturns[prop].amount,
                    border: {
                        bottom: bottomRowStyle,
                    },
                    numFmt: bracesCell.includes(propLabel) ? excelBracesFormat : excelDecimalFormat,
                },
                {
                    value: '',
                    width: 1,
                    border: {
                        left: thinBorderStyle,
                        right: thinBorderStyle,
                    },
                    cellFill: {
                        type: 'pattern',
                    },
                },
                ...periodColumnHeaders.map((col, idx) => ({
                    value: proFormaReturns[prop].periods[col] || 0,
                    width: 25,
                    border: {
                        right: idx === periodColumnHeaders.length - 1 ? borderStyle : undefined,
                        bottom: bottomRowStyle,
                    },
                    numFmt: bracesCell.includes(propLabel) ? excelBracesFormat : excelDecimalFormat,
                })),
            ],
        };
    });

    const cashFlowTableHeaderLabelsRow = {
        rowFont: {
            bold: true,
            size: defaultFontSize,
            color: { argb: EXCEL_NSBLUE },
        },
        rowFill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: EXCEL_WHITE },
        },
        rowCells: [
            {
                value: '',
                border: {
                    top: borderStyle,
                },
                alignment: { horizontal: 'right' },
            },
            {
                value: 'Equity multiple',
                border: {
                    top: borderStyle,
                },
                alignment: { horizontal: 'right' },
            },
            {
                value: 'IRR',
                border: {
                    top: borderStyle,
                },
                alignment: { horizontal: 'right' },
            },
            {
                value: 'Net profit',
                border: {
                    top: borderStyle,
                },
                alignment: { horizontal: 'right' },
            },
            {
                value: '',
                width: 1,
                border: {
                    left: thinBorderStyle,
                    right: thinBorderStyle,
                },
                cellFill: {
                    type: 'pattern',
                },
            },
            ...periodColumnHeaders.map((col, idx) => ({
                value: col,
                border: {
                    top: borderStyle,
                    right: idx === periodColumnHeaders.length - 1 ? borderStyle : undefined,
                },
                alignment: { horizontal: 'right' },
            })),
        ],
    };

    const cashFlowTableData = Object.keys(cashFlowData).map((prop, rowIdx) => ({
        rowCells: [
            {
                value: PropertyKeyToLabelMap[prop as keyof typeof PropertyKeyToLabelMap],
                border: {
                    left: borderStyle,
                    bottom: rowIdx === Object.keys(cashFlowData).length - 1 ? borderStyle : undefined,
                },
            },
            {
                value: cashFlowData[prop].equityMultiple,
                border: {
                    bottom: rowIdx === Object.keys(cashFlowData).length - 1 ? borderStyle : undefined,
                },
                numFmt: excelDecimalFormat,
            },
            {
                value: cashFlowData[prop].irr,
                numFmt: excelPercentFormat,
                border: {
                    bottom: rowIdx === Object.keys(cashFlowData).length - 1 ? borderStyle : undefined,
                },
            },
            {
                value: cashFlowData[prop].netProfit,
                numFmt: excelDecimalFormat,
                border: {
                    bottom: rowIdx === Object.keys(cashFlowData).length - 1 ? borderStyle : undefined,
                },
            },
            {
                value: '',
                width: 1,
                border: {
                    left: thinBorderStyle,
                    right: thinBorderStyle,
                },
                cellFill: {
                    type: 'pattern',
                },
            },
            ...periodColumnHeaders.map((col, idx) => ({
                value: cashFlowData[prop].periods[col] || 0,
                width: 17,
                border: {
                    right: idx === periodColumnHeaders.length - 1 ? borderStyle : undefined,
                    bottom: rowIdx === Object.keys(cashFlowData).length - 1 ? borderStyle : undefined,
                },
                numFmt: excelDecimalFormat,
            })),
        ],
    }));

    return {
        topHeaderRows,
        reportTables: [
            {
                tableHeaderRows: [tableHeaderLabelsRow],
                tableData: proFormaReturnsTableData,
                tableFooter: {
                    rowCells: [],
                },
            },
            {
                tableHeaderRows: [cashFlowTableHeaderLabelsRow],
                tableData: cashFlowTableData,
                tableFooter: {
                    rowCells: [],
                },
            },
        ],
    };
};
