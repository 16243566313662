import React, { useMemo } from 'react';
import {
    generatePath, Link, useLocation, useParams,
} from 'react-router-dom';
import { faCalculator } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';

import { URLS } from 'services/urls';
import { useSlideout } from 'contexts/BackOfEnvelopeContext';
import { useOrganizationAccess } from 'hooks/useOrganizationAccess';
import { NSButton } from 'bricks';
import { FEATURE_IDS } from 'services/constants';
import RenderIf from 'components/RenderIf/RenderIf';
import { useAuthContext } from 'contexts/AuthContext';
import NSLogo from '../../images/Northspyre-Logo.png';
import UserMenu from './components/UserMenu/UserMenu';
import OrganizationMenu from './components/OrganizationMenu/OrganizationMenu';
import UtilitiesMenu from './components/UtilitiesMenu/UtilitiesMenu';
import AnalyticsMenu from './components/AnalyticsMenu/AnalyticsMenu';
import CannyChangelogWidget from './components/CannyChangelogWidget/CannyChangelogWidget';
import './NSNavbar.scss';

export const DisplayedLogo: { [key: string]: string } = {
    // TODO Add other environment logos
    production: NSLogo,
};

const MainNav = ({ pathname }: { pathname: string }) => {
    const { hasCoreAccess } = useOrganizationAccess();

    return (
        <div className="NSNavbar__link-list d-flex">
            <Link to="/deals" className={`NSNavbar__link-list__link d-flex align-items-center px-2 ${pathname === '/deals' ? 'active' : ''}`}>
                Deals
            </Link>
            {hasCoreAccess && (
                <a href={URLS.PROJECTS_APP.PROJECTS} className="NSNavbar__link-list__link d-flex align-items-center px-2">
                    Projects
                </a>
            )}
            <a href={URLS.PROJECTS_APP.APPROVALS} className="NSNavbar__link-list__link d-flex align-items-center px-2">
                Approvals
            </a>
            {hasCoreAccess && (
                <AnalyticsMenu />
            )}
            <UtilitiesMenu />
        </div>
    );
};

const DealDashboardNav = ({ pathname, dealId }: { pathname: string; dealId: string }) => (
    <div className="NSNavbar__link-list d-flex">
        <Link
            to={generatePath(URLS.DEAL_DASHBOARD.HOME, { dealId })}
            className={classNames('NSNavbar__link-list__link d-flex align-items-center px-2', {
                active: pathname === generatePath(URLS.DEAL_DASHBOARD.HOME, { dealId }),
            })}
        >
            Deal Dashboard
        </Link>
        <Link
            to={generatePath(URLS.PRO_FORMA.HOME, { dealId })}
            className={classNames('NSNavbar__link-list__link d-flex align-items-center px-2', {
                active: pathname === generatePath(URLS.PRO_FORMA.HOME, { dealId }),
            })}
        >
            Pro Forma
        </Link>
        <Link
            to={generatePath(URLS.DOCUMENTS, { dealId })}
            className={classNames('NSNavbar__link-list__link d-flex align-items-center px-2', {
                active: pathname === generatePath(URLS.DOCUMENTS, { dealId }),
            })}
        >
            Documents
        </Link>
        <Link
            to={generatePath(URLS.REPORT_BUILDER, { dealId })}
            className={classNames('NSNavbar__link-list__link d-flex align-items-center px-2', {
                active: pathname === generatePath(URLS.REPORT_BUILDER, { dealId }),
            })}
        >
            Report Builder
        </Link>
    </div>
);

const NSNavbar = () => {
    const { userOrganizations, isLoadingUserOrganizations } = useAuthContext();
    const location = useLocation();
    const { dealId } = useParams<{ dealId: string }>();
    const { toggleSlideout } = useSlideout();
    const { pathname } = location;
    const isDealPage = pathname.startsWith('/deal/') && Boolean(dealId);

    const navigation = useMemo(() => {
        if (isDealPage) {
            return <DealDashboardNav pathname={pathname} dealId={dealId!} />;
        }
        return <MainNav pathname={pathname} />;
    }, [pathname, dealId, isDealPage]);

    const logoLinkDestination = isDealPage ? generatePath(URLS.DEAL_DASHBOARD.HOME, { dealId }) : URLS.DEALS.HOME;

    const organizations = userOrganizations?.map(org => org.organization) || [];

    return (
        <nav className="NSNavbar d-flex justify-content-between px-3 ">
            <div className="d-flex align-items-center">
                <div className="mr-2">
                    <Link to={logoLinkDestination} data-testid="home_url">
                        <img src={DisplayedLogo.production} style={{ height: '40px' }} alt="Logo" id="nav-bar-logo" />
                    </Link>
                </div>
                {navigation}
            </div>
            <div className="NSNavbar__right d-flex align-items-center">
                <div className="NSNavbar__right__icon-list d-flex align-items-center">
                    <RenderIf isTrue={organizations.length > 1 && !isLoadingUserOrganizations}>
                        <OrganizationMenu organizations={organizations} />
                    </RenderIf>
                    <NSButton
                        id="back-of-envelope-nav-button"
                        icon={faCalculator}
                        callback={toggleSlideout}
                        iconOnly
                        className="NSNavbar__right__icon-list__icon"
                        featureId={FEATURE_IDS.BACK_OF_ENVELOPE}
                        ariaLabel="calculator"
                        allowReadOnlyAccess
                    />
                    <CannyChangelogWidget />
                    <UserMenu />
                </div>
            </div>
        </nav>
    );
};

export default NSNavbar;
