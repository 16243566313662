import React, { useState } from 'react';

type IDefaultDates = {
    [key: string]: Date | undefined;
};

const useDateInput = (defaultDates: IDefaultDates = {}) => {
    const [dates, setDates] = useState<{ [key: string]: Date | undefined }>(defaultDates);
    const [dateStrings, setDateStrings] = useState<{ [key: string]: string }>(
        Object.fromEntries(Object.entries(defaultDates).map(([key, date]) => [key, date ? date.toISOString().split('T')[0] : ''])),
    );

    const handleDateChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newDateStr = event.target.value;
        setDateStrings(prev => ({ ...prev, [key]: newDateStr }));

        const newDate = newDateStr ? new Date(newDateStr) : undefined;
        setDates(prev => ({ ...prev, [key]: newDate }));
    };

    const handleUpdateDate = (key:string, newDate: Date) => {
        const newDateStr = newDate.toISOString().split('T')[0];
        setDateStrings(prev => ({ ...prev, [key]: newDateStr }));

        setDates(prev => ({ ...prev, [key]: newDate }));
    };

    return {
        dates, dateStrings, handleDateChange, handleUpdateDate,
    };
};

export default useDateInput;
