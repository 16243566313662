/* eslint-disable max-len */
import React, {
    useEffect, useState, useMemo, useCallback,
} from 'react';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import './ProFormaWizardStepThree.scss';

import { useAuthContext } from 'contexts/AuthContext';
import { NSCard, NSSwitch } from 'bricks';
import { useGetDealById } from 'views/Deals/hooks/useGetDealById';
import { ProFormaModelIds } from 'constants/proForma';
import { useProFormaWizard } from 'views/ProFormaWizard/context/ProFormaWizardProvider';
import { INITIAL_EXPENSES } from 'views/ProFormaWizard/constants';
import { formatUSDCurrencyTwoDigits } from 'ns_libs/formatter';
import RenderIf from 'components/RenderIf/RenderIf';
import { IExpense } from 'views/ProFormaWizard/BasicInformation/hooks/useExpenses';
import { useGetProFormaWizardStep } from 'views/ProFormaWizard/hooks/useGetProFormaWizardStep';
import { useUpdateProFormaWizardStep } from 'views/ProFormaWizard/hooks/useUpdateProFormaWizardStep';
import { UnitTypeIds } from 'constants/unitTypes';
import { useOtherIncomeManager } from './hooks/useOtherIncomeManager';
import { useUnitManager } from './hooks/useUnitManager';
import { IExpenses, ITenant, IUnitMix } from './types';
import OperatingInfo, { OperatingInfoValues } from './components/OperatingInfo/OperatingInfo';
import Expenses from './components/Expenses/Expenses';
import UnitMixOverviewCard from './components/UnitMixOverviewCard/UnitMixOverviewCard';
import TenantsOverviewCard from './components/TenantsOverviewCard/TenantsOverviewCard';
import OtherIncomeCard from './components/OtherIncomeCard/OtherIncomeCard';
import { getDefaultCreditLoss, getDefaultVacancy } from './helpers';

const ProFormaWizardStepThree = () => {
    const { selectedOrganizationId } = useAuthContext();
    const { registerStepHandler } = useProFormaWizard();
    const { dealId, proFormaId } = useParams<{ dealId: string; proFormaId: string }>();

    const { data: stepData } = useGetProFormaWizardStep({
        orgId: selectedOrganizationId!,
        proFormaId: proFormaId || '',
        stepNumber: 3,
    });

    const { data: deal } = useGetDealById({
        orgId: selectedOrganizationId!,
        dealId: Number(dealId),
        shouldFetch: !!Number(dealId),
    });

    const [proFormaModelId] = useState<ProFormaModelIds>(ProFormaModelIds.APARTMENT); // TODO get from API

    const initialOperatingInfoValues = useMemo(
        () => ({
            occupancyUponStabilizationPercentage: Math.round(Number(stepData?.occupancyUponStabilizationPercentage) * 100) ?? 0,
            rentalGrowthPercentage: stepData?.rentalGrowthPercentage ?? 0,
            percentPreLeased: stepData?.percentPreLeased ?? 0,
            leaseUpPace: stepData?.leaseUpPace ?? 0,
            lengthOfLease: stepData?.lengthOfLease ?? 0,
            initialFreeRent: stepData?.initialFreeRent ?? 0,
            stabilizedFreeRent: stepData?.stabilizedFreeRent ?? 0,
            vacancy: stepData?.vacancy ?? getDefaultVacancy(proFormaModelId),
            creditLoss: stepData?.creditLoss ?? getDefaultCreditLoss(proFormaModelId),
        }),
        [stepData, proFormaModelId],
    );

    const initialExpensesValues = useMemo<IExpenses>(() => ({
        expenses: stepData?.expensesValues?.expenses ?? 0,
        totalExpenses: stepData?.expensesValues?.totalExpenses ?? 0,
        expensesType: stepData?.expensesValues?.expensesType ?? 'sf',
    }), [stepData]);

    const initialExpensesDetail = useMemo(() => {
        if (!stepData?.operatingExpenses?.length) {
            return INITIAL_EXPENSES;
        }

        return stepData.operatingExpenses.map((expense: IExpense) => ({
            id: expense.id,
            name: expense.name,
            annualGrowth: Number(expense.annualGrowth) * 100,
            amountPerYear: Number(expense.amountPerYear),
        }));
    }, [stepData]);

    const initialOtherIncome = useMemo(() => {
        if (!stepData?.otherIncome?.length) {
            return [];
        }
        return stepData.otherIncome;
    }, [stepData]);

    const [expensesValues, setExpensesValues] = useState(initialExpensesValues);
    const [expensesDetail, setExpensesDetail] = useState<IExpense[]>(initialExpensesDetail);
    const [operatingInfoValues, setOperatingInfoValues] = useState(initialOperatingInfoValues);

    const [showExpenseDetailToggle, setShowExpenseDetailToggle] = useState(!!stepData?.operatingExpenses?.length);
    const [showOtherIncomeToggle, setShowOtherIncomeToggle] = useState(!!stepData?.otherIncome?.length);

    const [validationErrors, setValidationErrors] = useState<string[]>([]);

    useEffect(() => {
        if (stepData) {
            setOperatingInfoValues({
                occupancyUponStabilizationPercentage: Math.round(Number(stepData?.occupancyUponStabilizationPercentage) * 100) ?? 0,
                rentalGrowthPercentage: stepData.rentalGrowthPercentage ?? 0,
                percentPreLeased: stepData.percentPreLeased ?? 0,
                leaseUpPace: stepData.leaseUpPace ?? 0,
                lengthOfLease: stepData.lengthOfLease ?? 0,
                initialFreeRent: stepData.initialFreeRent ?? 0,
                stabilizedFreeRent: stepData.stabilizedFreeRent ?? 0,
                vacancy: stepData.vacancy ?? getDefaultVacancy(proFormaModelId),
                creditLoss: stepData.creditLoss ?? getDefaultCreditLoss(proFormaModelId),
            });
            setExpensesValues({
                expenses: stepData.expensesValues?.expenses ?? 0,
                totalExpenses: stepData.expensesValues?.totalExpenses ?? 0,
                expensesType: stepData.expensesValues?.expensesType ?? 'sf',
            });
            setExpensesDetail(stepData.operatingExpenses?.length ? stepData.operatingExpenses.map((expense: IExpense) => ({
                id: expense.id,
                name: expense.name,
                annualGrowth: Number(expense.annualGrowth) * 100,
                amountPerYear: Number(expense.amountPerYear),
            })) : INITIAL_EXPENSES);
            setShowExpenseDetailToggle(!!stepData.operatingExpenses?.length);
            setShowOtherIncomeToggle(!!stepData.otherIncome?.length);
        }
    }, [stepData, proFormaModelId]);

    const {
        unitArray, handleDuplicateOrAdd, handleDelete, handleUpdate,
    } = useUnitManager({
        proFormaModelId,
        initialUnits: stepData?.incomeUnitMixes || [],
    });

    const {
        incomeArray, handleAddOrDuplicateIncome, handleDeleteIncome, handleUpdateIncome,
    } = useOtherIncomeManager({
        initialIncomes: initialOtherIncome,
    });

    const totalRentalIncome = useMemo(() => unitArray.reduce((sum, unit) => sum + unit.totalRent!!, 0), [unitArray]);
    const totalOtherIncome = useMemo(() => incomeArray.reduce((sum, unit) => sum + unit.amountPerYear!!, 0), [incomeArray]);

    const toggleCalculate = useCallback(() => setShowOtherIncomeToggle(prev => !prev), []);

    const validate = useCallback(() => {
        const errors: string[] = [];

        // Validate operating info values
        if (!operatingInfoValues.occupancyUponStabilizationPercentage) errors.push('Occupancy upon stabilization is required');
        if (!operatingInfoValues.rentalGrowthPercentage) errors.push('Rental growth percentage is required');
        if (!operatingInfoValues.percentPreLeased) errors.push('Percent pre-leased is required');
        if (!operatingInfoValues.leaseUpPace) errors.push('Lease-up pace is required');
        if (!operatingInfoValues.lengthOfLease) errors.push('Length of lease is required');
        if (!operatingInfoValues.initialFreeRent) errors.push('Initial free rent is required');
        if (!operatingInfoValues.stabilizedFreeRent) errors.push('Stabilized free rent is required');
        if (!operatingInfoValues.vacancy) errors.push('Vacancy is required');
        if (!operatingInfoValues.creditLoss) errors.push('Credit loss is required');

        // Validate unit mix (at least one unit required)
        if (unitArray.length === 0) {
            errors.push('At least one unit mix item is required');
        }

        // Check each unit's required fields
        unitArray.forEach((unit, index) => {
            if (!unit.averageSquareFootage) errors.push(`Unit ${index + 1}: Average square footage is required`);
            if (!unit.amountPerMonth) errors.push(`Unit ${index + 1}: Amount per month is required`);
            if (!unit.units) errors.push(`Unit ${index + 1}: Number of units is required`);
        });

        // Validate other income if toggled
        if (showOtherIncomeToggle && incomeArray.length === 0) {
            errors.push('At least one other income item is required when other income is enabled');
        }

        // Validate expenses based on toggle state
        if (showExpenseDetailToggle) {
            if (expensesDetail.length === 0) {
                errors.push('At least one expense detail is required when expense detail is enabled');
            }
            expensesDetail.forEach((expense, index) => {
                if (!expense.amountPerYear) errors.push(`Expense ${index + 1}: Amount per year is required`);
                if (typeof expense.annualGrowth !== 'number') errors.push(`Expense ${index + 1}: Annual growth is required`);
            });
        } else {
            if (!expensesValues.expenses || expensesValues.expenses <= 0) {
                errors.push('Expenses value is required when expense detail is disabled');
            }
            if (!expensesValues.totalExpenses || expensesValues.totalExpenses <= 0) {
                errors.push('Total expenses value is required when expense detail is disabled');
            }
        }

        setValidationErrors(errors);
        return errors.length === 0;
    }, [operatingInfoValues, unitArray, showOtherIncomeToggle, incomeArray, showExpenseDetailToggle, expensesValues, expensesDetail]);

    const updateStep = useUpdateProFormaWizardStep();

    const getData = useCallback(() => {
        const transformedUnitArray = unitArray.map(unit => ({
            ...unit,
            averageSquareFootage: unit.averageSquareFootage,
            valuePerUnitPerMonth: unit.amountPerMonth,
            units: unit.units,
        }));

        const transformedExpenses = expensesDetail.map(expense => ({
            ...expense,
            annualGrowth: (Number(expense.annualGrowth) || 0) / 100,
        }));

        const transformedOtherIncome = incomeArray.map(income => ({
            ...income,
            annualGrowth: (Number(income.annualGrowth) || 0) / 100,
            percentageOfTotalRentalIncome: (Number(income.percentageOfTotalRentalIncome) || 0) / 100,
        }));

        const data = {
            ...operatingInfoValues,
            occupancyUponStabilizationPercentage: operatingInfoValues.occupancyUponStabilizationPercentage > 0
                ? parseFloat((Number(operatingInfoValues.occupancyUponStabilizationPercentage) / 100).toFixed(2)) : 0,
            incomeUnitMixes: transformedUnitArray,
            otherIncome: showOtherIncomeToggle ? transformedOtherIncome : [],
            expensesValues,
            operatingExpenses: showExpenseDetailToggle ? transformedExpenses : [],
        };

        return new Promise((resolve, reject) => {
            updateStep.mutate({
                orgId: selectedOrganizationId!,
                proFormaId: proFormaId || '',
                stepNumber: 3,
                data,
            }, {
                onSuccess: result => resolve(result),
                onError: error => reject(error),
            });
        });
    }, [unitArray, expensesDetail, incomeArray, operatingInfoValues, showOtherIncomeToggle, showExpenseDetailToggle, expensesValues, selectedOrganizationId, proFormaId, updateStep]);

    useEffect(() => {
        registerStepHandler(3, {
            validate,
            getData,
        });
    }, [registerStepHandler, validate, getData]);

    const renderCards = () => unitArray.map(unit => (proFormaModelId === ProFormaModelIds.APARTMENT ? (
        <div className="col-md-4" key={unit.id}>
            <UnitMixOverviewCard
                values={unit as IUnitMix}
                onDuplicate={() => handleDuplicateOrAdd(unit.id)}
                onDelete={() => handleDelete(unit.id)}
                onUpdate={handleUpdate}
            />
        </div>
    ) : (
        <div className="col-md-6" key={unit.id}>
            <TenantsOverviewCard
                values={unit as ITenant}
                onDuplicate={() => handleDuplicateOrAdd(unit.id)}
                onDelete={() => handleDelete(unit.id)}
                onUpdate={handleUpdate}
                count={unitArray.length}
            />
        </div>
    )));

    if (!deal) {
        return <div>No deal selected</div>;
    }

    const handleInputChange = useCallback((key: string, value: number | string) => {
        setExpensesValues(prevValues => ({
            ...prevValues,
            [key]: value,
        }));
    }, []);

    const handleDetailChange = useCallback((updatedExpenses: IExpense[]) => {
        setExpensesDetail(updatedExpenses);
    }, []);

    const handleOperatingInfoChange = useCallback((key: keyof OperatingInfoValues, value: number | Date | null) => {
        setOperatingInfoValues(prevValues => ({
            ...prevValues,
            [key]: value,
        }));
    }, []);

    const renderValidationErrors = () => {
        if (validationErrors.length === 0) return null;

        return (
            <NSCard className="mt-2 p-2">
                <div className="text-danger">
                    <div className="font-weight-bold mb-2">Please fix the following errors:</div>
                    <ul className="pl-4 mb-0">
                        {validationErrors.map(error => (
                            <li key={`error-${error.toLowerCase().replace(/\s+/g, '-')}`}>{error}</li>
                        ))}
                    </ul>
                </div>
            </NSCard>
        );
    };

    return (
        <div>
            {renderValidationErrors()}
            <OperatingInfo onChange={handleOperatingInfoChange} values={operatingInfoValues} />
            <div className="ProFormaWizardStepOne__separator" />
            {/* Income */}
            <div className="ProFormaWizardStepOne__title">Income</div>
            <div className="ProFormaWizardStepOne__subtitle">Overview of this investment's total income</div>
            <NSCard className="mt-2 p-2" title="Unit Mix">
                <div className="ProFormaWizardStepThree__unitTitle mb-2">{proFormaModelId === ProFormaModelIds.APARTMENT ? 'Unit Mix' : 'Tenants'}</div>
                <div className="row">
                    {renderCards()}
                    <div className={`col-md-${proFormaModelId === ProFormaModelIds.APARTMENT ? '4' : '6'} d-flex flex-column`}>
                        <NSCard
                            onClick={() => handleDuplicateOrAdd()}
                            className={`                               
                                cursor--pointer text-white align-items-center justify-content-center mb-2 flex-grow-1 
                                ${proFormaModelId === ProFormaModelIds.APARTMENT ? 'ProFormaWizardStepThree__addApartmentCard' : 'ProFormaWizardStepThree__addOtherCard'}
                            `}
                        >
                            <FontAwesomeIcon icon={faPlusCircle} size="lg" className="pb-1" />
                            <div>Add unit type</div>
                        </NSCard>
                    </div>
                </div>
                <NSCard className="NSCard--level-4">
                    <div className="d-flex justify-content-between text-white font-weight-bold p-2">
                        Total annual rental income
                        <div>{formatUSDCurrencyTwoDigits(totalRentalIncome)}</div>
                    </div>
                </NSCard>
            </NSCard>
            {/* Other income */}
            <NSCard className="mt-2 p-2" title="Unit Mix">
                <div className="d-flex justify-content-between">
                    <div>
                        <div className="ProFormaWizardStepThree__unitTitle">Other income</div>
                        <div className="ProFormaWizardStepOne__subtitle">Add additional income sources (e.g. RUBS, Parking)</div>
                    </div>
                    <div className="ProFormaWizardStepOne__subtitle align-self-center">
                        <NSSwitch
                            id="calculateToggle"
                            name="calculateToggle"
                            checked={showOtherIncomeToggle}
                            onChange={toggleCalculate}
                            label=""
                            containerClassName="text-dark"
                        />
                    </div>
                </div>

                <RenderIf isTrue={showOtherIncomeToggle}>
                    <div className="mt-2">
                        {incomeArray.map(income => (
                            <OtherIncomeCard
                                key={income.id}
                                values={income}
                                onUpdate={handleUpdateIncome}
                                onDelete={() => handleDeleteIncome(income.id)}
                            />
                        ))}
                        <NSCard
                            onClick={() => handleAddOrDuplicateIncome()}
                            className="ProFormaWizardStepThree__addOtherIncomeCard cursor--pointer text-white align-items-center justify-content-center mb-2 flex-grow-1"
                        >
                            <div className="py-3">
                                <FontAwesomeIcon icon={faPlus} className="pr-2" />
                                Add other income
                            </div>
                        </NSCard>
                    </div>
                    <NSCard className="NSCard--level-4">
                        <div className="d-flex justify-content-between text-white font-weight-bold p-2">
                            Total other income:
                            <div>{formatUSDCurrencyTwoDigits(totalOtherIncome)}</div>
                        </div>
                    </NSCard>
                </RenderIf>
            </NSCard>
            <div className="ProFormaWizardStepOne__separator" />
            <Expenses
                values={expensesValues}
                proFormaModelId={proFormaModelId}
                onChange={handleInputChange}
                onDetailChange={handleDetailChange}
                showExpenseDetail={showExpenseDetailToggle}
                setShowExpenseDetail={setShowExpenseDetailToggle}
                dealSF={deal.dealUnitTypes?.find(unit => unit.unitType === UnitTypeIds.GROSS_SF)?.value || 0}
                dealUnits={deal.dealUnitTypes?.find(unit => unit.unitType === UnitTypeIds.RESIDENTIAL_UNIT)?.value || 0}
                initialExpenses={stepData?.operatingExpenses?.length !== 0 ? expensesDetail : INITIAL_EXPENSES}
            />
        </div>
    );
};

export default ProFormaWizardStepThree;
