import { ProFormaGenericColumn } from 'views/ProFormaTable/components/ProFormaGenericTable/types';

export const BASE_UNIT_MIX_BASE_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'floor',
        field: 'floor',
        header: 'Floor',
        format: 'none',
        type: 'text',
        isFixed: true,
    },
    {
        key: 'allocation',
        field: 'allocation',
        header: 'Allocation',
        format: 'none',
        type: 'text',
        isFixed: true,
    },
    {
        key: 'sf',
        field: 'sf',
        header: 'SF',
        format: 'currency',
        isFixed: true,
        isReadOnly: true,
    },
    {
        key: 'numberOfRacks',
        field: 'numberOfRacks',
        header: '# of racks',
        format: 'currency',
        isFixed: true,
    },
    {
        key: 'kwPerRack',
        field: 'kwPerRack',
        header: 'kW/rack',
        format: 'currency',
        isFixed: true,
    },
    {
        key: 'pricePerRack',
        field: 'pricePerRack',
        header: '$/rack',
        format: 'currency',
        isFixed: true,
    },
    {
        key: 'pricePerKwMonth',
        field: 'pricePerKwMonth',
        header: '$/kW/mo',
        format: 'currency',
        isFixed: true,
    },
    {
        key: 'annualRentYearOne',
        field: 'annualRentYearOne',
        header: 'Annual rent (yr 1)',
        format: 'currency',
        isFixed: true,
    },
];

export const BASE_RECOVERY_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'recovery',
        field: 'recovery',
        header: 'Recovery',
        format: 'none',
        type: 'text',
        isFixed: true,
        isReadOnly: true,
    },
    {
        key: 'recoveryStart',
        field: 'recoveryStart',
        header: 'Rec. start',
        format: 'none',
        type: 'text',
        isFixed: true,
    },
    {
        key: 'pricePerKwYear',
        field: 'pricePerKwYear',
        header: '$/kW/year',
        format: 'currency',
        isFixed: true,
    },
    {
        key: 'amountPerYear',
        field: 'amountPerYear',
        header: 'Amount/year',
        format: 'currency',
        isFixed: true,
        isReadOnly: true,
    },
];

export const BASE_OTHER_INCOME_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'name',
        field: 'name',
        header: 'Other income',
        format: 'none',
        type: 'text',
        isFixed: true,
    },
    {
        key: 'start',
        field: 'start',
        header: 'Start',
        format: 'none',
        isFixed: true,
    },
    {
        key: 'annualGrowth',
        field: 'annualGrowth',
        header: 'Annual growth',
        format: 'percentage' as const,
        isFixed: true,
    },
    {
        key: 'pricePerRackYear',
        field: 'pricePerRackYear',
        header: '$/rack/year',
        format: 'currency',
        isFixed: true,
    },
    {
        key: 'pricePerSfYear',
        field: 'pricePerSfYear',
        header: '$/SF/year',
        format: 'currency',
        isFixed: true,
    },
    {
        key: 'amountPerYear',
        field: 'amountPerYear',
        header: 'Amount/year',
        format: 'currency',
        isFixed: true,
    },
];

export const BASE_TOTAL_POTENTIAL_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'name',
        field: 'name',
        header: 'Total potential income',
        format: 'none' as const,
        isFixed: true,
    },
    {
        key: 'invisibleStart',
        field: 'invisibleStart',
        header: '',
        isHidden: true,
    },
    {
        key: 'invisibleAnnualGrowth',
        field: 'invisibleAnnualGrowth',
        header: '',
        isHidden: true,
    },
    {
        key: 'invisiblePricePerRackYear',
        field: 'invisiblePricePerRackYear',
        header: '',
        isFixed: true,
    },
    {
        key: 'totalPricePerSfYear',
        field: 'totalPricePerSfYear',
        header: '$/SF/year',
        format: 'currency',
        isFixed: true,
    },
    {
        key: 'totalAmountPerYear',
        field: 'totalAmountPerYear',
        header: 'Amount/year',
        format: 'currency',
        isFixed: true,
    },
];

export const BASE_INCOME_ADJUSTMENTS_COLUMNS: ProFormaGenericColumn[] = [{
    key: 'name',
    field: 'name',
    header: 'Income adjustments',
    format: 'none',
    type: 'text',
    isFixed: true,
},
{
    key: 'invisibleUnits',
    field: 'invisibleUnits',
    header: '',
    isHidden: true,
    isFixed: true,
},
{
    key: 'percentOfIncome',
    field: 'percentOfIncome',
    header: '% of total pot. income',
    format: 'percentage',
    isFixed: true,
},
{
    key: 'pricePerRackYear',
    field: 'pricePerRackYear',
    header: '$/rack/year',
    isFixed: true,
    isNegative: true,
},
{
    key: 'pricePerKwYear',
    field: 'pricePerKwYear',
    header: '$/kW/year',
    isFixed: true,
    isNegative: true,
},
{
    key: 'totalAmountPerYear',
    field: 'totalAmountPerYear',
    header: 'Amount/year',
    isFixed: true,
    isNegative: true,
},
];

export const BASE_ACTUAL_TENANTS_UNIT_MIX_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'tenantName',
        field: 'tenantName',
        header: 'Tenant',
        format: 'none',
        type: 'text',
        isFixed: true,
    },
    {
        key: 'sf',
        field: 'sf',
        header: 'SF',
        format: 'currency',
        type: 'number',
        isFixed: true,
    },
    {
        key: 'numberOfRacks',
        field: 'numberOfRacks',
        header: '# of racks',
        format: 'none',
        type: 'number',
        isFixed: true,
    },
    {
        key: 'kwPerRack',
        field: 'kwPerRack',
        header: 'kW/rack',
        format: 'none',
        type: 'number',
        isFixed: true,
    },
    {
        key: 'leaseStartDate',
        field: 'leaseStartDate',
        header: 'Lease start',
        format: 'none',
        isFixed: true,
    },
    {
        key: 'rentStartDate',
        field: 'rentStartDate',
        header: 'Rent start',
        format: 'none',
        isFixed: true,
    },
    {
        key: 'rentStep',
        field: 'rentStep',
        header: 'Rent step',
        format: 'none',
        isFixed: true,
    },
    {
        key: 'rentGrowth',
        field: 'rentGrowth',
        header: 'Rent growth',
        format: 'percentage',
        isFixed: true,
    },
    {
        key: 'pricePerRack',
        field: 'pricePerRack',
        header: '$/rack',
        format: 'currency',
        isFixed: true,
    },
    {
        key: 'pricePerKwMonth',
        field: 'pricePerKwMonth',
        header: '$/kW/mo',
        format: 'currency',
        isFixed: true,
    },
    {
        key: 'annualRentYearOne',
        field: 'annualRentYearOne',
        header: 'Annual rent (yr 1)',
        format: 'currency',
        isFixed: true,
    },
];

export const BASE_ACTUAL_RECOVERY_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'recovery',
        field: 'recovery',
        header: 'Recovery',
        format: 'none',
        type: 'text',
        isFixed: true,
        isReadOnly: true,
    },
    {
        key: 'recoveryStart',
        field: 'recoveryStart',
        header: 'Rec. start',
        format: 'none',
        type: 'text',
        isFixed: true,
    },
    {
        key: 'recoveryPercentage',
        field: 'recoveryPercentage',
        header: 'Rec. %',
        format: 'percentage',
        isFixed: true,
    },
    {
        key: 'proRateShare',
        field: 'proRateShare',
        header: 'Pro rate share',
        format: 'percentage',
        isFixed: true,
    },
    {
        key: 'pricePerSfYear',
        field: 'pricePerSfYear',
        header: '$/SF/year',
        format: 'currency',
        isFixed: true,
    },
    {
        key: 'amountPerYear',
        field: 'amountPerYear',
        header: 'Amount/year',
        format: 'currency',
        isFixed: true,
    },
];

export const BASE_ACTUAL_OTHER_INCOME_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'name',
        field: 'name',
        header: 'Other income',
        format: 'none',
        type: 'text',
        isFixed: true,
    },
    {
        key: 'start',
        field: 'start',
        header: 'Start',
        format: 'none',
        isFixed: true,
    },
    {
        key: 'annualGrowth',
        field: 'annualGrowth',
        header: 'Annual growth',
        format: 'percentage',
        isFixed: true,
    },
    {
        key: 'rentalIncome',
        field: 'rentalIncome',
        header: '% of rental income',
        format: 'percentage',
        isFixed: true,
    },
    {
        key: 'pricePerRackYear',
        field: 'pricePerRackYear',
        header: '$/rack/year',
        format: 'currency',
        isFixed: true,
    },
    {
        key: 'pricePerKwYear',
        field: 'pricePerKwYear',
        header: '$/kW/year',
        format: 'currency',
        isFixed: true,
    },
    {
        key: 'amountPerYear',
        field: 'amountPerYear',
        header: 'Amount/year',
        format: 'currency',
        isFixed: true,
    },
];

export const BASE_ACTUAL_TOTAL_POTENTIAL_COLUMNS: ProFormaGenericColumn[] = [
    {
        key: 'name',
        field: 'name',
        header: 'Total potential income',
        format: 'none' as const,
        isFixed: true,
    },
    {
        key: 'invisibleStart',
        field: 'invisibleStart',
        header: '',
        format: 'none',
        isFixed: true,
    },
    {
        key: 'invisibleAnnualGrowth',
        field: 'invisibleAnnualGrowth',
        header: '',
        format: 'percentage',
        isFixed: true,
    },
    {
        key: 'invisibleRentalIncome',
        field: 'invisibleRentalIncome',
        header: '',
        format: 'percentage',
        isFixed: true,
    },
    {
        key: 'totalPricePerRackYear',
        field: 'totalPricePerRackYear',
        header: '',
        format: 'currency',
        isFixed: true,
    },
    {
        key: 'invisiblePricePerKwYear',
        field: 'invisiblePricePerKwYear',
        header: '',
        format: 'currency',
        isFixed: true,
    },
    {
        key: 'totalAmountPerYear',
        field: 'totalAmountPerYear',
        header: '',
        format: 'currency',
        isFixed: true,
    },
];

export const BASE_ACTUAL_INCOME_ADJUSTMENTS_COLUMNS: ProFormaGenericColumn[] = [{
    key: 'name',
    field: 'name',
    header: 'Income adjustments',
    format: 'none',
    type: 'text',
    isFixed: true,
},
{
    key: 'invisibleUnits',
    field: 'invisibleUnits',
    header: '',
    isHidden: true,
    isFixed: true,
},
{
    key: 'percentOfIncome',
    field: 'percentOfIncome',
    header: '% of total pot. income',
    format: 'percentage',
    isFixed: true,
},
{
    key: 'pricePerSFMonth',
    field: 'pricePerSFMonth',
    header: '$/SF/Mo.',
    isFixed: true,
    isNegative: true,
},
{
    key: 'pricePerUnitMonth',
    field: 'pricePerUnitMonth',
    header: '$/Unit/month',
    isFixed: true,
    isNegative: true,
},
{
    key: 'totalAmountPerYear',
    field: 'totalAmountPerYear',
    header: 'Amount/year',
    isFixed: true,
    isNegative: true,
},
];
