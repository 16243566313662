import React, { useState, useMemo } from 'react';
import '../ProFormaIncomeTable.scss';
import ProFormaGenericTable from 'views/ProFormaTable/components/ProFormaGenericTable/ProFormaGenericTable';
import { ProFormaGenericRow, ProFormaGenericColumn } from 'views/ProFormaTable/components/ProFormaGenericTable/types';
import { useScrollableSync } from 'views/ProFormaTable/hooks/useScrollableSync';
import { formatUSDCurrencyTwoDigits } from 'ns_libs/formatter';
import RenderIf from 'components/RenderIf/RenderIf';
import {
    BASE_RENTAL_COLUMNS,
    BASE_RECOVERY_COLUMNS,
    BASE_ACTUAL_OTHER_INCOME_COLUMNS,
    BASE_ESTIMATE_OTHER_INCOME_COLUMNS,
    BASE_ACTUAL_INCOME_ADJUSTMENTS_COLUMNS,
    BASE_ESTIMATE_INCOME_ADJUSTMENTS_COLUMNS,
    BASE_ACTUAL_TOTAL_POTENTIAL_COLUMNS,
    BASE_ESTIMATE_TOTAL_POTENTIAL_COLUMNS,
    BASE_ACTUAL_RENTAL_COLUMNS,
    BASE_ACTUAL_RECOVERY_COLUMNS,
} from './constants';

const OfficeIndustrialIncomeTable = () => {
    const { handleScroll, handleScrollableRef } = useScrollableSync();

    const periodOptions = useMemo(() => [
        { label: 'MON 26', value: 'month-26' },
        { label: 'MON 27', value: 'month-27' },
    ], []);

    const [rentalRows, setRentalRows] = useState<ProFormaGenericRow[]>([
        {
            id: '1',
            floor: '1',
            allocation: '100%',
            sf: '650',
            pricePerSf: '25.00',
            annualRent: '1,152,000',
            invisibleUnits: '0',
        },
        {
            id: '2',
            floor: '2',
            allocation: '60%',
            sf: '750',
            pricePerSf: '25.00',
            annualRent: '1,224,000',
            invisibleUnits: '0',
        },
    ]);

    const [recoveryRows, setRecoveryRows] = useState<ProFormaGenericRow[]>([
        {
            id: '1',
            recoveryType: 'Recovery income',
            recStart: 'Year 1',
            recPercent: '5%',
            pricePerSf: '25.00',
            amountYear: '1,152,000',
            invisibleUnits: '0',
        },
    ]);

    const [otherIncomeRows, setOtherIncomeRows] = useState<ProFormaGenericRow[]>([
        {
            id: '1',
            otherIncomeType: 'Parking income',
            start: 'Feb 2023',
            annualGrowth: '5%',
            percentOfIncome: '5%',
            pricePerSf: '25.00',
            amountYear: '1,152,000',
            invisibleUnits: '0',
        },
        {
            id: '2',
            otherIncomeType: 'Percentage rent',
            start: 'Feb 2023',
            annualGrowth: '5%',
            percentOfIncome: '5%',
            pricePerSf: '25.00',
            amountYear: '1,224,000',
            invisibleUnits: '0',
        },
    ]);

    const [incomeAdjustmentsRows, setIncomeAdjustmentsRows] = useState<ProFormaGenericRow[]>([
        {
            id: 'vacancy',
            name: 'Vacancy',
            percentOfIncome: '5.00%',
            pricePerSf: '($0.11)',
            pricePerUnit: '($94)',
            amountYear: '($336,794)',
            invisibleUnits: '0',
        },
        {
            id: 'credit-loss',
            name: 'Credit loss',
            percentOfIncome: '1.00%',
            pricePerSf: '($0.08)',
            pricePerUnit: '($94)',
            amountYear: '($67,359)',
            invisibleUnits: '0',
        },
    ]);

    const [actualRecoveryRows, setActualRecoveryRows] = useState<ProFormaGenericRow[]>([
        {
            id: '1',
            recoveryType: 'JLL',
            recStart: 'Feb 2023',
            recPercent: '5%',
            proRataShare: '5%',
            pricePerRsf: '$25.00',
            amountYear: '$1,152,000',
        },
    ]);

    const [actualRentalRows, setActualRentalRows] = useState<ProFormaGenericRow[]>([
        {
            id: '1',
            suite: '100',
            tenant: 'JLL',
            leaseStart: 'Feb 2023',
            rentStart: 'Mar 2023',
            rentGrowth: '5%',
            rentStep: 'Every 5 years',
            rsf: '650',
            pricePerRsf: '$25.00',
            annualRent: '$1,152,000',
        },
    ]);

    const operatingPeriodColumns = useMemo(() => periodOptions.map((period, index) => ({
        key: `column_${period.value}`,
        field: `column_${period.value}`,
        header: (
            <div className="h6 NSTable__thead__tr__th--grey-text text-uppercase text-nowrap text-secondary text-right mb-0">
                <div>
                    MONTH
                    {' '}
                    {index}
                </div>
                <div className="h6 text-uppercase text-right text-nowrap mt-1">
                    {period.label}
                </div>
            </div>
        ),
        format: 'currency' as const,
        isFixed: false,
    })), [periodOptions]);

    const estimateRentalColumns = useMemo(
        () => [...BASE_RENTAL_COLUMNS, ...operatingPeriodColumns],
        [operatingPeriodColumns],
    );

    const estimateRecoveryColumns = useMemo(
        () => [...BASE_RECOVERY_COLUMNS, ...operatingPeriodColumns],
        [operatingPeriodColumns],
    );

    const estimateOtherIncomeColumns = useMemo(
        () => [...BASE_ESTIMATE_OTHER_INCOME_COLUMNS, ...operatingPeriodColumns],
        [operatingPeriodColumns],
    );

    const estimateIncomeAdjustmentsColumns = useMemo(
        () => [...BASE_ESTIMATE_INCOME_ADJUSTMENTS_COLUMNS, ...operatingPeriodColumns],
        [operatingPeriodColumns],
    );

    const actualOtherIncomeColumns = useMemo(
        () => [...BASE_ACTUAL_OTHER_INCOME_COLUMNS, ...operatingPeriodColumns],
        [operatingPeriodColumns],
    );

    const actualRecoveryColumns = useMemo(
        () => [...BASE_ACTUAL_RECOVERY_COLUMNS, ...operatingPeriodColumns],
        [operatingPeriodColumns],
    );

    const actualIncomeAdjustmentsColumns = useMemo(
        () => [...BASE_ACTUAL_INCOME_ADJUSTMENTS_COLUMNS, ...operatingPeriodColumns],
        [operatingPeriodColumns],
    );

    const actualRentalColumns = useMemo(
        () => [...BASE_ACTUAL_RENTAL_COLUMNS, ...operatingPeriodColumns],
        [operatingPeriodColumns],
    );

    const createTotalPotentialColumns = (baseColumns: ProFormaGenericColumn[]) => {
        const baseCols = baseColumns.map(col => {
            let headerValue = col.header;

            if (col.key === 'potentialIncomeType') {
                headerValue = 'Total potential income';
            } else if (col.key === 'pricePerSf') {
                headerValue = formatUSDCurrencyTwoDigits(25.00);
            } else if (col.key === 'amountYear') {
                headerValue = formatUSDCurrencyTwoDigits(115200);
            } else if (col.key === 'invisibleUnits') {
                headerValue = '';
            }

            return {
                ...col,
                header: headerValue,
            };
        });

        const operatingCols = operatingPeriodColumns.map(col => ({
            ...col,
            header: (
                <div className="text-right">
                    {formatUSDCurrencyTwoDigits(115200)}
                </div>
            ),
        }));

        return [...baseCols, ...operatingCols];
    };

    const actualTotalPotentialColumns = useMemo(
        () => createTotalPotentialColumns(BASE_ACTUAL_TOTAL_POTENTIAL_COLUMNS),
        [operatingPeriodColumns],
    );

    const estimateTotalPotentialColumns = useMemo(
        () => createTotalPotentialColumns(BASE_ESTIMATE_TOTAL_POTENTIAL_COLUMNS),
        [operatingPeriodColumns],
    );

    // TODO: TODO remove this and get variable from socket
    const isEstimate = false;

    return (
        <div className="ProFormaIncome p-3">
            <RenderIf isTrue={isEstimate}>
                <ProFormaGenericTable
                    columns={estimateRentalColumns}
                    rows={rentalRows}
                    setRows={setRentalRows}
                    totalText="Total rental income"
                    totalValues={{
                        allocation: false,
                        sf: formatUSDCurrencyTwoDigits(650),
                        pricePerSf: formatUSDCurrencyTwoDigits(25.00),
                        amountYear: formatUSDCurrencyTwoDigits(7003000),
                        annualRent: formatUSDCurrencyTwoDigits(1152000),
                    }}
                    firstColumnWidth="130px"
                    numFirstColumns={2}
                    onScrollableRef={handleScrollableRef(0)}
                    onScroll={handleScroll}
                />
                <div className="pb-1" />
                <ProFormaGenericTable
                    columns={estimateRecoveryColumns}
                    rows={recoveryRows}
                    setRows={setRecoveryRows}
                    totalText="Total recovery income"
                    totalValues={{
                        pricePerSf: formatUSDCurrencyTwoDigits(1.25),
                        amountYear: formatUSDCurrencyTwoDigits(7003000),
                        recoveryType: false,
                        recStart: false,
                        recPercent: false,
                        invisibleUnits: false,
                    }}
                    onScrollableRef={handleScrollableRef(1)}
                    onScroll={handleScroll}
                />
                <div className="pb-1" />
                <ProFormaGenericTable
                    columns={estimateOtherIncomeColumns}
                    rows={otherIncomeRows}
                    setRows={setOtherIncomeRows}
                    totalText="Total other income"
                    totalValues={{
                        pricePerSf: formatUSDCurrencyTwoDigits(25.00),
                        amountYear: formatUSDCurrencyTwoDigits(7003000),
                        start: false,
                        annualGrowth: false,
                        percentOfIncome: false,
                        invisibleUnits: false,
                    }}
                    onScrollableRef={handleScrollableRef(2)}
                    onScroll={handleScroll}
                />
                <div className="pb-1" />
                <ProFormaGenericTable
                    columns={estimateTotalPotentialColumns}
                    rows={[]}
                    setRows={() => {}}
                    hideTotalFooter
                    hideAddButton
                    onScrollableRef={handleScrollableRef(3)}
                    onScroll={handleScroll}
                />
                <div className="pb-1" />
                <ProFormaGenericTable
                    columns={estimateIncomeAdjustmentsColumns}
                    rows={incomeAdjustmentsRows}
                    setRows={setIncomeAdjustmentsRows}
                    totalText="Effective gross revenue"
                    totalValues={{
                        percentOfIncome: false,
                        pricePerUnit: formatUSDCurrencyTwoDigits(94),
                        amountYear: formatUSDCurrencyTwoDigits(6331720),
                        pricePerSf: formatUSDCurrencyTwoDigits(25.00),
                    }}
                    onScrollableRef={handleScrollableRef(4)}
                    onScroll={handleScroll}
                />
            </RenderIf>

            <RenderIf isTrue={!isEstimate}>
                <ProFormaGenericTable
                    columns={actualRentalColumns}
                    rows={actualRentalRows}
                    setRows={setActualRentalRows}
                    totalText="Total rental income"
                    totalValues={{
                        suite: false,
                        tenant: false,
                        leaseStart: false,
                        rentStart: false,
                        rentGrowth: 5,
                        rentStep: 5,
                        rsf: formatUSDCurrencyTwoDigits(650),
                        pricePerRsf: formatUSDCurrencyTwoDigits(25.00),
                        annualRent: formatUSDCurrencyTwoDigits(1152000),
                    }}
                    firstColumnWidth="130px"
                    numFirstColumns={2}
                    onScrollableRef={handleScrollableRef(0)}
                    onScroll={handleScroll}
                />
                <div className="pb-1" />
                <ProFormaGenericTable
                    columns={actualRecoveryColumns}
                    rows={actualRecoveryRows}
                    setRows={setActualRecoveryRows}
                    totalText="Total recovery income"
                    totalValues={{
                        recoveryType: false,
                        recStart: false,
                        recPercent: false,
                        proRataShare: false,
                        pricePerRsf: formatUSDCurrencyTwoDigits(1.25),
                        amountYear: formatUSDCurrencyTwoDigits(7003000),
                    }}
                    hideAddButton
                    onScrollableRef={handleScrollableRef(1)}
                    onScroll={handleScroll}
                />
                <div className="pb-1" />
                <ProFormaGenericTable
                    columns={actualOtherIncomeColumns}
                    rows={otherIncomeRows}
                    setRows={setOtherIncomeRows}
                    totalText="Total other income"
                    totalValues={{
                        pricePerSf: formatUSDCurrencyTwoDigits(25.00),
                        amountYear: formatUSDCurrencyTwoDigits(7003000),
                        start: false,
                        annualGrowth: false,
                        percentOfIncome: false,
                        invisibleUnits: false,
                    }}
                    onScrollableRef={handleScrollableRef(2)}
                    onScroll={handleScroll}
                />
                <div className="pb-1" />
                <ProFormaGenericTable
                    columns={actualTotalPotentialColumns}
                    rows={[]}
                    setRows={() => {}}
                    hideTotalFooter
                    hideAddButton
                    onScrollableRef={handleScrollableRef(3)}
                    onScroll={handleScroll}
                />
                <div className="pb-1" />
                <ProFormaGenericTable
                    columns={actualIncomeAdjustmentsColumns}
                    rows={incomeAdjustmentsRows}
                    setRows={setIncomeAdjustmentsRows}
                    totalText="Effective gross revenue"
                    totalValues={{
                        percentOfIncome: false,
                        pricePerUnit: formatUSDCurrencyTwoDigits(94),
                        amountYear: formatUSDCurrencyTwoDigits(6331720),
                        pricePerSf: formatUSDCurrencyTwoDigits(25.00),
                    }}
                    onScrollableRef={handleScrollableRef(4)}
                    onScroll={handleScroll}
                />
            </RenderIf>
        </div>
    );
};

export default OfficeIndustrialIncomeTable;
