import React, { useContext, useState, useEffect } from 'react';
import useGetFeatureAccess from 'hooks/useGetUserAuthData';
import {
    AccessLevels, IFeatureAccess, IFeatureAccessMap, TAccessLevel, TFeatureId, TUserClassId, IRole,
} from 'types/auth';
import useGetAuthenticatedUser, { User } from 'queries/UserMenu/useGetAuthenticatedUser';
import { USER_CLASSES, SELECTED_ORGANIZATION_ID } from 'services/constants';
import useGetUserOrganizations, { IUserOrganization } from 'hooks/useGetUserOrganizations';

export interface IAuthContext {
    selectedOrganizationId?: string;
    setSelectedOrganizationId: (orgId: string) => void;
    userOrganizations: IUserOrganization[];
    isLoadingUserOrganizations: boolean;
    orgPermissions: IFeatureAccessMap;
    isLoadingAuthData: boolean;
    isFeatureAccessibleToUser: (featureIds: TFeatureId[], requiredAccessLevel: TAccessLevel) => boolean;
    role: IRole | null;
    user: User | null;
}

const defaultAuthContext: IAuthContext = {
    selectedOrganizationId: undefined,
    setSelectedOrganizationId: () => {},
    userOrganizations: [],
    isLoadingUserOrganizations: true,
    orgPermissions: {},
    isLoadingAuthData: true,
    isFeatureAccessibleToUser: () => false,
    role: null,
    user: null,
};

export const AuthContext = React.createContext<IAuthContext>(defaultAuthContext);

export const useAuthContext = () => useContext(AuthContext);
export default useAuthContext;

export const featureArrayToObject = (features: IFeatureAccess[] | undefined): IFeatureAccessMap => {
    if (!features) return {};
    return features.reduce((featuresObj, feature) => ({
        ...featuresObj,
        [feature.id]: feature,
    }), {} as IFeatureAccessMap);
};

export const isFeatureAccessible = (
    featureIds: number[],
    requiredAccessLevel: TAccessLevel,
    orgPermissions: IFeatureAccessMap,
    userClass: TUserClassId | undefined,
) => {
    if (userClass === USER_CLASSES.SUPER_ADMIN) return true;
    return featureIds.some(featureId => {
        const accessLevel = orgPermissions?.[featureId]?.accessLevel ?? AccessLevels.NO_ACCESS;
        return accessLevel >= requiredAccessLevel;
    });
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    type CookieKey = typeof SELECTED_ORGANIZATION_ID
    const getCookie = (key: CookieKey) => document.cookie
        .split('; ')
        .find(pair => pair.startsWith(key))
        ?.split('=')[1];

    const setCookie = (key: string, value: string, attributes: string) => {
        document.cookie = `${key}=${value}; ${attributes}`;
    };
    const [selectedOrganizationId, setSelectedOrganizationId] = useState<string | undefined>(
        () => getCookie(SELECTED_ORGANIZATION_ID) || undefined,
    );

    useEffect(() => {
        if (selectedOrganizationId) {
            setCookie(SELECTED_ORGANIZATION_ID, selectedOrganizationId, 'path=/; SameSite=None; Secure; Max-Age=34560000');
        }
    }, [selectedOrganizationId]);

    const { data: userAuthData, isLoading: isLoadingUserAuthData } = useGetFeatureAccess(selectedOrganizationId!, {
        enabled: Boolean(selectedOrganizationId),
        staleTime: Infinity,
    });

    const orgPermissions = featureArrayToObject(userAuthData?.featureAccess) || {};

    const { data: authenticatedUser, isLoading: isLoadingAuthenticatedUser } = useGetAuthenticatedUser();

    const isFeatureAccessibleToUser = (
        featureIds: TFeatureId[], requiredAccessLevel: TAccessLevel,
    ) => isFeatureAccessible(featureIds, requiredAccessLevel, orgPermissions, authenticatedUser?.user.userClass);

    const { data: userOrganizations = [], isLoading: isLoadingUserOrganizations } = useGetUserOrganizations();

    useEffect(() => {
        if (!selectedOrganizationId && userOrganizations.length > 0) {
            setSelectedOrganizationId(userOrganizations[0].organization.correlationId);
        }
    }, [userOrganizations, selectedOrganizationId]);

    return (
        <AuthContext.Provider
            value={{
                selectedOrganizationId: selectedOrganizationId || undefined,
                setSelectedOrganizationId,
                userOrganizations,
                isLoadingUserOrganizations,
                orgPermissions,
                isLoadingAuthData: isLoadingUserAuthData || isLoadingAuthenticatedUser,
                isFeatureAccessibleToUser,
                role: userAuthData?.role || null,
                user: authenticatedUser?.user || null,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
