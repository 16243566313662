/* eslint-disable operator-linebreak */
import { numeralFormatter, numeralFormatterCurrency } from 'ns_libs/formatter';
import {
    PRO_FORMA_APARTMENT_UNIT_TYPES,
    PRO_FORMA_INDUSTRIAL_UNIT_TYPES,
    PRO_FORMA_RETAIL_AND_OFFICE_UNIT_TYPES,
    UNIT_TYPE_NAMES,
    SQUARE_FEET_PER_ACRE,
    UnitTypeIds,
} from 'constants/unitTypes';
import { ProFormaModelIds } from 'constants/proForma';
import { format } from 'date-fns';
import { PRO_FORMA_TABS, PRO_FORMA_TABLE_NAV, RETURNS_AND_WATERFALL_TABS } from './constants';
import {
    IProFormaUses, IUpdateProFormaKeyValues, IGrossBuildableAreaLotsData, CashFlowEntry,
    IProFormaValues,
} from './types';
import { ProFormaGenericColumn } from './components/ProFormaGenericTable/types';

export const getProFormaUrl = (sideNavName: string, tabName?: string) => {
    const section = encodeURIComponent(sideNavName);
    let tab = '';

    if (!tabName) {
        if (sideNavName === PRO_FORMA_TABLE_NAV.PRO_FORMA.name) {
            tab = `&tab=${encodeURIComponent(PRO_FORMA_TABS.GENERAL_INFORMATION.name)}`;
        } else if (sideNavName === PRO_FORMA_TABLE_NAV.RETURNS_AND_WATERFALL.name) {
            tab = `&tab=${encodeURIComponent(RETURNS_AND_WATERFALL_TABS.RETURNS)}`;
        }
    } else {
        tab = `&tab=${encodeURIComponent(tabName)}`;
    }

    const url = `${window.location.pathname}?section=${section}${tab}`;

    return url;
};

export const generateDateRange = (dateRange: string[], labelFormat: string = 'MMM yyyy', isCashFlowPeriods: boolean = false) => {
    const dates: Date[] = [];

    const startDate = new Date(dateRange[0]);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(dateRange[1]);
    endDate.setHours(0, 0, 0, 0);

    if (isCashFlowPeriods) {
        startDate.setMonth(startDate.getMonth() - 1); // per product, we need to subtract one month before pro forma sale date for cash flows
        endDate.setMonth(endDate.getMonth() + 11); // per product, we need to add one year after pro forma sale date for cash flows
    }

    const currentDate = new Date(startDate);
    currentDate.setDate(1);

    while (currentDate <= endDate) {
        dates.push(new Date(currentDate));
        currentDate.setMonth(currentDate.getMonth() + 1);
        currentDate.setDate(1);
    }

    const options = dates.map(date => ({
        value: format(date, 'yyyy-MM-dd'), // 2024-01-01
        label: format(date, labelFormat),
    }));
    return options;
};

export const getBudgetLinesDateRange = (uses: IProFormaUses[]) => {
    /**
     * Get the earliest start date and latest end date of all budget lines.
     * If any budget line has a null start date, the earliest start date will be null.
     * If any budget line has a null end date, the latest end date will be null.
     */

    const budgetLineStartDates: (string | null)[] = [];
    const budgetLineEndDates: (string | null)[] = [];
    uses.map(highLevel => {
        highLevel.children?.map(subcategory => {
            subcategory.children?.map(budgetLine => {
                if (budgetLine.isEditable) {
                    budgetLineStartDates.push(budgetLine.startDate || null);
                    budgetLineEndDates.push(budgetLine.endDate || null);
                }
                return null;
            });
            return null;
        });
        return null;
    });

    let earliestBudgetLinesStartDate = null;
    let latestBudgetLinesEndDate = null;

    if (budgetLineStartDates.includes(null)) {
        earliestBudgetLinesStartDate = null;
    } else {
        const validStartDates = budgetLineStartDates.filter(date => date !== null) as string[];

        earliestBudgetLinesStartDate = validStartDates.length ? validStartDates.reduce((earliest, date) => (date < earliest ? date : earliest)) : null;
    }

    if (budgetLineEndDates.includes(null)) {
        latestBudgetLinesEndDate = null;
    } else {
        const validEndDates = budgetLineEndDates.filter(date => date !== null) as string[];

        latestBudgetLinesEndDate = validEndDates.length ? validEndDates.reduce((latest, date) => (date > latest ? date : latest)) : null;
    }

    return { earliestBudgetLinesStartDate, latestBudgetLinesEndDate };
};

const calculateParkingMetrics = (values: IProFormaValues, units: number): { totalSpots: number; ratio: string | undefined } => {
    const totalSpots = (values.structuredParkingSpaces || 0) + (values.surfaceParkingSpaces || 0);
    const ratio = totalSpots && units ? (totalSpots / units).toFixed(2) : undefined;
    return { totalSpots, ratio };
};

const calculateEfficiencyMetrics = (values: IProFormaValues, rentableSquareFootage: number): string | undefined => (
    rentableSquareFootage && values.grossBuildableArea
        ? (rentableSquareFootage / values.grossBuildableArea).toFixed(2)
        : undefined
);

const calculateAreaMetrics = (values: IProFormaValues, units: number) => {
    const landArea = values.landArea || 0;
    const landAreaInAcres = landArea && values.landAreaUnitTypeId === UnitTypeIds.ACRE
        ? landArea / SQUARE_FEET_PER_ACRE
        : landArea;
    const averageSqftPerUnit = values.grossBuildableArea && units
        ? values.grossBuildableArea / units
        : 0;

    return {
        netRentableArea: units && averageSqftPerUnit ? units * averageSqftPerUnit : undefined,
        unitsPerAcre: units && landAreaInAcres ? (units / landAreaInAcres).toFixed(2) : undefined,
    };
};

const createDisplayCard = (label: string, value: number | string | undefined, format?: string, valueSuffix?: string, fullWidth: boolean = false) => ({
    label, value, ...(format && { format }), ...(valueSuffix && { valueSuffix }), fullWidth,
});

const getApartmentDisplayCards = (values: IProFormaValues, units: number, rentableSquareFootage: number, isFar: boolean) => {
    const { totalSpots, ratio } = calculateParkingMetrics(values, units);
    const efficiencyRatio = calculateEfficiencyMetrics(values, rentableSquareFootage);
    const { netRentableArea, unitsPerAcre } = calculateAreaMetrics(values, units);

    const farCard = createDisplayCard('FAR', values.floorAreaRatio || 0, '0,0.00', undefined, true);
    const grossBuildableAreaCard = createDisplayCard('Gross buildable area', values.grossBuildableArea || 0, '0,0', 'ft²', true);

    return [
        createDisplayCard('Units', units),
        createDisplayCard('Net rentable area', netRentableArea, '0,0'),
        isFar ? grossBuildableAreaCard : farCard,
        createDisplayCard('Units/acre', unitsPerAcre, '0,0.00'),
        createDisplayCard('Total parking spots', totalSpots, '0,0'),
        createDisplayCard('Parking ratio', ratio, '0,0.00'),
        createDisplayCard('Efficiency ratio', efficiencyRatio, '0,0.00', '%'),
    ];
};

const getOfficeDisplayCards = (values: IProFormaValues, units: number, rentableSquareFootage: number) => {
    const { totalSpots, ratio } = calculateParkingMetrics(values, units);
    const efficiencyRatio = calculateEfficiencyMetrics(values, rentableSquareFootage);
    const { netRentableArea, unitsPerAcre } = calculateAreaMetrics(values, units);

    return [
        createDisplayCard('Suites', units),
        createDisplayCard('Net rentable area', netRentableArea, '0,0'),
        createDisplayCard('Efficiency ratio', efficiencyRatio, '0,0.00', '%', true),
        createDisplayCard('Gross buildable area', values.grossBuildableArea || 0, '0,0', 'ft²'),
        createDisplayCard('Average floor plate', unitsPerAcre, '0,0.00'),
        createDisplayCard('Total parking spots', totalSpots, '0,0'),
        createDisplayCard('Parking ratio', ratio, '0,0.00'),
    ];
};

const getDataCenterDisplayCards = () => [
    createDisplayCard('Tenants', undefined),
    createDisplayCard('Net rentable area', undefined),
    createDisplayCard('Racks', undefined),
    createDisplayCard('Total kW', undefined),
    createDisplayCard('Total parking spaces', undefined),
];

export const getProFormaModelDisplayCards = (
    proFormaModelId: number,
    values: IProFormaValues,
    units: number,
    rentableSquareFootage: number,
    isFar = false,
) => {
    switch (proFormaModelId) {
        case ProFormaModelIds.APARTMENT:
            return getApartmentDisplayCards(values, units, rentableSquareFootage, isFar);
        case ProFormaModelIds.OFFICE:
            return getOfficeDisplayCards(values, units, rentableSquareFootage);
        case ProFormaModelIds.DATA_CENTER:
            return getDataCenterDisplayCards();
        default:
            return [];
    }
};

export const formatValue = (value: string, isNegative = false, forDisplay = false, format?: ProFormaGenericColumn['format'], isHidden = false) => {
    if (isHidden) return '';

    if (format === 'none') return value;

    const num = parseFloat(value);
    if (Number.isNaN(num)) return value;
    if (!forDisplay) return value;

    const absNum = Math.abs(num);
    let formatted: string;

    switch (format) {
        case 'percentage':
            formatted = `${numeralFormatter(absNum, '0.00')}%`;
            break;
        default:
            formatted = numeralFormatterCurrency(absNum);
    }

    return isNegative ? `(${formatted})` : formatted;
};

export const getMissingDateText = (startDate: string | null, saleDate: string | null) => {
    if (!startDate && !saleDate) {
        return {
            title: 'Start and Sale dates required',
            prependedSubtitle: 'Provide start and sale dates on',
            boldSubtitleText: 'Start and End',
        };
    }
    if (!startDate) {
        return {
            title: 'Start date required',
            prependedSubtitle: 'Provide a start date on',
            boldSubtitleText: 'Start',
        };
    }
    if (!saleDate) {
        return {
            title: 'Sale date required',
            prependedSubtitle: 'Provide a sale date on',
            boldSubtitleText: 'End',
        };
    }
    return {
        title: null,
        prependedSubtitle: null,
        boldSubtitleText: null,
    };
};

/**
 * Includes Date logic as follows:
 *  if a user selects a start date, the sale date should be the first day of the next month.
 *  if a user selects a start date, and the start date is after the existing sale date, the sale date should be the first day of the next month.
 *  if a user selects a sale date, and the sale date is before the start date, the start date should be cleared.
 */
export const handleDateChange = (
    name: string,
    newDate: string | null,
    values: { [key: string]: any },
    handleUpdateProForma: (keyValues: IUpdateProFormaKeyValues[]) => void,
) => {
    if (!newDate) {
        handleUpdateProForma([{ key: name, value: null }]);
        return;
    }

    const [year, month, day] = newDate.split('-').map(Number);
    const selectedDate = new Date(year, month - 1, day);

    if (name === 'startDate') {
        const saleDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 1);
        const newStartDate = format(selectedDate, 'yyyy-MM-dd');
        const newSaleDate = format(saleDate, 'yyyy-MM-dd');
        const formattedSaleDate = new Date(values.saleDate);

        const keyUpdates: IUpdateProFormaKeyValues[] = [{ key: 'startDate', value: `${newStartDate}T00:00:00` }];

        if (!values.saleDate || selectedDate > formattedSaleDate) {
            keyUpdates.push({ key: 'saleDate', value: `${newSaleDate}T00:00:00` });
        }

        handleUpdateProForma(keyUpdates);
    } else {
        const newSaleDate = format(selectedDate, 'yyyy-MM-dd');
        const formattedStartDate = new Date(values.startDate);

        const keyUpdates: IUpdateProFormaKeyValues[] = [{ key: 'saleDate', value: `${newSaleDate}T00:00:00` }];
        if (values.startDate && formattedStartDate > selectedDate) {
            keyUpdates.push({ key: 'startDate', value: null });
        }
        handleUpdateProForma(keyUpdates);
    }
};

export const getProFormaDevelopmentUnitTypeOptions = (proFormaModelId: number) => {
    if (proFormaModelId === ProFormaModelIds.APARTMENT) {
        return PRO_FORMA_APARTMENT_UNIT_TYPES.map(unitType => ({
            label: UNIT_TYPE_NAMES[unitType].dropdownItemName,
            value: unitType,
        }));
    }
    if (proFormaModelId === ProFormaModelIds.INDUSTRIAL) {
        return PRO_FORMA_INDUSTRIAL_UNIT_TYPES.map(unitType => ({
            label: UNIT_TYPE_NAMES[unitType].dropdownItemName,
            value: unitType,
        }));
    }
    if (proFormaModelId === ProFormaModelIds.RETAIL || proFormaModelId === ProFormaModelIds.OFFICE) {
        return PRO_FORMA_RETAIL_AND_OFFICE_UNIT_TYPES.map(unitType => ({
            label: UNIT_TYPE_NAMES[unitType].dropdownItemName,
            value: unitType,
        }));
    }
    return [];
};

export const calculateGrossBuildableArea = (
    buildableLots: IGrossBuildableAreaLotsData,
    grossBuildableArea: string | null,
    floorAreaRatio: string | null,
    landArea: string | null,
    landAreaUnitTypeId: number,
) => {
    const { totalGrossBuildableArea, grossBuildableAreaLots } = buildableLots;
    if (grossBuildableAreaLots.length) return Number(totalGrossBuildableArea);
    if (grossBuildableArea !== null) return Number(grossBuildableArea);
    if (floorAreaRatio === null || landArea === null) return null;

    let calculatedGrossBuildableArea = Number(floorAreaRatio) * Number(landArea);
    if (landAreaUnitTypeId === UnitTypeIds.ACRE) calculatedGrossBuildableArea *= SQUARE_FEET_PER_ACRE;
    return calculatedGrossBuildableArea;
};

export const calculateFloorAreaRatio = (
    floorAreaRatio: string | null,
    landArea: string | null,
    landAreaUnitType: number,
    grossBuildableArea: number | null,
) => {
    if (grossBuildableArea !== null && landArea !== null) {
        const landAreaSf = landAreaUnitType === UnitTypeIds.ACRE ? Number(landArea) * SQUARE_FEET_PER_ACRE : Number(landArea);
        return landAreaSf ? grossBuildableArea / landAreaSf : 0;
    }
    return floorAreaRatio !== null ? Number(floorAreaRatio) : null;
};

// Creates the monthly columns for our table
export const createCashFlowColumns = (cashFlow: CashFlowEntry[] = []): ProFormaGenericColumn[] => cashFlow.map(cf => ({
    key: `column_${cf.month}`,
    field: `column_${cf.month}`,
    header: cf.month,
    isFixed: false,
}));

// Turns monthly numbers into table-friendly data
export const createCashFlowData = (cashFlow: CashFlowEntry[] = []): Record<string, string> => Object.fromEntries(
    cashFlow.map(cf => [`column_${cf.month}`, String(cf.value || 0)]),
);

// Combines all columns from different sources into one table
export const createTableCashFlowColumns = (items?: Array<{ cashFlow?: CashFlowEntry[] }>) => {
    // Flatten all cashFlow entries and extract their month fields
    const allMonths = items?.flatMap(item => item.cashFlow || []).map(cf => cf.month) || [];

    // Remove duplicates by converting to a Set, then back to an array
    const uniqueMonths = Array.from(new Set(allMonths));

    // Convert each unique month to a column definition
    return uniqueMonths.map(month => ({
        key: `column_${month}`,
        field: `column_${month}`,
        header: month,
        isFixed: false,
    }));
};
