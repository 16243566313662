import React from 'react';
import { NSButton } from 'bricks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { getReportTypeFromLabel } from 'views/ReportBuilder/helpers';
import { ReportTypes } from 'views/ReportBuilder/constants';

export interface INewReportButtonProps {
    reportName: string;
    handleReportSelection: (reportType: ReportTypes) => void;
}

const NewReportButton = ({ reportName, handleReportSelection }: INewReportButtonProps) => (
    <NSButton callback={() => handleReportSelection(getReportTypeFromLabel(reportName))} className="mt-2 py-2 w-100" color="secondary" outline={false}>
        <div className="text-white d-flex justify-content-between align-items-center">
            <h5 className="ml-2 my-0">{reportName}</h5>
            <FontAwesomeIcon icon={faPlus} size="lg" />
        </div>
    </NSButton>
);

export default NewReportButton;
