import { NSButton, NSCard, NSCheckbox } from 'bricks';
import React from 'react';
import { DEAL_OVERVIEW_ATTRIBUTES } from 'views/ReportBuilder/constants';
import { formatDealHeader } from 'views/ReportBuilder/helpers';

export interface IProps {
    checkedAttributeState: Record<string, boolean>;
    setCheckedAttributeState: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
}

const AttributesSection = ({ checkedAttributeState, setCheckedAttributeState }: IProps) => {
    const handleCheckboxChange = (key: string) => {
        setCheckedAttributeState(prevState => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    const handleSelectDeselectAll = (group: keyof typeof DEAL_OVERVIEW_ATTRIBUTES, selectAll: boolean) => {
        const groupItems = DEAL_OVERVIEW_ATTRIBUTES[group];
        setCheckedAttributeState(prevState => {
            const newState = { ...prevState };
            groupItems.forEach(item => {
                newState[item] = selectAll;
            });
            return newState;
        });
    };

    return (
        <div>
            {Object.entries(DEAL_OVERVIEW_ATTRIBUTES).map(([header, items]) => (
                <NSCard key={header} className="NSCard--level-3 p-3 mb-1">
                    <div className="d-flex justify-content-between align-items-center m-0">
                        <h5 className="mt-0 mb-2">{formatDealHeader(header)}</h5>
                        <NSButton
                            className="p-0 text-primary"
                            callback={() => {
                                const allSelected = items.every(item => checkedAttributeState[item]);
                                handleSelectDeselectAll(header as keyof typeof DEAL_OVERVIEW_ATTRIBUTES, !allSelected);
                            }}
                        >
                            {items.every(item => checkedAttributeState[item]) ? 'Deselect all' : 'Select all'}
                        </NSButton>
                    </div>
                    <div className="d-flex flex-column">
                        {items.map(item => (
                            <NSCheckbox
                                key={item}
                                id={item}
                                labelText={item}
                                checked={checkedAttributeState[item] || false}
                                callback={() => handleCheckboxChange(item)}
                            />
                        ))}
                    </div>
                </NSCard>
            ))}
        </div>
    );
};

export default AttributesSection;
