import { AxiosError } from 'axios';
import {
    QueryObserverSuccessResult, useQueries,
} from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS from 'services/urls';
import { S3PresignedUrlData } from 'types/s3Upload';

export interface IQueryKey {
    queryKey: readonly unknown[] | any[]
}

export interface IUploadUrlData {
    url: S3PresignedUrlData;
    fileName: string;
}

interface IPostParamsData {
    file_name: string;
    feature: string;
    public_read: boolean;
    organization_correlation_id: string;
}

const getUploadUrls = async ({
    queryKey,
}: IQueryKey): Promise<IUploadUrlData> => {
    const params = queryKey[1] as IPostParamsData;
    const response = await axiosInstance.get(API_ENDPOINTS.FILE_STORAGE, { params });
    return response.data;
};

const useUploadUrls = (
    organization_correlation_id: string,
    droppedFiles: File[],
    publicRead: boolean,
    feature: string,
    options?: any,
) => useQueries({
    queries: droppedFiles.map((file, index) => {
        const params = {
            file_name: file.name,
            feature,
            public_read: publicRead,
            organization_correlation_id,
        };
        return {
            queryKey: [`UploadUrl-${index}`, params],
            queryFn: getUploadUrls,
            ...options,
        };
    }),
    combine: results => {
        results.forEach(result => {
            if (result.isError) {
                options?.onError(result.error as AxiosError);
            } else if (result.isSuccess) {
                options?.onSuccess(result.data as QueryObserverSuccessResult<IUploadUrlData>);
            }
        });
        return results;
    },
});

export default useUploadUrls;
