import React from 'react';
import { Label, LabelProps } from 'reactstrap';
import RenderIf from '../../components/RenderIf/RenderIf';

export interface INSLabelProps extends LabelProps {
    className?: string;
    required?: boolean;
    children: React.ReactNode | React.ReactNode[];
}

const NSLabel = ({
    className, children, required, ...rest
}: INSLabelProps) => (
    <Label {...rest} className={className || 'mb-1 font-weight-normal text-dark'}>
        {children}
        <RenderIf isTrue={required}>
            <span className="text-danger ml-1">*</span>
        </RenderIf>
    </Label>
);

export default NSLabel;
