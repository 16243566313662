import { useMutation } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS from 'services/urls';
import { StepOneValues } from '../components/ProFormaWizardStepOne/ProFormaWizardStepOne';
import { ISource } from '../components/ProFormaWizardStepTwo/Sources/Source/types';
import { IExpense } from '../BasicInformation/hooks/useExpenses';
import { IExpenses } from '../components/ProFormaWizardStepThree/types';

export type StepTwoValues = {
    sources: ISource[];
    acquisitionCosts: number;
    hardCosts: number;
    totalHardCosts: number;
    hardCostType: string;
    softCosts: number;
    totalSoftCosts: number;
    softCostType: string;
    developerFee: number;
    interestReserve: number;
};

export type StepThreeValues = {
    rentalGrowthPercentage: number;
    percentPreLeased: number;
    leaseUpPace: number;
    lengthOfLease: number;
    initialFreeRent: number;
    stabilizedFreeRent: number;
    vacancy: number;
    creditLoss: number;
    incomeUnitMixes: any[];
    otherIncome: any[];
    expensesValues: IExpenses;
    operatingExpenses?: IExpense[];
    includePermanentDebt?: boolean;
    isInterestOnly?: boolean;
    amortizationInYears?: number;
    occupancyUponStabilizationPercentage?: number;
    feesAndClosingCostsPercentage?: number;
};

export type WizardStepValues = StepOneValues | StepTwoValues | StepThreeValues;

interface UpdateStepParams {
    orgId: string;
    proFormaId: string;
    stepNumber: number;
    data: WizardStepValues;
}

export const useUpdateProFormaWizardStep = () => useMutation({
    mutationFn: async ({
        orgId, proFormaId, stepNumber, data,
    }: UpdateStepParams) => {
        const response = await axiosInstance.patch(API_ENDPOINTS.PRO_FORMA_WIZARD_STEP(orgId, proFormaId, stepNumber), data);
        return response.data;
    },
});
