import { faPercentage } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NSInput, NSRadioOption } from 'bricks';
import NSModal from 'bricks/NSModal/NSModal';
import React, { useEffect, useState } from 'react';
import './LoanSizingModal.scss';
import { faRight } from '@fortawesome/pro-solid-svg-icons';
import { LOAN_CALCULATION_OPTIONS, LOAN_CALCULATION_TYPES } from '../SlideoutForm/constants';
import { ILoanSizingModalProps, ILoanSizingOption, ILoanSizingValue } from './types';
import { calculateLoanValue, loanRowByType } from './helpers';

const LoanSizingModal = ({
    onConfirm, onCancel, boeValues, ...props
}: ILoanSizingModalProps) => {
    const [ltv, setLtv] = useState<number>();
    const [ltc, setLtc] = useState<number>();
    const [debtYield, setDebtYield] = useState<number>();
    const [marketCap, setMarketCap] = useState<number>();
    const [ltvAmount, setLtvAmount] = useState<number>();
    const [ltcAmount, setLtcAmount] = useState<number>();
    const [debtYieldAmount, setDebtYieldAmount] = useState<number>();
    const [selectedOption, setSelectedOption] = useState<string>('');

    const maxAmount = Math.max(debtYieldAmount || 0, ltcAmount || 0, ltvAmount || 0);

    const handleOptionChange = (id: string) => {
        setSelectedOption(id);
    };

    useEffect(() => {
        if (boeValues) {
            // LTV
            const LTVBOEValues = {
                ...boeValues,
                selectedLoanCalculationOptions: LOAN_CALCULATION_OPTIONS[0],
                marketCapRate: marketCap || 0,
                contructionFinancing: ltv || 0,
            };
            setLtvAmount(calculateLoanValue(LTVBOEValues));

            // LTC
            const LTCBOEValues = {
                ...boeValues,
                selectedLoanCalculationOptions: LOAN_CALCULATION_OPTIONS[1],
                contructionFinancing: ltc || 0,
            };
            setLtcAmount(calculateLoanValue(LTCBOEValues));

            // Debt Yield
            const DebtYieldValues = {
                ...boeValues,
                selectedLoanCalculationOptions: LOAN_CALCULATION_OPTIONS[2],
                contructionFinancing: debtYield || 0,
            };
            setDebtYieldAmount(calculateLoanValue(DebtYieldValues));
        }
    }, [boeValues, marketCap, ltc, ltv, debtYield]);

    const handleCancel = () => {
        onCancel();
    };

    const handleSelect = () => {
        const optionMapping: Record<string, { option: ILoanSizingOption; type: LOAN_CALCULATION_TYPES; values: ILoanSizingValue }> = {
            debtYieldOption: {
                option: LOAN_CALCULATION_OPTIONS[2],
                type: LOAN_CALCULATION_TYPES.DY,
                values: { contructionFinancing: debtYield },
            },
            ltcOption: {
                option: LOAN_CALCULATION_OPTIONS[1],
                type: LOAN_CALCULATION_TYPES.LTC,
                values: { contructionFinancing: ltc },
            },
            ltvOption: {
                option: LOAN_CALCULATION_OPTIONS[0],
                type: LOAN_CALCULATION_TYPES.LTV,
                values: { contructionFinancing: ltv, marketCapRate: marketCap },
            },
        };

        const selected = optionMapping[selectedOption];
        if (selected) {
            onConfirm(selected.option, selected.type, selected.values);
        }
        onCancel();
    };

    const handleInputChange = (onChange: (value: number) => void, value: number) => {
        const newValue = Math.min(Math.max(value, 0), 100);
        onChange(newValue);
    };

    return (
        <NSModal
            {...props}
            size="xl"
            modalHeaderText="Loan sizing test"
            modalHeaderClassName="bg-primary text-white"
            modalFooterButtonText="Use selected"
            modalFooterCancelButtonText="Cancel"
            modalFooterButtonColor="primary"
            modalFooterCancelButtonFunction={handleCancel}
            modalFooterButtonFunction={handleSelect}
        >
            <div className="LoanTestModal d-flex flex-direction-row align-items-center justify-content-between">
                <div className="LoanCard">
                    <div className="LoanCard-title-box">
                        <div className="LoanCard-title" />
                        <div className="LoanCard-title">Metric</div>
                        <div className="LoanCard-title">Market cap rate</div>
                    </div>
                    <div className="LoanCard-row-box">
                        <div className="LoanCard-row">
                            <NSRadioOption
                                id="debtYieldOption"
                                name="radioOptions"
                                label="Debt yield"
                                checked={selectedOption === 'debtYieldOption'}
                                onChange={() => handleOptionChange('debtYieldOption')}
                            />
                        </div>
                        <div className="LoanCard-row">
                            <NSInput
                                id="debtYield"
                                type="number"
                                name="debtYield"
                                placeholder="Enter"
                                value={debtYield}
                                appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    handleInputChange(setDebtYield, event.target.valueAsNumber);
                                }}
                            />
                        </div>
                        <div className="LoanCard-row" />
                    </div>
                    <div className="LoanCard-row-box">
                        <div className="LoanCard-row">
                            <NSRadioOption
                                id="ltcOption"
                                name="radioOptions"
                                label="LTC"
                                checked={selectedOption === 'ltcOption'}
                                onChange={() => handleOptionChange('ltcOption')}
                            />
                        </div>
                        <div className="LoanCard-row">
                            <NSInput
                                id="loanToCost"
                                type="number"
                                name="loanToCost"
                                appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                                placeholder="Enter"
                                value={ltc}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    handleInputChange(setLtc, event.target.valueAsNumber);
                                }}
                            />
                        </div>
                        <div className="LoanCard-row" />
                    </div>
                    <div className="LoanCard-row-box">
                        <div className="LoanCard-row">
                            <NSRadioOption
                                id="ltvOption"
                                name="radioOptions"
                                label="LTV"
                                checked={selectedOption === 'ltvOption'}
                                onChange={() => handleOptionChange('ltvOption')}
                            />
                        </div>
                        <div className="LoanCard-row">
                            <NSInput
                                id="loanToValue"
                                type="number"
                                name="loanToValue"
                                appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                                placeholder="Enter"
                                value={ltv}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    handleInputChange(setLtv, event.target.valueAsNumber);
                                }}
                            />
                        </div>
                        <div className="LoanCard-row">
                            <NSInput
                                id="marketCap"
                                type="number"
                                name="marketCap"
                                appendInputAddon={<FontAwesomeIcon icon={faPercentage} />}
                                placeholder="Enter"
                                value={marketCap}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    handleInputChange(setMarketCap, event.target.valueAsNumber);
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="LoanIcons">
                    <span />
                    <FontAwesomeIcon icon={faRight} />
                    <FontAwesomeIcon icon={faRight} />
                    <FontAwesomeIcon icon={faRight} />
                </div>

                <div className="LoanCard">
                    <div className="LoanCard-title-box">
                        <div className="LoanCard-title">Max loan</div>
                    </div>
                    {loanRowByType(debtYieldAmount, maxAmount)}
                    {loanRowByType(ltcAmount, maxAmount)}
                    {loanRowByType(ltvAmount, maxAmount)}
                </div>
            </div>
        </NSModal>
    );
};

export default LoanSizingModal;
