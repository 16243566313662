import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS, { QUERY_KEYS } from 'services/urls';
import { IOrganizationUser } from 'types/organization';

const getOrganizationUsers = async (organizationId: string): Promise<IOrganizationUser[]> => {
    const response = await axiosInstance.get<IOrganizationUser[]>(
        API_ENDPOINTS.ORGANIZATION.USERS(organizationId),
    );
    return response.data;
};

export const useGetOrganizationUsers = (
    organizationId: string,
    options?: Omit<UseQueryOptions<IOrganizationUser[], Error>, 'queryKey' | 'queryFn'>,
) => useQuery<IOrganizationUser[], Error>({
    queryKey: [QUERY_KEYS.ORGANIZATION.USERS, organizationId],
    queryFn: () => getOrganizationUsers(organizationId),
    staleTime: Infinity,
    ...(options ?? {}),
});
